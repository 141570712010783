import {useEffect} from 'react';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useAutoBookShipmentMutation, AutoBookMutationOptions} from './hooks/useAutobookShipmentMutation';
import {useCreateParcelRateRequest} from 'App/api/quoting/hooks/parcel/useCreateParcelRateRequest';
import {useGetParcelRateRequest} from 'App/api/quoting/hooks/parcel/useGetParcelRateRequest';
import {CombinedQuote} from 'App/containers/Marketplace/components/NewParcelMarketplace/components/AllParcelRates/types/combinedQuote';
import {ParcelList} from 'App/containers/Marketplace/components/NewParcelMarketplace/components/AllParcelRates/components/ParcelList';
import {transformGenesisRateIntoCombinedQuote} from 'App/containers/Marketplace/components/NewParcelMarketplace/components/AllParcelRates/helpers/transformGenesisRateIntoCombinedQuote';
import {mapNewQuoteFormServiceOptions, NewQuoteServiceOptions} from 'App/api/quoting/utils/transformParcelPayload';
import {useCreateParcelCarrierConnection} from 'App/api/quoting/hooks/parcel/useCreateParcelCarrierConnection';
import {getParcelShipmentParentRFQ} from 'App/utils/rfq/getParcelShipmentParentRfq';
import ShipwellLoader from 'App/common/shipwellLoader';

type GenesisReRatedRatesProps = {
  shipment: Shipment;
  selectedQuote: CombinedQuote;
  onAutoBookSuccess: (bookResponse: Shipment) => void;
  onAutoBookError: (bookResponse: unknown) => void;
  submittedFormValues: {
    preferred_currency: string;
    service_options: NewQuoteServiceOptions;
  };
  autoBookMutationOptions?: Pick<AutoBookMutationOptions, 'onSuccess' | 'onError' | 'onMutate'>;
};

export const GenesisReRatedRatesModal = ({
  shipment,
  selectedQuote,
  submittedFormValues,
  autoBookMutationOptions
}: GenesisReRatedRatesProps) => {
  const {autoBookShipment, autoBookMutation} = useAutoBookShipmentMutation(autoBookMutationOptions);

  const {createParcelCarrierConnectionAsync, createParcelCarrierConnectionMutation} =
    useCreateParcelCarrierConnection();

  const {handleCreateParcelRequest, parcelRequestId} = useCreateParcelRateRequest();

  const {parcelRates, isLoadingParcelRates} = useGetParcelRateRequest({
    requestParams: {
      rateRequestId: parcelRequestId as string,
      accountId: selectedQuote?.capacity_provider?.account_id
    },
    shouldClearAccount: false
  });

  useEffect(() => {
    const {parcelCapacityProviderOptions, billTo} = mapNewQuoteFormServiceOptions(
      submittedFormValues.service_options,
      submittedFormValues.preferred_currency
    );

    void handleCreateParcelRequest({
      shipment,
      billTo,
      parcelCapacityProviderOptions
    });
  }, [
    handleCreateParcelRequest,
    shipment,
    submittedFormValues.preferred_currency,
    submittedFormValues.service_options
  ]);

  const handleAutoSelectedQuote = async (genesisRate: CombinedQuote) => {
    const parentRfq = getParcelShipmentParentRFQ(shipment);

    if (!parentRfq?.id || !parcelRequestId) return;

    await createParcelCarrierConnectionAsync(
      {
        legacyRfqId: parentRfq.id,
        rateId: genesisRate.id as string,
        rateRequestId: parcelRequestId
      },
      {
        onSettled: (carrierConnectionQuote) => {
          if (!carrierConnectionQuote?.id) return;

          autoBookShipment({
            autoBookRequest: {
              quote: carrierConnectionQuote.id,
              capacity_provider_options: {
                account_number: genesisRate.capacity_provider?.account_id,
                provider_code: genesisRate.service_options.provider_code,
                service_code: genesisRate.service_options.service_code ?? undefined,
                ...(!!genesisRate.service_options.service_options && {
                  service_options: genesisRate.service_options.service_options
                })
              }
            },
            shipmentId: shipment.id
          });
        }
      }
    );
  };

  if (isLoadingParcelRates) {
    return (
      <div className="flex flex-col items-center justify-center">
        <ShipwellLoader loading />
        <span className="text-base">Re-rating...</span>
      </div>
    );
  }

  if (autoBookMutation.isLoading || createParcelCarrierConnectionMutation.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <ShipwellLoader loading />
        <span className="text-base">Dispatching to carrier...</span>
      </div>
    );
  }

  return (
    <div>
      <ParcelList
        handleSelectQuote={handleAutoSelectedQuote}
        quotes={parcelRates?.[selectedQuote?.service_options?.provider_code].map(transformGenesisRateIntoCombinedQuote)}
      />
    </div>
  );
};
