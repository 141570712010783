import {Component} from 'react';
import {Token, Typeahead, tokenContainer} from 'react-bootstrap-typeahead';

let tagSelectorIdCounter = 1;

class TagSelector extends Component {
  constructor(props) {
    super(props);
    this.id = `tag_selector_id_${(tagSelectorIdCounter++).toString().padStart(6, '0')}`;
    this.state = {selected: [], value: '', isLoading: false, pagination: {pageSize: 100, page: 1}, options: []};
  }

  componentDidMount() {
    //there may be default selections we need to populate the state initially
    if (this.props.defaultValues) {
      this.parseDefaultValues(this.props.defaultValues);
    }
    if (this.props.localOptions) {
      //prefill the local list options when appropriate
      const {localOptions} = this.props;
      const results = localOptions.map((opt) => ({value: opt.id, label: opt.name, color: opt.color}));
      this.setState({options: results});
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValues !== this.props.defaultValues && this.props.defaultValues === null) {
      //we received a 'clear' signal from the parent
      this.setState({selected: []});
    } else if (prevProps.defaultValues !== this.props.defaultValues) {
      this.parseDefaultValues(this.props.defaultValues);
    }
  }

  parseDefaultValues(defaultValues) {
    const selections = [];
    //look through the local list to populate the default values with appropriate labels
    const {localOptions} = this.props;
    defaultValues.forEach((value) => {
      const matchingObj = localOptions.filter((opt) => opt.id === value)[0];
      matchingObj && selections.push({value: matchingObj.id, label: matchingObj.name, color: matchingObj.color});
    });
    this.setState({selected: selections});
  }

  onInputValueChange({target}) {
    this.setState({
      value: target.value
    });
  }

  /*
   * Send the current selections up to the parent component for refresh of data
   */
  updateParent(selections) {
    const arrayToUpdate = selections.map((e) => e.value);
    const filterObj = {};
    filterObj[this.props.fieldName] = arrayToUpdate;
    this.props.updateActiveFilters(filterObj);
  }

  render() {
    const {selected, isLoading} = this.state;
    const {placeholder} = this.props;
    return (
      <div className="tableFilters__tagSelector">
        <Typeahead
          id={`${this.id}`}
          multiple
          isLoading={isLoading}
          selected={selected ? selected : []}
          placeholder={placeholder}
          useCache={false}
          autoFocus={this.props.preventAutoFocus ? false : true}
          minLength={0}
          options={this.state.options}
          renderToken={(option, props, index) => <TokenDisplay key={index} onRemove={props.onRemove} option={option} />}
          renderMenuItemChildren={(option, props, index) => (
            <div className={`tag-option ${option.color}`}>
              <span className="option-label">{option.label}</span>
            </div>
          )}
          onChange={(selection) => {
            this.setState({selected: selection});
            this.updateParent(selection);
          }}
        />
      </div>
    );
  }
}

const TokenDisplay = (props) => {
  return (
    <Token
      className={`tag-option ${props.option.color} text-sw-text-reverse`}
      option={props.option}
      onRemove={props.onRemove}
    >
      <div className="mr-3">{props.option.label}</div>
    </Token>
  );
};

export default TagSelector;
