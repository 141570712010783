import {SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {ComponentProps} from 'react';

export type SvgIconWithNumberFallbackProps = ComponentProps<typeof SvgIcon>;

export function SvgIconWithNumberFallback(props: SvgIconWithNumberFallbackProps) {
  const isNumIcon = props.name.match(/Num[0-9]{1,}/);
  const isOutlinedIcon = props.name.match(/outlined/i);
  const isFilledIcon = props.name.match(/filled/i);

  const numberInsideOfIconToShow = isNumIcon ? props.name.match(/\d+/)?.at(0) : null;

  return (
    <>
      {!isNumIcon && <SvgIcon {...props} />}
      {isNumIcon && (
        <div
          role="status"
          aria-label={props['aria-label']}
          className={classNames(
            'flex size-5 items-center justify-center rounded-full text-xxs font-bold leading-normal',
            props.className,
            {
              'bg-sw-primary text-[#fff]': isFilledIcon,
              'border-sw-text border-2 text-sw-text': isOutlinedIcon
            }
          )}
        >
          {numberInsideOfIconToShow}
        </div>
      )}
    </>
  );
}
