import {ProviderCode} from '@shipwell/genesis-sdk';

// ideally this value should return from backend
const purolatorCodOptions = [{id: 'PERSONAL_CHECK', name: 'ExpressCheque'}];
const canparCodOptions = [
  {id: 'CASH', name: 'Cash'},
  {id: 'CHECK', name: 'Check'},
  {id: 'MONEY_ORDER', name: 'Money Order'}
];
const canadaPostCodOptions = [
  {id: 'CASH', name: 'Cash'},
  {id: 'CHECK', name: 'Check'},
  {id: 'MONEY_ORDER', name: 'Money Order'}
];

export const getProviderCodOptions = (providerCode?: ProviderCode) => {
  if (!providerCode) return [];

  const codOptions: Record<string, {id: string; name: string}[]> = {
    [ProviderCode.Purolator]: purolatorCodOptions,
    [ProviderCode.Canpar]: canparCodOptions,
    [ProviderCode.Canadapost]: canadaPostCodOptions
  };

  return codOptions[providerCode];
};
