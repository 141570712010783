import {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import classNames from 'classnames';
import {Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {isCurrentLocation} from '../helpers/isCurrentLocation';
import DockSchedulingNavigation from './DockSchedulingNavigation';
import {TemplateManagementLink} from 'App/containers/templateManagement/links';
import {
  ConditionalCreateDrayageShipmentLink,
  ConditionalCreateIntermodalShipmentLink
} from 'App/containers/shipments/links';
import {ConditionalServiceProvidersLink} from 'App/containers/serviceProviders/links';
import RfpCarrierLink from 'App/containers/userCompany/rfps/RfpCarrierLink';
import {State} from 'App/reducers/types';
import {ConditionalSettlementsNavigationLink} from 'App/containers/settlements/freightInvoices/components/SettlementsDashboard/ConditionalNavigationLink';
import './styles.scss';
import {ConditionalSuppliersLink} from 'App/containers/suppliers/routes/routes';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';
import {
  CREATE_ORDERS_USER_PERMISSION,
  CREATE_QUOTES_USER_PERMISSION,
  CREATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION,
  CREATE_ADDRESS_BOOK_PERMISSION,
  DELETE_ADDRESS_BOOK_PERMISSION,
  UPDATE_ADDRESS_BOOK_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import {ConditionalCreateOrderStandardNavigationLinks} from 'App/containers/orders/components';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';
import {browserHistory} from 'react-router';

type MainNavigationProps = {
  isQuotingLimitedUser: boolean;
  hasCustomerViewPermission: boolean;
  hasCustomerCreatePermission: boolean;
  hasCustomerRemovePermission: boolean;
  hasShipmentUpdatePermission: boolean;
  hasCompanyUserViewPermission: boolean;
  hasCompanyUserCreatePermission: boolean;
  hasCompanyUserDeletePermission: boolean;
  hasCompanyUserUpdatePermission: boolean;
  hasCompanyDetailsViewPermission: boolean;
  hasCompanyAdministratorPermission: boolean;
  hasCompanyDetailsUpdatePermission: boolean;
  hasCarrierCreatePermission: boolean;
  hasCarrierRemovePermission: boolean;
  hasCarrierViewPermission: boolean;
  hasQuotesUpdatePermission: boolean;
  hasDispatchViewPermission: boolean;
  hasAnalyticsViewPermission: boolean;
  hasOrdersViewPermission: boolean;
  hasContractsViewPermission: boolean;
  hasContractsUpdatePermission: boolean;
  hasContractsDeletePermission: boolean;
  hasContractsCreatePermission: boolean;
  hasIntegrationsViewPermission: boolean;
  hasIntegrationsCreatePermission: boolean;
  hasIntegrationsManagePermission: boolean;
  hasIntegrationsRemovePermission: boolean;
  isPurchaseOrdersEnabled: boolean;
  isAnalyticsEnabled: boolean;
  isLoadBoardEnabled: boolean;
  isContractsEnabled: boolean;
  isSupplierInvitesEnabled: boolean;
  isRoutingGuidesEnabled: boolean;
  isBidManagerEnabled: boolean;
  isPricingIntelEnabled: boolean;
  isAlertsDashboardEnabled: boolean;
  isCarrier: boolean;
  isSupplierUser: boolean;
  hasAppointmentsViewPermission: boolean;
  hasSchedulingViewPermission: boolean;
  hasLocationCreatePermission: boolean;
  hasLocationUpdatePermission: boolean;
  hasLocationDeletePermission: boolean;
};

// need to serpasrate this from the rest of the REDUX props
interface MainNavigationDepedencies {
  newAirShipmentHandler: () => void;
}

/**
 * Authenticated App Navigation
 * @param {*} props
 */
const MainNavigation = (props: MainNavigationProps & MainNavigationDepedencies) => {
  const [newShipmentExpanded, setNewShipmentExpanded] = useState(true);
  const hasManageCompanyProfilePermission =
    props.hasCompanyDetailsViewPermission ||
    props.hasCompanyAdministratorPermission ||
    props.hasCompanyDetailsUpdatePermission;
  const hasManageContractsPermission =
    props.hasContractsViewPermission ||
    props.hasContractsUpdatePermission ||
    props.hasContractsDeletePermission ||
    props.hasContractsCreatePermission;
  const hasManageUsersPermission =
    props.hasCompanyUserViewPermission ||
    props.hasCompanyUserUpdatePermission ||
    props.hasCompanyUserCreatePermission ||
    props.hasCompanyUserDeletePermission;
  const hasManageIntegrationsPermission =
    props.hasIntegrationsViewPermission ||
    props.hasIntegrationsCreatePermission ||
    props.hasIntegrationsManagePermission ||
    props.hasIntegrationsRemovePermission;
  const hasLocationPermission =
    props.hasLocationCreatePermission || props.hasLocationDeletePermission || props.hasLocationUpdatePermission;
  const hasManageDropdownAccess =
    // company
    props.hasCompanyAdministratorPermission ||
    props.hasCompanyDetailsViewPermission ||
    props.hasCompanyDetailsUpdatePermission ||
    // users
    props.hasCompanyUserCreatePermission ||
    props.hasCompanyUserUpdatePermission ||
    props.hasCompanyUserDeletePermission ||
    // costumer relationships
    props.hasCustomerCreatePermission ||
    props.hasCustomerRemovePermission ||
    // contracts
    props.hasContractsCreatePermission ||
    props.hasContractsUpdatePermission ||
    props.hasContractsDeletePermission ||
    // integrations.
    props.hasIntegrationsCreatePermission ||
    props.hasIntegrationsRemovePermission ||
    props.hasIntegrationsManagePermission ||
    // carrier_relationships
    props.hasCarrierCreatePermission ||
    props.hasCarrierRemovePermission ||
    // locations
    hasLocationPermission;
  const hasManageCompanyDropdownOptionAccess =
    hasManageCompanyProfilePermission ||
    //management areas located within manage company page
    hasManageContractsPermission ||
    hasManageUsersPermission ||
    hasManageIntegrationsPermission;
  const {
    stmSupplierPortal,
    stmV3OrdersTable,
    pdfGeneratorApiForDocumentGeneration,
    airMode,
    v3ToV2MigrationSwitcher,
    newShipmentCreationExperience
  } = useFlags();
  const supplierOrdersUrl = props.isSupplierUser ? '/orders' : '/purchase-orders';
  const [newShipmentBuilderPreference] = useLocalStorage<boolean>({
    key: 'new-shipment-builder-preference',
    initialValue: false
  });

  return (
    <nav className="sw-nav">
      {props.isAlertsDashboardEnabled && (
        <Link to="/compass" activeClassName="active">
          Compass
        </Link>
      )}
      {!props.isSupplierUser ? (
        <Link
          to="/dashboard"
          activeClassName="active"
          onClick={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            isCurrentLocation('/dashboard') && e.preventDefault();
          }}
        >
          Shipments
        </Link>
      ) : null}
      {props.isSupplierUser || (props.isPurchaseOrdersEnabled && props.hasOrdersViewPermission) ? (
        (stmSupplierPortal || stmV3OrdersTable) && !props.isSupplierUser ? (
          <Dropdown title="Orders" variant="tertiary" className="nav-submenu" indicator>
            {({onClick}: {onClick(): void}) => (
              <>
                <li key="orders-dashboard">
                  <Link to="/orders" activeClassName="active" onClick={onClick}>
                    Order Dashboard
                  </Link>
                </li>
                <li key="orders-optimization">
                  <Link to="/load-optimizations" activeClassName="active" onClick={onClick}>
                    Optimization Results
                  </Link>
                </li>
                {v3ToV2MigrationSwitcher && (
                  <li key="legacy-orders-dashboard">
                    <Link to="/purchase-orders" activeClassName="active" onClick={onClick}>
                      Legacy Orders
                    </Link>
                  </li>
                )}
              </>
            )}
          </Dropdown>
        ) : (
          <Link to={supplierOrdersUrl} activeClassName="active">
            Orders
          </Link>
        )
      ) : null}
      {(!props.isSupplierUser && (props.hasSchedulingViewPermission || props.hasAppointmentsViewPermission)) ||
      props.isCarrier ? (
        <DockSchedulingNavigation />
      ) : null}
      <ConditionalSettlementsNavigationLink />
      {props.isLoadBoardEnabled ? (
        <Link to="/load-board" activeClassName="active">
          Load Board
        </Link>
      ) : null}
      {props.isCarrier ? <RfpCarrierLink /> : null}
      {props.isAnalyticsEnabled && props.hasAnalyticsViewPermission ? (
        <Link to="/analytics" activeClassName="active">
          Analytics
        </Link>
      ) : null}
      {props.isBidManagerEnabled || props.isPricingIntelEnabled ? (
        <Dropdown title="Tools" variant="tertiary" className="nav-submenu" indicator>
          {({onClick}: {onClick(): void}) => [
            props.isBidManagerEnabled ? (
              <li key="tools-bid-manager">
                <Link to="/bid-manager" activeClassName="active" onClick={onClick}>
                  Bid Manager
                </Link>
              </li>
            ) : null,
            props.isPricingIntelEnabled ? (
              <li key="tool-pricing-intel">
                <Link to="/pricing-intel" activeClassName="active" onClick={onClick}>
                  Pricing Intel
                </Link>
              </li>
            ) : null
          ]}
        </Dropdown>
      ) : null}
      {props.hasDispatchViewPermission ? (
        <Link to="/dispatch" activeClassName="active">
          Dispatch
        </Link>
      ) : null}
      {hasManageDropdownAccess ? (
        <Dropdown title="Manage" variant="tertiary" className="nav-submenu" indicator>
          {({onClick}: {onClick(): void}) => [
            !props.isQuotingLimitedUser && hasLocationPermission ? (
              <li key="manage-addressbook">
                <Link to="/addressbook" activeClassName="active" onClick={onClick}>
                  Address Book
                </Link>
              </li>
            ) : null,
            props.hasCarrierViewPermission ? (
              <li key="manage-carriers">
                <Link to="/carriers/?ordering=company" activeClassName="active" onClick={onClick}>
                  Carriers
                </Link>
              </li>
            ) : null,
            hasManageCompanyDropdownOptionAccess ? (
              <li key="manage-company">
                <Link to="/company" activeClassName="active" onClick={onClick}>
                  Company
                </Link>
              </li>
            ) : null,
            props.hasCustomerViewPermission ? (
              <li key="manage-customers">
                <Link to="/customers" activeClassName="active" onClick={onClick}>
                  Customers
                </Link>
              </li>
            ) : null,
            props.hasDispatchViewPermission ? (
              <li key="manage-drivers">
                <Link to="/drivers" activeClassName="active" onClick={onClick}>
                  Drivers
                </Link>
              </li>
            ) : null,
            props.hasDispatchViewPermission ? (
              <li key="manage-equipment">
                <Link to="/equipment" activeClassName="active" onClick={onClick}>
                  Equipment
                </Link>
              </li>
            ) : null,
            !props.isQuotingLimitedUser ? (
              <li key="manage-productsv2">
                <Link to="/products" activeClassName="active" onClick={onClick}>
                  Products
                </Link>
              </li>
            ) : null,
            <li key="manage-service-providers">
              <ConditionalServiceProvidersLink />
            </li>,
            props.isSupplierInvitesEnabled ? (
              <li key="manage-supplier-shipments">
                {/* Per ETS' request, default display shows current user's shipments */}
                <Link to="/supplier-shipments/?onlyMyShipments=true" activeClassName="active">
                  Shipment Requests
                </Link>
              </li>
            ) : null,
            <li key="manage-suppliers">
              <ConditionalSuppliersLink onClick={onClick} />
            </li>,
            !pdfGeneratorApiForDocumentGeneration ? (
              <li key="manage-documents">
                <TemplateManagementLink />
              </li>
            ) : null,
            props.isRoutingGuidesEnabled ? (
              <li key="manage-workflows">
                <Link to="/automation/routing-guides" activeClassName="active">
                  Workflows
                </Link>
              </li>
            ) : null
          ]}
        </Dropdown>
      ) : null}
      {!props.isQuotingLimitedUser && !props.isSupplierUser ? (
        <Dropdown
          className="new-item-dropdown"
          variant="primary"
          indicator={false}
          icon={
            <div>
              <SvgIcon name="Plus" />
            </div>
          }
        >
          {({onClick}: {onClick(): void}) => [
            <PermissionsFallback key="new-shipment-dropdown" permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
              <>
                <div
                  onClick={() => setNewShipmentExpanded(!newShipmentExpanded)}
                  className={classNames('flex justify-between pl-5 pr-2 items-center w-56', {
                    'font-bold': newShipmentExpanded
                  })}
                >
                  <span>Shipment</span>
                  <SvgIcon name={newShipmentExpanded ? 'ExpandLess' : 'ExpandMore'} />
                </div>
                {newShipmentExpanded ? (
                  <>
                    <li>
                      <Link
                        className="pl-6 text-xs"
                        to={
                          newShipmentCreationExperience && newShipmentBuilderPreference === true
                            ? '/shipment/ftl/create'
                            : '/new-shipment-ftl'
                        }
                        activeClassName="active"
                        onClick={(e) => {
                          if (
                            window.location.pathname.includes('/shipment') &&
                            window.location.pathname.includes('/create')
                          ) {
                            e.preventDefault();

                            if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
                              browserHistory.push(`/shipment/router/ftl`);
                            } else {
                              browserHistory.push('/new-shipment-ftl');
                            }
                          }
                          onClick();
                        }}
                      >
                        FULL TRUCKLOAD
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="pl-6 text-xs"
                        to={
                          newShipmentCreationExperience && newShipmentBuilderPreference === true
                            ? '/shipment/ltl/create'
                            : '/new-shipment-ltl'
                        }
                        activeClassName="active"
                        onClick={(e) => {
                          if (
                            window.location.pathname.includes('/shipment') &&
                            window.location.pathname.includes('/create')
                          ) {
                            e.preventDefault();
                            if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
                              browserHistory.push(`/shipment/router/ltl`);
                            } else {
                              browserHistory.push('/new-shipment-ltl');
                            }
                          }
                          onClick();
                        }}
                      >
                        LESS THAN TRUCKLOAD
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="pl-6 text-xs"
                        to={
                          newShipmentCreationExperience && newShipmentBuilderPreference === true
                            ? '/shipment/vltl/create'
                            : '/new-shipment-vltl'
                        }
                        activeClassName="active"
                        onClick={(e) => {
                          if (
                            window.location.pathname.includes('/shipment') &&
                            window.location.pathname.includes('/create')
                          ) {
                            e.preventDefault();
                            if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
                              browserHistory.push(`/shipment/router/vltl`);
                            } else {
                              browserHistory.push('/new-shipment-vltl');
                            }
                          }
                          onClick();
                        }}
                      >
                        VOLUME LESS THAN TRUCKLOAD
                      </Link>
                    </li>
                    <li>
                      <ConditionalCreateDrayageShipmentLink
                        onClick={onClick}
                        isMainNav
                        newShipmentBuilderPreference={newShipmentBuilderPreference}
                        newShipmentCreationExperience={newShipmentCreationExperience}
                      />
                    </li>
                    <li>
                      <Link className="pl-6 text-xs" to="/new-quote-parcel" activeClassName="active" onClick={onClick}>
                        PARCEL
                      </Link>
                    </li>
                    <li>
                      <ConditionalCreateIntermodalShipmentLink
                        onClick={onClick}
                        isMainNav
                        newShipmentBuilderPreference={newShipmentBuilderPreference}
                        newShipmentCreationExperience={newShipmentCreationExperience}
                      />
                    </li>
                    <li>
                      <Link
                        className="pl-6 text-xs"
                        to={
                          newShipmentCreationExperience && newShipmentBuilderPreference === true
                            ? '/shipment/rail/create'
                            : '/new-shipment-rail'
                        }
                        activeClassName="active"
                        onClick={(e) => {
                          if (
                            window.location.pathname.includes('/shipment') &&
                            window.location.pathname.includes('/create')
                          ) {
                            e.preventDefault();
                            if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
                              browserHistory.push(`/shipment/router/rail`);
                            } else {
                              browserHistory.push('/new-shipment-rail');
                            }
                          }
                          onClick();
                        }}
                      >
                        RAIL
                      </Link>
                    </li>
                    {airMode && (
                      <li>
                        <Link
                          className="pl-6 text-xs"
                          to="/shipment/air/create"
                          activeClassName="active"
                          onClick={(e) => {
                            if (
                              window.location.pathname.includes('/shipment') &&
                              window.location.pathname.includes('/create')
                            ) {
                              e.preventDefault();
                              browserHistory.push(`/shipment/router/air`);
                            }
                            onClick();
                          }}
                        >
                          AIR
                        </Link>
                      </li>
                    )}
                  </>
                ) : null}
              </>
            </PermissionsFallback>,

            <PermissionsFallback key="new-quote" permissions={[CREATE_QUOTES_USER_PERMISSION]}>
              <li key="new-quote">
                <Link to="/new-quote" activeClassName="active" onClick={onClick}>
                  Quote
                </Link>
              </li>
            </PermissionsFallback>,
            props.isPurchaseOrdersEnabled ? (
              <PermissionsFallback key="new-order" permissions={[CREATE_ORDERS_USER_PERMISSION]}>
                <ConditionalCreateOrderStandardNavigationLinks onClick={onClick} />
              </PermissionsFallback>
            ) : null
          ]}
        </Dropdown>
      ) : null}
    </nav>
  );
};

const mapStateToProps = ({
  userProfile: {user},
  auth: {user: {permissions} = {}},
  company,
  userCompany
}: State): MainNavigationProps => ({
  isQuotingLimitedUser: user.is_quoting_limited_user || false,
  hasCustomerViewPermission: permissions?.includes('customer_relationships.view') || false,
  hasCustomerCreatePermission: permissions?.includes('customer_relationships.create') || false,
  hasCustomerRemovePermission: permissions?.includes('customer_relationships.remove') || false,
  hasShipmentUpdatePermission:
    permissions?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    permissions?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION) ||
    false,
  hasLocationCreatePermission: user.permissions?.includes(CREATE_ADDRESS_BOOK_PERMISSION) || false,
  hasLocationUpdatePermission: user.permissions?.includes(UPDATE_ADDRESS_BOOK_PERMISSION) || false,
  hasLocationDeletePermission: user.permissions?.includes(DELETE_ADDRESS_BOOK_PERMISSION) || false,
  hasCompanyUserViewPermission: user.permissions?.includes('users.view_company_users') || false,
  hasCompanyUserCreatePermission: user.permissions?.includes('users.create_company_users') || false,
  hasCompanyUserDeletePermission: user.permissions?.includes('users.delete_company_users') || false,
  hasCompanyUserUpdatePermission: user.permissions?.includes('users.update_company_users') || false,
  hasCompanyAdministratorPermission: permissions?.includes('company.administrator') || false,
  hasCompanyDetailsViewPermission: permissions?.includes('company.view_company_details') || false,
  hasCompanyDetailsUpdatePermission: permissions?.includes('company.update_company_details') || false,
  hasCarrierViewPermission: permissions?.includes('carrier_relationships.view_carrier_relationships') || false,
  hasCarrierCreatePermission: permissions?.includes('carrier_relationships.create_carrier_relationships') || false,
  hasCarrierRemovePermission: permissions?.includes('carrier_relationships.remove_carrier_relationships') || false,
  hasQuotesUpdatePermission: permissions?.includes('quotes.update_quotes') || false,
  hasDispatchViewPermission: permissions?.includes('carrier.view_driver_relationships') || false,
  hasAnalyticsViewPermission: permissions?.includes('analytics.view') || false,
  hasOrdersViewPermission: permissions?.includes('orders.view_orders') || false,
  hasContractsViewPermission: permissions?.includes('contracts.view_contracts') || false,
  hasContractsUpdatePermission: permissions?.includes('contracts.update_contracts') || false,
  hasContractsDeletePermission: permissions?.includes('contracts.delete_contracts') || false,
  hasContractsCreatePermission: permissions?.includes('contracts.create_contracts') || false,
  hasIntegrationsViewPermission: permissions?.includes('integrations.view_integrations') || false,
  hasIntegrationsCreatePermission: permissions?.includes('integrations.add_integrations') || false,
  hasIntegrationsRemovePermission: permissions?.includes('integrations.remove_integrations') || false,
  hasIntegrationsManagePermission: permissions?.includes('integrations.manage_integrations') || false,
  isPurchaseOrdersEnabled: company.company.feature_flags?.purchase_orders_enabled || false,
  isAnalyticsEnabled: company.company.feature_flags?.analytics_enabled || false,
  isLoadBoardEnabled: company.company.feature_flags?.show_load_board || false,
  isContractsEnabled: company.company.feature_flags?.contracts_enabled || false,
  isSupplierInvitesEnabled: company.company.feature_flags?.supplier_invites_enabled || false,
  isRoutingGuidesEnabled: company.company.feature_flags?.policies_enabled || false,
  isBidManagerEnabled: userCompany.company.feature_flags?.bid_manager_enabled || false,
  isPricingIntelEnabled: userCompany.company.feature_flags?.historical_pricing_enabled || false,
  isAlertsDashboardEnabled: userCompany.company.feature_flags?.alerts_dashboard_enabled || false,
  isCarrier: Boolean(userCompany.company.carrier),
  isSupplierUser: Boolean(userCompany.company.name === 'SYSTEM-WIDE SUPPLIER TENANT'),
  hasAppointmentsViewPermission: permissions?.includes('dock_scheduling.view_appointments') || false,
  hasSchedulingViewPermission: permissions?.includes('dock_scheduling.view_facilities') || false
});

export default connect(mapStateToProps)(MainNavigation);
