import {useGetAttachedDevices} from './useGetAttachedDevices';
import {useGetShipmentLocation} from './useGetShipmentLocation';

export const useDeviceLocaltionData = (shipmentId: string) => {
  const {attachedDevicesData, isFetchingAttachedDevicesData} = useGetAttachedDevices(shipmentId);
  const {shipmentLocationData, isFetchingShipmentLocation} = useGetShipmentLocation(shipmentId);

  return {
    devicesCount: attachedDevicesData.length,
    locationStr: shipmentLocationData?.cityState || '',
    dateStr: shipmentLocationData?.updateTime || '',
    isFetching: isFetchingAttachedDevicesData || isFetchingShipmentLocation
  };
};
