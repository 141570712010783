import {SvgIcon} from '@shipwell/shipwell-ui';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import {GroupedShipmentTimelineEvents, ShipmentTimelineEvent, StopAlertAlertTypeEnum} from '@shipwell/backend-core-sdk';
import TimelineIcon from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineIcon';
import TimelineCard from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineCard';
import FutureStop from 'App/components/ShipmentTrackingOverview/TimelineContainer/FutureStop';
import {mapEventTypeWithIcon} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils';
import {timelineFilterTypes} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils/timelineConstants';
import TimelineTrackingDeviceInformation from './TimelineTrackingDeviceInformation/TimelineTrackingDeviceInformation';
import {useDeviceLocaltionData} from './useDeviceLocationData';

export type FilterType = (typeof timelineFilterTypes)[keyof typeof timelineFilterTypes];

export interface TimelineFilter {
  timelineDisplayType: FilterType;
  groupType: FilterType;
  filterFutureStops: FilterType;
}

interface TimelineProps {
  stops: GroupedShipmentTimelineEvents[];
  shipmentId: string;
  toggleShowEventModal: () => void;
  selectedFilter: TimelineFilter;
  onUpdateEvent(event: ShipmentTimelineEvent): void;
  onDeleteEvent(event: ShipmentTimelineEvent): void;
}

function Timeline({
  stops,
  shipmentId,
  toggleShowEventModal,
  selectedFilter,
  onUpdateEvent,
  onDeleteEvent
}: TimelineProps) {
  const {dateStr, locationStr, devicesCount, isFetching} = useDeviceLocaltionData(shipmentId);
  const canHideVerticalLine = (stopIndex: number, eventIndex: number, stop: GroupedShipmentTimelineEvents) => {
    return stopIndex === stops?.length - 1 && eventIndex === stop?.timeline_events?.length - 1;
  };

  const canRollup = (stop: GroupedShipmentTimelineEvents) =>
    selectedFilter?.timelineDisplayType === timelineFilterTypes.ROLLUP_EVENTS &&
    stop?.timeline_events?.length > 1 &&
    Number.isInteger(stop?.stop_ordinal);

  function showFlag(event: GroupedShipmentTimelineEvents) {
    return event?.stop?.alerts?.some((a) => a?.alert_type === StopAlertAlertTypeEnum.Detention);
  }

  if (isEmpty(stops)) {
    return (
      <div className="flex h-5/6 flex-col items-center justify-center">
        <div className="mb-1">No Timeline Events</div>
        <div className="flex cursor-pointer items-center text-sw-primary" onClick={toggleShowEventModal}>
          <SvgIcon name="AddCircleOutlined" />
          Add Event
        </div>
      </div>
    );
  }

  const futureStops = stops?.filter((stop) => stop?.is_future_stop);
  const notFutureStops = stops?.filter((stop) => !stop?.is_future_stop);

  return (
    <>
      {futureStops?.map((timelineStop, stopIndex) => {
        const stopsHasIndexZero = stops.some((stop) => stop.stop_ordinal === 0);
        const iconNumberValue =
          stopsHasIndexZero && isNumber(timelineStop?.stop_ordinal)
            ? timelineStop?.stop_ordinal + 1
            : timelineStop?.stop_ordinal;
        return (
          <div key={stopIndex} className="ml-2">
            <FutureStop stopOrdinalNumber={iconNumberValue} isLastStop={stopIndex === stops?.length - 1} />
          </div>
        );
      })}
      {Boolean(devicesCount) && (
        <div className="ml-2">
          <div className="flex">
            <TimelineIcon showFlag={false} isLineHidden={notFutureStops.length === 0} iconToShow={'Truck'} />
            <TimelineTrackingDeviceInformation
              locationStr={locationStr}
              dateStr={dateStr}
              devicesCount={devicesCount}
              isFetching={isFetching}
            />
          </div>
        </div>
      )}
      {notFutureStops?.map((timelineStop, stopIndex) => {
        const stopsHasIndexZero = stops.some((stop) => stop.stop_ordinal === 0);
        const iconNumberValue =
          stopsHasIndexZero && isNumber(timelineStop?.stop_ordinal)
            ? timelineStop?.stop_ordinal + 1
            : timelineStop?.stop_ordinal;
        return (
          <div key={stopIndex} className="ml-2">
            {canRollup(timelineStop) ? (
              <div className="flex">
                <TimelineIcon
                  showFlag={showFlag(timelineStop)}
                  isLineHidden={stopIndex === stops.length - 1}
                  iconToShow={isNumber(iconNumberValue) ? `Num${iconNumberValue}Filled` : 'CheckCircleFilled'}
                />
                <TimelineCard
                  timelineEvents={timelineStop?.timeline_events}
                  shipmentId={shipmentId}
                  onUpdateEvent={onUpdateEvent}
                  onDeleteEvent={onDeleteEvent}
                />
              </div>
            ) : (
              timelineStop?.timeline_events?.map((timelineEvent: ShipmentTimelineEvent, eventIndex: number) => (
                <div className="flex" key={eventIndex}>
                  <TimelineIcon
                    showFlag={showFlag(timelineStop)}
                    isLineHidden={canHideVerticalLine(stopIndex, eventIndex, timelineStop)}
                    iconToShow={
                      isNumber(iconNumberValue)
                        ? `Num${iconNumberValue}Filled`
                        : mapEventTypeWithIcon(timelineEvent?.event_type)
                    }
                  />
                  <TimelineCard
                    timelineEvents={[timelineEvent]}
                    shipmentId={shipmentId}
                    onUpdateEvent={onUpdateEvent}
                    onDeleteEvent={onDeleteEvent}
                  />
                </div>
              ))
            )}
          </div>
        );
      })}
    </>
  );
}

export default Timeline;
