import {GenesisRatingSchemasRatingRateRequest} from '@shipwell/genesis-sdk';
import {QueryKey, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {ComponentType, useState} from 'react';
import {wrapDisplayName} from 'recompose';
import {listRateRequests} from 'App/api/genesis/typed';
import {SHIPMENT_RATE_REQUESTS} from 'App/data-hooks/queryKeys';

export const useGetParcelRateRequestIdByShipmentId = ({
  queryOptions,
  shipmentId
}: {
  shipmentId?: string;
  queryOptions?: Omit<
    UseQueryOptions<GenesisRatingSchemasRatingRateRequest | null | undefined>,
    'queryFn' | 'queryKey'
  >;
}) => {
  const getMostRecentRate = (rates: GenesisRatingSchemasRatingRateRequest[]) => {
    if (!rates.length) return null;

    return rates.sort((a, b) => {
      const aDate = new Date(a.created_at);
      const bDate = new Date(b.created_at);

      return bDate.getTime() - aDate.getTime();
    })[0];
  };

  const {enabled = true, ...restOptions} = queryOptions || {};

  const {data, isInitialLoading, isLoading, isFetched} = useQuery({
    queryKey: [SHIPMENT_RATE_REQUESTS, shipmentId] as QueryKey,
    queryFn: async () => {
      const ratesByShipmentId = await listRateRequests({shipmentId});
      const rates = getMostRecentRate(ratesByShipmentId.data);
      return rates;
    },
    enabled: !!shipmentId && enabled,
    notifyOnChangeProps: ['data'],
    ...restOptions
  });

  return {
    rateRequestIdByShipmentId: data?.id,
    isLoadingParcelRates: isInitialLoading,
    noParcelRatesOnShipment: !isLoading && isFetched && !data,
    parcelRateRequest: data
  };
};

export const withGetParcelRateRequestIdByShipmentId = <PropsT,>(Component: ComponentType<PropsT>) => {
  const ComponentWithGetParcelRateRequestIdByShipmentId = (props: PropsT) => {
    const [shipmentIdToFetchRateRequest, setShipmentIdToFetchRateRequest] = useState<string | undefined>();
    const getParcelRateRequestByShipmentId = useGetParcelRateRequestIdByShipmentId({
      shipmentId: shipmentIdToFetchRateRequest
    });

    return (
      <Component
        {...props}
        setShipmentIdToFetchRateRequest={setShipmentIdToFetchRateRequest}
        getParcelRateRequestByShipmentId={getParcelRateRequestByShipmentId}
      />
    );
  };

  ComponentWithGetParcelRateRequestIdByShipmentId.displayName = wrapDisplayName(
    Component,
    'withGetParcelRateRequestIdByShipmentId'
  );
  return ComponentWithGetParcelRateRequestIdByShipmentId;
};
