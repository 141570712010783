/* global google */
import _ from 'lodash';
import {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Formik, Form, Field} from 'formik';
import {object, string, mixed} from 'yup';
import {Radio} from 'react-bootstrap';
import {DeprecatedButton, FormikTextInput, FormikSelect} from '@shipwell/shipwell-ui';
import {Country, State} from 'country-state-city';
import {states, provinces, mexicoStates} from './utils';
// eslint-disable-next-line import/no-namespace
import * as actions from 'App/actions/shipments';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import RenderMap from 'App/components/RenderMap';

@connect((state) => ({}), actions)
export default class AddressLookup extends Component {
  static defaultProps = {
    onChange: () => {},
    types: ['geocode', 'establishment']
  };

  constructor(props) {
    super(props);

    let value = props.value;
    let isValid;

    if ((value === '' || !value) && Boolean(props.addr)) {
      // use props.addr as a fallback for props.value
      if (props.addr && props.addr.formatted_address) {
        //when loading from state/database, the object is not within a nested obj
        value = props.addr.formatted_address;
        isValid = true;
      } else {
        value = '';
      }
    }

    this.state = {
      value,
      isValid,
      showWarningModal: false,
      warnings: [],
      geocoded_address: {},
      provided_formatted_address: '',
      markerArray: [],
      mapBounds: null,
      componentForm: {
        address_1: null,
        address_2: null,
        city: null,
        state_province: null,
        postal_code: null,
        country: null,
        latitude: null,
        longitude: null
      },
      manualAddressError: null,
      errorFields: []
    };

    this.onPlaceChange = this.onPlaceChange.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleWarning = this.handleWarning.bind(this);
    this.getStateOptions = this.getStateOptions.bind(this);
    this.formatAddressString = this.formatAddressString.bind(this);
    this.getStatesListFromCountry = this.getStatesListFromCountry.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.addr !== nextProps.addr && Object.keys(nextProps.addr).length) {
      if (nextProps.addr) {
        this.setState({
          value: nextProps.addr.formatted_address,
          isValid: !!nextProps.addr.formatted_address
        });
        this.forceUpdate();
      }
    } else if (this.props.addr !== nextProps.addr && Object.keys(nextProps.addr).length === 0) {
      this.setState({value: '', isValid: false});
    }
    if (this.props.value !== nextProps.value) {
      this.setState({value: nextProps.value});
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.value !== this.state.value) {
      if (nextState.value === '') {
        this.props.onChange({}, '');
      }
    }
  }

  formatAddressString(address) {
    const {address_1 = '', address_2, city, state_province = '', postal_code, country = ''} = address;

    let formattedAddress = '';
    if (address_1) {
      formattedAddress += `${address_1}, `;
    }
    if (address_2) {
      formattedAddress += `${address_2}, `;
    }
    if (city) {
      formattedAddress += `${city}, `;
    }
    if (state_province) {
      formattedAddress += `${state_province.value || state_province}, `;
    }
    if (postal_code) {
      formattedAddress += `${postal_code}, `;
    }
    if (country) {
      formattedAddress += `${country.value || country}`;
    }
    return formattedAddress;
  }

  handleWarning(addressObj) {
    const {allowZipOnly} = this.props;
    const {geocoded_address, provided_formatted_address} = addressObj;

    if (!allowZipOnly || (allowZipOnly && geocoded_address && geocoded_address.address_1)) {
      /**
       * Don't show the warning if zip Only is allowed, since in most cases it isn't really a problem,
       * just set the values if an address1 is sent in, however, chances are we do want to show the warning
       */
      this.setState({
        showWarningModal: true,
        selectedAddressOption: 'new',
        warnings: addressObj.warnings,
        provided_formatted_address: provided_formatted_address,
        geocoded_address: geocoded_address,
        markerArray: [
          {
            show: true,
            position: {
              lat: geocoded_address.latitude,
              lng: geocoded_address.longitude
            },
            info: `<p>${geocoded_address.formatted_address}</p>`,
            label: null
          }
        ]
      });
    }
  }

  handleError() {
    this.props.toggleShowManualAddressModal(true);
  }

  handleAddressSelection() {
    const {selectedAddressOption, geocoded_address} = this.state;

    const statesAndProvinces = this.getStatesListFromCountry(geocoded_address.country);

    if (selectedAddressOption === 'original') {
      // @todo: fill in any components that are available from the previous lookup, including the lat/lng
      if (geocoded_address) {
        this.setState({
          markerArray: [],
          componentForm: {
            address_1: geocoded_address.address_1,
            address_2: geocoded_address.address_2,
            postal_code: geocoded_address.postal_code,
            city: geocoded_address.city,
            state_province: statesAndProvinces.find(
              (stateOrProvince) => stateOrProvince.value === geocoded_address.state_province
            ),
            country: {
              label: Country.getCountryByCode(geocoded_address.country).name,
              value: Country.getCountryByCode(geocoded_address.country).isoCode
            }
          },
          showWarningModal: false
        });
        this.props.toggleShowManualAddressModal(true);

        if (geocoded_address.postal_code) {
          // Drop the pin on the zip that was used
          this.handlePostalCodeLookup(geocoded_address.postal_code);
        }
      } else {
        this.setState({
          markerArray: [],
          showWarningModal: false
        });
        this.props.toggleShowManualAddressModal(true);
      }
    } else {
      // They used the suggested address - close the modal
      this.handleShowWarningModal(false);
    }
  }

  async onPlaceChange(address, input) {
    const {ignoreFieldWarnings, ignoreWarnings, customValidateAddress} = this.props;
    if (!address || !address.value.formatted_address) {
      this.setState({value: '', isValid: false});
      return this.props.onChange({}, '');
    }

    const place = {
      formatted_address: address.value.formatted_address || address.value.description
    };

    // TriumphPay needs to call a separate validating endpoint.
    const validator = customValidateAddress ? customValidateAddress : this.props.validateAddress;

    /** if address from Google AutoSuggest */
    if (place.formatted_address) {
      this.setState({value: place.formatted_address});

      try {
        const response = await validator(place.formatted_address);
        const {status, details} = response;
        if (status === 200) {
          //if there are field-specific warnings, ignore those that we don't care about and proceed
          const warningsToShow = Object.keys(details.field_warnings).filter(
            (e) => ignoreFieldWarnings && !ignoreFieldWarnings.includes(e)
          );
          if (warningsToShow > 0 && !ignoreWarnings) {
            this.setState({value: details.geocoded_address.formatted_address, isValid: true});

            this.props.onChange(details.geocoded_address, details.geocoded_address.formatted_address, input);

            if (details.warnings && details.warnings.length && !ignoreWarnings) {
              this.handleWarning(details);
            }
          } else if (ignoreFieldWarnings?.length > 0 && !ignoreWarnings) {
            //ensure we clear out any fields that might have changed during validate if they should be ignored
            ignoreFieldWarnings.forEach((field) => {
              delete details.geocoded_address[field];
            });
            const formattedAddress = this.formatAddressString(details.geocoded_address);

            this.setState({value: formattedAddress, isValid: true});
            details.geocoded_address.formatted_address = formattedAddress;
            this.props.onChange(details.geocoded_address, formattedAddress, input);
          } else {
            //proceed with whatever was entered
            this.props.onChange(details.geocoded_address, details.geocoded_address.formatted_address, input);
          }
        } else {
          this.setState({value: 'Address not found.'});
          this.handleError();
        }
      } catch (error) {
        this.setState({value: 'Address not found.'});
        this.handleError();
      }
    } else {
      //long list of conditions to prevent accidentally searching when we shouldnt
      if (
        place.name !== 'Address not found.' &&
        place.name !== '' &&
        !this.props.preventAddressSearch &&
        (!this.props.addr ||
          _.isEmpty(this.props.addr) ||
          this.props.addr.error === true ||
          (this.props.addr && !this.props.addr.formatted_address && this.props.addr !== place.name) ||
          (this.props.addr && this.props.addr.formatted_address && place.name !== this.props.addr.formatted_address))
      ) {
        //autocomplete was not selected, user tabbed off or hit enter
        try {
          const response = await validator(place.name);
          if (response.status === 200) {
            if (response.details.warnings && response.details.warnings.length > 0) {
              this.setState({
                value: response.details.geocoded_address.formatted_address
              });
              //still save this value even with a warning
              this.props.onChange(
                response.details.geocoded_address,
                response.details.geocoded_address.formatted_address,
                input
              );
              this.handleWarning(response.details);
            } else {
              this.setState({
                value: response.details.geocoded_address.formatted_address,
                isValid: true
              });
              this.props.onChange(
                response.details.geocoded_address,
                response.details.geocoded_address.formatted_address,
                input
              );
            }
          } else {
            //error
            if (response.details) {
              this.setState({value: 'Address not found.'});
              this.handleError();
            } else {
              this.setState({value: 'Address not found.'});
              this.handleError();
            }
          }
        } catch (error) {
          this.setState({value: 'Address not found.'});
          this.handleError();
        }
      }
    }
  }

  renderWarningModal() {
    return (
      <div>
        <p className="error-text-form-level text-left">
          <i className="icon icon-Delayed pad-right" />
          {this.state.warnings[0]}
        </p>
        <div className="addressLookup__option">
          <p> You entered: </p>
          <Radio
            name="select_address"
            value="original"
            onChange={() => this.setState({selectedAddressOption: 'original'})}
            checked={this.state.selectedAddressOption === 'original'}
          >
            {this.state.provided_formatted_address}{' '}
          </Radio>
        </div>
        <div className="addressLookup__option">
          <p> We found: </p>
          <Radio
            name="select_address"
            value="new"
            onChange={() => this.setState({selectedAddressOption: 'new'})}
            checked={this.state.selectedAddressOption === 'new'}
          >
            {this.state.geocoded_address.formatted_address}{' '}
          </Radio>
        </div>
        <div className="addressLookup__mapContainer">
          <RenderMap markerArray={this.state.markerArray} mapBounds={this.state.mapBounds} mapID="mapLookup" />
        </div>
      </div>
    );
  }

  getStateOptions(country) {
    if (country && country === 'US') {
      return states.map((s) => {
        return {label: s.name, value: s.id};
      });
    }
    if (country && country === 'CA') {
      return provinces.map((p) => {
        return {label: p.name, value: p.id};
      });
    }
    if (country && country === 'MX') {
      return mexicoStates.map((ms) => {
        return {label: ms.name, value: ms.id};
      });
    }
    return states
      .map((s) => {
        return {label: s.name, value: s.id};
      })
      .concat(
        provinces.map((p) => {
          return {label: p.name, value: p.id};
        }),
        mexicoStates.map((ms) => {
          return {label: ms.name, value: ms.id};
        })
      );
  }

  getStatesListFromCountry(countryId = 'US') {
    return State.getStatesOfCountry(countryId).map((state) => ({label: state.name, value: state.isoCode}));
  }

  formatAddressFieldItem(addressField) {
    return addressField ? `${addressField},` : '';
  }

  renderAddressComponentModal() {
    const AddressSchema = object().shape(
      {
        address_1: string().nullable(),
        address_2: string().nullable(),
        city: string().nullable(),
        state_province: mixed().nullable(),
        postal_code: mixed().nullable(),
        country: mixed().required('Country is required.'),
        latitude: mixed().when('longitude', {
          is: (longitude) => !!longitude,
          then: mixed().required('Latitude is required.'),
          otherwise: mixed().nullable()
        }),
        longitude: mixed().when('latitude', {
          is: (latitude) => !!latitude,
          then: mixed().required('Longitude is required.'),
          otherwise: mixed().nullable()
        })
      },
      ['latitude', 'longitude']
    );
    const {markerArray} = this.state;
    const countriesList = Country.getAllCountries().map((country) => {
      return {
        label: country.name,
        value: country.isoCode
      };
    });

    return (
      <div>
        <p>Please confirm the components of this address in the form below.</p>
        <Formik
          validationSchema={AddressSchema}
          onSubmit={(values) => {
            const {address_1 = '', address_2, city, state_province, postal_code, country, latitude, longitude} = values;

            const address1 = this.formatAddressFieldItem(address_1);
            const address2 = this.formatAddressFieldItem(address_2);
            const cityName = this.formatAddressFieldItem(city);
            const stateOrProvince = this.formatAddressFieldItem(state_province?.value);
            const postalCode = this.formatAddressFieldItem(postal_code);
            const latValue = this.formatAddressFieldItem(latitude);
            const lonValue = this.formatAddressFieldItem(longitude);

            const formattedAddress =
              `${address1} ${address2} ${cityName} ${stateOrProvince} ${postalCode} ${latValue} ${lonValue} ${country.value}`.trim();

            const addressToSave = {
              formatted_address: formattedAddress,
              geocoded_address: {
                country: country.value,
                formatted_address: formattedAddress,
                address_1,
                address_2,
                city,
                state_province: state_province?.value || null,
                postal_code,
                latitude,
                longitude
              }
            };

            if (markerArray.length) {
              addressToSave.geocoded_address.latitude = markerArray[0].position.lat;
              addressToSave.geocoded_address.longitude = markerArray[0].position.lng;
            }
            this.setState({
              value: formattedAddress
            });
            this.props.toggleShowManualAddressModal(false);

            this.props.onChange(addressToSave.geocoded_address, formattedAddress);
          }}
          initialValues={this.state.componentForm}
          render={({errors = {}, handleBlur, values, submitForm, isSubmitting}) => (
            <Form className="addressLookup__componentForm">
              <div className="flex flex-col gap-y-2">
                <Field
                  error={errors.country}
                  errorMessage={errors.country}
                  component={FormikSelect}
                  clearable
                  options={countriesList}
                  name="country"
                  label="Country"
                />
                <Field
                  error={errors.address_1}
                  errorMessage={errors.address_1}
                  component={FormikTextInput}
                  name="address_1"
                  label="Street Address"
                />
                <Field
                  error={errors.address_2}
                  errorMessage={errors.address_2}
                  component={FormikTextInput}
                  name="address_2"
                  label="Suite, Apt., Dock, etc."
                />
                <Field
                  error={errors.state_province}
                  errorMessage={errors.state_province}
                  component={FormikSelect}
                  clearable
                  options={this.getStatesListFromCountry(values?.country?.value)}
                  name="state_province"
                  label={values.country && values.country.value === 'CA' ? 'Province' : 'State'}
                />
                <Field
                  error={errors.city}
                  errorMessage={errors.city}
                  component={FormikTextInput}
                  name="city"
                  label="City"
                />
                <Field
                  error={errors.postal_code}
                  errorMessage={errors.postal_code}
                  component={FormikTextInput}
                  name="postal_code"
                  label="Postal Code"
                  onBlur={(e) => {
                    handleBlur(e);
                    this.handlePostalCodeLookup.bind(this);
                  }}
                />
                <Field
                  error={errors.latitude}
                  errorMessage={errors.latitude}
                  component={FormikTextInput}
                  name="latitude"
                  label="Latitude"
                />
                <Field
                  error={errors.longitude}
                  errorMessage={errors.longitude}
                  component={FormikTextInput}
                  name="longitude"
                  label="Longitude"
                />

                <div className="formSection__footer">
                  <DeprecatedButton onClick={submitForm} disabled={isSubmitting}>
                    Use This Address
                  </DeprecatedButton>
                </div>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }

  /**
   * Move marker based on postal code
   * @param  {Object|String} code Event object or postal code string
   */
  handlePostalCodeLookup(code) {
    if (typeof code !== 'string') {
      code = code.target.value;
    }
    this.props.getLatLngByPostalCode(code).then((resp) => {
      const markerArray = [];

      if (resp.status === 200) {
        const {latitude, longitude} = resp.details.geocoded_address;
        const marker = {
          show: true,
          label: null,
          position: {lat: latitude, lng: longitude}
        };

        markerArray.push(marker);
      }

      this.setState({markerArray: markerArray});
    });
  }

  handleShowWarningModal(visible = true) {
    this.setState({showWarningModal: visible});
  }

  handleShowAddressModal(visible = true) {
    this.props.toggleShowManualAddressModal(visible);
  }

  render() {
    const {showWarningModal, value, isValid} = this.state;
    const {showManualAddressModal} = this.props;
    return (
      <Fragment>
        <InfoModalWrapper
          show={showWarningModal}
          title="Confirm Address Details"
          extraClass="addressLookup__overrideZIndex"
          primaryAction={{
            label: 'Use Selected Address',
            action: this.handleAddressSelection.bind(this)
          }}
          onHide={this.handleShowWarningModal.bind(this, false)}
        >
          {this.renderWarningModal()}
        </InfoModalWrapper>

        <InfoModalWrapper
          show={showManualAddressModal}
          title="Confirm Address Details"
          extraClass="addressLookup__overrideZIndex"
          onHide={this.handleShowAddressModal.bind(this, false)}
        >
          {this.renderAddressComponentModal()}
        </InfoModalWrapper>

        {this.props.children({
          id: 'location',
          ref: 'input',
          tabIndex: this.props.tabIndex,
          className: this.props.className,
          isValid: isValid,
          onChange: this.onPlaceChange,
          value: value || ''
        })}
      </Fragment>
    );
  }
}
