import {useMutation} from '@tanstack/react-query';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import {v4 as uuid} from 'uuid';
import type {
  Shipment,
  CreateContact,
  CreateShipment,
  UpdateShipmentSummary,
  UpdateContactDetail,
  UpdateShipmentReferences,
  UpdateStop,
  DrayageShipmentModeSpecificData
} from '@shipwell/corrogo-sdk';
import {
  createShipment,
  updateShipmentSummary,
  createShipmentContact,
  deleteShipmentContact,
  updateShipmentContact,
  updateShipmentReferences,
  updateShipmentStop,
  updateShipmentStops,
  updateDrayageSpecificData,
  deleteShipmentStop
} from 'App/api/corrogo/typed';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

function isCreateShipment(shipment: CreateShipment | Shipment): shipment is CreateShipment {
  return !('id' in shipment);
}

export default function useUpdateShipment(shipmentId: string) {
  const createShipmentMutation = useMutation<
    Awaited<ReturnType<typeof createShipment>>,
    unknown,
    {data: CreateShipment}
  >(({data}) => createShipment(data));

  const createUpdateShipmentSummaryOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; data: UpdateShipmentSummary}
  >();

  const updateShipmentSummaryMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentSummary>>,
    unknown,
    {shipmentId: string; data: UpdateShipmentSummary}
  >(
    ({shipmentId, data}) => updateShipmentSummary(shipmentId, data),
    createUpdateShipmentSummaryOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId])
  );

  const createCreateShipmentRepOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; data: CreateContact}
  >();
  const createShipmentRepMutation = useMutation<
    Awaited<ReturnType<typeof createShipmentContact>>,
    unknown,
    {shipmentId: string; data: CreateContact}
  >(
    ({shipmentId, data}) => createShipmentContact(shipmentId, data),
    createCreateShipmentRepOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      mergeData: (shipment, variables) => {
        if (!shipment || !variables) {
          return;
        }
        const createdContact = {...variables.data, id: uuid()};
        return {...shipment, contacts: shipment.contacts ? [...shipment.contacts, createdContact] : [createdContact]};
      }
    })
  );

  const createUpdateShipmentRepOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; contactId: string; data: UpdateContactDetail}
  >();
  const updateShipmentRepMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentContact>>,
    unknown,
    {shipmentId: string; contactId: string; data: UpdateContactDetail}
  >(
    ({shipmentId, contactId, data}) => updateShipmentContact(shipmentId, contactId, data),
    createUpdateShipmentRepOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      mergeData: (shipment, variables) => {
        if (!shipment || !variables) {
          return;
        }
        const {contactId, data} = variables;
        return {
          ...shipment,
          contacts: (shipment.contacts || []).map((contact) =>
            contact.id === contactId ? {...data, id: contactId} : contact
          )
        };
      }
    })
  );

  const createDeleteShipmentRepOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; contactId: string}
  >();
  const deleteShipmentRepMutation = useMutation<
    Awaited<ReturnType<typeof deleteShipmentContact>>,
    unknown,
    {shipmentId: string; contactId: string}
  >(
    ({shipmentId, contactId}) => deleteShipmentContact(shipmentId, contactId),
    createDeleteShipmentRepOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      mergeData: (shipment, variables) => {
        if (!shipment || !variables) {
          return;
        }

        return {
          ...shipment,
          contacts: (shipment.contacts || []).filter((contact) => contact.id !== variables.contactId)
        };
      }
    })
  );

  const createUpdateShipmentReferencesOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; data: UpdateShipmentReferences}
  >();
  const updateShipmentReferencesMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentReferences>>,
    unknown,
    {shipmentId: string; data: UpdateShipmentReferences}
  >(
    ({shipmentId, data}) => updateShipmentReferences(shipmentId, data),
    createUpdateShipmentReferencesOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId])
  );

  const createUpdateShipmentStopOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; sequenceId: number; data: UpdateStop}
  >();
  const updateShipmentStopMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentStop>>,
    unknown,
    {shipmentId: string; sequenceId: number; data: UpdateStop}
  >(
    ({shipmentId, sequenceId, data}) => updateShipmentStop(shipmentId, sequenceId, data),
    createUpdateShipmentStopOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      mergeData: (shipment, variables) => {
        if (!shipment || !variables) {
          return;
        }
        return {
          ...shipment,
          stops: (shipment.stops || []).map((stop) =>
            stop.sequence_number === variables.data.sequence_number ? {...stop, ...variables.data} : stop
          )
        };
      }
    })
  );

  const createUpdateShipmentStopsOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; data: {stops: UpdateStop[]}}
  >();
  const updateShipmentStopsMutation = useMutation<
    Awaited<ReturnType<typeof updateShipmentStops>>,
    unknown,
    {shipmentId: string; data: {stops: UpdateStop[]}}
  >(
    ({shipmentId, data}) => updateShipmentStops(shipmentId, data),
    createUpdateShipmentStopsOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId])
  );

  const createUpdateDrayageSpecificDataOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; data: DrayageShipmentModeSpecificData}
  >();
  const updateDrayageSpecificDataMutation = useMutation<
    Awaited<ReturnType<typeof updateDrayageSpecificData>>,
    unknown,
    {shipmentId: string; data: DrayageShipmentModeSpecificData}
  >(
    ({shipmentId, data}) => updateDrayageSpecificData(shipmentId, data),
    createUpdateDrayageSpecificDataOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      mergeData: (shipment, variables) => {
        if (!shipment || !variables) {
          return;
        }
        return {
          ...shipment,
          mode_specific_data: [variables.data]
        };
      }
    })
  );

  async function createOrUpdateShipmentAsync(payload: CreateShipment | Shipment) {
    if (isNil(shipmentId) && isCreateShipment(payload)) {
      const {data} = await createShipmentMutation.mutateAsync({data: payload});
      return data?.id;
    }
    await Promise.allSettled([
      updateShipmentSummaryMutation.mutateAsync({
        shipmentId,
        data: omit(payload, 'stops', 'contacts', 'references')
      }),
      updateShipmentStopsMutation.mutateAsync({shipmentId, data: {stops: (payload.stops as UpdateStop[]) || []}}),
      updateShipmentReferencesMutation.mutateAsync({shipmentId, data: pick(payload, 'references')})
    ]);
    return shipmentId;
  }

  const deleteShipmentStopOptimisticUpdateHandlers = useOptimisticUpdate<
    Shipment,
    unknown,
    {shipmentId: string; sequenceId: number}
  >();
  const deleteShipmentStopMutation = useMutation<
    Awaited<ReturnType<typeof deleteShipmentStop>>,
    unknown,
    {shipmentId: string; sequenceId: number}
  >(
    ({shipmentId, sequenceId}) => deleteShipmentStop(shipmentId, sequenceId),
    deleteShipmentStopOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      mergeData: (shipment, variables) => {
        if (!shipment || !variables) {
          return;
        }
        return {
          ...shipment,
          stops: (shipment.stops || []).filter((stop) => stop.sequence_number !== variables.sequenceId)
        };
      }
    })
  );

  return {
    updateShipmentSummaryMutation,
    createShipmentRepMutation,
    updateShipmentRepMutation,
    deleteShipmentRepMutation,
    updateShipmentReferencesMutation,
    updateShipmentStopMutation,
    updateDrayageSpecificDataMutation,
    createOrUpdateShipmentAsync,
    updateShipmentStopsMutation,
    deleteShipmentStopMutation,

    isLoading:
      updateShipmentSummaryMutation.isLoading ||
      createShipmentRepMutation.isLoading ||
      updateShipmentRepMutation.isLoading ||
      deleteShipmentRepMutation.isLoading ||
      updateShipmentReferencesMutation.isLoading ||
      updateShipmentStopMutation.isLoading ||
      updateShipmentStopsMutation.isLoading ||
      updateDrayageSpecificDataMutation.isLoading ||
      createShipmentMutation.isLoading ||
      deleteShipmentStopMutation.isLoading
  };
}
