import {Field} from 'redux-form';
import {Button} from 'react-bootstrap';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderField from 'App/formComponents/renderField';
import Scroll from 'react-scroll';
var Element = Scroll.Element;

const renderMultipleIdentifyingCodes = ({fields, identifyingCodes, readOnly, meta: {touched, error}, ...rest}) => (
  <div>
    {fields.map((code, index) => (
      <div key={index} className="companyForm__inlineFields">
        <Element name={`position-identifying_codes[${index}].type`} />
        <Element name={`position-identifying_codes[${index}].value`} />
        <Field
          label="Type"
          name={`${code}.type`}
          component={renderDropDownSelector}
          data={identifyingCodes}
          placeholder="Select"
          disabled={readOnly}
        />
        <Field
          label="Value"
          name={`${code}.value`}
          component={renderField}
          placeholder="e.g., 2918604"
          disabled={readOnly}
        />
        {!readOnly && (
          <DeprecatedButton
            variant="icon"
            title="Remove this code"
            className="showAsLink action-link"
            onClick={() => {
              fields.remove(index);
            }}
          >
            <SvgIcon name="TrashOutlined" />
          </DeprecatedButton>
        )}
      </div>
    ))}

    {(!fields || fields.length === 0) && <p>No identifying codes added.</p>}
    {error && (
      <p className="error-text-form-level pad-top">
        <i className="icon icon-Delayed pad-right" />
        {error}
      </p>
    )}
    <div className="pad-top text-right">
      <Button bsStyle="default" type="button" onClick={() => fields.push({})}>
        <i className="flaticon-plus pad-right" /> Add Code
      </Button>
    </div>
  </div>
);

export default renderMultipleIdentifyingCodes;
