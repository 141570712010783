import {useMemo} from 'react';
import {useQuery, useQueries} from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import {DrayageBooking, DrayageLegOverallStatus, StopExecutionStatus} from '@shipwell/corrogo-sdk';
import {Accessorial, Hazmat} from '@shipwell/backend-core-sdk';
import get from 'lodash/get';
import {formatDateToMMDDYY} from 'App/utils/dateTimeGlobals';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {DRAYAGE_QUERY_KEY, DRAYAGE_LEG_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {
  referenceTypes,
  contacts as contactsConstants,
  shipmentDirections,
  accessorialsEnumMap,
  transformReferencesToDisplayValues,
  getReferenceValue
} from 'App/containers/shipments/utils/constants';
import {getDrayageLeg, getDrayageLegDocuments} from 'App/api/corrogo/typed';
import useFilteredAccessorials from 'App/data-hooks/mdm/useFilteredAccessorials';
import useHazmatCodes from 'App/data-hooks/mdm/useHazmatCodes';
import {
  transformContainerInfoToDisplayValues,
  transformStopsToDisplayValues,
  transformDrayageLegItemsToFormValues,
  isContainerLegItem,
  transformStopToFormValues,
  transformStopsToFormValues,
  getPickupAndDeliveryStops,
  getStopInfo,
  createSequenceNumberStopTypeAccessor,
  createStopByTypeAccessor
} from 'App/containers/shipments/utils/typed';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {convertKilometersToMiles} from 'App/utils/internationalConstants';
import {PickupStopType} from 'App/containers/shipments/components/StopsFields/StopTypeContactsFields';
import {StopTypeId} from 'App/containers/shipments/components/StopsFields/constants';

const transformLegInfoToDisplayValues = (legDetails?: DrayageBooking, drayageAccessorials?: Accessorial[]) => {
  const exportInfo = legDetails?.export_info;
  const isImport = isNull(exportInfo);

  const pickupStopTitle = isImport ? 'Pickup' : 'Load Location';

  const baseCutDate = exportInfo?.cut_date;
  const cutDate = baseCutDate ? formatDateToMMDDYY(baseCutDate) : '--';

  const importInfo = legDetails?.import_info;
  const baseLastFreeDay = importInfo?.last_free_day;
  const lastFreeDay = baseLastFreeDay ? formatDateToMMDDYY(baseLastFreeDay) : '--';

  const baseReleaseDate = importInfo?.release_date;
  const releaseDate = baseReleaseDate ? formatDateToMMDDYY(baseReleaseDate) : '--';

  const baseReturnDate = exportInfo?.return_date;
  const returnDate = baseReturnDate ? formatDateToMMDDYY(baseReturnDate) : '--';

  const contacts = legDetails?.contacts;
  const thirdPartyContact = contacts?.find((contact) => contact.contact_type === contactsConstants.OTHER);

  const shipmentName = legDetails?.name || '--';

  const legAccessorials = legDetails?.accessorials;
  const legAccessorialsDescriptions = legAccessorials?.map(
    (legAccessorial) =>
      drayageAccessorials?.find(
        (drayageAccessorial) => drayageAccessorial.code === get(accessorialsEnumMap, legAccessorial)
      )?.description
  );
  const legAccessorialsDisplay =
    legAccessorialsDescriptions && legAccessorialsDescriptions?.length > 0
      ? legAccessorialsDescriptions.join(', ')
      : '--';

  const status = startCaseToLower(legDetails?.overall_status).toUpperCase();

  const totalMiles = convertKilometersToMiles(legDetails?.total_kilometers || 0, 2) as string;

  const inGate = legDetails?.in_gate?.processing?.end;
  const formattedInGate = inGate ? formatDateTime(inGate) : '--';

  const outGate = legDetails?.out_gate?.processing?.end;
  const formattedOutGate = outGate ? formatDateTime(outGate) : '--';

  return {
    isImport,
    cutDate,
    lastFreeDay,
    releaseDate,
    returnDate,
    thirdPartyContact,
    shipmentName,
    legAccessorialsDisplay,
    status,
    totalMiles,
    pickupStopTitle,
    formattedInGate,
    formattedOutGate
  };
};

function transformDrayageLegToFormValues(drayageLeg: DrayageBooking, drayageAccessorials: Accessorial[]) {
  const bolNumber = getReferenceValue(referenceTypes.BOL_NUMBER, drayageLeg);
  const houseBolNumber = getReferenceValue(referenceTypes.HOUSE_BOL_NUMBER, drayageLeg);
  const bookingNumber = getReferenceValue(referenceTypes.BOOKING_NUMBER, drayageLeg);
  const direction = drayageLeg?.export_info
    ? shipmentDirections.EXPORT
    : drayageLeg?.import_info
    ? shipmentDirections.IMPORT
    : bolNumber !== '' || houseBolNumber !== ''
    ? shipmentDirections.IMPORT
    : houseBolNumber !== ''
    ? shipmentDirections.EXPORT
    : null;

  const importInfo = drayageLeg?.import_info;
  const lastFreeDay = importInfo?.last_free_day;
  const releaseDate = importInfo?.release_date;

  const exportInfo = drayageLeg?.export_info;
  const returnDate = exportInfo?.return_date;
  const cutDate = exportInfo?.cut_date;

  return {
    customer: null,
    name: drayageLeg?.name || null,
    direction,
    accessorials: (drayageLeg?.accessorials || []).map((accessorial) =>
      drayageAccessorials.find(
        (drayageAccessorial) => drayageAccessorial.code === get(accessorialsEnumMap, accessorial)
      )
    ),
    booking_number: bookingNumber,
    bol_number: bolNumber,
    house_bol_number: houseBolNumber,
    customer_reference_number: getReferenceValue(referenceTypes.CUSTOMER_REFERENCE_NUMBER, drayageLeg),
    po_number: getReferenceValue(referenceTypes.PO_NUMBER, drayageLeg),
    pickup: transformStopToFormValues(drayageLeg.stops?.[0]),
    delivery: transformStopToFormValues(drayageLeg.stops?.[1]),
    last_free_day: lastFreeDay,
    release_date: releaseDate,
    return_date: returnDate,
    cut_date: cutDate
  };
}

function useAncillaryLegData() {
  const {
    accessorials: {drayageShipment: drayageAccessorials},
    getFilteredAccessorialsQuery
  } = useFilteredAccessorials();

  const {
    getHazmatOptionFromId,
    isLoading: hazmatCodesQueryIsLoading,
    isSuccess: hazmatCodesQueryIsSuccess,
    isError: hazmatCodesQueryIsError,
    isFetching: hazmatCodesQueryIsFetching
  } = useHazmatCodes();

  return {
    isLoading: getFilteredAccessorialsQuery.isInitialLoading || hazmatCodesQueryIsLoading,
    isSuccess: getFilteredAccessorialsQuery.isSuccess && hazmatCodesQueryIsSuccess,
    isError: getFilteredAccessorialsQuery.isError || hazmatCodesQueryIsError,
    isFetching: getFilteredAccessorialsQuery.isFetching || hazmatCodesQueryIsFetching,
    context: {
      drayageAccessorials,
      getHazmatOptionFromId
    }
  };
}

async function fetchDrayageLeg(legId: string) {
  const response = await getDrayageLeg(legId);
  return response.data;
}

function convertDrayageLegToContext(
  {
    drayageAccessorials,
    getHazmatOptionFromId
  }: {drayageAccessorials: Accessorial[]; getHazmatOptionFromId: (id: string) => Hazmat | undefined},
  drayageLeg?: DrayageBooking
) {
  if (!drayageLeg) {
    return {};
  }

  const legInfoDisplayValues = transformLegInfoToDisplayValues(drayageLeg, drayageAccessorials);
  const referenceDisplayValues = transformReferencesToDisplayValues(drayageLeg);

  const stopsDisplayValues = transformStopsToDisplayValues(drayageLeg.stops, legInfoDisplayValues.isImport);

  const legInfoFormValues = transformDrayageLegToFormValues(drayageLeg, drayageAccessorials);
  const containers = drayageLeg.leg_items || [];
  const containerFormValues = transformDrayageLegItemsToFormValues(
    containers.filter(isContainerLegItem),
    getHazmatOptionFromId
  );

  const stops = drayageLeg.stops || [];

  const pickupAndDeliveryStops = getPickupAndDeliveryStops(stops, legInfoDisplayValues.isImport);
  const pickupStopInfo = getStopInfo(
    pickupAndDeliveryStops[0],
    legInfoDisplayValues.isImport ? 'Pickup' : 'Load Location'
  );
  const deliveryStopInfo = getStopInfo(pickupAndDeliveryStops[1], 'Delivery');

  const stopsFormValues = transformStopsToFormValues(drayageLeg.stops);
  const getStopType = createSequenceNumberStopTypeAccessor(drayageLeg.stops, legInfoDisplayValues.isImport);
  const getStopByType = createStopByTypeAccessor(drayageLeg.stops, legInfoDisplayValues.isImport);

  return {
    legInfoDisplayValues,
    referenceDisplayValues,
    legInfoFormValues,
    containerFormValues,
    stopsDisplayValues,
    stopsFormValues,
    pickupStopInfo,
    deliveryStopInfo,
    getStopType,
    getStopByType
  };
}

function useDrayageLeg(legId: string, queryConfig = {}) {
  const drayageLegQuery = useQuery([DRAYAGE_QUERY_KEY, legId], () => fetchDrayageLeg(legId), {
    enabled: !isEmpty(legId),
    staleTime: HOT_DATA_STALE_TIME,
    ...queryConfig
  });

  const drayageLegDocumentsQuery = useQuery(
    [DRAYAGE_LEG_DOCUMENTS_QUERY_KEY, legId],
    async () => {
      const response = await getDrayageLegDocuments(legId);
      return response.data.data;
    },
    {
      enabled: !isEmpty(legId),
      staleTime: HOT_DATA_STALE_TIME,
      ...queryConfig
    }
  );
  const drayageLegDocuments = drayageLegDocumentsQuery.data || [];

  const {
    context: {drayageAccessorials, getHazmatOptionFromId},
    isLoading,
    isSuccess,
    isError,
    isFetching
  } = useAncillaryLegData();

  const context = convertDrayageLegToContext(
    {
      drayageAccessorials,
      getHazmatOptionFromId
    },
    drayageLegQuery?.data
  );

  const containerDisplayValues = useMemo(() => {
    const containers = drayageLegQuery.data?.leg_items || [];
    return transformContainerInfoToDisplayValues(containers.filter(isContainerLegItem));
  }, [drayageLegQuery.data?.leg_items]);

  const getStopStatusAction = (sequenceNumber: number) => {
    const legOverallStatus = drayageLegQuery.data?.overall_status;
    const UNEDITABLE_LEG_STATUSES = [
      DrayageLegOverallStatus.Draft,
      DrayageLegOverallStatus.Cancelled
    ] as DrayageLegOverallStatus[];
    if (legOverallStatus && UNEDITABLE_LEG_STATUSES.includes(legOverallStatus)) {
      return null;
    }

    const getStopByType = context.getStopByType ? context.getStopByType : () => undefined;

    const pickupStop = getStopByType(StopTypeId.Pickup);
    const pickupStopExecutionStatus = pickupStop?.execution_status;

    const deliveryStop = getStopByType(StopTypeId.Delivery);
    const deliveryStopExecutionStatus = deliveryStop?.execution_status;

    const containerPickupStop = getStopByType(StopTypeId.ContainerPickup);
    const containerPickupStopExecutionStatus = containerPickupStop?.execution_status;

    const containerReturnStop = getStopByType(StopTypeId.ContainerReturn);
    const containerReturnStopExecutionStatus = containerReturnStop?.execution_status;

    const stopType = context.getStopType ? context.getStopType(sequenceNumber) : PickupStopType;
    const isPickup = stopType.id === StopTypeId.Pickup;
    const isDelivery = stopType.id === StopTypeId.Delivery;
    const isContainerPickup = stopType.id === StopTypeId.ContainerPickup;
    const isContainerReturn = stopType.id === StopTypeId.ContainerReturn;

    if (
      (isPickup && pickupStopExecutionStatus === StopExecutionStatus.AtStop) ||
      (isDelivery && deliveryStopExecutionStatus === StopExecutionStatus.AtStop)
    ) {
      return {
        label: 'Mark as Departed',
        nextStatus: StopExecutionStatus.Departed
      };
    }

    const isContainerReturnStopPending =
      isContainerReturn &&
      (containerReturnStopExecutionStatus === StopExecutionStatus.EnRoute ||
        containerReturnStopExecutionStatus === StopExecutionStatus.Pending);

    if (isContainerReturnStopPending && deliveryStopExecutionStatus === StopExecutionStatus.Departed) {
      return {
        label: 'Mark as Returned',
        nextStatus: StopExecutionStatus.Departed
      };
    }

    const isContainerPickupStopPending =
      isContainerPickup &&
      (containerPickupStopExecutionStatus === StopExecutionStatus.Pending ||
        containerPickupStopExecutionStatus === StopExecutionStatus.EnRoute);

    if (isContainerPickupStopPending) {
      return {
        label: 'Mark as Picked Up',
        nextStatus: StopExecutionStatus.Departed
      };
    }

    const isContainerPickupStopDeparted =
      containerPickupStop &&
      containerPickupStopExecutionStatus === StopExecutionStatus.Departed &&
      pickupStopExecutionStatus !== StopExecutionStatus.Departed;

    const isPickupStopPending =
      isPickup &&
      (pickupStopExecutionStatus === StopExecutionStatus.Pending ||
        pickupStopExecutionStatus === StopExecutionStatus.EnRoute);

    const isPickupStopDeparted =
      pickupStopExecutionStatus === StopExecutionStatus.Departed &&
      deliveryStopExecutionStatus !== StopExecutionStatus.Departed;

    if (isPickupStopPending || (isPickup && isContainerPickupStopDeparted) || (isDelivery && isPickupStopDeparted)) {
      return {
        label: 'Mark as Arrived',
        nextStatus: StopExecutionStatus.AtStop
      };
    }

    return null;
  };

  return {
    ...drayageLegQuery,
    isLoading: drayageLegQuery.isInitialLoading || isLoading,
    isSuccess: drayageLegQuery.isSuccess && isSuccess,
    isError: drayageLegQuery.isError || isError,
    isFetching: drayageLegQuery.isFetching || isFetching,
    context: {
      ...context,
      containerDisplayValues,
      drayageLegDocuments,
      getStopStatusAction
    }
  };
}

export default useDrayageLeg;

export function useDrayageLegs(legIds: string[]) {
  const legQueries = useQueries({
    queries: legIds.map((legId) => ({
      queryKey: [DRAYAGE_QUERY_KEY, legId],
      queryFn: () => fetchDrayageLeg(legId),
      staleTime: HOT_DATA_STALE_TIME,
      enabled: !!legId
    }))
  });

  const {
    context: {drayageAccessorials, getHazmatOptionFromId},
    isLoading,
    isSuccess,
    isError,
    isFetching
  } = useAncillaryLegData();

  return legQueries.map((drayageLegQuery) => {
    const context = convertDrayageLegToContext(
      {
        drayageAccessorials,
        getHazmatOptionFromId
      },
      drayageLegQuery?.data
    );
    return {
      ...drayageLegQuery,
      isLoading: drayageLegQuery.isInitialLoading || isLoading,
      isSuccess: drayageLegQuery.isSuccess && isSuccess,
      isError: drayageLegQuery.isError || isError,
      isFetching: drayageLegQuery.isFetching || isFetching,
      context
    };
  });
}
