import {RemoteLoadingEventDetail} from './remoteLoadingEventDetail';

export const REMOTE_LOADER_EVENT_NAME = 'DASHBOARD_REMOTE_LOADING';

function getTargetElement() {
  return window.document.body;
}

export function remoteLoading(isLoading: boolean) {
  const event = new CustomEvent<RemoteLoadingEventDetail>(REMOTE_LOADER_EVENT_NAME, {detail: {isLoading}});
  getTargetElement().dispatchEvent(event);
}

export function addEventListener(fn: (evt: CustomEventInit<RemoteLoadingEventDetail>) => void) {
  window.document.body.addEventListener(REMOTE_LOADER_EVENT_NAME, fn);
}

export function removeEventListener(fn: (evt: CustomEventInit<RemoteLoadingEventDetail>) => void) {
  window.document.body.removeEventListener(REMOTE_LOADER_EVENT_NAME, fn);
}
