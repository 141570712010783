import {useState, useMemo, useCallback, useEffect} from 'react';
import {connect, DispatchProp} from 'react-redux';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import {Loader} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {Shipment, Quote} from '@shipwell/backend-core-singlerequestparam-sdk';
import {withRouter, WithRouterProps} from 'react-router';
import {parseZone} from 'moment/moment';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useCreateCarrierConnectionQuoteMutation,
  useConnectionsQuery,
  CarrierConnectionQuote
} from 'App/containers/InstantRatesV2/hooks';
import {
  Table,
  TableRow,
  TableHeader,
  TableContainer,
  TableHeaderSortIcon
} from 'App/components/TypedTable/baseComponents';
import {useTypedTable} from 'App/components/TypedTable/hooks';
import {createInstantRatesTableColumns} from 'App/containers/InstantRatesV2/components/InstantRatesTable/columns';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {Rate} from 'src/@types/quotingTypes';
import {SELECT_QUOTE, SELECT_SHIPMENT_FOR_FORM, CLEAR_SELECTED_QUOTE, SELECT_SHIPMENT} from 'App/actions/types';
import {InstantRatesUpdateModal} from 'App/containers/InstantRatesV2/components/InstantRatesUpdateModal/InstantRatesUpdateModal';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';
import {useUserMe} from 'App/data-hooks';
import {SHIPMENT_CREATE_SPOT_NEGOTIATIONS} from 'App/components/permissions/PermissionsFallback/constants';
import {NewShipmentFormValues} from 'App/containers/Book/FormSections/InstantRatesCardV2';
import {flexRender} from '@tanstack/react-table';
import {TableLoadingBar} from 'App/components/TypedTable/complementaryComponents';
import {EmptyTableSprinkles} from 'App/components/Table/components/EmptyListView';
import {transformShipmentToForm} from 'App/utils/globals';
import useHazmatCodes from 'App/data-hooks/mdm/useHazmatCodes';
import {ShipmentMode} from '@shipwell/genesis-sdk';
import {useShipmentRating} from 'App/containers/InstantRatesV2/hooks/useShipmentRating';
import {ChargeBreakDowns} from 'App/containers/InstantRatesV2/components/InstantRatesTable/components/ChargeBreakDowns/ChargeBreakDowns';

type InjectedInstantRatesTablePropTypes = WithStatusToastProps &
  WithRouterProps &
  DispatchProp<
    | {type: typeof SELECT_QUOTE; payload: Quote & {isGenesisRate: boolean}}
    | {type: typeof SELECT_SHIPMENT_FOR_FORM; payload: ReturnType<typeof transformShipmentToForm>}
    | {type: typeof SELECT_SHIPMENT; payload: Shipment}
    | {type: typeof CLEAR_SELECTED_QUOTE}
  >;

export type InstantRatesTablePropTypes = {
  shipment: Shipment;
  expandedColumnSet?: boolean;
  viewAllColumns?: boolean;
  newShipmentInformation?: NewShipmentFormValues;
  isNewShipmentForm?: boolean;
  onRateSelect?: (rate: Quote) => void;
};

const InstantRatesTableV2 = ({
  setError,
  shipment,
  expandedColumnSet,
  dispatch,
  ...props
}: InstantRatesTablePropTypes & InjectedInstantRatesTablePropTypes) => {
  const [currentShipment, setCurrentShipment] = useState<Shipment>(shipment);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [showInstantRatesUpdateModal, setShowInstantRatesUpdateModal] = useState(false);
  const [rateSelected, setRateSelected] = useState<Rate>();
  const [isAccepting, setIsAccepting] = useState(false);

  // this useEffect is required because the props shipment might change
  useEffect(() => {
    setCurrentShipment(shipment);
  }, [shipment]);

  // get rates
  const {hasPolledRates, rates, isloadingRateInformation, createRatesMutation} = useShipmentRating({
    shipment: currentShipment,
    dispatch,
    setError
  });
  // add expandedContent to each rate that displays rate information
  const rateData = useMemo(
    () =>
      rates?.map((row) => ({
        ...row,
        expandedContent: <ChargeBreakDowns rate={row} />
      })),
    [rates]
  );

  // the eventual success callback after uses selects a rate
  // genesis rates need to perform an intermediate be-core POST whereas legacy rates flow here immediately
  const {hazmatOptions} = useHazmatCodes();
  const handleRateSelectSuccessCallback = useCallback(
    (data: CarrierConnectionQuote) => {
      if (props.onRateSelect) {
        return props.onRateSelect(data.legacyQuote);
      }
      // legacy hold over until we move away from react-redux.
      // this needs to be set for the confirmation page.
      dispatch({
        type: SELECT_QUOTE,
        payload: {...data.legacyQuote, isGenesisRate: data.rate.isGenesisRate}
      });

      const shipmentCopy = cloneDeep(shipment);
      dispatch({
        type: SELECT_SHIPMENT_FOR_FORM,
        payload: transformShipmentToForm(
          shipmentCopy,
          hazmatOptions,
          shipmentCopy.mode,
          [shipmentCopy.equipment_type].filter(Boolean),
          true
        )
      });

      if (!data.rate.isGenesisRate) {
        return props.router.push('/shipments/' + shipment.id + '/confirm?mode=instant-rates');
      }
      return props.router.push(
        '/shipments/' + shipment.id + '/confirm?mode=instant-rates&rateRequestId=' + data.rate.rateRequestId
      );
    },
    [dispatch, hazmatOptions, props, shipment]
  );

  const createCarrierConnectionQuoteMutation = useCreateCarrierConnectionQuoteMutation({
    onSuccess: handleRateSelectSuccessCallback,
    onError: (error) => {
      console.error(error);
      setError('Unknown Error', 'Unable to create carrier connection rate.');
    }
  });

  // handles selecting a rate from the table
  const handleSelect = useCallback(
    (rate: Rate) => {
      if (!rate) {
        // legacy hold over until we remove redux and backend-core from rating
        // this is set so we can't redirect to the confirmtion page.
        dispatch({type: CLEAR_SELECTED_QUOTE});
      }
      if (rate.isGenesisRate) {
        return createCarrierConnectionQuoteMutation.mutate(rate);
      }

      return handleRateSelectSuccessCallback({rate: rate, legacyQuote: rate.legacyQuote});
    },
    [createCarrierConnectionQuoteMutation, dispatch, handleRateSelectSuccessCallback]
  );

  // I'm not sure what the following does as it was copy-pasted from the v1 table
  const handleUpdate = useCallback(
    (rate: Rate) => {
      setShowInstantRatesUpdateModal(true);
      setRateSelected(rate);
    },
    [setShowInstantRatesUpdateModal, setRateSelected]
  );

  const handleCancel = useCallback(() => {
    setShowInstantRatesUpdateModal(false);
  }, [setShowInstantRatesUpdateModal]);

  const updateShipmentMutation = useUpdateShipment();
  const handleSubmitUpdateStops = useCallback(
    (shipment: Shipment, rate: Rate | undefined) => {
      updateShipmentMutation.mutate(
        {shipmentId: shipment.id, shipment: shipment},
        {
          onSuccess: ({data: updatedShipment}) => {
            if (rate) {
              handleSelect(rate);
            } else {
              createRatesMutation.mutate(shipment);
            }
            dispatch({type: SELECT_SHIPMENT, payload: updatedShipment});
            setCurrentShipment(updatedShipment);
            setShowInstantRatesUpdateModal(false);
          },
          onError: (error) => {
            console.error(error);
          },
          onSettled: () => setIsAccepting(false)
        }
      );
    },
    [createRatesMutation, dispatch, handleSelect, updateShipmentMutation]
  );

  const acceptRecommendation = useCallback(
    (rate: Rate) => {
      setLoadingMessage('Applying the recommendations..');
      setIsAccepting(true);
      const window = rate.recommendations?.filter((value) => value.code === 'STOP_WINDOW_ADJUSTED')[0];
      if (isNil(window)) {
        throw new Error('No recommendation found to carry out the update process');
      }
      const dateTimeFormat = 'HH:mm';
      const dateFormat = 'YYYY-MM-DD';
      currentShipment?.stops?.map((stop) => {
        const pickup = window?.recommendation?.window.pickup;
        if (stop.is_pickup && !isNil(pickup)) {
          stop.planned_time_window_start = parseZone(pickup?.earliestWindowStart).format(dateTimeFormat);
          stop.planned_time_window_end = parseZone(pickup?.latestWindowEnd).format(dateTimeFormat);
          stop.planned_date = parseZone(pickup.earliestWindowStart).format(dateFormat);
        }
        const delivery = window?.recommendation?.window.delivery;
        if (stop.is_dropoff && !isNil(delivery)) {
          stop.planned_time_window_start = parseZone(delivery.earliestWindowStart).format(dateTimeFormat);
          stop.planned_time_window_end = parseZone(delivery?.latestWindowEnd).format(dateTimeFormat);
          stop.planned_date = parseZone(delivery?.earliestWindowStart).format(dateFormat);
        }
      });
      handleSubmitUpdateStops(currentShipment, rate);
    },
    [currentShipment, handleSubmitUpdateStops]
  );

  const {data: {user, company} = {}} = useUserMe();
  const userPermissions = user?.permissions || [];
  const {krknTenderingUserPermissions, intLtl3RdPartySuppressRates} = useFlags();
  const canTakeActionOnRates = krknTenderingUserPermissions
    ? userPermissions.includes(SHIPMENT_CREATE_SPOT_NEGOTIATIONS)
    : true;

  const columns = useMemo(() => {
    const isLTL = shipment.mode?.code === ShipmentMode.Ltl;
    const shouldHideRates =
      intLtl3RdPartySuppressRates &&
      !!shipment.metadata?.bill_to_override?.direction &&
      isLTL &&
      ['COLLECT', '3RD_PARTY'].includes(shipment.metadata?.bill_to_override?.direction);

    // creating an RFQ always sets shipment to status quoting, however doing so doesn't update the store's shipment
    // allow status draft to also select a quote
    const isQuoting = !!shipment.state && ['quoting', 'draft'].includes(shipment.state);

    return createInstantRatesTableColumns(
      canTakeActionOnRates,
      isQuoting,
      shipment.mode?.code || '',
      company?.id || '',
      company?.name || '',
      handleSelect,
      handleUpdate,
      acceptRecommendation,
      expandedColumnSet,
      shouldHideRates,
      props.onRateSelect ? 'Book It' : undefined
    );
  }, [
    acceptRecommendation,
    canTakeActionOnRates,
    company?.id,
    company?.name,
    expandedColumnSet,
    handleSelect,
    handleUpdate,
    intLtl3RdPartySuppressRates,
    props.onRateSelect,
    shipment.metadata?.bill_to_override?.direction,
    shipment.mode?.code,
    shipment.state
  ]);

  const table = useTypedTable({
    data: rateData || [],
    columns,
    getRowCanExpand: () => true,
    enableSortingRemoval: false,
    initialState: {
      sorting: [
        {
          id: 'rate',
          desc: false
        }
      ]
    }
  });

  const {data: connections} = useConnectionsQuery(shipment.mode?.code, {
    enabled: !!shipment.mode?.code && [ShipmentMode.Ltl, ShipmentMode.Ftl].some((mode) => mode === shipment.mode?.code)
  });
  const activeConnections = connections?.length;

  if (activeConnections === 0 && hasPolledRates && rateData?.length === 0) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <span>No Rates</span>
      </div>
    );
  }

  if (isAccepting) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <Loader show={isAccepting}>{loadingMessage}</Loader>
      </div>
    );
  }

  return (
    <>
      {!isloadingRateInformation ? (
        <InstantRatesUpdateModal
          onUpdate={handleSubmitUpdateStops}
          show={showInstantRatesUpdateModal}
          close={handleCancel}
          loading={isloadingRateInformation}
          shipment={currentShipment}
          rate={rateSelected}
        />
      ) : null}
      {isloadingRateInformation && (
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0">
            <TableLoadingBar />
          </div>
        </div>
      )}
      <TableContainer>
        <Table
          head={table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  width={header.getSize()}
                  sortDirection={header.column.getIsSorted()}
                  onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                >
                  <div className="flex items-center gap-1">
                    {header.column.getCanSort() ? <TableHeaderSortIcon isSorted={header.column.getIsSorted()} /> : null}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                </TableHeader>
              ))}
            </TableRow>
          ))}
          body={table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2 z-0 content-start">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </TableRow>
          ))}
          isFixedTableLayout
          isStickyHeader={false}
        />
        {isloadingRateInformation ? (
          <div className="flex w-full justify-center py-4">
            <CircularProgress className="w-8 text-sw-primary" />
          </div>
        ) : !rateData?.length ? (
          <div className="self-center justify-items-center my-20">
            <EmptyTableSprinkles />
            <span className="font-medium text-lg">No instant rates available for this shipment.</span>
          </div>
        ) : null}
      </TableContainer>
    </>
  );
};

export default compose<InstantRatesTablePropTypes & InjectedInstantRatesTablePropTypes, InstantRatesTablePropTypes>(
  WithStatusToasts,
  withRouter,
  connect()
)(InstantRatesTableV2);
