import {useState, useEffect, useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {Card, Button} from '@shipwell/shipwell-ui';
import Table from 'App/components/Table';
import {ACCESSORIAL_TABLE} from 'App/containers/userCompany/rateTables/constants';
import {selectedRateTableDetails} from 'App/actions/_shipmentDetails';
import {formatCurrencyValue} from 'App/utils/globals';
import {useGetAccessorials} from 'App/containers/contracts/create/hooks/useGetAccessorials';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useCalculateAccessorialTable} from './hooks/useCalculateAccessorialTable';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';

type AccessorialTable = {
  id: string;
  calculation_rows: {
    accessorial: string;
    rate: number;
    calculation_description: string;
    rate_currency: string;
  }[];
};

type Props = {
  shipmentId: string;
  shouldRunCalculateItemsMutation?: boolean;
  isEditMode: boolean;
  values: {
    accessorial: string;
  };
  dispatch: (props: {type: string; payload: unknown}) => void;
  accessorialCustomer: AccessorialTable;
  accessorialVendor: AccessorialTable;
};

const RateTableDetailsAccessorial = ({
  values,
  dispatch,
  accessorialCustomer,
  accessorialVendor,
  isEditMode,
  shouldRunCalculateItemsMutation = false,
  shipmentId
}: Props) => {
  const {accessorials} = useGetAccessorials();
  const [buttonId, setButtonId] = useState<null | number>(null);
  const currentCustomer = accessorialCustomer?.calculation_rows?.[buttonId as number];
  const currentVendor = accessorialVendor?.calculation_rows?.[buttonId as number];

  const calculateAccessorialTableMutation = useCalculateAccessorialTable();

  const tableData = accessorialVendor
    ? accessorialVendor
    : accessorialCustomer
    ? accessorialCustomer
    : values?.accessorial
    ? values.accessorial
    : null;

  const tableDataId = (tableData as {id: string})?.id;

  const fetchRateTableLineItems = useCallback(() => {
    if (buttonId === null || buttonId < 0) {
      return;
    }
    if (currentCustomer || currentVendor) {
      const getRateItemsSource = () => {
        if (shouldRunCalculateItemsMutation && tableDataId) {
          return {
            accessorial: calculateAccessorialTableMutation?.data?.accessorials?.[buttonId].accessorial,
            rate: calculateAccessorialTableMutation?.data?.accessorials?.[buttonId].calculated_rate_amount,
            description: calculateAccessorialTableMutation?.data?.accessorials?.[buttonId].calculation_description
          };
        }

        return {
          accessorial: currentCustomer?.accessorial || currentVendor?.accessorial,
          rate: currentCustomer?.rate || currentVendor?.rate,
          description: currentCustomer?.calculation_description || currentVendor?.calculation_description
        };
      };

      dispatch(
        selectedRateTableDetails({
          customer: currentCustomer ? 'customer' : 'vendor',
          category: 'ACC',
          unit_name: getRateItemsSource().accessorial,
          unit_quantity: 1,
          unit_amount: getRateItemsSource().rate,
          description: getRateItemsSource().description,
          prepaid_amount: 0
        })
      );
      setButtonId(null);
    }
  }, [
    buttonId,
    dispatch,
    currentVendor,
    currentCustomer,
    calculateAccessorialTableMutation?.data?.accessorials,
    shouldRunCalculateItemsMutation,
    tableDataId
  ]);

  useEffect(() => {
    fetchRateTableLineItems();
  }, [fetchRateTableLineItems]);

  const columns = useMemo(() => {
    const initialColumns = [
      {
        Header: 'Accessorial',
        accessor: 'accessorial'
      },
      {
        Header: 'Rate',
        accessor: 'rate'
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ];

    if (accessorialVendor || accessorialCustomer) {
      initialColumns.push({
        Header: 'Action',
        accessor: 'action'
      });
    }

    return initialColumns;
  }, [accessorialVendor, accessorialCustomer]);

  useEffect(() => {
    if (shouldRunCalculateItemsMutation && tableDataId) {
      calculateAccessorialTableMutation.mutateAsync({
        accessorialChargeTableId: tableDataId,
        rateTableAccessorialCalculationRequest: {
          shipment_id: shipmentId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentId, shouldRunCalculateItemsMutation, tableDataId]);

  const getData = () => {
    if (shouldRunCalculateItemsMutation) {
      return calculateAccessorialTableMutation?.data?.accessorials?.map((row, index) => ({
        accessorial: accessorials?.find((acc) => acc.code === row.accessorial)?.description || '--',
        rate:
          row.calculated_rate_amount && row.calculated_rate_currency
            ? `${row.calculated_rate_currency} ${formatCurrencyValue(row.calculated_rate_amount)}`
            : '--',
        description: row.calculation_description ?? '--',
        action:
          accessorialVendor || accessorialCustomer ? (
            <Button
              id={String(index)}
              size="sm"
              variant="primary"
              disabled={!isEditMode}
              onClick={() => {
                setButtonId(index);
              }}
            >
              Add as Line Item
            </Button>
          ) : (
            '--'
          )
      }));
    }

    return (
      typeof tableData === 'object' &&
      tableData?.calculation_rows?.map((row, index: number) => ({
        accessorial: accessorials?.find((acc) => acc.code === row.accessorial)?.description || '--',
        rate: row.rate && row.rate_currency ? `${row.rate_currency} ${formatCurrencyValue(row.rate)}` : '--',
        description: row.calculation_description ?? '--',
        action:
          accessorialVendor || accessorialCustomer ? (
            <Button
              id={String(index)}
              variant="primary"
              size="sm"
              disabled={!isEditMode}
              onClick={() => {
                setButtonId(index);
              }}
            >
              Add as Line Item
            </Button>
          ) : (
            '--'
          )
      }))
    );
  };

  const isLoading = [calculateAccessorialTableMutation.isLoading, !accessorials].some(Boolean);

  return (
    <Card draggableProvided={null} title={ACCESSORIAL_TABLE} bodyClassName="p-0">
      {isLoading ? <ShipwellLoader loading /> : <Table columns={columns} data={getData()} />}
    </Card>
  );
};

export default connect((state: {shipmentdetails: {selectedRateTable: unknown; one: Shipment}}) => ({
  selectedRateTable: state.shipmentdetails.selectedRateTable,
  shipmentId: state.shipmentdetails.one.id
}))(RateTableDetailsAccessorial);
