import classNames from 'classnames';
import {DashboardColumnSort, DashboardColumnsSchema} from '../../commons/dashboardColumnSchema';
import {TableBody} from './tableBody/tableBody';
import {TableHeader} from './tableHeader/tableHeader';

export interface DashboardTableProps<TItem> {
  data?: TItem[];
  columnsSchema: DashboardColumnsSchema<TItem>;
  fullWidth?: boolean;
  sortBy?: DashboardColumnSort;
  onSortBy: (sort?: DashboardColumnSort) => void;
}

export function DashboardTable<TItem>(props: DashboardTableProps<TItem>) {
  return (
    <table
      className={classNames('border-separate table-fixed', {
        'w-full': !!props.fullWidth
      })}
    >
      <TableHeader
        sortBy={props.sortBy}
        fullWidth={props.fullWidth}
        columnsSchema={props.columnsSchema}
        onSortBy={props.onSortBy}
      />

      <TableBody data={props.data} fullWidth={props.fullWidth} columnsSchema={props.columnsSchema} />
    </table>
  );
}
