import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection, change, formValueSelector, getFormSyncErrors, stopAsyncValidation, touch} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import {getData as CountryList} from 'country-list';
import {
  CustomFieldEntityTypesEnum,
  ShipmentLineItemHazmatPackingGroupEnum,
  ShipmentLineItemTempUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {warnings} from 'App/formComponents/formSections/shipmentItemFieldsLegacy/utils/warnings';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import InputField from 'App/formComponents/fields/input';
import CheckboxField from 'App/formComponents/fields/checkbox';
import ProductSearchField from 'App/formComponents/fields/productSearch';
import HazmatSearchField from 'App/formComponents/fields/hazmatSearch';
import PackageTypesField from 'App/formComponents/fields/packageTypes';
import ProviderPackageTypesField from 'App/formComponents/fields/providerPackageTypes';
import MiniProductFields from 'App/formComponents/formSections/miniProductFieldsLegacy';
import SelectField from 'App/formComponents/fields/select';
import {fetchPackageTypes} from 'App/actions/shipments';
import {fetchPieceTypes} from 'App/actions/_shipments';
import {createProduct, editProduct} from 'App/actions/products';
import {validation} from 'App/formComponents/formSections/miniProductFieldsLegacy/utils/validation';
import {
  calculateFreightClass,
  freightClasses,
  cleanNumber,
  truncateNumber,
  scrollToFirstErrorField,
  isWhiteLabel,
  validateTemperature
} from 'App/utils/globals';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import {METRIC} from 'App/utils/internationalConstants';
import {CustomFieldsContextConsumer} from 'App/data-hooks';
import {isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {isSingleSelectionList} from 'App/containers/orders/utils';
import './styles.scss';

/**
 * Shipment Line-Items Fields
 * A redux-form component
 */
class ShipmentItemFields extends Component {
  constructor(props) {
    super(props);

    this.getWhiteLabel = this.getWhiteLabel.bind(this);

    this.state = {
      isSubmitting: false,
      lineItemTotals: null,
      calculatedFreightClass: null,
      showSaveModal: false,
      saveProductSuccess: false,
      saveProductError: false
    };
  }

  static defaultProps = {
    slimForm: false
  };

  componentDidMount() {
    const {shipmentLineItem, form, name} = this.props;
    const lineItemTotals = this.calculateLineItemTotals(shipmentLineItem);
    const estimatedFreightClass = this.estimateFreightClass(shipmentLineItem);

    this.requestFieldOptions();
    this.getWhiteLabel();
    this.setState({
      lineItemTotals: lineItemTotals,
      calculatedFreightClass: estimatedFreightClass
    });

    if (shipmentLineItem?.hazmat_identification_number) {
      this.props.dispatch(
        change(form, name, {
          ...shipmentLineItem,
          hazmat: true,
          hazmatDetails: {
            hazmat_identification_number: shipmentLineItem.hazmat_identification_number,
            hazmat_hazard_class: shipmentLineItem.hazmat_hazard_class,
            hazmat_packing_group: shipmentLineItem.hazmat_packing_group,
            hazmat_proper_shipping_name: shipmentLineItem.hazmat_proper_shipping_name
          }
        })
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {shipmentLineItem, hasLTL, onWeightChange, onLinearFeetEstimate} = this.props;

    if (prevProps.shipmentLineItem !== shipmentLineItem) {
      const checkFields = [
        'stackable',
        'length',
        'width',
        'height',
        'length_unit',
        'package_weight',
        'weight_unit',
        'total_packages'
      ];
      let isMissingField = false;
      let fieldHasChanged = false;

      /**
       * Check of changes in unit line item fields
       * Redux Form updates the store on blur, so we need to check if line-item values have changed
       */
      checkFields.forEach((field) => {
        const prevLineItem = prevProps.shipmentLineItem;

        if (!isMissingField && field !== 'stackable' && !shipmentLineItem[field]) {
          isMissingField = true;
        }
        if (!fieldHasChanged && prevLineItem && prevLineItem[field] !== shipmentLineItem[field]) {
          fieldHasChanged = true;
        }
      });

      if (
        prevProps.shipmentLineItem.package_weight !== shipmentLineItem.package_weight ||
        prevProps.shipmentLineItem.weight_unit !== shipmentLineItem.weight_unit ||
        prevProps.shipmentLineItem.total_packages !== shipmentLineItem.total_packages ||
        //length unit affects measurement system display, prompt onWeightChange
        prevProps.shipmentLineItem.length_unit !== shipmentLineItem.length_unit
      ) {
        onWeightChange && onWeightChange();
      }

      if (hasLTL && !isMissingField && fieldHasChanged) {
        onLinearFeetEstimate && onLinearFeetEstimate();
      }

      this.setState({
        lineItemTotals: this.calculateLineItemTotals(shipmentLineItem),
        calculatedFreightClass: this.estimateFreightClass(shipmentLineItem)
      });
    }
  }

  getWhiteLabel() {
    this.setState({isWhiteLabel: isWhiteLabel()});
  }

  requestFieldOptions() {
    const {packageTypes, pieceTypes} = this.props;

    if (!packageTypes || packageTypes.length === 0) {
      this.props.dispatch(fetchPackageTypes());
    }

    if (!pieceTypes || pieceTypes.length === 0) {
      this.props.dispatch(fetchPieceTypes());
    }
  }

  /**
   * Hazmat checkbox handler
   * @param  {String} lineItem Line item row
   * @param  {Object} e        Change event
   */
  handleHazmatSelection(e) {
    const {shipmentLineItem, name, form} = this.props;
    const {checked} = e.target;

    if (!checked) {
      const hazardValues = Object.assign({}, shipmentLineItem, {
        hazmatDetails: '',
        hazmat_hazard_class: null,
        hazmat_identification_number: null,
        hazmat_packing_group: null,
        hazmat_proper_shipping_name: null
      });

      /** Remove hazmat details if hazmat option is not selected */
      this.props.dispatch(change(form, name, hazardValues));
    }
  }

  /**
   * Update model with hazmat selection details
   * @param  {String} lineItem Line item row
   * @param  {Array}  values   List of selected hazmat options
   */
  handleHazmatDetailsSelection(e, values) {
    const {shipmentLineItem, name, form} = this.props;
    let hazardValues;

    if (values) {
      const {hazard_class, identification_number, packing_group, proper_shipping_name} = values;
      hazardValues = {
        hazmat_hazard_class: hazard_class || null,
        hazmat_identification_number: identification_number || null,
        hazmat_packing_group: packing_group || null,
        hazmat_proper_shipping_name: proper_shipping_name || null
      };
    } else {
      hazardValues = {
        hazmatDetails: null,
        hazmat_hazard_class: null,
        hazmat_identification_number: null,
        hazmat_packing_group: null,
        hazmat_proper_shipping_name: null
      };
    }

    this.props.dispatch(change(form, name, Object.assign({}, shipmentLineItem, hazardValues)));
  }

  //updates hazmatDetails when individual hazmat fields change
  handleUpdateHazmatDetails(e, value) {
    const {shipmentLineItem, name, form} = this.props;
    const fieldName = e.target.name.split('.').at(-1).replace('hazmat_', '');
    const hazmatDetails = {...(shipmentLineItem.hazmatDetails || {}), [fieldName]: value};

    this.props.dispatch(
      change(
        form,
        name,
        Object.assign({}, shipmentLineItem, {
          hazmatDetails
        })
      )
    );
  }

  //updates hazmatDetails when packing group changes
  handleUpdatePackingGroup(e, value) {
    const {shipmentLineItem, name, form} = this.props;
    const hazmatDetails = {...(shipmentLineItem.hazmatDetails || {}), packing_group: value};

    this.props.dispatch(
      change(
        form,
        name,
        Object.assign({}, shipmentLineItem, {
          hazmatDetails
        })
      )
    );
  }

  /**
   * Update a line item from the selected product in search
   * @param  {String} lineItem ArrayField name
   * @param  {Object} value    Selected product from search
   */
  handleSetProductLineItem(e, result) {
    const {shipmentLineItem, form, name} = this.props;

    if (result?.value) {
      const {id, ...value} = result.value;

      if (value?.hazmat_identification_number) {
        value.hazmat = true;
        value.hazmatDetails = {
          hazmat_identification_number: value.hazmat_identification_number || null,
          hazmat_hazard_class: value.hazmat_hazard_class || null,
          hazmat_packing_group: value.hazmat_packing_group || null,
          hazmat_proper_shipping_name: value.hazmat_proper_shipping_name || null
        };
      } else {
        value.hazmat = false;
        value.hazmatDetails = null;
      }

      if (!value?.weight_unit) {
        value.weight_unit = 'LB';
      }
      if (shipmentLineItem?.purchase_order) {
        value.purchase_order = shipmentLineItem.purchase_order;
      }

      this.props.dispatch(change(form, name, Object.assign({}, shipmentLineItem, value)));
    }
  }

  async handleSaveProduct(e) {
    e.preventDefault();
    const {shipmentLineItem} = this.props;
    const productId = shipmentLineItem.products?.value.id;
    const errors = this.handleProductValidation();
    const hasErrors = Object.keys(errors).length;
    let response = null;

    this.setState({isSubmitting: true});

    if (shipmentLineItem.image?.blob) {
      shipmentLineItem.image = shipmentLineItem.image.blob;
    }

    try {
      if (!hasErrors && !productId) {
        response = await this.props.dispatch(createProduct(shipmentLineItem));
      } else if (!hasErrors) {
        response = await this.props.dispatch(editProduct(productId, shipmentLineItem));
      } else {
        this.setState({isSubmitting: false});
      }
      if (response?.body) {
        this.handleSetProductLineItem(null, {value: response.body});
        this.handleProductSaveSuccess();
        this.setState({isSubmitting: false, showSaveModal: false});
      }
    } catch (error) {
      this.handleProductSaveError();
      this.setState({isSubmitting: false, showSaveModal: false});
    }
  }

  handleProductValidation() {
    const {shipmentLineItem, form, fields, index} = this.props;
    const errors = validation(shipmentLineItem);
    const hasErrors = Object.keys(errors).length;

    if (hasErrors) {
      const fieldErrors = [];
      const mappedFields = Object.keys(errors)
        .map((fieldError) => fields.find((field) => field.includes(fieldError)))
        .filter((field) => field);

      fieldErrors[index] = errors;

      // Manually trigger field validation errors
      this.props.dispatch(touch(form, ...mappedFields));
      this.props.dispatch(stopAsyncValidation(form, {line_items: fieldErrors}));

      scrollToFirstErrorField();
    }
    return errors;
  }

  /**
   * Calculate Line-Item totals for density, freight_class, and weight
   * @param {Object} lineItems  Shipment line-item
   * @param {Number} index      Index of line-item in list
   */
  calculateLineItemTotals(lineItem) {
    return calculateShipmentTotals({line_items: [lineItem]});
  }

  /**
   * Estimate freight class
   * @param {Object} lineItems  Shipment line-item
   * @param {Number} index      Index of line-item in list
   */
  estimateFreightClass(lineItem) {
    const {length, width, height, length_unit, package_weight, weight_unit, total_packages} = lineItem;
    let calculatedFreightClass = calculateFreightClass(
      length,
      width,
      height,
      length_unit,
      package_weight,
      weight_unit,
      total_packages
    );

    if (Number.isNaN(calculatedFreightClass)) {
      calculatedFreightClass = null;
    }
    return calculatedFreightClass;
  }

  handleDisplaySaveModal(e) {
    e.preventDefault();
    const {product_ref, ...errors} = this.handleProductValidation();
    const hasErrors = Object.keys(errors).length;

    if (!hasErrors) {
      this.setState({showSaveModal: true});
    }
  }

  handleToggleSaveModal() {
    this.setState({showSaveModal: false});
  }

  handleProductSaveSuccess() {
    this.setState(
      {
        isSubmitting: false,
        saveProductSuccess: true
      },
      () => {
        setTimeout(() => this.setState({saveProductSuccess: false}), 3000);
      }
    );
  }

  handleProductSaveError() {
    this.setState(
      {
        isSubmitting: false,
        saveProductError: true
      },
      () => {
        setTimeout(() => this.setState({saveProductError: false}), 3000);
      }
    );
  }

  render() {
    const {
      lineItemTotals,
      calculatedFreightClass,
      showSaveModal,
      isSubmitting,
      saveProductSuccess,
      saveProductError,
      isWhiteLabel
    } = this.state;

    const {
      name,
      hasLTL,
      hasParcel,
      isIntlParcel,
      hasDrayage,
      slimForm,
      externalForm,
      pieceTypes,
      shipmentLineItem,
      manualWeightEntry,
      parcelProvider,
      fieldWarnings = warnings(shipmentLineItem, this.props),
      unitPreferences,
      companyId
    } = this.props;

    const packingGroupOptions = Object.values(ShipmentLineItemHazmatPackingGroupEnum).reduce(
      (acc, value) => [...acc, {id: value, name: value}],
      []
    );

    const temperatureUnit = shipmentLineItem?.temp_unit || ShipmentLineItemTempUnitEnum.F;

    return (
      <FormSection name={name}>
        <div className="shipment-item">
          {!externalForm ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  key={shipmentLineItem.products ? '1' : '2'}
                  name="products"
                  label="Product"
                  component={ProductSearchField}
                  onChange={this.handleSetProductLineItem.bind(this)}
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field req name="description" label="Product Description" component={InputField} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4} xl={2}>
              <Field
                req
                name="total_packages"
                label="Quantity"
                normalize={(value) => cleanNumber(value, {allowDecimals: true})}
                component={InputField}
              />
            </Grid>
            {!hasParcel ? (
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <Field
                  simpleValue
                  req={!hasLTL}
                  isParcel
                  name="package_type"
                  label="Packaging"
                  placeholder="Select a package type"
                  component={PackageTypesField}
                />
              </Grid>
            ) : null}
            {hasParcel ? (
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <Field
                  simpleValue
                  provider={parcelProvider}
                  isParcel
                  name="provider_specific_packaging"
                  label="Packaging"
                  placeholder="Select a package type"
                  component={ProviderPackageTypesField}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={6} lg={4} xl={2}>
              <Field
                req={!manualWeightEntry}
                name="package_weight"
                label="Package Weight"
                normalize={(value) =>
                  //display decimals if not parcel
                  truncateNumber(value, 2)
                }
                component={InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2}>
              <Field
                clearable={false}
                req={false}
                simpleValue
                name="weight_unit"
                label="Weight Unit"
                searchable={false}
                options={[
                  {id: 'LB', name: 'LB'},
                  {id: 'KG', name: 'KG'}
                ]}
                component={SelectField}
              />
            </Grid>
            {!hasParcel ? (
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <span>
                  <strong>Total Item Weight </strong>
                  <br />
                  <div className="shipment-item-total-weight">
                    {shipmentLineItem?.package_weight &&
                    shipmentLineItem.package_weight > 0 &&
                    shipmentLineItem?.total_packages &&
                    shipmentLineItem.total_packages > 0 ? (
                      `${parseFloat(shipmentLineItem.package_weight * shipmentLineItem.total_packages).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                        ${shipmentLineItem.weight_unit || 'lb'}`
                    ) : (
                      <strong>--</strong>
                    )}
                  </div>
                </span>
              </Grid>
            ) : null}
          </Grid>
          {!hasParcel || shipmentLineItem.provider_specific_packaging === 'YOUR_PACKAGING' ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <Field
                  req={hasLTL || hasParcel}
                  name="length"
                  label="Length"
                  normalize={(value) =>
                    //allow decimal entry if not parcel
                    truncateNumber(value, 2)
                  }
                  component={InputField}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <Field
                  req={hasLTL || hasParcel}
                  name="width"
                  label="Width"
                  normalize={(value) =>
                    //display decimals if not parcel
                    truncateNumber(value, 2)
                  }
                  component={InputField}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <Field
                  req={hasLTL || hasParcel}
                  name="height"
                  label="Height"
                  normalize={(value) =>
                    //display decimals if not parcel
                    truncateNumber(value, 2)
                  }
                  component={InputField}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <Field
                  simpleValue
                  name="length_unit"
                  searchable={false}
                  options={[
                    {id: 'IN', name: 'IN'},
                    {id: 'CM', name: 'CM'}
                  ]}
                  displayData={
                    lineItemTotals && lineItemTotals.density > 0 ? (
                      <span>
                        {` ${parseFloat(lineItemTotals.density).toFixed(2)} ${
                          lineItemTotals.system === METRIC ? `kg/m` : `lb/ft`
                        }`}
                        <sup>3</sup>
                      </span>
                    ) : null
                  }
                  component={SelectField}
                />
              </Grid>
              {!hasDrayage && !hasParcel ? (
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <Field
                    simpleValue
                    req={hasLTL}
                    name="freight_class"
                    label="Freight Class"
                    placeholder="Select one"
                    displayData={calculatedFreightClass && ` (est. ${calculatedFreightClass})`}
                    options={freightClasses.map((item) => ({
                      id: String(item.id),
                      name: item.name
                    }))}
                    component={SelectField}
                  />
                </Grid>
              ) : null}
            </Grid>
          ) : null}
          {!hasDrayage ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <Field
                    name="total_pieces"
                    label="Number of Pieces"
                    req={isIntlParcel}
                    normalize={(value) => cleanNumber(value)}
                    component={InputField}
                  />
                </Grid>
                {!hasParcel ? (
                  <>
                    <Grid item xs={12} md={6} lg={4} xl={2}>
                      <Field
                        clearable
                        simpleValue
                        name="piece_type"
                        label="Piece Type"
                        placeholder="Select one"
                        options={pieceTypes}
                        component={SelectField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={2}>
                      <Field
                        name="nmfc_item_code"
                        label="NMFC Code"
                        normalize={(value) => cleanNumber(value)}
                        component={InputField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={2}>
                      <Field
                        name="nmfc_sub_code"
                        label="NMFC Sub Code"
                        normalize={(value) => cleanNumber(value)}
                        component={InputField}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <Field
                    name="value_per_piece"
                    req={isIntlParcel}
                    label={`Value Per Piece (${
                      shipmentLineItem.value_per_piece_currency
                        ? shipmentLineItem.value_per_piece_currency
                        : unitPreferences.currency
                    })`}
                    format={(value) => truncateNumber(value)}
                    normalize={(value) => (value === '' ? null : cleanNumber(value, {allowDecimals: true}))}
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <Field
                    clearable
                    simpleValue
                    req={isIntlParcel}
                    name="country_of_manufacture"
                    label="Country of Manufacture"
                    placeholder="Select one"
                    options={CountryList().map((country) => ({
                      id: country.code,
                      name: country.name
                    }))}
                    component={SelectField}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
          {!hasParcel ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <Field name="stackable" label="Stackable" component={CheckboxField} />
                </Grid>
                {!externalForm ? (
                  <Grid item xs={12} md={6} lg={4} xl={2}>
                    <Field name="refrigeration_required" label="Refrigeration Required" component={CheckboxField} />
                  </Grid>
                ) : null}
              </Grid>
              <Grid container spacing={2}>
                {!externalForm ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Field
                          req={shipmentLineItem?.refrigeration_required}
                          label={`Min Temp (\u00B0${temperatureUnit})`}
                          name="refrigeration_min_temp"
                          placeholder="e.g., 35"
                          format={(value) => validateTemperature(value)}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          req={shipmentLineItem?.refrigeration_required}
                          label={`Max Temp (\u00B0${temperatureUnit})`}
                          name="refrigeration_max_temp"
                          placeholder="e.g., 50"
                          format={(value) => validateTemperature(value)}
                          component={InputField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={6}>
                  <Field
                    name="hazmat"
                    label="Hazmat"
                    component={CheckboxField}
                    onChange={this.handleHazmatSelection.bind(this)}
                  />
                </Grid>
                {shipmentLineItem?.hazmat ? (
                  <>
                    <Grid item xs={12}>
                      <Field
                        className="hazmat-details-field"
                        name="hazmatDetails"
                        label="Hazmat Code"
                        component={HazmatSearchField}
                        onChange={this.handleHazmatDetailsSelection.bind(this)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4} xl={2}>
                          <Field
                            req
                            name="hazmat_identification_number"
                            label="Hazmat Identification Number"
                            component={InputField}
                            onChange={this.handleUpdateHazmatDetails.bind(this)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={2}>
                          <Field
                            req
                            name="hazmat_hazard_class"
                            label="Hazmat Class"
                            component={InputField}
                            onChange={this.handleUpdateHazmatDetails.bind(this)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={2}>
                          <Field
                            simpleValue
                            req
                            name="hazmat_packing_group"
                            label="Hazmat Packing Group"
                            placeholder="Select one"
                            options={packingGroupOptions}
                            component={SelectField}
                            onChange={this.handleUpdatePackingGroup.bind(this)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        req
                        name="hazmat_proper_shipping_name"
                        label="Hazmat Proper Shipping Name"
                        component={InputField}
                        onChange={this.handleUpdateHazmatDetails.bind(this)}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </>
          ) : null}
          {fieldWarnings.freightClassWarning ? (
            <div className="freightclass-warning">
              <span>
                <strong className="error-text">Disclaimer:</strong> Your freight class selection is more than two
                classes away from the density based{' '}
                <strong>
                  <em>estimate</em>
                </strong>
                . The freight class estimate is only intended to be guidance, and the carrier may determine a freight
                class based on NMFC guidelines, which can affect your rate. If you are uncertain about the freight class
                you should choose, contact <a href="mailto:support@shipwell.com">support@shipwell.com</a> for
                assistance.
              </span>
            </div>
          ) : null}
          {fieldWarnings.excessiveLengthWarning ? (
            <div className="length-warning">
              <span>
                <strong className="error-text">Disclaimer:</strong> Items over 8 ft may be subject to an extra charge.
                While each carrier has different length requirements, please add an “Excessive Length” accessorial to
                your shipment to avoid unexpected charges upon tender to your carrier. Contact{' '}
                {isWhiteLabel ? (
                  'your account representative'
                ) : (
                  <a href="mailto:support@shipwell.com">support@shipwell.com</a>
                )}{' '}
                with questions.
              </span>
            </div>
          ) : null}
          {!slimForm && !externalForm ? (
            <div className="shipment-item-footer">
              {saveProductSuccess && (
                <span className="text-success">
                  Successfully {shipmentLineItem.products ? ' updated' : ' saved'} product.
                </span>
              )}
              {saveProductError ? (
                <span className="error-text">Error {shipmentLineItem.products ? ' updating' : ' saving'} product.</span>
              ) : null}
              <button
                className="btn btn-primary"
                disabled={isSubmitting}
                onClick={this.handleDisplaySaveModal.bind(this)}
              >
                <i className="icon-Save pad-right" />
                {shipmentLineItem.products ? ' Update ' : ' Save '} product
              </button>
            </div>
          ) : null}
          <Grid container spacing={2}>
            <CustomFieldsContextConsumer>
              {({customFields = []}) => {
                return customFields.map((cf) => {
                  const isOwner = isCustomFieldOwner(cf.company, companyId);
                  return (
                    <Grid item xs={12} key={cf.id}>
                      <Field
                        name={`custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)}.${cf.id}`}
                        simpleValue
                        label={cf.label}
                        req={cf.required && isOwner}
                        disabled={!isOwner}
                        options={cf.allowed_values.map((allowedValue) => ({
                          id: allowedValue.label,
                          name: allowedValue.label
                        }))}
                        component={isSingleSelectionList(cf.field_type) ? SelectField : InputField}
                      />
                    </Grid>
                  );
                });
              }}
            </CustomFieldsContextConsumer>
          </Grid>
        </div>
        <InfoModalWrapper show={showSaveModal} title="Save Product" onHide={this.handleToggleSaveModal.bind(this)}>
          <MiniProductFields />
          <button className="btn btn-primary" disabled={isSubmitting} onClick={this.handleSaveProduct.bind(this)}>
            {isSubmitting ? <i className="icon icon-Restart rotate" /> : null}
            {shipmentLineItem.products ? ' Update' : ' Save'}
          </button>
        </InfoModalWrapper>
      </FormSection>
    );
  }
}

export default connect((state, props) => ({
  user: state.auth.user,
  pieceTypes: state.shipments.pieceTypes
    ? state.shipments.pieceTypes.map((item) => ({id: item.id, name: item.description}))
    : [],
  fields:
    state.form[props.form] &&
    state.form[props.form].registeredFields &&
    Object.keys(state.form[props.form].registeredFields).filter((key) => key.startsWith(props.name)),
  purchaseOrder: state.purchaseOrders.details.purchaseOrdersById,
  manualWeightEntry: formValueSelector(props.form)(state, 'manual_total_weight'),
  parcelProvider: formValueSelector(props.form)(state, 'parcel_provider'),
  syncErrors: getFormSyncErrors(props.form)(state),
  shipmentLineItem: formValueSelector(props.form)(state, props.name),
  unitPreferences: state.userCompany.unitPreferences,
  companyId: state.auth.company.id
}))(ShipmentItemFields);
