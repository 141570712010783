import {CompanySchema} from '@shipwell/shipment-assembly-sdk';
import {FormikDateTimePicker} from '@shipwell/shipwell-ui';
import {Field} from 'formik';
import {useMemo} from 'react';
import {OpenTimes} from '../OperatingDaysInputs';

export function TimeFieldCompany({
  name,
  label,
  openDay,
  onChange,
  timezone,
  values
}: {
  name: string;
  label: string;
  openDay: boolean;
  onChange: (val: string) => void;
  timezone?: string | null;
  values: CompanySchema | OpenTimes;
}) {
  const fieldValue = values[name as keyof typeof values];
  // We are marking it as false because the logic related to the flag needs a change from the BE. That way, once it is released, we will only need to switch to using useFlag, and everything should work as expected.
  // const {overnightAddressBookSupport} = useFlags();
  const overnightAddressBookSupport = false;

  const day = name.split('_')[0] as keyof typeof values;
  const openTime = values[`${day}_open_time` as keyof typeof values];
  const closeTime = values[`${day}_close_time` as keyof typeof values];

  const showOvernightWarning = overnightAddressBookSupport && openTime && closeTime && openTime > closeTime;
  const showSingleTimeWarning = overnightAddressBookSupport && ((openTime && !closeTime) || (!openTime && closeTime));

  const parsedValue = useMemo(() => {
    if (typeof fieldValue === 'string') {
      const [hours, minutes] = fieldValue.split(':').map(Number);
      if (!isNaN(hours) && !isNaN(minutes)) {
        const date = new Date();
        date.setHours(hours, minutes);
        return date;
      }
    }
    return fieldValue;
  }, [fieldValue]);

  return (
    <div>
      <Field
        name={name}
        label={label}
        component={FormikDateTimePicker}
        hideTimezone={!timezone}
        showTimeSelectOnly
        timezone={timezone}
        value={parsedValue}
        disabled={!openDay}
        onChange={onChange}
        timeIntervals={15}
        ignoreTimezone
      />

      {showOvernightWarning && (
        <span className="text-sw-warning text-[12px] text-gray-500 leading-3">
          Be aware that Open time is after the Close time and represents a facility that is open Overnight from prior
          Open time.
        </span>
      )}

      {showSingleTimeWarning && (
        <span className="text-sw-warning text-[12px] text-gray-500 leading-3">
          Since only one time is indicated this day, it will be treated as a facility that is open overnight.
        </span>
      )}
    </div>
  );
}
