import {useMutation} from '@tanstack/react-query';
import {Quote} from '@shipwell/backend-core-sdk';
import {createCarrierConnectionQuote} from '../../typed';

export const useCreateParcelCarrierConnection = () => {
  const {mutateAsync: createParcelCarrierConnectionAsync, ...createParcelCarrierConnectionMutation} = useMutation({
    mutationFn: async ({
      legacyRfqId,
      rateId,
      rateRequestId
    }: {
      legacyRfqId: string;
      rateId: string;
      rateRequestId: string;
    }) => {
      const response: Quote = await createCarrierConnectionQuote({
        legacyRfqId,
        id: rateId,
        rateRequestId
      });
      return response;
    }
  });

  return {
    createParcelCarrierConnectionAsync,
    createParcelCarrierConnectionMutation
  };
};
