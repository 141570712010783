import {DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE} from '../../commons/constants';
import {DashboardColumnSort, DashboardColumnSortDirection} from '../../commons/dashboardColumnSchema';
import {DashboardBaseSearchParams} from '../../commons/dashboardSearchParams';
import {DashboardUrlSearchParams} from '../../commons/dashboardUrlSearchParams';

export function getUrlParamsFromBaseParams(values: DashboardBaseSearchParams) {
  const toStringObj: DashboardUrlSearchParams = {
    searchTerm: values.searchTerm || '',
    orderBy: getOrderByStringFromSort(values.orderBy) || '',
    page: values.page ? String(values.page) : String(DEFAULT_FIRST_PAGE) || '',
    pageSize: values.pageSize ? String(values.pageSize) : String(DEFAULT_PAGE_SIZE) || ''
  };

  return toStringObj;
}

export function getOrderByStringFromSort(sort?: DashboardColumnSort) {
  if (!sort) return;
  const prefix = sort.direction === DashboardColumnSortDirection.DESC ? '-' : '';
  return `${prefix}${sort.key}`;
}
