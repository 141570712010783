import {AppointmentEntry, Filters} from 'App/data-hooks/appointments/types';
import {AppointmentDateInfo} from './AppointmentDateInfo';
import {AppointmentStatusInfo} from './AppointmentStatusInfo';
import {formatReferences} from '../../utils';

interface Option {
  label: string;
  value: string;
  appointment: AppointmentEntry;
  filters: Filters;
}

export const FormatOptionLabel = (option: Option, {context}: {context: 'menu' | 'value'}) => {
  const {appointment} = option;

  const references = formatReferences(appointment.references || [], option.filters, true);

  if (context === 'menu') {
    return (
      <div className="flex items-start justify-between grey-color py-2 min-w-0">
        <AppointmentStatusInfo
          status={appointment.status}
          references={references}
          companyName={appointment.carrierName}
        />
        <AppointmentDateInfo start={appointment.start} end={appointment.end} status={appointment.status} />
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <span className="font-bold">{appointment.scheduledResourceReferenceId || appointment.carrierName || ''}</span>
    </div>
  );
};
