import {
  TrackingApi,
  TrackingApiCreateResourceTrackingConfigRequest,
  TrackingApiGetAttachedDevicesRequest,
  TrackingApiGetDeviceHistoryRequest,
  TrackingApiListDevicesRequest,
  TrackingApiQuickstartResourceTrackingConfigRequest,
  TrackingApiStopResourceTrackingRequest
} from '@shipwell/locus-sdk';
import {configuration} from './config';

const trackingApi = new TrackingApi(configuration);

export async function createResourceTrackingConfig(params?: TrackingApiCreateResourceTrackingConfigRequest) {
  const response = await trackingApi.createResourceTrackingConfig(params);
  return response.data;
}

export async function quickstartResourceTrackingConfig(params?: TrackingApiQuickstartResourceTrackingConfigRequest) {
  const response = await trackingApi.quickstartResourceTrackingConfig(params);
  return response.data;
}

export async function listDevices(params: TrackingApiListDevicesRequest, account_id: number, page_number = 1) {
  const response = await trackingApi.listDevices(params, {params: {account_id, page_number}});
  return response.data;
}

export async function stopResourceTracking(params: TrackingApiStopResourceTrackingRequest) {
  const response = await trackingApi.stopResourceTracking(params);
  return response.data;
}

export async function getAttachedDevices(params: TrackingApiGetAttachedDevicesRequest) {
  const response = await trackingApi.getAttachedDevices(params);
  return response.data;
}

export const getDeviceHistory = async (params: TrackingApiGetDeviceHistoryRequest) => {
  const response = await trackingApi.getDeviceHistory(params);
  return response.data.data;
};
