import {Modal, DeprecatedButton, InlineNotification, InlineNotificationVariantEnum} from '@shipwell/shipwell-ui';
import {change, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import {
  CarrierRelationshipCarrierStatusEnum,
  ContractCriteriaByValueRequestModeEnum,
  ContractRateCurrencyEnum,
  EquipmentTypeValues
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ContractSelect} from 'App/formComponents/formSections/tenderRequest/ContractSelect';
import useToggle from 'App/utils/hooks/useToggle';
import {useSelectedContract} from 'App/data-hooks/contracts/useSelectedContract';
import {SelectedContract} from 'App/formComponents/formSections/tenderRequest/SelectedContract';
import {useModesQuery, useTotalDistance} from 'App/data-hooks';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import {getStopOriginDestinationAndAdditionalAddresses} from 'App/formComponents/formSections/tenderRequest/utils';
import {NewShipmentFormValues} from 'App/containers/shipments/utils/typed';

type ChangeFunction = typeof change;

// currently newShipmentForm-centric
const LoadFromContract = ({change, formValues}: {change: ChangeFunction; formValues: NewShipmentFormValues}) => {
  const {mode: formMode, equipment_type: formEquipmentType, stops} = formValues || {};
  const {origin, destination} = getStopOriginDestinationAndAdditionalAddresses(stops || []);
  const stopAddresses = stops?.map((stop) => stop.location.address);
  const stopLocations = stops?.map((stop) => stop.location);
  const {totalDistance} = useTotalDistance({stops: stopLocations || []});

  const {data: shipmentModes} = useModesQuery();
  const {data: shipmentEquipmentTypes} = useEquipmentTypesQuery();

  const mode = shipmentModes?.find((mode) => mode.id === formMode);
  const equipmentType = shipmentEquipmentTypes?.find((equipment) => equipment.id === formEquipmentType);

  const isDisabled =
    !origin?.formatted_address || !destination?.formatted_address || !mode || !equipmentType || !totalDistance;

  const [showModal, toggleShowModal] = useToggle();
  const {selectedApplicableContract, handleContractChange, isLoading, contractCarrierRelationship, chargeLineItems} =
    useSelectedContract();

  const handleChange = () => {
    const values = chargeLineItems?.map(({unit_quantity, category, unit_name, unit_amount}) => ({
      unit_quantity,
      category,
      unit_name,
      unit_amount
    }));

    if (
      values &&
      contractCarrierRelationship &&
      contractCarrierRelationship.carrier_status === CarrierRelationshipCarrierStatusEnum.Active
    ) {
      change('newShipmentForm', 'financials', values);

      // This will prevent carrier information, like `driver`,
      // that was already filled, from being erased.
      if (formValues.carrier?.value !== contractCarrierRelationship.shipwell_vendor?.id) {
        change('newShipmentForm', 'carrier', {
          value: contractCarrierRelationship.shipwell_vendor?.id,
          label: contractCarrierRelationship.name,
          disabled: false
        });
      }

      toggleShowModal();
    }
  };

  return (
    <>
      <DeprecatedButton variant="tertiary" onClick={toggleShowModal} disabled={isDisabled}>
        <span className="font-normal">Load From Contract</span>
      </DeprecatedButton>
      <Modal
        title="Load From Contract"
        show={showModal}
        onClose={toggleShowModal}
        onPrimaryAction={handleChange}
        bodyVariant="disableOverflowScroll"
      >
        {!!mode?.code && !!equipmentType?.machine_readable ? (
          <div className="flex flex-col gap-y-4">
            <span className="font-bold">Selecting a contract will override shipment carrier and financials.</span>
            <ContractSelect
              stopAddresses={stopAddresses}
              contract={selectedApplicableContract?.contract}
              onChange={handleContractChange}
              modes={[mode.code as ContractCriteriaByValueRequestModeEnum]}
              equipment={[equipmentType?.machine_readable as EquipmentTypeValues]}
              currencyOfRecord={selectedApplicableContract?.contract?.rate_currency || ContractRateCurrencyEnum.Usd}
              label="Load Financials from Contract"
            />
            {contractCarrierRelationship &&
            contractCarrierRelationship?.carrier_status !== CarrierRelationshipCarrierStatusEnum.Active ? (
              <InlineNotification title="Invalid Carrier Status" variant={InlineNotificationVariantEnum.error}>
                Carrier Status must be Active
              </InlineNotification>
            ) : null}
            {selectedApplicableContract ? (
              <SelectedContract
                applicableContractWithCharges={selectedApplicableContract}
                modes={[mode.description || '--']}
                equipment={[equipmentType.name || '--']}
                isLoading={isLoading}
              />
            ) : null}
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default connect(
  (state: never) => ({
    formValues: getFormValues('newShipmentForm')(state)
  }),
  {change}
)(LoadFromContract);
