import {ELDDeviceLocation} from '@shipwell/backend-core-singlerequestparam-sdk';

export const exportToCSV = (breadcrumbs: ELDDeviceLocation[]) => {
  if (!breadcrumbs || breadcrumbs?.length === 0) {
    return;
  }

  const remappedData: Record<string, string>[] = breadcrumbs.map((item, index) => {
    return {
      'Movement #': `${index + 1}`,
      'Date/Time': item.update_time,
      'Lat, Lon': `${item.lat}, ${item.lon}`
    };
  });

  const csvRows = [];
  const headers = Object.keys(remappedData[0]);
  csvRows.push(headers.map((header) => `"${header}"`).join(','));

  for (const row of remappedData) {
    const values = headers.map((header) => `"${row[header]}"`);
    csvRows.push(values.join(','));
  }

  const csvString = csvRows.join('\n');
  const blob = new Blob([csvString], {type: 'text/csv'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'sensor_data.csv';
  a.click();
  URL.revokeObjectURL(url);
};
