import withConditionalFallback from 'App/components/withConditionalFallback';
import withFlags from 'App/utils/withFlags';
import classNames from 'classnames';
import {Link, browserHistory} from 'react-router';
import {compose} from 'recompose';

type ConditionalLinkProps = {
  onClick?: () => unknown;
  isMainNav?: boolean;
  newShipmentBuilderPreference?: boolean;
  newShipmentCreationExperience?: boolean;
};

const CreateDrayageShipmentLink = ({
  onClick,
  isMainNav = false,
  newShipmentBuilderPreference,
  newShipmentCreationExperience
}: ConditionalLinkProps) => (
  <Link
    to={
      newShipmentCreationExperience && newShipmentBuilderPreference === true
        ? '/shipment/drayage/create'
        : '/drayage-shipment/create'
    }
    className={classNames({'text-xs pl-6 uppercase': isMainNav})}
    activeClassName="active"
    onClick={(e) => {
      if (window.location.pathname.includes('/shipment') && window.location.pathname.includes('/create')) {
        e.preventDefault();
        if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
          browserHistory.push(`/shipment/router/drayage`);
        } else {
          browserHistory.push('/drayage-shipment/create`);');
        }
      }
      if (onClick) {
        onClick();
      }
    }}
  >
    Drayage
  </Link>
);

const OldCreateDrayageShipmentLink = ({
  onClick,
  isMainNav = false,
  newShipmentBuilderPreference,
  newShipmentCreationExperience
}: ConditionalLinkProps) => (
  <Link
    to={
      newShipmentCreationExperience && newShipmentBuilderPreference === true
        ? '/shipment/drayage/create'
        : '/new-shipment-drayage'
    }
    className={classNames('uppercase', {'text-xs pl-6': isMainNav})}
    activeClassName="active"
    onClick={(e) => {
      if (window.location.pathname.includes('/shipment') && window.location.pathname.includes('/create')) {
        e.preventDefault();

        if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
          browserHistory.push(`/shipment/router/drayage`);
        } else {
          browserHistory.push('/new-shipment-drayage`);');
        }
      }
      if (onClick) {
        onClick();
      }
    }}
  >
    Drayage
  </Link>
);

const CreateIntermodalShipmentLink = ({
  onClick,
  isMainNav = false,
  newShipmentBuilderPreference,
  newShipmentCreationExperience
}: ConditionalLinkProps) => (
  <Link
    to={
      newShipmentCreationExperience && newShipmentBuilderPreference === true
        ? '/shipment/intermodal/create'
        : '/new-shipment-intermodal'
    }
    className={classNames('uppercase', {'text-xs pl-6': isMainNav})}
    activeClassName="active"
    onClick={(e) => {
      if (window.location.pathname.includes('/shipment') && window.location.pathname.includes('/create')) {
        e.preventDefault();

        if (newShipmentCreationExperience && newShipmentBuilderPreference === true) {
          browserHistory.push(`/shipment/router/intermodal`);
        } else {
          browserHistory.push('/new-shipment-intermodal');
        }
      }
      if (onClick) {
        onClick();
      }
    }}
  >
    Intermodal
  </Link>
);

export const ConditionalCreateDrayageShipmentLink = compose<ConditionalLinkProps, ConditionalLinkProps>(
  withFlags('platNewCreateDrayage'),
  withConditionalFallback(
    ({platNewCreateDrayage}: {platNewCreateDrayage: boolean}) => !platNewCreateDrayage,
    OldCreateDrayageShipmentLink
  )
)(CreateDrayageShipmentLink);

export const ConditionalCreateIntermodalShipmentLink = compose<ConditionalLinkProps, ConditionalLinkProps>(
  withFlags('fmNewCreateIntermodal'),
  withConditionalFallback(({fmNewCreateIntermodal}: {fmNewCreateIntermodal: boolean}) => !fmNewCreateIntermodal, null)
)(CreateIntermodalShipmentLink);
