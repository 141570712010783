import {OverlayTrigger} from 'react-bootstrap';
import {Tooltip} from 'react-bootstrap';
import {Rate} from 'src/@types/quotingTypes';

type Props = {
  quote: Rate;
  companyId: string;
  companyName: string;
};

export const QuoteOwner = ({quote, companyId, companyName}: Props) => {
  if (quote.isGenesisRate) return <span>{quote.service_level || '--'}</span>;

  const getServiceCode = (quote: Rate) => {
    if (quote?.created_by_company?.id === companyId) {
      return companyName?.split('')[0].toUpperCase();
    }

    return quote?.created_by_company?.name?.split('')[0].toUpperCase();
  };

  if (quote?.created_by_company?.is_shipwell === true) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip placement="bottom" id="cloudtrucks_rate">
            <span>CloudTrucks rate</span>
          </Tooltip>
        }
      >
        <img className="quoteItem__belongsToCloudTrucks" src="/images/cloudtrucks_logo.png" alt="CloudTrucks" />
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip placement="bottom" id="quote-created_by_company">
          <span>{quote.created_by_company?.id === companyId ? companyName : quote.created_by_company?.name} rate</span>
        </Tooltip>
      }
    >
      <span className="quoteItem__belongsTo">{getServiceCode(quote)}</span>
    </OverlayTrigger>
  );
};
