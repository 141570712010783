import PropTypes from 'prop-types';
import {Card} from '@shipwell/shipwell-ui';
import {formatCurrency} from 'App/utils/internationalConstants';
import {formatDateTime} from 'App/utils/globals';
import './styles.scss';

const TenderDetails = (props) => {
  const {shipment, tenderDetails = {}} = props;
  const tenderedTo = tenderDetails && tenderDetails.tender_to_company && tenderDetails.tender_to_company.name;
  const rate =
    tenderDetails.charge_line_items &&
    tenderDetails.charge_line_items.reduce((total, item) => total + item.unit_amount * item.unit_quantity, 0);
  const requested =
    tenderDetails.charge_line_items &&
    tenderDetails.charge_line_items.length > 0 &&
    tenderDetails.charge_line_items[0].created_at;

  return (
    <Card
      title={<span>Load # {shipment.metadata && shipment.metadata.load_board_id}</span>}
      actions={<span className="shipment__tender-status font-bold">Pending Approval</span>}
    >
      <div className="shipment__tender">
        <div className="shipment__tender-body">
          <div className="shipment__tender-container">
            <div className="shipment__tender-info">
              {tenderedTo && (
                <>
                  <span>Tendered To</span>
                  <span>{tenderedTo}</span>
                </>
              )}
            </div>
            <div className="shipment__tender-info">
              <span>Rate</span>
              <span>{formatCurrency(rate)}</span>
            </div>
            <div className="shipment__tender-info">
              <span>Requested</span>
              <span>{formatDateTime(requested, true)}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

TenderDetails.propTypes = {
  shipment: PropTypes.shape({
    metadata: PropTypes.shape({
      load_board_id: PropTypes.string
    })
  }),
  tenderDetails: PropTypes.shape({
    tender_to_company: PropTypes.shape({
      name: PropTypes.string
    }),
    charge_line_items: PropTypes.arrayOf(
      PropTypes.shape({
        unit_amount: PropTypes.number,
        unit_quantity: PropTypes.number,
        created_at: PropTypes.string
      })
    )
  })
};

TenderDetails.defaultProps = {
  shipment: {},
  tenderDetails: {}
};

export default TenderDetails;
