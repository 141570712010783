import {DrayageLegOverallStatus} from '@shipwell/corrogo-sdk';
import {SvgIcon, Dropdown} from '@shipwell/shipwell-ui';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import {LegStatusPillBase} from 'App/containers/shipments/components/Status/LegStatusPill';
import {useDrayageLeg, useUpdateExecutionStatus} from 'App/data-hooks';
import {StopTypeId} from 'App/containers/shipments/components/StopsFields/constants';

const dropdownVariantOptions = [
  DrayageLegOverallStatus.CarrierConfirmed,
  DrayageLegOverallStatus.Dispatched,
  DrayageLegOverallStatus.AtPickup,
  DrayageLegOverallStatus.InTransit,
  DrayageLegOverallStatus.AtDelivery,
  DrayageLegOverallStatus.Delivered
];

// statuses in which the user cannot manually modify
const unmodifiableStatuses = [
  DrayageLegOverallStatus.Cancelled,
  DrayageLegOverallStatus.Draft,
  DrayageLegOverallStatus.Tendered
] as DrayageLegOverallStatus[];

export const LegStatusDropdown = ({legId}: {legId: string}) => {
  const {isLoading, data, context} = useDrayageLeg(legId);

  const status = data?.overall_status;

  const {updateLegExecutionStatusMutation} = useUpdateExecutionStatus(legId);

  if (isLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  if (!status || unmodifiableStatuses.includes(status)) {
    return null;
  }

  const getStopByType = context.getStopByType || noop;
  const isImportWithContainerReturn =
    context.legInfoDisplayValues?.isImport && !isNil(getStopByType(StopTypeId.ContainerReturn));

  const statusOptions = isImportWithContainerReturn
    ? [...dropdownVariantOptions, DrayageLegOverallStatus.ContainerReturned]
    : dropdownVariantOptions;

  return (
    <Dropdown icon={<SvgIcon name="Edit" />} variant="icon" indicator={false} className="p-1">
      {({onClick}: {onClick: () => void}) =>
        statusOptions
          .filter((optionStatus) => optionStatus !== status)
          .map((optionStatus) => (
            <li
              key={optionStatus}
              onClick={() => {
                updateLegExecutionStatusMutation.mutate({status: optionStatus});
                onClick();
              }}
            >
              <LegStatusPillBase fixedSize status={optionStatus} />
            </li>
          ))
      }
    </Dropdown>
  );
};
