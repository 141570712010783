import withConditionalFallback from 'App/components/withConditionalFallback';
import {useFacilityPerms} from 'App/data-hooks';
import withFlags from 'App/utils/withFlags';
import {MouseEvent} from 'react';
import {Link} from 'react-router';
import {compose} from 'recompose';

export interface FacilitiesRouteProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const FacilitiesLink = ({onClick}: FacilitiesRouteProps) => {
  const {canView} = useFacilityPerms();

  return (
    <>
      {canView ? (
        <Link to="/facilities" activeClassName="active" onClick={onClick}>
          My Facilities
        </Link>
      ) : null}
    </>
  );
};

export const ConditionalFacilitiesLink = compose<FacilitiesRouteProps, FacilitiesRouteProps>(
  withFlags('fiDockScheduling'),
  withConditionalFallback(({fiDockScheduling}: {fiDockScheduling?: boolean}) => !fiDockScheduling, null)
)(FacilitiesLink);
