import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import find from 'lodash/find';
import {DeprecatedButton, SidebarContent, Modal, Loader} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import {ErrorBoundary} from 'App/common/ErrorBoundary/ErrorBoundary';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import SendShipmentDocuments from 'App/formComponents/forms/sendShipmentDocuments';
import DocumentSelect, {NoDocuments, UploadDocuments} from 'App/formComponents/fields/documentSelect';
import {EMAIL_CONTACTS} from 'App/formComponents/forms/sendShipmentDocuments/constants';
import {
  fetchDocuments,
  fetchDocumentTypes,
  deleteDocument,
  putDocument,
  fetchDocument,
  getDocAuditLog
} from 'App/actions/_documents';
import getNil from 'App/utils/getNil';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import {
  DocumentViewLayout,
  DocumentMetadata,
  DocumentAuditLog,
  DocumentDisplay,
  DocumentActions,
  documentPropType
} from 'App/components/DocumentView';
import './styles.scss';
import {SHIPMENT_DOCUMENTS_QUERY_KEY, SHIPMENT_DOCUMENT_TYPES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {ShipmentMode} from '@shipwell/genesis-sdk';

const ShipmentDocumentView = ({
  shipment,
  document,
  documentTypes,
  auditLog,
  dispatch,
  setError,
  onClose,
  onEmailDocument
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const onFinishEdit = () => setIsEditing(false);

  const handleUpdateDocument = async (values) => {
    try {
      await dispatch(putDocument(shipment.id, values.id, values));
      dispatch(fetchDocument(shipment.id, values.id));
      dispatch(fetchDocuments(shipment.id));
      dispatch(getDocAuditLog(shipment.id));
    } catch (e) {
      setError('Error!', `Error updating document. ${get(e, 'error_description', '')}`);
      console.error('Error updating document', e);
    } finally {
      onFinishEdit();
    }
  };

  const handleDeleteDocument = async () => {
    try {
      await dispatch(deleteDocument(shipment.id, document.id));
      dispatch(fetchDocuments(shipment.id));
      dispatch(getDocAuditLog(shipment.id));
    } catch (e) {
      setError('Error!', `Error deleting document. ${get(e, 'error_description', '')}`);
      console.error('Error deleting document', e);
    } finally {
      onClose();
    }
  };

  const documentType = find(documentTypes, {id: get(document, 'type')})?.name;
  const fileType = document?.filename?.split('.').at(-1);
  const docAuditLog = auditLog.find((el) => el.id === document?.id);

  const isGenesisProvider =
    !shipment.ups_specific_options && !shipment.fedex_specific_options && !shipment.usps_specific_options;

  const isParcel = shipment.mode?.code === ShipmentMode.Parcel;

  const shouldRotateZplFile = !isGenesisProvider && isParcel;

  return (
    <>
      <DocumentViewLayout>
        <div>
          <DocumentMetadata document={document} documentType={documentType}>
            {isEditing ? (
              <ShipmentDocumentForm edit values={document} onCancel={onFinishEdit} onSubmit={handleUpdateDocument} />
            ) : handleUpdateDocument ? (
              <DeprecatedButton
                variant="tertiary"
                onClick={() => setIsEditing(true)}
                className="shipment__collaboration-documents-view__editButton"
              >
                Edit Details
              </DeprecatedButton>
            ) : null}
          </DocumentMetadata>
          {docAuditLog?.emails?.length > 0 ? <DocumentAuditLog auditLogEmails={docAuditLog.emails} /> : null}
        </div>
        <DocumentDisplay shouldRotateZplFile={shouldRotateZplFile} document={document} fileType={fileType} />
      </DocumentViewLayout>
      <DocumentActions
        document={document}
        onDeleteDocument={handleDeleteDocument}
        onEmailDocument={onEmailDocument}
        fileType={fileType}
      />
    </>
  );
};

ShipmentDocumentView.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  document: documentPropType,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onEmailDocument: PropTypes.func,
  ...WithStatusToastsPropTypes
};

ShipmentDocumentView.defaultProps = {
  ...WithStatusToastsDefaultProps
};

const ConnectedShipmentDocumentView = compose(
  withStatusToasts,
  connect((state) => ({
    shipment: state.shipmentdetails.one,
    documentTypes: state.documents.documentTypes,
    document: state.documents.document,
    auditLog: state.documents.auditLog
  }))
)(ShipmentDocumentView);

const DocumentsController = ({shipment, documents, document, documentTypes, dispatch, setSuccess, setError}) => {
  const [loading, setLoading] = useState(false);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [showDocsModal, setShowDocsModal] = useState(false);
  const [viewingDocument, setViewingDocument] = useState(false);
  const queryClient = useQueryClient();
  const documentsData = documents?.documents?.results || [];
  const documentsPaginatedResponse = documents?.documents;
  useEffect(() => {
    if (!documentTypes.length) {
      dispatch(fetchDocumentTypes());
    }
  }, [documentTypes, dispatch]);

  useEffect(() => {
    if (documentTypes?.length) {
      queryClient.setQueryData([SHIPMENT_DOCUMENT_TYPES_QUERY_KEY], documentTypes);
    }
  }, [documentTypes, queryClient]);

  useEffect(() => {
    queryClient.setQueryData([SHIPMENT_DOCUMENTS_QUERY_KEY, shipment.id], documentsPaginatedResponse);
  }, [documents, documentsPaginatedResponse, queryClient, shipment.id]);

  useEffect(() => {
    setLoading(true);
    if (shipment.id) {
      Promise.all([dispatch(fetchDocuments(shipment.id)), dispatch(getDocAuditLog(shipment.id))]).finally(() =>
        setLoading(false)
      );
    }
  }, [dispatch, shipment]);

  const handleSuccess = (title, message) => {
    setSuccess(title, message, 'top-right', {portal: true});
    setSelectedDocumentIds([]);
  };

  const handleFetchDocument = async (clickedDocument) => {
    setLoading(true);
    try {
      await dispatch(fetchDocument(shipment.id, clickedDocument.id));
      setViewingDocument(true);
    } catch (e) {
      setError('Error!', `Error fetching document ${get(e, 'error_description', '')}`);
      console.error('Error fetching document', e);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailDocument = (document) => {
    setViewingDocument(false);
    if (document?.id) {
      setSelectedDocumentIds([document.id]);
    } else {
      setSelectedDocumentIds([]);
    }
    setShowDocsModal(true);
  };

  return (
    <>
      <div className="shipment__collaboration-documents">
        <ErrorBoundary fallback={() => 'There was an error displaying the documents.'}>
          {loading ? (
            <Loader />
          ) : documentsData.length ? (
            <DocumentSelect
              documents={documentsData}
              selectedDocuments={selectedDocumentIds}
              setSelectedDocuments={setSelectedDocumentIds}
              hidePreviewCta
              onClickDocument={handleFetchDocument}
            />
          ) : (
            <NoDocuments />
          )}
        </ErrorBoundary>
        <DeprecatedButton
          className="email"
          disabled={loading || selectedDocumentIds.length < 1}
          onClick={() => setShowDocsModal(true)}
        >
          {loading ? 'Loading...' : 'Email Documents'}
        </DeprecatedButton>
      </div>
      <Modal show={showDocsModal} title="Shipment Documents" onClose={() => setShowDocsModal(false)}>
        <SendShipmentDocuments
          mode={EMAIL_CONTACTS}
          shipmentId={shipment.id}
          initialSelectedDocuments={selectedDocumentIds}
          onSuccess={handleSuccess}
          onCancel={() => setShowDocsModal(false)}
        />
      </Modal>
      <Modal
        className="h-screen-85"
        show={viewingDocument}
        title={`${find(documentTypes, {id: get(document, 'type')})?.name} • ${getNil(document, 'description', '')}`}
        onClose={() => setViewingDocument(false)}
        footerComponent={null}
        size="large"
        portal
      >
        <ConnectedShipmentDocumentView
          onClose={() => setViewingDocument(false)}
          onEmailDocument={handleEmailDocument}
        />
      </Modal>
    </>
  );
};

const ConnectedDocumentsController = compose(
  withStatusToasts,
  connect((state) => ({
    shipment: state.shipmentdetails.one,
    documents: state.documents,
    document: state.documents.document,
    documentTypes: state.documents.documentTypes
  }))
)(DocumentsController);

const SidebarDocuments = (props) => (
  <SidebarContent
    className="shipment__action-container"
    title="Documents"
    action={<UploadDocuments shipment={props.shipment} />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading*/}
    <ConnectedDocumentsController {...props} />
  </SidebarContent>
);

SidebarDocuments.tabsRole = 'TabPanel';

export default SidebarDocuments;
