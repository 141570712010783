import {RFQ} from '@shipwell/backend-core-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';

// This logic is specific for parcel shipments
export const getParcelShipmentParentRFQ = (shipment: Shipment, companyId?: string | null) => {
  if (!companyId) return null;

  const rfqs = (shipment?.rfqs || []) as RFQ[];

  if (rfqs.length > 0) {
    const rfqMatch = rfqs
      // no additional filtering; we only want to consider the latest RFQ
      .filter((rfq) => !rfq.has_parent_rfq)
      .sort((a, b) => {
        if (!a.updated_at || !b.updated_at) return 0;

        return +b.updated_at - +a.updated_at;
      })[0];

    // since parcel does not use rateshare we only want to return an RFQ if it belongs to the viewing company
    if (rfqMatch.company_owner_id === companyId) {
      return rfqMatch;
    }
  }
  return null;
};
