import {SvgIcon} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {SvgIconColors, SvgIconNames} from 'App/containers/appointments/constants';

export const AppointmentStatusIcon = ({status}: {status: AppointmentStatusEnum}) => {
  const iconName = SvgIconNames[status];
  const color = SvgIconColors[status];
  const size = '24';

  return <SvgIcon color={color} name={iconName} width={size} height={size} />;
};
