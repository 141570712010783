import {SvgIcon} from '@shipwell/shipwell-ui';

const TimelineTrackingDeviceInformation = ({
  devicesCount,
  locationStr,
  dateStr,
  isFetching
}: {
  devicesCount: number;
  locationStr: string;
  dateStr: string;
  isFetching: boolean;
}) => {
  return (
    <div className={'mb-4 flex w-11/12 flex-col rounded-md border-1 border-sw-disabled p-2'}>
      {isFetching ? (
        <div className="h-8 flex items-center content-center">
          <SvgIcon name="LoadingDots" />
        </div>
      ) : (
        <>
          {locationStr ? (
            <>
              <div className="flex flex-col font-bold text-sw-primary">Current location: {locationStr}</div>
              <div>
                <small className="text-sw-disabled-text">{dateStr} - Tracker Update</small>
              </div>
            </>
          ) : (
            <div className="flex flex-col font-bold text-sw-primary">Awaiting Tracking Data</div>
          )}
          <small className="text-sw-disabled-text">
            {devicesCount} {devicesCount > 1 ? 'Devices' : 'Device'} tracking.
          </small>
        </>
      )}
    </div>
  );
};

export default TimelineTrackingDeviceInformation;
