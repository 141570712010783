import {ELDDeviceLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {DashboardColumnsSchema} from 'App/components/dashboards/commons/dashboardColumnSchema';

export const getColumnsSchema = (breadcrumbs: ELDDeviceLocation[]) =>
  [
    {
      header: 'Movement #',
      content: (breadcrumb) => breadcrumbs.indexOf(breadcrumb) + 1
    },
    {
      header: 'Date/Time',
      content: (breadcrumb) => breadcrumb.update_time
    },
    {
      header: 'Lat, Lon',
      content: (breadcrumb) => `${breadcrumb.lat}, ${breadcrumb.lon}`
    }
  ] as DashboardColumnsSchema<ELDDeviceLocation>;
