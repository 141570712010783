import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';

export const EditableHeader = ({headerLabel, canEditShipments}: {headerLabel: string; canEditShipments?: boolean}) => {
  return (
    <>
      {canEditShipments ? (
        <Tooltip placement="top" portal tooltipContent="Click a cell in this column to edit.">
          <SvgIcon name="Edit" color={'$sw-disabled'} />
        </Tooltip>
      ) : null}
      <span>{headerLabel}</span>
    </>
  );
};
