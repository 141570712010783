import {OrderStop} from '@shipwell/corrogo-sdk';
import moment from 'moment';
import {Field, useFormikContext} from 'formik';
import {FormikDateTimePicker, SvgIcon} from '@shipwell/shipwell-ui';
import get from 'lodash/get';
import {InitialValuesOrderDates} from '../initialValues';
import RenderField from '../components/RenderField';
import {getFormattedAddressFromAddressFields} from 'App/utils/corrogo/mappers';
import './style.scss';

type OrderStopType = 'ship_from' | 'ship_to';

type UpdateOrderDatesFormProps = {
  orderNumber: string;
  type: OrderStopType;
  orderStop: OrderStop | undefined;
  index: number;
  shipmentPickupWindow: {start: string; end: string} | undefined;
  shipmentDeliveryWindow: {start: string; end: string} | undefined;
  removeSelectedById: (id: string) => void;
  orderId: string;
};

const UpdateOrderDatesForm = ({
  orderNumber,
  type,
  orderStop,
  index,
  shipmentPickupWindow,
  shipmentDeliveryWindow,
  removeSelectedById,
  orderId
}: UpdateOrderDatesFormProps) => {
  const {values, setFieldValue} = useFormikContext<InitialValuesOrderDates>();
  const label = type === 'ship_from' ? 'Pickup' : 'Delivery';
  const stopPlanningWindow = type === 'ship_from' ? shipmentPickupWindow : shipmentDeliveryWindow;
  const getFieldName = (type: OrderStopType, field: 'start' | 'end'): string => {
    return `orders[${index}][${type}].shipping_requirements.plan_window.${field}`;
  };

  const shipToStartMinDate = moment(get(values, getFieldName('ship_from', 'end'), null)).toDate();

  const closeDatePicker = (): void => {
    const event = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    document.body.dispatchEvent(event);
  };

  return (
    <div className="modify-react-datepicker">
      <div className="grid w-full grid-cols-7 gap-4">
        <div className="col-span-1">
          {type === 'ship_from' ? <RenderField value={orderNumber} label="Order Number" /> : ''}
        </div>
        <div className="col-span-2">
          <RenderField
            value={getFormattedAddressFromAddressFields(orderStop)?.formatted_address}
            label={`${label} address`}
          />
        </div>
        <div className="col-span-2">
          <Field
            prepend={<SvgIcon name="Calendar" color="$sw-icon" />}
            timezone={orderStop?.timezone}
            dateFormat="EEE MMM dd, yyyy HH:mm"
            showTimeSelect
            label={`${label} Date/Time Start`}
            component={FormikDateTimePicker}
            name={getFieldName(type, 'start')}
            minDate={type === 'ship_to' ? shipToStartMinDate : undefined}
            actions={
              <div className="absolute right-0 top-[228px] z-[1055] flex h-[190px] w-[323.06px] items-start justify-start gap-1 rounded-b border-t border-sw-border bg-sw-background-component p-1 shadow-md">
                <button
                  type="button"
                  className="flex flex-col gap-2"
                  onClick={() => {
                    setFieldValue(getFieldName(type, 'start'), stopPlanningWindow?.start);
                    setFieldValue(getFieldName(type, 'end'), stopPlanningWindow?.end);
                    closeDatePicker();
                  }}
                >
                  <span style={{color: '#006ba2'}}>Apply Shipment Pickup Date</span>
                  <span>{moment(stopPlanningWindow?.start).format('ddd MMM DD, YYYY HH:mm - HH:mm z') || '---'}</span>
                </button>
              </div>
            }
          />
        </div>
        <div className="col-span-2 flex items-center justify-between">
          <div className="mb-3 flex-1">
            <Field
              prepend={<SvgIcon name="Calendar" color="$sw-icon" />}
              timezone={orderStop?.timezone}
              dateFormat="EEE MMM dd, yyyy HH:mm"
              showTimeSelect
              label={`${label} Date/Time End`}
              component={FormikDateTimePicker}
              name={getFieldName(type, 'end')}
              actions={
                <div className="absolute right-0 top-[228px] z-[1055] flex h-[190px] w-[323.06px] items-start justify-start gap-1 rounded-b border-t border-sw-border bg-sw-background-component p-1 shadow-md">
                  <button
                    type="button"
                    className="flex flex-col gap-2"
                    onClick={() => {
                      setFieldValue(getFieldName(type, 'start'), stopPlanningWindow?.start);
                      setFieldValue(getFieldName(type, 'end'), stopPlanningWindow?.end);
                      closeDatePicker();
                    }}
                  >
                    <span style={{color: '#006ba2'}}>Apply Shipment Pickup Date</span>
                    <span>{moment(stopPlanningWindow?.end).format('ddd MMM DD, YYYY HH:mm - HH:mm z') || '---'}</span>
                  </button>
                </div>
              }
            />
          </div>
          <SvgIcon name="TrashOutlined" className="mb-4 ml-4" onClick={() => removeSelectedById(orderId)} />
        </div>
      </div>
    </div>
  );
};

export default UpdateOrderDatesForm;
