import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import set from 'lodash/set';
import {validation as pocValidation} from '../../pointOfContactFields/utils/validation';
import {makeValidationErrors} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';

/**
 * Validation for `Shipment Stop` form
 * @param  {Object} values Field values from form
 * @return {Object}        Errors
 */
export const validation = (stop, stopCustomFields = []) => {
  const errors = {};

  /** address validation */
  if (stop.location && !stop.location.address) {
    errors.location = Object.assign({}, errors.location, {address: 'Enter a location'});
  } else if (stop.location.address && !Object.keys(stop.location.address).length) {
    errors.location = Object.assign({}, errors.location, {address: 'Enter a location'});
  } else if (stop.location.address && !stop.location.address.formatted_address) {
    errors.location = Object.assign({}, errors.location, {
      address: 'Enter a complete address with a street name/number, city, state, and postal code'
    });
  }

  /** point of contact validation */
  if (stop.location && stop.location.point_of_contacts) {
    stop.location.point_of_contacts.forEach((contact, index) => {
      const pocErrors = pocValidation(contact);

      if (Object.keys(pocErrors).length) {
        if (!errors.location) {
          errors.location = {};
        }
        if (!errors.location.point_of_contacts) {
          errors.location.point_of_contacts = [];
        }
        if (Object.keys(pocErrors).length) {
          errors.location.point_of_contacts[index] = pocErrors;
        }
      }
    });
  }

  /** custom field validation */
  if (stopCustomFields?.length > 0) {
    set(
      errors,
      `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentStop)}`,
      makeValidationErrors(stop, stopCustomFields, CustomFieldEntityTypesEnum.ShipmentStop)
    );
  }

  return errors;
};
