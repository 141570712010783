import withConditionalFallback from 'App/components/withConditionalFallback';
import {State} from 'App/reducers/types';
import withFlags from 'App/utils/withFlags';
import {useFlags} from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {compose} from 'recompose';

const IntegrationsRoute = () => (
  <Link to="/company/integrations" activeClassName="active">
    Integrations
  </Link>
);

export const ConditionalIntegrationsLink = compose(
  connect((state: State) => ({
    hasIntegrationsCompanyFlagEnabled: get(state, 'userCompany.company.feature_flags.integrations_enabled', false),
    hasManageIntegrationsPermissions:
      state.userProfile.user.permissions?.includes('integrations.view_integrations') ||
      state.userProfile.user.permissions?.includes('integrations.add_integrations') ||
      state.userProfile.user.permissions?.includes('integrations.manage_integrations') ||
      state.userProfile.user.permissions?.includes('integrations.remove_integrations')
  })),
  withConditionalFallback(
    ({
      hasIntegrationsCompanyFlagEnabled,
      hasManageIntegrationsPermissions
    }: {
      hasIntegrationsCompanyFlagEnabled: boolean;
      hasManageIntegrationsPermissions: boolean;
    }) => !hasIntegrationsCompanyFlagEnabled || !hasManageIntegrationsPermissions,
    null
  )
)(IntegrationsRoute);

const FuelSurchargesRoute = () => (
  <Link className="subLink" to="/company/lane-management/fuel-surcharges" activeClassName="active">
    Fuel Surcharges
  </Link>
);

export const ConditionalFuelSurchargesLink = compose(
  withFlags('fuelSurchargeRateTable'),
  withConditionalFallback(
    ({fuelSurchargeRateTable}: {fuelSurchargeRateTable: boolean}) => !fuelSurchargeRateTable,
    null
  )
)(FuelSurchargesRoute);

const AccessorialRatesRoute = () => (
  <Link className="subLink" to="/company/lane-management/accessorial-rates" activeClassName="active">
    Accessorial Rates
  </Link>
);

export const ConditionalAccessorialRatesLink = compose(
  connect((state) => ({
    rateTablesEnabled: get(state, 'userCompany.company.feature_flags.rate_tables_enabled', false)
  })),
  withConditionalFallback(({rateTablesEnabled}: {rateTablesEnabled: boolean}) => !rateTablesEnabled, null)
)(AccessorialRatesRoute);

export const CarrierComplianceRoute = () => (
  <Link to={'/company/defaults/carrier-compliance'} className="subLink" activeClassName="active">
    Carrier Compliance
  </Link>
);

const CompassAlertsRoute = () => (
  <Link to="/company/defaults/compass-alerts" className="subLink" activeClassName="active">
    Compass Alerts
  </Link>
);

export const ConditionalCompassAlertsLink = compose(
  connect((state) => ({
    alertsDashBoardEnabled: get(state, 'userCompany.company.feature_flags.alerts_dashboard_enabled', false)
  })),
  withConditionalFallback(
    ({alertsDashBoardEnabled}: {alertsDashBoardEnabled: boolean}) => !alertsDashBoardEnabled,
    null
  )
)(CompassAlertsRoute);

const MileageConfigurationsLinkBase = () => (
  <Link to="/company/defaults/customer-settings" className="subLink" activeClassName="active">
    Customer Settings
  </Link>
);

export const ConditionalCustomerSettingsLink = compose(
  withFlags('gaMileageConfigurations'),
  withConditionalFallback(
    ({gaMileageConfigurations}: {gaMileageConfigurations: boolean}) => !gaMileageConfigurations,
    null
  )
)(MileageConfigurationsLinkBase);

const LoadOptimizationLink = () => (
  <Link to="/company/defaults/load-optimization-configurations" className="subLink" activeClassName="active">
    Load Optimization
  </Link>
);

export const ConditionalLoadOptimizationLink = compose(
  withFlags('gaShipmentAssemblyMultiTruck'),
  withConditionalFallback(
    ({gaShipmentAssemblyMultiTruck}: {gaShipmentAssemblyMultiTruck: boolean}) => !gaShipmentAssemblyMultiTruck,
    null
  )
)(LoadOptimizationLink);

export const ConditionalSettlementsConfigsLink = () => {
  const {stmInvoiceRuleConfigurationsDashboard} = useFlags();
  if (!stmInvoiceRuleConfigurationsDashboard) return null;
  return (
    <Link to="/company/settlements-configurations" className="subLink" activeClassName="active" role="link">
      Settlements
    </Link>
  );
};

export const ConditionalInternationalConfigsLink = () => {
  const {stmShipmentCurrencyOfRecord} = useFlags();
  if (!stmShipmentCurrencyOfRecord) return null;
  return (
    <Link to="/company/international-configurations" className="subLink" activeClassName="active" role="link">
      International Configs
    </Link>
  );
};

const RfpAlphaLink = () => (
  <Link to="/company/lane-management/rfps_import" className="subLink" activeClassName="active">
    RFP Import
  </Link>
);

export const ConditionalRfpLinkAlpha = compose(
  withFlags('wfaCompanyRfpAlpha'),
  withConditionalFallback(({wfaCompanyRfpAlpha}: {wfaCompanyRfpAlpha: boolean}) => !wfaCompanyRfpAlpha, null)
)(RfpAlphaLink);

const RfpLink = () => (
  <Link to="/company/lane-management/rfps" className="subLink" activeClassName="active">
    RFP Automation
  </Link>
);

export const ConditionalRfpLink = compose(
  withFlags('wfaCompanyRfp'),
  withConditionalFallback(({wfaCompanyRfp}: {wfaCompanyRfp: boolean}) => !wfaCompanyRfp, null)
)(RfpLink);
