import {Dropdown} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Link} from 'react-router';
import {useAppointments, useUserMe} from 'App/data-hooks';
import {ConditionalAppointmentsLink} from 'App/containers/appointments/links';
import {ConditionalFacilitiesLink} from 'App/containers/facilities/links';

const DockSchedulingNavigation = () => {
  const {data: auth, isLoading, isError} = useUserMe();

  const {fiDockScheduling} = useFlags();
  const hasAppointmentsViewPermission = auth?.user?.permissions?.includes('dock_scheduling.view_appointments') || false;
  const hasSchedulingViewPermission = auth?.user?.permissions?.includes('dock_scheduling.view_facilities') || false;

  const isCarrier = !!auth?.company?.carrier && !auth?.company?.shipper;
  const isShipper = !!auth?.company?.shipper;

  const {
    appointments: {entries}
  } = useAppointments(
    {page: 1, pageSize: 1},
    {
      enabled: isCarrier // only need to fetch appointments to check if a carrier should see the "appointments" link.
    }
  ); // we only need to see if the user has any

  // the feature flag should be considered if you are not a carrier
  if ((!fiDockScheduling && !isCarrier) || isLoading || isError) return null;

  // order matters here. Since users can be both shippers and carriers we want them to have this flow
  // first. If they are just a shipper they will get the default as expected. If they are just a carrier
  // they will get the appointments link.
  if (isCarrier) {
    return entries?.length ? (
      <Link to="/appointments" activeClassName="active">
        Appointments
      </Link>
    ) : null;
  }

  if (isShipper && (!hasSchedulingViewPermission || !hasAppointmentsViewPermission)) {
    return null;
  }

  return (
    <Dropdown title="Scheduling" variant="tertiary" className="nav-submenu" indicator>
      {({onClick}: {onClick(): void}) => [
        hasAppointmentsViewPermission ? (
          <li key="manage-appointments">
            <ConditionalAppointmentsLink onClick={onClick} />
          </li>
        ) : null,
        hasSchedulingViewPermission ? (
          <li key="manage-facilities">
            <ConditionalFacilitiesLink onClick={onClick} />
          </li>
        ) : null
      ]}
    </Dropdown>
  );
};

export default DockSchedulingNavigation;
