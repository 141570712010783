import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import classNames from 'classnames';

/**
 * Form Footer
 * Formik wrapper for the footer component
 */
const FormFooter = (props) => {
  const {isSubmitting, dirty} = useFormikContext();

  return <Footer isSubmitting={isSubmitting} dirty={dirty} {...props} />;
};

/**
 *
 * Footer
 * A sticky footer.
 */
export const Footer = ({
  primaryActionName,
  secondaryActionName,
  onCancel,
  options,
  dirty,
  ignoreIsDirty,
  onSubmit,
  isSubmitting,
  displaySecondaryLeft,
  primaryActionDisabled
}) => (
  <div
    className={classNames(
      'flex justify-end fixed bottom-0 left-0 right-0 px-8 py-4 z-10 border-t border-sw-border bg-sw-background',
      {'justify-between': displaySecondaryLeft}
    )}
    id="form-footer"
  >
    {onCancel && (
      <DeprecatedButton
        className={classNames({'ml-4': !displaySecondaryLeft})}
        variant="secondary"
        disabled={isSubmitting}
        onClick={onCancel}
      >
        {secondaryActionName}
      </DeprecatedButton>
    )}
    <div className="flex items-center">
      {options ? <span className="ml-4">{options}</span> : null}
      <DeprecatedButton
        aria-label={primaryActionName}
        className="ml-4"
        type={onSubmit ? undefined : 'submit'}
        onClick={onSubmit}
        disabled={isSubmitting || !(ignoreIsDirty || dirty) || primaryActionDisabled}
        loading={isSubmitting}
      >
        {primaryActionName}
      </DeprecatedButton>
    </div>
  </div>
);

Footer.propTypes = {
  onCancel: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  primaryActionName: PropTypes.string,
  secondaryActionName: PropTypes.string,
  ignoreIsDirty: PropTypes.bool,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  displaySecondaryLeft: PropTypes.bool,
  primaryActionDisabled: PropTypes.bool
};

Footer.defaultProps = {
  options: null,
  primaryActionName: 'Save',
  secondaryActionName: 'Cancel',
  ignoreIsDirty: false,
  dirty: true,
  isSubmitting: false,
  onSubmit: undefined, // used when footer is not within a form,
  displaySecondaryLeft: false
};

export default FormFooter;
