import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useViewShipmentMovementLog} from './useViewShipmentMovementLog';
import {ViewShipmentMovementLogModal} from './ViewShipmentMovementLogModal';

export const ViewShipmentMovementLog = ({shipment}: {shipment: Shipment}) => {
  const {show, hide, isVisible} = useVisibility();
  const {isFetching, data: breadcrumbs} = useViewShipmentMovementLog(shipment.id);

  return (
    <>
      <DeprecatedButton variant="text" loading={isFetching} disabled={!breadcrumbs} onClick={show}>
        View Shipment Movement Log
      </DeprecatedButton>
      {!isFetching && breadcrumbs && (
        <ViewShipmentMovementLogModal
          breadcrumbs={breadcrumbs}
          show={isVisible}
          onClose={hide}
          shipmentCode={shipment.reference_id || shipment.id}
        />
      )}
    </>
  );
};
