import {Card, DisplayValue} from '@shipwell/shipwell-ui';
import first from 'lodash/first';
import {DrayageTransportationOrderOrderItemsInner} from '@shipwell/corrogo-sdk';
import {useTransportationOrder} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import {ContainerCard} from 'App/containers/shipments/components/TenderFields/ContainersView';
import {calculateTotalCharge} from 'App/containers/shipments/utils/tenderRateCalculations';
import {checkIsOverweight, generateContainerLabel} from 'App/containers/shipments/utils/typed';
import {formatCurrency} from 'App/utils/internationalConstants';

const useContainerFilters = (containers: DrayageTransportationOrderOrderItemsInner[] | undefined) => {
  const overweightContainerIds = containers
    ?.filter((container) => checkIsOverweight(container.gross_weight.value, container.gross_weight.unit))
    .map((container) => container.id);

  const hazmatContainerIds = containers?.filter((container) => container.hazmat).map((container) => container.id);

  return {overweightContainerIds, hazmatContainerIds};
};

const TenderContainersView = ({
  shipmentId,
  transportationOrderId
}: {
  shipmentId: string;
  transportationOrderId: string;
}) => {
  const {
    accessorials,
    ratePerContainer,
    queryInfo: transportationOrderQuery
  } = useTransportationOrder(transportationOrderId, shipmentId);

  const transportationOrder = transportationOrderQuery.data;
  const containers = transportationOrder?.order_items || [];

  const {overweightContainerIds, hazmatContainerIds} = useContainerFilters(containers);

  if (transportationOrderQuery.isInitialLoading) {
    return <Loader loading />;
  }

  return (
    <div className="grid h-96 items-start gap-y-4 overflow-auto pb-10">
      {containers?.map((container, index) => {
        const containerSpecificAccessorials = accessorials.filter(
          (accessorial) => accessorial.item_id === container.id
        );

        const containerSpecificTotal = ratePerContainer + calculateTotalCharge(containerSpecificAccessorials);

        const isOverweight = overweightContainerIds?.includes(container.id);
        const isHazmat = hazmatContainerIds?.includes(container.id);

        const containerIdentificationNumber = container.identification_number;
        const containerSealNumber = first(container.seals || [])?.value;

        const label = generateContainerLabel(index, containerIdentificationNumber, containerSealNumber);

        return (
          <ContainerCard
            key={container.id}
            label={label}
            isHazmat={isHazmat}
            isOverweight={isOverweight}
            lineHaulAndFuelSurchargeTotal={ratePerContainer}
            containerSpecificDebits={containerSpecificAccessorials}
            containerSpecificTotal={containerSpecificTotal}
          />
        );
      })}
    </div>
  );
};

export const TenderContainerDetails = ({
  shipmentId,
  transportationOrderId
}: {
  shipmentId: string;
  transportationOrderId: string;
}) => {
  const {
    transportationOrderDisplayValues,
    accessorials,
    ratePerContainer,
    queryInfo: transportationOrderQuery
  } = useTransportationOrder(transportationOrderId, shipmentId);

  if (transportationOrderQuery.isInitialLoading) {
    return <Loader loading />;
  }

  return (
    <Card draggableProvided={false} title="Container Details">
      <div className="flex flex-row justify-between bg-sw-background px-8 py-4">
        <DisplayValue label="Total Containers">{transportationOrderDisplayValues.numberOfContainers}</DisplayValue>
        <DisplayValue label="Shipment Total">
          {formatCurrency(
            ratePerContainer * transportationOrderDisplayValues.numberOfContainers + calculateTotalCharge(accessorials)
          )}
        </DisplayValue>
      </div>
      <div className="-mb-4 pt-4">
        <TenderContainersView shipmentId={shipmentId} transportationOrderId={transportationOrderId} />
      </div>
    </Card>
  );
};
