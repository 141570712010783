import classNames from 'classnames';
import {RFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ProviderCode, Rate} from '@shipwell/genesis-sdk';
import SideNav from 'App/common/sideNav';
import {useParcelRfqPolling} from 'App/api/quoting/hooks/useParcelRfqPolling';

export const RatesSideNav = ({
  setSelectedTab,
  selectedTab,
  rfqFromShipment,
  parcelRates
}: {
  setSelectedTab: (tab: string) => void;
  selectedTab: string;
  rfqFromShipment?: RFQ | null;
  parcelRates: Record<string, Rate[]>;
}) => {
  const {parcelRfqPollingQuery} = useParcelRfqPolling({
    rfq: rfqFromShipment
  });

  const makeSidebarTabs = (rfq: RFQ) => {
    const tabs = [
      rfq?.fedex_specific_options && {
        providerCode: 'fedex'
      },
      rfq?.ups_specific_options && {
        providerCode: 'ups'
      },
      rfq?.usps_specific_options && {
        providerCode: 'usps'
      },
      ...Object.entries(parcelRates).map(([providerCode]) => {
        return {
          providerCode: providerCode.toUpperCase()
        };
      })
    ]
      .filter(Boolean)
      // sort this in alphabetical order, cause we might have a lot of new carriers
      .sort((a, b) => {
        if (!a?.providerCode || !b?.providerCode) return 0;

        return a.providerCode.localeCompare(b.providerCode);
      });

    return tabs;
  };

  const tabs = parcelRfqPollingQuery?.data?.did_finish_autoquote && makeSidebarTabs(parcelRfqPollingQuery?.data);

  return (
    <SideNav className="h-screen-minus-header bg-sw-background">
      <button
        onClick={() => setSelectedTab('all')}
        className={classNames('w-full text-base min-h-[30px]', {
          'border-sw-primary border-l-4 bg-sw-active-light text-sw-primary': selectedTab === 'all'
        })}
      >
        All Parcel Quotes
      </button>
      {tabs &&
        tabs.map((tab) => {
          if (!tab?.providerCode) return null;

          const isCanadaPost = tab?.providerCode === ProviderCode.Canadapost;

          return (
            <button
              key={tab?.providerCode}
              className={classNames('border-transparent border-l-4 w-full text-xs min-h-[30px] text-left pl-12', {
                '!border-sw-primary bg-sw-active-light text-sw-primary': selectedTab === tab?.providerCode
              })}
              onClick={() => setSelectedTab(tab?.providerCode)}
            >
              {isCanadaPost ? 'Canada Post'.toUpperCase() : tab?.providerCode.toUpperCase()}
            </button>
          );
        })}
    </SideNav>
  );
};
