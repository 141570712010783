import {v4} from 'uuid';
import {Marker, Popup, Route, LngLat} from '@trimblemaps/trimblemaps-js';
import {SvgIcon} from '@shipwell/shipwell-ui';
import type {SvgIconName} from '@shipwell/shipwell-ui';
import range from 'lodash/range';
import {Shipment, ELDDeviceLocation} from '@shipwell/backend-core-sdk';
import DrayageStopToolTip, {StopDisplayValues} from 'App/components/trimbleMap/utils/DrayageStopTooltip';
import {arrayIncludes} from 'App/utils/betterTypedArrayMethods';
import {MARKER_INDEX_MAX} from 'App/common/constants';
export const getFirstStopCompletionTime = (shipment: Shipment) =>
  shipment.stops?.[0].confirmed_departure_at || shipment.stops?.[0].confirmed_arrival_at;

export const getTrackingLineCoordinates = (trackingPoints: ELDDeviceLocation[]) =>
  trackingPoints
    // we need to filter bad points that don't have lat/lon to avoid
    // them being interpreted as points in the (0, 0) coordinate.
    .filter((point) => point.lon && point.lat)
    .map((point) => [point.lon, point.lat]);

export type GeocodeResult = {
  Coords: {
    Lon: number;
    Lat: number;
  };
}[];

function geocodeResultToLngLatLike(geocodeResult: GeocodeResult) {
  return geocodeResult.map((result) => new LngLat(result.Coords.Lon, result.Coords.Lat));
}

export function makeRoute(geocodeList: GeocodeResult[]) {
  return new Route({
    routeId: v4(),
    stops: geocodeList.map((location) => geocodeResultToLngLatLike(location)[0])
  });
}

export function makeRouteConfig(geocodeList: GeocodeResult[]) {
  const completedRoute = makeRoute(geocodeList);
  const futureRoute = makeRoute(geocodeList);
  const entireRoute = makeRoute(geocodeList);

  return {completedRoute, futureRoute, entireRoute};
}

function getLocationType(index: number) {
  return index < MARKER_INDEX_MAX ? `STOP_${index + 1}` : 'STOP';
}

export function convertGeocodeResultsToLocations(geocodeList: GeocodeResult[], drayageStops?: StopDisplayValues[]) {
  return geocodeList.map((location, index) => ({
    coords: geocodeResultToLngLatLike(location)[0],
    type: getLocationType(index),
    details: drayageStops ? <DrayageStopToolTip stopNumber={index} stop={drayageStops[index]} /> : null
  }));
}

export function createMarker(markerElement: HTMLElement, locationType: string) {
  const offset: [number, number] = arrayIncludes(
    [MarkerType.TruckMarker, MarkerType.BoatMarker, MarkerType.RailMarker],
    locationType
  )
    ? [0, -15]
    : [0, 0];

  return new Marker(markerElement, {offset});
}

export function createPopup(locationType: string) {
  const getTooltipOffset = (type: string) => {
    if (type.includes(MarkerType.Stop)) {
      return 12;
    }
    return 28;
  };
  return new Popup({
    className: 'trimbleMap__markerPopup',
    closeButton: false,
    offset: getTooltipOffset(locationType),
    anchor: 'bottom'
  });
}

export function createTrackingPointLocation(lat: number, lon: number) {
  return {
    coords: new LngLat(lon, lat),
    type: MarkerType.TrackingPoint
  };
}

const StopMarker = ({name}: {name: SvgIconName}) => (
  <span className="size-5 rounded-full bg-sw-background">
    <SvgIcon
      name={name}
      height="24"
      width="24"
      color="$sw-icon"
      className="absolute -translate-x-0.5 -translate-y-0.5"
    />
  </span>
);

const OilWellMarker = (
  <span className="rounded-full border-2 border-sw-icon bg-sw-background p-0.5">
    <SvgIcon name="OilRig" height="24" width="24" color="$sw-icon" />
  </span>
);

const getMarker = (name: SvgIconName) => (
  <SvgIcon className="trimbleMap__marker" name={name} height="32" width="32" color="$sw-active" />
);

const numberedMarkerMap = range(0, MARKER_INDEX_MAX).reduce(
  (markerObject, index) => ({...markerObject, [`STOP_${index + 1}`]: <StopMarker name={`Num${index + 1}Outlined`} />}),
  {}
);

export enum MarkerType {
  TruckMarker = 'TRUCK_MARKER',
  Stop = 'STOP',
  TrackingPoint = 'TRACKING_POINT',
  OilWell = 'OIL_WELL',
  BoatMarker = 'BOAT_MARKER',
  RailMarker = 'RAIL_MARKER',
  AirMarker = 'AIR_MARKER',
  DispatchPoint = 'DISPATCH_POINT'
}

export const markerMap = {
  [MarkerType.TruckMarker]: getMarker('TruckMapOutlinedWhiteBackground'),
  ...numberedMarkerMap,
  [MarkerType.Stop]: <StopMarker name="LocationAdd" />,
  [MarkerType.TrackingPoint]: <div className="trimbleMap__marker-trackingPoint bg-sw-warning" />,
  [MarkerType.DispatchPoint]: <div className="trimbleMap__marker-trackingPoint bg-sw-success" />,
  [MarkerType.OilWell]: OilWellMarker,
  [MarkerType.BoatMarker]: getMarker('BoatMapOutlinedWhiteBackground'),
  [MarkerType.RailMarker]: getMarker('TrainMapOutlined'),
  [MarkerType.AirMarker]: getMarker('PlaneMapOutlined')
};
export type LineCoordinatesList = {
  mode: string;
  coords: number[];
}[][];
export const createLines = (planCoordinatesList: LineCoordinatesList) =>
  planCoordinatesList.map((loadCoordinates) => {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: loadCoordinates.map((coordinateInfo) => coordinateInfo.coords)
          }
        }
      ],
      id: `line-${v4()}`,
      //each set of coordinates in a load will have the same mode,
      //so return the mode from the first load
      mode: loadCoordinates[0].mode
    };
  });

export const createTrackingLines = (trackingCoordinates: number[][]) => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: transformLongitudes(trackingCoordinates)
        }
      }
    ],
    id: `line-${v4()}`
  };
};

// eslint-disable-next-line no-undef
export const createAirRouteLines = (stops: TrimbleMaps.LngLat[]) => {
  const coordinates = stops.map((stop) => [stop.lng, stop.lat]);

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates
        }
      }
    ],
    id: v4()
  };
};

//helper function to generate coordinates that work with Trimble's line drawing feature
//deals with crossing the 180th meridian
export function transformLongitudes(trackingCoordinates: Array<number[]>) {
  if (trackingCoordinates[0][0] < 0 && trackingCoordinates[trackingCoordinates.length - 1][0] > 0) {
    trackingCoordinates.reverse();
  }
  trackingCoordinates.forEach((coord, index) => {
    if (index > 0) {
      const [long] = coord;
      const [prevLong] = trackingCoordinates[index - 1];
      if (prevLong - long < -180 || prevLong - long > 180) {
        if (long - prevLong >= 180) {
          trackingCoordinates[index][0] -= 360;
        } else if (long - prevLong < 180) {
          trackingCoordinates[index][0] += 360;
        }
      }
    }
  });
  return trackingCoordinates;
}

export const createStopPoints = (planCoordinatesList: LineCoordinatesList) => {
  const loadCoordinatesListWithFirstStopExcluded = planCoordinatesList.map((loadCoordinatesList) =>
    loadCoordinatesList.filter((coord, index) => index > 0)
  );
  //don't show a load stop point at the origin pickup stop
  return loadCoordinatesListWithFirstStopExcluded
    .map((coordinatesList) =>
      coordinatesList.map((stopCoordinate) => {
        return {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: stopCoordinate.coords
              }
            }
          ],
          id: `point-${v4()}`,
          //each set of coordinates in a load will have the same mode,
          //so return the mode from the first load
          mode: coordinatesList[0].mode
        };
      })
    )
    .flat();
};
