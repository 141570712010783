import {MouseEvent} from 'react';
import {Link} from 'react-router';

import {useAppointmentPerms} from 'App/data-hooks';

export type AppointmentsLinkProps = {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

const AppointmentsLink = ({onClick}: AppointmentsLinkProps) => (
  <Link to="/appointments" activeClassName="active" onClick={onClick}>
    Calendar
  </Link>
);

export const ConditionalAppointmentsLink = ({onClick}: AppointmentsLinkProps) => {
  const {canView} = useAppointmentPerms();
  return canView ? <AppointmentsLink onClick={onClick} /> : null;
};
