import {RFQ, Shipment, SlimRFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useUserMe} from 'App/data-hooks';
import {GenesisRatingSchemasRatingRateRequest, ShipmentMode} from '@shipwell/genesis-sdk';
import {useQuery} from '@tanstack/react-query';
import {SHIPMENT_RATE_REQUESTS} from 'App/data-hooks/queryKeys';
import {
  getRateRequests,
  useConnectionsQuery,
  useCreateNewQuoteMutation,
  useRatesPolling
} from 'App/containers/InstantRatesV2/hooks';
import {useEffect, useRef, useState} from 'react';
import {CREATE_RFQ, SELECT_RFQ} from 'App/actions/types';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {DispatchProp} from 'react-redux';
import isEqual from 'lodash/isEqual';
import {toCreateRateRequest} from 'App/containers/InstantRatesV2/common/transform';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';
import pick from 'lodash/pick';

const getIsOutdatedRateRequest = (rfq?: SlimRFQ | RFQ, rateRequest?: GenesisRatingSchemasRatingRateRequest | null) => {
  return new Date(rfq?.created_at ?? 0).getTime() > new Date(rateRequest?.created_at ?? 0).getTime();
};

export const useShipmentRating = ({
  shipment,
  setError,
  dispatch
}: {shipment: Shipment} & Partial<WithStatusToastProps> &
  DispatchProp<{type: typeof CREATE_RFQ; payload: RFQ} | {type: typeof SELECT_RFQ; payload: RFQ}>) => {
  const {data: {company} = {}} = useUserMe();
  const {data: equipmentTypes} = useEquipmentTypesQuery();

  // gets capacity providers by mode
  const {data: connections} = useConnectionsQuery(shipment.mode?.code, {
    enabled: !!shipment.mode?.code
  });

  const isLTL = shipment.mode?.code === ShipmentMode.Ltl;
  const isShipmentOwner = shipment.customer?.id === company?.id;

  // adapted from the legacy Instant Rates table logic
  const getMostRecentRfq = () =>
    shipment.rfqs
      // LTL currently needs an RFQ that has autoquote true otherwise it won't get rates
      ?.filter(
        (rfq) =>
          !rfq.has_parent_rfq &&
          rfq.shipment_modes?.some((mode) => mode.id === shipment.mode?.id) &&
          (!shipment.significantly_modified_at ||
            new Date(rfq.created_at || 0) > new Date(shipment.significantly_modified_at)) &&
          (!isLTL || rfq.autoquote) &&
          (isShipmentOwner
            ? //if you are the owner of the shipment, filter down to just RFQs that you own (since you can't see broker's)
              rfq.company_owner_id === company?.id
            : //if you are a broker on this shipment (not the root customer), you should also be looking at the customer's RFQ
              //so that you correctly see your markup attached to the quote you are working with
              rfq.company_owner_id === shipment.customer?.id)
      )
      .sort((a, b) => {
        return new Date(b.created_at ?? 0).getTime() - new Date(a.created_at ?? 0).getTime();
      })[0];

  const [legacyRFQ, setLegacyRFQ] = useState<RFQ | SlimRFQ | undefined>(getMostRecentRfq());
  const [rateRequest, setRateRequest] = useState<GenesisRatingSchemasRatingRateRequest>();

  const watchKeys = pick(toCreateRateRequest(shipment, equipmentTypes || []), [
    'equipment',
    'transportation_mode',
    'gross_weight',
    'handling_units'
  ]);
  const watchKeysRef = useRef(watchKeys);

  const isAllowedToReRate = !!shipment.state && ['draft', 'quoting'].includes(shipment.state);

  useEffect(() => {
    if (!isEqual(watchKeysRef.current, watchKeys) && isAllowedToReRate) {
      watchKeysRef.current = watchKeys;
      setLegacyRFQ(undefined);
      setRateRequest(undefined);
    }
  }, [isAllowedToReRate, watchKeys]);

  // gets genesis rate requests from shipment id
  const getRateRequestQuery = useQuery(
    [SHIPMENT_RATE_REQUESTS, shipment.id],
    async () => {
      const rateRequest = await getRateRequests(shipment.id);
      if (rateRequest && !!shipment.mode?.code && rateRequest.transportation_mode === shipment.mode.code) {
        if (!getIsOutdatedRateRequest(legacyRFQ, rateRequest)) {
          setRateRequest(rateRequest);
          return rateRequest;
        }
      }
      return null;
    },
    {enabled: !!legacyRFQ?.id}
  );

  // mutation to create rfq and/or rate request
  const createRatesMutation = useCreateNewQuoteMutation({
    shipment: shipment,
    legacyRfq: legacyRFQ,
    options: {
      onSuccess: (data) => {
        if (data.rfq) {
          setLegacyRFQ(data.rfq);
          // set the newly created RFQ in redux
          dispatch({type: SELECT_RFQ, payload: data.rfq});
        }
        if (data.rateRequest) {
          setRateRequest(data.rateRequest);
        }
      },
      onError: (error) => {
        console.error(error);
        // Will improve error messaging in a later ticket
        setError?.('Unable to create rate request', 'Rate request could not be created please try again.');
      }
    }
  });

  useEffect(() => {
    if (
      !isAllowedToReRate ||
      createRatesMutation.isLoading ||
      createRatesMutation.isError ||
      (!!legacyRFQ?.id && (getRateRequestQuery.isLoading || !getRateRequestQuery.isFetchedAfterMount))
    ) {
      return;
    }
    const isOutdatedRateRequest = getIsOutdatedRateRequest(legacyRFQ, rateRequest);

    if ((connections?.length && (!rateRequest?.id || isOutdatedRateRequest)) || !legacyRFQ?.id)
      createRatesMutation.mutate(shipment);
  }, [
    connections?.length,
    createRatesMutation,
    getRateRequestQuery.isFetchedAfterMount,
    getRateRequestQuery.isLoading,
    isAllowedToReRate,
    legacyRFQ,
    rateRequest,
    shipment
  ]);

  const {response, hasPolledRates} = useRatesPolling({
    legacyRfq: legacyRFQ,
    rateRequestId: rateRequest?.id
  });

  const isloadingRateInformation =
    (!!shipment.mode?.code && !hasPolledRates) ||
    createRatesMutation.isLoading ||
    getRateRequestQuery.isLoading ||
    !getRateRequestQuery.isFetchedAfterMount ||
    !legacyRFQ ||
    (!!connections?.length && !rateRequest);

  return {
    rates: response.rates,
    hasPolledRates,
    legacyRFQ,
    rateRequest,
    isloadingRateInformation,
    createRatesMutation
  };
};
