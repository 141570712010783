import {useCallback, useEffect, useRef, useState} from 'react';
import {DashboardColumnsSchema} from '../../commons/dashboardColumnSchema';
import {DashboardCustomFilters} from '../../commons/dashboardCustomFilters';
import {DashboardBaseSearchParams, DashboardSearchParams} from '../../commons/dashboardSearchParams';
import {getBaseParamsFromUrlParams} from './getBaseValuesFromUrlParams';
import {getUrlParamsFromBaseParams} from './getUrlParmsFromBaseParams';
import {getSanitizedObjectsFromParams, getSanitizedObjectsFromUrlParams} from './paramsHelpers';
import {setNextUrl} from './urlHelpers';

export interface UseParamsProps<TExtraParams> {
  columnsSchema: DashboardColumnsSchema;
  customFilters?: DashboardCustomFilters<TExtraParams>;
}

export function useParams<TExtraParams>(props: UseParamsProps<TExtraParams>) {
  const customFiltersRef = useRef(props.customFilters);
  customFiltersRef.current = props.customFilters;

  const [params, setParams] = useState<DashboardSearchParams<TExtraParams>>({
    base: {},
    extra: {} as never
  });

  const setBaseParam = useCallback((param: keyof DashboardBaseSearchParams, value: unknown | undefined) => {
    setParams((prev) => {
      prev.base[param] = value as never;
      const sanitizedObjs = getSanitizedObjectsFromParams({
        params: prev,
        baseParamsToUrlParamsFn: getUrlParamsFromBaseParams,
        extraParamsToUrlParamsFn: customFiltersRef.current?.getUrlParamsFromValues
      });

      setNextUrl(sanitizedObjs.sanitizedUrlParams);
      return sanitizedObjs.sanitizedParams;
    });
  }, []);

  const setExtraParams = useCallback((value?: TExtraParams) => {
    setParams((prev) => {
      prev.extra = value || ({} as never);
      const sanitizedObjs = getSanitizedObjectsFromParams({
        params: prev,
        baseParamsToUrlParamsFn: getUrlParamsFromBaseParams,
        extraParamsToUrlParamsFn: customFiltersRef.current?.getUrlParamsFromValues
      });

      setNextUrl(sanitizedObjs.sanitizedUrlParams);
      return sanitizedObjs.sanitizedParams;
    });
  }, []);

  const resetParams = useCallback(() => {
    setNextUrl({});

    const sanitizedParams = getSanitizedObjectsFromUrlParams({
      columnsSchema: props.columnsSchema,
      baseUrlParamsToParamsFn: getBaseParamsFromUrlParams,
      extraUrlParamsToParamsFn: customFiltersRef.current?.getValuesFromUrlParams
    });

    setParams(sanitizedParams);
  }, [props.columnsSchema]);

  useEffect(() => {
    const sanitizedParams = getSanitizedObjectsFromUrlParams({
      columnsSchema: props.columnsSchema,
      baseUrlParamsToParamsFn: getBaseParamsFromUrlParams,
      extraUrlParamsToParamsFn: customFiltersRef.current?.getValuesFromUrlParams
    });

    setParams(sanitizedParams);
  }, [props.columnsSchema]);

  return {
    params,
    resetParams,
    setBaseParam,
    setExtraParams
  };
}
