import React from 'react';
import {isInitialized} from '@fullstory/browser';
import {Context} from '@rollbar/react';
import {RollbarTools} from './rollbar/rollbarTools';

export function InfoPage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataDogRUM = (window as any).DD_RUM;
  const rollbar = React.useContext(Context);

  return (
    <>
      <h2>General Info</h2>
      <table className="border-separate border-spacing-2">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Version</td>
            <td>{process.env.VERSION}</td>
          </tr>
          <tr>
            <td>Fullstory</td>
            <td>{isInitialized() ? 'true' : 'false'}</td>
          </tr>
          <tr>
            <td>Datadog</td>
            <td>{dataDogRUM ? 'true' : 'false'}</td>
          </tr>
          <tr>
            <td>Rollbar</td>
            <td>
              {rollbar ? 'true' : 'false'}
              {rollbar && <RollbarTools />}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
