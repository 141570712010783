import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ShipmentMode} from '@shipwell/genesis-sdk';
import {COMPANY_INTEGRATION_CARRIERS} from 'App/data-hooks/queryKeys';
import {EnhancedCapacityProviderSchema, getCapacityProvidersByMode} from 'App/api/integrations/typed';

export const useConnectionsQuery = (
  mode?: string | null,
  options?: Omit<
    UseQueryOptions<unknown, AxiosError, EnhancedCapacityProviderSchema[], (string | undefined)[]>,
    'queryKey'
  >
): UseQueryResult<EnhancedCapacityProviderSchema[], AxiosError> =>
  useQuery(
    [COMPANY_INTEGRATION_CARRIERS],
    async () => {
      if (!mode) throw new Error('Mode is required.');

      const response = await getCapacityProvidersByMode(mode as ShipmentMode);
      return response;
    },
    options
  );
