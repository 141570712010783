import {DeviceHistoryEntry} from '@shipwell/locus-sdk';
import columns from './sensorDataTableColumns';

export const remapDataToCsv = (data: DeviceHistoryEntry[]) => {
  return data.map((item) => {
    const remappedItem: Record<string, string> = {};
    columns.forEach((column) => {
      remappedItem[column.Header] = column.accessor(item);
    });
    return remappedItem;
  });
};
