import React from 'react';
import {Popover} from '@shipwell/shipwell-ui';
import StatusEdit from 'App/containers/Shipment/components/Status/Edit';
import {StatusCell} from 'App/containers/Dashboard/cells';
import {ShippingDashboardRow} from '@shipwell/backend-core-sdk';
export const EditableStatusCell = ({
  row,
  dashboardRefetch
}: {
  row: ShippingDashboardRow;
  dashboardRefetch?: () => void;
}) => {
  return (
    <Popover
      placement="bottom-start"
      showArrow={false}
      portal
      trigger={({
        isOpen,
        setIsOpen,
        setTriggerElement
      }: {
        isOpen: boolean;
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setTriggerElement: (el: HTMLElement | null) => void;
      }) => {
        const selectedCellStyle = isOpen ? ' border-1 border-sw-primary' : '';
        return (
          <div
            className={`flex p-2 absolute top-0 left-0 w-full h-full${selectedCellStyle}`}
            ref={setTriggerElement}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          >
            <StatusCell shippingDashboardRow={row} />
          </div>
        );
      }}
    >
      {({setIsOpen}: {setIsOpen: React.Dispatch<React.SetStateAction<boolean>>}) => (
        <StatusEdit
          dashboardShipmentId={row.id}
          onCancel={(e) => {
            e.stopPropagation();
            setIsOpen((prev) => !prev);
          }}
          onSubmit={() => {
            if (dashboardRefetch) dashboardRefetch();
            setIsOpen((prev) => !prev);
          }}
          onError={(error) => console.error(error)}
        />
      )}
    </Popover>
  );
};
