import {useState} from 'react';
import {ColumnSort, OnChangeFn, SortingState, Updater} from '@tanstack/react-table';
import {browserHistory} from 'react-router';
import {stringify} from 'query-string';
import {convertSortingStateToSortString, convertSortStringToSortingState} from '../tableUtils';

export function useTableSort({
  defaultSort,
  shouldUseRouter,
  sortParam = 'ordering'
}: {
  defaultSort?: ColumnSort;
  shouldUseRouter?: boolean;
  sortParam?: string;
} = {}): [SortingState, OnChangeFn<SortingState>, string | undefined] {
  const searchParamURL = new URLSearchParams(location.search);
  const urlSortParams = convertSortStringToSortingState(searchParamURL.get(sortParam));
  let initial: SortingState = [];
  if (shouldUseRouter && urlSortParams.length) {
    initial = urlSortParams;
  } else if (defaultSort) {
    initial = [defaultSort];
  }

  const [sorting, setSorting] = useState<SortingState>(initial);
  const sortString = sorting.length ? convertSortingStateToSortString(sorting) : undefined;
  const getOrderingParam = () => searchParamURL.get(sortParam);
  const convertOrderingParamToSortState = () => convertSortStringToSortingState(getOrderingParam());
  function onSortingChange(
    updater: Updater<SortingState>,
    dashboardViewFilters?: Record<string, string | string[]>
  ): void {
    const getState = (prevState: SortingState) => (updater instanceof Function ? updater(prevState) : updater);
    setSorting((prevSorting) => {
      const updatedSorting = getState(prevSorting);
      if (shouldUseRouter) {
        if (updatedSorting.length > 0) {
          const orderingParam = convertSortingStateToSortString(updatedSorting);
          //if we are loading dashboard view params, we use that for the search params
          //instead of using the default search params from location.search
          const dashboardViewParams = dashboardViewFilters
            ? stringify({...dashboardViewFilters, [sortParam]: orderingParam})
            : null;
          searchParamURL.set(sortParam, orderingParam);
          browserHistory.push({
            pathname: window.location.pathname,
            search: dashboardViewParams ? `?${dashboardViewParams}` : `?${searchParamURL.toString()}`
          });
        }
        //if the sorting state is unsorted, then delete the sorting param
        else {
          searchParamURL.delete(sortParam);
          browserHistory.push({
            pathname: window.location.pathname,
            search: `?${searchParamURL.toString()}`
          });
        }
      }
      return updatedSorting;
    });
    return;
  }

  return [shouldUseRouter ? convertOrderingParamToSortState() : sorting, onSortingChange, sortString];
}
