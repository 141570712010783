import isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import {
  LocationType,
  EquipmentType,
  EquipmentTypeValues,
  ShipmentLineItem,
  Shipment,
  Stop,
  Accessorial,
  ShipmentLineItemTempUnitEnum,
  ShipmentTemperatureUnitEnum,
  ProductPackageType,
  ProductPieceType
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  StopReason,
  PackagingType,
  LocationType as GenesisLocationType,
  TemperatureUnit,
  EquipmentType as GenesisEquipmentType,
  TimeWindow,
  Appointment,
  PieceType,
  Accessorials,
  CreateRateRequest,
  CommonStop,
  CommonHandlingUnit,
  CommonEquipment,
  ShipmentMode,
  CapacityProviderSchema,
  LengthUnit,
  CapacityProviderOptions,
  DimensionsMeasurementTypeEnum,
  Dimensions
} from '@shipwell/genesis-sdk';
import {reeferTypes} from 'App/utils/globals';

export const toCreateRateRequest = (
  shipment: Shipment,
  equipmentTypes: EquipmentType[],
  capacityProvider?: CapacityProviderSchema[]
): CreateRateRequest => {
  const {stops, line_items, accessorials} = shipment;
  const commonStops = transformStops(stops ?? []);
  const shipmentMode = shipment.mode?.code === 'LTL' ? ShipmentMode.Ltl : ShipmentMode.Ftl;

  return {
    ...(shipment.customer_reference_number
      ? {
          customer_reference_number: shipment.customer_reference_number
        }
      : {}),
    shipment_id: shipment.id,
    equipment: mapEquipment(shipment, equipmentTypes),
    transportation_mode: shipmentMode,
    stops: commonStops,
    capacity_providers:
      capacityProvider?.map((value): CapacityProviderOptions => ({provider_code: value.provider_code})) || [],
    preferred_currency: shipment.preferred_currency,
    gross_weight: {
      value: shipment.total_weight_override?.value || 0,
      unit: shipment.total_weight_override?.unit
    },
    accessorials: transformAccessorials(accessorials),
    ...(shipment.total_declared_value
      ? {
          total_declared_value: {
            currency: shipment.total_declared_value_currency,
            amount: shipment.total_declared_value
          }
        }
      : {}),
    handling_units: transformLineItems(line_items ?? [], commonStops, shipmentMode)
  };
};

/**
 * Maps backend core equipment to genesis equipment.
 */
const mapEquipment = (
  shipment: Omit<Shipment, 'equipment_type'> & {equipment_type?: EquipmentType | number},
  equipmentTypes: EquipmentType[]
): CommonEquipment[] => {
  // depending on where the shipment is coming from the equipment type information will change.
  // sometimes it's an object, sometimes it's just an integer. This method will test both.
  const {
    temperature_lower_limit: temperatureLowerLimit,
    temperature_upper_limit: temperatureUpperLimit,
    equipment_type: equipmentType,
    temperature_unit: temperatureUnit
  } = shipment;
  const equipment: CommonEquipment = {
    equipment_type: GenesisEquipmentType.DryVan // must have default value
  };

  const shipmentEquipmentIsNumber = typeof equipmentType === 'number';
  const isReefer = reeferTypes.some((reeferType) =>
    shipmentEquipmentIsNumber ? reeferType === equipmentType : reeferType === equipmentType?.id
  );

  if (shipmentEquipmentIsNumber) {
    const mappedEquipmentType = mapEquipmentType(equipmentTypes, equipmentType);
    if (mappedEquipmentType) {
      equipment.equipment_type = mappedEquipmentType;
    }
  } else if (equipmentType) {
    equipment.equipment_type = hackMapGenesisEquipmentToPandoEquipment(
      mapShipmentEquipmentToGenesisEquipment(equipmentType.machine_readable as EquipmentTypeValues | undefined)
    );
  }

  if (temperatureUnit && temperatureLowerLimit && temperatureUpperLimit && isReefer) {
    equipment.temperature = {
      unit: mapTemperatureUnit(temperatureUnit),
      minimum: temperatureLowerLimit,
      maximum: temperatureUpperLimit
    };
  }

  return [equipment];
};

/**
 * Maps backend core equipment type to genesis equipment type.
 */
const mapEquipmentType = (equipmentTypes: EquipmentType[], typeId?: number): GenesisEquipmentType => {
  const type = equipmentTypes?.find((et) => et.id == typeId);
  // ideally we would return this line
  const genesisEquipment = mapShipmentEquipmentToGenesisEquipment(
    type?.machine_readable as EquipmentTypeValues | undefined
  );

  // but because of issues w/ Pando/Genesis frontend needs to handle the extra mapping
  return hackMapGenesisEquipmentToPandoEquipment(genesisEquipment);
};

const mapShipmentEquipmentToGenesisEquipment = (equipmentType?: EquipmentTypeValues): GenesisEquipmentType => {
  switch (equipmentType) {
    case EquipmentTypeValues.Aircraft:
    case undefined:
      return GenesisEquipmentType.Other;
    default:
      return equipmentType;
  }
};

// hack until Pando does its own genesis equipment mapping
const hackMapGenesisEquipmentToPandoEquipment = (
  genesisEquipment: GenesisEquipmentType
): typeof GenesisEquipmentType.DryVan | typeof GenesisEquipmentType.Flatbed | typeof GenesisEquipmentType.Reefer => {
  switch (genesisEquipment) {
    case GenesisEquipmentType.Flatbed:
    case GenesisEquipmentType.Flatbed53Foot:
    case GenesisEquipmentType.FlatbedAirRide:
    case GenesisEquipmentType.FlatbedConestoga:
    case GenesisEquipmentType.FlatbedConestoga48:
    case GenesisEquipmentType.FlatbedConestoga53:
    case GenesisEquipmentType.FlatbedDouble:
    case GenesisEquipmentType.FlatbedHotshot:
    case GenesisEquipmentType.FlatbedMaxi:
    case GenesisEquipmentType.FlatbedOverdimension:
    case GenesisEquipmentType.CoFlatbed:
      return GenesisEquipmentType.Flatbed;
    case GenesisEquipmentType.Reefer:
    case GenesisEquipmentType.ReeferAirRide:
    case GenesisEquipmentType.ReeferDouble:
    case GenesisEquipmentType.ReeferIntermodal:
    case GenesisEquipmentType.ContainerReefer20Foot:
    case GenesisEquipmentType.ContainerReefer40Foot:
    case GenesisEquipmentType.ContainerReeferTank20Foot:
    case GenesisEquipmentType.RefrigeratedBoxcar:
    case GenesisEquipmentType.ContainerRefrigerated:
      return GenesisEquipmentType.Reefer;
    default:
      return GenesisEquipmentType.DryVan;
  }
};

const mapTemperatureUnit = (shipmentTemperatureUnit: ShipmentLineItemTempUnitEnum | ShipmentTemperatureUnitEnum) => {
  switch (shipmentTemperatureUnit) {
    case ShipmentLineItemTempUnitEnum.C:
    case ShipmentTemperatureUnitEnum.C:
      return TemperatureUnit.Celsius;
    default:
      return TemperatureUnit.Fahrenheit;
  }
};
/**
 * Maps backend-core data model ShipmentLineItem to genesis data model FtlShipmentLineItem.
 *
 * Stops in this method are hardcoded to "1" and "2" as full truck load only ever has two stops
 * for all items.
 *
 * - Stop sequence "1" is set to "LOAD"
 * - stop sequence "2" is set to "UNLOAD".
 *
 * Note: all values are mapped 1-to-1 or required. default values are provided if no value is provided
 * through making a shipment.
 *
 * - Numeric values that have not been provided will be set to -1.
 * - Values which can be marked as null or undefined will be set to undefined.
 */
const transformLineItems = (
  lineItems: ShipmentLineItem[],
  stops: CommonStop[],
  mode: ShipmentMode
): CommonHandlingUnit[] => {
  const pickSequenceNumber = getSequenceNumber(stops, 'p');
  const dropSequenceNumber = getSequenceNumber(stops, 'd');
  const result = lineItems.map(
    ({
      weight_unit: weightUnit,
      package_weight: packageWeight,
      value_per_piece: valuePerPiece,
      value_per_piece_currency: valuePerPieceCurrency,
      package_type: packageType,
      piece_type: pieceType,
      total_packages: totalPackages,
      freight_class: freightClass,
      length_unit: lengthUnit = LengthUnit.In,
      length,
      width,
      height,
      nmfc_item_code,
      nmfc_sub_code,
      temp_unit: tempUnit,
      refrigeration_max_temp: refrigerationMaxTemp,
      refrigeration_min_temp: refrigerationMinTemp,
      stackable,
      description
    }): CommonHandlingUnit => ({
      description: description || undefined,
      stackable,
      temperature:
        tempUnit && refrigerationMinTemp && refrigerationMaxTemp
          ? {unit: mapTemperatureUnit(tempUnit), minimum: refrigerationMinTemp, maximum: refrigerationMaxTemp}
          : undefined,
      nmfc_codes: nmfc_item_code
        ? [{code: nmfc_item_code, ...(nmfc_sub_code && {sub_code: nmfc_sub_code})}]
        : undefined,
      dimensions:
        length && width && height
          ? ({
              measurement_type: DimensionsMeasurementTypeEnum.Dimensions,
              unit: lengthUnit,
              length: {unit: lengthUnit, value: length},
              width: {unit: lengthUnit, value: width},
              height: {unit: lengthUnit, value: height}
            } as Dimensions)
          : undefined,
      weight: {
        unit: weightUnit,
        value: packageWeight ?? -1
      },
      quantity: totalPackages ?? -1,
      declared_value: isNil(valuePerPiece)
        ? undefined
        : {
            currency: valuePerPieceCurrency,
            amount: valuePerPiece
          },
      packaging_type: transformPackageType(packageType),
      piece_type: transformPieceType(pieceType),
      ...(mode === ShipmentMode.Ltl
        ? {
            freight_class: freightClass
          }
        : {}),
      associated_stops: [
        {
          reason: StopReason.Load,
          stop: {
            sequence_id: pickSequenceNumber
          }
        },
        {
          reason: StopReason.Unload,
          stop: {
            sequence_id: dropSequenceNumber
          }
        }
      ]
    })
  );

  return result ?? [];
};

/**
 * Gets the pick or drop sequence number from stops (typically the first stop). If no
 * stop is present in the list or it is undefined then -1 is set.
 */
const getSequenceNumber = (stops: CommonStop[], pickOrDrop: 'p' | 'd' = 'p'): number | -1 => {
  if (!stops || stops?.length === 0) {
    return -1;
  }

  const sequenceNumbers = stops.map((stop) => stop.sequence_number ?? -1); // set -1 as default since "0" is valid

  if (pickOrDrop.toLowerCase() === 'p') {
    return sequenceNumbers.reduce((left, right) => {
      if (left > right) {
        return right;
      }
      return left;
    });
  }

  // return last sequence number
  return sequenceNumbers.reduce((left, right) => {
    if (left > right) {
      return left;
    }
    return right;
  });
};

/**
 * Maps backend-core stop to genesis FtlStop for getting FTL rates. Stop sequences will start at "1"
 * and increase by "1" individually from there.
 *
 * Note: all values are mapped 1-to-1 or required. default values are provided if no value is provided
 * through making a shipment.
 *
 * - Numeric values that have not been provided will be set to -1.
 * - Values which can be marked as null or undefined will be set to undefined.
 * - Dates are set to default today using `moment()`.
 */
const transformStops = (stops: Stop[]): CommonStop[] => {
  const result = stops.map(
    ({
      location,
      ordinal_index,
      planned_date,
      planned_time_window_start,
      planned_time_window_end,
      accessorials,
      instructions
    }): CommonStop => {
      const {timezone, country, address_1, address_2, city, postal_code, state_province, latitude, longitude} =
        location.address;

      const sequenceNumber = isNil(ordinal_index) ? -1 : ordinal_index + 1;

      return {
        sequence_number: sequenceNumber,
        location: {
          country: country,
          line_1: address_1 || undefined,
          line_2: address_2 || undefined,
          locality: city || undefined,
          postal_code: postal_code || undefined,
          region: state_province || undefined,
          geolocation:
            isNil(latitude) || isNil(longitude)
              ? undefined
              : {
                  latitude,
                  longitude
                },
          location_type: location.location_type && transformLocationType(location.location_type),
          timezone: location.address.timezone || undefined,
          formatted_address: location.address.formatted_address || undefined,
          location_name: location.location_name
        },
        requested_window: getRequestedWindow(
          planned_date || new Date().toString(),
          timezone,
          planned_time_window_start || '00:00',
          planned_time_window_end || '00:00'
        ),
        appointment: getAppointment(
          planned_date || new Date().toString(),
          planned_time_window_start,
          planned_time_window_end,
          timezone
        ),
        contact: {
          company_name: location.company_name || location.location_name,
          first_name: location?.point_of_contacts?.[0]?.first_name ?? undefined,
          last_name: location?.point_of_contacts?.[0]?.last_name ?? undefined
        },
        instructions: instructions || undefined,
        accessorials: transformAccessorials(accessorials)
      };
    }
  );

  return result ?? [];
};

/**
 * Gets a requested window object to send to genesis. The requested window tells
 * the carrier what "dates" to look at for pickup and drop off.
 *
 * The requested window will use the planned_date from the stop and use the UTC time info
 * as the earliest time and latest time.
 */
const getRequestedWindow = (
  plannedDate: string,
  timezone?: string | null,
  plannedTimeStart?: string,
  plannedTimeEnd?: string
): TimeWindow => {
  const tz = timezone || moment.tz.guess();

  // default set these so we can at least send a value to genesis0 for rates.
  const earliestDateISO = moment(plannedDate || new Date())
    .set({
      hour: Number(plannedTimeStart?.split(':')[0]),
      minute: Number(plannedTimeStart?.split(':')[1]),
      second: 0
    })
    .tz(tz, true)
    .toISOString(true);
  const latestDateISO = moment(plannedDate || new Date())
    .set({
      hour: Number(plannedTimeEnd?.split(':')[0]),
      minute: Number(plannedTimeEnd?.split(':')[1]),
      second: 0
    })
    .tz(tz, true)
    .toISOString(true);
  return {
    earliest: earliestDateISO,
    latest: latestDateISO
  };
};

/**
 * Gets appointment object to send to genesis. The planned window tells the carrier
 * when the dispatch request should occur and in what order.
 *
 * The `appointment_number` is always set to 1 as carriers only support a single
 * appointment at this time.
 */
const getAppointment = (
  plannedDate?: string,
  plannedTimeWindowStart?: string | null,
  plannedTimeWindowEnd?: string | null,
  timezone?: string | null
): Appointment => {
  const tz = timezone || moment.tz.guess();
  const earliestTime = moment(plannedTimeWindowStart || '00:00:00', 'HH:mm:ss'),
    latestTime = moment(plannedTimeWindowEnd || '23:59:59', 'HH:mm:ss');

  const earliestDateISO = moment(plannedDate || new Date())
    .set({
      hour: earliestTime.get('hour'),
      minute: earliestTime.get('minute'),
      second: earliestTime.get('second')
    })
    .tz(tz, true)
    .toISOString(true);
  const latestDateISO = moment(plannedDate || new Date())
    .set({
      hour: latestTime.get('hour'),
      minute: latestTime.get('minute'),
      second: latestTime.get('second')
    })
    .tz(tz, true)
    .toISOString(true);

  return {
    appointment_number: 1,
    begin: earliestDateISO,
    end: latestDateISO
  };
};

/**
 * Mapps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `LocationType.Other`.
 *
 * - "Business (with dock or forklift)"
 * - "Trade Show/Convention Center"
 */
const transformLocationType = (locationType: LocationType): GenesisLocationType => {
  switch (locationType.name) {
    case 'Business (with dock or forklift)':
      return GenesisLocationType.BusinessWithDock;
    case 'Trade Show/Convention Center':
      return GenesisLocationType.Exhibition;
    default:
      return GenesisLocationType.Other;
  }
};

const mapAccessorial = (accessorial: Accessorial): Accessorials | undefined => {
  const accessoriesMap: {[key: string]: Accessorials} = {
    APPT: Accessorials.AppointmentDelivery,
    CONDEL: Accessorials.ConstructionSiteDelivery,
    CONPU: Accessorials.ConstructionSitePickup,
    CNVDEL: Accessorials.ConventionTradeshowDelivery,
    CNVPU: Accessorials.ConventionTradeshowPickup,
    DROPDEL: Accessorials.DropTrailerDelivery,
    GUR: Accessorials.Guaranteed,
    INDEL: Accessorials.InsideDelivery,
    INPU: Accessorials.InsidePickup,
    LGDEL: Accessorials.LiftgateDelivery,
    LGPU: Accessorials.LiftgatePickup,
    LTDDEL: Accessorials.LimitedAccessDelivery,
    LTDPU: Accessorials.LimitedAccessPickup,
    APPTPU: Accessorials.PickupAppointment,
    RESDEL: Accessorials.ResidentialDelivery,
    RESPU: Accessorials.ResidentialPickup,
    SATDEL: Accessorials.SaturdayDelivery,
    SATPU: Accessorials.SaturdayPickup
  };

  if (accessoriesMap[accessorial.code]) return accessoriesMap[accessorial.code];
  if (accessorial.type === 'excessive-length') return Accessorials.ExcessiveLength;
  return undefined;
};

const transformAccessorials = (accessorials: Accessorial[] | undefined): Accessorials[] | undefined => {
  return accessorials?.map((accessorial) => mapAccessorial(accessorial)).filter(Boolean) as Accessorials[];
};

/**
 * Maps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `FtlPackageType.Other`.
 */
const transformPackageType = (packageType?: ProductPackageType): PackagingType => {
  switch (packageType) {
    case ProductPackageType.Bag:
      return PackagingType.Bag;
    case ProductPackageType.Bale:
      return PackagingType.Bale;
    case ProductPackageType.Bin:
      return PackagingType.Bin;
    case ProductPackageType.Bottle:
      return PackagingType.Bottle;
    case ProductPackageType.Box:
      return PackagingType.Box;
    case ProductPackageType.Bucket:
      return PackagingType.Bucket;
    case ProductPackageType.Bundle:
      return PackagingType.Bundle;
    case ProductPackageType.Can:
      return PackagingType.Can;
    case ProductPackageType.Carton:
      return PackagingType.Carton;
    case ProductPackageType.Case:
      return PackagingType.Case;
    case ProductPackageType.Coil:
      return PackagingType.Coil;
    case ProductPackageType.Crate:
      return PackagingType.Crate;
    case ProductPackageType.Cylinder:
      return PackagingType.Cylinder;
    case ProductPackageType.Drum:
      return PackagingType.Drum;
    case ProductPackageType.FloorLoaded:
      return PackagingType.FloorLoaded;
    case ProductPackageType.Jerrican:
      return PackagingType.Jerrican;
    case ProductPackageType.Pail:
      return PackagingType.Pail;
    case ProductPackageType.Pieces:
      return PackagingType.Pieces;
    case ProductPackageType.Pkg:
      return PackagingType.Package;
    case ProductPackageType.Plt:
      return PackagingType.Pallet;
    case ProductPackageType.Reel:
      return PackagingType.Reel;
    case ProductPackageType.Roll:
      return PackagingType.Roll;
    case ProductPackageType.Skid:
      return PackagingType.Skid;
    case ProductPackageType.ToteBin:
      return PackagingType.ToteBin;
    case ProductPackageType.ToteCan:
      return PackagingType.ToteCan;
    case ProductPackageType.Tube:
      return PackagingType.Tube;
    case ProductPackageType.Unit:
      return PackagingType.Unit;
    default:
      return PackagingType.Other;
  }
};

/**
 * Maps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `FtlPackageType.Other`.
 */
const transformPieceType = (pieceType?: ProductPieceType): PieceType | undefined => {
  switch (pieceType) {
    case ProductPieceType.Bag:
      return PieceType.Bag;
    case ProductPieceType.Bale:
      return PieceType.Bale;
    case ProductPieceType.Bottle:
      return PieceType.Bottle;
    case ProductPieceType.Box:
      return PieceType.Box;
    case ProductPieceType.Bucket:
      return PieceType.Bucket;
    case ProductPieceType.Bundle:
      return PieceType.Bundle;
    case ProductPieceType.Can:
      return PieceType.Carton;
    case ProductPieceType.Carton:
      return PieceType.Carton;
    case ProductPieceType.Case:
      return PieceType.Case;
    case ProductPieceType.Coil:
      return PieceType.Coil;
    case ProductPieceType.Crate:
      return PieceType.Crate;
    case ProductPieceType.Cylinder:
      return PieceType.Cylinder;
    case ProductPieceType.Drum:
      return PieceType.Drum;
    case ProductPieceType.Jerrican:
      return PieceType.Jerrican;
    case ProductPieceType.Pail:
      return PieceType.Pail;
    case ProductPieceType.Pieces:
      return PieceType.Pieces;
    case ProductPieceType.Reel:
      return PieceType.Reel;
    case ProductPieceType.Roll:
      return PieceType.Roll;
    case ProductPieceType.Skid:
      return PieceType.Skid;
    case ProductPieceType.Tube:
      return PieceType.Tube;
    default:
      return undefined;
  }
};
