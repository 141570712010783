import upperFirst from 'lodash/upperFirst';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import moment from 'moment';
import {Button, Row, Col, FormControl, FormGroup} from 'react-bootstrap';
import {compose} from 'recompose';
import {ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import * as actions from '../../actions/shipments';
import * as marketplaceActions from '../../actions/marketplace';
import SendCustomerQuote from 'App/components/SendCustomerQuote/SendCustomerQuote';
import {unpackErrors, validateDollarValue, removeCommasAndDollarSign, formatCurrencyValue} from 'App/utils/globals';
import './_quote-financials.scss';
import withFlags from 'App/utils/withFlags';
import {SourceTypeEnum} from 'App/api/quoting/typed';

class QuoteFinancials extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);

    this.handleMarkupChange = this.handleMarkupChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleEditQuote = this.handleEditQuote.bind(this);
    this.handleLinkQuoteAndPost = this.handleLinkQuoteAndPost.bind(this);
    this.updateMarkup = this.updateMarkup.bind(this);
    this.updateMargin = this.updateMargin.bind(this);

    this.state = {
      brokerMarkup: null,
      showManualMarkup: false,
      quoteOwner: null,
      margin: ''
    };
  }

  componentDidMount() {
    if (this.props.selectedQuote) {
      const markupCharge = this.props.selectedQuote.charge_line_items.filter((e) => e.is_provider_markup === true)[0];
      if (markupCharge) {
        this.updateMargin(markupCharge.amount);
        this.setState({
          brokerMarkup: markupCharge.amount,
          showManualMarkup: true
        });
      } else {
        if (this.props.selectedQuote.customer_markup) {
          this.setState({
            showManualMarkup: true,
            brokerMarkup: this.props.selectedQuote.customer_markup
          });
          this.updateMargin(this.props.selectedQuote.customer_markup);
        } else {
          this.setState({showManualMarkup: true, brokerMarkup: ''});
        }
      }
    }
    if (this.props.customerNegotiation && this.props.customerNegotiation.recipient_emails) {
      const emails = [];
      for (var i = 0; i < this.props.customerNegotiation.recipient_emails.length; i++) {
        emails.push({
          label: this.props.customerNegotiation.recipient_emails[i],
          value: this.props.customerNegotiation.recipient_emails[i]
        });
      }
      this.props.populateRecipientEmails(emails);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedQuote !== this.props.selectedQuote) {
      const markupCharge = nextProps.selectedQuote.charge_line_items.filter((e) => e.is_provider_markup === true)[0];
      if (markupCharge) {
        this.updateMargin(markupCharge.amount);
        this.setState({
          brokerMarkup: markupCharge.amount,
          showManualMarkup: true
        });
      } else {
        if (nextProps.selectedQuote.customer_markup) {
          this.setState({
            showManualMarkup: true,
            brokerMarkup: nextProps.selectedQuote.customer_markup
          });
          this.updateMargin(nextProps.selectedQuote.customer_markup);
        } else {
          this.setState({showManualMarkup: true, brokerMarkup: ''});
        }
      }
    }
    if (nextProps.customerNegotiation !== this.props.customerNegotiation) {
      if (nextProps.customerNegotiation.recipient_emails) {
        const emails = [];
        for (var i = 0; i < nextProps.customerNegotiation.recipient_emails.length; i++) {
          emails.push({
            label: nextProps.customerNegotiation.recipient_emails[i],
            value: nextProps.customerNegotiation.recipient_emails[i]
          });
        }
        this.props.populateRecipientEmails(emails);
      }
    }
  }
  componentWillUnmount() {
    this.props.populateRecipientEmails([]);
  }

  handleMarkupChange(event) {
    let value = event.target.value;
    if (value.startsWith('$')) {
      value = value.slice(1);
    }
    value = value.replace(/,/g, '');
    this.setState({
      brokerMarkup: value.trim()
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.brokerMarkup !== this.state.brokerMarkup) {
      let total = 0;
      const selectedQuote = this.props.selectedQuote;
      if (selectedQuote && selectedQuote.charge_line_items) {
        //if there is a broker markup, adjust the total value so it adds the markup correctly
        total = selectedQuote.total;
        if (this.state.brokerMarkup) {
          for (var i = 0; i < selectedQuote.charge_line_items.length; i++) {
            if (selectedQuote.charge_line_items[i].is_provider_markup) {
              total -= selectedQuote.charge_line_items[i].amount;
            }
          }
        }
        if (selectedQuote.customer_markup !== 0) {
          total -= selectedQuote.customer_markup;
        }
        if (this.state.brokerMarkup) {
          total = parseFloat(Math.round((total + parseFloat(this.state.brokerMarkup)) * 100) / 100).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          );
        }
        if (!isNaN(parseFloat(total))) {
          this.props.setTotal(total);
        }
      }
    }
  }

  handleEditQuote(brokerMarkup) {
    return new Promise((resolve, reject) => {
      //check if the markup is different than what is in the selectedQuote
      const markupCharge = this.props.selectedQuote.charge_line_items.filter((e) => e.is_provider_markup === true)[0];
      if (!markupCharge || (markupCharge && parseFloat(markupCharge.amount) !== parseFloat(brokerMarkup))) {
        if (!validateDollarValue(brokerMarkup)) {
          reject(this.props.handleQuoteErrors('Enter a valid number for markup'));
        } else {
          //do a put on the quote first -- this quote lives on teh shipper's RFQ, so we edit that one
          const rfqId = this.props.selectedRFQ.id;
          const quoteId = this.props.selectedQuote.id;
          const quoteObj = {
            charge_line_items: JSON.parse(JSON.stringify(this.props.selectedQuote.charge_line_items)),
            mode: this.props.selectedQuote.mode.id,
            service_level: this.props.selectedQuote.service_level.id
          };
          for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
            if (quoteObj.charge_line_items[i].is_provider_markup) {
              quoteObj.charge_line_items[i].amount = parseFloat(removeCommasAndDollarSign(brokerMarkup));
              quoteObj.charge_line_items[i].unit_amount = parseFloat(brokerMarkup);
              quoteObj.charge_line_items[i].unit_quantity = 1;
            }
          }

          if (!markupCharge) {
            quoteObj.charge_line_items.push({
              is_provider_markup: true,
              unit_amount: brokerMarkup,
              unit_quantity: 1,
              unit_name: 'Item Charge',
              category: ChargeCategory.Lh,
              charge_code: 'LHS'
            });
          }
          const opts = {};
          /*if (this.props.selectedRFQ.company_owner_id) {
            opts['xCompanyId'] = this.props.selectedRFQ.company_owner_id;
          }*/
          if (this.props.selectedQuote.created_by_company.id !== this.props.company.id) {
            if (parseFloat(brokerMarkup) !== parseFloat(this.props.selectedQuote.customer_markup)) {
              //this is not our quote, so we can only modify the customer_markup field on it
              const markup = {customer_markup: brokerMarkup};
              this.props.editQuote(rfqId, quoteId, markup, opts).then((response) => {
                if (response.status === 200) {
                  resolve({status: 200});
                } else {
                  reject(this.props.handleQuoteErrors(response.details.error_description));
                }
              });
            } else {
              resolve({status: 200});
            }
          } else {
            this.props.editQuote(rfqId, quoteId, quoteObj, opts).then((response) => {
              if (response.status === 200) {
                resolve({status: 200});
              } else {
                reject(this.props.handleQuoteErrors(response.details.error_description));
              }
            });
          }
        }
      } else {
        //no update needed
        resolve({status: 200});
      }
    });
  }

  handleLinkQuoteAndPost(attrs, customerNegotiation) {
    //copy the quote object with new markup and POST it to the customer negotiation
    const quoteCopy = JSON.parse(JSON.stringify(this.props.selectedQuote));
    for (var i = 0; i < quoteCopy.charge_line_items.length; i++) {
      delete quoteCopy.charge_line_items[i].id;
      if (quoteCopy.charge_line_items[i].is_provider_markup === true) {
        //make sure the markup is up to date
        quoteCopy.charge_line_items[i].unit_amount = this.state.brokerMarkup;
        quoteCopy.charge_line_items[i].unit_quantity = 1;
      }
    }
    if (quoteCopy.charge_line_items.filter((e) => e.is_provider_markup === true).length === 0) {
      quoteCopy.charge_line_items.push({
        unit_amount: parseFloat(removeCommasAndDollarSign(this.state.brokerMarkup)) || 0,
        unit_quantity: 1,
        unit_name: 'Item Charge',
        is_provider_markup: true,
        category: ChargeCategory.Lh,
        charge_code: 'LHS'
      });
    }
    quoteCopy.mode = quoteCopy.mode.id;
    quoteCopy.service_level = quoteCopy.service_level.id;
    delete quoteCopy.id;
    delete quoteCopy.spot_negotiation_quote;
    delete quoteCopy.created_by_company;
    if (quoteCopy.carrier) {
      quoteCopy.carrier = quoteCopy.carrier.id;
    }
    if (quoteCopy.delivery_date) {
      quoteCopy.delivery_date = moment(quoteCopy.delivery_date).format('YYYY-MM-DD');
    }

    if (quoteCopy.equipment_type?.id) {
      quoteCopy.equipment_type = quoteCopy.equipment_type.id;
    }
    if (quoteCopy.mode?.id) {
      quoteCopy.mode = quoteCopy.mode.id;
    }
    if (quoteCopy.service_level?.id) {
      quoteCopy.service_level = quoteCopy.service_level.id;
    }
    if (attrs.message) {
      quoteCopy.message = attrs.message;
    }

    //the quote is constructed, now post it to the customer negotiation
    return this.props.createSpotNegotiationQuote(customerNegotiation.id, quoteCopy).then((spotResponse) => {
      if (spotResponse.status === 200) {
        //go to the bids page for this shipment
        this.context.router.push('/bids/' + customerNegotiation.id);
      } else {
        const errors = spotResponse.details.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = spotResponse.error_description;
        //handle edge cases for errors here
        if (submissionError.charge_line_items) {
          //put that on the total field
          if (submissionError.charge_line_items.unit_amount) {
            submissionError.total = submissionError.charge_line_items.unit_amount[0];
          }
        }
        throw new SubmissionError(submissionError);
      }
    });
  }

  onFormSubmit(attrs) {
    const body = {};
    body.involved_customer_users = attrs.customers.map((e) => {
      return {
        id: e.value
      };
    });
    body.involved_vendor_users = [{id: this.props.user.id}];
    return this.props.getCustomerNegotiation(this.props.selectedShipment.id, body).then((response) => {
      if (response.status === 200) {
        const customerNeg = response.details;
        //we now add a quote to that negotiation
        this.handleLinkQuoteAndPost(attrs, customerNeg);
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }
    });
  }

  updateMarkup(margin) {
    if (parseFloat(margin)) {
      let carrierTotal = 0;
      let calculatedMarkup = 0;
      if (
        this.props.selectedQuote &&
        this.props.selectedQuote.charge_line_items &&
        this.props.selectedQuote.charge_line_items.length > 0
      ) {
        for (var i = 0; i < this.props.selectedQuote.charge_line_items.length; i++) {
          if (!this.props.selectedQuote.charge_line_items[i].is_provider_markup) {
            carrierTotal += this.props.selectedQuote.charge_line_items[i].amount;
          }
        }
      }
      calculatedMarkup = parseFloat(parseFloat(margin / 100) * carrierTotal).toFixed(2);
      if (!isNaN(calculatedMarkup)) {
        this.setState({brokerMarkup: calculatedMarkup});
      }
    }
  }
  updateMargin(markup) {
    if (parseFloat(markup)) {
      let carrierTotal = 0;
      let calculatedMargin = 0;
      if (
        this.props.selectedQuote &&
        this.props.selectedQuote.charge_line_items &&
        this.props.selectedQuote.charge_line_items.length > 0
      ) {
        for (var i = 0; i < this.props.selectedQuote.charge_line_items.length; i++) {
          if (!this.props.selectedQuote.charge_line_items[i].is_provider_markup) {
            carrierTotal += this.props.selectedQuote.charge_line_items[i].amount;
          }
        }
      }
      calculatedMargin = parseFloat(parseFloat(markup / carrierTotal) * 100).toFixed(2);
      if (!isNaN(calculatedMargin)) {
        this.setState({margin: calculatedMargin + '%'});
      }
    }
  }

  render() {
    const {selectedQuote, selectedShipment, intLtl3RdPartySuppressRates} = this.props;

    const shouldHideRates =
      intLtl3RdPartySuppressRates &&
      !!selectedShipment?.metadata?.bill_to_override?.direction &&
      ['COLLECT', '3RD_PARTY'].includes(selectedShipment?.metadata?.bill_to_override?.direction) &&
      selectedShipment?.mode?.code === 'LTL';

    let chargeLineItems = [];
    let total = 0;
    let carrierTotal = 0;
    if (selectedQuote && selectedQuote.charge_line_items) {
      chargeLineItems = selectedQuote.charge_line_items.sort((a, b) => {
        if (a.is_provider_markup && !b.is_provider_markup) {
          return true;
        }
        if (!a.is_provider_markup && b.is_provider_markup) {
          return false;
        }
        return a.amount < b.amount;
      });
      //if there is a broker markup, adjust the total value so it adds the markup correctly
      total = selectedQuote.total;
      for (var i = 0; i < chargeLineItems.length; i++) {
        if (chargeLineItems[i].is_provider_markup) {
          if (this.state.brokerMarkup) {
            total -= chargeLineItems[i].amount;
          }
        } else {
          carrierTotal += chargeLineItems[i].amount;
        }
      }
      if (selectedQuote.customer_markup !== 0) {
        total -= selectedQuote.customer_markup;
      }
    }

    const isInstantRate =
      selectedQuote && [SourceTypeEnum.Instant, SourceTypeEnum.CarrierConnection].includes(selectedQuote.source_type);

    return (
      <div className="quote-financials">
        <Row>
          {selectedQuote && selectedQuote.charge_line_items && (
            <Col xs={12} sm={isInstantRate ? 12 : 6}>
              {!this.props.isConfirmPage && <h3>Ready to schedule this shipment now?</h3>}
              <div
                className={
                  this.props.isConfirmPage
                    ? 'quote-financials-form-confirm formstyle-light'
                    : 'quote-financials-form formstyle-light'
                }
              >
                <div className="quote-financials-form__header">
                  <h5>Financials</h5>
                </div>

                {chargeLineItems.map(
                  function (item, i) {
                    //if this is a broker markup charge, show the field to modify it
                    if (!item.is_provider_markup) {
                      return (
                        <div className="shipmentDetails__financials-row" key={i}>
                          <span className="shipmentDetails__row-title">{upperFirst(item.unit_name.toLowerCase())}</span>
                          <span className="text-right">
                            {shouldHideRates
                              ? '--'
                              : `$${parseFloat(Math.round(item.amount * 100) / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}`}
                          </span>
                        </div>
                      );
                    }
                  }.bind(this)
                )}

                {this.state.showManualMarkup && this.props.canMarkupQuote && (
                  <div>
                    <div className="shipmentDetails__financials-row quote-financials__carrierTotal">
                      <span className="shipmentDetails__row-title">Shipment Total</span>
                      <span className="quote-financials__carrierTotal-value text-right">
                        {shouldHideRates ? '--' : `$${formatCurrencyValue(carrierTotal)}`}
                      </span>
                    </div>
                    {!shouldHideRates &&
                      this.props.selectedShipment &&
                      this.props.selectedShipment.mode &&
                      this.props.selectedShipment.mode.code !== 'PARCEL' && (
                        <div className="shipmentDetails__financials-row">
                          <span className="shipmentDetails__row-title">Markup</span>
                          <span className="quote-financials__markupContainer">
                            <FormGroup>
                              <FormControl
                                value={this.state.margin}
                                onChange={(e) => this.setState({margin: e.target.value})}
                                onBlur={(val) => this.updateMarkup(parseFloat(val.target.value))}
                                placeholder="% Margin"
                              />
                            </FormGroup>
                            <FormGroup validationState={this.props.quoteErrors && 'error'}>
                              <FormControl
                                autoFocus
                                value={this.state.brokerMarkup}
                                onChange={this.handleMarkupChange}
                                placeholder="$ Markup"
                                onBlur={(val) => this.updateMargin(parseFloat(val.target.value))}
                              />
                            </FormGroup>
                          </span>
                        </div>
                      )}
                  </div>
                )}
                <div className="shipmentDetails__financials-total">
                  <span className="shipmentDetails__row-title">Customer Total:</span>

                  {shouldHideRates ? (
                    <span className="shipmentDetails__financials-totalDollar text-right">--</span>
                  ) : (
                    <span className="shipmentDetails__financials-totalDollar text-right">
                      $
                      {parseFloat(this.state.brokerMarkup)
                        ? parseFloat(
                            Math.round((total + parseFloat(this.state.brokerMarkup)) * 100) / 100
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        : parseFloat(Math.round(total * 100) / 100).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                    </span>
                  )}
                </div>

                <div className="pad-top text-center">
                  {this.props.quoteErrors && (
                    <p className="error-text-form-level">
                      <i className="icon icon-Delayed pad-right" />
                      {this.props.quoteErrors}
                    </p>
                  )}
                </div>
                {!this.props.isConfirmPage && (
                  <div className="text-right">
                    <Button bsStyle="primary" onClick={() => this.props.bookIt(this.state.brokerMarkup)}>
                      <i className="icon icon-Truck pad-right" />
                      Continue Booking
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          )}
          {!isInstantRate && (
            <Col xs={12} sm={6}>
              <h3>Need to send this quote to your customer?</h3>
              <SendCustomerQuote
                onSubmit={this.onFormSubmit}
                showPreview={() => this.props.showPreview()}
                defaultEmails={this.props.sendCustomerQuote && this.props.sendCustomerQuote.recipient_emails}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      selectedQuote: state.shipments.selectedQuote,
      selectedShipment: state.shipments.selectedShipment,
      selectedRFQ: state.shipments.selectedRFQ,
      company: state.auth.company,
      user: state.auth.user,
      customerNegotiation: state.marketplace.customerNegotiation,
      sendCustomerQuote: state.shipments.sendCustomerQuote
    }),
    {...marketplaceActions, ...actions}
  ),
  withFlags('intLtl3RdPartySuppressRates')
)(QuoteFinancials);
