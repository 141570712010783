import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {SubmissionError} from 'redux-form';
import get from 'lodash/get';
import has from 'lodash/has';
import pluralize from 'pluralize';
import {Modal, Toast} from '@shipwell/shipwell-ui';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {ChargeCategory, FedexShipmentOptionsPackagingEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import NewShipmentForm from './NewShipmentForm';
import CreateMultipleShipments from './components/CreateMultipleShipments';
import CreateMultipleConfirmation from './components/CreateMultipleConfirmation';
import withFlags from 'App/utils/withFlags';
import * as formActions from 'App/actions/forms';
import * as documentActions from 'App/actions/documents';
import * as usersActions from 'App/actions/users';
import * as shipmentActions from 'App/actions/shipments';
import * as vendorActions from 'App/actions/vendors';
import * as shipmentdetailsActions from 'App/actions/_shipmentDetails';
import {cleanPayload, onCreateQuoteSuccess} from 'App/containers/quotes/create/utils/createQuote';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import './_new-shipment.scss';
import {
  unpackErrors,
  constructShipmentObject,
  transformLocationAppointmentTypes,
  transformEquipmentType,
  transformShipmentMode,
  removeCommasAndDollarSign,
  isBillToFormEmpty
} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import TenderRequestForm from 'App/containers/tendering/create';
import ExecuteRoutingGuide from 'App/containers/routingGuides/execute';
import {getCarrierRelationshipsPromise, putCarrierRelationshipsCarrierRelationshipIdPromise} from 'App/api/carriers';
import {GetInternationalPreferencesQuery} from 'App/data-hooks';
import {withGetFedExAccountsQuery} from 'App/data-hooks/parcel/FedEx/hooks/useGetFedexAccounts';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';

export class NewShipment extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  static propTypes = {
    fetchShipmentId: PropTypes.func,
    handleRedirect: PropTypes.func,
    handleSetShipId: PropTypes.func,
    onFormSubmit: PropTypes.func
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.saveNewShipmentForm = this.saveNewShipmentForm.bind(this);
    this.handleBOLandEquipment = this.handleBOLandEquipment.bind(this);

    this.state = {
      errorOverride: null,
      selectedCarrier: null,
      defaultVendorPOC: null,
      showMultipleModal: false,
      creatingShipments: false,
      createdShipments: [],
      showConfirmMultiplierModal: false,
      isTender: false,
      submitSucceeded: false,
      shipmentId: null,
      showRoutingGuideModal: false,
      showConsolidationToast: false,
      newShipmentProps: null,
      redirectWhenCloseTenderForm: false
    };
  }
  componentDidMount() {
    const {company, params, location} = this.props;
    this.props.clearSelectedRelationship();

    if (params.shipment_id && company.id) {
      this.props.getShipmentDetails(params.shipment_id).then((response) => {
        // if the user try to reach this page using a PARCEL shipment, redirect to the new-quote form
        if (response.details.mode?.code === 'PARCEL') {
          this.context.router.push(`/shipments/${this.props.shipment.id}/edit`);
        }
      });
    }
    const showConsolidationToast = get(location, 'query.showSuccess', false);
    this.setState({showConsolidationToast});
  }

  componentDidUpdate(prevProps) {
    const {params, company} = this.props;
    if (params.shipment_id && company.id !== prevProps.company.id) {
      this.props.getShipmentDetails(params.shipment_id);
    }
    const showConsolidationToast = get(location, 'query.showSuccess', false);
    const prevShowConsolidationToast = get(prevProps, 'location.query.showSuccess', false);
    if (showConsolidationToast && showConsolidationToast !== prevShowConsolidationToast) {
      this.setState({showConsolidationToast});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.newShipmentForm &&
      nextProps.newShipmentForm &&
      nextProps.newShipmentForm.values &&
      nextProps.newShipmentForm.values.carrier &&
      this.props.newShipmentForm.values &&
      nextProps.newShipmentForm.values.carrier !== this.props.newShipmentForm.values.carrier
    ) {
      //get the company object to send later
      this.props.getCompanyDetails(nextProps.newShipmentForm.values.carrier.value).then((response) => {
        if (response.status === 200) {
          this.setState({selectedCarrier: response.details});
        }
      });
      //User removed carrier, update state
    } else if (this.props?.newShipmentForm?.values?.carrier && !nextProps?.newShipmentForm?.values?.carrier) {
      this.setState({selectedCarrier: null});
    }
  }

  saveNewShipmentForm(attrs) {
    if (attrs.stops) {
      attrs.stops = transformLocationAppointmentTypes(
        attrs.stops,
        this.props.locationTypes,
        this.props.appointmentTypes
      );
    }

    if (attrs.mode) {
      attrs.mode = transformShipmentMode(attrs.mode, this.props.shipmentModes);
    }

    //temporarily not doing this transform to make it work with swagger
    if (attrs.equipment_type) {
      attrs.equipment_type = transformEquipmentType(attrs.equipment_type, this.props.equipmentTypes);
    }

    const {serviceLevels} = this.props;
    if (attrs.service_level) {
      const serviceLevelId = Number(attrs.service_level);
      attrs.service_level = serviceLevels.find((sl) => sl.id === serviceLevelId) || null;
    }

    attrs.temperature_unit = getShipmentTemperatureUnit(attrs);

    const shipmentObj = constructShipmentObject(attrs, this.props.flags);
    const opts = {};
    if (attrs.book_as_customer) {
      //add the xShipperId
      opts['xCompanyId'] = attrs.book_as_customer.value;
    }
    const metadata = {tags: []};
    if (attrs.metadata && attrs.metadata.tags && attrs.metadata.tags.length > 0) {
      for (let i = 0; i < attrs.metadata.tags.length; i++) {
        if (attrs.metadata.tags[i].id) {
          metadata.tags.push(attrs.metadata.tags[i].id);
        } else {
          metadata.tags.push(attrs.metadata.tags[i]);
        }
      }
    }

    if (attrs.metadata?.max_buy_amount) {
      metadata.max_buy_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.max_buy_amount));
      metadata.max_buy_amount_currency = attrs.preferred_currency;
    }
    if (attrs.metadata?.buy_it_now_amount) {
      metadata.buy_it_now_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.buy_it_now_amount));
      metadata.buy_it_now_amount_currency = attrs.preferred_currency;
    }
    if (attrs.metadata?.target_rate_amount) {
      metadata.target_rate_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.target_rate_amount));
      metadata.target_rate_amount_currency = attrs.preferred_currency;
    }

    const {hasFTL} = checkShipmentModes(attrs.mode);
    if (hasFTL) {
      metadata.bypass_carrier_insurance_coverage = !!attrs?.metadata?.bypass_carrier_insurance_coverage;
    }

    shipmentObj.metadata = metadata;

    if (attrs.custom_data) {
      shipmentObj.custom_data = attrs.custom_data;
    }

    if (attrs.total_declared_value) {
      shipmentObj.total_declared_value = attrs.total_declared_value;
      shipmentObj.total_declared_value_currency = attrs.total_declared_value_currency;
    }

    if (attrs.total_weight_override && !has(attrs.total_weight_override, 'value')) {
      shipmentObj.total_weight_override.value = null;
    }

    if (attrs.total_quantity_override) {
      shipmentObj.total_quantity_override = attrs.total_quantity_override;
    }

    if (attrs.preferred_currency) {
      shipmentObj.preferred_currency = attrs.preferred_currency;
    }

    if (attrs.financials) {
      shipmentObj.financials = attrs.financials;
    }

    if (attrs.multiplier && attrs.multiplier > 1) {
      //we need to create multiple copies of this shipment
      this.setState({totalToCreate: attrs.multiplier, created: 0}, () => {
        return this.handleShowMultipleShipmentsModal(attrs, shipmentObj, opts);
      });
    } else {
      let saveShipmentRequest;
      if (attrs.id) {
        saveShipmentRequest = this.props.editShipment(
          attrs.id,
          cleanPayload(shipmentObj, {oldValue: ['', null, 'null', NaN], hardDelete: false}),
          opts
        );
      } else {
        saveShipmentRequest = this.props.createShipment(
          cleanPayload(shipmentObj, {oldValue: ['', null, 'null', NaN], hardDelete: false}),
          opts
        );
      }

      //POST the shipment object
      return saveShipmentRequest.then((response) => {
        if (response.status === 200) {
          //proceed to kick off the RFQ process
          const rfqObj = {};
          //default to false
          rfqObj.autoquote = false;
          rfqObj.shipment = response.details.id;
          rfqObj.parent_rfq = null;
          rfqObj.equipment_types = attrs.equipment_type ? [attrs.equipment_type] : [];
          rfqObj.shipment_modes = [attrs.mode];
          //only create an RFQ if there is financial information on this shipment, otherwise just redirect
          if (attrs.financials?.length > 0) {
            return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
              if (rfqResponse.status === 200) {
                //RFQ was created successfully, since this is a manual shipment, we need to assign the financials and carrier info
                //create a new quote
                const quoteObj = {};
                if (attrs.financials) {
                  quoteObj.charge_line_items = JSON.parse(JSON.stringify(attrs.financials));
                } else {
                  quoteObj.charge_line_items = [];
                }
                if (quoteObj.charge_line_items.length > 0) {
                  let total = 0;
                  for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
                    //strip out any commas or leading $
                    if (
                      typeof quoteObj.charge_line_items[i].unit_amount === 'string' &&
                      quoteObj.charge_line_items[i].unit_amount.startsWith('$')
                    ) {
                      quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.slice(1);
                    }
                    //replace commas if this is a string
                    if (typeof quoteObj.charge_line_items[i].unit_amount === 'string') {
                      quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.replace(
                        /,/g,
                        ''
                      );
                    }
                    quoteObj.charge_line_items[i].unit_amount = parseFloat(quoteObj.charge_line_items[i].unit_amount);
                    quoteObj.charge_line_items[i].unit_quantity = parseFloat(
                      quoteObj.charge_line_items[i].unit_quantity
                    );
                    //default to the user's preferred currency for each line item
                    quoteObj.charge_line_items[i].unit_amount_currency = attrs.preferred_currency;
                    total += parseFloat(
                      quoteObj.charge_line_items[i].unit_amount * quoteObj.charge_line_items[i].unit_quantity
                    );
                  }
                  quoteObj.total = total;
                  //if there is markup
                  if (attrs.markup) {
                    //add a new charge_line_item and update the total
                    quoteObj.charge_line_items.push({
                      unit_amount: parseFloat(attrs.markup),
                      unit_amount_currency: attrs.preferred_currency,
                      unit_name: 'Item Charge',
                      category: ChargeCategory.Lh,
                      charge_code: 'LHS',
                      is_provider_markup: true,
                      unit_quantity: 1
                    });
                    quoteObj.total += parseFloat(attrs.markup);
                    quoteObj.total = parseFloat(quoteObj.total).toFixed(2);
                  }
                }
                //add the mode that this quote will use

                quoteObj.rfq = rfqResponse.details.id;

                //add the default service mode TODO make these selectable
                quoteObj.mode = attrs.mode.id || 1;
                quoteObj.service_level = attrs.service_level || 19;
                quoteObj.equipment_type = attrs.equipment_type.id;
                //create the new quote
                return this.props.createQuote(quoteObj.rfq, quoteObj).then((quoteResponse) => {
                  if (quoteResponse.status === 200) {
                    //now award the quote to the shipment
                    return this.props
                      .awardQuote(response.details.id, {
                        quote: quoteResponse.details.id
                      })
                      .then((awardResponse) => {
                        if (awardResponse.status === 200) {
                          this.props.resetNewShipmentForm();
                          this.context.router.push('/dashboard/');
                        } else {
                          //show errors
                          const errors = awardResponse.field_errors || [];
                          let submissionError = {};
                          submissionError = unpackErrors(errors, submissionError, [
                            'stops',
                            'line_items',
                            'financials',
                            'charge_line_items'
                          ]);
                          submissionError._error = awardResponse.error_description;
                          //handle edge cases for errors here
                          throw new SubmissionError(submissionError);
                        }
                      });
                  }
                  //show errors
                  const errors = quoteResponse.field_errors || [];
                  let submissionError = {};
                  submissionError = unpackErrors(errors, submissionError, [
                    'stops',
                    'line_items',
                    'financials',
                    'charge_line_items'
                  ]);
                  submissionError._error = quoteResponse.error_description;
                  if (submissionError.charge_line_items) {
                    submissionError.financials = submissionError.charge_line_items;
                  }
                  //handle edge cases for errors here
                  this.setState({
                    errorOverride: quoteResponse.error_description
                  });
                  throw new SubmissionError(submissionError);
                });
              }
              //show errors
              const errors = rfqResponse.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);

              submissionError._error = rfqResponse.error_description;
              //handle edge cases for errors here
              this.setState({
                errorOverride: rfqResponse.error_description
              });
              throw new SubmissionError(submissionError);
            });
          }
          this.props.resetNewShipmentForm();
          this.context.router.push('/dashboard/');
        } else {
          // show errors
          const errors = response.field_errors || [];
          const submissionError = unpackErrors(errors, {}, ['stops', 'line_items', 'financials']);
          submissionError._error = response.error_description;

          this.setState({errorOverride: response.error_description});
          throw new SubmissionError(submissionError);
        }
      });
    }
  }

  redirectForQuotes(shipment, dispatch, props) {
    return onCreateQuoteSuccess(shipment, dispatch, {...props, router: this.context.router});
  }

  async onFormSubmit(attrs, ...args) {
    const {featureFlags, serviceLevels, platNewDriverWorkflow, pdfGeneratorApiForDocumentGeneration, company} =
      this.props;
    try {
      // if there's a name and phone but no id, this is a new driver
      if (platNewDriverWorkflow && attrs?.driver_cell && attrs?.driver?.full_name && !attrs?.driver?.id) {
        // get the carrier relationship
        const response = await getCarrierRelationshipsPromise({vendorId: attrs.carrier?.value});
        // the first result should always be correct since we look up by ID
        if (response.body?.results?.[0]) {
          const carrierRelationship = response.body.results[0];
          // create the new driver on the carrier relationship
          await putCarrierRelationshipsCarrierRelationshipIdPromise(carrierRelationship.id, {
            ...carrierRelationship,
            drivers: [
              ...(carrierRelationship.drivers || []),
              {full_name: attrs?.driver?.full_name, phone_number: attrs?.driver_cell}
            ]
          });
        }
      }
    } catch (error) {
      console.error(error);
      const submissionError = {driver_cell: error?.error_description};
      throw new SubmissionError(submissionError);
    }

    if (attrs.multiplier && attrs.multiplier > 1) {
      if (this.state.showConfirmMultiplierModal) {
        this.setState({showConfirmMultiplierModal: false});
      } else {
        this.setState({showConfirmMultiplierModal: true});
        return;
      }
    }
    if (attrs.option === 'request-tender') {
      this.setState({isTender: true});
    }
    if (attrs.option === 'routing-guide') {
      this.setState({showRoutingGuideModal: true});
    }
    if (attrs.option === 'save-only') {
      return this.saveNewShipmentForm(attrs);
    }
    if (attrs.stops) {
      attrs.stops = transformLocationAppointmentTypes(
        attrs.stops,
        this.props.locationTypes,
        this.props.appointmentTypes
      );
    }

    if (attrs.mode) {
      attrs.mode = transformShipmentMode(attrs.mode, this.props.shipmentModes);
    }
    //transform equipment type to the dict
    if (attrs.equipment_type) {
      attrs.equipment_type = transformEquipmentType(attrs.equipment_type, this.props.equipmentTypes);
    }

    if (attrs.service_level) {
      const serviceLevelId = Number(attrs.service_level);
      attrs.service_level = serviceLevels.find((sl) => sl.id === serviceLevelId) || null;
    }

    attrs.temperature_unit = getShipmentTemperatureUnit(attrs);

    const shipmentObj = constructShipmentObject(attrs, this.props.flags);
    const opts = {};
    const metadata = {tags: []};

    const {hasFTL, hasLTL, hasParcel, hasDrayage} = checkShipmentModes(attrs.mode);

    if (attrs.metadata && attrs.metadata.tags && attrs.metadata.tags.length > 0) {
      for (let i = 0; i < attrs.metadata.tags.length; i++) {
        if (attrs.metadata.tags[i].id) {
          metadata.tags.push(attrs.metadata.tags[i].id);
        } else {
          metadata.tags.push(attrs.metadata.tags[i]);
        }
      }
    }
    if (attrs.metadata?.max_buy_amount) {
      metadata.max_buy_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.max_buy_amount));
      metadata.max_buy_amount_currency = attrs.preferred_currency;
    }
    if (attrs.metadata?.buy_it_now_amount) {
      metadata.buy_it_now_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.buy_it_now_amount));
      metadata.buy_it_now_amount_currency = attrs.preferred_currency;
    }
    if (attrs.metadata?.target_rate_amount) {
      metadata.target_rate_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.target_rate_amount));
      metadata.target_rate_amount_currency = attrs.preferred_currency;
    }

    if (hasFTL) {
      metadata.bypass_carrier_insurance_coverage = !!attrs?.metadata?.bypass_carrier_insurance_coverage;
    }

    //make sure load board isn't selected if it's LTL, parcel, or drayage
    metadata.load_board_enabled = !hasParcel && !hasDrayage && !hasLTL && attrs.pushToLoadboard;

    shipmentObj.metadata = metadata;

    if (attrs.custom_data) {
      shipmentObj.custom_data = attrs.custom_data;
    }
    if (attrs.book_as_customer) {
      //add the xShipperId
      opts['xCompanyId'] = attrs.book_as_customer.value;
    }
    if (attrs.total_declared_value) {
      shipmentObj.total_declared_value = attrs.total_declared_value;
      shipmentObj.total_declared_value_currency = attrs.total_declared_value_currency;
    }

    if (attrs.preferred_currency) {
      shipmentObj.preferred_currency = attrs.preferred_currency;
    }

    if (attrs.financials) {
      shipmentObj.financials = attrs.financials;
    }

    if (isBillToFormEmpty(attrs.bill_to_override)) {
      shipmentObj.metadata = {
        ...shipmentObj.metadata,
        bill_to_override: null
      };
    } else {
      shipmentObj.metadata = {
        ...shipmentObj.metadata,
        bill_to_override: {
          ...attrs.bill_to_override,
          company_address:
            attrs.bill_to_override.company_address.formatted_address || attrs.bill_to_override.company_address
        }
      };
    }

    if (attrs.total_weight_override && !has(attrs.total_weight_override, 'value')) {
      shipmentObj.total_weight_override.value = null;
    }

    if (attrs.option === 'push-dashboard') {
      shipmentObj.metadata.open = true;
    }

    if (['routing-guide', 'request-tender', 'get-quotes'].includes(attrs.option)) {
      shipmentObj.state = 'quoting';
    }

    if (attrs.multiplier && attrs.multiplier > 1) {
      // add the markup so that it gets passed to the quote obj
      if (attrs.markup) {
        shipmentObj.markup = attrs.markup;
      }
      //we need to create multiple copies of this shipment
      this.setState({totalToCreate: attrs.multiplier, created: 0}, () => {
        return this.handleShowMultipleShipmentsModal(attrs, shipmentObj, opts);
      });
    } else {
      let saveShipmentRequest;
      if (attrs.id) {
        saveShipmentRequest = this.props.editShipment(
          attrs.id,
          cleanPayload(shipmentObj, {oldValue: ['', null, 'null', NaN], hardDelete: false}),
          opts
        );
      } else {
        saveShipmentRequest = this.props.createShipment(
          cleanPayload(shipmentObj, {oldValue: ['', null, 'null', NaN], hardDelete: false}),
          opts
        );
      }

      //POST the shipment object
      return saveShipmentRequest.then((response) => {
        if (response.status === 200) {
          this.setState({submitSucceeded: true});
          this.setState({shipmentId: response.details.id, selectedShipment: response.details});
          //proceed to kick off the RFQ process
          const {isTender} = this.state;
          const rfqObj = {};
          // autoquote according to mode like we do for new quotes if "get quotes" was selected
          rfqObj.autoquote = attrs.option === 'get-quotes' && attrs.mode.id !== 1 && attrs.mode.id !== 5;
          rfqObj.shipment = response.details.id;
          rfqObj.parent_rfq = null;
          rfqObj.equipment_types = attrs.equipment_type ? [attrs.equipment_type] : [];
          rfqObj.shipment_modes = [attrs.mode];

          // TITAN-4803: passing the fedex freight account to a third party RFQ causes a 400
          const shipmentCustomer = response.details.customer?.id;
          const isThirdPartyShipment = !!shipmentCustomer && company.id !== shipmentCustomer;
          // if LTL we need to set fedex_specific_options on the rfq
          if (hasLTL && this.props.isFedExEnabled && !isThirdPartyShipment) {
            if (this.props.fedexFreightAccounts?.length) {
              // the existing code from createQuote.js just uses the first freight account found
              rfqObj.fedex_specific_options = {
                account: this.props.fedexFreightAccounts[0].id,
                packaging: FedexShipmentOptionsPackagingEnum.YourPackaging
              };
            }
          }

          //pass in opts in case this is a broker working on a shipper account
          return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
            if (rfqResponse.status === 200) {
              //assign the vendor first, if it exists
              if (this.state.selectedCarrier) {
                const carrierToAssign = this.state.selectedCarrier;
                //assign a vendor POC if one is selected, or default if there is only one
                let pocToAssign = null;
                if (attrs.vendor_point_of_contact && !this.state.defaultVendorPOC) {
                  pocToAssign = attrs.vendor_point_of_contact.id || attrs.vendor_point_of_contact;
                } else if (this.state.defaultVendorPOC) {
                  pocToAssign = this.state.defaultVendorPOC.id
                    ? this.state.defaultVendorPOC.id
                    : this.state.defaultVendorPOC;
                }
                return this.props
                  .assignCarrier(response.details.id, {
                    vendor: carrierToAssign,
                    vendor_point_of_contact: pocToAssign,
                    customer_charge_line_items: [],
                    vendor_charge_line_items: []
                  })
                  .then((assignCarrierResponse) => {
                    if (assignCarrierResponse.status === 200) {
                      if (attrs.financials?.length > 0) {
                        return this.awardManualQuote(attrs, rfqResponse, response, assignCarrierResponse.details);
                      }
                      return this.handleBOLandEquipment(attrs, response, assignCarrierResponse);
                    }
                    //show errors
                    const errors = assignCarrierResponse.field_errors || [];
                    let submissionError = {};
                    submissionError = unpackErrors(errors, submissionError);
                    // Invalid phone number due to import
                    if (submissionError.vendor) {
                      if (submissionError.vendor.primary_phone_number === 'Enter a valid phone number.') {
                        submissionError.carrier = "Carrier company's phone number is not valid";
                      } else {
                        submissionError.carrier = submissionError.vendor.primary_phone_number;
                      }
                    }

                    //handle edge cases for errors here
                    throw new SubmissionError(submissionError);
                  });
              }
              // clear out the previous shipment object that is state at this point
              this.props.clearShipmentState();
              //award the quote, if it exists
              if (attrs.financials?.length > 0) {
                return this.awardManualQuote(attrs, rfqResponse, response, null);
              }
              if (isTender || attrs.option === 'routing-guide') {
                return this.setState({redirectWhenCloseTenderForm: true});
              }
              if (attrs.option === 'print-bol' && !pdfGeneratorApiForDocumentGeneration) {
                return this.props.generateBOL(response.details.id).then((BOLResponse) => {
                  if (BOLResponse.details?.id) {
                    this.context.router.push(`/shipments/${response.details.id}/documents/${BOLResponse.details?.id}`);
                  }
                });
              }
              if (attrs.option === 'get-quotes') {
                this.redirectForQuotes(rfqResponse.details, ...args);
              } else {
                //no quote, just go to shipment details
                this.props.resetNewShipmentForm();
                this.context.router.push('/shipments/' + response.details.id);
              }
            } else {
              //show errors
              const errors = rfqResponse.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
              submissionError._error = rfqResponse.error_description;
              //handle edge cases for errors here
              throw new SubmissionError(submissionError);
            }
          });
        }
        //show errors
        const errors = response.field_errors || [];
        const submissionError = unpackErrors(errors, {}, ['stops', 'line_items']);
        submissionError._error = response.error_description;

        // handle edge cases for errors here
        if (submissionError.stops) {
          for (var i = 0; i < submissionError.stops.length; i++) {
            if (Object.keys(submissionError.stops[i]).length === 0) {
              delete submissionError.stops[i];
            }
          }
        }
        throw new SubmissionError(submissionError);
      });
    }
  }

  awardManualQuote(attrs, rfqResponse, response, vendorObj) {
    //construct a new quote
    const quoteObj = {};
    let customerCharges = [];
    let vendorCharges = [];
    if (attrs.financials) {
      quoteObj.charge_line_items = JSON.parse(JSON.stringify(attrs.financials));
    } else {
      quoteObj.charge_line_items = [];
    }
    if (quoteObj.charge_line_items.length > 0) {
      let total = 0;
      for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
        //strip out any commas or leading $
        if (
          typeof quoteObj.charge_line_items[i].unit_amount === 'string' &&
          quoteObj.charge_line_items[i].unit_amount.startsWith('$')
        ) {
          quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.slice(1);
        }
        //replace commas if this is a string
        if (typeof quoteObj.charge_line_items[i].unit_amount === 'string') {
          quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.replace(/,/g, '');
        }
        quoteObj.charge_line_items[i].unit_amount = parseFloat(quoteObj.charge_line_items[i].unit_amount);
        quoteObj.charge_line_items[i].unit_quantity = parseFloat(quoteObj.charge_line_items[i].unit_quantity);
        //default to the user's preferred currency for each line item
        quoteObj.charge_line_items[i].unit_amount_currency = attrs.preferred_currency;
        total += parseFloat(quoteObj.charge_line_items[i].unit_amount * quoteObj.charge_line_items[i].unit_quantity);
      }
      quoteObj.total = total;
      //get vendor charges before markup is added
      vendorCharges = JSON.parse(JSON.stringify(quoteObj.charge_line_items));
      //if there is markup
      if (attrs.markup) {
        //add a new charge_line_item and update the total
        quoteObj.charge_line_items.push({
          unit_amount: parseFloat(attrs.markup),
          unit_amount_currency: attrs.preferred_currency,
          unit_name: 'Item Charge',
          category: ChargeCategory.Lh,
          charge_code: 'LHS',
          is_provider_markup: true,
          unit_quantity: 1
        });
        quoteObj.total += parseFloat(attrs.markup);
        quoteObj.total = parseFloat(quoteObj.total).toFixed(2);
      }
    }
    //add the mode that this quote will use

    quoteObj.rfq = rfqResponse.details.id;

    //add the carrier to the manual quote if one is selected
    if (vendorObj && vendorObj.vendor && vendorObj.vendor.carrier) {
      quoteObj.carrier = vendorObj.vendor.carrier.id;
    }

    //add the default service mode TODO make these selectable
    quoteObj.mode = attrs.mode.id || 1;
    quoteObj.service_level = attrs.service_level || 19;
    //create the new quote
    quoteObj.equipment_type = attrs.equipment_type.id;
    //deliberately NOT sending opts in here, assuming that the quote should be attributed to the logged in user
    return this.props.createQuote(quoteObj.rfq, quoteObj).then((quoteResponse) => {
      if (quoteResponse.status === 200) {
        //now award the quote to the shipment
        return this.props
          .awardQuote(response.details.id, {
            quote: quoteResponse.details.id
          })
          .then((awardResponse) => {
            if (awardResponse.status === 200) {
              //if a vendor has been selected, copy over the vendor financials to that side of the shipment
              if (vendorObj) {
                vendorObj.customer_charge_line_items = vendorCharges;
                //do a PUT on the vendor assignment to update the charges
                return this.props
                  .editCarrierAssignment(response.details.id, vendorObj.id, vendorObj)
                  .then((carrierAssignmentResponse) => {
                    if (carrierAssignmentResponse.status === 200) {
                      return this.handleBOLandEquipment(attrs, response, carrierAssignmentResponse);
                    }
                    const errors = carrierAssignmentResponse.field_errors || [];
                    let submissionError = {};
                    submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
                    submissionError._error = carrierAssignmentResponse.error_description;
                    //handle edge cases for errors here
                    throw new SubmissionError(submissionError);
                  });
              }
              return this.handleBOLandEquipment(attrs, response, null);
            }
            //show errors
            const errors = awardResponse.field_errors || [];
            let submissionError = {};
            submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
            submissionError._error = awardResponse.error_description;
            //handle edge cases for errors here
            throw new SubmissionError(submissionError);
          });
      }
      //show errors
      const errors = quoteResponse.field_errors || [];
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError, [
        'stops',
        'line_items',
        'financials',
        'charge_line_items'
      ]);
      submissionError._error = quoteResponse.error_description;
      //handle edge cases for errors here
      if (submissionError.charge_line_items) {
        submissionError.financials = submissionError.charge_line_items;
      }
      throw new SubmissionError(submissionError);
    });
  }

  async assignCarriersDriverAndPowerUnit(attrs, response, assignCarrierResponse) {
    try {
      const assignEquipmentResponse = await this.props.assignEquipmentConfig(response.details.id, {
        carrier_assignment: assignCarrierResponse.details.id,
        power_unit: attrs.power_unit ? attrs.power_unit.id : null,
        driver: {
          phone_number: attrs.driver_cell
        }
      });

      if (assignEquipmentResponse.status !== 200) {
        const errors = assignEquipmentResponse.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
        submissionError._error = assignEquipmentResponse.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }

      return assignEquipmentResponse;
    } catch (error) {
      console.error(error);
    }
  }

  async handleBOLandEquipment(attrs, response, assignCarrierResponse) {
    const needsToAssignCarriersDriverAndPowerUnit = !!(
      (attrs.driver_cell || attrs.hasOwnProperty('power_unit')) &&
      assignCarrierResponse
    );
    const bypassBOLGeneration = this.props?.pdfGeneratorApiForDocumentGeneration;

    if (needsToAssignCarriersDriverAndPowerUnit) {
      await this.assignCarriersDriverAndPowerUnit(attrs, response, assignCarrierResponse);
    }

    if (bypassBOLGeneration) {
      this.context.router.push('/shipments/' + response.details.id);
      return;
    }

    const BOLResponse = await this.props.generateBOL(response.details.id);
    const BOLid = BOLResponse.details ? BOLResponse.details.id : null;

    //send the shipment booked emails (to the POCs)
    this.props.sendShipmentBooked(response.details.id);

    if (attrs.option === 1 && BOLid !== null) {
      this.props.resetNewShipmentForm();
      this.props.triggerSuccessfulDispatch(true, true);
      //go straight to the BOL
      this.context.router.push('/shipments/' + response.details.id + '/documents/' + BOLid);
    } else {
      //proceed to the shipment details screen now that it's all set
      this.props.resetNewShipmentForm();
      this.context.router.push('/shipments/' + response.details.id);
    }
  }

  /* SHIPMENT MULTIPLIER ACTIONS */
  async handleCreateMultipleShipments(payloads, opts) {
    //open the modal to show progress
    this.setState({creatingShipments: true});
    const cleanedPayloads = payloads.map((payload) =>
      cleanPayload(payload, {oldValue: ['', null, 'null', NaN], hardDelete: false})
    );
    const createShipmentsResponse = await this.performAll(cleanedPayloads, opts);
    if (createShipmentsResponse) {
      const shipmentsGetResponse = await this.fetchAllShipments(createShipmentsResponse);
      return shipmentsGetResponse;
    }
  }

  handleShowMultipleShipmentsModal(attrs, shipmentObj, opts) {
    //open the modal to allow users to set dates and metadata
    this.setState({shipmentToMultiply: {attrs, shipmentObj, opts}, showMultipleModal: true});
  }

  /* get shipment details for a list of shipments */
  async fetchAllShipments(results) {
    const newResults = [];
    for (const result of results) {
      const request = this.props.getShipmentDetails(result.details.id, {}, true);
      newResults.push(await this.handleShipmentGet(request));
    }
    this.setState({creatingShipments: false, createdShipments: newResults});
    return newResults;
  }
  /* shipment get by id */
  async handleShipmentGet(request) {
    return await request.then((response) => {
      return response.details;
    });
  }
  /* perform all creations for shipments */
  async performAll(payloads, opts) {
    const results = [];
    for (const payload of payloads) {
      const request = this.props.createShipment(payload, opts);
      results.push(await this.handleShipmentPost(request, payload, opts));
    }
    return results;
  }
  /* post shipment */
  async handleShipmentPost(request, attrs, opts) {
    return await request.then(async (response) => {
      if (response.status === 200) {
        return await this.handleRFQPost(response, attrs, opts);
      }
      //show errors
      const errors = response.field_errors || [];
      const submissionError = unpackErrors(errors, {}, ['stops', 'line_items']);
      submissionError._error = response.error_description;

      // handle edge cases for errors here
      if (submissionError.stops) {
        for (var i = 0; i < submissionError.stops.length; i++) {
          if (Object.keys(submissionError.stops[i]).length === 0) {
            delete submissionError.stops[i];
          }
        }
      }
      this.setState({creatingShipments: false});

      throw new SubmissionError(submissionError);
    });
  }
  /* post rfq */
  async handleRFQPost(response, attrs, opts) {
    //proceed to kick off the RFQ process
    const rfqObj = {};
    //default to false
    rfqObj.autoquote = false;
    rfqObj.shipment = response.details.id;
    rfqObj.parent_rfq = null;
    rfqObj.equipment_types = [attrs.equipment_type] || [];
    rfqObj.shipment_modes = [attrs.mode];
    //pass in opts in case this is a broker working on a shipper account
    return await this.props.createRFQ(rfqObj, opts).then(async (rfqResponse) => {
      if (rfqResponse.status === 200) {
        //assign the vendor first, if it exists
        if (this.state.selectedCarrier) {
          const carrierToAssign = this.state.selectedCarrier;
          //assign a vendor POC if one is selected, or default if there is only one
          let pocToAssign = null;
          if (attrs.vendor_point_of_contact && !this.state.defaultVendorPOC) {
            pocToAssign = attrs.vendor_point_of_contact.id || attrs.vendor_point_of_contact;
          } else if (this.state.defaultVendorPOC) {
            pocToAssign = this.state.defaultVendorPOC.id ? this.state.defaultVendorPOC.id : this.state.defaultVendorPOC;
          }
          return await this.props
            .assignCarrier(response.details.id, {
              vendor: carrierToAssign,
              vendor_point_of_contact: pocToAssign,
              customer_charge_line_items: [],
              vendor_charge_line_items: []
            })
            .then(async (assignCarrierResponse) => {
              if (assignCarrierResponse.status === 200) {
                if (attrs.financials?.length > 0) {
                  return await this.handleFinancials(attrs, rfqResponse, response, assignCarrierResponse.details);
                }
                const {createdShipments} = this.state;
                createdShipments.push(response.details);
                this.setState({created: this.state.created + 1, createdShipments: createdShipments});
                return response;
              }
              //show errors
              const errors = rfqResponse.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
              submissionError._error = rfqResponse.error_description;
              //handle edge cases for errors here
              this.setState({
                creatingShipments: false,
                showMultipleModal: false,
                multipleError: 'There was an error assigning a vendor to these shipments.'
              });

              throw new SubmissionError(submissionError);
            });
        }
        //award the quote, if it exists
        if (attrs.financials?.length > 0) {
          return await this.handleFinancials(attrs, rfqResponse, response, null);
        }
        const {createdShipments} = this.state;
        createdShipments.push(response.details);
        this.setState({created: this.state.created + 1, createdShipments: createdShipments});
        return response;
      }
      //show errors
      const errors = rfqResponse.field_errors || [];
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
      submissionError._error = rfqResponse.error_description;
      //handle edge cases for errors here
      this.setState({
        creatingShipments: false,
        showMultipleModal: false,
        multipleError: 'There was an error creating these shipments.'
      });

      throw new SubmissionError(submissionError);
    });
  }
  /* post quote and update financials */
  async handleFinancials(attrs, rfqResponse, response, vendorObj) {
    //construct a new quote
    const quoteObj = {};
    let customerCharges = [];
    let vendorCharges = [];
    if (attrs.financials) {
      quoteObj.charge_line_items = JSON.parse(JSON.stringify(attrs.financials));
    } else {
      quoteObj.charge_line_items = [];
    }
    if (quoteObj.charge_line_items.length > 0) {
      let total = 0;
      for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
        //strip out any commas or leading $
        if (
          typeof quoteObj.charge_line_items[i].unit_amount === 'string' &&
          quoteObj.charge_line_items[i].unit_amount.startsWith('$')
        ) {
          quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.slice(1);
        }
        //replace commas if this is a string
        if (typeof quoteObj.charge_line_items[i].unit_amount === 'string') {
          quoteObj.charge_line_items[i].unit_amount = quoteObj.charge_line_items[i].unit_amount.replace(/,/g, '');
        }
        quoteObj.charge_line_items[i].unit_amount = parseFloat(quoteObj.charge_line_items[i].unit_amount);
        quoteObj.charge_line_items[i].unit_quantity = parseFloat(quoteObj.charge_line_items[i].unit_quantity);
        //default to the user's preferred currency for each line item
        quoteObj.charge_line_items[i].unit_amount_currency = attrs.preferred_currency;
        total += parseFloat(quoteObj.charge_line_items[i].unit_amount * quoteObj.charge_line_items[i].unit_quantity);
      }
      quoteObj.total = total;
      //get vendor charges before markup is added
      vendorCharges = JSON.parse(JSON.stringify(quoteObj.charge_line_items));
      //if there is markup
      if (attrs.markup) {
        //add a new charge_line_item and update the total
        quoteObj.charge_line_items.push({
          unit_amount: parseFloat(attrs.markup),
          unit_amount_currency: attrs.preferred_currency,
          unit_name: 'Item Charge',
          category: ChargeCategory.Lh,
          charge_code: 'LHS',
          is_provider_markup: true,
          unit_quantity: 1
        });
        quoteObj.total += parseFloat(attrs.markup);
        quoteObj.total = parseFloat(quoteObj.total).toFixed(2);
      }
    }
    //add the mode that this quote will use

    quoteObj.rfq = rfqResponse.details.id;

    //add the carrier to the manual quote if one is selected
    if (vendorObj && vendorObj.vendor && vendorObj.vendor.carrier) {
      quoteObj.carrier = vendorObj.vendor.carrier.id;
    }

    //add the default service mode TODO make these selectable
    quoteObj.mode = attrs.mode.id || 1;
    quoteObj.service_level = attrs.service_level || 19;
    //create the new quote
    quoteObj.equipment_type = attrs.equipment_type.id;
    //deliberately NOT sending opts in here, assuming that the quote should be attributed to the logged in user
    return await this.props.createQuote(quoteObj.rfq, quoteObj).then(async (quoteResponse) => {
      if (quoteResponse.status === 200) {
        //now award the quote to the shipment
        return await this.props
          .awardQuote(response.details.id, {
            quote: quoteResponse.details.id
          })
          .then(async (awardResponse) => {
            if (awardResponse.status === 200) {
              //if a vendor has been selected, copy over the vendor financials to that side of the shipment
              if (vendorObj) {
                vendorObj.customer_charge_line_items = vendorCharges;
                //do a PUT on the vendor assignment to update the charges
                return await this.props
                  .editCarrierAssignment(response.details.id, vendorObj.id, vendorObj)
                  .then((carrierAssignmentResponse) => {
                    if (carrierAssignmentResponse.status === 200) {
                      const {createdShipments} = this.state;
                      createdShipments.push(response.details);
                      this.setState({created: this.state.created + 1, createdShipments: createdShipments});
                      return response;
                    }
                    const errors = carrierAssignmentResponse.field_errors || [];
                    let submissionError = {};
                    submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
                    submissionError._error = carrierAssignmentResponse.error_description;
                    this.setState({
                      creatingShipments: false,
                      showMultipleModal: false,
                      multipleError: 'There was an error updating the financials on these shipments.'
                    });

                    //handle edge cases for errors here
                    throw new SubmissionError(submissionError);
                  });
              }
              const {createdShipments} = this.state;
              createdShipments.push(response.details);
              this.setState({created: this.state.created + 1, createdShipments: createdShipments});
              return response;
            }
            //show errors
            const errors = awardResponse.field_errors || [];
            let submissionError = {};
            submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
            submissionError._error = awardResponse.error_description;
            //handle edge cases for errors here
            this.setState({
              creatingShipments: false,
              showMultipleModal: false,
              multipleError: 'There was an error adding the financials to these shipments.'
            });

            throw new SubmissionError(submissionError);
          });
      }
      //show errors
      const errors = quoteResponse.field_errors || [];
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError, [
        'stops',
        'line_items',
        'financials',
        'charge_line_items'
      ]);
      submissionError._error = quoteResponse.error_description;
      //handle edge cases for errors here
      if (submissionError.charge_line_items) {
        submissionError.financials = submissionError.charge_line_items;
      }
      this.setState({
        creatingShipments: false,
        showMultipleModal: false,
        multipleError: 'There was an error creating a Request for Quotes.'
      });

      throw new SubmissionError(submissionError);
    });
  }

  handleContinue() {
    this.onFormSubmit({...this.props.newShipmentForm.values});
  }

  renderDispatchErrorModal() {
    const {multipleError, created, totalToCreate} = this.state;

    return (
      <div className="modal-terms-error">
        <h3>
          <i className="icon icon-Delayed" /> We had a problem creating these shipments
        </h3>
        <p>
          {multipleError} We were able to create {created} out of {totalToCreate} shipments.
        </p>
      </div>
    );
  }

  render() {
    let showDriverField = true;
    if (
      this.state.selectedCarrier &&
      this.state.selectedCarrier.brokerage &&
      this.state.selectedCarrier.brokerage.is_3pl === true &&
      !this.state.selectedCarrier.carrier
    ) {
      //we dont show the driver field if the vendor is a 3pl without a carrier arm
      showDriverField = false;
    }

    //don't show driver field if ltl or vltl
    if (
      this.props.newShipmentForm &&
      this.props.newShipmentForm.values &&
      this.props.newShipmentForm.values.mode &&
      (this.props.newShipmentForm.values.mode === '2' || this.props.newShipmentForm.values.mode === '4')
    ) {
      showDriverField = false;
    }
    const {shipment, purchaseOrders, location} = this.props;
    const {
      showMultipleModal,
      created,
      totalToCreate,
      creatingShipments,
      createdShipments,
      isTender,
      submitSucceeded,
      shipmentId,
      selectedShipment,
      showRoutingGuideModal,
      showConsolidationToast,
      selectedCarrier,
      defaultVendorPOC,
      shipmentToMultiply
    } = this.state;
    if (this.props.isCompanyPreferencesFetching) {
      return null;
    }

    return (
      <div className="new-shipment content-wrapper">
        <section className="content">
          <>
            <GetInternationalPreferencesQuery>
              {({data: internationalPreferences}) => (
                <NewShipmentForm
                  setDefaultVendorPOC={(poc) => {
                    this.setState({defaultVendorPOC: poc ? poc.id : null});
                  }}
                  isEditForm={this.props.params.shipment_id}
                  shipment={shipment}
                  purchaseOrders={purchaseOrders}
                  showDriverField={showDriverField}
                  errorOverride={this.state.errorOverride}
                  saveNewShipmentForm={this.saveNewShipmentForm}
                  shipmentModes={this.props.shipmentModes}
                  onSubmit={this.onFormSubmit}
                  searchForCarrierByID={this.props.searchForCarrierByID}
                  getCarrierRelationshipsCarrierRelationshipId={this.props.getCarrierRelationshipsCarrierRelationshipId}
                  selectedRelationship={this.props.selectedRelationship}
                  multipleError={this.state.multipleError}
                  created={created}
                  totalToCreate={totalToCreate}
                  location={location}
                  internationalPreferences={internationalPreferences}
                />
              )}
            </GetInternationalPreferencesQuery>
            <Toast
              show={showConsolidationToast}
              title="Shipment Created!"
              variant="success"
              anchor="top-right"
              delay="10000"
            >
              Your {pluralize('order', parseFloat(location.query.ordersConsolidated), true)}{' '}
              {pluralize('has', parseFloat(location.query.ordersConsolidated))} been consolidated into a shipment.
            </Toast>
          </>
          );
        </section>
        {showMultipleModal && (
          <CreateMultipleShipments
            creatingShipments={creatingShipments}
            createdShipments={createdShipments}
            created={created}
            totalToCreate={totalToCreate}
            open
            close={() => this.context.router.push('/dashboard/')}
            shipmentToMultiply={shipmentToMultiply}
            onSubmit={this.handleCreateMultipleShipments.bind(this)}
            cancel={() => this.setState({showMultipleModal: false})}
          />
        )}

        <TenderRequestForm
          show={isTender}
          onClose={() => {
            this.state.redirectWhenCloseTenderForm
              ? this.context.router.push('/shipments/' + shipmentId)
              : this.setState({isTender: false});
          }}
          router={this.context.router}
          submitSucceeded={submitSucceeded}
          selectedCarrier={{id: selectedCarrier?.id, poc: defaultVendorPOC}}
          shipmentDetailData={selectedShipment}
        />

        <Modal
          show={showRoutingGuideModal && shipmentId}
          title="Push to Routing Guide"
          footerComponent={null}
          onClose={() => this.context.router.push('/shipments/' + shipmentId)}
        >
          <ExecuteRoutingGuide
            selectedShipment={selectedShipment}
            onCancel={() => this.context.router.push('/shipments/' + shipmentId)}
            onSubmitSuccess={() => this.context.router.push('/shipments/' + shipmentId + '?routingGuideRunning=true')}
          />
        </Modal>

        {this.state.showConfirmMultiplierModal && (
          <CreateMultipleConfirmation
            open
            numberToCreate={this.props.newShipmentForm.values.multiplier}
            createdShipments={[this.props.newShipmentForm.values]}
            cancel={() => this.setState({showConfirmMultiplierModal: false})}
            continue={this.handleContinue.bind(this)}
          />
        )}
        {this.state.multipleError && (
          <InfoModalWrapper
            bsSize="large"
            show={this.state.multipleError}
            title=""
            onHide={() => {
              this.setState({multipleError: null});
            }}
            children={this.renderDispatchErrorModal()}
            primaryAction={{
              label: 'Close',
              action: () => {
                this.setState({multipleError: null});
              }
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.auth.company,
    newShipmentForm: state.form.newShipmentForm,
    addressBook: state.shipments.addressbook,
    hazmatCodes: state.shipments.hazmatCodes,
    serviceLevels: state.shipments.serviceLevels,
    locationTypes: state.addresses.locationTypes,
    actingAsCompany: state.brokers.actingAsCompany,
    carrierRelationships: state.vendors.carrierRelationships,
    equipmentTypes: state.shipments.equipmentTypes,
    shipmentModes: state.shipments.shipmentModes,
    appointmentTypes: state.shipments.appointmentTypes,
    selectedRelationship: state.vendors.selectedRelationship,
    shipment: state.shipmentdetails.one,
    currency: state.userCompany.unitPreferences.currency,
    featureFlags: state.userCompany.feature_flags,
    isCompanyPreferencesFetching: state.company.preferences.isFetching
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...formActions,
      ...documentActions,
      ...usersActions,
      ...vendorActions,
      ...shipmentActions,
      ...shipmentdetailsActions
    },
    dispatch
  );
};

export default compose(
  withLDConsumer(),
  connect(mapStateToProps, mapDispatchToProps),
  withFlags('platNewDriverWorkflow', 'pdfGeneratorApiForDocumentGeneration'),
  withGetFedExAccountsQuery
)(NewShipment);
