import {Button, Modal} from '@shipwell/shipwell-ui';
import Loader from 'App/common/shipwellLoader';

import Table from 'App/components/Table';
import {useSensorTableData} from './useSensorTableData';

export type SensorDataTableModalProps = {
  showModal: boolean;
  onClose: () => void;
  deviceName: string;
  deviceId: string;
  provider: string;
  resourceId: string;
};

export const SensorDataTableModal = ({deviceId, provider, resourceId, ...props}: SensorDataTableModalProps) => {
  const deviceNameLabel = props.deviceName ? ` • ${props.deviceName}` : '';
  const modalTitle = `Tracking details${deviceNameLabel} • ${deviceId}`;

  const sensorTableData = useSensorTableData({
    deviceId,
    provider,
    resourceId
  });

  return (
    <Modal
      size={'large'}
      title={modalTitle}
      show={props.showModal}
      onClose={props.onClose}
      bodyVariant="disableOverflowScroll"
      footerComponent={null}
    >
      <div className="h-[calc(100vh-200px)]">
        {sensorTableData.isLoading ? (
          <Loader loading />
        ) : (
          <Table
            columns={sensorTableData.columns}
            data={sensorTableData.data}
            showPaginationFooter
            pageCount={sensorTableData.pageCount}
            onFetchData={sensorTableData.onFetchData}
            initialPageSize={sensorTableData.pageSize}
            footerContent={
              <Button variant="tertiary" iconName="Download" onClick={sensorTableData.exportToCSV}>
                Export Table Data
              </Button>
            }
          />
        )}
      </div>
    </Modal>
  );
};
