import {object, string, number, TestContext, array} from 'yup';
import {
  TerminalFeeServiceTypeEnum,
  StorageServiceTypeEnum,
  TransloadServiceTypeEnum,
  Address,
  Stage,
  LegacyShipmentStage,
  LegacyShipmentStageStageTypeEnum,
  ServiceStage,
  ShipmentService,
  Transload,
  ServiceStageStageTypeEnum,
  ServiceProvider as CorrogoServiceProvider
} from '@shipwell/corrogo-sdk';
import {
  ServiceProvider,
  Address as LegacyAddress,
  EquipmentType,
  StopLocation as LegacyStopLocation,
  Stop,
  ShipmentLineItem,
  ShipmentPreferredCurrencyEnum
} from '@shipwell/backend-core-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Uuid} from 'id128';
import moment from 'moment';
import omit from 'lodash/omit';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {dateConstructorSafariPolyfill} from 'App/utils/dateTimeGlobalsTyped';
import {emptyStringToNull, validateDecimalPoints} from 'App/utils/yupHelpers';
import {AddressSchema} from 'App/utils/yupHelpersTyped';

const LegacyShipmentLineItemSchema = array().of(
  object().shape({
    description: string().nullable().required('Description is required.'),
    package_weight: number()
      .nullable()
      .transform(emptyStringToNull)
      .typeError('Weight must be a number.')
      .test({
        name: 'Weight max 2 decimals',
        message: 'Enter up to two decimals.',
        test: (value) => (value ? validateDecimalPoints(value, 2) : true)
      }),
    package_type: string().nullable(),
    total_packages: number().nullable().typeError('Quantity must be a number.')
  })
);

export const LEGACY_SHIPMENT_STAGE_VALIDATION_SCHEMA = object().shape({
  stage_type: string().nullable().required('Stage Type is required.'),
  equipment: object().nullable().required('Equipment is required.').shape({
    id: number(),
    machine_readable: string(),
    name: string()
  }),
  pickup_date: string().nullable().required('Pickup Date is required.'),
  delivery_date: string()
    .nullable()
    .required('Delivery Date is required.')
    .test('delivery_date', 'Delivery date cannot be before pickup date.', (value, context) => {
      const {parent} = context as Omit<TestContext, 'parent'> & {
        parent: {
          pickup_date: string;
        };
      };
      const pickupDate = moment(parent.pickup_date);
      const deliveryDate = moment(value);
      return !parent.pickup_date || !value || pickupDate.isBefore(deliveryDate);
    }),
  pickup_location: AddressSchema.test(
    'pickup_location',
    'A valid pickup location is required.',
    (value) => !!value?.address_1 || !!value?.line_1
  ),
  delivery_location: AddressSchema.test(
    'delivery_location',
    'A valid delivery location is required.',
    (value) => !!value?.address_1 || !!value?.line_1
  ),
  drayage_container_number: string()
    .nullable()
    .test(
      'drayage_container_number',
      'Container number can only contain uppercase letters and numbers.',
      (value?: string | null) => {
        if (!value) {
          //don't validate null-ish values
          return true;
        }
        //https://blog.cruxsystems.com/what-is-a-shipping-container-number
        const hasOnlyUpperCaseLettersAndNumbers = (value: string) => /^[A-Z]+[0-9]+$/.test(value);
        return hasOnlyUpperCaseLettersAndNumbers(value);
      }
    ),
  drayage_seal_number: string().nullable(),
  drayage_chassis_number: string().nullable(),
  line_items: LegacyShipmentLineItemSchema
});

export const SERVICE_STAGE_VALIDATION_SCHEMA = object().shape({
  stage_type: string().nullable().required('Stage Type is required.'),
  location: AddressSchema.test(
    'location',
    'A valid location is required.',
    (value) => !!value?.address_1 || !!value?.line_1
  ),
  start_date: string().nullable().required('Start Date / Time is required.'),
  end_date: string()
    .nullable()
    .required('End Date / Time is required.')
    .test('end_date', 'End date cannot be before start date.', (value, context) => {
      const {parent} = context as Omit<TestContext, 'parent'> & {
        parent: {
          start_date: string;
        };
      };
      const startDate = moment(parent.start_date);
      const endDate = moment(value);
      return !parent.start_date || !value || startDate.isBefore(endDate);
    }),
  provider_rate: number().nullable(),
  customer_rate: number().nullable(),
  service_provider: object().nullable().required('Service Provider is required.').shape({provider_id: string()})
});

export const SERVICE_STAGE_OPTIONS = [
  TransloadServiceTypeEnum.Transload,
  TerminalFeeServiceTypeEnum.TerminalFee,
  StorageServiceTypeEnum.Storage
];

const SERVICE_STAGE_SELECT_OPTIONS = SERVICE_STAGE_OPTIONS.map((stageOption) => ({
  label: startCaseToLower(stageOption),
  value: stageOption
}));

export const LEGACY_SHIPMENT_MODES = ['Drayage', 'FTL', 'Intermodal', 'LTL', 'VLTL'];

export const LEGACY_SHIPMENT_MODES_CONSTANTS = {
  DRAYAGE: 'DRAYAGE',
  FTL: 'FTL',
  Intermodal: 'INTERMODAL',
  LTL: 'LTL',
  VLTL: 'VLTL'
};

export const STAGE_SELECT_OPTIONS = [
  ...LEGACY_SHIPMENT_MODES.map((mode) => ({label: mode, value: mode})),
  ...SERVICE_STAGE_SELECT_OPTIONS
];

export type StageType =
  | 'DRAYAGE'
  | 'VLTL'
  | 'LTL'
  | 'INTERMODAL'
  | 'FTL'
  | TransloadServiceTypeEnum
  | TerminalFeeServiceTypeEnum
  | StorageServiceTypeEnum
  | null;

export type NewStageFormValues = {
  stage_type: StageType;
  start_date: string | null;
  end_date: string | null;
  location: LegacyStopLocation | LegacyAddress | null;
  service_provider: ServiceProvider | CorrogoServiceProvider | null;
  provider_rate: string | null;
  customer_rate: string | null;
  equipment: EquipmentType | null;
  pickup_location: LegacyStopLocation | LegacyAddress | null;
  pickup_date: string | null;
  delivery_location: LegacyStopLocation | LegacyAddress | null;
  delivery_date: string | null;
  preferred_currency: ShipmentPreferredCurrencyEnum;
  drayage_container_number?: string;
  drayage_seal_number?: string;
  drayage_chassis_number?: string;
  line_items?: ShipmentLineItem[];
};

export function isLegacyStopLocation(
  address: Address | LegacyAddress | LegacyStopLocation
): address is LegacyStopLocation {
  return (address as LegacyStopLocation).address !== undefined;
}

export const isServiceStageType = (stageType: StageType) => {
  return (
    stageType === TransloadServiceTypeEnum.Transload ||
    stageType === TerminalFeeServiceTypeEnum.TerminalFee ||
    stageType === StorageServiceTypeEnum.Storage
  );
};

export function resourceStageIsLegacyShipmentStage(
  resourceStage?: Stage
): resourceStage is LegacyShipmentStage & {stage_type: 'LEGACY_SHIPMENT'} {
  return resourceStage?.stage_type === LegacyShipmentStageStageTypeEnum.LegacyShipment;
}
export function isLegacyShipmentStage(resourceId: string) {
  return Uuid.isCanonical(resourceId);
}

export function isServiceStage(resourceId: string) {
  return !Uuid.isCanonical(resourceId);
}

export function serviceProviderIsCorrogoServiceProvider(
  serviceProvider?: CorrogoServiceProvider | ServiceProvider
): serviceProvider is CorrogoServiceProvider {
  return Boolean((serviceProvider as CorrogoServiceProvider)?.provider_id !== undefined);
}

//Before we can access the service field, we need confirm that the stage is of type service,
//since other stage types don't have this field.
export function resourceStageIsServiceStage(
  resourceStage?: Stage
): resourceStage is ServiceStage & {stage_type: 'SERVICE'} {
  return resourceStage?.stage_type === ServiceStageStageTypeEnum.Service;
}

//Narrow down the service stages to those that have the service field,
//or are type analogs to the transload type. Service provider is required, so we use this to limit
//the members who may have the optional service field.
export function isTransloadAnalogServiceStage(
  serviceStage?: ShipmentService
): serviceStage is Transload & {service_type: 'TRANSLOAD'} {
  return Boolean((serviceStage as Transload)?.service_provider);
}

export const shouldPrepopulateEquipmentTypeField = (resourceId: string, legacyShipment: Shipment | undefined) => {
  if (!resourceId || !legacyShipment) {
    return;
  }
  return (
    isLegacyShipmentStage(resourceId) &&
    legacyShipment?.mode?.code &&
    //don't prepopulate equipment type from drayage stage
    LEGACY_SHIPMENT_MODES.filter((mode) => mode !== 'Drayage').includes(legacyShipment.mode.code)
  );
};
export const getResourceServiceStageDataIfServiceStage = (resourceId: string, resourceStage?: Stage) =>
  isServiceStage(resourceId) &&
  resourceStage &&
  //narrow down the resource stage to types that have the service field.
  resourceStageIsServiceStage(resourceStage) &&
  //narrow down the service stages to types that have stage modal fields..
  //(i.e., location, actual_datetimes)
  isTransloadAnalogServiceStage(resourceStage.service)
    ? resourceStage.service
    : null;

export interface StageDependencies {
  stage_id: string;
  directly_depends_on_stages: string[];
}

export const getDependentStagesUpdatedDependencies = (
  relatedStages: Stage[],
  resourceId: string
): StageDependencies[] => {
  const stageToDelete = relatedStages.find((stage) => stage.id === resourceId);
  const stageToDeleteIndex = relatedStages.findIndex((stage) => stage.id === stageToDelete?.id);
  return relatedStages.map((stage) => {
    //there is a simple, linear relationship between stage dependencies.
    if (stage.directly_depends_on_stages.length === 1 && stage.directly_depends_on_stages[0] === stageToDelete?.id) {
      return {
        ...mapStageToStageDependencies(stage),
        //find the stage that occurs immediately prior to the stage to be deleted,
        //and replace the reference to the stage id to be deleted with the prior stage id.
        directly_depends_on_stages: relatedStages[stageToDeleteIndex - 1]
          ? [relatedStages[stageToDeleteIndex - 1].id]
          : []
      };
    }
    return mapStageToStageDependencies(stage);
  });
};

export const mapStageToStageDependencies = (stage: Stage): StageDependencies => {
  return {stage_id: stage.id, directly_depends_on_stages: stage.directly_depends_on_stages};
};

export const getUpdatedStagesList = (
  relatedStages: Stage[],
  resourceId: string,
  stages: Stage[]
): StageDependencies[] => {
  //update the dependencies for the stages related to the resource to be deleted.
  const dependentStagesUpdatedDependencies = getDependentStagesUpdatedDependencies(relatedStages, resourceId);
  //map the full stage objects to the stage dependency properties.
  const fullStageDependenciesList = stages.map((stage) => mapStageToStageDependencies(stage));
  //update the full list of stages and their dependencies
  // with the newly updated dependent stages.
  return fullStageDependenciesList.map((stage) => {
    const updatedStage = dependentStagesUpdatedDependencies.find(
      (updatedStage) => updatedStage.stage_id === stage.stage_id
    );
    if (updatedStage) {
      return updatedStage;
    }
    return stage;
  });
};

export const hasDirectlyDependsOnStages = (stage: Stage) => stage.directly_depends_on_stages.length > 0;

export const formatLegacyStopDateTime = (legacyShipmentStop?: Stop): string | null =>
  legacyShipmentStop && legacyShipmentStop.planned_date && legacyShipmentStop.planned_time_window_start
    ? //the user is creating a stage from a legacy shipment, and that legacy shipment has requisite data..
      //to prefill the stop date/time.
      dateConstructorSafariPolyfill(
        `${legacyShipmentStop.planned_date} ${legacyShipmentStop.planned_time_window_start}`
      ).toISOString()
    : null;

// if the previous stage is a legacy shipment, we want to make sure
// the data is formatted as if it were an address book entry, so we
// spread both the location and address to have address fields both
// top level and nested, which is how an address book entry is structured

// if the previous stage is a service, we spread the address book data
// we retrieved from the `system_id` on the service location
export const getPrefilledLegacyStopLocation = (legacyShipmentStop?: Stop): LegacyStopLocation | LegacyAddress | null =>
  legacyShipmentStop?.location.address
    ? {
        ...legacyShipmentStop.location,
        ...omit(legacyShipmentStop.location.address, 'id')
      }
    : null;

export const isCurrencyCode = (stringValue: string | undefined | null): stringValue is ShipmentPreferredCurrencyEnum =>
  !!stringValue && Object.values(ShipmentPreferredCurrencyEnum).includes(stringValue as ShipmentPreferredCurrencyEnum);
