import {DeprecatedButton, SvgIcon, Tooltip, IconButton} from '@shipwell/shipwell-ui';
import {validateDateTime} from 'App/utils/dateTimeGlobals';
import {formatDate} from 'App/utils/dateTimeGlobalsTyped';
import {Rate} from 'src/@types/quotingTypes';
import {formatCurrency} from 'App/utils/internationalConstants';
import {MouseEvent, ReactNode} from 'react';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {OverlayTrigger, Tooltip as ReactTooltip} from 'react-bootstrap';
import {createColumnHelper, Row} from '@tanstack/react-table';
import isNil from 'lodash/isNil';
import {useIsMutating} from '@tanstack/react-query';
import {QuoteOwner} from './components/QuoteOwner/QuoteOwner';
import {getHasError} from 'App/containers/InstantRatesV2/hooks/utils';
import {CREATE_CARRIER_CONNECTION_QUOTE_MUTATION_KEY} from 'App/data-hooks/mutationKeys';

interface OnRateSelectChangeEvent {
  (rate: Rate, selected?: boolean): void;
}

type OnRateStopsUpdateChangeEvent = (rate: Rate) => void;

type OnRateStopsAcceptChangeEvent = (rate: Rate) => void;

interface InstantRatesButtonSelectionPropTypes {
  isSelected?: boolean;
  isDisabled?: boolean;
  rateId: string;
  onClick: () => void;
  buttonText?: string;
}

interface InstantRatesUpdateButtonStopsProps {
  onClick: () => void;
}

const InstantRatesButtonSelection = (props: InstantRatesButtonSelectionPropTypes) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  };

  const isSelecting = !!useIsMutating({
    mutationKey: [CREATE_CARRIER_CONNECTION_QUOTE_MUTATION_KEY]
  });

  const loading = !!useIsMutating({
    mutationKey: [CREATE_CARRIER_CONNECTION_QUOTE_MUTATION_KEY],
    predicate: (mutation) => {
      return mutation.options.variables?.rateId == props.rateId;
    }
  });

  return (
    <DeprecatedButton
      size="small"
      disabled={props.isDisabled || isSelecting}
      onClick={onClickHandler}
      loading={loading}
    >
      {props.buttonText ? props.buttonText : props.isSelected ? 'Selected' : 'Select'}
    </DeprecatedButton>
  );
};

const ChangeHereButtonSelection = (props: InstantRatesUpdateButtonStopsProps) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <DeprecatedButton size="small" className="float-right" onClick={onClickHandler}>
      Change here
    </DeprecatedButton>
  );
};

const ChangeAcceptRecommendationButtonSelection = (props: InstantRatesUpdateButtonStopsProps) => {
  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <DeprecatedButton size="small" className="float-right ml-2" onClick={onClickHandler}>
      Accept
    </DeprecatedButton>
  );
};

interface ServiceLevel {
  row: Row<Rate>;
  company_name: string;
  company_id: string;
}

const ServiceLevelColumn = ({row, company_name, company_id}: ServiceLevel) => {
  const quote = row.original;
  const dispatchError = !!quote && !quote.can_dispatch && (
    <OverlayTrigger
      placement="top"
      overlay={
        <ReactTooltip placement="bottom">
          <div>{quote?.error_message ? quote?.error_message : '--'}</div>
        </ReactTooltip>
      }
    >
      <i className="icon icon-Delayed text-danger top-0 text-base" />
    </OverlayTrigger>
  );
  const isVLTL = !!quote && !!quote.mode && quote.mode.code === 'VLTL' && (
    <OverlayTrigger
      placement="top"
      overlay={
        <ReactTooltip placement="bottom">
          <div>Volume LTL quote</div>
        </ReactTooltip>
      }
    >
      <i className="icon icon-Adjust top-0 text-base" />
    </OverlayTrigger>
  );
  const isCSP = !!quote && !!quote.is_csp_rate && (
    <OverlayTrigger
      placement="top"
      overlay={
        <ReactTooltip placement="bottom">
          <div>Customer-specific rate</div>
        </ReactTooltip>
      }
    >
      <i className="icon icon-Handshake top-0 text-base" />
    </OverlayTrigger>
  );

  const providerLogo = quote.provider_logo_url ? <img src={quote.provider_logo_url} height="14" alt="Echo" /> : '';

  return (
    <div className="instant-rates__columns-details">
      {quote.service_level && typeof quote.service_level !== 'string' && 'description' in quote.service_level && (
        <span>{quote.service_level?.description}</span>
      )}
      <span>{quote.lane_type !== 'UNSPECIFIED' ? startCaseToLower(quote.lane_type) : ''}</span>
      <span className="instant-rates__columns-details-icons flex items-center">
        {isVLTL}
        {isCSP}
        {providerLogo}
        <QuoteOwner companyId={company_id} companyName={company_name} quote={quote} />
        {dispatchError}
      </span>
    </div>
  );
};

const columnHelper = createColumnHelper<Rate & {expandedContent?: ReactNode}>();
/**
 * Creates table header definitions and behaviors for each row.
 * @param onSelect callback function for when the "select" button is clicked.
 * @param selectedRateId the row to select by rate id
 * @param expandedColumnSet true to show all additional columns, default is false.
 */
const createInstantRatesTableColumns = (
  canTakeActionOnRates: boolean,
  isAllowedToSelectRate: boolean,
  mode: string,
  company_id: string,
  company_name: string,
  onSelect: OnRateSelectChangeEvent,
  onShowUpdateStops: OnRateStopsUpdateChangeEvent,
  acceptRecommendation: OnRateStopsAcceptChangeEvent,
  expandedColumnSet?: boolean,
  shouldHideRates = false,
  buttonText?: string
) => {
  const FailedMessageTootipComponent = () => (
    <div className="flex w-full items-center justify-end gap-2 text-sw-error">
      Failed to Quote
      <SvgIcon width="20px" height="16px" name="ErrorFilled" color="sw-error" />
    </div>
  );

  const MessageTootipComponent = () => <SvgIcon width="20px" height="20px" name="ErrorOutlined" color="sw-info" />;

  const columns = [
    columnHelper.accessor('carrier.display_name', {
      size: 150,
      header: 'Carrier',
      cell: ({row}) => {
        const isFedex = row.original?.carrier?.display_name?.toLowerCase() === 'fedex';

        return (
          <div className="flex flex-col">
            {mode === 'LTL' ? (
              <>
                <div className="space-x-1">
                  {row.original.carrier
                    ? row.original.carrier.display_name
                    : row.original.created_by_company
                    ? row.original.created_by_company.name
                    : '--'}
                  {isFedex && !!row.original?.carrier_code && `(${row.original.carrier_code})`}
                </div>
                {!!row.original.carrier?.account_code && (
                  <span className="text-xs text-sw-disabled-text">{row.original.carrier.account_code}</span>
                )}
                {!!row.original.capacity_provider?.account_name && (
                  <span className="text-xs text-sw-disabled-text">{row.original.capacity_provider?.account_name}</span>
                )}
              </>
            ) : (
              row?.original?.carrier?.display_name
            )}
          </div>
        );
      }
    }),
    ...(mode === 'FTL'
      ? [
          columnHelper.accessor('earliest_pickup_date', {
            size: 100,
            header: 'Pickup',
            cell: ({row, getValue}) => {
              const value = getValue();
              return value && row.original?.earliest_pickup_date && validateDateTime(value) ? (
                formatDate(value)
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              );
            }
          })
        ]
      : []),
    ...(mode === 'LTL'
      ? [
          columnHelper.accessor('service_level', {
            size: 150,
            header: 'Service',
            cell: ({row}) => <ServiceLevelColumn row={row} company_id={company_id} company_name={company_name} />
          })
        ]
      : []),
    ...(expandedColumnSet
      ? [
          columnHelper.accessor('delivery_date', {
            size: 100,
            header: 'Delivery',
            cell: ({row, getValue}) => {
              const value = getValue();
              return value && row.original?.delivery_date && validateDateTime(value) ? (
                formatDate(value)
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              );
            }
          }),
          columnHelper.accessor('transit_days', {
            size: 100,
            header: 'Transit Time',
            cell: ({row, getValue}) => {
              const value = getValue();
              return !isNil(row.original?.transit_days) && !Number.isNaN(row.original?.transit_days) ? (
                <div className="flex w-full justify-center">{value} days</div>
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              );
            }
          })
        ]
      : []),
    ...(mode === 'FTL'
      ? [
          columnHelper.accessor('expires_at', {
            size: 100,
            header: 'Expires',
            cell: ({row, getValue}) => {
              const value = getValue();
              return value && row.original?.expires_at && validateDateTime(value) ? (
                formatDate(value)
              ) : (
                <div className="flex w-full justify-center">
                  <span>--</span>
                </div>
              );
            }
          })
        ]
      : []),
    columnHelper.accessor('rate.amount', {
      id: 'rate',
      size: 200,
      header: 'Rate',
      enableSorting: true,
      sortUndefined: 'last',
      cell: ({row}) => {
        const hasError = getHasError(row.original);

        return !hasError ? (
          <div className="w-full space-y-6">
            <div className="flex w-full flex-row justify-end items-center">
              <div className="flex flex-row">
                <div className="text-black pr-2 font-bold">
                  {shouldHideRates
                    ? '--'
                    : row.original?.rate?.amount
                    ? formatCurrency(row.original?.rate?.amount, row.original?.rate?.currency)
                    : '--'}
                </div>
                <div>
                  {!shouldHideRates && row.original.recommendations && row.original.recommendations?.length > 0 ? (
                    <Tooltip
                      portal
                      tooltipClassname="w-64"
                      tooltipContent={
                        <div className="font-bold">
                          <ul>
                            {row.original.recommendations &&
                              row.original.recommendations?.map((recommendation, index) => (
                                <li key={index}>- {recommendation.message}</li>
                              ))}
                          </ul>
                          <br />
                          <ChangeAcceptRecommendationButtonSelection
                            onClick={() => acceptRecommendation(row.original)}
                          />
                          <ChangeHereButtonSelection onClick={() => onShowUpdateStops(row.original)} />
                        </div>
                      }
                    >
                      <MessageTootipComponent />
                    </Tooltip>
                  ) : null}
                </div>
              </div>

              {!shouldHideRates && !!row.original.expandedContent && (
                <IconButton
                  aria-label="expand-rates"
                  onClick={() => row.toggleExpanded()}
                  iconName={row.getIsExpanded() ? 'ExpandLess' : 'ExpandMore'}
                />
              )}
            </div>
            {row.getIsExpanded() && (
              <div className="flex w-full flex-row justify-end">{row.original.expandedContent}</div>
            )}
          </div>
        ) : (
          <div className="flex w-full flex-row justify-end">
            <Tooltip
              portal
              tooltipContent={
                <div className="font-bold max-w-96">
                  {!!row.original.error_message && <span>{row.original.error_message}</span>}
                  {!!row.original.errors?.length && (
                    <ul>
                      {row.original.errors.map((error, index) => (
                        <li key={index}>
                          <span>{error}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {!row.original.error_message && !row.original.errors?.length && <span>Unknown error</span>}
                </div>
              }
            >
              <FailedMessageTootipComponent />
            </Tooltip>
          </div>
        );
      }
    }),
    ...(canTakeActionOnRates
      ? [
          columnHelper.display({
            size: 100,
            header: 'Actions',
            cell: ({row}) => {
              const hasError = getHasError(row.original);
              const isDisabled = !isAllowedToSelectRate || hasError;
              const isSelected = isDisabled && !!row.original.legacyQuote?.accepted_at;

              return (
                <InstantRatesButtonSelection
                  isSelected={isSelected}
                  isDisabled={isDisabled}
                  onClick={() => onSelect(row.original)}
                  rateId={row.original.id}
                  buttonText={buttonText}
                />
              );
            }
          })
        ]
      : [])
  ];

  return columns;
};

export {createInstantRatesTableColumns};
