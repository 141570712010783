import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Scroll from 'react-scroll';
var Element = Scroll.Element;

@connect((state) => ({}), {})
export default class VendorsFMCSACensusData extends Component {
  constructor(props) {
    super(props);

    this.state = {mcNumber: ''};
  }

  componentDidMount() {
    if (
      this.props.fmcsaCensusData &&
      this.props.fmcsaCensusData.identification_codes &&
      this.props.fmcsaCensusData.identification_codes.length > 0
    ) {
      for (let i = 0; i < this.props.fmcsaCensusData.identification_codes.length; i++) {
        if (this.props.fmcsaCensusData.identification_codes[i].id === 'MC_NUMBER') {
          this.setState({mcNumber: this.props.fmcsaCensusData.identification_codes[i].name});
        }
      }
    }
  }

  renderReadOnly() {
    const {fmcsaCensusData} = this.props;

    return (
      <div className="vendors__shipwell-vendor gray">
        <Grid container>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Total Power Units</b>
            </p>
            <p className="read-only">
              {fmcsaCensusData && fmcsaCensusData.total_power_units ? fmcsaCensusData.total_power_units : '-'}
            </p>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Total Drivers</b>
            </p>
            <p className="read-only">
              {fmcsaCensusData && fmcsaCensusData.total_drivers ? fmcsaCensusData.total_drivers : '-'}
            </p>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Safety Rating</b>
            </p>
            <p className="read-only">
              {fmcsaCensusData && fmcsaCensusData.safety_rating ? fmcsaCensusData.safety_rating : '-'}
            </p>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Safety Rating Date</b>
            </p>
            <p className="read-only">
              {fmcsaCensusData &&
              fmcsaCensusData.safety_rating_date &&
              moment(fmcsaCensusData.safety_rating_date).isValid()
                ? moment(fmcsaCensusData.safety_rating_date).format('MMM DD, YYYY')
                : '-'}
            </p>
          </Grid>
        </Grid>
        <Grid container>
          {/*<Grid item md={2} sm={6} xs={12}>   //hide for now
            <p>
              <b>OUT OF SERVICE</b>
            </p>
            <p>{fmcsaCensusData && fmcsaCensusData.active ? 'No' : !_.isEmpty(fmcsaCensusData) ? 'Yes' : ''}</p>
          </Grid>*/}
          <Grid item md={3} sm={6} xs={12}>
            <p>
              <b>Operating Authority</b>
            </p>
            <p className="read-only">{this.state.mcNumber ? this.state.mcNumber : '-'}</p>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Paper className="gray">
          <div className="vendors__header">
            <h3>
              <b>Safety & Review</b>
            </h3>
          </div>
          <Element name={`position-vendor.shipwell_vendor.name`} />
          {this.renderReadOnly()}
        </Paper>
      </div>
    );
  }
}
