import {useQuery, useQueryClient} from '@tanstack/react-query';
import {
  DrayageTransportationOrder,
  ChargeCategory,
  Charge,
  StopLocation,
  OrderStatus,
  ContainerOrderItem,
  Financials,
  ProductOrderItem,
  DrayageTransportationOrderOrderItemsInner
} from '@shipwell/corrogo-sdk';
import {TRANSPORTATION_ORDER_QUERY_KEY, TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {convertKilometersToMiles, formatCurrency} from 'App/utils/internationalConstants';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {
  getShipmentTransportationOrders,
  getDrayageTransportationOrder,
  getTransportationOrderDocuments
} from 'App/api/corrogo/typed';
import {
  calculateTotalChargesWithCredits,
  calculateTotalCharge
} from 'App/containers/shipments/utils/tenderRateCalculations';

const generateLineItemRow = (charge: Charge, isDebit: boolean) => [
  startCaseToLower(charge.name || charge.category),
  charge.description || '--',
  charge.quantity,
  isDebit ? '--' : formatCurrency(parseFloat(charge.unit_amount.value)),
  isDebit ? formatCurrency(parseFloat(charge.unit_amount.value)) : '--'
];

const getOrderItemFinancials = (orderItem?: ContainerOrderItem | ProductOrderItem, financials?: Financials) => {
  if (!orderItem || !financials) {
    return {
      associatedDebits: [],
      associatedCredits: [],
      totalCharge: 0
    };
  }
  const associatedDebits = financials.debits?.filter((debit) => debit.item_id === orderItem.id) || [];
  const associatedCredits = financials.credits?.filter((credit) => credit.item_id === orderItem.id) || [];

  const totalMinusCredits = formatCurrency(calculateTotalCharge(associatedDebits));
  const totalCharge = formatCurrency(calculateTotalChargesWithCredits(associatedDebits, associatedCredits));

  const lineItemDisplayValues = [
    ...associatedDebits.map((charge) => generateLineItemRow(charge, true)),
    ...associatedCredits.map((charge) => generateLineItemRow(charge, false))
  ];

  return {
    associatedDebits,
    associatedCredits,
    totalMinusCredits,
    totalCharge,
    lineItemDisplayValues
  };
};

function getRateDetails(debits?: Charge[]) {
  const getRateByCategory = (category: ChargeCategory) =>
    parseFloat(debits?.find((debit) => debit.category === category)?.unit_amount.value || '0');

  const lineHaul = getRateByCategory(ChargeCategory.LineHaul);
  const fuelSurcharge = getRateByCategory(ChargeCategory.FuelSurcharge);
  const ratePerContainer = lineHaul + fuelSurcharge;

  return {lineHaul, fuelSurcharge, ratePerContainer};
}

function getLocationDescription(location: StopLocation) {
  const {
    address: {locality, region, postal_code}
  } = location;
  if (!locality || !region || !postal_code) {
    return '--';
  }
  return `${locality}, ${region} ${postal_code}`;
}

const transformTransportationOrderToDisplayValues = (order?: DrayageTransportationOrder) => {
  if (!order) {
    return {
      id: '',
      carrier: '--',
      carrierId: '',
      numberOfContainers: 0,
      expiration: '--',
      rawStatus: '--',
      status: '--',
      rawRatePerContainer: 0,
      ratePerContainer: 0,
      created_at: '',
      eta: '--',
      customer: '--'
    };
  }

  const numberOfContainers = order.order_items.length;
  const status = startCaseToLower(order.status);
  const {lineHaul, fuelSurcharge, ratePerContainer} = getRateDetails(order.initial_financials.debits);

  const eta = order.import_info?.release_date
    ? formatDateTime(order.import_info.release_date)
    : order.export_info?.cut_date
    ? formatDateTime(order.export_info?.cut_date)
    : '--';

  return {
    id: order.id,
    carrierId: order.service_provider.provider_id || '',
    carrier: order.service_provider.name || '--',
    numberOfContainers,
    expiration: order.expiration ? formatDateTime(order.expiration) : '--',
    createdAt: order.created_at ? formatDateTime(order.created_at) : '--',
    statusHeader: status.toUpperCase(),
    status,
    lineHaul,
    fuelSurcharge,
    rawRatePerContainer: ratePerContainer,
    ratePerContainer: formatCurrency(ratePerContainer),
    pickupLocation: getLocationDescription(order.stops[0].location),
    deliveryLocation: getLocationDescription(order.stops[1].location),
    eta,
    customer: order.booking_party.name || '--',
    totalMiles: convertKilometersToMiles(order.total_kilometers || 0, 2) as string
  };
};

const transformTransportationOrdersToDisplayValues = (transportationOrders?: DrayageTransportationOrder[]) =>
  transportationOrders?.map((order) => transformTransportationOrderToDisplayValues(order)) || [];

export const useTransportationOrder = (transportationOrderId: string, shipmentId?: string) => {
  const queryClient = useQueryClient();

  const getShipmentTransportationOrderQuery = useQuery(
    [TRANSPORTATION_ORDER_QUERY_KEY, shipmentId, transportationOrderId],
    async () => {
      if (!transportationOrderId) {
        return Promise.reject(new Error('Invalid transportation order id'));
      }
      const response = await getDrayageTransportationOrder(transportationOrderId);
      return response.data;
    },
    {
      enabled: !!transportationOrderId,
      placeholderData: () =>
        queryClient
          .getQueryData<DrayageTransportationOrder[]>([TRANSPORTATION_ORDER_QUERY_KEY, shipmentId])
          ?.find((transportationOrder: DrayageTransportationOrder) => transportationOrder.id === transportationOrderId)
    }
  );

  const transportationOrder = getShipmentTransportationOrderQuery.data;

  const transportationOrderDisplayValues = transformTransportationOrderToDisplayValues(transportationOrder);

  const debits = transportationOrder?.initial_financials.debits || [];

  const accessorials = debits.filter((debit) => debit.category === ChargeCategory.Accessorial) || ([] as Charge[]);
  const {ratePerContainer} = getRateDetails(debits);

  const getServiceProviderFinancialDisplayValuesFromOrderItem = (
    orderItem: ContainerOrderItem | DrayageTransportationOrderOrderItemsInner
  ) => getOrderItemFinancials(orderItem, transportationOrder?.service_provider_financials);

  const getOrderItemById = (orderItemId: string) =>
    transportationOrder?.order_items.find((orderItem) => orderItem.id === orderItemId);

  return {
    transportationOrderDisplayValues,
    debits,
    accessorials,
    ratePerContainer,
    queryInfo: getShipmentTransportationOrderQuery,
    getServiceProviderFinancialDisplayValuesFromOrderItem,
    getOrderItemById
  };
};

const useTransportationOrders = (shipmentId?: string) => {
  const getShipmentTransportationOrdersQuery = useQuery([TRANSPORTATION_ORDER_QUERY_KEY, shipmentId], async () => {
    if (!shipmentId) {
      return Promise.reject(new Error('Invalid shipment ID'));
    }
    const response = await getShipmentTransportationOrders(shipmentId);
    return response.data.data;
  });

  const transportationOrderDisplayValues = transformTransportationOrdersToDisplayValues(
    getShipmentTransportationOrdersQuery.data
  );

  const getTransportationOrdersOfStatus = (status: OrderStatus) =>
    getShipmentTransportationOrdersQuery.data?.filter((transportationOrder) => transportationOrder.status === status);

  const getTransportationOrderItems = (transportationOrderId: string) =>
    getTransportationOrdersOfStatus(OrderStatus.Accepted)?.find(
      (transportationOrder) => transportationOrderId === transportationOrder.id
    )?.order_items;

  const getTransportaionOrderDocumentsQuery = useQuery(
    [TRANSPORTATION_ORDER_DOCUMENTS_QUERY_KEY, shipmentId],
    async () => {
      const transportationOrders = getTransportationOrdersOfStatus(OrderStatus.Accepted) || [];
      const response = await Promise.all(
        transportationOrders.map(async (transportationOrder) => {
          const transportationOrderId = transportationOrder.id;
          const docResponse = await getTransportationOrderDocuments(transportationOrderId);
          const documents = docResponse.data.data || [];
          return documents.map((document) => ({...document, transportationOrderId}));
        })
      );
      return response.flat();
    },
    {enabled: !!shipmentId}
  );
  const transportationOrderDocuments = getTransportaionOrderDocumentsQuery.data || [];

  return {
    isLoading: getTransportaionOrderDocumentsQuery.isInitialLoading,
    isSuccess: getTransportaionOrderDocumentsQuery.isSuccess,
    isError: getTransportaionOrderDocumentsQuery.isError,
    isFetching: getTransportaionOrderDocumentsQuery.isFetching,

    transportationOrderDisplayValues,
    getTransportationOrdersOfStatus,
    getTransportationOrderItems,
    queryInfo: getShipmentTransportationOrdersQuery,
    transportationOrderDocuments
  };
};

export default useTransportationOrders;
