import {useQuery} from '@tanstack/react-query';
import {DashboardSearchParams} from '../../commons/dashboardSearchParams';
import {DashboardSearchResult} from '../../commons/dashboardSearchResult';
import {useState} from 'react';
import {v4} from 'uuid';

export interface UseSearchServiceProps<TItem, TExtraParams> {
  id?: string;
  params: DashboardSearchParams<TExtraParams>;
  searchFn: (params: DashboardSearchParams<TExtraParams>) => Promise<DashboardSearchResult<TItem>>;
}

export function useSearchService<TItems, TExtraParams>(props: UseSearchServiceProps<TItems, TExtraParams>) {
  const [queryName] = useState(() => `simpleDashboardQuery-${v4()}`);

  const listQuery = useQuery({
    queryKey: [queryName, props.params],
    queryFn: () => props.searchFn(props.params)
  });

  return {
    data: listQuery.data,
    isLoading: listQuery.isFetching || listQuery.isLoading || listQuery.isRefetching,
    refetch: listQuery.refetch
  };
}
