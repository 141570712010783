import {SvgIconName} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum, ReferenceQualifierEnum} from '@shipwell/tempus-sdk';

export const FULL_CALENDAR_LICENSE_KEY = '0718307691-fcs-1712598500';
export const BigCalendarAllDayEventClass = 'sw-all-day-event';
export const BigCalendarCurrentDateTimeId = 'BigCalendarCurrentDateTimeId';

export const FacilityAppointmentResourceId = '000_AAA_FacilityAppointmentsResourceId';
export const FacilityAppointmentResourceName = 'Facility Appointments';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InitAppointmentCallback = async (...args: any[]) => {
  console.log('NOOP', {args});
};

export const DocklessEventColor = '#a8a8a8';
export const DefaultAppointmentDurationMinutes = 60;

export const StatusToColor: Record<AppointmentStatusEnum, string> = {
  [AppointmentStatusEnum.Arrived]: 'bg-sw-success-background-hex',
  [AppointmentStatusEnum.ArrivedLate]: 'bg-sw-error-background-hex',
  [AppointmentStatusEnum.Unscheduled]: 'bg-sw-background-component',
  [AppointmentStatusEnum.Cancelled]: 'bg-sw-error-background-hex',
  [AppointmentStatusEnum.Completed]: 'bg-sw-disabled-alternate',
  [AppointmentStatusEnum.Delayed]: 'bg-sw-warning-background-hex',
  [AppointmentStatusEnum.NoShow]: 'bg-sw-error-background-hex',
  [AppointmentStatusEnum.Rejected]: 'bg-sw-error-background-hex',
  [AppointmentStatusEnum.Scheduled]: 'bg-sw-active-light-hex',
  [AppointmentStatusEnum.Rescheduled]: 'bg-sw-background-component',
  [AppointmentStatusEnum.DockInUse]: 'bg-sw-warning-background-hex',
  [AppointmentStatusEnum.DockIsReady]: 'bg-sw-success-background-hex'
};

export const SvgIconColors: Record<AppointmentStatusEnum, string> = {
  [AppointmentStatusEnum.Arrived]: 'sw-success',
  [AppointmentStatusEnum.ArrivedLate]: 'sw-error',
  [AppointmentStatusEnum.Cancelled]: 'sw-background-header',
  [AppointmentStatusEnum.Completed]: 'sw-background-header',
  [AppointmentStatusEnum.NoShow]: 'sw-error',
  [AppointmentStatusEnum.Scheduled]: 'sw-active',
  [AppointmentStatusEnum.Rescheduled]: 'sw-active',
  [AppointmentStatusEnum.Unscheduled]: 'sw-background-header',
  [AppointmentStatusEnum.Rejected]: 'sw-error',
  [AppointmentStatusEnum.Delayed]: 'sw-warning',
  [AppointmentStatusEnum.DockInUse]: 'sw-warning',
  [AppointmentStatusEnum.DockIsReady]: 'sw-success'
};

export const SvgIconNames: Record<AppointmentStatusEnum, SvgIconName> = {
  [AppointmentStatusEnum.Arrived]: 'Time',
  [AppointmentStatusEnum.ArrivedLate]: 'TimeMissing',
  [AppointmentStatusEnum.Cancelled]: 'CalendarCancelled',
  [AppointmentStatusEnum.Completed]: 'CheckCircleFilled',
  [AppointmentStatusEnum.NoShow]: 'ErrorOutlined',
  [AppointmentStatusEnum.Scheduled]: 'Calendar',
  [AppointmentStatusEnum.Rescheduled]: 'Calendar',
  [AppointmentStatusEnum.Unscheduled]: 'UnplannedTime',
  [AppointmentStatusEnum.Rejected]: 'ThumbsDown',
  [AppointmentStatusEnum.Delayed]: 'CalendarCancelled',
  [AppointmentStatusEnum.DockInUse]: 'CalendarCancelled',
  [AppointmentStatusEnum.DockIsReady]: 'CalendarCheck'
};

export const StatusTexts: Record<AppointmentStatusEnum, string> = {
  [AppointmentStatusEnum.Arrived]: 'Arrived',
  [AppointmentStatusEnum.ArrivedLate]: 'ArrivedLate',
  [AppointmentStatusEnum.Cancelled]: 'Cancelled',
  [AppointmentStatusEnum.Completed]: 'Complete',
  [AppointmentStatusEnum.NoShow]: 'No Show',
  [AppointmentStatusEnum.Scheduled]: 'Scheduled',
  [AppointmentStatusEnum.Rescheduled]: 'Rescheduled',
  [AppointmentStatusEnum.Unscheduled]: 'Unscheduled',
  [AppointmentStatusEnum.Rejected]: 'Rejected',
  [AppointmentStatusEnum.Delayed]: 'Delayed',

  [AppointmentStatusEnum.DockInUse]: 'Dock Occupied',
  [AppointmentStatusEnum.DockIsReady]: 'Dock Is Ready'
};

export const ReferenceAbbreviatedLabels: Record<ReferenceQualifierEnum, string> = {
  [ReferenceQualifierEnum.PoNumber]: 'PO#',
  [ReferenceQualifierEnum.BolNumber]: 'BOL#',
  [ReferenceQualifierEnum.CustomerReferenceNumber]: 'Customer Ref#',
  [ReferenceQualifierEnum.ProNumber]: 'PRO#',
  [ReferenceQualifierEnum.ShipmentReferenceId]: 'Shipment Ref#',
  [ReferenceQualifierEnum.OrderNumber]: 'Order#'
};

export const ReferencePreferenceOrder = [
  ReferenceQualifierEnum.PoNumber,
  ReferenceQualifierEnum.CustomerReferenceNumber,
  ReferenceQualifierEnum.ProNumber,
  ReferenceQualifierEnum.ShipmentReferenceId,
  ReferenceQualifierEnum.BolNumber,
  ReferenceQualifierEnum.OrderNumber
];

export const ReferenceQualifierNames: Record<ReferenceQualifierEnum, string> = {
  [ReferenceQualifierEnum.PoNumber]: 'Purchase Order',
  [ReferenceQualifierEnum.BolNumber]: 'Bill of Lading',
  [ReferenceQualifierEnum.CustomerReferenceNumber]: 'Customer Reference',
  [ReferenceQualifierEnum.ProNumber]: 'PRO',
  [ReferenceQualifierEnum.ShipmentReferenceId]: 'Shipment',
  [ReferenceQualifierEnum.OrderNumber]: 'Order Number'
};
