import {ReactNode} from 'react';
import {
  Shipment,
  RFQ,
  SlimRFQ,
  FedexShipmentOptions,
  UPSShipmentOptions,
  USPSShipmentOptions
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SelectFedexRatesAccount} from '../SelectFedexRatesAccount';
import {SelectUpsRatesAccount} from '../SelectUpsRatesAccount';
import {useCreateRfq} from 'App/api/quoting/hooks/useCreateRfq';

type ParcelOptions = {
  fedex_specific_options?: FedexShipmentOptions | null;
  ups_specific_options?: UPSShipmentOptions | null;
  usps_specific_options?: USPSShipmentOptions | null;
};

export const RatesAccountSelector = ({
  providerCode,
  shipment,
  setRFQFromShipment,
  rfq,
  packageType,
  setIsLoadingRfqCreation
}: {
  providerCode: string;
  shipment: Shipment;
  setRFQFromShipment: (rfq: RFQ) => void;
  rfq: RFQ;
  packageType: string;
  setIsLoadingRfqCreation: (isLoading: boolean) => void;
}) => {
  const {mutateAsync: createParcelRFQ} = useCreateRfq({
    onMutate: () => {
      setIsLoadingRfqCreation(true);
    },
    onSettled: () => {
      setIsLoadingRfqCreation(false);
    }
  });

  const handleCreateParcelRFQ = async (shipment: Shipment, provider: string) => {
    let {fedex_specific_options, ups_specific_options, usps_specific_options} = rfq;
    const packageTypeForRfq = packageType === 'MIXED_TYPES' ? 'YOUR_PACKAGING' : packageType;
    // override the provider specific options from the orignal rfq
    // with the new account number that was just set on the shipment
    if (provider === 'fedex') {
      fedex_specific_options = shipment.fedex_specific_options;
    } else if (provider === 'ups') {
      ups_specific_options = shipment.ups_specific_options;
    } else if (provider === 'usps') {
      usps_specific_options = shipment.usps_specific_options;
    }

    if (!shipment.mode) return;

    await createParcelRFQ(
      {
        rFQ: {
          shipment: shipment.id,
          parent_rfq: null as unknown as SlimRFQ,
          shipment_modes: [shipment.mode],
          autoquote: true,
          fedex_specific_options: fedex_specific_options
            ? {...fedex_specific_options, packaging: packageTypeForRfq}
            : null,
          ups_specific_options: ups_specific_options ? {...ups_specific_options, packaging: packageTypeForRfq} : null,
          usps_specific_options: usps_specific_options ? {...usps_specific_options, packaging: packageTypeForRfq} : null
        } as RFQ
      },
      {
        onSuccess: (data) => {
          setRFQFromShipment(data);
        }
      }
    );
  };

  const handleAccountChange = async (selectedAccount: string, provider: string) => {
    if (selectedAccount && provider) {
      const options = `${provider}_specific_options`;

      const shipmentForQuote = {
        ...shipment,
        [options]: {
          ...shipment[options as keyof ParcelOptions],
          account: selectedAccount
        }
      } as Shipment;

      await handleCreateParcelRFQ(shipmentForQuote, provider);
    }
  };

  const accountsMapping: Record<string, ReactNode> = {
    fedex: <SelectFedexRatesAccount rfq={rfq} onAccountChange={handleAccountChange} />,
    ups: <SelectUpsRatesAccount rfq={rfq} onAccountChange={handleAccountChange} />
  };

  return <div className="w-full">{accountsMapping[providerCode.toLowerCase()] || null}</div>;
};
