import {ReactNode} from 'react';
import {DEFAULT_FIRST_PAGE, PageSizesValue} from '../../commons/constants';
import {useOptimisticDebounce} from '../useOptimisticDeboucer/useOptimisticDebouncer';
import {remoteLoading} from '../useRemoteLoading/remoteLoading';
import {PageSizeSelect} from './pageSizeSelect/pageSizeSelect';
import {PaginationButton} from './paginationButton/paginationButton';

export interface DashboardFooterProps {
  page?: number;
  perPage?: PageSizesValue;
  totalItems?: number;
  leftElements?: ReactNode;
  onPageChange: (page: number) => void;
  onPerPageChange: (page: PageSizesValue) => void;
}

export function DashboardFooter(props: DashboardFooterProps) {
  const pageDeboucer = useOptimisticDebounce({
    value: props.page,
    onSubmit: props.onPageChange,
    onBeforeDebounce: () => {
      remoteLoading(true);
    },
    onAfterDebounce: () => {
      remoteLoading(false);
    }
  });

  const perPageDeboucer = useOptimisticDebounce({
    value: props.perPage,
    onSubmit: props.onPerPageChange,
    onBeforeDebounce: () => {
      remoteLoading(true);
    },
    onAfterDebounce: () => {
      remoteLoading(false);
    }
  });

  const finalPage =
    props.totalItems && props.perPage ? Math.ceil(props.totalItems / props.perPage) : DEFAULT_FIRST_PAGE;
  const currentPage = pageDeboucer.optimisticValue || DEFAULT_FIRST_PAGE;
  const canGoBack = props.totalItems ? currentPage > DEFAULT_FIRST_PAGE : false;
  const canGoNext = props.totalItems ? currentPage < finalPage : false;

  function goFirst() {
    pageDeboucer.submit(DEFAULT_FIRST_PAGE);
  }

  function goBack() {
    if (!props.totalItems) return;
    const newPage = Math.max(currentPage - 1, DEFAULT_FIRST_PAGE);
    pageDeboucer.submit(newPage);
  }

  function goNext() {
    if (!props.totalItems) return;
    const newPage = Math.min(currentPage + 1, finalPage);
    pageDeboucer.submit(newPage);
  }

  function goLast() {
    if (!props.totalItems) return;
    pageDeboucer.submit(finalPage);
  }

  return (
    <div className={'flex p-2 items-center justify-between bg-sw-background border-t-1 border-t-sw-border'}>
      <div>{props.leftElements}</div>
      <div>
        <div className="flex gap-4 items-center">
          <div>
            <PageSizeSelect value={perPageDeboucer.optimisticValue} onChange={perPageDeboucer.submit} />
          </div>
          <div>
            <span aria-label="current page">
              Page {pageDeboucer.optimisticValue} of {pageDeboucer.optimisticValue ? finalPage : '--'}
            </span>
          </div>
          <div className="flex gap-1">
            <div>
              <PaginationButton iconName="FirstPage" ariaLabel="first page" isDisabled={!canGoBack} onClick={goFirst} />
            </div>
            <div>
              <PaginationButton
                iconName="ArrowLargeLeft"
                ariaLabel="previous page"
                isDisabled={!canGoBack}
                onClick={goBack}
              />
            </div>
            <div>
              <PaginationButton
                iconName="ArrowLargeRight"
                ariaLabel="next page"
                isDisabled={!canGoNext}
                onClick={goNext}
              />
            </div>
            <div>
              <PaginationButton iconName="LastPage" ariaLabel="last page" isDisabled={!canGoNext} onClick={goLast} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
