import {validateEmail, validatePhoneNumber} from 'App/utils/globals';

/**
 * Validation for `Point of Contact` form
 * @param  {Object} values Field values from form
 * @return {Object}        Errors
 */
export const validation = (contact) => {
  const errors = {};

  if (contact.email) {
    if (!validateEmail(contact.email)) {
      errors.email = 'Invalid email';
    }
  }
  if (contact.phone_number) {
    if (!(contact.phone_number.length > 0 && contact.phone_number.length < 5 && contact.phone_number[0] === '+')) {
      if (!validatePhoneNumber(contact.phone_number)) {
        errors.phone_number = 'Invalid phone number';
      }
    }
  }

  return errors;
};
