/* eslint-disable no-undef */
import {useEffect, useState} from 'react';
import {addEventListener, removeEventListener} from './remoteLoading';
import {RemoteLoadingEventDetail} from './remoteLoadingEventDetail';

export function useRemoteLoading() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const eventCallback = (evt: CustomEventInit<RemoteLoadingEventDetail>) => {
      setIsLoading(evt.detail?.isLoading || false);
    };

    addEventListener(eventCallback);

    return () => {
      removeEventListener(eventCallback);
    };
  }, []);

  return {
    isLoading
  };
}
