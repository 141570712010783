import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Button, FileDisplay, DisplayValue, Rule, Title} from '@shipwell/shipwell-ui';
import {useViewZpl} from './useViewZpl';
import {downloadZplDocument, printZplDocument} from 'App/components/DocumentView/utils';
import {formatDateTime} from 'App/utils/globals';
import './styles.scss';
import ShipwellLoader from 'App/common/shipwellLoader';
import classNames from 'classnames';

export function printDocument(doc, fileType = 'image') {
  // zpl could also be zplii
  if (fileType?.includes('zpl')) {
    return printZplDocument(doc.file);
  }

  const mywindow = window.open('', 'PRINT', 'height=400px,width=600px');
  const head = mywindow.document.getElementsByTagName('head')[0];
  const title = mywindow.document.createElement('title');
  title.innerText = doc.filename || doc.name || '';
  head.appendChild(title);

  const body = mywindow.document.getElementsByTagName('body')[0];
  switch (fileType) {
    case 'image': {
      const imageElement = mywindow.document.createElement('img');
      imageElement.addEventListener('load', () => {
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      });
      imageElement.style.height = '100%';
      imageElement.style.width = '100%';
      imageElement.src = get(doc, 'file');
      body.appendChild(imageElement);
      break;
    }
    default: {
      const documentElement = mywindow.document.createElement('iframe');
      documentElement.addEventListener('load', () => {
        mywindow.document.close(); // necessary for IE >= 10
        documentElement.focus();
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      });
      documentElement.height = '100%';
      documentElement.width = '100%';
      documentElement.src = get(doc, 'file');
      body.appendChild(documentElement);
      break;
    }
  }
}

export const documentPropType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  file: PropTypes.string,
  filename: PropTypes.string
});

export const DocumentMetadata = ({children, document, documentType}) => {
  const description = get(document, 'description');
  const createdAt = get(document, 'created_at');
  const updatedAt = get(document, 'updated_at');
  return (
    <div className="w-80 px-4 py-0">
      <Title variant="sectionTitle">Details</Title>
      {documentType ? <DisplayValue label="Type">{documentType}</DisplayValue> : null}
      {description ? <DisplayValue label="Description">{description}</DisplayValue> : null}
      {createdAt ? <DisplayValue label="Created">{formatDateTime(createdAt)}</DisplayValue> : null}
      {updatedAt ? <DisplayValue label="Updated">{formatDateTime(updatedAt)}</DisplayValue> : null}
      {children}
    </div>
  );
};

DocumentMetadata.propTypes = {
  children: PropTypes.node,
  document: documentPropType,
  documentType: PropTypes.string
};

export const DocumentAuditLog = ({auditLogEmails}) => (
  <div className="w-80 p-4">
    <Title variant="sectionTitle">Audit Log</Title>
    {auditLogEmails.map((email, i) => {
      const updatedAt = get(email, 'updated_at');
      const status = get(email, 'status');
      const replyToEmail = get(email, 'replyto_email');
      const toEmail = get(email, 'to_email');
      return (
        <div key={i}>
          {i > 0 ? <Rule /> : null}
          {updatedAt ? <DisplayValue label="Date">{formatDateTime(updatedAt)}</DisplayValue> : null}
          {status ? <DisplayValue label="Status">{status}</DisplayValue> : null}
          {replyToEmail ? <DisplayValue label="From">{replyToEmail}</DisplayValue> : null}
          {toEmail ? <DisplayValue label="To">{toEmail}</DisplayValue> : null}
        </div>
      );
    })}
  </div>
);

DocumentAuditLog.propTypes = {
  auditLogEmails: PropTypes.arrayOf(
    PropTypes.shape({
      updated_at: PropTypes.string,
      status: PropTypes.string,
      replyto_email: PropTypes.string,
      to_email: PropTypes.string
    })
  )
};

export const DocumentDisplay = ({document, fileType, emptyText = '', shouldRotateZplFile = true}) => {
  const isZpl = fileType?.includes('zpl');
  const generateZplImageQuery = useViewZpl({document, isEnabled: isZpl});
  const zplImage = generateZplImageQuery?.data;
  const zplStatus = generateZplImageQuery?.status;

  return (
    <div className="grow">
      {isZpl ? (
        zplStatus === 'loading' ? (
          <ShipwellLoader loading />
        ) : zplStatus === 'error' ? (
          <span>There was an error loading your ZPL label.</span>
        ) : (
          <img
            className={classNames('mx-auto h-full', {
              'rotate-180': shouldRotateZplFile
            })}
            src={zplImage}
            alt="ZPL label"
          />
        )
      ) : fileType === 'html' ? (
        <iframe className="size-full border-0" src={get(document, 'file')} />
      ) : (
        <FileDisplay
          fileURL={get(document, 'file')}
          fileName={get(document, 'filename')}
          altText={get(document, 'description')}
          emptyText={emptyText}
          enableToolbar
        />
      )}
    </div>
  );
};

DocumentDisplay.propTypes = {
  document: documentPropType,
  fileType: PropTypes.oneOf(['image', 'html', 'pdf', 'zpl']),
  emptyText: PropTypes.string,
  shouldRotateZplFile: PropTypes.bool
};

export const DocumentActions = ({
  document,
  deleteDisableTitle = '',
  onDeleteDocument,
  onPrintDocument,
  onEmailDocument,
  fileType,
  showPrintDocument = true
}) => {
  const showPrintButton = showPrintDocument && fileType !== 'pdf' && onPrintDocument;
  const isZplFile = fileType?.includes('zpl');

  const handleDownloadZpl = () => {
    if (!isZplFile) {
      return;
    }
    downloadZplDocument(document.file, document.filename);
  };

  return (
    <div className="documentView-footer absolute bottom-0 left-0 flex w-full items-center justify-between bg-sw-background-component p-6 ">
      <div>
        {onDeleteDocument ? (
          <Button
            disabled={deleteDisableTitle !== ''}
            color="warning"
            variant="tertiary"
            onClick={() => onDeleteDocument(document)}
          >
            Delete Document
          </Button>
        ) : null}
      </div>
      <div className="documentView-footer__primary flex justify-end">
        {isZplFile ? (
          <Button variant="secondary" onClick={handleDownloadZpl}>
            Download
          </Button>
        ) : null}
        {showPrintButton ? ( // pdf files are printed from the toolbar
          <Button variant="secondary" onClick={() => onPrintDocument(document, fileType)}>
            Print
          </Button>
        ) : (
          // but we need some kind of content here if there aren't any delete or email actions so the
          // footer displays correctly when empty
          <>&nbsp;</>
        )}
        {onEmailDocument ? <Button onClick={() => onEmailDocument(document)}>Email</Button> : null}
      </div>
    </div>
  );
};

DocumentActions.propTypes = {
  document: documentPropType,
  onPrintDocument: PropTypes.func,
  onEmailDocument: PropTypes.func,
  isDeleteDisabled: PropTypes.bool,
  deleteDisableTitle: PropTypes.string,
  onDeleteDocument: PropTypes.func,
  fileType: PropTypes.oneOf(['image', 'html', 'pdf', 'zpl']),
  showPrintDocument: PropTypes.bool
};

DocumentActions.defaultProps = {
  onPrintDocument: printDocument
};

export const DocumentViewLayout = ({children}) => <div className="documentView flex h-full">{children}</div>;

DocumentViewLayout.propTypes = {
  children: PropTypes.node
};

const DocumentView = ({document, fileType}) => {
  return (
    <>
      <DocumentViewLayout>
        <DocumentMetadata document={document} />
        <DocumentDisplay document={document} fileType={fileType} />
      </DocumentViewLayout>
      <DocumentActions
        document={document}
        fileType={fileType}
        onPrintDocument={() => printDocument(document, fileType)}
      />
    </>
  );
};

DocumentView.propTypes = {
  document: documentPropType,
  fileType: PropTypes.oneOf(['image', 'html', 'pdf', 'zpl'])
};

export default DocumentView;
