export const formatDeviceBreadcrumbsData = (city?: string, state?: string, time?: string, timezone?: string) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);

  const updateTime = time ? formatter.format(new Date(time)) : null;
  const cityState = city && state ? `${city}, ${state}` : null;

  return {
    cityState,
    timezone: timezone || null,
    updateTime
  };
};
