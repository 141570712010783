import {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {ControlLabel, Button, Form, FormGroup, Pagination} from 'react-bootstrap';
import Async from 'react-select/async';
import ReactTable from 'react-table-6';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import {StopsItineraryCell, StopsItineraryOverlay} from 'App/components/stopEta';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import {fetchBrokerShipperRelationshipsPromise} from 'App/actions/brokers';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {bind} from 'App/utils/camelize';
import columns from 'App/containers/Dashboard/components/Columns';
import 'react-table-6/react-table.css';
import './styles.scss';
import {checkShipmentModes} from 'App/utils/globalsTyped';

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const columnSortingMapping = {
  equipment_type: 'equipment'
};

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    shipperRelationships: state.brokers.shipperRelationshipsForHeader,
    selectedShipment: state.shipments.selectedShipment,
    shipments: state.shipments.all,
    isLoading: state.shipments.isLoading,
    hazmatCodes: state.shipments.hazmatCodes
  }),
  {...actions, ...brokerActions, ...shipmentDetailsActions}
)
class RecentShipments extends Component {
  constructor(props) {
    super(props);

    bind(this, [
      'getShipments',
      'showRowsPerPage',
      'renderPagination',
      'sortCategory',
      'cloneShipment',
      'handleSelectPage',
      'getCustomers',
      'handlePageChange',
      'handlePageSizeChange',
      'handleSortedChange'
    ]);
    columns.reference_id.sortable = false;
    this.tableColumns = [
      //majority of these columns live in Dashboard/components/columns/index.js
      columns.reference_id,
      //origin column uses state and props
      {
        Header: 'Origin',
        id: 'origin',
        minWidth: 125,
        accessor: (d) => d.stops,
        sortable: false,
        Cell: (row) => {
          let pickUpStop;
          const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);
          for (let i = 0; i < sortedStops.length; i++) {
            if (sortedStops[i].is_pickup && !pickUpStop) {
              pickUpStop = sortedStops[i];
            }
          }
          return pickUpStop
            ? `${pickUpStop.location?.address?.city},  ${pickUpStop.location?.address?.state_province}`
            : '';
        }
      },
      //destination column uses state and props
      {
        Header: 'Destination',
        id: 'destination',
        minWidth: 125,
        accessor: (d) => d.stops,
        sortable: false,
        Cell: (row) => {
          let dropOffStop;
          const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);
          for (let i = 0; i < sortedStops.length; i++) {
            if (sortedStops[i].is_dropoff) {
              dropOffStop = sortedStops[i];
            }
          }
          return dropOffStop
            ? `${dropOffStop.location?.address?.city},  ${dropOffStop.location?.address?.state_province}`
            : '';
        }
      },
      //stops
      {
        Header: 'Stops',
        id: 'stops',
        accessor: (d) => d.stops,
        sortable: false,
        minWidth: 75,
        Cell: (row) => {
          const countPick = row?.value?.filter((s) => s.is_pickup).length;
          const countDrop = row?.value?.filter((s) => s.is_dropoff).length;
          const {hasLTL} = checkShipmentModes(row?.original?.mode);
          return (
            <StopsItineraryOverlay stops={row.value} isLoadBoard={false} hasLTL={hasLTL}>
              <StopsItineraryCell countPick={countPick} countDrop={countDrop} />
            </StopsItineraryOverlay>
          );
        }
      },
      //clone button
      {
        Header: '',
        id: 'clone',
        accessor: (d) => d.clone,
        sortable: false,
        minWidth: 75,
        Cell: (row) => {
          return (
            <Button
              bsStyle="primary"
              onClick={() => {
                this.cloneShipment(row.original);
              }}
            >
              Clone
            </Button>
          );
        }
      }
    ];

    this.state = {
      isBroker: false,
      showCloneModal: false,
      pagination: {
        page: 1,
        pageSize: 10,
        ordering: ['-pickup']
      },
      customerPagination: {
        page: 1,
        pageSize: 25,
        ordering: 'company',
        q: ''
      },
      sortAscending: true,
      selectedShipper: null,
      cloneError: false
    };
  }

  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('customer_relationships.view')
    ) {
      //then this is a broker
      this.setState({isBroker: true}, () => {
        if (this.state.isBroker || this.props.is_quoting_limited_user) {
          this.tableColumns.splice(1, 0, {
            Header: () => (
              <span>
                Customer
                <span className="btn-sort" />
              </span>
            ),
            id: 'customer',
            sortable: false,
            minWidth: 150,
            accessor: (d) => d.customer,
            Cell: (row) => {
              return this.props.is_quoting_limited_user
                ? row.original.relationship_to_customer && row.original.relationship_to_customer.customer.name
                : row.value.name;
            }
          });
        }
      });
    }
    if (this.props.company && this.props.company.id) {
      this.getShipments(this.props.company.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.user &&
      nextProps.user.permissions &&
      nextProps.user.permissions !== this.props.user.permissions &&
      nextProps.user.permissions.includes('customer_relationships.view')
    ) {
      //then this is a broker
      this.setState({isBroker: true}, () => {
        if (this.state.isBroker || this.props.is_quoting_limited_user) {
          this.tableColumns.splice(1, 0, {
            Header: () => (
              <span>
                Customer
                <span className="btn-sort" />
              </span>
            ),
            id: 'customer',
            sortable: false,
            minWidth: 150,
            accessor: (d) => d.customer,
            Cell: (row) => {
              return this.props.is_quoting_limited_user
                ? row.original.relationship_to_customer && row.original.relationship_to_customer.customer.name
                : row.value.name;
            }
          });
        }
      });
    }
    if (nextProps.company && this.props.company !== nextProps.company) {
      this.getShipments(nextProps.company.id);
    }
    if (nextProps.cloning === false && this.props.cloning === true) {
      this.setState({showCloneModal: false});
    }
  }

  getShipments() {
    this.props.getShipments(this.state.pagination);
  }

  sortCategory(e) {
    e.preventDefault();
    const category = e.target.value;
    const orderBy = this.state.sortAscending ? category : `-${category}`;
    const pagination = {...this.state.pagination, page: 1, ordering: orderBy};
    // set pagination and toggle sortAscending
    this.setState(
      {
        pagination: {...pagination},
        sortAscending: !this.state.sortAscending
      },
      () => {
        this.getShipments(this.props.company.id);
      }
    );
  }

  handleTypeaheadChange(shipper) {
    const pagination = {...this.state.pagination, page: 1};
    //get shipper id
    if (shipper) {
      pagination.customerId = shipper.value;
      this.setState(
        {
          selectedShipper: shipper,
          pagination: pagination
        },
        () => {
          this.getShipments();
        }
      );
    } else {
      delete pagination.customerId;
      this.setState(
        {
          selectedShipper: null,
          pagination: pagination
        },
        () => {
          this.getShipments();
        }
      );
    }
  }
  handlePageChange(pageIndex) {
    const pagination = {...this.state.pagination, page: pageIndex + 1};
    this.setState({pagination}, () => {
      this.getShipments(this.props.company.id);
    });
    document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
  }
  handlePageSizeChange(pageSize, pageIndex) {
    const pagination = {...this.state.pagination, pageSize: pageSize, page: 1};
    this.setState({pagination}, () => {
      this.getShipments(this.props.company.id);
    });
  }
  handleSortedChange(newSorted, column, shiftKey) {
    const pagination = {...this.state.pagination};
    if (newSorted && newSorted.length) {
      //some parsing is needed when column id's don't match the exact model name the backend requires
      const sortValue = this.parseColumnSorting(JSON.parse(JSON.stringify(newSorted[0].id)), newSorted[0].desc);
      //we only allow one sort at a time
      pagination.ordering = [sortValue];
    }
    this.setState({pagination}, () => {
      this.getShipments(this.props.company.id);
    });
  }

  getCustomers(input) {
    const pagination = this.state.customerPagination;
    if (input) {
      pagination.q = input;
      pagination.pageSize = null;
    }
    return fetchBrokerShipperRelationshipsPromise(this.props.company.brokerage.id, pagination)
      .then((response) => {
        if (response && response.results) {
          const options = response.results.map((result) => ({
            value: _.get(result, 'company.id'),
            label: _.get(result, 'company.name')
          }));
          return {options: options};
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async cloneShipment(shipment) {
    try {
      //re-fetch the full shipment details before proceeding
      const response = await this.props.getShipmentDetails(shipment.id);
      if (response?.status === 200) {
        const shipmentToClone = JSON.parse(JSON.stringify(response.details));
        shipmentToClone.line_items = shipmentToClone.line_items.map((li) => ({...li, purchase_order: null}));
        shipmentToClone.delayed = false;

        shipmentToClone.stops = shipmentToClone.stops.map((stop) => {
          return {
            ...stop,
            unconfirmed_arrival_at: null,
            unconfirmed_departure_at: null,
            confirmed_arrival_at: null,
            confirmed_departure_at: null,
            trip_management_eta: null,
            trip_managent_eta_last_updated: null,
            status: null
          };
        });
        this.props.onSelectShipment(shipmentToClone);
      }
    } catch (error) {
      this.setState({cloneError: true});
      console.error('Error cloning shipment. ', error);
    }
  }

  render() {
    let shippers = [];
    if (this.props.shipperRelationships && this.props.shipperRelationships.results) {
      shippers = this.props.shipperRelationships.results.sort(function (a, b) {
        var nameA = a.company.name.toUpperCase();
        var nameB = b.company.name.toUpperCase();
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        return 0;
      });
    }

    return (
      <div>
        {this.props.cloning === true ? (
          <div className="text-center">
            <h3>Cloning...</h3>
            <ShipwellLoader loading={this.props.cloning} />{' '}
          </div>
        ) : (
          <div className="quotingHeader__cloneTable">
            <Form inline>
              <FormGroup className="customerFilter">
                <ControlLabel>Filter by Customer: &nbsp;</ControlLabel>
                <Async
                  classNamePrefix={'sw-react-select'}
                  multi={false}
                  onChange={(value) => {
                    this.handleTypeaheadChange(value);
                  }}
                  value={this.state.selectedShipper}
                  placeholder="Search for Customer"
                  loadOptions={this.getCustomers}
                  filterOptions={(options, filter, currentValues) => {
                    // Do no filtering, just return all options
                    return options;
                  }}
                />
              </FormGroup>
            </Form>

            <ReactTableFixedColumns
              showPagination
              data={this.props.shipments ? this.props.shipments.results : []}
              pages={this.props.shipments ? this.props.shipments.total_pages : 1}
              loading={this.props.isLoading}
              sortable={false}
              manual
              defaultPageSize={
                localStorage.getItem('dashboardPageSize')
                  ? Number(localStorage.getItem('dashboardPageSize'))
                  : this.state.pagination.pageSize
              }
              page={this.state.pagination.page - 1}
              pageSize={this.state.pagination.pageSize}
              onPageChange={(pageIndex) => {
                this.handlePageChange(pageIndex);
              }}
              onPageSizeChange={(pageSize, pageIndex) => {
                this.handlePageSizeChange(pageSize, pageIndex);
              }}
              onSortedChange={(newSorted, column, shiftKey) => {
                this.handleSortedChange(newSorted, column, shiftKey);
              }}
              sorted={this.state.pagination.ordering.map((e) => {
                //parse when columns dont match e.g., equipment_type
                if (columnSortingMapping[e] || columnSortingMapping[e.slice(1)]) {
                  return e.startsWith('-')
                    ? {id: columnSortingMapping[e.slice(1)], desc: true}
                    : {id: columnSortingMapping[e], desc: false};
                }

                return e.startsWith('-') ? {id: e.slice(1), desc: true} : {id: e, desc: false};
              })}
              minRows={10}
              pageSizeOptions={[10, 25, 50, 100]}
              defaultSorted={
                localStorage.getItem('dashboardSort')
                  ? JSON.parse(localStorage.getItem('dashboardSort'))
                  : [
                      {
                        id: 'pickup',
                        desc: true
                      }
                    ]
              }
              id="shipmentsTable"
              getTrGroupProps={(state, rowInfo, column, instance) => {
                if (!rowInfo) {
                  return {
                    className: 'no-content'
                  };
                }
                return '';
              }}
              getTheadFilterThProps={() => {
                return {
                  style: {
                    overflow: 'inherit'
                  }
                };
              }}
              //onFetchData={this.fetchData} removed because this is done manually when page changes
              columns={this.tableColumns}
              className="-highlight -striped"
              previousText={<i className="icon icon-Left" />}
              nextText={<i className="icon icon-Right" />}
              rowsText=""
              noDataText={this.props.isLoading ? '' : 'No Shipments'}
              LoadingComponent={ShipwellLoader}
              ref={(ref) => (this._reacttable = ref)}
            />
          </div>
        )}
        {this.state.cloneError && (
          <p className="text-error">
            <i className="icon icon-Delayed pad-right" /> There was an error cloning this shipment.
          </p>
        )}
      </div>
    );
  }

  showRowsPerPage(e) {
    e.preventDefault();
    const pagination = {...this.state.pagination, pageSize: e.target.value, page: 1};
    localStorage.setItem('dashboardPagination', e.target.value.toString());

    this.setState({pagination}, () => {
      this.getShipments(this.props.company.id);
    });
  }

  renderPagination() {
    const pages = this.props.shipments
      ? Math.ceil(this.props.shipments.total_count / this.state.pagination.pageSize)
      : 1;

    return (
      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        items={pages}
        maxButtons={5}
        activePage={this.state.pagination.page}
        onSelect={this.handleSelectPage}
        className={this.state.searchValue ? 'disabled no-margin' : 'no-margin'}
      />
    );
  }

  handleSelectPage(pageBtn) {
    const pagination = {...this.state.pagination, page: pageBtn};

    this.setState({pagination}, () => {
      this.getShipments(this.props.company.id);
    });
  }
}

export default RecentShipments;
