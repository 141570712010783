import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AddressBookEntry, ShipmentPreferredCurrencyEnum} from '@shipwell/backend-core-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Formik, Form, Field, FormikHelpers} from 'formik';
import {Uuid} from 'id128';
import {lazy} from 'yup';
import moment from 'moment';
import {
  AssociateServiceToShipmentRequest,
  Storage,
  StorageServiceTypeEnum,
  LegacyShipmentStageStageTypeEnum,
  LocationType,
  OceanTrackingServiceTypeEnum,
  RailTrackingServiceTypeEnum,
  Stage,
  TerminalFee,
  TerminalFeeServiceTypeEnum,
  Transload,
  TransloadServiceTypeEnum
} from '@shipwell/corrogo-sdk';
import {Checkbox, FormikSelect, Loader as FullPageLoader} from '@shipwell/shipwell-ui';
import {withRouter, WithRouterProps} from 'react-router';
import {compose} from 'recompose';
import {useShipmentStagesQuery} from './hooks/useShipmentStagesQuery';
import {
  STAGE_SELECT_OPTIONS,
  LEGACY_SHIPMENT_STAGE_VALIDATION_SCHEMA,
  SERVICE_STAGE_VALIDATION_SCHEMA,
  NewStageFormValues,
  isServiceStageType,
  isLegacyStopLocation,
  resourceStageIsLegacyShipmentStage,
  isLegacyShipmentStage,
  serviceProviderIsCorrogoServiceProvider,
  getResourceServiceStageDataIfServiceStage,
  shouldPrepopulateEquipmentTypeField,
  LEGACY_SHIPMENT_MODES,
  StageType,
  formatLegacyStopDateTime,
  getPrefilledLegacyStopLocation,
  isCurrencyCode
} from 'App/containers/shipments/components/DashboardSummary/utils';
import {
  mapAddressLookupFieldsToAddress,
  getFormattedAddressFromAddressFields,
  mapAddressToAddressLookupFields
} from 'App/utils/corrogo/mappers';
import LegacyShipmentStageFields from 'App/containers/shipments/components/DashboardSummary/LegacyShipmentStageFields';
import {ConditionalLegacyDrayageShipmentStageFields} from 'App/containers/shipments/components/DashboardSummary/LegacyDrayageShipmentStageFields';
import ServiceStageFields from 'App/containers/shipments/components/DashboardSummary/ServiceStageFields';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {createShipment, assignServiceToShipment, createLegacyShipmentStage} from 'App/api/corrogo/typed';
import {useModesQuery} from 'App/data-hooks';
import {createShipment as createLegacyShipment} from 'App/api/shipment/typed';
import Loader from 'App/common/shipwellLoader';
import {getStageByResourceId} from 'App/containers/shipments/utils/typed';
import {
  RESOURCE_STAGES_QUERY_KEY,
  SERVICES_QUERY_KEY,
  SHIPMENTS_STAGES_QUERY_KEY,
  ADDRESS_BOOK_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import useToggle from 'App/utils/hooks/useToggle';
import {addUtcOffsetToDate} from 'App/utils/dateTimeGlobalsTyped';
import useLegacyShipment from 'App/data-hooks/shipments/useLegacyShipment';
import {getAddressBookAddressById} from 'App/api/addressBook/typed';
import {getInvisibleDataFromLegacyShipment} from 'App/containers/shipments/components/DashboardSummary/invisibleDataMigrationUtils';
import {useRelatedStages} from 'App/containers/shipments/components/DashboardSummary/StagesView';
import {
  useRetrieveService,
  useServiceCreation,
  useUpdateService
} from 'App/containers/shipments/components/DashboardSummary/hooks/useServices';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {
  ADD_NEW_STAGE_FORM_HEADER_TEXT,
  CLONE_STAGE_FORM_HEADER_TEXT,
  GENERIC_ERROR_MESSAGE,
  GENERIC_UPDATE_ERROR_MESSAGE
} from 'App/containers/shipments/components/DashboardSummary/constants';
import {ConditionalTruncatedLegacyShipmentLineItemsFields} from 'App/containers/shipments/components/DashboardSummary/truncatedLegacyShipmentLineItemFields';
import {NewStageCurrencyOfRecordField} from 'App/containers/shipments/components/DashboardSummary/NewStageCurrencyOfRecordField';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';

// hook to get the closest related legacy shipment to the current stage
// if your chain looks like FTL -> Transload -> Terminal Fee (current), it would return the FTL stage at the beginning of the chain.
const useGetClosestRelatedLegacyShipment = (resourceId: string, shipmentId?: string) => {
  const relatedStages = useRelatedStages(shipmentId, resourceId);

  // we create a copy and reverse the list here since the list is backwards.
  // the last element of the list is the closest in relation
  const mostRecentLegacyShipmentStage = relatedStages
    .slice()
    .reverse()
    .find((stage) => stage.stage_type === LegacyShipmentStageStageTypeEnum.LegacyShipment);
  const isLegacyShipmentStage = resourceStageIsLegacyShipmentStage(mostRecentLegacyShipmentStage);
  // once we have the related legacy shipment stage, query to get the full shipment for data copying purposes
  const {shipmentQuery: mostRecentLegacyShipmentQuery} = useLegacyShipment(
    mostRecentLegacyShipmentStage && isLegacyShipmentStage ? mostRecentLegacyShipmentStage.legacy_shipment_id : '',
    {
      enabled: !!(isLegacyShipmentStage && mostRecentLegacyShipmentStage?.legacy_shipment_id)
    }
  );
  return mostRecentLegacyShipmentQuery.data;
};

const createDefaultFormValues = ({
  resourceId,
  legacyShipment,
  resourceStage,
  service,
  serviceLocation,
  isCloningContainer = false,
  companyDefaultCurrency
}: {
  resourceId: string;
  legacyShipment?: Shipment;
  resourceStage?: Stage;
  service?: Transload | TerminalFee | Storage;
  serviceLocation?: AddressBookEntry;
  isCloningContainer: boolean;
  companyDefaultCurrency: ShipmentPreferredCurrencyEnum;
}): NewStageFormValues => {
  if (isCloningContainer) {
    const legacyShipmentPickupStop = legacyShipment?.stops?.sort(
      (stopOne, stopTwo) => stopOne.ordinal_index - stopTwo.ordinal_index
    )[0];
    const legacyShipmentDeliveryStop = legacyShipment?.stops?.sort(
      (stopOne, stopTwo) => stopTwo.ordinal_index - stopOne.ordinal_index
    )[0];

    const prefilledPickupDateTime = formatLegacyStopDateTime(legacyShipmentPickupStop);
    const prefilledDeliveryDateTime = formatLegacyStopDateTime(legacyShipmentDeliveryStop);
    const prefilledPickupLocation = getPrefilledLegacyStopLocation(legacyShipmentPickupStop);
    const prefilledDeliveryLocation = getPrefilledLegacyStopLocation(legacyShipmentDeliveryStop);
    return {
      stage_type: legacyShipment?.mode?.description as StageType,
      start_date: prefilledPickupDateTime,
      end_date: null,
      location: null,
      service_provider: null,
      provider_rate: null,
      customer_rate: null,
      equipment: legacyShipment?.equipment_type ? legacyShipment?.equipment_type : null,
      pickup_location: prefilledPickupLocation,
      pickup_date: prefilledPickupDateTime,
      delivery_location: prefilledDeliveryLocation,
      delivery_date: prefilledDeliveryDateTime,
      line_items: legacyShipment?.line_items,
      preferred_currency: legacyShipment?.preferred_currency || companyDefaultCurrency
    };
  }
  if (service && service.service_type) {
    const startDate = service.actual_datetimes?.start ? new Date(service.actual_datetimes?.start).toISOString() : null;
    const endDate = service.actual_datetimes?.end ? new Date(service.actual_datetimes?.end).toISOString() : null;
    const serviceStageFormattedAddress = getFormattedAddressFromAddressFields(service?.location?.address);

    const ratesCurrency = service.provider_rate?.currency_code || service.customer_rate?.currency_code;

    return {
      stage_type: service.service_type,
      start_date: startDate,
      end_date: endDate,
      location: {
        ...(serviceLocation ?? {}),
        ...mapAddressToAddressLookupFields(service.location),
        ...serviceStageFormattedAddress
      },
      service_provider: service.service_provider,
      provider_rate: service.provider_rate?.value || null,
      customer_rate: service.customer_rate?.value || null,
      equipment: null,
      pickup_location: null,
      pickup_date: null,
      delivery_location: null,
      delivery_date: null,
      preferred_currency: isCurrencyCode(ratesCurrency)
        ? ratesCurrency
        : legacyShipment?.preferred_currency || companyDefaultCurrency
    };
  }

  // sort so that the last stop by ordinal index is first. we can use that to pre-fill the location and pickup date
  const sortedLegacyShipmentStops = isLegacyShipmentStage(resourceId)
    ? legacyShipment?.stops?.sort((stopOne, stopTwo) => stopTwo.ordinal_index - stopOne.ordinal_index)
    : null;
  const prepopulateEquipmentTypeField = shouldPrepopulateEquipmentTypeField(resourceId, legacyShipment);
  const serviceStageData = getResourceServiceStageDataIfServiceStage(resourceId, resourceStage);
  const serviceStageFormattedAddress = getFormattedAddressFromAddressFields(serviceStageData?.location?.address);
  const previousStageEndParsedDate = serviceStageData?.actual_datetimes?.end
    ? new Date(serviceStageData?.actual_datetimes?.end).toISOString()
    : null;
  const prefilledStopDateTime =
    sortedLegacyShipmentStops && sortedLegacyShipmentStops.length
      ? formatLegacyStopDateTime(sortedLegacyShipmentStops[0])
      : //if the user is creating a stage from a service stage, and that stage has requisite data..
      //to prefill the stop/date time.
      serviceStageData
      ? previousStageEndParsedDate
      : null;

  const prefilledLocation =
    sortedLegacyShipmentStops && sortedLegacyShipmentStops.length
      ? getPrefilledLegacyStopLocation(sortedLegacyShipmentStops[0])
      : serviceStageData
      ? {
          ...(serviceLocation ?? {}),
          ...mapAddressToAddressLookupFields(serviceStageData.location),
          ...serviceStageFormattedAddress
        }
      : null;
  return {
    stage_type: null,
    start_date: prefilledStopDateTime,
    end_date: null,
    location: prefilledLocation,
    service_provider: null,
    provider_rate: null,
    customer_rate: null,
    equipment: prepopulateEquipmentTypeField && legacyShipment?.equipment_type ? legacyShipment?.equipment_type : null,
    pickup_location: prefilledLocation,
    pickup_date: prefilledStopDateTime,
    delivery_location: null,
    delivery_date: null,
    preferred_currency: legacyShipment?.preferred_currency || companyDefaultCurrency
  };
};

interface NewStageFormProps extends WithRouterProps {
  shipmentId?: string;
  resourceId: string;
  onClose: (shouldAddAnotherStage?: boolean, previousResourceId?: string) => void;
  serviceType: // if this property is passed, the edit version of this form shall be used.
  | TransloadServiceTypeEnum
    | TerminalFeeServiceTypeEnum
    | StorageServiceTypeEnum
    | OceanTrackingServiceTypeEnum
    | RailTrackingServiceTypeEnum
    | undefined;
  handleSuccess: (title: string, message: string) => void;
  handleError: (title: string, message: string) => void;
  //This is a one-off workflow to allow for easily creating multiple drayage containers
  //to a v3 shipment drayage stages, as legacy drayage shipments.
  //Drayage stage cloning, and the associated 'container view' in the stages view,
  //is not supported for other modes or workflows.
  isCloningContainer: boolean;
}

function NewStageForm({
  shipmentId,
  resourceId,
  onClose,
  serviceType,
  handleSuccess,
  handleError,
  isCloningContainer = false
}: NewStageFormProps) {
  const queryClient = useQueryClient();
  const internationalPreferencesQuery = useGetInternationalPreferences();

  // we only want to enable this query when a uuid is passed, which represents a v2 shipment.
  const {shipmentQuery} = useLegacyShipment(resourceId, {enabled: Uuid.isCanonical(resourceId)});
  const serviceQuery = useRetrieveService(serviceType, resourceId);

  const getCreationMutation = useServiceCreation();
  const getUpdateServiceMutation = useUpdateService();
  const createShipmentMutation = useMutation(createShipment);
  const createLegacyShipmentMutation = useMutation(
    ({shipment, xCompanyId}: {shipment: Shipment; xCompanyId?: string}) => createLegacyShipment(shipment, xCompanyId)
  );
  const shipmentModesQuery = useModesQuery();
  const [showFullPageLoader, toggleShowFullPageLoader] = useToggle();
  const [shouldAddAnotherStage, toggleShouldAddAnotherStage] = useToggle();
  const createLegacyShipmentStageMutation = useMutation(
    ({
      shipmentId,
      payload
    }: {
      shipmentId: string;
      payload: {legacy_shipment_id: string; directly_depends_on_stages: string[]};
    }) => createLegacyShipmentStage(shipmentId, payload)
  );
  const assignServiceToShipmentMutation = useMutation(
    ({
      shipmentId,
      serviceId,
      payload
    }: {
      shipmentId: string;
      serviceId: string;
      payload: AssociateServiceToShipmentRequest;
    }) => assignServiceToShipment(shipmentId, serviceId, payload)
  );

  const shipmentStagesQuery = useShipmentStagesQuery(shipmentId || '');
  // if the stage we are building off of is a service
  // then we need to use the `system_id` on the service location
  // to access its address book entry which will give us supplemental
  // location information for data transfer
  const resourceStage = getStageByResourceId(resourceId, shipmentStagesQuery.data);
  const serviceStageData = getResourceServiceStageDataIfServiceStage(resourceId, resourceStage);
  const serviceLocationId = serviceStageData?.location.system_id;
  const serviceLocationQuery = useQuery(
    [ADDRESS_BOOK_QUERY_KEY, serviceLocationId],
    async () => {
      if (!serviceLocationId) {
        return null;
      }
      const response = await getAddressBookAddressById(serviceLocationId);
      return response.data;
    },
    {
      enabled: !!serviceLocationId,
      retry: 0
    }
  );

  const closestRelatedLegacyShipment = useGetClosestRelatedLegacyShipment(resourceId, shipmentId);

  if (
    internationalPreferencesQuery.isLoading ||
    shipmentModesQuery.isInitialLoading ||
    shipmentStagesQuery.isInitialLoading ||
    (shipmentQuery.isLoading && shipmentQuery.isFetching) ||
    serviceLocationQuery.isInitialLoading
  ) {
    return <Loader loading />;
  }

  const handleLegacyShipmentSubmission = async (values: NewStageFormValues) => {
    if (!shipmentModesQuery.data || values.pickup_location === null || values.delivery_location === null) {
      return;
    }

    // if this stage is created off of a legacy shipment, copy invisible data from that.
    // if the stage is created off a service, use the closest legacy shipment in the chain to copy invisible data from.
    // if there is no legacy shipment in the chain, do not copy invisible data.
    const dataToCopy = shipmentQuery.data
      ? getInvisibleDataFromLegacyShipment(shipmentQuery.data, values.stage_type, isCloningContainer)
      : closestRelatedLegacyShipment
      ? getInvisibleDataFromLegacyShipment(closestRelatedLegacyShipment, values.stage_type)
      : {};

    const isValidPickupDateTime = values?.pickup_date && moment(values.pickup_date).isValid();
    const isValidDropoffDateTime = values?.delivery_date && moment(values.delivery_date).isValid();
    const pickupDateWithUtcOffsetSubtracted = isValidPickupDateTime
      ? addUtcOffsetToDate(values?.pickup_date || '')
      : null;
    const deliveryDateWithUtcOffsetSubtracted = isValidDropoffDateTime
      ? addUtcOffsetToDate(values?.delivery_date || '')
      : null;
    const [plannedPickupDate, plannedPickupWindowStartTime] =
      pickupDateWithUtcOffsetSubtracted?.toISOString().split('T') || '';
    const [plannedDeliveryDate, plannedDeliveryWindowStartTime] =
      deliveryDateWithUtcOffsetSubtracted?.toISOString().split('T') || '';

    const drayageNewContainerValues = isCloningContainer
      ? {
          drayage_container_number: values.drayage_container_number,
          drayage_seal_number: values.drayage_seal_number,
          drayage_chassis_number: values.drayage_chassis_number
        }
      : {};

    const shipmentPayload = {
      ...dataToCopy.topLevelFields,
      ...drayageNewContainerValues,
      mode: shipmentModesQuery.data.find(
        (shipmentMode) => shipmentMode.code === (values.stage_type || '').toUpperCase()
      ),
      equipment_type: values.equipment,
      metadata: {tags: [], ...dataToCopy.metadata, archived: false, open: true},
      stops: [
        {
          ...dataToCopy.pickupStop,
          ordinal_index: 0,
          is_pickup: true,
          planned_date: plannedPickupDate,
          planned_time_window_start: plannedPickupWindowStartTime,
          location: {
            ...dataToCopy.pickupStopLocation,
            // for non-address book locations, we need to submit this with a location wrapper since we're only provided with address data
            ...(isLegacyStopLocation(values.pickup_location)
              ? {...values.pickup_location, created_using_address_book_entry_id: values.pickup_location?.id}
              : {address: values.pickup_location})
          }
        },
        {
          ...dataToCopy.deliveryStop,
          ordinal_index: 1,
          is_dropoff: true,
          planned_date: plannedDeliveryDate,
          planned_time_window_start: plannedDeliveryWindowStartTime,
          location: {
            ...dataToCopy.deliveryStopLocation,
            ...(isLegacyStopLocation(values.delivery_location)
              ? {...values.delivery_location, created_using_address_book_entry_id: values.delivery_location?.id}
              : {address: values.delivery_location})
          }
        }
      ],
      // For the clone container flow, use the line item form values. Otherwise, use the line items from
      //the invisible data migration, as they are otherwise not set.
      line_items: isCloningContainer ? values.line_items : dataToCopy.topLevelFields?.line_items,
      preferred_currency: values.preferred_currency
    };
    const response = await createLegacyShipmentMutation.mutateAsync(
      {
        shipment: shipmentPayload as unknown as Shipment,
        xCompanyId: dataToCopy.topLevelFields?.customer?.id || undefined
      },
      {
        onError: () => {
          toggleShowFullPageLoader();
          handleError('Error!', GENERIC_ERROR_MESSAGE);
          onClose();
        }
      }
    );
    return response.data.id;
  };

  const handleServiceStageSubmission = async (values: NewStageFormValues, isEdit = false) => {
    if (!values.location || !values.service_provider || !values.start_date || !values.end_date) {
      return;
    }

    const dataToCopy = shipmentQuery.data
      ? getInvisibleDataFromLegacyShipment(shipmentQuery.data, values.stage_type)
      : {};

    // if the location is an address book entry, save the ID of that entry as the `system_id`
    const servicePayload = {
      location: isLegacyStopLocation(values.location)
        ? {
            ...mapAddressLookupFieldsToAddress(values.location?.address),
            location_type: LocationType.Other,
            system_id: values.location.created_using_address_book_entry_id ?? values.location.id ?? undefined
          }
        : mapAddressLookupFieldsToAddress(values.location),
      actual_datetimes: {start: values.start_date, end: values.end_date},
      provider_rate: values.provider_rate
        ? {value: values.provider_rate, currency_code: values.preferred_currency}
        : undefined,
      customer_rate: values.customer_rate
        ? {value: values.customer_rate, currency_code: values.preferred_currency}
        : undefined,
      //assign id, which is present in backend-core service provider model, to provider_id..
      //which is the identifier for the corrogo service provider model.
      service_provider: {
        ...values.service_provider,
        provider_id: serviceProviderIsCorrogoServiceProvider(values.service_provider)
          ? values.service_provider?.provider_id
          : values.service_provider.id
      },
      references: dataToCopy.references ?? serviceStageData?.references,
      // if we're going from v2 legacy shipment -> v3 service, copy over the custom data and customer from the v2 legacy shipment.
      // otherwise, just copy over the whole custom_data field on the previous service.
      custom_data: dataToCopy.custom_data ?? serviceStageData?.custom_data
    };

    const response = isEdit
      ? await getUpdateServiceMutation(values.stage_type).mutateAsync(
          {serviceId: resourceId, servicePayload},
          {
            onError: () => {
              toggleShowFullPageLoader();
              handleError('Error!', GENERIC_UPDATE_ERROR_MESSAGE);
              onClose();
            }
          }
        )
      : await getCreationMutation(values.stage_type).mutateAsync(servicePayload, {
          onError: () => {
            toggleShowFullPageLoader();
            handleError('Error!', GENERIC_ERROR_MESSAGE);
            onClose();
          }
        });
    return response.data.id;
  };

  const createShipmentWrapper = async () => {
    // don't create a shipment wrapper if a shipment wrapper already exists
    if (shipmentId) {
      return;
    }

    // create an empty v3 shipment wrapper. this will be the overarching structure which
    // holds the different stages
    const shipmentWrapper = await createShipmentMutation.mutateAsync({});
    const shipmentWrapperId = shipmentWrapper.data?.id;

    if (!shipmentWrapperId) {
      return;
    }

    // add the leg (v2 legacy shipment) passed in to this modal as a stage on the new v3 shipment wrapper created above
    const legacyStageResponse = await createLegacyShipmentStageMutation.mutateAsync(
      {
        shipmentId: shipmentWrapperId,
        payload: {legacy_shipment_id: resourceId, directly_depends_on_stages: []}
      },
      {
        onError: () => {
          toggleShowFullPageLoader();
          handleError('Error!', GENERIC_ERROR_MESSAGE);
          onClose();
        }
      }
    );

    return {shipmentWrapperId, legacyStageResponse};
  };

  const handleSubmit = async (values: NewStageFormValues) => {
    toggleShowFullPageLoader();
    let serviceId;
    // if we are editing a service
    if (serviceType) {
      await handleServiceStageSubmission(values, true);
      await queryClient.invalidateQueries([SERVICES_QUERY_KEY, serviceType, resourceId]);
      await queryClient.invalidateQueries([SHIPMENTS_STAGES_QUERY_KEY, shipmentId]);
      toggleShowFullPageLoader();
      handleSuccess(
        `${
          values.stage_type === 'FTL' || values.stage_type === 'LTL' || values.stage_type === 'VLTL'
            ? values.stage_type
            : values.stage_type
            ? startCaseToLower(values.stage_type)
            : 'Existing'
        } Stage Updated!`,
        'Your changes have been saved.'
      );
      onClose();
      return;
    }

    const wrapperStageData = await createShipmentWrapper();

    const shipmentWrapperId = shipmentId ? shipmentId : wrapperStageData?.shipmentWrapperId;

    if (!shipmentWrapperId) {
      toggleShowFullPageLoader();
      handleError('Error!', GENERIC_ERROR_MESSAGE);
      return;
    }

    if (isServiceStageType(values.stage_type)) {
      // if a service stage was chosen, create that service out of the values the user entered in the form
      serviceId = await handleServiceStageSubmission(values);
      if (!serviceId) {
        toggleShowFullPageLoader();
        handleError('Error!', GENERIC_ERROR_MESSAGE);
        return;
      }

      const payload = resourceStage
        ? {directly_depends_on_stages: [resourceStage.id]}
        : wrapperStageData?.legacyStageResponse.data.id
        ? {directly_depends_on_stages: [wrapperStageData.legacyStageResponse.data.id]}
        : {};

      // assign that new service as a stage to the shipment
      await assignServiceToShipmentMutation.mutateAsync(
        {
          shipmentId: shipmentWrapperId,
          serviceId,
          payload
        },
        {
          onError: () => {
            toggleShowFullPageLoader();
            handleError('Error!', GENERIC_ERROR_MESSAGE);
            onClose();
          }
        }
      );
    } else {
      // if a legacy shipment stage was chosen, create that legacy shipment out of the values the user entered in the form
      const legacyShipmentId = await handleLegacyShipmentSubmission(values);
      if (!legacyShipmentId) {
        toggleShowFullPageLoader();
        handleError('Error!', GENERIC_ERROR_MESSAGE);
        return;
      }

      // assign that new legacy shipment as a stage in our v3 wrapper created earlier
      const createLegacyShipmentStageResponse = await createLegacyShipmentStageMutation.mutateAsync(
        {
          shipmentId: shipmentWrapperId,
          payload: {
            legacy_shipment_id: legacyShipmentId,
            directly_depends_on_stages:
              // if we cloned a container, the new legacy drayage shipment is not dependent on the drayage stage
              // that we created it from.
              isCloningContainer
                ? []
                : resourceStage?.id
                ? [resourceStage.id]
                : wrapperStageData?.legacyStageResponse.data.id
                ? [wrapperStageData.legacyStageResponse.data.id]
                : []
          }
        },
        {
          onError: () => {
            toggleShowFullPageLoader();
            handleError('Error!', GENERIC_ERROR_MESSAGE);
            onClose();
          }
        }
      );
      //set the service id to the stage created from the legacy shipment
      serviceId = createLegacyShipmentStageResponse?.data?.legacy_shipment_id;
    }
    await queryClient.invalidateQueries([SHIPMENTS_STAGES_QUERY_KEY, shipmentId]);
    await queryClient.invalidateQueries([RESOURCE_STAGES_QUERY_KEY, resourceId]);
    toggleShowFullPageLoader();
    handleSuccess(
      `${
        values.stage_type === 'FTL' || values.stage_type === 'LTL' || values.stage_type === 'VLTL'
          ? values.stage_type
          : values.stage_type
          ? startCaseToLower(values.stage_type)
          : 'New'
      } Stage Created!`,
      'Your stage has been created.'
    );
    onClose(shouldAddAnotherStage, serviceId);
  };

  const handleAdvancedShipmentCreation = async (
    values: NewStageFormValues,
    setSubmitting: FormikHelpers<NewStageFormValues>['setSubmitting'],
    validateForm: FormikHelpers<NewStageFormValues>['validateForm']
  ) => {
    const wrapperStageData = await createShipmentWrapper();
    const shipmentWrapperId = shipmentId ? shipmentId : wrapperStageData?.shipmentWrapperId;
    //imperatively validate the form, since the tertiary button triggering this action..
    //doesn't go through the standard onSubmit lifecycle.
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      return;
    }
    toggleShowFullPageLoader();
    const legacyShipmentId = await handleLegacyShipmentSubmission(values);
    if (!legacyShipmentId || !shipmentWrapperId) {
      toggleShowFullPageLoader();
      handleError('Error!', GENERIC_ERROR_MESSAGE);
      return;
    }
    // assign that new legacy shipment as a stage in our v3 wrapper created earlier
    createLegacyShipmentStageMutation.mutate(
      {
        shipmentId: shipmentWrapperId,
        payload: {
          legacy_shipment_id: legacyShipmentId,
          directly_depends_on_stages:
            //if we cloned a container, the new legacy drayage shipment is not dependent on the drayage stage
            //that we created it from.
            isCloningContainer ? [] : resourceStage?.id ? [resourceStage.id] : []
        }
      },
      {
        onSuccess: () => {
          const linkToManualShipmentCreation = `/new-shipment/${legacyShipmentId}`;
          window.open(linkToManualShipmentCreation, '_blank');
          onClose();
        },
        onSettled: () => {
          toggleShowFullPageLoader();
          setSubmitting(false);
        },
        onError: () => {
          toggleShowFullPageLoader();
          handleError('Error!', GENERIC_ERROR_MESSAGE);
          onClose();
        }
      }
    );
  };

  if (internationalPreferencesQuery.isError) {
    return <>Could not load currency of record preferences for your company</>;
  }

  const companyDefaultCurrency = internationalPreferencesQuery.data.currency;
  const allowCurrencyChange = internationalPreferencesQuery.data.allow_currency_change;

  return (
    <>
      <Formik
        initialValues={createDefaultFormValues({
          resourceId,
          legacyShipment: shipmentQuery.data,
          resourceStage,
          service: serviceQuery.data,
          serviceLocation: serviceLocationQuery.data || undefined,
          isCloningContainer: isCloningContainer,
          companyDefaultCurrency
        })}
        validationSchema={() =>
          lazy((values: NewStageFormValues) =>
            isServiceStageType(values.stage_type)
              ? SERVICE_STAGE_VALIDATION_SCHEMA
              : LEGACY_SHIPMENT_STAGE_VALIDATION_SCHEMA
          )
        }
        onSubmit={handleSubmit}
      >
        {({values, setSubmitting, validateForm, setFieldValue}) => (
          <Form noValidate>
            <div className="mb-12">
              <div className="pb-4">
                {isCloningContainer ? CLONE_STAGE_FORM_HEADER_TEXT : ADD_NEW_STAGE_FORM_HEADER_TEXT}
              </div>
              <Field
                name="stage_type"
                component={FormikSelect}
                options={STAGE_SELECT_OPTIONS}
                onChange={(selectedOption: string) => {
                  if (
                    //equipment type has been prepopulated from legacy shipment
                    shouldPrepopulateEquipmentTypeField(resourceId, shipmentQuery.data) &&
                    //drayage has been selected (we don't want to default equipment type)
                    selectedOption === LEGACY_SHIPMENT_MODES.find((mode: string) => mode === 'Drayage')
                  ) {
                    //clear out the default equipment value
                    setFieldValue('equipment', null);
                    setFieldValue('stage_type', selectedOption);
                  } else setFieldValue('stage_type', selectedOption);
                }}
                simpleValue
                label="Stage Type"
                maxMenuHeight={500}
                required
                disabled={serviceType}
              />
              {values.stage_type ? (
                isServiceStageType(values.stage_type) ? (
                  <ServiceStageFields allowCurrencyChange={allowCurrencyChange} />
                ) : (
                  <>
                    <LegacyShipmentStageFields />
                    <ConditionalLegacyDrayageShipmentStageFields isCloningContainer={isCloningContainer} />
                    <ConditionalTruncatedLegacyShipmentLineItemsFields isCloningContainer={isCloningContainer} />
                    {allowCurrencyChange ? (
                      <div className="pt-4">
                        <NewStageCurrencyOfRecordField />
                      </div>
                    ) : null}
                  </>
                )
              ) : null}
            </div>
            <ModalFormFooter
              onCancel={onClose}
              options={
                //we are creating a new stage and not cloning an existing stage
                !serviceType && !isCloningContainer ? (
                  <Checkbox
                    label="Add Another Stage"
                    onClick={toggleShouldAddAnotherStage}
                    checked={shouldAddAnotherStage}
                  />
                ) : null
              }
              //if stage is not a legacy shipment mode, don't show advanced creation button
              tertiaryActionName={
                values.stage_type && !isServiceStageType(values.stage_type) ? 'Advanced Creation' : null
              }
              onTertiaryActionClick={() => handleAdvancedShipmentCreation(values, setSubmitting, validateForm)}
            />
          </Form>
        )}
      </Formik>
      <FullPageLoader show={showFullPageLoader}>Preparing Shipment...</FullPageLoader>
    </>
  );
}

export default compose<NewStageFormProps, Partial<NewStageFormProps>>(withRouter)(NewStageForm);
