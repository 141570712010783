/* global $, google */
import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, change, FormSection} from 'redux-form';
import {Form, Button, Row, Col} from 'react-bootstrap';
import renderAsyncSelect from 'App/formComponents/renderAsyncSelect';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderField from 'App/formComponents/renderField';
import renderErrorField from 'App/formComponents/renderErrorField';
import './_mode.scss';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import Scroll from 'react-scroll';
var Element = Scroll.Element;

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    equipmentTypes: state.shipments.equipmentTypes,
    shipmentModes: state.shipments.shipmentModes,
    shipperRelationships: state.brokers.shipperRelationshipsForHeader,
    loadingCustomerRelationships: state.brokers.loadingCustomerRelationships
  }),
  {...actions, ...brokerActions}
)
export default class Customer extends Component {
  constructor(props) {
    super(props);

    this.getCustomers = this.getCustomers.bind(this);

    this.state = {
      isBroker: false,
      pagination: {
        page: 1,
        pageSize: 25,
        ordering: 'company',
        q: ''
      }
    };
  }

  componentDidMount() {
    if (
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('customer_relationships.view')
    ) {
      //then this is a broker
      this.setState({isBroker: true});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.user &&
      nextProps.user.permissions &&
      nextProps.user.permissions !== this.props.user.permissions &&
      nextProps.user.permissions.includes('customer_relationships.view')
    ) {
      //then this is a broker
      this.setState({isBroker: true});
    }
  }

  componentWillMount() {}

  getCustomers(input) {
    const pagination = this.state.pagination;
    if (input) {
      pagination.q = input;
      pagination.pageSize = null;
    }
    return this.props
      .fetchBrokerShipperRelationshipsForTypeaheads(this.props.company.brokerage.id, pagination)
      .then((response) => {
        if (response.status === 200) {
          return response.details;
        }
      });
  }

  render() {
    const {formValues, showCustomerField} = this.props;
    return (
      <div id="customer">
        {this.state.isBroker && (
          <div>
            <Element name={`position-book_as_customer`} />

            <h2 className="border-bottom">Customer</h2>
            <div className="formstyle-light">
              <div className="quote-flow__mode">
                <span className="quote-flow__customer">
                  {showCustomerField && (
                    <Field
                      required
                      label="Customer Account"
                      extraClass="quotingHeader__customer"
                      name="book_as_customer"
                      defaultValue={formValues?.values?.book_as_customer}
                      getOptions={this.getCustomers}
                      component={renderAsyncSelect}
                      disabled={Boolean(formValues?.values?.id)}
                      valueField="company.id"
                      labelField="company.name"
                      placeholder="Search for an account to book under"
                      defaultOptions
                    />
                  )}
                  <Field
                    label="Customer Reference #"
                    name="customer_reference_number"
                    component={renderField}
                    placeholder="e.g., 5H1PW3LL"
                  />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
