import {DateTimePicker} from 'react-widgets';
import {FormGroup, ControlLabel} from 'react-bootstrap';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import {formatHrMin} from 'App/utils/globals';

moment.locale('en');
momentLocalizer();

const renderDateTimeField = ({
  input,
  value,
  placeholder,
  label,
  time,
  req,
  meta: {touched, error, warning},
  ...rest
}) => (
  <FormGroup validationState={touched && error ? 'error' : null}>
    <ControlLabel>
      {
        <span>
          {label}
          {req && <span className="requiredField">*</span>}
        </span>
      }
    </ControlLabel>

    <DateTimePicker
      {...input}
      value={value}
      format={'MMM D, YYYY ' + formatHrMin}
      timeFormat={formatHrMin}
      placeholder={placeholder ? placeholder : 'Select Date/Time'}
      onBlur={() => input.onBlur(input.value)}
      min={moment().startOf('hour').toDate()}
      step={30}
      time={time}
      {...rest}
    />
    {touched && error && (
      <p className="error-text">
        <i className="icon icon-Delayed" />
        {error}
      </p>
    )}
  </FormGroup>
);

export default renderDateTimeField;
