import {useState} from 'react';
import {connect} from 'react-redux';
import {Formik, Form, Field} from 'formik';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {object, string} from 'yup';
import {FormikLabelInput, Toast} from '@shipwell/shipwell-ui';
import {shipmentsShipmentIdPut} from 'App/actions/_shipmentDetails';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const defaultFormValues = {
  name: ''
};

export const validationSchema = object().shape({
  name: string()
});

const ShipmentDescriptionForm = ({shipment, user, isQuotingLimitedUser, dispatch}) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const handleSubmit = async (value) => {
    const response = await dispatch(shipmentsShipmentIdPut(shipment.id, {...shipment, name: value}));
    if (response.status === 400) {
      setErrorMessages(response, [`Error updating name for ${shipment.id}.`], ['name']);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{...defaultFormValues, ...shipment}}
      >
        {() => (
          <Form>
            <Field
              name="name"
              placeholder="Shipment Description"
              component={FormikLabelInput}
              onSubmit={handleSubmit}
              disabled={
                (!user.permissions?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) &&
                  !user.permissions?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) ||
                isQuotingLimitedUser
              }
              autoComplete="name"
            />
          </Form>
        )}
      </Formik>
      <Toast
        variant="error"
        show={errorMessages.length > 0}
        title="Error on Shipment!"
        delay={10000}
        onClose={() => setErrorMessages([])}
      >
        <div className="flex flex-col gap-2 pb-4">
          {errorMessages.map((error, i) => (
            <div key={i}>
              <span>{error}</span>
            </div>
          ))}
        </div>
        <span>
          {pluralize('This', errorMessages.length)} {pluralize('error', errorMessages.length)} must be corrected before
          any new changes can be saved.
        </span>
      </Toast>
    </>
  );
};

ShipmentDescriptionForm.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }),
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }),
  isQuotingLimitedUser: PropTypes.bool,
  dispatch: PropTypes.func
};

ShipmentDescriptionForm.defaultProps = {
  isQuotingLimitedUser: false,
  dispatch: () => {}
};

export default connect((state) => ({
  shipment: state.shipmentdetails.one,
  user: state.userProfile.user,
  isQuotingLimitedUser: state.userProfile.is_quoting_limited_user
}))(ShipmentDescriptionForm);
