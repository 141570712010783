import {useQuery} from '@tanstack/react-query';
import {validateLocation} from 'App/api/locations/typed';
import {getBreadcrumbs} from 'App/api/shipment/typed';
import {formatDeviceBreadcrumbsData} from './formatDeviceBreadcrumbsData';

export const useGetShipmentLocation = (shipmentId: string) => {
  const GET_SHIPMENT_LOCATION_QUERY = 'getsShipmentLocation';
  const shipmentLocationQuery = useQuery(
    [GET_SHIPMENT_LOCATION_QUERY, shipmentId],
    async () => {
      const breadcrumbs = await getBreadcrumbs({
        shipmentId
      });

      const {lat, lon} = breadcrumbs?.data?.shipment_locations?.[0] || {};
      const latLongStr = `${lat}, ${lon}`;
      const validAddress = await validateLocation(latLongStr);

      const output = formatDeviceBreadcrumbsData(
        validAddress?.data?.geocoded_address?.city || undefined,
        validAddress.data.geocoded_address?.state_province || undefined,
        breadcrumbs?.data?.shipment_locations?.[0]?.update_time || undefined,
        validAddress.data.geocoded_address?.timezone || undefined
      );

      return output;
    },
    {
      enabled: Boolean(shipmentId)
    }
  );

  const {data: shipmentLocationData, isFetching: isFetchingShipmentLocation} = shipmentLocationQuery;

  return {
    shipmentLocationData,
    isFetchingShipmentLocation,
    shipmentLocationQuery
  };
};
