import momentTZ from 'moment-timezone';
import {CombinedQuote} from '../../types/combinedQuote';
import {createColumnHelper} from '@tanstack/react-table';
import {Button, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import isNil from 'lodash/isNil';
import {formatCurrencyValue} from 'App/utils/globals';
import pluralize from 'pluralize';

const columnHelper = createColumnHelper<CombinedQuote>();

export const getParcelColumns = ({
  handleSelectQuote,
  packageType,
  numberOfPackages,
  disableActions
}: {
  handleSelectQuote: (quote: CombinedQuote) => Promise<void>;
  packageType?: string;
  numberOfPackages?: number;
  disableActions?: boolean;
}) => [
  columnHelper.accessor('imageUrl', {
    header: () => {
      return (
        <div className="flex items-center gap-2">
          <span>Carrier</span>
          <Tooltip
            placement="top"
            wrapperClassname="flex items-center content-center"
            arrow
            tooltipContent={
              <div className="flex flex-col items-start">
                <span className="text-sm font-bold">Carrier Accounts</span>
                <span>To change a parcel carriers account, go to the individual carrier page to update.</span>
              </div>
            }
          >
            <SvgIcon name="InfoOutlined" color="$sw-icon" />
          </Tooltip>
        </div>
      );
    },
    cell: (info) => {
      return <img src={info.getValue()} alt="carrier" style={{width: '80px', height: '50px', objectFit: 'contain'}} />;
    },
    sortingFn: (a, b) => {
      if (!a.original.carrier?.display_name || !b.original.carrier?.display_name) return 0;

      return a.original.carrier?.display_name.localeCompare(b.original.carrier?.display_name);
    },
    id: 'carrier',
    size: 160
  }),
  columnHelper.accessor('serviceLevelLabel', {
    header: 'Service',
    cell: (info) => {
      let service = info.getValue();
      if (service?.length > 26) {
        service = service.slice(0, 23) + '...';
        return (
          <Tooltip portal tooltipContent={service}>
            <span>{service}</span>
          </Tooltip>
        );
      }
      return service || '--';
    },
    id: 'service',
    size: 160
  }),
  columnHelper.accessor('packageTypes', {
    header: 'Package',
    cell: (info) => {
      const packageTypes = info.getValue();

      const pkgNumberString = numberOfPackages && numberOfPackages > 1 ? ` (${numberOfPackages})` : '';

      if (!packageType) {
        return 'Custom';
      }

      const packageTypeString = packageTypes?.[packageType]
        ? packageTypes?.[packageType]
        : packageType === 'MIXED_TYPES'
        ? 'Mixed Packages'
        : 'Custom';

      // if the type is too long to fit in the column
      // show a truncated string and a tooltip with the full length package type
      if (packageTypeString.length > 23) {
        return (
          <Tooltip tooltipContent={packageTypeString}>
            {`${packageTypeString.slice(0, 20)}...${pkgNumberString}`}
          </Tooltip>
        );
      }
      return `${packageTypeString}${pkgNumberString}`;
    },
    id: 'packageType',
    enableSorting: false
  }),
  columnHelper.accessor('transit_days', {
    header: 'Transit',
    cell: (info) => {
      const value = info.getValue();
      return !isNil(value) ? pluralize('Day', value, true) : '--';
    },
    id: 'transit_days',
    size: 80
  }),
  columnHelper.accessor('delivery_date', {
    header: 'Delivery Date',
    cell: (info) => {
      const value = info.getValue();
      return momentTZ(value).isValid() ? momentTZ.tz(value, momentTZ.tz.guess()).format('ddd, MMMM D') : '--';
    },
    id: 'delivery_date',
    size: 140
  }),
  columnHelper.accessor('total', {
    header: 'Rate',
    cell: (info) => {
      const original = info.row.original;

      if (original.is_failure) {
        return (
          <Tooltip tooltipContent={original.error_message || 'Failed to Quote'} portal>
            <span className="text-danger">
              <i className="icon icon-Delayed text-danger pad-right" /> Failed to Quote
            </span>
          </Tooltip>
        );
      }
      return <b>{`${original.currency || 'USD'} ${formatCurrencyValue(original.total)}`}</b>;
    },
    id: 'total',
    size: 120,
    // since we created a logic to put failed quotes with total = undefined, this helps put them on the end of the list
    sortUndefined: 'last'
  }),
  columnHelper.display({
    header: 'Action',
    cell: (info) => {
      const original = info.row.original;
      return (
        !original.is_failure && (
          <Button disabled={disableActions} onClick={() => void handleSelectQuote(original)}>
            Select
          </Button>
        )
      );
    },
    id: 'select',
    size: 120
  })
];
