import React, {MouseEvent, ReactNode} from 'react';
import {Button, Popover} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';

export const FILTER_TYPE = {
  OR: 'Or',
  EXCLUDE: 'Exclude',
  AND: 'And'
} as const;

export type FilterType = (typeof FILTER_TYPE)[keyof typeof FILTER_TYPE];

export const FilterLabelWithFilterType = ({
  label,
  filterType,
  onChange
}: {
  label: ReactNode;
  filterType: FilterType;
  onChange: (val: FilterType) => void;
}) => {
  return (
    <FlexBox gap="s" items="center">
      <span>{label}</span>
      <Popover
        placement="bottom-start"
        showArrow={false}
        trigger={({
          setIsOpen,
          setTriggerElement
        }: {
          setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
          setTriggerElement: (el: HTMLElement | null) => void;
        }) => (
          <Button
            ref={setTriggerElement}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
            variant="tertiary"
            size="sm"
            isCompact
            iconName="CarrotDown"
            iconSide="right"
          >
            {filterType}
          </Button>
        )}
      >
        {({setIsOpen}: {setIsOpen: React.Dispatch<React.SetStateAction<boolean>>}) => (
          <ul className="m-0">
            <li>
              <Button
                variant="tertiary"
                size="sm"
                width="full"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onChange('Or');
                  setIsOpen(false);
                }}
              >
                Or
              </Button>
            </li>
            <li>
              <Button
                variant="tertiary"
                size="sm"
                width="full"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onChange('Exclude');
                  setIsOpen(false);
                }}
              >
                Exclude
              </Button>
            </li>
            <li>
              <Button
                variant="tertiary"
                size="sm"
                width="full"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onChange('And');
                  setIsOpen(false);
                }}
              >
                And
              </Button>
            </li>
          </ul>
        )}
      </Popover>
    </FlexBox>
  );
};
