/* eslint-disable max-len */
import {Component} from 'react';
import {connect} from 'react-redux';
import {ProviderCode} from '@shipwell/genesis-sdk';
import {getPackageTypesByProvider} from './utils';
import SelectField from 'App/formComponents/fields/select';
import {fetchFedexPackageTypes, fetchUPSPackageTypes, fetchUSPSPackageTypes} from 'App/actions/_shipments';

/**
 * Provider Specific Package Type Field
 */
class ProviderPackageTypeField extends Component {
  static defaultProps = {
    value: '',
    name: 'provider_specific_packaging',
    input: {},
    placeholder: 'Select',
    expenseClasses: [],
    options: [
      {
        id: 'YOUR_PACKAGING',
        name: (
          <span>
            Package <div>(Custom)</div>
          </span>
        )
      }
    ],
    provider: [],
    clearable: true
  };

  componentDidMount() {
    const {company, fedexPackageTypes, upsPackageTypes, uspsPackageTypes} = this.props;

    if (company.id && !fedexPackageTypes.length) {
      this.getFedexPackageTypes();
    }
    if (company.id && !upsPackageTypes.length) {
      this.getUPSPackageTypes();
    }
    if (company.id && !uspsPackageTypes.length) {
      this.getUSPSPackageTypes();
    }
  }

  componentDidUpdate(prevProps) {
    const {company, fedexPackageTypes, upsPackageTypes, uspsPackageTypes} = this.props;

    if (company.id && prevProps.company !== company) {
      if (!fedexPackageTypes.length) {
        this.getFedexPackageTypes();
      }
      if (!upsPackageTypes.length) {
        this.getUPSPackageTypes();
      }
      if (!uspsPackageTypes.length) {
        this.getUSPSPackageTypes();
      }
    }
  }

  /**
   * FedEx Package Types - parcel
   */
  async getFedexPackageTypes() {
    try {
      return await this.props.dispatch(fetchFedexPackageTypes());
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * UPS Package Types - parcel
   */
  async getUPSPackageTypes() {
    try {
      return await this.props.dispatch(fetchUPSPackageTypes());
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * USPS Package Types - parcel
   */
  async getUSPSPackageTypes() {
    try {
      return await this.props.dispatch(fetchUSPSPackageTypes());
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      input,
      arrowRenderer,
      placeholder,
      packageTypes,
      fedexPackageTypes,
      upsPackageTypes,
      uspsPackageTypes,
      isParcel,
      provider,
      field = {},
      ...rest
    } = this.props;

    const cleanedProvider = provider.filter(Boolean);

    const value = input.value || field.value || rest.value;
    const name = input.name || field.name || rest.name;
    const onChange = input.onChange || field.onChange || rest.onChange;
    let options = this.props.options;

    if (cleanedProvider.length === 1 && cleanedProvider.find((prov) => prov.id === 'fedex')) {
      options = [...options, ...fedexPackageTypes];
    }
    if (cleanedProvider.length === 1 && cleanedProvider.find((prov) => prov.id === 'ups')) {
      options = [...options, ...upsPackageTypes];
    }
    if (cleanedProvider.length === 1 && cleanedProvider.find((prov) => prov.id === 'usps')) {
      options = [...options, ...uspsPackageTypes];
    }
    if (
      cleanedProvider.length === 1 &&
      Object.values(ProviderCode).some((provider) => provider === cleanedProvider[0].id)
    ) {
      const packageTypesByProvider = getPackageTypesByProvider(cleanedProvider[0].id);
      options = [...options, ...packageTypesByProvider];
    }

    return (
      <SelectField
        {...rest}
        value={value}
        name={name}
        placeholder={placeholder}
        options={options}
        onChange={(value) => {
          if (value && field && field.onChange) {
            return onChange({target: {value, name}});
          }
          if (value && onChange) {
            return onChange(value);
          }
          if (!value && field && field.onChange) {
            return onChange({target: {value: null, name}});
          }
          if (!value) {
            return onChange(null);
          }
        }}
      />
    );
  }
}

export default connect((state) => ({
  company: state.auth.company,
  fedexPackageTypes: state.shipments.fedexPackageTypes.map((type) => ({
    ...type,
    name: (
      <span>
        FedEx® {type.label}
        {type.description.map((description) => (
          <div>{description}</div>
        ))}
      </span>
    )
  })),
  upsPackageTypes: state.shipments.upsPackageTypes.map((type) => ({
    ...type,
    name: (
      <span>
        UPS {type.label}
        {type.description.map((description) => (
          <div>{description}</div>
        ))}
      </span>
    )
  })),
  uspsPackageTypes: state.shipments.uspsPackageTypes.map((type) => ({
    ...type,
    name: (
      <span>
        USPS {type.label}
        {type.description.map((description) => (
          <div>{description}</div>
        ))}
      </span>
    )
  }))
}))(ProviderPackageTypeField);
