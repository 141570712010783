import {ProductPackageType, ShipmentLineItem} from '@shipwell/backend-core-sdk';
import {Card, CollapsibleCardContent, DisplayValue, Title} from '@shipwell/shipwell-ui';
import {SvgIconWithNumberFallback} from 'App/components/svgIconWithNumberFallback/svgIconWithNumberFallback';
import {usePackageTypes} from 'App/data-hooks';
import upperFirst from 'lodash/upperFirst';
import pluralize from 'pluralize';

interface TrackingItemsProps {
  items?: ShipmentLineItem[];
}

export default function TrackingItems({items}: TrackingItemsProps) {
  const {data: packageTypes} = usePackageTypes();

  function getSelectedPackageType(providerSpecificPackaging: string, type?: ProductPackageType) {
    providerSpecificPackaging = providerSpecificPackaging
      ? providerSpecificPackaging
          .replace(/_/g, ' ')
          .replace('FEDEX', 'FedEx®')
          .toLowerCase()
          .split(' ')
          .map(upperFirst)
          .join(' ')
      : '';

    if (providerSpecificPackaging) {
      return providerSpecificPackaging;
    }
    if (packageTypes) {
      const pkg = packageTypes?.find((item) => item.code === type);
      if (pkg) {
        return pkg.description;
      }
      return '--';
    }
    return '--';
  }

  return (
    <>
      <Card
        draggableProvided={false}
        title={
          <div className="flex flex-1">
            <Title variant="CardTitle" className="flex pl-2 font-medium">
              Shipping Items
            </Title>
            <span className="font-normal uppercase text-sw-label">
              {items?.length} {pluralize('Item', items?.length)}
            </span>
          </div>
        }
        isCollapsible
      >
        <CollapsibleCardContent>
          {items?.map((item, idx) => {
            return (
              <div
                className={`grid grid-cols-2 gap-4 md:grid-cols-5 ${idx + 1 < items?.length ? 'mb-4' : ''}`}
                key={idx}
              >
                <div>
                  <div className="float-left mr-2">
                    <SvgIconWithNumberFallback name={`Num${idx + 1}Outlined`} />
                  </div>
                  <DisplayValue label="item">{item.description || '--'}</DisplayValue>
                </div>

                <DisplayValue label="quantity">{item.total_packages || '--'}</DisplayValue>
                <DisplayValue label="number of pieces">{item.total_pieces || '--'}</DisplayValue>
                <DisplayValue label="packaging">
                  {getSelectedPackageType(item.provider_specific_packaging || '', item.package_type)}
                </DisplayValue>
                <DisplayValue label="package weight">
                  {`${item.package_weight || ''} ${item.weight_unit || ''}`.trim() || '--'}
                </DisplayValue>
              </div>
            );
          })}
        </CollapsibleCardContent>
      </Card>
    </>
  );
}
