import {useState, useEffect, useMemo} from 'react';
import {getFacilityHoursOfOperation} from 'App/api/facilities';
import {FacilityHoursOfOperation} from '@shipwell/tempus-sdk';
import moment from 'moment';

type DayOfWeek = 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY';

export const useFacilityHoursOfOperation = (id: string | undefined, applyFilters: boolean, selectedDate: Date) => {
  const [hours, setHours] = useState<FacilityHoursOfOperation[]>([]);

  useEffect(() => {
    const fetchHours = async () => {
      if (!id) {
        return;
      } else if (applyFilters) {
        const fetchedHours = await getFacilityHoursOfOperation(id);
        setHours(fetchedHours || []);
        return;
      }
      setHours([]);
    };

    fetchHours();
  }, [id, applyFilters]);

  const dayMapping = useMemo(
    () => ({
      SUNDAY: 0,
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6
    }),
    []
  );

  const hiddenDays = useMemo(() => {
    return hours
      .filter((day) => !day.is_facility_open)
      .map((day) => dayMapping[day.day as DayOfWeek]) // Map to numeric indices
      .filter((dayIndex): dayIndex is number => dayIndex !== undefined); // Ensure valid numbers only
  }, [hours, dayMapping]);

  const lastOpenDay = useMemo(() => {
    const defaultYesterdayIndex = (selectedDate.getDay() - 1 + 7) % 7;

    if (!hours || hours.length === 0) {
      return defaultYesterdayIndex;
    }
    const openDays = hours.filter((day) => day.is_facility_open).map((day) => dayMapping[day.day as DayOfWeek]);

    if (openDays.length === 0) {
      return defaultYesterdayIndex;
    }

    const selectedDayIndex = selectedDate.getDay(); // Index of selectedDate (0 for Sunday, 1 for Monday, etc.)
    let previousDay = selectedDayIndex;

    // Loop backwards through the week to find the last open day
    for (let i = 0; i < 7; i++) {
      previousDay = (previousDay - 1 + 7) % 7;
      if (openDays.includes(previousDay)) {
        return previousDay;
      }
    }

    return defaultYesterdayIndex;
  }, [hours, dayMapping, selectedDate]);

  const lastOpenDate = useMemo(() => {
    if (lastOpenDay === undefined) return selectedDate;
    const selectedDayIndex = selectedDate.getDay();
    const daysAgo = (selectedDayIndex - lastOpenDay + 7) % 7;
    return moment(selectedDate).subtract(daysAgo, 'days').toDate();
  }, [lastOpenDay, selectedDate]);

  return {hours, hiddenDays, lastOpenDay, lastOpenDate};
};
