import _ from 'lodash';
import {Component} from 'react';
import {Link} from 'react-router';
import {reduxForm, change, formValueSelector} from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {Button} from 'react-bootstrap';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import VendorsReportedData from './VendorsReportedData';
import VendorsFMCSA from './VendorsFMCSA';
import VendorsInsurance from './VendorsInsurance';
import VendorsPOC from './VendorsPOC';
import VendorsDocuments from './VendorsDocuments';
import VendorsTriumphPay from './VendorsTriumphPay';
import VendorsFMCSACensusData from './VendorsFMCSACensusData';
import FedExAccountDetails from 'App/containers/fedex/accounts';
import UPSAccountDetails from 'App/containers/ups/accounts';
import USPSAccountDetails from 'App/containers/usps/accounts';
import validate from 'App/utils/validateManageVendorsForm';
import {scrollToFirstErrorField} from 'App/utils/globals';
import SubmitButton from 'App/utils/SubmitButton';
import {basePath} from 'App/api/config';
import {checkTriumphPayAuth} from 'App/actions/_integrations';
import {getTriumphPayVendorDetails} from 'App/actions/_vendors';
import {getAccessToken} from 'App/api/utils';

import Scroll from 'react-scroll';
var Element = Scroll.Element;

@connect(
  (state) => ({
    ManageVendorsForm: state.form.ManageVendorsForm,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    isTriumphPayAuthed: state.integrations && state.integrations.isTriumphPayAuthed,
    triumphPay: state.vendors.triumphPay,
    paymentProcessorViewEnabled:
      state.auth.user &&
      state.auth.user.permissions &&
      state.auth.user.permissions.includes('payment_processors.view_payment_info'),
    paymentProcessorUpdateEnabled:
      state.auth.user &&
      state.auth.user.permissions &&
      state.auth.user.permissions.includes('payment_processors.update_payment_info'),
    paymentProcessorCreateEnabled:
      state.auth.user &&
      state.auth.user.permissions &&
      state.auth.user.permissions.includes('payment_processors.create_payment_info'),
    isAnalyticsEnabled:
      state.auth.company && state.auth.company.feature_flags && state.auth.company.feature_flags.analytics_enabled,
    isAnalyticsPermitted:
      state.auth.user && state.auth.user.permissions && state.auth.user.permissions.includes('analytics.view'),
    isManageIntegrationsPermitted:
      state.auth.user &&
      state.auth.user.permissions &&
      state.auth.user.permissions.includes('integrations.manage_integrations'),
    isFedExEnabled: state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.fedex_enabled,
    isUPSEnabled: state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.ups_enabled,
    isUSPSEnabled: state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.usps_enabled
  }),
  {checkTriumphPayAuth: checkTriumphPayAuth, getTriumphPayVendorDetails}
)
class ManageVendorsForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.redirect = this.redirect.bind(this);

    this.state = {embed_url: ''};
  }
  async componentDidMount() {
    const {
      user,
      ManageVendorsForm = {},
      selectedRelationship,
      isAnalyticsEnabled,
      isAnalyticsPermitted,
      featureFlags,
      selectedCarrier
    } = this.props;
    const {values = {}} = ManageVendorsForm;
    if (featureFlags && featureFlags.triumph_pay_enabled) {
      this.props.checkTriumphPayAuth();
    }
    if (
      featureFlags &&
      featureFlags.triumph_pay_enabled &&
      selectedCarrier &&
      selectedCarrier.vendor &&
      selectedCarrier.vendor.external_payment_processor_id
    ) {
      this.props.getTriumphPayVendorDetails(selectedCarrier.vendor.external_payment_processor_id);
    } else {
      this.props.dispatch(change('ManageVendorsForm', 'triumphPay', null));
    }
    //defaults number of poc to 1
    if (!values.point_of_contacts || values.point_of_contacts.length === 0) {
      this.props.dispatch(change('ManageVendorsForm', 'point_of_contacts', [{}]));
    }
    //defaults number of identifying codes to 1
    if (
      !values.vendor ||
      (values.vendor &&
        values.vendor.shipwell_vendor &&
        values.vendor.shipwell_vendor.identifying_codes &&
        values.vendor.shipwell_vendor.identifying_codes.length === 0)
    ) {
      this.props.dispatch(change('ManageVendorsForm', 'vendor.shipwell_vendor.identifying_codes', [{}]));
    }

    if (isAnalyticsEnabled && isAnalyticsPermitted) {
      const carrierRelationshipId =
        (selectedRelationship && selectedRelationship.id) || this.context.router.params.carrier_id;

      const accessToken = await getAccessToken();
      // Using axios as a temporary means since swagger has breaking changes
      axios
        .get(`${basePath}/carrier-relationships/${carrierRelationshipId}/analytics/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken
          }
        })
        .then(({data}) => {
          this.setState({embed_url: data.embed_url});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  redirect() {
    if (this.props.showInModal) {
      this.props.closeModal();
    } else {
      this.context.router.push('/carriers');
    }
  }

  render() {
    const {
      carrierTags,
      addTag,
      currentTag,
      currentTags,
      handleBlur,
      handleTagsChange,
      handleTagsFocus,
      tagsSearch,
      selectedCarrier,
      selectedRelationship,
      handleSubmit,
      identifyingCodes,
      error,
      submitting,
      edit,
      existingVendor,
      manualEntry,
      newRelationshipId,
      submittedForm,
      companyUsers,
      featureFlags = {},
      isTriumphPayAuthed,
      isAnalyticsEnabled,
      isAnalyticsPermitted,
      isManageIntegrationsPermitted,
      isFedExEnabled,
      isUPSEnabled,
      isUSPSEnabled,
      paymentProcessorViewEnabled,
      paymentProcessorUpdateEnabled,
      paymentProcessorCreateEnabled
    } = this.props;
    const isEditable = selectedCarrier && selectedCarrier.vendor && selectedCarrier.vendor.is_editable;
    const providerCode =
      selectedRelationship &&
      selectedRelationship.carrier_direct_integration_profile &&
      selectedRelationship.carrier_direct_integration_profile.provider_code;

    return (
      <div className="vendors__form">
        {isFedExEnabled && providerCode && providerCode === 'FEDEX' && (
          <Paper>
            <div className="vendors__header">
              <h3>FedEx Account</h3>
              {isManageIntegrationsPermitted && <Link to="/fedex">Add FedEx Account</Link>}
            </div>
            <div className="vendors__shipwell-vendor">
              <FedExAccountDetails />
            </div>
          </Paper>
        )}
        {isUPSEnabled && providerCode && providerCode === 'UPS' && (
          <Paper>
            <div className="vendors__header">
              <h3>UPS Account</h3>
              {isManageIntegrationsPermitted && <Link to="/ups">Add UPS Account</Link>}
            </div>
            <div className="vendors__shipwell-vendor">
              <UPSAccountDetails />
            </div>
          </Paper>
        )}
        {isUSPSEnabled && providerCode && providerCode === 'USPS' && (
          <Paper>
            <div className="vendors__header">
              <h3>USPS Account</h3>
            </div>
            <div className="vendors__shipwell-vendor">
              <USPSAccountDetails />
            </div>
          </Paper>
        )}
        <VendorsReportedData
          shipwellVendor={selectedCarrier && selectedCarrier.vendor && selectedCarrier.vendor.shipwell_vendor}
          isEditable={isEditable}
          identifyingCodes={identifyingCodes}
          carrierTags={carrierTags}
          edit={edit}
          existingVendor={existingVendor}
          form="ManageVendorsForm"
          manualEntry={manualEntry}
          addTag={addTag}
          currentTag={currentTag}
          currentTags={currentTags}
          handleTagsBlur={handleBlur}
          handleTagsChange={handleTagsChange}
          handleTagsFocus={handleTagsFocus}
          tagsSearch={tagsSearch}
          companyUsers={companyUsers}
        />
        {!manualEntry &&
          selectedCarrier &&
          selectedCarrier.vendor &&
          selectedCarrier.vendor.fmcsa_vendor &&
          !_.isEmpty(selectedCarrier.vendor.fmcsa_vendor) && (
            <VendorsFMCSA
              fmcsaVendor={selectedCarrier && selectedCarrier.vendor && selectedCarrier.vendor.fmcsa_vendor}
              identifyingCodes={identifyingCodes}
              carrierTags={carrierTags}
            />
          )}
        {!manualEntry &&
          selectedCarrier &&
          selectedCarrier.vendor &&
          selectedCarrier.vendor.fmcsa_census_data &&
          !_.isEmpty(selectedCarrier.vendor.fmcsa_census_data) && (
            <VendorsFMCSACensusData
              fmcsaCensusData={selectedCarrier && selectedCarrier.vendor && selectedCarrier.vendor.fmcsa_census_data}
            />
          )}
        <VendorsInsurance
          shipwellVendor={selectedCarrier && selectedCarrier.vendor && selectedCarrier.vendor.shipwell_vendor}
          isEditable={isEditable}
          form="ManageVendorsForm"
          manualEntry={manualEntry}
        />
        <VendorsPOC
          form="ManageVendorsForm"
          selectedRelationship={selectedRelationship}
          edit={edit}
          ManageVendorsForm={this.props.ManageVendorsForm}
          openPOCEdit={this.props.openPOCEdit}
          featureFlags={featureFlags}
          carrierRoles={this.props.carrierRoles}
        />
        {isTriumphPayAuthed && paymentProcessorViewEnabled && (
          <VendorsTriumphPay
            form="ManageVendorsForm"
            isEditable={isEditable}
            manualEntry={manualEntry}
            ManageVendorsForm={this.props.ManageVendorsForm}
            selectedRelationship={selectedRelationship}
            paymentProcessorUpdateEnabled={paymentProcessorUpdateEnabled}
            paymentProcessorCreateEnabled={paymentProcessorCreateEnabled}
          />
        )}
        <VendorsDocuments
          form="ManageVendorsForm"
          edit={edit}
          handleSubmit={handleSubmit}
          newRelationshipId={newRelationshipId}
          selectedRelationship={selectedRelationship}
          submittedForm={submittedForm}
        />
        {isAnalyticsEnabled && isAnalyticsPermitted && (
          <Paper>
            <div className="vendors__header">
              <h3>Analytics</h3>
            </div>
            {this.state.embed_url && (
              <iframe width="100%" height="600" style={{border: 0}} src={this.state.embed_url} />
            )}
          </Paper>
        )}

        {error && (
          <div className="error-text text-danger error-message text-center">
            <i className="icon icon-Delayed pad-right" />
            {error}
          </div>
        )}
        <div
          className={
            'manage-carriers__footer pad-top-double pad-bottom-double' + (!this.props.showInModal ? ' noModal' : '')
          }
        >
          <Button
            bsStyle="default"
            disabled={submitting}
            onClick={() => {
              //this.props.reset();
              this.redirect();
            }}
          >
            Cancel
          </Button>
          <SubmitButton handleSubmit={handleSubmit} submitting={submitting} />
        </div>
      </div>
    );
  }
}

ManageVendorsForm = reduxForm({
  form: 'ManageVendorsForm',
  validate,
  onSubmitFail: scrollToFirstErrorField,
  enableReinitialize: true
})(ManageVendorsForm);

ManageVendorsForm = connect((state, ownProps) => {
  return {
    initialValues: {
      vendor: state.vendors.selectedCarrier && state.vendors.selectedCarrier.vendor,
      carrier_owner:
        state.vendors.selectedRelationship && state.vendors.selectedRelationship.carrier_owner
          ? state.vendors.selectedRelationship.carrier_owner.id
          : undefined,
      point_of_contacts:
        state.vendors.selectedRelationship && state.vendors.selectedRelationship.point_of_contacts
          ? state.vendors.selectedRelationship.point_of_contacts
          : [
              {
                load_board_enabled: false
              }
            ],
      notes: state.vendors.selectedRelationship && state.vendors.selectedRelationship.notes,
      tags: state.vendors.selectedRelationship && state.vendors.selectedRelationship.tags,
      triumphPay: state.vendors.triumphPay
    }
  };
})(ManageVendorsForm);

const selector = formValueSelector('ManageVendorsForm');

ManageVendorsForm = connect((state) => {
  let defaultExpiresAt = selector(state, 'vendor.shipwell_vendor.insurance_expires_at');
  if (defaultExpiresAt) {
    defaultExpiresAt = moment(defaultExpiresAt).toDate();
  }
  // can select values individually
  const is_3pl = selector(state, 'vendor.shipwell_vendor.is_3pl');
  return {
    defaultExpiresAt,
    is_3pl,
    carrierRoles: state.carriers.roles
  };
})(ManageVendorsForm);

export default ManageVendorsForm;
