import {StopCustomData} from '@shipwell/backend-core-sdk';
import {
  Availability,
  AvailabilityRequest,
  BulkUpdateHoursOfOperationRequest,
  Configuration,
  CreateFacility,
  CreateFacilityDock,
  CreateFacilityDockAppointmentRule,
  CreateFacilityHoliday,
  CreateFacilityPointOfContact,
  CreateLoadType,
  FacilitiesApi,
  Facility,
  FacilityDock,
  FacilityHoliday,
  FacilityHoursOfOperation,
  FacilityIncludeQueryOptionEnum,
  FacilityPointOfContact,
  ShipmentMatchResults,
  ShipmentMatchingIncludeParams,
  UpdateFacilityDock,
  UpdateFacilityDockAppointmentRule,
  UpdateFacilityHoliday,
  UpdateLoadType
} from '@shipwell/tempus-sdk';
import {getAccessToken} from 'App/api/utils';
import axios from 'axios';
import {getV3ApiAllOfPaginated} from '../typedUtils';

const IncludeDockCount = false;

const basePath = process.env.SHIPWELL_TEMPUS_API_BASE_PATH;
function createFacilitiesApi(): FacilitiesApi {
  return new FacilitiesApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}
const api = createFacilitiesApi();

export function createFacility(body: CreateFacility) {
  return createFacilitiesApi().createFacilityFacilitiesPost(body);
}

export function updateFacility(facility: Facility) {
  const {id, ...body} = facility;
  return createFacilitiesApi().updateFacilityFacilitiesFacilityIdPut(id, body);
}

export async function getFacilities(pointOfContactUserId: undefined | string = undefined): Promise<Facility[]> {
  const api = createFacilitiesApi();

  const facilities = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const axiosOptions = IncludeDockCount ? [FacilityIncludeQueryOptionEnum.DockCount] : undefined;
    const {data} = await api.listFacilitiesFacilitiesGet(pointOfContactUserId, page, pageSize, undefined, axiosOptions);
    return data;
  }, 100);

  return facilities;
}

export async function getFacility(facilityId: string): Promise<Facility> {
  const {data: facility} = await createFacilitiesApi().retrieveFacilityFacilitiesFacilityIdGet(facilityId, [
    FacilityIncludeQueryOptionEnum.DockCount,
    FacilityIncludeQueryOptionEnum.ContactCount,
    FacilityIncludeQueryOptionEnum.LoadTypeCount
  ]);

  return facility;
}

export async function getFacilityDocks(facilityId: string): Promise<FacilityDock[]> {
  const api = createFacilitiesApi();

  const docks = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await api.listFacilityDocksFacilitiesFacilityIdDocksGet(facilityId, page, pageSize, 'created_at');
    return data;
  });
  return docks;
}

export async function getFacilityHoursOfOperation(facilityId: string): Promise<FacilityHoursOfOperation[]> {
  const api = createFacilitiesApi();
  const hours = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await api.listFacilityHoursOfOperationFacilitiesFacilityIdHoursOfOperationGet(
      facilityId,
      page,
      pageSize
    );
    return data;
  });
  return hours;
}

export async function createPointOfContactFacility(
  facilityId: string,
  body: CreateFacilityPointOfContact
): Promise<FacilityPointOfContact> {
  const response = await createFacilitiesApi().createPointOfContactFacilitiesFacilityIdPointOfContactsPost(
    facilityId,
    body
  );
  return response.data;
}

export async function updateFacilityPointOfContact(
  facilityId: string,
  contact: FacilityPointOfContact
): Promise<FacilityPointOfContact> {
  const {id, person_name, facility_role, is_default_for_facility} = contact;
  let {phone_number} = contact;
  phone_number = phone_number || undefined;
  const response =
    await createFacilitiesApi().updatePointOfContactFacilitiesFacilityIdPointOfContactsFacilityPointOfContactIdPut(
      id,
      facilityId,
      {
        person_name,
        facility_role,
        phone_number,
        is_default_for_facility
      }
    );
  return response.data;
}

export async function deleteFacilityPointOfContact(facilityId: string, contactId: string): Promise<void> {
  const api = createFacilitiesApi();
  await api.deleteFacilityPointOfContactFacilitiesFacilityIdPointOfContactsFacilityPointOfContactIdDelete(
    contactId,
    facilityId
  );
}

export function bulkUpdateHoursOfOperation(facilityId: string, body: BulkUpdateHoursOfOperationRequest) {
  return createFacilitiesApi().bulkUpdateHoursOfOperationFacilitiesFacilityIdHoursOfOperationPatch(facilityId, body);
}

export async function getFacilityPointsOfContact(facilityId: string): Promise<FacilityPointOfContact[]> {
  const api = createFacilitiesApi();
  const pointsOfContact = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await api.listFacilityPointOfContactsFacilitiesFacilityIdPointOfContactsGet(
      facilityId,
      page,
      pageSize
    );
    return data;
  });
  return pointsOfContact;
}

/**
 * Because our backend performs strict validation, and because `null` is not
 * valid for the generated SDK type, we need a hacky repair job on LoadType
 * request bodies before we can send them to the back end.
 */
function cleanupLoadType<T extends CreateLoadType | UpdateLoadType>(body: T): T {
  // Firstly, all day appointment load types MUST have nulls for duration fields.
  if (body.all_day_appointment) {
    // This cast IS ABSOLUTELY NECESSARY so that we can assign `null` to the
    // fields that the back end requires it to be set.
    const repairable = body as unknown as Record<string, unknown>;
    repairable.load_unload_duration = null;
    repairable.appointment_duration = null;
  }
  // that's all for now.
  return body;
}

export function createFacilityLoadType(facilityId: string, body: CreateLoadType) {
  body = cleanupLoadType(body);
  return createFacilitiesApi().createFacilityLoadTypeFacilitiesFacilityIdLoadTypesPost(facilityId, body);
}

export async function getFacilityHolidays(facilityId: string) {
  const api = createFacilitiesApi();
  const holidays = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await api.listFacilityHolidaysFacilitiesFacilityIdHolidaysGet(facilityId, page, pageSize);
    return data;
  });
  return holidays;
}

export async function createFacilityHoliday(facilityId: string, body: CreateFacilityHoliday): Promise<FacilityHoliday> {
  const api = createFacilitiesApi();
  const response = await api.createFacilityHolidayFacilitiesFacilityIdHolidaysPost(facilityId, body);
  return response.data;
}

export async function updateFacilityHoliday(
  facilityId: string,
  holidayId: string,
  body: UpdateFacilityHoliday
): Promise<FacilityHoliday> {
  const api = createFacilitiesApi();
  const response = await api.updateFacilityHolidayFacilitiesFacilityIdHolidaysHolidayIdPut(holidayId, facilityId, body);
  return response.data;
}

export async function deleteFacilityHoliday(facilityId: string, holidayId: string): Promise<void> {
  const api = createFacilitiesApi();
  await api.deleteFacilityHolidayFacilitiesFacilityIdHolidaysHolidayIdDelete(holidayId, facilityId);
}

export async function getAvailability(facilityId: string, body: AvailabilityRequest): Promise<Availability> {
  const client = createFacilitiesApi();

  // data returned from these APIs could be string | null | undefined but the tempus API only allows
  // undefined or string :'(
  const {data} = await client.listFacilityAvailabilityFacilitiesFacilityIdAvailabilityPost(facilityId, body);

  return data;
}

export function createDock(facilityId: string, body: CreateFacilityDock) {
  return createFacilitiesApi().createDockFacilitiesFacilityIdDocksPost(facilityId, body);
}

export function createDockRules(facilityId: string, dockId: string, body: CreateFacilityDockAppointmentRule) {
  return createFacilitiesApi().createFacilityDockAppointmentRuleFacilitiesFacilityIdDocksDockIdRulesPost(
    facilityId,
    dockId,
    body
  );
}

export function getStandardHolidays(startYear: number, endYear: number) {
  return createFacilitiesApi().retrieveStandardHolidayScheduleFacilitiesStandardHolidaysScheduleGet(startYear, endYear);
}

export function createHolidayFacility(facilityId: string, body: CreateFacilityHoliday) {
  return createFacilitiesApi().createFacilityHolidayFacilitiesFacilityIdHolidaysPost(facilityId, body);
}

export function createFacilityDocument(facilityId: string, file: File, documentType?: string, description?: string) {
  return createFacilitiesApi().createFacilityDocumentFacilitiesFacilityIdDocumentsPost(
    facilityId,
    file,
    documentType,
    description
  );
}

export async function getFacilityLoadTypes(facilityId: string) {
  let loadTypes = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await createFacilitiesApi().listLoadTypesFacilitiesFacilityIdLoadTypesGet(
      facilityId,
      page,
      pageSize
    );
    return data;
  });
  if (loadTypes) {
    loadTypes = loadTypes.map((result) => {
      return {
        ...result,
        stackable: result.stackable ?? undefined,
        is_hazmat: result?.is_hazmat ?? undefined
      };
    });
  }
  return loadTypes;
}

export function updateFacilityLoadType(loadTypeId: string, facilityId: string, updateLoadType: UpdateLoadType) {
  updateLoadType = cleanupLoadType(updateLoadType);
  return api.updateLoadTypeFacilitiesFacilityIdLoadTypesLoadTypeIdPut(loadTypeId, facilityId, updateLoadType);
}

export function deleteFacilityLoadType(loadTypeId: string, facilityId: string) {
  return api.deleteLoadTypeFacilitiesFacilityIdLoadTypesLoadTypeIdDelete(loadTypeId, facilityId);
}

export async function getFacilityDockRules(facilityId: string, dockId: string) {
  const dockRules = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await api.listFacilityDockAppointmentRulesFacilitiesFacilityIdDocksDockIdRulesGet(
      facilityId,
      dockId,
      page,
      pageSize
    );
    return data;
  });
  return dockRules;
}

export function updateFacilityDockName(dockId: string, facilityId: string, updateFacilityDock: UpdateFacilityDock) {
  return api.updateDockFacilitiesFacilityIdDocksDockIdPut(dockId, facilityId, updateFacilityDock);
}

export function deleteFacilityDock(dockId: string, facilityId: string) {
  return api.deleteDockFacilitiesFacilityIdDocksDockIdDelete(dockId, facilityId);
}

export function updateFacilityDockRule(
  ruleId: string,
  facilityId: string,
  dockId: string,
  updateFacilityDockAppointmentRule: UpdateFacilityDockAppointmentRule
) {
  return api.updateFacilityDockAppointmentRuleFacilitiesFacilityIdDocksDockIdRulesRuleIdPut(
    ruleId,
    facilityId,
    dockId,
    updateFacilityDockAppointmentRule
  );
}

export function deleteFacilityDockRule(ruleId: string, facilityId: string, dockId: string) {
  return api.deleteFacilityDockAppointmentRuleFacilitiesFacilityIdDocksDockIdRulesRuleIdDelete(
    ruleId,
    facilityId,
    dockId
  );
}

//FI-3057 This workaround will be replace once the API is ready to use planning_window
interface PlanningWindowResponse {
  planning_window: {
    start: string;
    end: string;
  };
}

export async function parseStopPlannedWindowCustomField(stopCustom: StopCustomData, stopPlanned: string) {
  if (!basePath) return;

  const accessToken: string = (await getAccessToken()) as string;

  const body = {
    stop_custom_data: stopCustom,
    stop_planned_date: stopPlanned
  };

  const res = await axios.post<PlanningWindowResponse>(
    `${basePath}/facilities/shipwell-ui/stop-planned-window-custom-field`,
    body,
    {
      headers: {
        authorization: accessToken,
        'Content-Type': 'application/json'
      }
    }
  );

  return res.data;
}

export async function getFacilityDocuments(facilityId: string) {
  const facilityDocuments = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await api.listFacilityFacilityDocumentsFacilitiesFacilityIdDocumentsGet(facilityId, page, pageSize);
    return data;
  });
  return facilityDocuments;
}

export function deleteFacilityDocument(documentId: string, facilityId: string) {
  return api.deleteFacilityDocumentFacilitiesFacilityIdDocumentsFacilityDocumentIdDelete(documentId, facilityId);
}
/**
 * Fetches all the load IDs, docks, and dock rules that at a facility that may match the required information
 * to accommodate appointments for a shipwell shipment.
 * @param {string} facilityId
 * @param {string} shipmentId
 * @param {string} stopId
 * @returns {Promise<import('@shipwell/tempus-sdk').ShipmentMatchResults>}
 * @throws {import('axios').AxiosError<import('@shipwell/tempus-sdk').ShipwellApiErrorResponse>}
 */
export async function getShipmentMatchResults(
  facilityId: string,
  shipmentId: string,
  stopId: string
): Promise<ShipmentMatchResults> {
  const {data} = await api.matchShipmentFacilitiesFacilityIdShipmentMatchingGet(facilityId, shipmentId, stopId);
  return data;
}

export async function deleteFacilityById(facilityId: string) {
  return api.deleteFacilityFacilitiesFacilityIdDelete(facilityId);
}

export function getFacilitiesShipmentMatching({
  facilityId,
  shipmentId,
  stopId,
  include
}: {
  facilityId: string;
  shipmentId: string;
  stopId: string;
  include?: ShipmentMatchingIncludeParams[];
}) {
  return api.matchShipmentFacilitiesFacilityIdShipmentMatchingGet(facilityId, shipmentId, stopId, include);
}
