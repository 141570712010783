import React, {useState, useEffect} from 'react';
import {AppointmentStatusEnum, FacilityAppointmentType} from '@shipwell/tempus-sdk';
import {AppointmentEntry, Filters} from 'App/data-hooks/appointments/types';
import {RegularSelect} from '@shipwell/shipwell-ui';
import {FormatOptionLabel} from './components';

interface Option {
  label: string;
  value: string;
  appointment: AppointmentEntry;
  filters: Filters;
}

interface DockSchedulingActions {
  openSidePanel: (appointment: AppointmentEntry | null) => void;
  openModalWithDetails: (appointment: AppointmentEntry) => void;
  openDetailsPage?: (appointment: AppointmentEntry) => void;
}

interface DockSchedulingSearchProps {
  handleInputChange: (value: string) => void;
  appointments: AppointmentEntry[];
  actions: DockSchedulingActions;
  filters: Filters;
}

const filterAppointments = (appointments: AppointmentEntry[], filters: Filters): Option[] => {
  return appointments.map((appointment) => ({
    label: appointment.carrierName || '',
    value: appointment.id,
    appointment,
    filters
  }));
};

const customStyles = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menu: (provided: any) => ({
    ...provided,
    width: '489px'
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: (provided: any) => ({
    ...provided,
    width: '260px'
  })
};

export const DockSchedulingSearch: React.FC<DockSchedulingSearchProps> = ({
  handleInputChange,
  appointments,
  actions,
  filters
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOptions] = useState<Option | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [, setInputValue] = useState('');

  useEffect(() => {
    setOptions(filterAppointments(appointments, filters));
  }, [appointments, filters]);

  const handleInputChangeLocal = (value: string) => {
    setInputValue(value);
    handleInputChange(value);
    setMenuIsOpen(value.trim().length > 0);
  };

  const handleChange = (newValue: Option | null) => {
    if (!newValue) return;
    setSelectedOptions(newValue);
    const {appointment} = newValue;
    handleOptionSelect(appointment);
    setMenuIsOpen(false);
  };

  const handleOptionSelect = (appointment: AppointmentEntry) => {
    if (appointment.appointmentType === FacilityAppointmentType.FirstComeFirstServe) {
      actions.openSidePanel(appointment);
      return;
    }

    switch (appointment.status) {
      case AppointmentStatusEnum.Scheduled:
        actions.openSidePanel(appointment);
        break;

      case AppointmentStatusEnum.Unscheduled:
        actions.openModalWithDetails(appointment);
        break;

      default:
        actions.openSidePanel(appointment);
    }
  };

  const handleBlur = () => {
    setMenuIsOpen(false);
  };
  return (
    <RegularSelect
      options={options}
      formatOptionLabel={FormatOptionLabel}
      label=""
      placeholder={!selectedOption ? 'Search Calendar' : ''}
      onInputChange={handleInputChangeLocal}
      onChange={handleChange}
      filterOption={() => true}
      isClearable={true}
      noOptionsMessage={() => 'No Search Results'}
      styles={customStyles}
      menuIsOpen={menuIsOpen}
      onBlur={handleBlur}
    />
  );
};
