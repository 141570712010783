import {Button} from '@shipwell/shipwell-ui';
import NoFilterResultsView from 'App/components/Table/components/NoFilterResultsSprinkles';
import {ReactNode} from 'react';
import {DashboardColumnsSchema} from '../commons/dashboardColumnSchema';
import {DashboardCustomFilters} from '../commons/dashboardCustomFilters';
import {DashboardSearchParams} from '../commons/dashboardSearchParams';
import {DashboardSearchResult} from '../commons/dashboardSearchResult';
import {DashboardLoadingRibbon} from './dashboadLoadingRibbon/dashboardLoadingRibbon';
import {DashboardProvider} from './dashboardContext/provider';
import {DashboardFooter} from './dashboardFooter/dashboardFooter';
import {DashboardHeader} from './dashboardHeader/dashboardHeader';
import {DashboardTable} from './dashboardTable/dashboardTable';
import {useParams} from './useParams/useParams';
import {useSearchService} from './useSearchService/useSearchService';

export interface SimpleDashboardProps<TItem, TExtraParams = never> {
  id?: string;
  title: string;
  fullWidth?: boolean;
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  footerLeft?: ReactNode;
  showSearchTerm?: boolean;
  columnsSchema: DashboardColumnsSchema<TItem>;
  customFilters?: DashboardCustomFilters<TExtraParams>;
  children?: ReactNode;
  containerClassName?: string;
  searchFn: (params: DashboardSearchParams<TExtraParams>) => Promise<DashboardSearchResult<TItem>>;
}

export function SimpleDashboard<TItem, TExtraParams>(props: SimpleDashboardProps<TItem, TExtraParams>) {
  const params = useParams({
    columnsSchema: props.columnsSchema,
    customFilters: props.customFilters
  });

  const service = useSearchService({
    params: params.params,
    searchFn: props.searchFn
  });

  const showEmptyState = !service.isLoading && !service.data?.items.length;

  return (
    <DashboardProvider refresh={service.refetch}>
      <div className={props.containerClassName || 'h-[calc(100vh_-_64px)] flex flex-col'}>
        <div>
          <DashboardHeader
            title={props.title}
            leftElements={props.headerLeft}
            rightElements={props.headerRight}
            showSearchTerm={props.showSearchTerm}
            searchTerm={params.params?.base.searchTerm}
            onSearchTermChange={(value) => params.setBaseParam('searchTerm', value)}
          >
            {props.customFilters?.component({
              values: params.params.extra as TExtraParams,
              onChange: params.setExtraParams
            })}
          </DashboardHeader>

          <DashboardLoadingRibbon isLoading={service.isLoading} />
        </div>

        <div className="flex-1 overflow-auto">
          <DashboardTable
            data={service.data?.items}
            columnsSchema={props.columnsSchema}
            fullWidth={props.fullWidth}
            sortBy={params.params.base.orderBy}
            onSortBy={(orderBy) => params.setBaseParam('orderBy', orderBy)}
          />

          {showEmptyState && (
            <NoFilterResultsView>
              <Button onClick={params.resetParams}>Clear Filters</Button>
            </NoFilterResultsView>
          )}
        </div>

        {service.data?.pagination && (
          <div>
            <DashboardFooter
              page={service.data?.pagination?.page}
              perPage={service.data?.pagination?.pageSize}
              totalItems={service.data?.pagination?.totalItems}
              leftElements={props.footerLeft}
              onPageChange={(page) => params.setBaseParam('page', page)}
              onPerPageChange={(pageSize) => params.setBaseParam('pageSize', pageSize)}
            />
          </div>
        )}
      </div>
      {props.children}
    </DashboardProvider>
  );
}
