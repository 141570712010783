import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {compose} from 'recompose';
import LoginErrorPage from 'App/containers/authentication/components/loginErrorPage';
import AccessDeniedError from 'App/containers/authentication/components/AccessDeniedError';
import withCompanyBrand from 'App/components/withCompanyBrand';

const ConfigurationErrorPageWrapper = ({router, company, location}) => {
  if (location.query.error === 'access_denied') {
    return <AccessDeniedError />;
  }

  return (
    <LoginErrorPage
      companyName={company}
      callback={() => router.push('/')}
      message="We had trouble connecting you through our secure login portal. Please click the button below to try again. If the issue persists, contact your administrator."
      buttonLabel="Try Again"
    />
  );
};

ConfigurationErrorPageWrapper.propTypes = {
  router: PropTypes.object,
  company: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({
      clientId: PropTypes.string,
      connection: PropTypes.string,
      lang: PropTypes.string,
      error: PropTypes.string,
      errorDescription: PropTypes.string,
      trackingId: PropTypes.string,
      logUrl: PropTypes.string
    }),
    origin: PropTypes.string
  })
};

export default compose(withCompanyBrand, withRouter)(ConfigurationErrorPageWrapper);
