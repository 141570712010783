import {ELDDeviceLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {PageSizesValue} from 'App/components/dashboards/commons/constants';
import {useCallback, useMemo, useState} from 'react';
import {exportToCSV} from './exportToCsv';

export const useViewShipmentMovementLogModal = (breadcrumbs: ELDDeviceLocation[]) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<PageSizesValue>(20);
  const totalItems = useMemo(() => breadcrumbs.length, [breadcrumbs]);

  const paginatedBreadcrumbs = useMemo(() => {
    const start = (page - 1) * perPage;
    const end = start + perPage;
    return breadcrumbs.slice(start, end);
  }, [breadcrumbs, page, perPage]);

  const onPerPageChange = useCallback((perPage: PageSizesValue) => {
    setPerPage(perPage);
    setPage(1);
  }, []);

  const handleExportToCsv = useCallback(() => exportToCSV(breadcrumbs), [breadcrumbs]);

  return {
    handleExportToCsv,
    onPageChange: setPage,
    onPerPageChange,
    page,
    perPage,
    totalItems,
    paginatedBreadcrumbs
  };
};
