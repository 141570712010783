import {useState} from 'react';
import {Card, CollapsibleCardContent, Button} from '@shipwell/shipwell-ui';
import {InstantRatesModal} from 'App/containers/InstantRatesV2/components/InstantRatesModal';
import InstantRatesTableV2 from 'App/containers/InstantRatesV2/components/InstantRatesTable';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {WithRouterProps, withRouter} from 'react-router';

export interface NewShipmentFormValues {
  transportation_mode?: string;
  equipment?: unknown;
  line_items?: unknown[];
  stops?: unknown[];
}

interface Props extends Partial<WithRouterProps> {
  isNewShipmentForm?: boolean;
  newShipmentFormValues?: NewShipmentFormValues;
  shipment: Shipment;
}

const CardTitle = ({onClick}: {onClick: () => void}) => {
  return (
    <div className="flex justify-between w-full items-center">
      <span>Instant Rates</span>
      <Button onClick={onClick} variant="tertiary" isCompact>
        <span className="font-normal">Edit Quote</span>
      </Button>
    </div>
  );
};

const RatesCard = ({isNewShipmentForm, newShipmentFormValues, shipment, router}: Props) => {
  const [showInstantRatesModal, setInstantRatesModal] = useState(false);

  return (
    <>
      <Card
        draggableProvided={false}
        title={<CardTitle onClick={() => router?.push(`/shipments/${shipment?.id}/edit`)} />}
        bodyClassName="p-0"
        isCollapsible
      >
        <CollapsibleCardContent>
          <InstantRatesTableV2
            shipment={shipment}
            viewAllColumns={false}
            isNewShipmentForm={isNewShipmentForm}
            expandedColumnSet
            newShipmentInformation={newShipmentFormValues}
          />
        </CollapsibleCardContent>
      </Card>

      <InstantRatesModal
        shipment={shipment}
        show={showInstantRatesModal}
        onClose={() => setInstantRatesModal(false)}
        newShipmentInformation={newShipmentFormValues}
      />
    </>
  );
};

export const DigitalFreightMarketplaceRatesCardV2 = withRouter(RatesCard);
