import {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputField from 'App/formComponents/fields/input';
import CustomerSearch from 'App/formComponents/fields/customerSearch';
import renderField from 'App/formComponents/renderField';
import ShipmentModeEquipmentFields from 'App/formComponents/formSections/shipmentModeEquipmentFields';
import {validateTemperature, reeferTypes} from 'App/utils/globals';
import {getShipmentTemperatureUnit} from 'App/utils/getShipmentTemperatureUnit';
import './styles.scss';

/**
 * Quick Quote - Shipment details header
 */
class ShipmentDetailsHeader extends Component {
  render() {
    const {showCustomerField, company, equipmentType, formValues} = this.props;
    const temperatureUnit = getShipmentTemperatureUnit(formValues);

    return (
      <Paper square elevation={1} className="quick-quote-header-container quick-quote-header">
        <Grid container item spacing={2}>
          {showCustomerField && (
            <Grid item xs={12} md={3}>
              <Field
                req
                label="Customer Account"
                name="customer"
                isDisabled={formValues && Boolean(formValues.id)}
                component={CustomerSearch}
                brokerageId={company.brokerage.id}
                validate={(value) => {
                  if (!value) {
                    return 'Enter a customer account';
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <Field label="Customer Reference #" name="customer_reference_number" component={InputField} />
          </Grid>
        </Grid>
        <ShipmentModeEquipmentFields form="newQuoteForm" slimForm />
        {equipmentType.find((el) => reeferTypes.includes(el.id)) && (
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                req
                label={<>Minimum Temperature (&deg;{temperatureUnit})</>}
                name="temperature_lower_limit"
                placeholder={temperatureUnit === 'C' ? 'e.g., 0' : 'e.g., 35'}
                component={renderField}
                format={(value) => validateTemperature(value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                req
                label={<>Maximum Temperature (&deg;{temperatureUnit})</>}
                name="temperature_upper_limit"
                placeholder={temperatureUnit === 'C' ? 'e.g., 10' : 'e.g., 50'}
                component={renderField}
                format={(value) => validateTemperature(value)}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
}

export default connect((state) => ({
  company: state.auth.company,
  shipmentModes: state.shipments.shipmentModes,
  equipmentTypes: state.shipments.equipmentTypes,
  unitPreferences: state.users.unitPreferences
}))(ShipmentDetailsHeader);
