import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {Quote} from '@shipwell/backend-core-singlerequestparam-sdk';
import {createCarrierConnectionQuote} from 'App/api/quoting/typed';
import {Rate} from 'src/@types/quotingTypes';
import {CREATE_CARRIER_CONNECTION_QUOTE_MUTATION_KEY} from 'App/data-hooks/mutationKeys';

export interface CarrierConnectionQuote {
  rate: Rate;
  legacyQuote: Quote;
}
/**
 * Creates a rate appending to a request for rate in backend core. This function
 * is meant to stitch together genesis and backend-core which don't
 * communicate rates.
 */
export const useCreateCarrierConnectionQuoteMutation = (
  options?: Omit<UseMutationOptions<CarrierConnectionQuote, AxiosError, Rate, unknown | Promise<unknown>>, 'mutationFn'>
) =>
  useMutation(
    async (rate: Rate) => {
      // if rating a legacy RFQ rate just return the quote
      if (!rate.isGenesisRate) return {rate, legacyQuote: rate.legacyQuote};
      // creating the carrier connection quote is required to continue!
      if (!rate.legacyRfqId) {
        throw new Error('Rate dispatch requires an RFQ.');
      }
      const legacyQuote = await createCarrierConnectionQuote(rate);

      return {
        rate,
        legacyQuote
      };
    },
    {...options, mutationKey: [CREATE_CARRIER_CONNECTION_QUOTE_MUTATION_KEY]}
  );
