import _ from 'lodash';
import moment from 'moment-timezone';
import {ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  REQ_DATA,
  FETCH_SHIPMENTS_AND_PERSIST,
  FETCH_SHIPMENTS,
  ALERT_ERROR,
  RESET_SHIPMENTS,
  FETCH_DASHBOARD,
  FETCH_UNPAID_SHIPMENTS,
  SELECT_SHIPMENT,
  GET_SHIPPER_CREDIT,
  GET_SHIPPER_DASHBOARDS,
  DISABLE_SIGNUP_SUBMIT,
  ENABLE_SIGNUP_SUBMIT,
  FETCH_SHIPPER_LINE_ITEMS,
  POST_SHIPPER_LINE_ITEM,
  PUT_SHIPPER_LINE_ITEM,
  GET_SHIPPER_CARRIERS,
  FETCH_SHIPMENT_OFFERS,
  GET_SHIPMENT_POST_OFFERS,
  CLEAR_SHIPMENT_MESSAGES,
  DELETE_SHIPMENT_QUOTES,
  DELETE_OFFERS,
  GO_TO_STOPS,
  TRIGGER_REBOOK,
  DISPATCH_SHIPMENT_QUOTE,
  FETCH_SHIPPER_FTL_CARRIERS,
  CREATE_SHIPMENT,
  EDIT_SHIPMENT,
  CREATE_RFQ,
  SELECT_RFQ,
  SELECT_BROKER_RFQ,
  SELECT_QUOTE,
  CREATE_QUOTE,
  EDIT_QUOTE,
  SELECT_CARRIER_RATE,
  CLEAR_SELECT_CARRIER_RATE,
  AWARD_QUOTE,
  CANCEL_ONDEMAND,
  FETCH_ACCESSORIAL_CODES,
  GET_REP_ROLES,
  GET_NMFC_CODES,
  GET_HAZMAT_CODES,
  GET_PACKAGE_TYPES,
  GET_EQUIPMENT_TYPES,
  GET_APPOINTMENT_TYPES,
  GET_SERVICE_LEVELS,
  GET_SHIPMENT_MODES,
  GET_CHARGE_CATEGORIES,
  POPULATE_RECIPIENT_EMAILS,
  SELECT_SHIPMENT_FOR_FORM,
  SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS,
  CLEAR_SELECTED_SHIPMENT,
  CLEAR_SELECTED_QUOTE,
  SHIPMENTS_ERROR,
  RESET_ERROR,
  SET_HISTORY_FILTER,
  SUCCESSFUL_DISPATCH,
  INITIAL_QUOTE_LOADING,
  INITIAL_QUOTE_VALUES,
  SET_CLONE_TRIGGER,
  INITIAL_SHIPMENT_VALUES,
  TRIGGER_MARKETPLACE_REDIRECT,
  FETCH_TAGS,
  FETCH_TAG,
  CLEAR_TAG
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getSubdomain} from 'App/utils/location';
import {getShipwellApi} from 'App/api/config';
import {fetchShippingDashboard, getShipments as getShipmentsTyped} from 'App/api/shipment/typed';
import {validateLocation} from 'App/api/locations/typed';
import {validateAddressPromise} from 'App/api/locations';

//ApiKeyAuth.apiKey = '';
// Uncomment the following line to set a prefix for the API key, e.g. "Token" (defaults to null)
//ApiKeyAuth.apiKeyPrefix['Authorization'] = 'Token';

// starts the Loading... spinner
function requestData() {
  return {type: REQ_DATA};
}

/*
========================================================================
    --- * *  Fetch ALL Shipments  * * ---
* List all Shipments ever requested or run by this Shipper\n
* @param {Integer} shipperId
* @param {String} authorization Basic Authorization Header of &#39;
Token {token}&#39;\n
* @param {module:api/shipwellApi~shippersShipperIdShipmentsOnDemandGetCallback}
 callback The callback function, accepting three arguments: error, data, response
* data is of type: {Array.<module:model/Shipment>}

========================================================================
*/

export function getShippingDashboard(opts, count, persistExistingShipments) {
  if (opts.customer) {
    //hardcoding this delete here because we are using the customerId contains filter always
    delete opts.customer;
  }
  return async (dispatch) => {
    if (!persistExistingShipments) {
      dispatch(requestData());
    }
    try {
      const response = await fetchShippingDashboard(opts);
      if (persistExistingShipments) {
        //infinite scroll -- persist existing data
        dispatch({
          type: FETCH_SHIPMENTS_AND_PERSIST,
          payload: response.data
        });
      } else {
        dispatch({
          type: FETCH_SHIPMENTS,
          payload: response.data,
          meta: {
            key: count
          }
        });
      }
      return {status: 200};
    } catch (error) {
      dispatch({
        type: ALERT_ERROR,
        payload: error?.error_description
      });
      return {status: 400};
    }
  };
}

export function getShipments(opts = {}, count, persistExistingShipments) {
  if (opts.customer) {
    //hardcoding this delete here because we are using the customerId contains filter always
    delete opts.customer;
  }
  return async (dispatch) => {
    if (!persistExistingShipments) {
      dispatch(requestData());
    }
    try {
      const response = await getShipmentsTyped(opts);
      if (response) {
        if (persistExistingShipments) {
          //infinite scroll -- persist existing data
          dispatch({
            type: FETCH_SHIPMENTS_AND_PERSIST,
            payload: response
          });
        } else {
          dispatch({
            type: FETCH_SHIPMENTS,
            payload: response,
            meta: {
              key: count
            }
          });
        }
        return {status: 200};
      }
    } catch (error) {
      dispatch({
        type: ALERT_ERROR,
        payload: error.response?.data?.error_description || 'There was an error retrieving shipments.'
      });
      return {status: 400};
    }
  };
}

// clear fetch shipments
export function resetShipments() {
  return (dispatch) => {
    dispatch({type: RESET_SHIPMENTS});
  };
}

// get aggregated shipment data
export function getDashboard(companyId) {
  return (dispatch) => {
    dispatch(requestData());
    return getDashboardPromise(companyId)
      .then((response) => {
        dispatch({type: FETCH_DASHBOARD, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400};
      });
  };
}

async function getDashboardPromise(companyId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);
  return new Promise(function (resolve, reject) {
    shipwellApi.companiesCompanyIdDashboardGet(companyId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//get unpaid shipments

export function fetchUnpaidShipments() {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  const shipperId = localStorage.getItem('oovlxkkp');

  return (dispatch) => {
    // "Loading..."
    dispatch(requestData());

    return fetchUnpaidShipmentsPromise(shipperId, authorization)
      .then((response) => {
        dispatch({type: FETCH_UNPAID_SHIPMENTS, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error retrieving shipments.'
        });
      });
  };
}

async function fetchUnpaidShipmentsPromise(shipperId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipperIdShipmentsUnpaidGet(shipperId, authorization, function (err, data, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response.body);
      }
    });
  });
}

//get shipments by date params

export function fetchShipmentsByDate(opts) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  const shipperId = localStorage.getItem('oovlxkkp');

  return (dispatch) => {
    // "Loading..."
    dispatch(requestData());

    return fetchShipmentsByDatePromise(shipperId, authorization, opts)
      .then((response) => {
        dispatch({type: FETCH_UNPAID_SHIPMENTS, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error retrieving shipments.'
        });
      });
  };
}

async function fetchShipmentsByDatePromise(shipperId, authorization, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipperIdShipmentsPaymentsGet(shipperId, authorization, opts, function (err, data, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response.body);
      }
    });
  });
}

//get tracking for outside user
export function fetchExternalShipment(shipmentId, key) {
  return (dispatch) => {
    return fetchExternalShipmentPromise(shipmentId, key)
      .then((response) => {
        dispatch({type: SELECT_SHIPMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function fetchExternalShipmentPromise(shipmentId, key) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsExternalShipmentIdGet(shipmentId, key, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

//get credit outstanding for shipper
export function getCreditOutstanding() {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  const shipperId = localStorage.getItem('oovlxkkp');

  return (dispatch) => {
    return getCreditOutstandingPromise(shipperId, authorization)
      .then((response) => {
        dispatch({type: GET_SHIPPER_CREDIT, payload: response});
      })
      .catch((response) => {
        //dispatch({type: types.ALERT_ERROR, payload:"There was an error getting credit information."});
      });
  };
}

async function getCreditOutstandingPromise(shipperId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipperIdCreditOutstandingGet(shipperId, authorization, function (err, data, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response.body);
      }
    });
  });
}

//get list of looker dashboards for a shipper
export function getShipperDashboards() {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  const shipperId = localStorage.getItem('oovlxkkp');

  return (dispatch) => {
    return getShipperDashboardsPromise(authorization)
      .then((response) => {
        dispatch({type: GET_SHIPPER_DASHBOARDS, payload: response});
      })
      .catch((response) => {});
  };
}

async function getShipperDashboardsPromise(authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.lookerShippersDashboardsGet(authorization, function (err, data, response) {
      if (err) {
        if (response && response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('Dashboards not found.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}

//get a looker URL for a dashboard for a shipper
export function getShipperDashboardURL(dashboardID) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  const shipperId = localStorage.getItem('oovlxkkp');

  return (dispatch) => {
    return getShipperDashboardURLPromise(dashboardID, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function getShipperDashboardURLPromise(dashboardID, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.lookerShippersDashboardsDashboardIdGet(dashboardID, authorization, function (err, data, response) {
      if (err) {
        if (response && response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('Dashboard URL not found.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}

//VALIDATE AN ADDRESS

export function validateAddress(address) {
  return (dispatch) => {
    dispatch({type: DISABLE_SIGNUP_SUBMIT});
    return validateLocation(address)
      .then((axiosResponse) => {
        dispatch({type: ENABLE_SIGNUP_SUBMIT});
        return {status: 200, details: axiosResponse.data};
      })
      .catch((axiosError) => {
        return {status: 400, details: axiosError.response?.data};
      });
  };
}

//look up a lat/lng by a zip code
export function getLatLngByPostalCode(postal_code) {
  return (dispatch) => {
    return validateAddressPromise(postal_code)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

//INVOICE LINE ITEM MODIFICATION - FTL

// post a new invoice line item for a shipment
export function postShipmentInvoiceLineItem(shipmentId, body) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return postShipmentInvoiceLineItemPromise(shipmentId, body, authorization)
      .then((response) => {
        //dispatch({type: types.POST_SHIPMENT_LINE_ITEM, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function postShipmentInvoiceLineItemPromise(shipmentId, body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdInvoiceLineItemsPost(
      shipmentId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('There was an unexpected error creating this invoice line item.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

// update an invoice line item for a shipment
export function updateShipmentInvoiceLineItem(shipmentId, lineItemId, body) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return updateShipmentInvoiceLineItemPromise(shipmentId, lineItemId, body, authorization)
      .then((response) => {
        //dispatch({type: types.POST_SHIPMENT_LINE_ITEM, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function updateShipmentInvoiceLineItemPromise(shipmentId, lineItemId, body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdInvoiceLineItemsShipmentInvoiceLineItemIdPut(
      shipmentId,
      lineItemId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('There was an unexpected error updating this invoice line item.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

// delete an invoice line item for a shipment
export function deleteShipmentInvoiceLineItem(shipmentId, lineItemId) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return deleteShipmentInvoiceLineItemPromise(shipmentId, lineItemId, authorization)
      .then((response) => {
        //dispatch({type: types.POST_SHIPMENT_LINE_ITEM, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function deleteShipmentInvoiceLineItemPromise(shipmentId, lineItemId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdInvoiceLineItemsShipmentInvoiceLineItemIdDelete(
      shipmentId,
      lineItemId,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('There was an unexpected error deleting this invoice line item.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

//get all saved line items for a shipper
export function getShipperLineItems() {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return getShipperLineItemsPromise(authorization)
      .then((response) => {
        dispatch({type: FETCH_SHIPPER_LINE_ITEMS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function getShipperLineItemsPromise(authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersLineItemsGet(authorization, function (err, data, response) {
      if (err) {
        if (response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error getting line items.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}

//save a line item for a shipper
export function postShipperLineItem(lineItem) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return postShipperLineItemPromise(lineItem, authorization)
      .then((response) => {
        dispatch({type: POST_SHIPPER_LINE_ITEM, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function postShipperLineItemPromise(lineItem, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersLineItemsPost(lineItem, authorization, function (err, data, response) {
      if (err) {
        if (response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error saving this line item.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}
// save a line item for a shipper
export function putShipperLineItem(lineItem) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  const id = lineItem.id;
  return (dispatch) => {
    return putShipperLineItemPromise(id, lineItem, authorization)
      .then((response) => {
        dispatch({type: PUT_SHIPPER_LINE_ITEM, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        dispatch({type: ALERT_ERROR, payload: response});
        return {status: 400, details: response};
      });
  };
}

async function putShipperLineItemPromise(id, lineItem, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersLineItemsLineItemIdPut(id, lineItem, authorization, function (err, data, response) {
      if (err) {
        if (response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error saving this line item.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}

//Add new LTL shipment line item
export function createShipmentLineItem(shipmentId, body) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return createShipmentLineItemPromise(shipmentId, body, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function createShipmentLineItemPromise(shipmentId, body, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdLineitemsPost(
      shipmentId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response && response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('Unable to create this line item.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

//UPDATE LTL Line Item
export function updateShipmentLineItem(shipmentId, lineItemId, body) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return updateShipmentLineItemPromise(shipmentId, lineItemId, body, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function updateShipmentLineItemPromise(shipmentId, lineItemId, body, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdLineitemsShipmentLineItemIdPut(
      shipmentId,
      lineItemId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response && response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('Unable to update this line item.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}
//UPDATE LTL Line Item
export function deleteShipmentLineItem(shipmentId, lineItemId) {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return deleteShipmentLineItemPromise(shipmentId, lineItemId, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function deleteShipmentLineItemPromise(shipmentId, lineItemId, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdLineitemsShipmentLineItemIdDelete(
      shipmentId,
      lineItemId,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response && response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('Unable to delete this line item.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

async function updateShipmentLocationPromise(shipmentId, body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdUpdateLocationPut(
      shipmentId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response && response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('Unable to update this shipment.');
          }
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

//Get all available carriers that could be used to manually set a shipment
export function getShipperCarriers() {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;
  return (dispatch) => {
    return getShipperCarriersPromise(authorization)
      .then((response) => {
        dispatch({type: GET_SHIPPER_CARRIERS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function getShipperCarriersPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersCarriersGet(authorization, function (err, data, response) {
      if (err) {
        if (response && response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('Unable to get carriers.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
   ------ * *  LIST SHIPMENT OFFER SUGGESTIONS (Details)  * * ------
     * List all offer suggestions for a given shipment
     * @param {Integer} shipmentId
     * @param {String} authorization Basic Authorization Header of &#39;Token {token}&#39;\n
     * @param {module:api/shipwellApi~shippersShipmentsShipmentIdOnDemandOfferSuggestionGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {Array.<module:model/OfferSuggestions>}
     */

async function listShipmentOfferSuggestionsPromise(shipmentId, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdOnDemandOfferSuggestionGet(
      shipmentId,
      authorization,
      function (err, data, response) {
        if (err) {
          return reject(err);
        }
        if (response.statusCode === 202) {
          // still waiting on quotes
          return resolve(null);
        }
        return resolve(response.body);
      }
    );
  });
}

export function listShipmentOfferSuggestions(shipmentId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return listShipmentOfferSuggestionsPromise(shipmentId, authorization)
      .then((response) => {
        dispatch({type: FETCH_SHIPMENT_OFFERS, payload: response});
      })
      .catch((response) => {
        // @hack - should maybe have a quoting-specific error instead of just the shipment error
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting offer suggestions.'
        });
        throw response;
      });
  };
}

/**
 * Post an offer for an FTL shipment
 * @param {Integer} shipmentId
 * @param {module:model/PostOfferForShipment} body
 * @param {String} authorization Basic Authorization Header of &#39;Token {token}&#39;\n
 * @param {module:api/shipwellApi~shippersShipmentsShipmentIdOnDemandPostOfferPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {module:model/Shipment}
 */

async function postShipmentOfferPromise(shipmentId, body, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdOnDemandPostOfferPost(
      shipmentId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          if (response.body && response.body.errors) {
            reject(response.body.errors[0]);
          } else {
            reject('There was an unexpected error posting this offer.');
          }
        } else {
          return resolve(response.body);
        }
      }
    );
  });
}

export function postShipmentOffer(shipmentId, body) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  return (dispatch) => {
    return postShipmentOfferPromise(shipmentId, body, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

/**
 * GET post offers for a shipment
 */

async function getPostShipmentOfferPromise(shipmentId, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdOnDemandPostOfferGet(
      shipmentId,
      authorization,
      function (err, data, response) {
        if (err) {
          return reject(err);
        }
        return resolve(response.body);
      }
    );
  });
}

export function getPostShipmentOffer(shipmentId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return getPostShipmentOfferPromise(shipmentId, authorization)
      .then((response) => {
        dispatch({type: GET_SHIPMENT_POST_OFFERS, payload: response});
      })
      .catch((response) => {
        dispatch(shipmentError(response));
        throw response;
      });
  };
}

export function clearShipmentMessages() {
  return (dispatch) => {
    return dispatch({type: CLEAR_SHIPMENT_MESSAGES, payload: []});
  };
}

//DELETE QUOTE FROM store - in cases where we need to return to form or re-search for quotes.

export function deleteShipmentQuotes(shipmentId) {
  return (dispatch) => {
    return dispatch({type: DELETE_SHIPMENT_QUOTES, payload: []});
  };
}

export function deleteOffers() {
  return (dispatch) => {
    return dispatch({type: DELETE_OFFERS, payload: []});
  };
}

export function triggerGoToStopsPage() {
  return (dispatch) => {
    return dispatch({type: GO_TO_STOPS, payload: true});
  };
}

export function removeGoToStopsPage() {
  return (dispatch) => {
    return dispatch({type: GO_TO_STOPS, payload: false});
  };
}

export function triggerRebook(shipment) {
  return (dispatch) => {
    return dispatch({type: TRIGGER_REBOOK, payload: shipment});
  };
}

export function removeTriggerRebook() {
  return (dispatch) => {
    return dispatch({type: TRIGGER_REBOOK, payload: null});
  };
}

/*
========================================================================
        ------ * *  DISPATCH SHIPMENT  * * ------
 * Award an LTL shipment to a specific LTLQuote and dispatch it
 * @param {Integer} shipmentId
 * @param {module:model/LTLDispatchRequestBody} body
 * @param {String} authorization Basic Authorization Header of &#39;Token {token}&#39;\n
 * @param {module:api/shipwellApi~shippersShipmentsShipmentIdDispatchPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {module:model/Shipment}

========================================================================
*/

async function dispatchShipmentPutPromise(shipmentId, quoteId, brokerMargin, cardToPay, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise((resolve, reject) => {
    let body = {};
    if (brokerMargin === null) {
      body = {quote_id: quoteId};
    } else {
      body = {quote_id: quoteId, custom_broker_margin_amount: brokerMargin};
    }
    if (cardToPay) {
      body.payment_source_id = cardToPay;
    }
    shipwellApi.shippersShipmentsShipmentIdDispatchPut(shipmentId, body, authorization, (err, data, response) => {
      if (err) {
        if (response.body && response.body.detail) {
          return reject(response.body.errors[0]);
        }
        return reject(
          'There was an unexpected error and dispatch failed with this carrier. Please try again and contact Shipwell if the problem persists.'
        );
      }
      return resolve(response.body);
    });
  });
}

export function dispatchShipmentForQuote(shipmentId, quoteId, brokerMargin, cardToPay) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return dispatchShipmentPutPromise(shipmentId, quoteId, brokerMargin, cardToPay, authorization)
      .then((response) => {
        dispatch({type: DISPATCH_SHIPMENT_QUOTE, payload: response});
      })
      .catch((err) => {
        dispatch(shipmentError(err));
        return {error: err};
      });
  };
}

//get all carriers that a lone wolf shipper would use to run an FTL shipment (pulls from brokerage carrier groups)
export function fetchShipperFTLCarriers() {
  var token = localStorage.getItem('dsiajksdjk');
  var authorization = 'Token ' + token;

  return (dispatch) => {
    return fetchShipperFTLCarriersPromise(authorization)
      .then((response) => {
        dispatch({type: FETCH_SHIPPER_FTL_CARRIERS, payload: response});
      })
      .catch((response) => {
        dispatch({type: ALERT_ERROR, payload: response});
      });
  };
}

async function fetchShipperFTLCarriersPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersCarriersGet(authorization, function (err, data, response) {
      if (err) {
        if (response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error getting carriers.');
        }
      } else {
        resolve(response.body);
      }
    });
  });
}

/*
========================================================================
          ------ * *  CREATE Shipment * * ------

     * Create a new Shipment
     * A Shipment is in the &#39;draft&#39; state by default, and can start with very little information if desired.
     * @param {module:model/CreateShipment} body
     * @param {module:api/shipwellApi~shipmentsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Shipment}
========================================================================
*/

export async function createShipmentPromise(shipmentObj, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsPost(shipmentObj, opts, function (err, data, response) {
      if (err) {
        const error = parseErrors(response);
        error.err = response?.body;
        reject(error);
      } else {
        resolve(response?.body);
      }
    });
  });
}

export function createShipment(shipmentObj, opts) {
  // metadata is required
  if (_.isEmpty(shipmentObj.metadata)) {
    shipmentObj.metadata = {};
  }
  if (_.isEmpty(shipmentObj.metadata.tags)) {
    shipmentObj.metadata.tags = [];
  }
  if (!shipmentObj.metadata.hasOwnProperty('open')) {
    shipmentObj.metadata.open = false;
  }
  if (_.isEmpty(shipmentObj.metadata.archived)) {
    shipmentObj.metadata.archived = false;
  }

  return (dispatch) => {
    // "Loading..."
    return createShipmentPromise(shipmentObj, opts)
      .then((response) => {
        dispatch({type: CREATE_SHIPMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
/*
========================================================================
          ------ * *  UPDATE Shipment * * ------

    * Update a Shipment
     * After a successful update, the &#39;stops&#39; and &#39;line_items&#39; of the Shipment will be exactly the same as those provided in the request. This means that if existing Stops or ShipmentLineItems were left out of their arrays, those objects would be deleted. Providing existing objects in those arrays with their IDs will update those objects instead of creating or deleting them. Providing objects in the arrays that do not have IDs will create new objects. If you omit the &#39;stops&#39; or &#39;line_items&#39; arrays from the request body, no changes will be made to those related object sets.
     * @param {String} shipmentId The id of the shipment
     * @param {module:model/CreateShipment} body
     * @param {module:api/shipwellApi~shipmentsShipmentIdPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Shipment}
========================================================================
*/

export async function editShipmentPromise(shipmentId, shipmentObj, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdPut(shipmentId, shipmentObj, opts, function (err, data, response) {
      if (err) {
        const error = parseErrors(response);
        error.err = response?.body;
        reject(error);
      } else {
        resolve(response?.body);
      }
    });
  });
}

export function editShipment(shipmentId, shipmentObj, opts) {
  opts = opts || {};
  if (_.isEmpty(shipmentObj.metadata)) {
    shipmentObj.metadata = {};
  }
  if (!shipmentObj.metadata.tags) {
    shipmentObj.metadata.tags = [];
  }
  if (!shipmentObj.metadata.open) {
    shipmentObj.metadata.open = false;
  }
  if (!shipmentObj.metadata.archived) {
    shipmentObj.metadata.archived = false;
  }
  return (dispatch) => {
    // "Loading..."
    dispatch(requestData());

    return editShipmentPromise(shipmentId, shipmentObj, opts)
      .then((response) => {
        dispatch({type: EDIT_SHIPMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  GET Shipment * * ------

    **
     * Obtain information about a Shipment
     * @param {String} shipmentId The id of the shipment
     * @param {module:api/shipwellApi~shipmentsShipmentIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Shipment}
     *
========================================================================
*/

export async function getShipmentDetailsPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdGet(shipmentId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getShipmentDetails(shipmentId, opts, preventAction) {
  opts = opts || {};
  return (dispatch) => {
    // "Loading..."
    return getShipmentDetailsPromise(shipmentId, opts)
      .then((response) => {
        if (preventAction) {
          //in shipment multiplier, we don't want to set the SELECTED SHIPMENT value in the store
          return {status: 200, details: response};
        }
        dispatch({type: SELECT_SHIPMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  CREATE RFQ * * ------
     * Create a new RFQ, kicking off autoquoting if specified
     * An RFQ is a Request For Quote. All quotes will be created under an RFQ.
     * @param {module:model/RFQ} body
     * @param {module:api/shipwellApi~quotingRfqsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RFQ}

========================================================================
*/

export async function createRFQPromise(rfqObj, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingRfqsPost(rfqObj, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function createRFQ(rfqObj, opts) {
  opts = opts || {};

  return (dispatch) => {
    // "Loading..."
    return createRFQPromise(rfqObj, opts)
      .then((response) => {
        dispatch({type: CREATE_RFQ, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  GET RFQ * * ------
     * Create a new RFQ, kicking off autoquoting if specified
     * An RFQ is a Request For Quote. All quotes will be created under an RFQ.
     * @param {module:model/RFQ} body
     * @param {module:api/shipwellApi~quotingRfqsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RFQ}

========================================================================
*/

export async function getRFQDetailsPromise(rfqId, opts) {
  const shipwellApi = await getShipwellApi();

  //default to not using xShipperId at all on this call
  opts = opts || {};
  return new Promise(function (resolve, reject) {
    shipwellApi.quotingRfqsRfqIdGet(rfqId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getRFQDetails(rfqId, opts) {
  return (dispatch) => {
    // "Loading..."
    return getRFQDetailsPromise(rfqId, opts)
      .then((response) => {
        dispatch({type: SELECT_RFQ, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  GET BROKER's RFQ * * ------
     * Get a RFQ,
     * An RFQ is a Request For Quote. All quotes will be created under an RFQ.
     * @param {module:model/RFQ} body
     * @param {module:api/shipwellApi~quotingRfqsPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RFQ}

========================================================================
*/

async function getBrokerRFQDetailsPromise(rfqId, opts) {
  const shipwellApi = await getShipwellApi();

  //default to not using xShipperId at all on this call
  opts = opts || {};
  return new Promise(function (resolve, reject) {
    shipwellApi.quotingRfqsRfqIdGet(rfqId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getBrokerRFQDetails(rfqId, opts) {
  return (dispatch) => {
    // "Loading..."
    return getBrokerRFQDetailsPromise(rfqId, opts)
      .then((response) => {
        dispatch({type: SELECT_BROKER_RFQ, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function setSelectedBrokerRFQ(rfq) {
  return (dispatch) => {
    return dispatch({type: SELECT_BROKER_RFQ, payload: rfq});
  };
}

export function clearSelectedBrokerRFQ() {
  return (dispatch) => {
    return dispatch({type: SELECT_BROKER_RFQ, payload: null});
  };
}

//Select a quote for modification (setting selectedQuote in state)
export function selectQuote(quote) {
  return (dispatch) => {
    return dispatch({type: SELECT_QUOTE, payload: quote});
  };
}

/*
========================================================================
          ------ * *  CREATE Quote * * ------
     * Create a new quote under an RFQ
     * Note: Need to add more info about creating a quote here
     * @param {String} rfqId The id of the rfq
     * @param {module:model/CreateQuote} body
     * @param {module:api/shipwellApi~quotingRfqsRfqIdQuotesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Quote}
========================================================================
*/

async function createQuotePromise(rfqId, quoteObj, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingRfqsRfqIdQuotesPost(rfqId, quoteObj, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function createQuote(rfqId, quoteObj, opts) {
  opts = opts || {};
  return (dispatch) => {
    // "Loading..."
    return createQuotePromise(rfqId, quoteObj, opts)
      .then((response) => {
        dispatch({type: CREATE_QUOTE, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/**
 * Obtain information about a quote
 * @param {String} rfqId The id of the rfq
 * @param {String} quoteId The id of the quote
 * @param {module:api/shipwellApi~quotingRfqsRfqIdQuotesQuoteIdGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Quote}
 */
async function getQuoteDetailsPromise(rfqId, quoteId, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  opts = opts || {};
  return new Promise(function (resolve, reject) {
    shipwellApi.quotingRfqsRfqIdQuotesQuoteIdGet(rfqId, quoteId, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getQuoteDetails(rfqId, quoteId, opts) {
  return (dispatch) => {
    // "Loading..."
    return getQuoteDetailsPromise(rfqId, quoteId, opts)
      .then((response) => {
        dispatch({type: SELECT_QUOTE, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  UPDATE Quote * * ------

    /**
     * Update a quote
     * Note: this route might be eliminated, so create new quotes when possible
     * @param {String} rfqId The id of the rfq
     * @param {String} quoteId The id of the quote
     * @param {module:model/CreateQuote} body
     * @param {module:api/shipwellApi~quotingRfqsRfqIdQuotesQuoteIdPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Quote}
     *
========================================================================
*/

async function editQuotePromise(rfqId, quoteId, quoteObj, opts) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingRfqsRfqIdQuotesQuoteIdPut(rfqId, quoteId, quoteObj, opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function editQuote(rfqId, quoteId, quoteObj, opts) {
  if (quoteObj.charge_line_items) {
    for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
      quoteObj.charge_line_items[i].category = ChargeCategory.Lh;
      quoteObj.charge_line_items[i].charge_code = 'LHS';
    }
  }
  opts = opts || {};
  return (dispatch) => {
    // "Loading..."
    return editQuotePromise(rfqId, quoteId, quoteObj, opts)
      .then((response) => {
        dispatch({type: EDIT_QUOTE, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

export function selectCarrierRate(quote) {
  return (dispatch) => {
    dispatch({type: SELECT_CARRIER_RATE, payload: quote});
  };
}

export function clearSelectedCarrierRate() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECT_CARRIER_RATE, payload: {}});
  };
}

/*
========================================================================
          ------ * *  AWARD Quote * * ------

    /**
     * Award a quote to a Shipment
     * @param {String} shipmentId The id of the shipment
     * @param {module:model/AwardQuoteRequest} body
     * @param {module:api/shipwellApi~shipmentsShipmentIdAwardQuotePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Shipment}
     *
========================================================================
*/

async function autoBookShipmentPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAutoBookPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function autoBookShipment(shipmentId, body) {
  return (dispatch) => {
    return autoBookShipmentPromise(shipmentId, body)
      .then((response) => {
        dispatch({type: SELECT_SHIPMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  AWARD Quote * * ------

    /**
     * Award a quote to a Shipment
     * @param {String} shipmentId The id of the shipment
     * @param {module:model/AwardQuoteRequest} body
     * @param {module:api/shipwellApi~shipmentsShipmentIdAwardQuotePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Shipment}
     *
========================================================================
*/

async function awardQuotePromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdAwardQuotePost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function awardQuote(shipmentId, body) {
  return (dispatch) => {
    return awardQuotePromise(shipmentId, body)
      .then((response) => {
        dispatch({type: AWARD_QUOTE, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
    /**
     * Trigger sending the shipment booked notification to all applicable actors on a shipment
     * @param {String} shipmentId The id of the shipment
     * @param {Object} opts Optional parameters
     * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
     * @param {module:api/shipwellApi~shipmentsShipmentIdSendShipmentBookedGetCallback} callback The callback function, accepting three arguments: error, data, response
     *
========================================================================
*/

async function sendShipmentBookedPromise(shipmentId) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdSendShipmentBookedGet(shipmentId, {}, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function sendShipmentBooked(shipmentId) {
  return (dispatch) => {
    return sendShipmentBookedPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}
/*
========================================================================
          ------ * *  Assign Carrier * * ------

    /**
     * Create a new carrier assignment
     * Only one carrier assignment is associated with a shipment at a time.  POSTing here will overwrite the previous carrier assignment.
     * @param {String} shipmentId The id of the shipment
     * @param {module:model/CreateCarrierAssignment} body
     * @param {Object} opts Optional parameters
     * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
     * @param {module:api/shipwellApi~shipmentsShipmentIdAssignCarrierPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CarrierAssignment}

========================================================================
*/

async function assignCarrierPromise(shipmentId, body, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCarrierAssignmentsPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function assignCarrier(shipmentId, body) {
  return (dispatch) => {
    return assignCarrierPromise(shipmentId, body)
      .then((response) => {
        //dispatch({type: types.AWARD_QUOTE, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  Edit Carrier Assignment * * ------

   * Update the charge line items of a VendorAssignment
     * @param {String} shipmentId The id of the shipment
     * @param {String} vendorAssignmentId The id of a VendorAssignment
     * @param {module:model/VendorAssignment} body
     * @param {module:api/shipwellApi~shipmentsShipmentIdVendorAssignmentsVendorAssignmentIdPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/VendorAssignment}

========================================================================
*/

export async function editCarrierAssignmentPromise(shipmentId, carrierAssignmentId, body, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCarrierAssignmentsCarrierAssignmentIdPut(
      shipmentId,
      carrierAssignmentId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

export function editCarrierAssignment(shipmentId, carrierAssignmentId, body) {
  return (dispatch) => {
    return editCarrierAssignmentPromise(shipmentId, carrierAssignmentId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  Delete Carrier * * ------

   /**
     * Delete all carrier assignments associated with the shipment
     * @param {String} shipmentId The id of the shipment
     * @param {Object} opts Optional parameters
     * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
     * @param {module:api/shipwellApi~shipmentsShipmentIdAssignCarrierDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/NoContentResponse}
     *

========================================================================
*/

async function unassignCarrierPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCarrierAssignmentsDelete(shipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function unassignCarrier(shipmentId) {
  return (dispatch) => {
    return unassignCarrierPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
          ------ * *  Assign Equipment Config * * ------

    /**
     * Create a new equipment config
     * Only one equipment config is associated with a shipment at a time.  POSTing here will overwrite the previous equipment config and attempt to track with the new configuration.
     * @param {String} shipmentId The id of the shipment
     * @param {module:model/CreateEquipmentConfig} body
     * @param {Object} opts Optional parameters
     * @param {String} opts.xCompanyId allows request to retrieve information about the company given in the header instead of the company associated with the requesting user
     * @param {module:api/shipwellApi~shipmentsShipmentIdEquipmentConfigPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/EquipmentConfig}

========================================================================
*/

async function assignEquipmentConfigPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdEquipmentConfigPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function assignEquipmentConfig(shipmentId, body) {
  return (dispatch) => {
    return assignEquipmentConfigPromise(shipmentId, body)
      .then((response) => {
        //dispatch({type: types.AWARD_QUOTE, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return response;
      });
  };
}

/*
========================================================================
    --------- * * * * CANCEL DELETE SHIPMENT * * * * ---------
* Cancel a Shipment that has yet to depart\n
* @param {Integer} shipmentId
* @param {String} authorization Basic Authorization Header of &#39;
Token {token}&#39;\n
* @param {module:api/shipwellApi~shippersShipmentsShipmentIdCancelDeleteCallback}
callback The callback function, accepting three arguments: error, data, response
* data is of type: {module:model/Shipment}

========================================================================
*/

async function cancelDeleteShipmentPromise(shipmentId, reason, authorization) {
  const shipwellApi = await getShipwellApi(window.decimalSupportForShipmentLineItems);

  const body = {cancellation_reason: reason, cancellation_reason_code: null};
  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdCancelDelete(
      shipmentId,
      body,
      authorization,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          return resolve(response.body);
        }
      }
    );
  });
}
export function cancelDeleteShipment(shipmentId, reason) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return cancelDeleteShipmentPromise(shipmentId, reason, authorization)
      .then((response) => {
        dispatch({type: CANCEL_ONDEMAND, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

/*
========================================================================
   /**
     * Estimate how many linear feet of a truck that ShipmentLineItems would fill if packed optimally
     * @param {module:model/LinearFeetEstimate} body
     * @param {module:api/shipwellApi~shipmentsLinearFeetPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LinearFeetEstimate}
     */

export async function getLinearFeetEstimatePromise(line_items) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsLinearFeetPost(line_items, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getLinearFeetEstimate(line_items) {
  return (dispatch) => {
    return getLinearFeetEstimatePromise(line_items)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((err) => {
        if (err.error_description) {
          dispatch({
            type: ALERT_ERROR,
            payload: err.error_description
          });
        }
        return {status: 400, details: err};
      });
  };
}

/*
========================================================================
    --------- * * * * FETCH ACCESSORIAL CODES * * * * ---------
    /**
     * A list of all accessorials supported by the API
     * @param {module:api/shipwellApi~shipmentsAccessorialsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Accessorial>}
     */

async function fetchAccessorialCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsAccessorialsGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchAccessorialCodes() {
  return (dispatch) => {
    return fetchAccessorialCodesPromise()
      .then((response) => {
        dispatch({type: FETCH_ACCESSORIAL_CODES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting accessorial codes.'
        });
      });
  };
}

/**
 * A list of all NMFCs supported by the API
 * @param {module:api/shipwellApi~shipmentsNmfcGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/NMFC>}
 */
async function fetchShipmentRepRolesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentRepRolesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchShipmentRepRoles() {
  return (dispatch) => {
    return fetchShipmentRepRolesPromise()
      .then((response) => {
        dispatch({type: GET_REP_ROLES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting shipment rep roles.'
        });
      });
  };
}

/**
 * A list of all NMFCs supported by the API
 * @param {module:api/shipwellApi~shipmentsNmfcGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/NMFC>}
 */
async function fetchNMFCCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsNmfcGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchNMFCCodes() {
  return (dispatch) => {
    return fetchNMFCCodesPromise()
      .then((response) => {
        dispatch({type: GET_NMFC_CODES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting NMFC codes.'
        });
      });
  };
}

/**
 * A list of all hazmat supported by the API
 * @param {module:api/shipwellApi~shipmentsHazmatGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/Hazmat>}
 */
export async function fetchHazmatCodesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsHazmatGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchHazmatCodes() {
  return (dispatch) => {
    return fetchHazmatCodesPromise()
      .then((response) => {
        dispatch({type: GET_HAZMAT_CODES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting Hazmat codes.'
        });
      });
  };
}

/**
 * A list of all package types supported by the API
 * @param {module:api/shipwellApi~shipmentsPackageTypesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/PackageType>}
 */
async function fetchPackageTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsPackageTypesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchPackageTypes() {
  return (dispatch) => {
    return fetchPackageTypesPromise()
      .then((response) => {
        dispatch({type: GET_PACKAGE_TYPES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting package types.'
        });
      });
  };
}

/**
 * A list of all equipment types supported by the API
 * @param {module:api/shipwellApi~shipmentsPackageTypesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/PackageType>}
 */
async function fetchEquipmentTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsEquipmentTypesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchEquipmentTypes() {
  return (dispatch) => {
    return fetchEquipmentTypesPromise()
      .then((response) => {
        dispatch({type: GET_EQUIPMENT_TYPES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting equipment types.'
        });
      });
  };
}

/**
 * A list of all equipment types supported by the API
 * @param {module:api/shipwellApi~shipmentsPackageTypesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/PackageType>}
 */
async function fetchAppointmentTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsAppointmentTypesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchAppointmentTypes() {
  return (dispatch) => {
    return fetchAppointmentTypesPromise()
      .then((response) => {
        dispatch({type: GET_APPOINTMENT_TYPES, payload: response});
        return response;
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting appointment types.'
        });
      });
  };
}

/**
 * A list of all service levels supported by the API
 * @param {module:api/shipwellApi~shipmentsServiceLevelsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/ServiceLevel>}
 */
async function fetchServiceLevelsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsServiceLevelsGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchServiceLevels() {
  return (dispatch) => {
    return fetchServiceLevelsPromise()
      .then((response) => {
        dispatch({type: GET_SERVICE_LEVELS, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting service levels.'
        });
      });
  };
}

/**
 * A list of all shipment modes supported by the API
 * @param {module:api/shipwellApi~shipmentsShipmentModesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/ShipmentMode>}
 */
export async function fetchShipmentModesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentModesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchShipmentModes() {
  return (dispatch) => {
    return fetchShipmentModesPromise()
      .then((response) => {
        dispatch({type: GET_SHIPMENT_MODES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting shipment modes.'
        });
      });
  };
}

/**
 * A list of all charge line item categories supported by the API
 * @param {module:api/shipwellApi~shipmentsChargeLineItemCategoriesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/ChargeLineItemCategory>}
 */
async function fetchChargeCategoriesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsChargeLineItemCategoriesGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function fetchChargeCategories() {
  return (dispatch) => {
    return fetchChargeCategoriesPromise()
      .then((response) => {
        dispatch({type: GET_CHARGE_CATEGORIES, payload: response});
      })
      .catch((err) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting charge categories.'
        });
      });
  };
}

export function populateRecipientEmails(recipients) {
  return (dispatch) => {
    return dispatch({
      type: POPULATE_RECIPIENT_EMAILS,
      payload: {recipient_emails: recipients}
    });
  };
}

//save a modified shipment object for use in redux forms
export function selectFormShipment(shipment) {
  return (dispatch) => {
    return dispatch({type: SELECT_SHIPMENT_FOR_FORM, payload: shipment});
  };
}

//manually set selectedShipment, only use when absolutely needed
export function selectShipment(shipment) {
  return (dispatch) => {
    return dispatch({type: SELECT_SHIPMENT, payload: shipment});
  };
}

//save some charge line items to the store for use in the customer half of financials later on

export function saveQuoteCopyForCustomerFinancials(quote) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS,
      payload: quote
    });
  };
}

/* ------------ clearSelectedShipment --------------- */

export function clearSelectedShipment() {
  return (dispatch) => {
    return dispatch({type: CLEAR_SELECTED_SHIPMENT});
  };
}

/* ------------ clearSelectedQuote --------------- */

export function clearSelectedQuote() {
  return (dispatch) => {
    return dispatch({type: CLEAR_SELECTED_QUOTE});
  };
}

/* ------------ shipmentError --------------- */

export function shipmentError(error) {
  return {type: SHIPMENTS_ERROR, payload: error};
}

export function clearAlertError() {
  return (dispatch) => {
    return dispatch({type: ALERT_ERROR, payload: null});
  };
}

export function resetFormError() {
  return {type: RESET_ERROR, error: false, details: null};
}

/*
========================================================================
  ---* * REVERSE GEOCODING (prevents "OVER_QUERY_LIMIT") * * ---
========================================================================
*/

var Geolocalizer = function () {
  this.queue = []; // queue handler..
  this.resolved = [];
  this.geolocalizer = new google.maps.Geocoder();
};

Geolocalizer.prototype = {
  Localize(locations) {
    var that = this;

    // Enqueue the locations.
    for (var i = 0; i < locations.length; i++) {
      this.queue.push(locations[i]);
    }
    // return a promise and resolve it after every element have been
    //fetched (either with success or failure), then reset the queue.

    return new Promise(function (resolve, reject) {
      that.resolveQueueElements().then(function (resolved) {
        resolve(resolved);
        that.queue = [];
        that.resolved = [];
      });
    });
  },

  resolveQueueElements(callback) {
    var that = this;

    return new Promise(function (resolve, reject) {
      // Loop the queue and resolve each element.
      // Prevent QUERY_LIMIT by delaying actions by one second.
      (function loopWithDelay(such, queue, i) {
        // console.log("Attempting the resolution of " + queue[i - 1]);
        setTimeout(function () {
          if (!queue.length) {
            return;
          }

          such.find(
            queue[i - 1],
            /*callback*/
            function (res) {
              such.resolved.push(res);
            }
          );
          if (--i) {
            loopWithDelay(such, queue, i);
          }
        }, 1000);
      })(that, that.queue, that.queue.length);

      // Check every second if the queue has been cleared.
      var it = setInterval(function () {
        if (that.queue.length === that.resolved.length) {
          resolve(that.resolved);
          clearInterval(it);
        }
      }, 1000);
    });
  },

  find(s, callback) {
    const latlng = new google.maps.LatLng(s.lastLoc[0], s.lastLoc[1]);
    this.geolocalizer.geocode(
      {
        location: latlng
      },
      function (res, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          var r = {
            id: s.shipId,
            shipment_name: s.shipmentName,
            address: res[0].formatted_address,
            last_latitude: s.lastLocLat,
            last_longitude: s.lastLocLng,
            est_time_of_arrival: s.estTimeArrival,
            last_loc_updated: s.last_loc_updated,
            shipper_load_number: s.loadNumber,
            last_loc_updated: s.lastLocUpdate
          };
          callback(r);
        } else {
          callback(undefined);
          // console.log(status);
          // console.log("could not locate " + s);
        }
      }
    );
  }
};

const filterOndemands = (ondemands) => {
  const filteredArray = [];

  const activeTrucks = ondemands.filter((truck, i) => {
    //quoted  but not booked

    const quoted = truck.shipment_state === 'QUOTED';
    // truck not moving - way past the posted ETA date/time
    const oneHourAgo = moment().subtract(1, 'hour');
    const lastUpdated = moment(truck.last_loc_updated);
    const wayPastTime = moment(lastUpdated).isBefore(moment(oneHourAgo));

    // not picked up yet
    var d = new Date(0);
    var e = moment(truck.last_loc_updated);

    const waiting = ~~d === ~~e;
    const expired = moment().isAfter(moment(truck.shipper_price_valid_until));
    const cancelled = truck.cancelled_by_shipper_at;
    const cancelledStatus = cancelled.getTime() ? true : false;

    const awarded =
      truck.awarded === true && cancelledStatus === true
        ? 'cancelled'
        : truck.awarded === true
        ? 'awarded'
        : truck.awarded === undefined && expired === false
        ? 'pending'
        : 'expired';

    const delivered = truck.delivered === true ? 'delivered' : truck.on_route === true ? 'on route' : 'ready';

    return (
      quoted === false &&
      cancelledStatus === false &&
      truck.delivered === false &&
      awarded !== 'expired' &&
      waiting === false &&
      wayPastTime === false
    );
  });

  activeTrucks.map((item) => {
    filteredArray.push({
      id: item.id,
      address: '',
      last_latitude: item.last_latitude,
      last_longitude: item.last_longitude,
      est_time_of_arrival: item.est_time_of_arrival,
      last_loc_updated: item.last_loc_updated,
      shipper_load_number: item.shipper_load_number,
      shipment_name: item.shipment_name
    });
  });
  return filteredArray;
};

/*
========================================================================
  ---* * REVERSE GEOCODING for ALL active shipments (TRACK PAGE) * * ---
========================================================================
*/

export function fetchGeoAddress(ondemands) {
  const locations = [];
  const filtered = filterOndemands(ondemands);

  for (var i = 0; i < filtered.length; i++) {
    locations.push({
      shipId: filtered[i].id,
      shipmentName: filtered[i].shipment_name,
      lastLoc: [filtered[i].last_latitude, filtered[i].last_longitude],
      lastLocLat: filtered[i].last_latitude,
      lastLocLng: filtered[i].last_longitude,
      estTimeArrival: filtered[i].est_time_of_arrival,
      loadNumber: filtered[i].shipper_load_number,
      lastLocUpdate: filtered[i].last_loc_updated
    });
  }
  const myAmazingGeo = new Geolocalizer();

  return (dispatch) => {
    return myAmazingGeo.Localize(locations).catch((response) => {
      dispatch(shipmentError(response));
    });
  };
}

/*
========================================================================
  ---* * REVERSE GEOCODING for ONE shipment (Ship Details Page) * * ---
========================================================================
*/

export function fetchOneGeoAddress(one) {
  const lstAddress = [];
  lstAddress.push({
    lastLoc: [one.last_latitude, one.last_longitude]
  });
  const myAmazingGeo = new Geolocalizer();

  return (dispatch) => {
    return myAmazingGeo.Localize(lstAddress).catch((response) => {
      dispatch(shipmentError(response));
    });
  };
}

//PAY FOR A SHIPMENT//

export function payShipment(shipmentId, source) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return payShipmentPromise(shipmentId, source, authorization)
      .then((response) => {
        //send status code to kick off next payment if needed
        return response;
      })
      .catch((response) => {
        return {data: {status: 400}};
      });
  };
}

async function payShipmentPromise(shipmentId, source, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shippersShipmentsShipmentIdPayPut(
      shipmentId,
      {source_id: source},
      authorization,
      function (err, response, data) {
        if (err) {
          reject(err);
        } else {
          resolve({response, data});
        }
      }
    );
  });
}

export function setHistoryFilter(filter) {
  return (dispatch) => {
    dispatch({type: SET_HISTORY_FILTER, payload: filter});
  };
}

export function triggerSuccessfulDispatch(bool, isFTL) {
  return (dispatch) => {
    dispatch({
      type: SUCCESSFUL_DISPATCH,
      payload: {trigger: bool, isFTL: isFTL}
    });
  };
}

export function setInitialQuoteLoading(bool) {
  return (dispatch) => {
    dispatch({type: INITIAL_QUOTE_LOADING, payload: bool});
  };
}

export function setInitialQuotingValues(shipment) {
  return (dispatch) => {
    dispatch({type: INITIAL_QUOTE_VALUES, payload: shipment});
  };
}

export function setCloneShipmentTrigger(bool, shipment) {
  return (dispatch) => {
    dispatch({
      type: SET_CLONE_TRIGGER,
      payload: {bool: bool, shipment: shipment}
    });
  };
}

export function setInitialShipmentValues(shipment) {
  return (dispatch) => {
    dispatch({type: INITIAL_SHIPMENT_VALUES, payload: shipment});
  };
}

export function triggerMarketplaceRedirect(bool) {
  return (dispatch) => {
    dispatch({type: TRIGGER_MARKETPLACE_REDIRECT, payload: bool});
  };
}

export function dispatchError(error) {
  return (dispatch) => {
    dispatch({
      type: ALERT_ERROR,
      payload: error
    });
  };
}

// TAGS
export function fetchTags() {
  return (dispatch) => {
    return fetchTagsPromise()
      .then((response) => {
        dispatch({type: FETCH_TAGS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export async function fetchTagsPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsTagsGet(function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function postTag(body) {
  return (dispatch) => {
    return postTagPromise(body)
      .then((response) => {
        dispatch({type: FETCH_TAGS, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export async function postTagPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsTagsPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getTag(shipmentTagId) {
  return (dispatch) => {
    return getTagPromise(shipmentTagId)
      .then((response) => {
        dispatch({type: FETCH_TAG, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getTagPromise(shipmentTagId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsTagsShipmentTagIdGet(shipmentTagId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function editTag(shipmentTagId, body) {
  return (dispatch) => {
    return editTagPromise(shipmentTagId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export async function editTagPromise(shipmentTagId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsTagsShipmentTagIdPut(shipmentTagId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function deleteTag(shipmentTagId) {
  return (dispatch) => {
    return deleteTagPromise(shipmentTagId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function deleteTagPromise(shipmentTagId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsTagsShipmentTagIdDelete(shipmentTagId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function clearTag() {
  return (dispatch) => {
    dispatch({type: CLEAR_TAG});
  };
}

export function requestAppDownload(shipmentId) {
  return (dispatch) => {
    return requestAppDownloadPromise(shipmentId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function requestAppDownloadPromise(shipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdRequestAppDownloadPost(shipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function requestApplessPing(shipmentId, body) {
  return (dispatch) => {
    return requestApplessPingPromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}

async function requestApplessPingPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsShipmentIdCellTrackingPost(shipmentId, body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

export function getTruckloadPriceEstimate(body) {
  return (dispatch) => {
    return getTruckloadPriceEstimatePromise(body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

async function getTruckloadPriceEstimatePromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.shipmentsTruckloadPricingPost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}
