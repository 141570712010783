import {useState} from 'react';
import {TrackingApiGetDeviceHistoryRequest} from '@shipwell/locus-sdk';
import {useGetDeviceHistory} from './useGetDeviceHistory';
import columns from './sensorDataTableColumns';
import {remapDataToCsv} from './remapDataToCsv';

export const useSensorTableData = (params: Omit<TrackingApiGetDeviceHistoryRequest, 'accountId' | 'resourceType'>) => {
  const INITIAL_PAGE_SIZE = 20;
  const {deviceHistoryData, deviceHistoryStatus} = useGetDeviceHistory(params);

  const [options, setOptions] = useState({
    page: 1,
    size: INITIAL_PAGE_SIZE
  });

  const onFetchData = ({pageSize, pageIndex}: {pageSize: number; pageIndex: number}) => {
    setOptions({size: pageSize, page: pageIndex ? pageIndex + 1 : 1});
  };

  const exportToCSV = () => {
    if (!deviceHistoryData || deviceHistoryData?.length === 0) {
      return;
    }

    const remappedData = remapDataToCsv(deviceHistoryData);
    const csvRows = [];
    const headers = Object.keys(remappedData[0]);
    csvRows.push(headers.map((header) => `"${header}"`).join(','));

    for (const row of remappedData) {
      const values = headers.map((header) => `"${row[header]}"`);
      csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sensor_data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return {
    data: deviceHistoryData?.slice((options.page - 1) * options.size, options.page * options.size),
    isLoading: deviceHistoryStatus === 'loading',
    columns,
    onFetchData,
    page: options.page,
    pageCount: deviceHistoryData?.length ? Math.ceil(deviceHistoryData.length / options.size) : 0,
    pageSize: options.size,
    exportToCSV
  };
};
