import {useState} from 'react';
import {BaseShipmentParcelPickupStatusEnum, ShipmentStatesValues} from '@shipwell/backend-core-sdk';
import {Checkbox} from '@shipwell/shipwell-ui';
import {FlexBox} from 'App/components/Box';
import {
  FILTER_TYPE,
  FilterLabelWithFilterType,
  FilterType
} from 'App/components/TypedTable/complementaryComponents/FilterLabelWithFilterType';
import {TableFilter} from 'App/components/TypedTable/complementaryComponents';

type StatusOption = {
  label: string;
  value: string;
  filterName: 'status' | 'open';
};

export const statusOptions: StatusOption[] = [
  {label: 'Appointment Pending', value: ShipmentStatesValues.AppointmentPending, filterName: 'status'},
  {label: 'At Delivery', value: ShipmentStatesValues.AtDelivery, filterName: 'status'},
  {label: 'At Pickup', value: ShipmentStatesValues.AtPickup, filterName: 'status'},
  {label: 'Auction Running', value: 'auction_running', filterName: 'status'},
  {label: 'Cancelled', value: ShipmentStatesValues.Cancelled, filterName: 'status'},
  {label: 'Carrier Confirmed', value: ShipmentStatesValues.CarrierConfirmed, filterName: 'status'},
  {label: 'Delayed', value: 'delayed', filterName: 'status'},
  {label: 'Delivered', value: ShipmentStatesValues.Delivered, filterName: 'status'},
  {label: 'Dispatched', value: ShipmentStatesValues.Dispatched, filterName: 'status'},
  {label: 'Draft', value: ShipmentStatesValues.Draft, filterName: 'status'},
  {label: 'In Transit', value: ShipmentStatesValues.InTransit, filterName: 'status'},
  {label: 'Open', value: 'open', filterName: 'open'},
  {label: 'Out For Delivery', value: 'out_for_delivery', filterName: 'status'},
  {label: 'Quote Accepted', value: ShipmentStatesValues.QuoteAccepted, filterName: 'status'},
  {label: 'Quoting', value: ShipmentStatesValues.Quoting, filterName: 'status'},
  {label: 'Reconciled', value: ShipmentStatesValues.Reconciled, filterName: 'status'},
  {label: 'Scheduled', value: 'scheduled', filterName: 'status'},
  {label: 'Tendered', value: ShipmentStatesValues.Tendered, filterName: 'status'}
];

const parcelPickupStatuses = Object.values(BaseShipmentParcelPickupStatusEnum).map((status) => status.toLowerCase());

// The StatusFilter is a mashup of 3 filter params: status, parcel_pickup_status, and open
// This makes for some interesting logic onchange and when checking if an option is checked
export const StatusFilter = ({
  onStatusChange,
  activeStatuses,
  openStatus,
  activeStatusExclude,
  onStatusExcludeChange,
  onOpenStatusChange,
  activeParcelPickupStatuses,
  onParcelPickupStatusChange,
  isOpen,
  onToggleOpen,
  isClearable,
  onClear
}: {
  onStatusChange: (statuses: string[]) => void;
  activeStatuses: string[];
  activeStatusExclude: string[];
  onStatusExcludeChange: (statuses: string[]) => void;
  openStatus: string;
  onOpenStatusChange: (isOpen: string) => void;
  activeParcelPickupStatuses: string[];
  onParcelPickupStatusChange: (statuses: string[]) => void;
  isOpen: boolean;
  onToggleOpen: () => void;
  isClearable: boolean;
  onClear: () => void;
}) => {
  const defaultFilterType = activeStatusExclude.length || openStatus === 'false' ? FILTER_TYPE.EXCLUDE : FILTER_TYPE.OR;
  const [filterType, setFilterType] = useState<FilterType>(defaultFilterType);
  const isOrFilterType = filterType === FILTER_TYPE.OR;

  const isCheckedOption = (status: StatusOption) => {
    if (status.filterName === 'open') {
      return isOrFilterType ? openStatus === 'true' : openStatus === 'false';
    }

    return [...activeStatuses, ...activeParcelPickupStatuses, ...activeStatusExclude].some(
      (activeStatus) => activeStatus === status.value
    );
  };

  const handleStatusChange = (status: StatusOption) => {
    // if checked, remove option from both status and parcel_pickup_status (if applicable)
    if (isCheckedOption(status)) {
      if (parcelPickupStatuses.some((parcelStatus) => parcelStatus === status.value)) {
        onParcelPickupStatusChange(activeParcelPickupStatuses.filter((activeStatus) => activeStatus !== status.value));
      }
      return onStatusChange(activeStatuses.filter((activeStatus) => activeStatus !== status.value));
    }
    // if not checked, add option to both status and parcel_pickup_status (if applicable)
    if (parcelPickupStatuses.some((parcelStatus) => parcelStatus === status.value)) {
      onParcelPickupStatusChange([...activeParcelPickupStatuses, status.value]);
    }
    onStatusChange([...activeStatuses, status.value]);
  };

  const handleStatusChangeExclude = (status: StatusOption) => {
    // if previously checked, filter it out (uncheck it). otherwise append it to list
    const newStatusExcludes = isCheckedOption(status)
      ? activeStatusExclude.filter((stat) => stat !== status.value)
      : [...activeStatusExclude, status.value];
    onStatusExcludeChange(newStatusExcludes);
  };

  const handleOpenStatusChange = () => {
    // open and NOT_SCHEDULED should be queried together
    if (openStatus === 'true') {
      onOpenStatusChange('');
      onParcelPickupStatusChange(
        activeParcelPickupStatuses.filter(
          (activeStatus) => activeStatus !== BaseShipmentParcelPickupStatusEnum.NotScheduled
        )
      );
      return;
    }
    onOpenStatusChange('true');
    onParcelPickupStatusChange([...activeParcelPickupStatuses, BaseShipmentParcelPickupStatusEnum.NotScheduled]);
  };

  const handleChangeOr = (status: StatusOption) => {
    if (status.filterName === 'open') {
      return handleOpenStatusChange();
    }
    if (status.filterName === 'status') {
      return handleStatusChange(status);
    }
  };

  const handleSelectFilterType = (val: FilterType) => {
    setFilterType(val);
    if (val === FILTER_TYPE.OR) {
      onStatusChange(activeStatusExclude);
      onParcelPickupStatusChange(
        parcelPickupStatuses.filter((stat) => activeStatusExclude.some((activeStat) => activeStat === stat))
      );

      onStatusExcludeChange([]);
      if (openStatus === 'false') {
        handleOpenStatusChange();
      }
    }
    if (val === FILTER_TYPE.EXCLUDE) {
      // if exclude, open checked should be a FALSE value, all other filters go to statusExclude, activeParcelPickupStatuses is not used at all
      onParcelPickupStatusChange([]);
      onStatusChange([]);
      if (openStatus === 'true') {
        onOpenStatusChange('false');
      }
      onStatusExcludeChange(activeStatuses);
    }
  };

  const handleChangeExclude = (status: StatusOption) => {
    if (status.filterName === 'open') {
      return openStatus === 'false' ? onOpenStatusChange('') : onOpenStatusChange('false');
    }
    if (status.filterName === 'status') {
      return handleStatusChangeExclude(status);
    }
  };

  return (
    <TableFilter
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      label={<FilterLabelWithFilterType label="Status" filterType={filterType} onChange={handleSelectFilterType} />}
      isClearable={isClearable}
      onClear={onClear}
    >
      <FlexBox direction="col" gap="s">
        {statusOptions.map((status) => (
          <Checkbox
            key={status.value}
            label={status.label}
            name={status.value}
            checked={isCheckedOption(status)}
            onChange={() => (isOrFilterType ? handleChangeOr(status) : handleChangeExclude(status))}
            fixedHeight={false}
          />
        ))}
      </FlexBox>
    </TableFilter>
  );
};
