import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Field, getFormValues, change} from 'redux-form';
import {
  FedexShipmentOptions,
  UPSShipmentOptions,
  USPSShipmentOptions
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ProviderCode, ShipmentMode} from '@shipwell/genesis-sdk';
import SelectField from 'App/formComponents/fields/select';
import {useUserMe} from 'App/data-hooks';
import {useCapacityProviders} from 'App/data-hooks/integrations/useCapacityProviders';
import {PackagingTypes} from 'App/utils/packagingTypes';
import {NewQuoteServiceOptions, getFormattedParcelProvider} from 'App/api/quoting/utils/transformParcelPayload';
import ShipwellLoader from 'App/common/shipwellLoader';
import {GenesisServiceOptions} from 'App/formComponents/formSections/shipmentServices/genesisServiceOptions';
import {LegacyServiceOptions} from 'App/formComponents/formSections/shipmentServices/legacyServiceOptions';
import './styles.scss';
import {CombinedQuote} from 'App/containers/Marketplace/components/NewParcelMarketplace/components/AllParcelRates/types/combinedQuote';
import {State} from 'App/reducers/types';

type ParcelOptions = {
  fedex_specific_options?: FedexShipmentOptions | null;
  ups_specific_options?: UPSShipmentOptions | null;
  usps_specific_options?: USPSShipmentOptions | null;
  // genesis specific options
  service_options?: NewQuoteServiceOptions | null;
};

export type PartialNewQuoteFormValues = ParcelOptions & {
  parcel_provider?: Array<{id: string; name: string}>;
  line_items?: Array<{provider_specific_packaging?: string | null}>;
  id?: string;
  preferred_currency?: string;
};

type ShipmentServicesProps = {
  values?: PartialNewQuoteFormValues;
  form: string;
  dispatch: Dispatch;
  triggerFieldTouch: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
  isLoadingParcelRates?: boolean;
};

const ShipmentServices = ({
  values = {},
  form,
  dispatch,
  triggerFieldTouch,
  setFieldValue,
  isLoadingParcelRates
}: ShipmentServicesProps) => {
  const {data: {company: {feature_flags: featureFlags = {}} = {}} = {}} = useUserMe();
  const {fedex_enabled: fedexEnabled, ups_enabled: upsEnabled, usps_enabled: uspsEnabled} = featureFlags;

  const isFedExParcel = !!values.fedex_specific_options;
  const isUPSParcel = !!values.ups_specific_options;
  const isUSPSParcel = !!values.usps_specific_options;
  // genesis providers live under service_options
  const genesisProviderCode = values.service_options?.provider_code;
  const isGenesisParcel = !!genesisProviderCode && !isFedExParcel && !isUPSParcel && !isUSPSParcel;

  // Genesis accounts
  const {capacityProviders: parcelCapacityProviders} = useCapacityProviders({modes: [ShipmentMode.Parcel]});

  // these options spawn RFQs to get quotes
  const parcelProviderOptions = [
    ...(fedexEnabled ? [{id: 'fedex', name: 'FedEx'}] : []),
    ...(upsEnabled ? [{id: 'ups', name: 'UPS'}] : []),
    ...(uspsEnabled ? [{id: 'usps', name: 'USPS'}] : [])
  ];
  // these options create genesis rate requests to get quotes
  const genesisProviderOptions =
    parcelCapacityProviders?.map((providerSchema) => ({
      id: providerSchema.provider_code,
      name: getFormattedParcelProvider(providerSchema.provider_code)
    })) || [];
  const combinedProviderOptions = [...parcelProviderOptions, ...genesisProviderOptions];

  let parcelLabel = 'FedEx';

  if (isUPSParcel) {
    parcelLabel = 'UPS';
  }
  if (isUSPSParcel) {
    parcelLabel = 'USPS';
  }
  if (genesisProviderCode) {
    parcelLabel = getFormattedParcelProvider(genesisProviderCode);
  }

  const handleUpdateProviderSpecificPackaging = () =>
    values.line_items?.map((lineItem, index) =>
      dispatch(change(form, `line_items[${index}].provider_specific_packaging`, PackagingTypes.YourPackaging))
    );

  const handleChangeParcelProvider = (e: unknown, selectValue: typeof combinedProviderOptions) => {
    // original RFQ logic
    if (selectValue && Array.isArray(selectValue)) {
      const providers = parcelProviderOptions.map(({id}) => id);
      providers.forEach((provider) => {
        const providerKey = `${provider}_specific_options` as keyof ParcelOptions;

        if (selectValue?.find(({id}) => id === provider)) {
          // if there are 0 or more than 1 providers selected then we have to clear the existing specific_options
          // if there is 1 provider selected we will try to use an existing options object
          // if there is no existing options object set it to {} to get new quotes from RFQ
          if (selectValue?.length !== 1 || !values[providerKey]) {
            dispatch(change(form, providerKey, {}));
            // set all packaging to the genering YourPackaging
            handleUpdateProviderSpecificPackaging();
          }
        } else {
          // if the option is not selected set its option value to null to prevent getting quotes from RFQ
          dispatch(change(form, providerKey, null));
        }
      });

      // special logic for genesis providers
      const genesisProviders = genesisProviderOptions.map(({id}) => id);
      if (genesisProviders.length) {
        if (selectValue?.length === 1 && genesisProviders.includes(selectValue[0].id as ProviderCode)) {
          // !!special_options.provider_code -> show service options and rate for a single genesis provider
          return dispatch(change(form, 'service_options', {provider_code: selectValue[0].id}));
        }
        if (!selectValue?.length) {
          // special_options = {} -> rate all genesis providers
          dispatch(change(form, 'service_options', {}));
        } else if (!selectValue?.some(({id}) => genesisProviders.includes(id as ProviderCode))) {
          // special_options = null -> do not fetch genesis rates for any provider
          dispatch(change(form, 'service_options', null));
        } else {
          const filteredProviders = genesisProviders.filter((provider) =>
            selectValue.map(({id}) => id).includes(provider)
          );
          dispatch(change(form, 'service_options', {providerCodes: filteredProviders}));
        }
        // set all packaging to the genering YourPackaging
        handleUpdateProviderSpecificPackaging();
      }
    }
  };

  return isLoadingParcelRates ? (
    <ShipwellLoader loading={isLoadingParcelRates} />
  ) : (
    <>
      {form === 'confirmShipmentForm' ? null : (
        <div className="field-grid">
          <div className="grid-item-2">
            <Field
              multi
              label="Parcel Provider"
              name="parcel_provider"
              placeholder="Parcel Provider"
              options={combinedProviderOptions}
              component={SelectField as unknown as 'select'}
              onChange={handleChangeParcelProvider}
            />
          </div>
        </div>
      )}
      {values.parcel_provider?.length !== 1 ? null : isGenesisParcel ? (
        <GenesisServiceOptions
          values={values}
          parcelLabel={parcelLabel}
          triggerFieldTouch={triggerFieldTouch}
          setFieldValue={setFieldValue}
          // we should not let the user change it on the confirm shipment screen
          disableStandardService={form === 'confirmShipmentForm'}
        />
      ) : (
        <LegacyServiceOptions
          values={values}
          parcelLabel={parcelLabel}
          triggerFieldTouch={triggerFieldTouch}
          setFieldValue={setFieldValue}
          isFedExParcel={isFedExParcel}
          isUPSParcel={isUPSParcel}
          isUSPSParcel={isUSPSParcel}
          dispatch={dispatch}
          form={form}
        />
      )}
    </>
  );
};

export default connect((state: State, {form}: ShipmentServicesProps) => ({
  values: getFormValues(form)(state) || {},
  selectedQuote: (
    state.shipments as {
      selectedQuote: CombinedQuote;
    }
  ).selectedQuote
}))(ShipmentServices);
