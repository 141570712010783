import {useRollbar} from '@rollbar/react';
import {Button} from '@shipwell/shipwell-ui';

export function RollbarTools() {
  const rollbar = useRollbar();

  function sendTestInfo() {
    rollbar.info('Test info');
  }

  function throwError() {
    throw new Error('Test error');
  }

  return (
    <div className="flex gap-2">
      <Button size="sm" onClick={sendTestInfo}>
        Send test info
      </Button>
      <Button size="sm" onClick={throwError}>
        Throw real error
      </Button>
    </div>
  );
}
