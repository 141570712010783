import {flexRender} from '@tanstack/react-table';
import {CombinedQuote} from '../../types/combinedQuote';
import {
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableRow
} from 'App/components/TypedTable/baseComponents';
import {useTableSort, useTypedTable} from 'App/components/TypedTable/hooks';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import {getParcelColumns} from 'App/containers/Marketplace/components/NewParcelMarketplace/components/AllParcelRates/components/ParcelList/columns';

export const ParcelList = ({
  packageType,
  numberOfPackages,
  handleSelectQuote,
  quotes,
  disableActions
}: {
  quotes: CombinedQuote[];
  handleSelectQuote: (quote: CombinedQuote) => Promise<void>;
  packageType?: string;
  numberOfPackages?: number;
  disableActions?: boolean;
}) => {
  const [sorting, setSorting] = useTableSort({
    defaultSort: {id: 'total', desc: false}
  });

  const columns = getParcelColumns({handleSelectQuote, packageType, numberOfPackages, disableActions});

  const table = useTypedTable({
    data: quotes,
    enableSortingRemoval: false,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    columns
  });

  return (
    <TableContainer>
      <Table
        head={table.getHeaderGroups().map((headerGroup) => (
          <TableHeaderRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader
                key={header.id}
                width={header.getSize()}
                onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
              >
                <div className="flex items-center gap-1">
                  {header.column.getCanSort() ? <TableHeaderSortIcon isSorted={header.column.getIsSorted()} /> : null}
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </div>
              </TableHeader>
            ))}
          </TableHeaderRow>
        ))}
        body={
          quotes.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyListView itemLabel="rate" />
          )
        }
      />
    </TableContainer>
  );
};
