import axios from 'axios';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const OTTER_DJANGO = basePath.slice(0, -3); //remove the 'v2' at the end of the standard basePath
/**
 * Create workflow
 */
export async function createWorkflow(body) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.post(OTTER_DJANGO + '/workflows/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Get workflows
 */
export async function getWorkflows(opts = {}) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      },
      params: opts
    };
    const request = axios.get(OTTER_DJANGO + '/workflows/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Delete workflow
 */
export async function deleteWorkflow(workflowId) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.delete(OTTER_DJANGO + '/workflows/' + workflowId + '/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Get workflow
 */
export async function getWorkflow(workflowId, version) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      },
      params: {
        version
      }
    };

    const request = axios.get(OTTER_DJANGO + '/workflows/' + workflowId + '/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Update workflow
 */
export async function updateWorkflow(workflowId, body) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.put(OTTER_DJANGO + '/workflows/' + workflowId + '/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Get workflow executions (shipmentID specified in opts)
 */
export async function getWorkflowExecutions(opts) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      },
      params: {...opts, include: 'workflow'}
    };
    const request = axios.get(OTTER_DJANGO + '/workflow-execution-instances/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

// TODO: Remove this when 'workflow-execution-instances' supports 'id.in' paramter
export async function getWorkflowExecutionsByIdsList(opts) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      },
      params: {...opts}
    };
    const request = axios.get(OTTER_DJANGO + '/workflow-executions/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Get workflow executions (shipmentID specified in opts)
 */
export async function getWorkflowExecutionById(executionId) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      },
      params: {include: 'workflow'}
    };

    const request = axios.get(OTTER_DJANGO + '/workflow-execution-instances/' + executionId + '/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

/**
 * Cancel workflow execution
 */
export async function cancelWorkflowExecution(executionId) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.post(OTTER_DJANGO + '/workflow-executions/' + executionId + '/cancel', {}, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

export async function getWorkflowTriggeringEvents(opts) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      },
      params: opts
    };

    const request = axios.get(OTTER_DJANGO + '/workflow-triggering-events/', config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}

export async function getWorkflowExecutionShipmentInstance(workflowExecutionId) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };

    const request = axios.get(
      `${OTTER_DJANGO}/workflow-execution-instances/${workflowExecutionId}?include=workflow`,
      config
    );
    request
      .then((response) => resolve(response))
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}
