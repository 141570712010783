import {ReferenceQualifierEnum} from '@shipwell/tempus-sdk';

type GroupedReferences = Record<string, string[]>;
type Reference = {qualifier: string; value: string};
type Filters = {
  preferredReference: Record<string, boolean>;
};
export type FormattedReference = {
  qualifier: ReferenceQualifierEnum;
  value: string;
};

export const formatReferences = (
  references: Reference[],
  filters?: Filters,
  includeMissingAsNA?: boolean
): FormattedReference[] => {
  const groupedReferences = groupReferencesByQualifier(references);
  const formattedReferences = formatGroupedReferences(groupedReferences);

  if (filters && shouldFilterReferences(filters)) {
    return filterReferences(formattedReferences, filters, includeMissingAsNA);
  }

  return filterByDefaultQualifier(formattedReferences);
};

const groupReferencesByQualifier = (references: Reference[]): GroupedReferences => {
  return references.reduce((acc: GroupedReferences, ref) => {
    if (!acc[ref.qualifier]) {
      acc[ref.qualifier] = [];
    }
    acc[ref.qualifier].push(ref.value);
    return acc;
  }, {} as GroupedReferences);
};

const formatGroupedReferences = (groupedReferences: GroupedReferences): FormattedReference[] => {
  return Object.entries(groupedReferences).reduce((acc, [qualifier, values]) => {
    if (isValidQualifier(qualifier)) {
      acc.push({
        qualifier: qualifier as ReferenceQualifierEnum,
        value: values.join(', ')
      });
    }
    return acc;
  }, [] as FormattedReference[]);
};

const isValidQualifier = (qualifier: string): boolean => {
  return Object.values(ReferenceQualifierEnum).includes(qualifier as ReferenceQualifierEnum);
};

const shouldFilterReferences = (filters?: Filters): boolean => {
  return !!filters && Object.values(filters.preferredReference).some((value) => value);
};

const filterReferences = (
  formattedReferences: FormattedReference[],
  filters: Filters,
  includeMissingAsNA?: boolean
): FormattedReference[] => {
  let filteredReferences = formattedReferences.filter((ref) => filters.preferredReference[ref.qualifier] === true);

  if (includeMissingAsNA) {
    const missingReferences = getMissingReferences(filteredReferences, filters);
    filteredReferences = [...filteredReferences, ...missingReferences];
  }

  return filteredReferences;
};

const getMissingReferences = (filteredReferences: FormattedReference[], filters: Filters): FormattedReference[] => {
  return Object.keys(filters.preferredReference)
    .filter(
      (qualifier) =>
        filters.preferredReference[qualifier] && !filteredReferences.some((ref) => ref.qualifier === qualifier)
    )
    .map((qualifier) => ({
      qualifier: qualifier as ReferenceQualifierEnum,
      value: 'N/A'
    }));
};

const filterByDefaultQualifier = (formattedReferences: FormattedReference[]): FormattedReference[] => {
  return formattedReferences.filter((ref) => ref.qualifier === ReferenceQualifierEnum.ShipmentReferenceId);
};
