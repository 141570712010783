import {DashboardColumnsSchema} from 'App/components/dashboards/commons/dashboardColumnSchema';
import {RowCell} from './rowCell/rowCell';

export interface TableBodyProps<TItem> {
  data?: TItem[];
  fullWidth?: boolean;
  columnsSchema: DashboardColumnsSchema<TItem>;
}

export function TableBody<TItem>(props: TableBodyProps<TItem>) {
  return (
    <tbody>
      {props.data?.map((item, rowIndex) => (
        <tr key={rowIndex} className="even:bg-sw-background hover:outline hover:outline-sw-primary hover:outline-1">
          {props.columnsSchema?.map((columnSchema, columnIdx) => (
            <RowCell
              key={columnIdx}
              fullWidth={props.fullWidth}
              rowIndex={rowIndex}
              columnIdx={columnIdx}
              item={item}
              columnSchema={columnSchema}
            />
          ))}
        </tr>
      ))}
    </tbody>
  );
}
