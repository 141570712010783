import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import moment from 'moment-timezone';
import {Formik, Field} from 'formik';
import {object, mixed, string} from 'yup';
import {
  FormikTextInput,
  FormikPhoneInput,
  FormikCheckbox,
  FormikDateTimePicker,
  FormikSelect,
  DeprecatedButton,
  Card
} from '@shipwell/shipwell-ui';
import {getFedExRegistrationAccounts} from 'App/containers/fedex/actions/async';
import {getUPSRegistrationAccounts} from 'App/containers/ups/actions/async';
import {getUSPSRegistrationAccounts} from 'App/containers/usps/actions/async';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {nullableEmailSchemaField, nullablePhoneNumberSchemaField} from 'App/utils/yupHelpersTyped';
import './styles.scss';

/** Default values */
export const defaultValues = {
  fedex_account: null,
  ups_account: null,
  usps_account: null,
  address: {},
  point_of_contact: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: ''
  },
  scheduled_date: '',
  time_window_start: '',
  time_window_end: '',
  is_regularly_scheduled: false,
  carrier_code: ''
};

/**
 * Schedule Pickup Form
 * @param {*} props
 */
const SchedulePickupForm = (props) => {
  const {
    values,
    defaultValues,
    dispatch,
    onCancelPickup,
    onCancel,
    onSubmit,
    providerCode,
    isReadOnlyAddress,
    ...formProps
  } = props;
  const [parcelAccounts, setParcelAccounts] = useState(props.parcelAccounts);

  /** validation rules */
  const validationSchema = object().shape({
    fedex_account: mixed().test('fedex_account', 'Fedex account is required', function (value) {
      if (providerCode === 'FEDEX') {
        return Boolean(value);
      }
      return true;
    }),
    ups_account: mixed().test('ups_account', 'UPS account is required', function (value) {
      if (providerCode === 'UPS') {
        return Boolean(value);
      }
      return true;
    }),
    address: mixed().test('address', 'A valid address is required.', (value) => value?.country || true),
    point_of_contact: object().shape({
      first_name: string().required('First name is required.'),
      email: nullableEmailSchemaField(),
      phone_number: nullablePhoneNumberSchemaField('phone_number')
    }),
    scheduled_date: mixed()
      .test('scheduled_date', "Pickup date must not be before today's date.", function (value) {
        if (value) {
          const now = moment().format('YYYY-MM-DD');
          return moment(value).isSameOrAfter(now);
        }
        return true;
      })
      .test(
        'scheduled_date_tomorrow',
        "Only next day pickup is allowed. Please choose tomorrow's date.",
        function (value) {
          if (value && providerCode === 'USPS' && !this.parent.is_regularly_scheduled) {
            const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
            return moment(value).isSame(tomorrow);
          }
          return true;
        }
      )
      .test(
        'fedex_ground_date',
        'The earliest available pickup date is tomorrow. Please a choose another date.',
        function (value) {
          if (
            value &&
            !this.parent.is_regularly_scheduled &&
            (this.parent.carrier_code === 'FDXG' || this.parent.carrier_code === 'FXSP')
          ) {
            const today = moment().format('YYYY-MM-DD');
            return moment(value).isAfter(today, 'day');
          }
          return true;
        }
      )
      .required('Pickup date is required.'),
    time_window_start: string().nullable().required('Ready time is required.'),
    time_window_end: mixed()
      .required('Latest available time is required.')
      .test('time_window_end', 'Latest available time must be at least 2 hours after Ready Time.', function (value) {
        const {time_window_start: timeWindowStart} = this.parent;
        if (value && timeWindowStart) {
          const startTime = moment(timeWindowStart, 'HH:mm');
          const endTime = moment(value, 'HH:mm');
          return startTime.add(2, 'hours').isSameOrBefore(endTime);
        }
        return true;
      })
  });

  /**
   * Request FedEx accounts
   */
  const getParcelRegistrationAccounts = async () => {
    try {
      let response = {};

      if (providerCode === 'FEDEX') {
        response = await dispatch(getFedExRegistrationAccounts());
      }
      if (providerCode === 'UPS') {
        response = await dispatch(getUPSRegistrationAccounts());
      }

      if (providerCode === 'USPS') {
        response = await dispatch(getUSPSRegistrationAccounts());
      }

      if (response && response.body && response.body.results.length) {
        setParcelAccounts(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddressSelection = (selection, setFieldValue) => {
    if (selection?.address?.id) {
      setFieldValue('point_of_contact', selection.point_of_contacts[0]);
    }
  };

  /** Form cancel pickup callback  */
  const handleCancelPickup = (values) => {
    if (onCancelPickup) {
      onCancelPickup(values);
    }
  };

  /** Form cancel callback  */
  const handleCancel = (values, actions) => {
    if (onCancel) {
      onCancel(values, actions);
    }
  };

  /** Form submit callback  */
  const handleSubmit = (values, actions) => {
    // hack because back end doesn't expose USPS account number on rfqs and shipments
    // if its a USPS pickup and there's not an account number, default to Shipwell USPS account
    if (values.provider_code && values.provider_code === 'USPS' && !values.usps_account) {
      values.usps_account = parcelAccounts[0] && parcelAccounts[0].id;
    }
    if (onSubmit) {
      onSubmit(values, actions);
    }
  };

  useEffect(() => {
    if (!parcelAccounts.length) {
      getParcelRegistrationAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formProps}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{...defaultValues, provider_code: providerCode, ...values}}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, setFieldValue, setFieldTouched, isSubmitting, values}) => {
        const providerName = values.provider_code ? values.provider_code : providerCode;

        return (
          <form className="scheduled-pickup-form" noValidate="novalidate" onSubmit={handleSubmit}>
            <div className="field-grid">
              {providerCode !== 'USPS' && (
                <div className="grid-item-2">
                  <Field
                    simpleValue
                    required
                    clearable={false}
                    label={`${providerName === 'FEDEX' ? 'FedEx' : providerName} Account ID`}
                    name={`${providerName.toLowerCase()}_account`}
                    getOptionLabel={({account_number: accountNumber}) => accountNumber}
                    options={parcelAccounts}
                    component={FormikSelect}
                  />
                </div>
              )}
            </div>
            <Card title="Pickup Address">
              <div className="field-grid">
                <div className="grid-item-2">
                  <Field
                    required
                    label="Address"
                    name="address"
                    disabled={isReadOnlyAddress}
                    onAddressSelect={(selection) => handleAddressSelection(selection, setFieldValue)}
                    onFieldBlur={() => setFieldTouched('address')}
                    labelKey="description"
                    component={AddressSearch}
                  />
                </div>
              </div>
            </Card>
            <Card title="Contact Information">
              <div className="field-grid">
                <Field required name="point_of_contact.first_name" label="First Name" component={FormikTextInput} />
                <Field name="point_of_contact.last_name" label="Last Name" component={FormikTextInput} />
                <Field name="point_of_contact.phone_number" label="Phone Number" component={FormikPhoneInput} />
                <Field name="point_of_contact.email" label="Email Address" component={FormikTextInput} />
              </div>
            </Card>
            <Card title="Package Information">
              <div className="field-grid">
                <div className="grid-item-2">
                  <Field
                    required
                    showTimeSelect={false}
                    name="scheduled_date"
                    label="Pickup Date"
                    component={FormikDateTimePicker}
                  />
                </div>
                <Field
                  required
                  showTimeSelectOnly
                  name="time_window_start"
                  label="Ready Time"
                  component={FormikDateTimePicker}
                />
                <Field
                  required
                  showTimeSelectOnly
                  name="time_window_end"
                  label="Latest Available Time"
                  component={FormikDateTimePicker}
                />
              </div>
            </Card>
            <div className="grid-checkbox-field smartpost-checkbox">
              <Field label="This is a recurring pickup" name="is_regularly_scheduled" component={FormikCheckbox} />
              {values.is_regularly_scheduled && (
                <p className="field-warning">
                  Recurring pickups must be set up through {providerName === 'FEDEX' ? 'FedEx' : providerName}. Contact{' '}
                  {providerName === 'FEDEX' ? 'FedEx' : providerName} to schedule your recurring pickup. Each recurring
                  pickup must be entered manually in Shipwell.
                </p>
              )}
            </div>
            <div className="sw-modal-footer">
              <div>
                {values.id && (
                  <DeprecatedButton variant="warning" disabled={isSubmitting} onClick={handleCancelPickup}>
                    Cancel Pickup
                  </DeprecatedButton>
                )}
              </div>
              <div className="sw-modal-footer-primary">
                <DeprecatedButton variant="secondary" disabled={isSubmitting} onClick={handleCancel}>
                  Cancel
                </DeprecatedButton>
                <DeprecatedButton
                  disabled={isSubmitting}
                  icon={isSubmitting && <i className="icon icon-Restart rotate" />}
                  onClick={handleSubmit}
                >
                  Save
                </DeprecatedButton>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

SchedulePickupForm.defaultProps = {
  defaultValues,
  parcelAccounts: []
};

SchedulePickupForm.propTypes = {
  values: PropTypes.object,
  defaultValues: PropTypes.object,
  dispatch: PropTypes.func,
  onCancelPickup: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  providerCode: PropTypes.string,
  isReadOnlyAddress: PropTypes.bool,
  parcelAccounts: PropTypes.object
};

export default connect()(SchedulePickupForm);
