import {useMutation, UseMutationOptions, UseMutationResult} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ShipmentMode, GenesisRatingSchemasRatingRateRequest} from '@shipwell/genesis-sdk';
import {
  RFQ,
  Shipment,
  SlimRFQ,
  FedexShipmentOptions,
  UPSShipmentOptions,
  USPSShipmentOptions
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getRfqDetails} from 'App/api/quoting/typed';
import {fetchEquipmentTypesPromise} from 'App/api/shipment/typed';
import {createRateRequest} from 'App/api/genesis/typed';
import {getCapacityProvidersByMode} from 'App/api/integrations/typed';
import {toCreateRateRequest} from 'App/containers/InstantRatesV2/common/transform';
import {PackagingTypes} from 'App/utils/packagingTypes';
import {getShipmentModeFlags} from 'App/containers/quotes/create/utils/createQuote';
import store from 'App/routes/store';
import {createRfq} from 'App/api/quoting/typed';
import {getFedexAccounts} from 'App/api/registration/typed';
import {State} from 'App/reducers/types';

interface OptionsRFQ {
  fedex_specific_options: FedexShipmentOptions;
  usps_specific_options: UPSShipmentOptions;
  ups_specific_options: USPSShipmentOptions;
}

/**
 * Hook to create a new quote
 * @param shipment
 * @param legacyRfq
 * @param options
 */
export const useCreateNewQuoteMutation = ({
  shipment,
  legacyRfq,
  options
}: {
  shipment: Shipment;
  legacyRfq?: SlimRFQ | RFQ | null;
  options?: Omit<
    UseMutationOptions<
      {rfq?: RFQ; rateRequestId?: string; rateRequest?: GenesisRatingSchemasRatingRateRequest},
      AxiosError,
      Shipment,
      unknown
    >,
    'queryFn'
  >;
}): UseMutationResult<
  {rfq?: RFQ; rateRequestId?: string; rateRequest?: GenesisRatingSchemasRatingRateRequest},
  AxiosError,
  Shipment,
  unknown
> => {
  const {userCompany: {company, feature_flags} = {}} = (store.getState() || {}) as State;

  const xCompanyId =
    company?.id && legacyRfq?.company_owner_id && company.id !== legacyRfq.company_owner_id
      ? legacyRfq.company_owner_id
      : undefined;

  let rfq: RFQ | undefined;

  const createNewQuote = async (rfq?: RFQ) => {
    const {isLTL, isFTL} = getShipmentModeFlags({mode: shipment.mode});
    let rfqResponse = rfq;

    if (!rfqResponse) {
      const {fedex_enabled: fedExEnabled} = feature_flags || {};

      const config: OptionsRFQ = {
        fedex_specific_options: {},
        ups_specific_options: {},
        usps_specific_options: {}
      };

      const rfqPayload = {
        autoquote: isLTL,
        shipment: shipment.id,
        shipment_modes: shipment.mode ? [shipment.mode] : [],
        equipment_types: shipment.equipment_type ? [shipment.equipment_type] : [],
        has_parent_rfq: false,
        parent_rfq: null as unknown as undefined
      };

      const isThirdPartyShipment = !!shipment.customer?.id && !!company?.id && shipment.customer.id !== company.id;
      if (isLTL && fedExEnabled && !isThirdPartyShipment) {
        const fedexAccounts = await getFedexAccounts();

        if (fedexAccounts?.results?.length) {
          const fedexFreightAccount = fedexAccounts.results.find(
            (result) => result.is_freight && !!result.meter_number
          );
          if (fedexFreightAccount) {
            config.fedex_specific_options = {
              account: fedexFreightAccount.id,
              packaging: PackagingTypes.YourPackaging
            };
          }
        }
      }

      const rfqPayloadWithOptions = {
        ...rfqPayload,
        fedex_specific_options: config.fedex_specific_options.account
          ? config.fedex_specific_options
          : (null as unknown as undefined),
        ups_specific_options: null as unknown as undefined,
        usps_specific_options: null as unknown as undefined
      };

      rfqResponse = await createRfq({
        rFQ: rfqPayloadWithOptions,
        ...(isThirdPartyShipment && !!shipment.customer?.id && {xCompanyId: shipment.customer.id})
      });
    }

    if (!rfqResponse?.id) {
      throw Error('Could not create legacy RFQ');
    }

    let genesisResponse;
    if (isFTL || isLTL) {
      genesisResponse = await createGenesisRateRequest();
    }

    return {rateRequestId: genesisResponse?.rateRequestId, rfq: rfqResponse, rateRequest: genesisResponse?.rateRequest};
  };

  /**
   * create request to LTL or FTL to genesis
   * @param shipment
   */
  const createGenesisRateRequest = async () => {
    const mode = shipment.mode?.code === ShipmentMode.Ltl ? ShipmentMode.Ltl : ShipmentMode.Ftl;
    const connections = await getCapacityProvidersByMode(mode);

    if (connections.length !== 0) {
      const {data: equipmentTypes} = await fetchEquipmentTypesPromise();
      const request = toCreateRateRequest(shipment, equipmentTypes, connections);
      const rateRequest = await createRateRequest({
        createRateRequest: request
      });

      const rateRequestId = rateRequest?.id;
      if (!rateRequestId) {
        throw Error('Rate Request did not return an identifier.');
      }

      return {rateRequestId: rateRequestId, rateRequest};
    }
  };

  return useMutation(async () => {
    if (!shipment.mode?.code) {
      throw new Error('Shipment mode is required.');
    }
    if (![ShipmentMode.Ltl, ShipmentMode.Ftl].some((mode) => mode === shipment.mode?.code)) {
      throw new Error('Only LTL and FTL are supported.');
    }

    if (legacyRfq?.id) {
      const {data} = await getRfqDetails({
        rfqId: legacyRfq.id,
        includeFailures: true,
        xCompanyId
      });
      rfq = data;
    }

    const mode = shipment.mode?.code === ShipmentMode.Ltl ? ShipmentMode.Ltl : ShipmentMode.Ftl;
    const connections = await getCapacityProvidersByMode(mode);

    if (mode === ShipmentMode.Ltl && !!legacyRfq && !connections.length) {
      return {rfq};
    }
    return createNewQuote(rfq);
  }, options);
};
