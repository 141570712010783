import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {RateStatus} from '@shipwell/genesis-sdk';
import {RFQ, SlimRFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getRfqDetails, SourceTypeEnum} from 'App/api/quoting/typed';
import {RFQ_DETAILS_KEY, RATE_REQUEST_KEY} from 'App/data-hooks/queryKeys';
import {Rate} from 'src/@types/quotingTypes';
import {listRatesByRequestId} from 'App/api/genesis/typed';
import {instantQuotesFilter} from 'App/containers/Marketplace/components/InstantRates/legacy/utils';
import {transformRate, transformRatesLegacy} from 'App/containers/InstantRatesV2/hooks/utils';
import {useEffect, useState} from 'react';
import store from 'App/routes/store';
import {State} from 'App/reducers/types';

type PollingResponse = {
  response: {
    rates: Rate[];
    rfq?: RFQ | undefined;
  };
  hasPolledRates: boolean;
};

export const useRatesPolling = ({
  legacyRfq,
  rateRequestId
}: {
  legacyRfq?: SlimRFQ | RFQ;
  rateRequestId?: string;
}): PollingResponse => {
  const {userCompany: {company} = {}} = (store.getState() || {}) as State;

  const legacyRfqId = legacyRfq?.id;

  const xCompanyId =
    company?.id && legacyRfq?.company_owner_id && company.id !== legacyRfq.company_owner_id
      ? legacyRfq.company_owner_id
      : undefined;

  const rfqRefetchIntervalCallback = (data?: RfqQueryResponse) => {
    return !legacyRfqId || !data?.rfq?.autoquote || data?.rfq?.did_finish_autoquote ? 0 : 2000;
  };

  const genesisRefetchIntervalCallback = (data?: GenesisPollingResponse) => {
    const {status} = data || {};
    return !rateRequestId || [RateStatus.Completed, RateStatus.Errored].some((rateStatus) => rateStatus === status)
      ? 0
      : 1500;
  };

  // temporary hack until backend fixes polling logic
  const [genesisRatesCount, setGenesisRatesCount] = useState<number>(0);
  const hackGenesisRefetchIntervalCallback = (data?: GenesisPollingResponse) => {
    if (genesisRefetchIntervalCallback(data)) {
      return genesisRefetchIntervalCallback(data);
    }

    const moreRatesFound = data?.rates.length && data.rates.length > genesisRatesCount;
    if (moreRatesFound) {
      setGenesisRatesCount(data.rates.length);
    }
    const stillProcessing = !data?.rates.every((rate) =>
      [RateStatus.Completed, RateStatus.Errored].some((rateStatus) => rateStatus === rate.status)
    );

    return moreRatesFound || stillProcessing ? 5000 : 0;
  };

  useEffect(() => {
    if (rateRequestId) {
      setGenesisRatesCount(0);
    }
  }, [rateRequestId]);

  const baseOptions: UseQueryOptions<
    RfqQueryResponse | GenesisPollingResponse,
    AxiosError,
    RfqQueryResponse | GenesisPollingResponse,
    string[]
  > = {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    retry: 0,
    refetchOnMount: true
  };

  const rfqQuery = useRfqRatesPolling({
    legacyRfqId,
    xCompanyId,
    options: {...baseOptions, refetchInterval: rfqRefetchIntervalCallback}
  });
  const genesisQuery = useGenesisRatesPolling({
    rateRequestId,
    legacyRfqId,
    options: {
      ...baseOptions,
      // temporary hack until backend fixes polling logic
      refetchInterval: (data) => genesisRefetchIntervalCallback(data) || hackGenesisRefetchIntervalCallback(data)
    }
  });

  const combinedRates = [...(rfqQuery.data?.rates || []), ...(genesisQuery.data?.rates || [])];
  const {rfq} = rfqQuery.data || {};

  const response = {rates: combinedRates, rfq};

  const isLoading = (legacyRfq && rfqQuery.isLoading) || (rateRequestId && genesisQuery.isLoading);
  const hasPolledRates =
    !rfqRefetchIntervalCallback(rfqQuery.data) && !genesisRefetchIntervalCallback(genesisQuery.data) && !isLoading;

  return {response, hasPolledRates};
};

type RfqQueryResponse = {rates: Rate[]; rfq?: RFQ};
const useRfqRatesPolling = ({
  legacyRfqId,
  xCompanyId,
  options
}: {
  legacyRfqId?: string | null;
  xCompanyId?: string;
  options?: Omit<UseQueryOptions<RfqQueryResponse, AxiosError, RfqQueryResponse, string[]>, 'queryKey'>;
}) =>
  useQuery(
    [RFQ_DETAILS_KEY, legacyRfqId || ''],
    async () => {
      if (legacyRfqId) {
        const {data: rfq} = await getRfqDetails({
          rfqId: legacyRfqId,
          includeFailures: true,
          xCompanyId
        });

        const rates = instantQuotesFilter(rfq?.quotes || [])
          .filter(({source_type: sourceType}) => sourceType === SourceTypeEnum.Instant)
          .map((value) => transformRatesLegacy(value, (value.rfq || rfq.id) as string));
        return {rates, rfq};
      }
      return {rates: []};
    },
    {
      ...options
    }
  );

type GenesisPollingResponse = {rates: Rate[]; status?: RateStatus};
const useGenesisRatesPolling = ({
  rateRequestId,
  legacyRfqId,
  options
}: {
  rateRequestId?: string;
  legacyRfqId?: string | null;
  options?: Omit<UseQueryOptions<GenesisPollingResponse, AxiosError, GenesisPollingResponse, string[]>, 'queryKey'>;
}) =>
  useQuery(
    [RATE_REQUEST_KEY, rateRequestId || ''],
    async () => {
      if (rateRequestId) {
        const {data, status} = await listRatesByRequestId(rateRequestId);
        const rates = data
          .filter(
            (rate) => ![RateStatus.Created, RateStatus.Processing].some((rateStatus) => rateStatus === rate.status)
          )
          .map((value) => transformRate(value, rateRequestId, legacyRfqId));

        return {rates, status};
      }
      return {rates: [], status: undefined};
    },
    {
      ...options
    }
  );
