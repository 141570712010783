import {Tooltip, IconButton, DeprecatedButton} from '@shipwell/shipwell-ui';
import {Button as BsButton} from 'react-bootstrap';
import {Row, createColumnHelper} from '@tanstack/react-table';
import moment from 'moment';
import {Quote, SpotNegotiation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ReactNode} from 'react';
import {formatDateTime} from 'App/utils/globals';
import {getLowestBid} from 'App/utils/globalsTyped';
import {formatCurrency} from 'App/utils/internationalConstants';

const columnHelper = createColumnHelper<SpotNegotiation>();

/**
 * Conditionally returns a tooltip overlay
 */
const renderOverlay = (row: Row<SpotNegotiation>, children: ReactNode) => {
  // check if the carrier is active or Shipwell
  return !['ACTIVE', null].some((status) => status === row.original?.carrier_status) ? (
    <Tooltip tooltipContent="Carrier Status is Not Active">{children}</Tooltip>
  ) : (
    <>{children}</>
  );
};

export const getColumns = ({
  handleSelectSpotNegotiation,
  handleSelect,
  setDocumentPreviewQuote,
  compactView,
  canTakeActionOnBid
}: {
  handleSelectSpotNegotiation: (spotNegotiation: SpotNegotiation) => void;
  handleSelect: (quote: Quote) => void;
  setDocumentPreviewQuote: (documentPreviewQuote: Quote | null) => void;
  compactView: boolean;
  canTakeActionOnBid: boolean;
}) => [
  columnHelper.accessor('vendor.name', {
    id: 'carrier',
    header: 'Carrier',
    cell: (info) => {
      const rowContent =
        !['ACTIVE', null].some((status) => status === info.row.original?.carrier_status) || !canTakeActionOnBid ? (
          <span className="bids-card-inactive-carrier-name">{info.getValue()}</span>
        ) : (
          <DeprecatedButton variant="tertiary" onClick={() => handleSelectSpotNegotiation(info.row.original)}>
            {info.getValue()}
          </DeprecatedButton>
        );
      return renderOverlay(info.row, rowContent);
    },
    enableSorting: true
  }),
  columnHelper.accessor('message_count', {
    id: 'message_count',
    header: 'Messages',
    cell: (info) => info.getValue(),
    enableSorting: true
  }),
  ...(!compactView
    ? [
        columnHelper.accessor('created_at', {
          id: 'created_at',
          header: 'Created Date',
          cell: (info) => {
            return renderOverlay(info.row, <span>{formatDateTime(info.getValue(), true)}</span>);
          },
          enableSorting: true
        }),
        columnHelper.accessor(
          (row) => {
            const lowestBid = getLowestBid({shipmentSpotNegotiation: row});
            return lowestBid?.created_at;
          },
          {
            id: 'submitted',
            header: 'Submitted',
            cell: (info) => {
              const lowestBid = getLowestBid({shipmentSpotNegotiation: info.row.original});
              return info.getValue()?.length
                ? renderOverlay(info.row, <span>{formatDateTime(lowestBid?.created_at, true)}</span>)
                : '--';
            },
            enableSorting: true,
            sortUndefined: 'last'
          }
        )
      ]
    : []),
  columnHelper.accessor(
    (row) => {
      const lowestBid = getLowestBid({shipmentSpotNegotiation: row});
      return lowestBid?.total;
    },
    {
      id: 'bid',
      header: 'Bid',
      cell: (info) => {
        if (!info.getValue()) {
          return <div className="marketplaceBid-amount flex items-center gap-x-1">--</div>;
        }

        const lowestBid = getLowestBid({shipmentSpotNegotiation: info.row.original});
        const {documents} = lowestBid?.spot_negotiation_quote || {};
        const numberOfQuotes = info.row.original.quotes?.length || 0;

        return renderOverlay(
          info.row,
          <div className="marketplaceBid-amount flex items-center gap-x-1">
            {formatCurrency(lowestBid?.total, lowestBid?.currency)}
            {documents?.length ? (
              <IconButton
                onClick={() => setDocumentPreviewQuote(lowestBid)}
                iconName="Document"
                aria-label="Document"
              />
            ) : null}
            {
              //when we have more than one quote, show a number to indicate there are more
              numberOfQuotes > 1 ? <span className="text-primary">({numberOfQuotes - 1})</span> : null
            }
          </div>
        );
      },
      enableSorting: true,
      sortUndefined: 'last'
    }
  ),
  columnHelper.accessor('quotes', {
    header: 'Action',
    cell: (info) => {
      if (!canTakeActionOnBid) return null;
      if (info.row.original.is_most_recent_winner) {
        return <span className="text-success">Won</span>;
      }
      if (info.row.original.closed_by_customer_at && moment(info.row.original.closed_by_customer_at).isValid()) {
        return (
          <BsButton disabled bsStyle="danger">
            Closed
          </BsButton>
        );
      }
      if (info.row.original?.carrier_status !== 'ACTIVE') {
        return (
          <BsButton disabled bsStyle="danger">
            Inactive
          </BsButton>
        );
      }
      if (info.getValue()?.length === 0) {
        return (
          <BsButton disabled bsStyle="danger">
            No Bids
          </BsButton>
        );
      }
      return (
        <BsButton
          bsStyle="primary"
          onClick={() => {
            const lowestBid = getLowestBid({shipmentSpotNegotiation: info.row.original});
            if (lowestBid) handleSelect(lowestBid);
          }}
        >
          Select
        </BsButton>
      );
    },
    enableSorting: false
  })
];
