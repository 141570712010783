import {useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {OrdersApiListOrdersRequest} from '@shipwell/corrogo-sdk';
import {ORDERS_QUERY_KEY} from '../queryKeys';
import {getOrdersList} from 'App/api/corrogo/orders';

export const useGetOrdersList = (
  params: OrdersApiListOrdersRequest,
  queryOptions?: Omit<UseQueryOptions<OrdersApiListOrdersRequest>, 'queryFn' | 'queryKey'>
) => {
  const {decimalSupportForShipmentLineItems}: {decimalSupportForShipmentLineItems: boolean} = useFlags();
  const queryClient = useQueryClient();
  const {data, isLoading, isFetching, error} = useQuery({
    queryKey: [ORDERS_QUERY_KEY, params],
    queryFn: () => getOrdersList(params, decimalSupportForShipmentLineItems),
    enabled: queryOptions?.enabled ?? true
  });

  const orderListRefresh = async () => await queryClient.invalidateQueries([ORDERS_QUERY_KEY]);

  return {
    paginatedOrders: data,
    isLoadingOrders: isLoading,
    isFetchingOrders: isFetching,
    ordersError: error,
    orderListRefresh
  };
};
