import {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {Modal, Toast} from '@shipwell/shipwell-ui';
import {GET_SHIPMENT_CARRIER} from 'App/actions/types';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import View, {carrierEquipmentContexts} from 'App/containers/Shipment/components/CarrierEquipment/View';
import Edit from 'App/containers/Shipment/components/CarrierEquipment/Edit';
import {getCurrentCarrier} from 'App/containers/Shipment/components/CarrierEquipment/utils';
import WithStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';

import 'App/containers/Shipment/components/CarrierEquipment/styles.scss';
import moment from 'moment';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';
import {getShipment} from 'App/api/shipment/typed';

const ShipmentCarrierEquipment = ({
  editing,
  handleEdit,
  shipment,
  setError,
  company,
  dispatch,
  carrierConfig,
  getCarrierConfig,
  handleSuccess,
  handleWarning,
  ...props
}) => {
  const toast = useVisibility();

  const initialShipmentContractState = useMemo(() => {
    return {
      contractAppliedAt: shipment?.relationship_to_vendor?.contract_applied_at,
      contractId: shipment?.relationship_to_vendor?.contract_id
    };
  }, [shipment]);

  const [initialCarrierDetails, setInitialCarrierDetails] = useState({});
  useEffect(() => {
    if (shipment.id) {
      getCarrierConfig(shipment.id);
    }
  }, [shipment, getCarrierConfig]);

  useEffect(() => {
    const getCarrierPoc = async (vendorId) => {
      try {
        const response = await getCarrierRelationshipsPromise({vendorId});
        if (response.body?.results?.length) {
          // the first result should always be correct since we look up by ID
          setInitialCarrierDetails(response.body?.results[0]);
          //put this in redux for use in the references section
          dispatch({
            type: GET_SHIPMENT_CARRIER,
            payload: response.body?.results[0]
          });
        }
      } catch (error) {
        console.error('Unable to get Vendor POC', error);
      }
    };

    if (carrierConfig?.vendor) {
      getCarrierPoc(carrierConfig.vendor.id);
    } else if (getCurrentCarrier(shipment)?.id === company?.id) {
      //user's company is the current carrier
      setInitialCarrierDetails({shipwell_vendor: company});
    }
  }, [carrierConfig, company, shipment, dispatch]);

  const handleShipmentContractApply = async () => {
    const shipmentAfterUpdate = await getShipment(shipment.id);

    const hadContractBeforeUpdate = initialShipmentContractState?.contractId;
    const hasContractAfterUpdate = shipmentAfterUpdate?.relationship_to_vendor?.contract_id;

    if (hadContractBeforeUpdate && !hasContractAfterUpdate) {
      toast.showWithData({
        message: `Carrier ${initialCarrierDetails?.name} does not have a contract that meets the new shipment configuration.  They have been removed from the shipment.  Please tender the shipment to an available carrier.`,
        variant: 'error'
      });
    }

    if (
      !!initialShipmentContractState?.contractAppliedAt &&
      !!shipmentAfterUpdate?.relationship_to_vendor?.contract_applied_at
    ) {
      const hasContractAppliedAtDateChanged = !moment(initialShipmentContractState?.contractAppliedAt).isSame(
        shipmentAfterUpdate?.relationship_to_vendor?.contract_applied_at
      );

      if (hasContractAppliedAtDateChanged) {
        toast.showWithData({
          message: 'Re-applying contract to carrier after shipment changes have been made…',
          variant: 'success'
        });
      }
    }
  };

  return (
    <>
      <View
        carrierConfig={carrierConfig}
        initialCarrierDetails={initialCarrierDetails}
        handleSuccess={handleSuccess}
        handleEdit={handleEdit}
        shipment={shipment}
        context={carrierEquipmentContexts.SHIPMENT_DETAILS}
        {...props}
      />
      <Modal show={editing} title="Carrier & Equipment" footerComponent={null} onClose={handleEdit(false)}>
        <Edit
          handleSuccess={handleSuccess}
          handleWarning={handleWarning}
          onCancelEdit={handleEdit(false)}
          carrierConfig={carrierConfig}
          setError={setError}
          initialCarrierDetails={initialCarrierDetails}
          shipment={shipment}
          handleShipmentContractApply={handleShipmentContractApply}
          context={carrierEquipmentContexts.SHIPMENT_DETAILS}
          {...props}
        />
      </Modal>
      <Toast
        show={toast.isVisible}
        title={toast.data?.title}
        variant={toast.data?.variant}
        onClose={toast.hideAndClearData}
      >
        {toast.data?.message}
      </Toast>
    </>
  );
};

ShipmentCarrierEquipment.propTypes = {
  editing: PropTypes.bool.isRequired,
  shipment: PropTypes.object,
  company: PropTypes.object,
  handleEdit: PropTypes.func,
  dispatch: PropTypes.func,
  carrierConfig: PropTypes.object,
  getCarrierConfig: PropTypes.func,
  handleSuccess: PropTypes.func,
  ...WithStatusToastsPropTypes,
  handleWarning: PropTypes.func
};

ShipmentCarrierEquipment.defaultProps = {
  editing: false,
  shipment: null,
  handleEdit: () => {},
  setError: () => {}
};

const mapStateToProps = (state) => ({
  company: state.userCompany.company
});
export default compose(WithStatusToasts, connect(mapStateToProps))(ShipmentCarrierEquipment);
