import {SvgIcon, TextInput} from '@shipwell/shipwell-ui';
import {ReactNode} from 'react';
import {useOptimisticDebounce} from '../useOptimisticDeboucer/useOptimisticDebouncer';
import {remoteLoading} from '../useRemoteLoading/remoteLoading';

export interface DashboardHeaderProps {
  title: string;
  leftElements?: ReactNode;
  rightElements?: ReactNode;
  showSearchTerm?: boolean;
  searchTerm?: string;
  children?: ReactNode;
  onSearchTermChange?: (term: string) => void;
}

export function DashboardHeader(props: DashboardHeaderProps) {
  const debouncer = useOptimisticDebounce({
    value: props.searchTerm,
    onSubmit: props.onSearchTermChange,
    onBeforeDebounce: () => {
      remoteLoading(true);
    },
    onAfterDebounce: () => {
      remoteLoading(false);
    }
  });

  return (
    <div className={'bg-sw-background border-b-1 border-b-sw-border'}>
      <div className="flex justify-between items-center py-3 px-5">
        <div className="flex gap-3 items-center">
          {props.leftElements && <div>{props.leftElements}</div>}
          {props.title && <div className="font-medium text-2xl">{props.title}</div>}
          {props.showSearchTerm && (
            <div>
              <TextInput
                name="dashboard-global-search"
                prepend={<SvgIcon name="Search" />}
                label={`Search for ${props.title}`}
                value={debouncer.optimisticValue}
                onChange={(evt) => debouncer.submit(evt.target.value)}
              />
            </div>
          )}
          {props.children && <div>{props.children}</div>}
        </div>

        {props.rightElements && <div>{props.rightElements}</div>}
      </div>
    </div>
  );
}
