import {DEFAULT_COLUMN_WIDTH} from 'App/components/dashboards/commons/constants';
import {DashboardColumnSchema} from 'App/components/dashboards/commons/dashboardColumnSchema';
import classNames from 'classnames';

export interface RowCellProps<TItem> {
  item: TItem;
  fullWidth?: boolean;
  rowIndex: number;
  columnIdx: number;
  columnSchema: DashboardColumnSchema<TItem>;
}

export function RowCell<TItem>(props: RowCellProps<TItem>) {
  const colWidth = props.fullWidth ? undefined : props.columnSchema.width || DEFAULT_COLUMN_WIDTH;

  return (
    <td
      className={classNames('p-2', {
        'sticky left-0 z-10 border-r-2 border-sw-border-light': props.columnIdx === 0 && props.columnSchema.isFixed,
        'bg-[#fff]': props.rowIndex % 2 === 0,
        'bg-sw-background': props.rowIndex % 2 === 1
      })}
    >
      <div
        className={classNames({
          truncate: props.columnSchema.truncate !== false
        })}
        style={{width: colWidth}}
      >
        {props.columnSchema.content(props.item)}
      </div>
    </td>
  );
}
