import _ from 'lodash';
import {ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  ALERT_ERROR,
  REQ_DATA,
  FETCH_SHIPMENTS,
  FETCH_SHIPMENTS_AND_PERSIST,
  FETCH_UNPAID_SHIPMENTS,
  RESET_SHIPMENTS,
  SELECT_SHIPMENT,
  GET_SHIPPER_CREDIT,
  DISABLE_SIGNUP_SUBMIT,
  ENABLE_SIGNUP_SUBMIT,
  FETCH_SHIPMENT_OFFERS,
  GET_SHIPMENT_POST_OFFERS,
  DELETE_OFFERS,
  CREATE_SHIPMENT,
  EDIT_SHIPMENT,
  CREATE_RFQ,
  SELECT_RFQ,
  SELECT_BROKER_RFQ,
  CREATE_QUOTE,
  SELECT_QUOTE,
  EDIT_QUOTE,
  SELECT_CARRIER_RATE,
  CLEAR_SELECT_CARRIER_RATE,
  AWARD_QUOTE,
  FETCH_ACCESSORIAL_CODES,
  GET_REP_ROLES,
  GET_NMFC_CODES,
  GET_HAZMAT_CODES,
  GET_PACKAGE_TYPES,
  GET_PIECE_TYPES,
  FETCH_PACKAGE_TYPES_SUCCESS,
  FETCH_UPS_PACKAGE_TYPES_SUCCESS,
  FETCH_USPS_PACKAGE_TYPES_SUCCESS,
  GET_EQUIPMENT_TYPES,
  GET_APPOINTMENT_TYPES,
  GET_SERVICE_LEVELS,
  GET_SHIPMENT_MODES,
  GET_CHARGE_CATEGORIES,
  GET_SHIPMENT_STATUSES,
  POPULATE_RECIPIENT_EMAILS,
  SELECT_SHIPMENT_FOR_FORM,
  SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS,
  CLEAR_SELECTED_SHIPMENT,
  SHIPMENTS_ERROR,
  SUCCESSFUL_DISPATCH,
  INITIAL_QUOTE_LOADING,
  INITIAL_QUOTE_VALUES,
  SET_CLONE_TRIGGER,
  INITIAL_SHIPMENT_VALUES,
  TRIGGER_MARKETPLACE_REDIRECT,
  FETCH_TAGS,
  FETCH_TAG,
  CLEAR_TAG
} from './types';
import {shipment, locations, quoting} from 'App/api';
import {getSubdomain} from 'App/utils/location';
import {validateLocation} from 'App/api/locations/typed';

/**
 * @todo Break this file out into separate files
 */

export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

// starts the Loading... spinner
export const requestData = () => ({
  type: REQ_DATA
});

/**
 * Shipments - actions
 */
export const shipmentsRequestSuccess = (response, count) => ({
  type: FETCH_SHIPMENTS,
  payload: response.body,
  meta: {key: count}
});

export const shipmentsRequestSuccessAndPersist = (response) => ({
  type: FETCH_SHIPMENTS_AND_PERSIST,
  payload: response.body
});

export const shipmentsByDateRequestSuccess = (response) => ({
  type: FETCH_UNPAID_SHIPMENTS,
  payload: response.body
});

// Clear fetch shipments
export function resetShipments() {
  return (dispatch) => dispatch({type: RESET_SHIPMENTS});
}

// Get shipments
export function getShipments(opts, count, persistExistingShipments) {
  if (opts.customer) {
    delete opts.customer;
  }
  return (dispatch) => {
    if (!persistExistingShipments) {
      dispatch(requestData());
    }
    return shipment
      .getShipmentsPromise(opts)
      .then((response) => {
        if (persistExistingShipments) {
          dispatch(shipmentsRequestSuccessAndPersist(response));
        } else {
          dispatch(shipmentsRequestSuccess(response, count));
        }
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400};
      });
  };
}

// Get shipments by date
export function fetchShipmentsByDate(opts) {
  const token = localStorage.getItem('dsiajksdjk');
  const shipperId = localStorage.getItem('oovlxkkp');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    dispatch(requestData());

    return shipment
      .fetchShipmentsByDatePromise(shipperId, authorization, opts)
      .then((response) => {
        dispatch(shipmentsByDateRequestSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(displayError({error_description: 'There was an error retrieving shipments.'}));
        return response;
      });
  };
}

/**
 * Shipment - actions
 */
export const externalShipmentRequestSuccess = (response) => ({
  type: SELECT_SHIPMENT,
  payload: response.body
});

// Get external shipment
export function fetchExternalShipment(shipmentId, key) {
  return (dispatch) => {
    return shipment
      .fetchExternalShipmentPromise(shipmentId, key)
      .then((response) => {
        dispatch(externalShipmentRequestSuccess(response));
        return {status: 200, details: response.body};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400, details: response.body};
      });
  };
}

/**
 * Shipment Credit - actions
 */
export const creditOutstandingRequestSuccess = (response) => ({
  type: GET_SHIPPER_CREDIT,
  payload: response.body
});

// Get credit outstanding
export function getCreditOutstanding() {
  const token = localStorage.getItem('dsiajksdjk');
  const shipperId = localStorage.getItem('oovlxkkp');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    return shipment
      .getCreditOutstandingPromise(shipperId, authorization)
      .then((response) => {
        dispatch(creditOutstandingRequestSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

/**
 * Locations - actions
 */
export const disableSignupSubmit = () => ({
  type: DISABLE_SIGNUP_SUBMIT
});

export const enableSignupSubmit = () => ({
  type: ENABLE_SIGNUP_SUBMIT
});

// Validate Address
export function validateAddress(address) {
  return (dispatch) => {
    dispatch(disableSignupSubmit());
    return validateLocation(address)
      .then((axiosResponse) => {
        dispatch(enableSignupSubmit());
        return {status: 200, details: axiosResponse.data};
      })
      .catch((axiosError) => {
        return {status: 400, details: axiosError.response?.data};
      });
  };
}

// Get lat/lng by postal code
export function getLatLngByPostalCode(postalCode) {
  return (dispatch) => {
    return locations
      .validateAddressPromise(postalCode)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

/**
 * Shipment Line Items - actions
 */
export function deleteShipmentLineItem(shipmentId, lineItemId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    return shipment
      .deleteShipmentLineItemPromise(shipmentId, lineItemId, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

/**
 * Shipment Offer Suggestions - actions
 */
export const shipmentOfferSuggestionsRequestSuccess = (response) => ({
  type: FETCH_SHIPMENT_OFFERS,
  payload: response.body
});

export const shipmentPostOfferRequestSuccess = (response) => ({
  type: GET_SHIPMENT_POST_OFFERS,
  payload: response.body
});

export function deleteOffers() {
  return (dispatch) => dispatch({type: DELETE_OFFERS, payload: []});
}

// Get shipment offer suggestions
export function listShipmentOfferSuggestions(shipmentId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    return shipment
      .listShipmentOfferSuggestionsPromise(shipmentId, authorization)
      .then((response) => {
        dispatch(shipmentOfferSuggestionsRequestSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(displayError({error_description: 'There was an error getting offer suggestions.'}));
        return response;
      });
  };
}

// Create shipment offer
export function postShipmentOffer(shipmentId, body) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    return shipment
      .postShipmentOfferPromise(shipmentId, body, authorization)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

// Get shipment offer
export function getPostShipmentOffer(shipmentId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    return shipment
      .getPostShipmentOfferPromise(shipmentId, authorization)
      .then((response) => {
        dispatch(shipmentPostOfferRequestSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(shipmentError(response));
        return response;
      });
  };
}

/**
 * Shipment - actions
 */
export const shipmentCreateSuccess = (response) => ({
  type: CREATE_SHIPMENT,
  payload: response.body
});

export const shipmentUpdateSuccess = (response) => ({
  type: EDIT_SHIPMENT,
  payload: response.body
});

export const shipmentRequestSuccess = (response) => ({
  type: SELECT_SHIPMENT,
  payload: response.body
});

export function createShipment(shipmentObj, opts = {}) {
  // metadata is required
  if (_.isEmpty(shipmentObj.metadata)) {
    shipmentObj.metadata = {};
  }
  if (_.isEmpty(shipmentObj.metadata.tags)) {
    shipmentObj.metadata.tags = [];
  }
  if (_.isEmpty(shipmentObj.metadata.open)) {
    shipmentObj.metadata.open = false;
  }
  if (_.isEmpty(shipmentObj.metadata.archived)) {
    shipmentObj.metadata.archived = false;
  }
  return (dispatch) => {
    return shipment
      .createShipmentPromise(shipmentObj, opts)
      .then((response) => {
        dispatch(shipmentCreateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

export function editShipment(shipmentId, shipmentObj, opts = {}, shipmentUpdates = null) {
  if (_.isEmpty(shipmentObj.metadata)) {
    shipmentObj.metadata = {};
  }
  if (_.isEmpty(shipmentObj.metadata.tags)) {
    shipmentObj.metadata.tags = [];
  }
  if (_.isEmpty(shipmentObj.metadata.open)) {
    shipmentObj.metadata.open = false;
  }
  if (_.isEmpty(shipmentObj.metadata.archived)) {
    shipmentObj.metadata.archived = false;
  }
  const payloadWithUpdates = shipmentUpdates ? _.merge(shipmentObj, shipmentUpdates) : shipmentObj;

  return (dispatch) => {
    dispatch(requestData());

    return shipment
      .editShipmentPromise(shipmentId, payloadWithUpdates, opts)
      .then((response) => {
        dispatch(shipmentUpdateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

export function getShipmentDetails(shipmentId, opts = {}) {
  return (dispatch) => {
    return shipment
      .getShipmentDetailsPromise(shipmentId, opts)
      .then((response) => {
        dispatch(shipmentRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

//populate the selectedShipment in state
export function selectShipment(shipment) {
  return (dispatch) => {
    return dispatch({type: SELECT_SHIPMENT, payload: shipment});
  };
}

/**
 * Quoting - actions
 */
export const rfqCreateSuccess = (response) => ({
  type: CREATE_RFQ,
  payload: response.body
});

export const rfqRequestSuccess = (response) => ({
  type: SELECT_RFQ,
  payload: response.body
});

export const brokerRFQRequestSuccess = (response) => ({
  type: SELECT_BROKER_RFQ,
  payload: response.body
});

export function createRFQ(rfqObj, opts = {}) {
  return (dispatch) => {
    return quoting
      .createRFQPromise(rfqObj, opts)
      .then((response) => {
        dispatch(rfqCreateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

export function getRFQDetails(rfqId, opts = {}) {
  return (dispatch) => {
    return quoting
      .getRFQDetailsPromise(rfqId, opts)
      .then((response) => {
        dispatch(rfqRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function getBrokerRFQDetails(rfqId, opts = {}) {
  return (dispatch) => {
    return quoting
      .getRFQDetailsPromise(rfqId, opts)
      .then((response) => {
        dispatch(brokerRFQRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Quoting - actions
 */
export const quoteCreateSuccess = (response) => ({
  type: CREATE_QUOTE,
  payload: response.body
});

export const quoteRequestSuccess = (response) => ({
  type: SELECT_QUOTE,
  payload: response.body
});

export const quoteUpdateSuccess = (response) => ({
  type: EDIT_QUOTE,
  payload: response.body
});

export function setSelectedBrokerRFQ(rfq) {
  return (dispatch) => dispatch({type: SELECT_BROKER_RFQ, payload: rfq});
}

export function selectQuote(quote) {
  return (dispatch) => dispatch({type: SELECT_QUOTE, payload: quote});
}

export function selectCarrierRate(quote) {
  return (dispatch) => dispatch({type: SELECT_CARRIER_RATE, payload: quote});
}

export function clearSelectedCarrierRate() {
  return (dispatch) => dispatch({type: CLEAR_SELECT_CARRIER_RATE, payload: {}});
}

export function createQuote(rfqId, quoteObj, opts = {}) {
  return (dispatch) => {
    return quoting
      .createQuotePromise(rfqId, quoteObj, opts)
      .then((response) => {
        dispatch(quoteCreateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

export function getQuoteDetails(rfqId, quoteId, opts = {}) {
  return (dispatch) => {
    return quoting
      .getQuoteDetailsPromise(rfqId, quoteId, opts)
      .then((response) => {
        dispatch(quoteRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function editQuote(rfqId, quoteId, quoteObj, opts = {}) {
  if (quoteObj.charge_line_items) {
    quoteObj.charge_line_items = quoteObj.charge_line_items.map((item) => ({
      ...item,
      category: ChargeCategory.Lh,
      charge_code: 'LHS'
    }));
  }
  return (dispatch) => {
    return quoting
      .editQuotePromise(rfqId, quoteId, quoteObj, opts)
      .then((response) => {
        dispatch(quoteUpdateSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return {status: 400, details: response};
      });
  };
}

/**
 * Auto book - actions
 */
export const autoBookShipmentSuccess = (response) => ({
  type: SELECT_SHIPMENT,
  payload: response.body
});

export function autoBookShipment(shipmentId, body) {
  return (dispatch) => {
    return shipment
      .autoBookShipmentPromise(shipmentId, body)
      .then((response) => {
        dispatch(autoBookShipmentSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * "Dispatch" FTL instant rate - in essence, tenders the quote to shipwell at the current price
 */
export function dispatchFTLInstantRate(shipmentId, body) {
  return (dispatch) => {
    return shipment
      .dispatchFTLInstantRatePromise(shipmentId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Award Quote - actions
 */
export const awardQuoteSuccess = (response) => ({
  type: AWARD_QUOTE,
  payload: response.body
});

export function awardQuote(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .awardQuotePromise(shipmentId, body)
      .then((response) => {
        dispatch(awardQuoteSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Shipment Booked - actions
 */
export function sendShipmentBooked(shipmentId) {
  return (dispatch) => {
    return shipment
      .sendShipmentBookedPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Assign Vendor - actions
 */
export function assignVendor(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .assignVendorPromise(shipmentId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

export function editVendorAssignment(shipmentId, vendorAssignmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .editVendorAssignmentPromise(shipmentId, vendorAssignmentId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

export function unassignCarrier(shipmentId) {
  return (dispatch) => {
    return shipment
      .unassignCarrierPromise(shipmentId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Equipment Config - actions
 */
export function assignEquipmentConfig(shipmentId, body) {
  return (dispatch) => {
    return shipment
      .assignEquipmentConfigPromise(shipmentId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Linear Feet - actions
 */
export function getLinearFeetEstimate(lineItems) {
  return (dispatch) => {
    return shipment
      .getLinearFeetEstimatePromise(lineItems)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((error) => {
        dispatch(displayError(error));
        return {status: 400, details: error};
      });
  };
}

/**
 * Accessorial Codes - actions
 */
export const accessorialCodesRequestSuccess = (response) => ({
  type: FETCH_ACCESSORIAL_CODES,
  payload: response.body
});

export function fetchAccessorialCodes() {
  return (dispatch) => {
    return shipment
      .fetchAccessorialCodesPromise()
      .then((response) => {
        dispatch(accessorialCodesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting accessorial codes.'}));
        return error;
      });
  };
}

/**
 * Rep Roles - actions
 */
export const repRolesRequestSuccess = (response) => ({
  type: GET_REP_ROLES,
  payload: response.body
});

export function fetchShipmentRepRoles() {
  return (dispatch) => {
    return shipment
      .fetchShipmentRepRolesPromise()
      .then((response) => {
        dispatch(repRolesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting shipment rep roles.'}));
        return error;
      });
  };
}

/**
 * NMFC Codes - actions
 */
export const nmfcCodesRequestSuccess = (response) => ({
  type: GET_NMFC_CODES,
  payload: response.body
});

export function fetchNMFCCodes() {
  return (dispatch) => {
    return shipment
      .fetchNMFCCodesPromise()
      .then((response) => {
        dispatch(nmfcCodesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting NMFC codes.'}));
        return error;
      });
  };
}

/**
 * Hazmat Codes - actions
 */
export const hazmatCodesRequestSuccess = (response) => ({
  type: GET_HAZMAT_CODES,
  payload: response.body
});

export function fetchHazmatCodes() {
  return (dispatch) => {
    return shipment
      .fetchHazmatCodesPromise()
      .then((response) => {
        dispatch(hazmatCodesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting Hazmat codes.'}));
        return error;
      });
  };
}

/**
 * Package Types - actions
 */
export const packageTypesRequestSuccess = (response) => ({
  type: GET_PACKAGE_TYPES,
  payload: response.body
});

export const pieceTypesRequestSuccess = (response) => ({
  type: GET_PIECE_TYPES,
  payload: response.body
});

export const fedexPackageTypesRequestSuccess = (response) => ({
  type: FETCH_PACKAGE_TYPES_SUCCESS,
  payload: response.body
});

export const upsPackageTypesRequestSuccess = (response) => ({
  type: FETCH_UPS_PACKAGE_TYPES_SUCCESS,
  payload: response.body
});

export const uspsPackageTypesRequestSuccess = (response) => ({
  type: FETCH_USPS_PACKAGE_TYPES_SUCCESS,
  payload: response.body
});

export function fetchPackageTypes() {
  return (dispatch) => {
    return shipment
      .fetchPackageTypesPromise()
      .then((response) => {
        dispatch(packageTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting package types.'}));
        return error;
      });
  };
}

export function fetchPieceTypes() {
  return (dispatch) => {
    return shipment
      .fetchPieceTypesPromise()
      .then((response) => {
        dispatch(pieceTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting piece types.'}));
        return error;
      });
  };
}

export function fetchFedexPackageTypes() {
  return (dispatch) => {
    return quoting
      .getFedExPackageTypes()
      .then((response) => {
        dispatch(fedexPackageTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting package types.'}));
        return error;
      });
  };
}

export function fetchUPSPackageTypes() {
  return (dispatch) => {
    return quoting
      .getUPSPackageTypes()
      .then((response) => {
        dispatch(upsPackageTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting package types.'}));
        return error;
      });
  };
}

export function fetchUSPSPackageTypes() {
  return (dispatch) => {
    return quoting
      .getUSPSPackageTypes()
      .then((response) => {
        dispatch(uspsPackageTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting package types.'}));
        return error;
      });
  };
}

/**
 * Equipment Types - actions
 */
export const equipmentTypesRequestSuccess = (response) => ({
  type: GET_EQUIPMENT_TYPES,
  payload: response.body
});

export function fetchEquipmentTypes() {
  return (dispatch) => {
    return shipment
      .fetchEquipmentTypesPromise()
      .then((response) => {
        dispatch(equipmentTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting equipment types.'}));
        return error;
      });
  };
}

/**
 * Appointment Types - actions
 */
export const appointmentTypesRequestSuccess = (response) => ({
  type: GET_APPOINTMENT_TYPES,
  payload: response.body
});

export function fetchAppointmentTypes() {
  return (dispatch) => {
    return shipment
      .fetchAppointmentTypesPromise()
      .then((response) => {
        dispatch(appointmentTypesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting appointment types.'}));
        return error;
      });
  };
}

/**
 * Service Levels - actions
 */
export const serviceLevelsRequestSuccess = (response) => ({
  type: GET_SERVICE_LEVELS,
  payload: response.body
});

export function fetchServiceLevels() {
  return (dispatch) => {
    return shipment
      .fetchServiceLevelsPromise()
      .then((response) => {
        dispatch(serviceLevelsRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting service levels.'}));
        return error;
      });
  };
}

/**
 * Shipment Modes - actions
 */
export const shipmentModesRequestSuccess = (response) => ({
  type: GET_SHIPMENT_MODES,
  payload: response.body
});

export function fetchShipmentModes() {
  return (dispatch) => {
    return shipment
      .fetchShipmentModesPromise()
      .then((response) => {
        dispatch(shipmentModesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting shipment modes.'}));
        return error;
      });
  };
}

/**
 * Charge Categories - actions
 */
export const chargeCategoriesRequestSuccess = (response) => ({
  type: GET_CHARGE_CATEGORIES,
  payload: response.body
});

export function fetchChargeCategories() {
  return (dispatch) => {
    return shipment
      .fetchChargeCategoriesPromise()
      .then((response) => {
        dispatch(chargeCategoriesRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting charge categories.'}));
        return error;
      });
  };
}

export const fetchShipmentStatusesSuccess = (response) => ({
  type: GET_SHIPMENT_STATUSES,
  payload: response.body
});
export const fetchShipmentStatuses = (opts = {}) => {
  return (dispatch) => {
    return shipment
      .fetchShipmentStatusesPromise(opts)
      .then((res) => {
        dispatch(fetchShipmentStatusesSuccess(res));
        return res;
      })

      .catch((error) => {
        dispatch(displayError({error_description: 'There was an error getting shipment statuses.'}));
        return error;
      });
  };
};

export function populateRecipientEmails(recipients) {
  return (dispatch) => {
    return dispatch({
      type: POPULATE_RECIPIENT_EMAILS,
      payload: {recipient_emails: recipients}
    });
  };
}

// Save a modified shipment object for use in redux forms
export function selectFormShipment(shipment) {
  return (dispatch) => {
    return dispatch({type: SELECT_SHIPMENT_FOR_FORM, payload: shipment});
  };
}

// Save some charge line items to the store for use in the customer half of financials later on
export function saveQuoteCopyForCustomerFinancials(quote) {
  return (dispatch) => {
    return dispatch({
      type: SAVE_QUOTE_FOR_CUSTOMER_FINANCIALS,
      payload: quote
    });
  };
}

// Clear selected shipment
export function clearSelectedShipment() {
  return (dispatch) => {
    return dispatch({type: CLEAR_SELECTED_SHIPMENT});
  };
}

// Shipment error
export function shipmentError(error) {
  return {type: SHIPMENTS_ERROR, payload: error};
}

export function clearAlertError() {
  return (dispatch) => {
    return dispatch({type: ALERT_ERROR, payload: null});
  };
}

/**
 * Shipment Pay - actions
 */
export function payShipment(shipmentId, source) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = `Token ${token}`;

  return (dispatch) => {
    return shipment
      .payShipmentPromise(shipmentId, source, authorization)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return {data: {status: 400}};
      });
  };
}

export function triggerSuccessfulDispatch(bool, isFTL) {
  return (dispatch) => {
    dispatch({
      type: SUCCESSFUL_DISPATCH,
      payload: {trigger: bool, isFTL: isFTL}
    });
  };
}

export function setInitialQuoteLoading(bool) {
  return (dispatch) => {
    dispatch({type: INITIAL_QUOTE_LOADING, payload: bool});
  };
}

export function setInitialQuotingValues(shipment) {
  return (dispatch) => {
    dispatch({type: INITIAL_QUOTE_VALUES, payload: shipment});
  };
}

export function setCloneShipmentTrigger(bool, shipment) {
  return (dispatch) => {
    dispatch({
      type: SET_CLONE_TRIGGER,
      payload: {bool: bool, shipment: shipment}
    });
  };
}

export function setInitialShipmentValues(shipment) {
  return (dispatch) => {
    dispatch({type: INITIAL_SHIPMENT_VALUES, payload: shipment});
  };
}

export function triggerMarketplaceRedirect(bool) {
  return (dispatch) => {
    dispatch({type: TRIGGER_MARKETPLACE_REDIRECT, payload: bool});
  };
}

export function dispatchError(error) {
  return (dispatch) => {
    dispatch({
      type: ALERT_ERROR,
      payload: error
    });
  };
}

/**
 * Shipment Tags - API
 */
export const shipmentTagsRequestSuccess = (response) => ({
  type: FETCH_TAGS,
  payload: response.body
});

export const shipmentTagRequestSuccess = (response) => ({
  type: FETCH_TAG,
  payload: response.body
});

export const shipmentTagCreateSuccess = (response) => ({
  type: FETCH_TAGS,
  payload: response.body
});

export function fetchTags() {
  return (dispatch) => {
    return shipment
      .fetchTagsPromise()
      .then((response) => {
        dispatch(shipmentTagsRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((error) => {
        return error;
      });
  };
}

export function postTag(body) {
  return (dispatch) => {
    return shipment
      .postTagPromise(body)
      .then((response) => {
        dispatch(shipmentTagCreateSuccess(response));
        return {status: 200};
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getTag(shipmentTagId) {
  return (dispatch) => {
    return shipment
      .getTagPromise(shipmentTagId)
      .then((response) => {
        dispatch(shipmentTagRequestSuccess(response));
        return {status: 200};
      })
      .catch((error) => {
        return error;
      });
  };
}

export function editTag(shipmentTagId, body = {}) {
  return (dispatch) => {
    return shipment
      .editTagPromise(shipmentTagId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((error) => {
        return error;
      });
  };
}

export function deleteTag(shipmentTagId) {
  return (dispatch) => {
    return shipment
      .deleteTagPromise(shipmentTagId)
      .then((response) => {
        return {status: 200};
      })
      .catch((error) => {
        return error;
      });
  };
}

export function clearTag() {
  return (dispatch) => {
    dispatch({type: CLEAR_TAG});
  };
}

export function requestAppDownload(shipmentId) {
  return (dispatch) => {
    return shipment
      .requestAppDownloadPromise(shipmentId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function requestApplessPing(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .requestApplessPingPromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function getTruckloadPriceEstimate(body = {}) {
  return (dispatch) => {
    return shipment
      .getTruckloadPriceEstimatePromise(body)
      .then((response) => {
        return {status: 200, details: response.body};
      })
      .catch((response) => {
        dispatch(displayError(response));
      });
  };
}

export function startAppTracking(shipmentId) {
  return (dispatch) => {
    return shipment
      .startAppTrackingPromise(shipmentId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
      });
  };
}
