import {ELDDeviceLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Modal} from '@shipwell/shipwell-ui';
import {ViewShipmentMovementLogTable} from './ViewShipmentMovementLogTable';

export const ViewShipmentMovementLogModal = ({
  onClose,
  show,
  breadcrumbs,
  shipmentCode
}: {
  breadcrumbs: ELDDeviceLocation[];
  show: boolean;
  onClose: () => void;
  shipmentCode: string;
}) => {
  return (
    <Modal
      title={`Movement Details • Shipment ID #: ${shipmentCode}`}
      show={show}
      size={'medium'}
      onClose={onClose}
      bodyClassName={'m-0 p-0'}
      footerComponent={null}
    >
      <ViewShipmentMovementLogTable breadcrumbs={breadcrumbs} />
    </Modal>
  );
};
