import {cleanDeep} from 'App/utils/object/cleanDeep';
import {DashboardBaseSearchParams, DashboardSearchParams} from '../../commons/dashboardSearchParams';
import {DashboardColumnSortDirection, DashboardColumnsSchema} from '../../commons/dashboardColumnSchema';

export interface GetSanitizedObjectsProps<TExtraParams> {
  params: DashboardSearchParams<TExtraParams>;
  baseParamsToUrlParamsFn: (params: DashboardBaseSearchParams) => Record<string, string>;
  extraParamsToUrlParamsFn?: (params: TExtraParams) => Record<string, string>;
}

export function getSanitizedObjectsFromParams<TExtraParams>(props: GetSanitizedObjectsProps<TExtraParams>) {
  const sanitizedParams = cleanDeep(props.params) as DashboardSearchParams<TExtraParams>;

  const baseUrlParams = props.baseParamsToUrlParamsFn(props.params.base);
  const extraUrlParams = props.extraParamsToUrlParamsFn?.(props.params.extra);
  const urlParams: Record<string, string> = {
    ...baseUrlParams,
    ...extraUrlParams
  };

  const sanitizedUrlParams = cleanDeep(urlParams) as Record<string, string>;

  return {
    sanitizedParams,
    sanitizedUrlParams
  };
}

export interface GetSanitizedObjectsFromUrlParamsProps<TExtraParams> {
  columnsSchema: DashboardColumnsSchema;
  baseUrlParamsToParamsFn: () => DashboardBaseSearchParams;
  extraUrlParamsToParamsFn?: () => TExtraParams;
}

export function getSanitizedObjectsFromUrlParams<TExtraParams>(
  props: GetSanitizedObjectsFromUrlParamsProps<TExtraParams>
) {
  const baseParams = props.baseUrlParamsToParamsFn();

  const firstToSort = props.columnsSchema.find(
    (column) => !!column.sort && column.sort.direction !== DashboardColumnSortDirection.NONE
  );

  baseParams.orderBy = baseParams.orderBy || firstToSort?.sort;

  const extraParams = props.extraUrlParamsToParamsFn?.();
  const params: DashboardSearchParams<TExtraParams> = {
    base: baseParams,
    extra: extraParams || ({} as never)
  };

  const sanitizedUrlParams = cleanDeep(params) as DashboardSearchParams<TExtraParams>;

  return sanitizedUrlParams;
}
