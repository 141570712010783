import replace from 'lodash/replace';
import upperCase from 'lodash/upperCase';
import {CanadaPostServiceTypes, CanparServiceTypes, ProviderCode, PurolatorServiceTypes} from '@shipwell/genesis-sdk';
import {enumToOptionConverter} from './enumToOptionConverter';
import {startCaseToLower} from 'App/utils/startCaseToLower';

const serviceCodesByProvider = {
  [ProviderCode.Purolator]: enumToOptionConverter(PurolatorServiceTypes),
  [ProviderCode.Canpar]: enumToOptionConverter(CanparServiceTypes),
  [ProviderCode.Canadapost]: enumToOptionConverter(CanadaPostServiceTypes)
};

export const getProviderServiceCodes = (providerCode?: ProviderCode) => {
  if (!providerCode || !(providerCode in serviceCodesByProvider)) return [];

  const serviceCodes = serviceCodesByProvider[<keyof typeof serviceCodesByProvider>providerCode];
  return serviceCodes?.map((value) => ({
    id: value.value,
    name: replace(startCaseToLower(value.label), /\b(?:Us|Am|Pm)\b/g, (match) => upperCase(match))
  }));
};
