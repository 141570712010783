import {ReactNode} from 'react';

export type DashboardColumnsSchema<TData = never> = Array<DashboardColumnSchema<TData>>;

export interface DashboardColumnSchema<TData = never> {
  header: ReactNode;
  isFixed?: boolean;
  width?: string | number;
  sort?: DashboardColumnSort;
  truncate?: boolean;
  content: (data: TData) => ReactNode;
}

export interface DashboardColumnSort {
  key: string;
  direction: DashboardColumnSortDirection;
}

export enum DashboardColumnSortDirection {
  ASC = 'ascending',
  DESC = 'descending',
  NONE = 'none'
}
