import _ from 'lodash';
import {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';

import Scroll from 'react-scroll';
var Element = Scroll.Element;

@connect((state) => ({}), {})
export default class VendorsFMCSA extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderReadOnly() {
    const {fmcsaVendor} = this.props;

    return (
      <div className="vendors__shipwell-vendor gray">
        <Grid container spacing={8} className="vendors__info" justify="flex-end">
          <Grid item md={4} sm={12}>
            <p>
              <b>Company Name</b>
            </p>
            <p className="read-only">
              {fmcsaVendor
                ? fmcsaVendor.dba_name
                  ? fmcsaVendor.dba_name + ' (' + fmcsaVendor.name + ')'
                  : fmcsaVendor.name
                : '-'}
            </p>
          </Grid>
          <Grid item md={4} sm={12}>
            <Grid container direction="column">
              <Grid item md={12}>
                <p>
                  <b>Mailing Address</b>
                </p>
                <p className="read-only">
                  {fmcsaVendor && fmcsaVendor.mailing_address && fmcsaVendor.mailing_address.formatted_address
                    ? fmcsaVendor.mailing_address.formatted_address
                    : '-'}
                </p>
              </Grid>
              <Grid item md={12}>
                <p>
                  <b>Billing Address</b>
                </p>
                <p className="read-only">
                  {fmcsaVendor && fmcsaVendor.billing_address && fmcsaVendor.billing_address.formatted_address
                    ? fmcsaVendor.billing_address.formatted_address
                    : '-'}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} sm={12}>
            <Grid container direction="column">
              <Grid item md={12}>
                <p>
                  <b>E-mail</b>
                </p>
                <p className="email read-only">
                  {fmcsaVendor && fmcsaVendor.primary_email ? fmcsaVendor.primary_email : '-'}
                </p>
              </Grid>
              <Grid item md={12}>
                <p>
                  <b>Phone</b>
                </p>
                <p className="read-only">
                  {fmcsaVendor && fmcsaVendor.primary_phone_number
                    ? getPhoneHyperlink(fmcsaVendor.primary_phone_number)
                    : '-'}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="vendors__identifyingCodes">
          <Grid container spacing={8}>
            {fmcsaVendor &&
              fmcsaVendor.identifying_codes &&
              fmcsaVendor.identifying_codes.length > 0 &&
              fmcsaVendor.identifying_codes.map((el, idx) => {
                return (
                  <Grid item key={idx} md={4} sm={12}>
                    <div>
                      <b>{el.type.replace(/_/g, ' ')}</b>
                    </div>{' '}
                    <div className="read-only">{el.value ? el.value : '-'}</div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Paper className="gray">
          <div className="vendors__header">
            <h3>
              <b>FMCSA Data</b>
            </h3>
          </div>
          <Element name={`position-vendor.shipwell_vendor.name`} />
          {this.renderReadOnly()}
        </Paper>
      </div>
    );
  }
}
