import {ShipmentMode} from '@shipwell/genesis-sdk';
import {useQuery} from '@tanstack/react-query';
import {EnhancedCapacityProviderSchema, getCapacityProviders} from 'App/api/integrations/typed';
import {CAPACITY_PROVIDERS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {ComponentType} from 'react';
import {wrapDisplayName} from 'recompose';

export const useCapacityProviders = ({
  activeOnly = true,
  modes = []
}: {
  activeOnly?: boolean;
  modes?: ShipmentMode[];
} = {}) => {
  const {data, ...capacityProvidersQuery} = useQuery([CAPACITY_PROVIDERS_QUERY_KEY, {activeOnly, modes}], async () => {
    const response = await getCapacityProviders();
    return response;
  });

  // the modes at the root of the capacity provider lists the abilities of the provider
  // activeOnly returns only those providers who actually have accounts that match the modes we need
  const filteredProvidersByMode = data
    ?.filter((currentCarrier) => currentCarrier.modes?.every((mode) => modes.includes(mode)))
    .map((currentCarrier) => ({
      ...currentCarrier,
      ...(activeOnly
        ? {
            accounts: currentCarrier.accounts?.filter((account) =>
              modes.every((mode) => account?.modes?.includes(mode))
            )
          }
        : {})
    }));

  const capacityProvidersByMode = Object.values(ShipmentMode).reduce(
    (acc, mode) => ({
      ...acc,
      [mode]: data
        ?.filter((currentCarrier) => currentCarrier?.modes?.some((carrierMode) => carrierMode === mode))
        .map((currentCarrier) => ({
          ...currentCarrier,
          ...(activeOnly
            ? {
                accounts: currentCarrier.accounts?.filter((account) => account.modes.includes(mode))
              }
            : {})
        }))
    }),
    {} as Record<ShipmentMode, EnhancedCapacityProviderSchema>
  );

  return {
    capacityProvidersQuery,
    capacityProviders: filteredProvidersByMode,
    capacityProvidersByMode
  };
};

export default useCapacityProviders;

// HOC for use with class components
export const withCapcityProviders = <PropsT,>(Component: ComponentType<PropsT>) => {
  const ComponentWithCapacityProvidersQuery = (props: PropsT) => {
    const {capacityProvidersByMode} = useCapacityProviders({activeOnly: true, modes: []});

    return (
      <Component
        {...props}
        capacityProvidersByMode={capacityProvidersByMode}
        // dupe but parcel is such a special boi
        parcelCapacityProviders={capacityProvidersByMode[ShipmentMode.Parcel]}
      />
    );
  };

  ComponentWithCapacityProvidersQuery.displayName = wrapDisplayName(Component, 'withCapacityProviders');
  return ComponentWithCapacityProvidersQuery;
};
