import {Token} from 'react-bootstrap-typeahead';

export const TokenDisplay = ({
  option,
  onRemove,
  simpleValue
}: {
  key: number;
  option: {label: string} | string;
  onRemove?: (() => void) | undefined;
  simpleValue?: boolean;
}) => {
  return (
    <Token className="bg-sw-primary text-sw-text-reverse" option={option} onRemove={onRemove}>
      {simpleValue && typeof option === 'string' ? option : typeof option !== 'string' ? option.label : ''}
    </Token>
  );
};
