import {getShipwellApi} from 'App/api/config';
import {apiCallback} from 'App/api/utils';
import {validateLocation} from 'App/api/locations/typed';

/**
 * Address Validate - API
 */
export async function validateAddressPromise(address) {
  try {
    const axiosResponse = await validateLocation(address);
    return new Promise((resolve, reject) => {
      if (axiosResponse.response) {
        reject(axiosResponse.response.data?.error || 'Address not found.');
      } else {
        resolve(axiosResponse.data);
      }
    });
  } catch (axiosError) {
    return new Promise((resolve, reject) => {
      const {non_field_errors: nonFieldErrors, error} = axiosError.response?.data || {};
      reject(nonFieldErrors || error || 'Address not found.');
    });
  }
}

/**
 * Location Types
 * @deprecated use `app/api/locations/typed/getLocationTypes`
 */
export async function getLocationTypesPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise((resolve, reject) => {
    shipwellApi.locationTypesGet(apiCallback(resolve, reject));
  });
}
