import {useMutation} from '@tanstack/react-query';
import {
  updateOceanTrackingService,
  updateRailTrackingService,
  updateOceanTrackingServiceContact,
  updateRailTrackingServiceContact
} from 'App/api/corrogo';
import {SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';

export default function useUpdateServices(shipmentId) {
  const createOptimisticUpdateHandlers = useOptimisticUpdate();

  const createCustomOptimisticUpdateHandlers = ({errorHandler, mergeData}) =>
    createOptimisticUpdateHandlers([SHIPMENTS_QUERY_KEY, shipmentId], {
      errorHandler,
      mergeData
    });

  const contactOptimisticUpdateHandlers = createCustomOptimisticUpdateHandlers({
    errorHandler: (error, {contactId}) => {
      console.error(`Error updating contact ${contactId}`, error.message);
    },
    mergeData: (shipment, {contactId, data}) => ({
      ...shipment,
      stages: shipment.stages.map((stage) =>
        stage.stage_type === 'SERVICE'
          ? {
              ...stage,
              service: {
                ...stage.service,
                contacts: stage.service.contacts.map((contact) =>
                  contact.id === contactId ? {...data, id: contactId} : contact
                )
              }
            }
          : stage
      )
    })
  });

  const serviceOptimisticUpdateHandlers = createCustomOptimisticUpdateHandlers({
    errorHandler: (error) => {
      console.error('Error updating service', error.message);
    },
    mergeData: (shipment, {data}) => ({
      ...shipment,
      stages: shipment.stages.map((stage) =>
        stage.stage_type === 'SERVICE'
          ? {
              ...stage,
              service: {
                ...stage.service,
                ...data
              }
            }
          : stage
      )
    })
  });

  const updateOceanTrackingServiceMutation = useMutation(
    ({serviceId, data}) => updateOceanTrackingService(serviceId, data),
    serviceOptimisticUpdateHandlers
  );

  const updateRailTrackingServiceMutation = useMutation(
    ({serviceId, data}) => updateRailTrackingService(serviceId, data),
    serviceOptimisticUpdateHandlers
  );

  const updateRailTrackingServiceContactMutation = useMutation(
    ({serviceId, contactId, data}) => updateRailTrackingServiceContact(serviceId, contactId, data),
    contactOptimisticUpdateHandlers
  );

  const updateOceanTrackingServiceContactMutation = useMutation(
    ({serviceId, contactId, data}) => updateOceanTrackingServiceContact(serviceId, contactId, data),
    contactOptimisticUpdateHandlers
  );

  return {
    updateOceanTrackingServiceMutation,
    updateRailTrackingServiceMutation,
    updateRailTrackingServiceContactMutation,
    updateOceanTrackingServiceContactMutation,
    isLoading:
      updateOceanTrackingServiceMutation.isLoading ||
      updateRailTrackingServiceMutation.isLoading ||
      updateRailTrackingServiceContactMutation.isLoading ||
      updateOceanTrackingServiceContactMutation.isLoading
  };
}
