import {ShipwellError, TendersApiTendersPostRequest} from '@shipwell/backend-core-singlerequestparam-sdk';
import {UseMutationOptions, useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {createTender} from 'App/api/tenders/typed';
import {TENDER_TO_CARRIER_KEY} from 'App/data-hooks/queryKeys';

export const useCreateTender = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof createTender>>,
    AxiosError<ShipwellError, unknown>,
    TendersApiTendersPostRequest
  >
) => {
  const createTenderMutation = useMutation(
    [TENDER_TO_CARRIER_KEY],
    (payload: TendersApiTendersPostRequest) => createTender(payload),
    {
      ...options
    }
  );
  return {
    createTender: createTenderMutation.mutate,
    createTenderAsync: createTenderMutation.mutateAsync
  };
};
