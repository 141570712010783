import {Component, ErrorInfo, ReactNode} from 'react';
import {ErrorBoundaryFallback} from './ErrorBoundaryFallback';

interface ErrorBoundaryProps {
  fallback?: (error: Error, errorInfo: ErrorInfo) => ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error: Error) {
    return {error, errorInfo: null};
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({error, errorInfo: info});
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      if (this.props.fallback) {
        return this.props.fallback(this.state.error, this.state.errorInfo);
      }
      return <ErrorBoundaryFallback error={this.state.error} errorMessage={this.state.error.message} />;
    }

    return this.props.children;
  }
}
