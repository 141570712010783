import {
  PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdInvoicesGetRequest,
  PaymentsApi,
  PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdPutRequest,
  PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdSetDefaultPostRequest,
  PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesPostRequest,
  PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdDeleteRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {configuration} from './configuration';

const paymentsApi = new PaymentsApi(configuration);

export async function getTriumphPayVendorInvoices(
  // I'm refactoring this to use the single request param version of the SDK and the old version defaulted the required
  // `triumphId` param to an empty string. This is almost certainly a bad idea, but it's not in scope for my current work
  // to fix this and hunt down what might break.
  options: PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdInvoicesGetRequest = {triumphId: ''}
) {
  return paymentsApi.paymentsIntegrationsTriumphPayVendorsTriumphIdInvoicesGet(options);
}

export function paymentsIntegrationsTriumphPayVendorsTriumphIdGet(triumphId: string) {
  const result = paymentsApi.paymentsIntegrationsTriumphPayVendorsTriumphIdGet({triumphId});
  return result;
}

export function paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdPut(
  params: PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdPutRequest
) {
  return paymentsApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdPut(params);
}

export function paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdSetDefaultPost(
  requestParameters: PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdSetDefaultPostRequest
) {
  return paymentsApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdSetDefaultPost(
    requestParameters
  );
}

export function paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesPost(
  params: PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesPostRequest
) {
  return paymentsApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesPost(params);
}

export function paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdDelete(
  params: PaymentsApiPaymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdDeleteRequest
) {
  return paymentsApi.paymentsIntegrationsTriumphPayVendorsTriumphIdPaymentTypesTriumphPaymentTypeIdDelete(params);
}
