import {useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {ProviderCode, UpdateRateTable, PurolatorAccountCredentialsSchema} from '@shipwell/genesis-sdk';
import {deleteRateTable, disconnectSmc3, getRateTables, getSmc3Account, updateRateTable} from 'App/api/genesis/typed';
import {COMPANY_INTEGRATION_SMC3, RATE_TABLE_QUERY, SERVICE_LEVELS_KEY} from 'App/data-hooks/queryKeys';
import {getServiceLevels} from 'App/api/referenceData/typed';

type Smc3AccountType = {
  account: PurolatorAccountCredentialsSchema;
  id: string;
  provider_code: ProviderCode;
};

export const useGetSmc3Query = (
  options?: UseQueryOptions<Promise<Smc3AccountType | undefined>, AxiosError, Smc3AccountType, string[]>
) =>
  useQuery(
    [COMPANY_INTEGRATION_SMC3],
    async () => {
      const {data} = await getSmc3Account();
      return data?.accounts && (data.accounts[0] as Smc3AccountType);
    },
    options
  );

export const useDisconnectSmc3Mutation = (options: UseMutationOptions<unknown, AxiosError, void, unknown>) =>
  useMutation([COMPANY_INTEGRATION_SMC3], () => disconnectSmc3(), options);

export const useGetRateTables = (scacValue?: string) => {
  const getRateTablesQuery = useQuery(
    [RATE_TABLE_QUERY, scacValue],
    async () => {
      const response = await getRateTables({
        carrierReferenceQualifier: 'SCAC',
        carrierReferenceValue: scacValue,
        limit: 200 //default to max to get all matching records
      });
      return response.data;
    },
    {enabled: !!scacValue}
  );
  return {
    rateTables: getRateTablesQuery?.data?.data
  };
};

export const useGetServiceLevels = () => {
  const getServiceLevelsQuery = useQuery([SERVICE_LEVELS_KEY], async () => {
    const response = await getServiceLevels();
    return response.data;
  });
  return {
    serviceLevels: getServiceLevelsQuery?.data,
    getServiceLevelsQuery
  };
};

export const useUpdateRateTable = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRateTable>>,
    AxiosError,
    {rateTableId: string; updatedRateTable: UpdateRateTable}
  >
) => {
  const queryClient = useQueryClient();
  const updateRateTableMutation = useMutation(
    [RATE_TABLE_QUERY],
    ({rateTableId, updatedRateTable}: {rateTableId: string; updatedRateTable: UpdateRateTable}) =>
      updateRateTable(rateTableId, updatedRateTable),
    {
      onSettled: () => void queryClient.invalidateQueries([RATE_TABLE_QUERY]),
      ...options
    }
  );
  return {
    updateRateTable: updateRateTableMutation.mutate
  };
};

export const useDeleteRateTable = (
  options?: UseMutationOptions<Awaited<ReturnType<typeof deleteRateTable>>, AxiosError, string, unknown>
) => {
  const deleteRateTableMutation = useMutation(
    [RATE_TABLE_QUERY],
    (rateTableId) => deleteRateTable(rateTableId),
    options
  );
  return {
    deleteRateTable: deleteRateTableMutation.mutate,
    isDeleteRateTableLoading: deleteRateTableMutation.isLoading
  };
};
