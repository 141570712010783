import {SvgIcon} from '@shipwell/shipwell-ui';
import {DEFAULT_COLUMN_WIDTH} from 'App/components/dashboards/commons/constants';
import {
  DashboardColumnSchema,
  DashboardColumnSortDirection
} from 'App/components/dashboards/commons/dashboardColumnSchema';
import classNames from 'classnames';

export interface HeaderRowProps {
  fullWidth?: boolean;
  column: DashboardColumnSchema;
  sortDirection?: DashboardColumnSortDirection;
  onSortDirectionChange: (sort: DashboardColumnSortDirection) => void;
}

export function HeaderRowCell(props: HeaderRowProps) {
  function handleNextSortState(current?: DashboardColumnSortDirection) {
    switch (current) {
      case DashboardColumnSortDirection.ASC:
        props.onSortDirectionChange(DashboardColumnSortDirection.DESC);
        break;

      case DashboardColumnSortDirection.DESC:
        props.onSortDirectionChange(DashboardColumnSortDirection.NONE);
        break;

      default:
        props.onSortDirectionChange(DashboardColumnSortDirection.ASC);
        break;
    }
  }

  let colWidth: string | number | undefined = undefined;

  if (props.fullWidth) {
    if (props.column.width) {
      colWidth = props.column.width;
    }
  } else {
    colWidth = props.column.width || DEFAULT_COLUMN_WIDTH;
  }

  return (
    <th
      aria-sort={props.sortDirection || 'none'}
      style={{width: colWidth}}
      className={classNames(
        'sticky top-0',
        'border-b border-sw-border last:border-r-0',
        'whitespace-nowrap font-normal text-sw-text bg-sw-background-component',
        {
          'left-0 z-20 border-r-2': props.column.isFixed,
          'border-r-1 z-10': !props.column.isFixed
        }
      )}
    >
      <div
        className="flex gap-1 p-2 items-center w-full"
        role={props.column.sort ? 'button' : undefined}
        onClick={() => {
          if (props.column.sort) handleNextSortState(props.sortDirection);
        }}
      >
        {props.column.sort && (
          <div className={`rounded-full ${props.sortDirection ? 'bg-sw-active-light text-sw-active' : ''}`}>
            <SvgIcon
              aria-label={props.sortDirection || DashboardColumnSortDirection.NONE}
              name={props.sortDirection === DashboardColumnSortDirection.ASC ? 'CarrotUp' : 'CarrotDown'}
            />
          </div>
        )}
        <div className="truncate">{props.column.header}</div>
      </div>

      {/* {onResize ? <TableDragHandle resizeHandler={onResize} /> : null} */}
    </th>
  );
}
