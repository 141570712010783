import moment from 'moment';

export const SECONDS_PER_DAY = 86400;

/**
 * Takes in a date and returns a new date with the configured datetime options.
 */
export type ConfigureDateOptions = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};
/**
 * Takes in the target date and returns a new date with the configured datetime options.
 * @param {date} date starting date to configure and change options
 * @returns {Date}
 */
export const configureDate = (date: Date, options: ConfigureDateOptions): Date => {
  const newDate = new Date(date);
  if (options.hours) {
    newDate.setHours(options.hours);
  }
  if (options.minutes) {
    newDate.setMinutes(options.minutes);
  }
  if (options.seconds) {
    newDate.setSeconds(options.seconds);
  }
  if (options.milliseconds) {
    newDate.setMilliseconds(options.milliseconds);
  }
  if (options.years) {
    newDate.setFullYear(options.years);
  }
  if (options.months) {
    newDate.setMonth(options.months);
  }
  if (options.days) {
    newDate.setDate(options.days);
  }

  return newDate;
};

export const formatDateTimeTimestamp = (dateTime: Date | string | undefined, timezone = moment.tz.guess()) => {
  return dateTime && moment(dateTime).isValid() ? moment.tz(dateTime, timezone).format('MMM D YYYY HH:mm z') : null;
};

/**
 * Format a date to the ddd, MMM D YYYY HH:mm (day, month date year) format
 * based on the pattern described here - https://github.com/shipwell/frontend-web/blob/dev/dev/style-guides/date-time-formatting.md
 */
export const formatDateTime = (date: string | Date, timezone = moment.tz.guess()) => {
  const mDate = moment.tz(date, timezone);
  return mDate.isValid() ? mDate.format('ddd, MMM D YYYY HH:mm z') : null;
};

export const formatDate = (date: string | Date, timezone = moment.tz.guess()) => {
  const mDate = moment.tz(date, timezone);
  return mDate.isValid() ? mDate.format('ddd, MMM D, YYYY') : null;
};

export const formatDateMonthDayYear = (date: string, timezone = moment.tz.guess()) => {
  const mDate = moment.tz(date, timezone);
  return mDate.isValid() ? mDate.format('MM/DD/YY') : null;
};

export function formatWeekdayDateTimeTz(date: string | Date, timezone = moment.tz.guess()): string {
  const m = moment.tz(date, timezone);
  if (!m.isValid()) {
    return '';
  }
  return m.format('ddd, MMM DD, YYYY H:mm zz');
}

export const timeRemainingFromToday = (date: string) => {
  const momentDate = moment(date);
  const today = moment();

  const days = momentDate.diff(today, 'days');
  const totalHours = momentDate.diff(today, 'hours');

  const daysInHours = days * 24;
  const hours = totalHours - daysInHours;

  return {days, hours};
};

export const isPastDate = (date: string) => {
  const today = moment();
  return today.isAfter(moment(date));
};
/**
 * Adds any number of days to the passed date.
 * @param {Date} date Date to add or subtract days to.
 * @param {number} daysToAdd postive or negative days to move the date by.
 * @returns {Date} new Date object created from the first.
 */
export const addDays = (date: Date | string | number, daysToAdd: number): Date => {
  const dt = new Date(date.valueOf());
  dt.setDate(dt.getDate() + daysToAdd);
  return dt;
};
/**
 * Adds any number of minutes to the passed date.
 * @param {Date} date Date to add or subtract minutes to.
 * @param {number} minutesToAdd positive or negative minutes to move the date by.
 * @returns {Date} new Date object created from the first.
 */
export const addMinutes = (date: Date | string, minutesToAdd: number, timezone?: string): Date => {
  if (typeof date === 'string') {
    date = timeZoneAwareParse(date, timezone);
  }
  const dt = new Date(date.valueOf());
  dt.setMinutes(date.getMinutes() + minutesToAdd);
  return dt;
};

export const addDaysAndFormatDate = (daysToAdd: number, format: string) =>
  moment().add(daysToAdd, 'days').format(format);

export const addUtcOffsetToDate = (date: string) => {
  const dateMomentObject = moment(date);
  dateMomentObject.add(dateMomentObject.utcOffset(), 'minutes');
  return dateMomentObject.toDate();
};

export const hourOptions = Array.from({length: 24}, (_, i) => {
  const label = `${i < 10 ? '0' : ''}${i}:00`;
  const value = `${i < 10 ? '0' : ''}${i}:00:00`;
  return {label, value};
});

export const quarterHourOptions = Array.from({length: 24 * 4}, (_, i) => {
  const hours = Math.floor(i / 4);
  const minutes = (i % 4) * 15;
  const hourLabel = `${hours < 10 ? '0' : ''}${hours}`;
  const minuteLabel = `${minutes === 0 ? '00' : minutes}`;
  const label = `${hourLabel}:${minuteLabel}`;
  const value = `${hourLabel}:${minuteLabel}:00`;
  return {label, value};
});

// create hour options in half hour increments
export const halfHourOptions = Array.from({length: 24}, (_, i) => {
  // const hour = i + 1;
  const labelHalf = `${i < 10 ? '0' : ''}${i}:30`;
  const valueHalf = `${i < 10 ? '0' : ''}${i}:30:00`;
  const label = `${i < 10 ? '0' : ''}${i}:00`;
  const value = `${i < 10 ? '0' : ''}${i}:00:00`;
  return [
    {label, value},
    {label: labelHalf, value: valueHalf}
  ];
}).flat();

//See https://stackoverflow.com/questions/4310953/invalid-date-in-safari
export const dateConstructorSafariPolyfill = (date: string) => new Date(date.replace(/-/g, '/'));

type ISO8601ConverterType = {hrs: number; mins: number};
/**
 * converts a time duration into an ISO-8601 string
 * example: "4 hours 15 minutes" becomes P0DT4H15M0.000000S
 */
export const convertTimeToISO8601 = ({hrs, mins}: ISO8601ConverterType) => {
  let timeString = `P0DT${hrs}H${mins}M0`;
  timeString += '.000000S';
  return timeString;
};

export const convertISO8601ToMinutes = (duration: string): number => {
  const timeStringregex = /P0DT(?:(\d+)H)?(?:(\d+)M)?/;
  const matches = duration.match(timeStringregex);

  if (!matches) {
    return 60;
  }
  const hours = matches[1] ? parseInt(matches[1]) : 0;
  const minutes = matches[2] ? parseInt(matches[2]) : 0;

  return hours * 60 + minutes;
};

export const convertDuration = (
  iso8601duration: string,
  to: 'minutes' | 'hours' | 'seconds' | 'milliseconds' = 'minutes'
) => {
  const d = moment.duration(iso8601duration);
  switch (to) {
    case 'hours':
      return d.asHours();
    case 'minutes':
      return d.asMinutes();
    case 'seconds':
      return d.asSeconds();
    case 'milliseconds':
      return d.asMilliseconds();
  }
};

export const getThanksgivingDate = (year: number): string => {
  // iterate from end of november and find the last Thursday
  let date = moment([year, 10]).endOf('month');
  while (date.day() !== 4) {
    date = date.subtract(1, 'day');
  }
  return date.format('YYYY-MM-DD');
};

/** Normalize a duration such that it can be an exact string match for a <select /> option. */
export function normalizeISO8601Duration(dur: string): string {
  return moment.duration(dur).toISOString();
}

export function withTimeOfDay(date: Date | string, timeOfDay = '00:00', timezone?: string): Date {
  const m = moment.tz(date, timezone ?? moment.tz.guess());
  const [hoursStr, minutesStr] = timeOfDay.split(':');
  m.set('hour', +hoursStr);
  m.set('minute', +minutesStr);
  m.set('second', 0);
  m.set('millisecond', 0);
  return m.toDate();
}

/**
 * Creates a new date object at midnight today.
 */
export function today(timezone?: string): Date {
  return withTimeOfDay(new Date(), '00:00', timezone);
}

export function formatISODate(date: Date, timezone?: string): string {
  return moment.tz(date, timezone ?? moment.tz.guess()).format('YYYY-MM-DD');
}

export function monthAndYear(date: Date): string {
  return moment(date).format('MMMM YYYY');
}

export function monthDateYear(date: Date): string {
  return [date.getMonth() + 1, date.getDate(), date.getFullYear()].map((n) => n.toString().padStart(2, '0')).join('/');
}
/**
 * Returns true if the date passed has the same numerical values represented by `getFullYear`, `getMonth` and `getDay`
 * as the `new Date()` javascript object.
 */
export function isToday(date: Date): boolean {
  if (Number.isNaN(date.valueOf())) {
    return false;
  }
  const todayDate = new Date();
  return (
    date.getFullYear() === todayDate.getFullYear() &&
    date.getMonth() === todayDate.getMonth() &&
    date.getDate() === todayDate.getDate()
  );
}
/**
 * Returns true if the date passed has the same year and month as "today"
 * but differ by 1 day.
 */
export function isTomorrow(date: Date): boolean {
  if (Number.isNaN(date.valueOf())) {
    return false;
  }

  // get the current date
  const todayDate = new Date();

  // Set the time to the start of the day to ensure we're only working with the date part
  todayDate.setHours(0, 0, 0, 0);

  // Create a new Date object for tomorrow
  const tomorrow = new Date(todayDate);
  /**
   * The Date object will handle end of month/year transitions
   * this will cover use-cases where the date goes over to the next month or next year
   */
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    date.getFullYear() === tomorrow.getFullYear() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getDate() === tomorrow.getDate()
  );
}
/**
 * Returns True if the date passed has the same numerical values as `getFullYear` and `getMonth` but `getDay`
 * differ by 1 with the passed `date` having the lesser value.
 */
export function isYesterday(date: Date): boolean {
  if (Number.isNaN(date.valueOf())) {
    return false;
  }
  const todayDate = new Date();
  return (
    date.getFullYear() === todayDate.getFullYear() &&
    date.getMonth() === todayDate.getMonth() &&
    date.getDate() === todayDate.getDate() - 1
  );
}

export function colloquialDate(date: Date): string {
  const mDate = moment(date);
  const mToday = moment(today());
  const days = mDate.diff(mToday, 'days');
  switch (days) {
    case -2:
    case 2:
      return mDate.format('dddd');
    case -1:
      return 'yesterday';
    case 0:
      return 'today';
    case 1:
      return 'tomorrow';
    default:
      return mDate.format('MM/DD/YY');
  }
}

export function yearDashMonthDashDate(date: Date, timezone?: string): string {
  let m = moment(date);
  if (timezone) {
    m = m.tz(timezone);
  }
  return m.format('YYYY-MM-DD');
}

/**
 * Converts part of date to human friendly text. The text returned appends additional
 * text such as st, nd, rd, th to the date portion.
 * @param {string|numberDate} date this should be a string with the minimum date information, a number in milliseconds, or an actual Date object
 * @returns {string|undefined} the day in a human friendly format for example 28 will return "28th"
 * @example humanizeDate("2022-02-28T08:32:33.000000-06:00");
 * @example humanizeDate(new Date());
 * @example humanizeDate(1678989152731);
 * @throws {Error} if date parameter passed cannot be parsed as a valid date
 */
export function humanizeDay(date: string | number | Date): string | undefined {
  const dt = new Date(date);
  if (Number.isNaN(dt.valueOf())) {
    throw new Error(`${date.toString()} is not a valid date`);
  }

  const formatter = Intl.DateTimeFormat(undefined, {
    timeStyle: 'full',
    dateStyle: 'full'
  });

  const formatedParts = formatter.formatToParts(dt);
  const dayPart = formatedParts.find((p) => p.type === 'day');
  if (!dayPart?.value) {
    return;
  }
  const dayValue = Number(dayPart.value);
  if (dayValue < 11 || dayValue > 19) {
    if (dayPart.value.endsWith('1')) {
      return `${dayPart.value}st`;
    }
    if (dayPart.value.endsWith('2')) {
      return `${dayPart.value}nd`;
    }
    if (dayPart.value.endsWith('3')) {
      return `${dayPart.value}rd`;
    }
  }

  return `${dayPart.value}th`;
}
/**
 * Humanizes the weekday as a long version of the weekday rather than abbreviated.
 * @param {string|numberDate} date this should be a string with the minimum date information, a number in milliseconds, or an actual Date object
 * @returns {string|undefined} the day of the week in a human friendly format for example Thur will return "Thursday"
 * @example humanizeDayOfTheWeek("2022-02-28T08:32:33.000000-06:00");
 * @example humanizeDayOfTheWeek(new Date());
 * @example humanizeDayOfTheWeek(1678989152731);
 * @throws {Error} if date parameter passed cannot be parsed as a valid date
 */
export function getLongDayOfTheWeek(date: string | number | Date): string | undefined {
  const formatter = Intl.DateTimeFormat(undefined, {
    dateStyle: 'full',
    timeStyle: 'full'
  });

  const formatParts = formatter.formatToParts(new Date(date));
  const weekDayPart = formatParts.find((p) => p.type === 'weekday');

  return weekDayPart?.value;
}
/**
 * Humanizes the weekday as a long version of the weekday rather than abbreviated.
 * @param date
 * @returns {string|undefined} the month in a human friendly format for example Jul will return "July"
 * @example getLongMonth("2022-02-28T08:32:33.000000-06:00");
 * @example getLongMonth(new Date());
 * @example getLongMonth(1678989152731);
 * @throws {Error} if date parameter passed cannot be parsed as a valid date
 */
export function getLongMonth(date: string | number | Date): string | undefined {
  const formatter = Intl.DateTimeFormat(undefined, {
    dateStyle: 'long',
    timeStyle: 'long'
  });

  const formatParts = formatter.formatToParts(new Date(date));
  const weekDayPart = formatParts.find((p) => p.type === 'month');

  return weekDayPart?.value;
}
/**
 * Creates a humanized date which can be displayed to a user.
 * @param {string|numberDate} date this should be a string with the minimum date information, a number in milliseconds, or an actual Date object
 * @param {', '|' '} seperator how to seperate that data with just spaces or commas,
 * @returns a formatted day with Day of the Week, Month Day, year. i.e. `'Thursday, July 1st, 2023'`
 * @example humanDayOfTheWeek("2022-02-28T08:32:33.000000-06:00");
 * @example humanDayOfTheWeek(new Date());
 * @example humanDayOfTheWeek(1678989152731);
 * @throws {Error} if date parameter passed cannot be parsed as a valid date
 */
export function humanizeDate(date: string | number | Date, seperator: ', ' | ' ' = ', ') {
  // this eventually could be extended to do diffferent formats i.e. 'full', 'long', 'short'
  const month = getLongMonth(date) ?? '';
  const dayOfWeek = getLongDayOfTheWeek(date) ?? '';
  const day = humanizeDay(date) ?? '';
  const year = new Date(date).getFullYear();
  return `${dayOfWeek}${seperator}${month} ${day}${seperator}${year}`;
}

export function ensureDateString(datum: string | Date): string {
  if (datum instanceof Date) {
    datum = datum.toISOString();
  }
  return datum;
}

export function weekdayMonthDate(dateIn: string | number | Date): string {
  const formatter = Intl.DateTimeFormat(undefined, {
    dateStyle: 'full',
    timeStyle: 'full'
  });
  const date = new Date(dateIn);
  const formatParts = formatter.formatToParts(new Date(date));
  const weekDayPart = formatParts.find((p) => p.type === 'weekday')?.value ?? '';
  return `${weekDayPart} ${date.getMonth() + 1}/${date.getDate()}`;
}

/**
 * Formats a duration as `<hours> hr(s) <minutes> min`, omitting parts when
 * obvious or something else obvious for very short durations, as implied by
 * the appointment lists in the Dock Scheduling mocks.
 * - Joe 2023-05-18
 */
export function formatHrsMins(milliseconds: number): string {
  milliseconds = Math.round(milliseconds);
  if (Number.isNaN(milliseconds)) {
    throw new Error('Expected a duration in milliseconds.');
  }
  if (milliseconds < 0) {
    throw new Error('Durations are never negative.');
  }
  if (milliseconds < 1) {
    return '0 s';
  }
  if (milliseconds === 1) {
    return `1 ms`;
  }
  if (milliseconds <= 500) {
    return `${milliseconds} ms`;
  }
  const seconds = Math.round(milliseconds / 1000);
  if (seconds === 60) {
    return '1 min';
  }
  if (seconds < 100) {
    return `${seconds} s`;
  }
  let minutes = Math.round(seconds / 60);
  if (minutes === 60) {
    return '1 hr';
  }
  if (minutes < 100) {
    return `${minutes} mins`;
  }
  const hrs = Math.floor(minutes / 60);
  minutes -= 60 * hrs;
  if (hrs === 1) {
    if (minutes === 0) {
      return `${hrs} hrs`;
    }
    if (minutes === 1) {
      return `1 hr 1 min`;
    }
    return `1 hr ${minutes} mins`;
  }
  if (minutes === 0) {
    return `${hrs} hrs`;
  }
  if (minutes === 1) {
    return `${hrs} hrs 1 min`;
  }
  return `${hrs} hrs ${minutes} mins`;
}

export function formatTimeOfDay(date: Date, timezone = moment.tz.guess()): string {
  return moment(date).tz(timezone).format('H:mm zz');
}

export function formatSelectableDateTime(date: Date, timezone = moment.tz.guess()): string {
  return moment(date).tz(timezone).format('MM/DD/yyyy HH:mm zz');
}

export function parseYearMonthDate(dateString: string, timezone?: string): Date {
  const momentDateObject = moment(dateString, 'YYYY-MM-DD').tz(timezone ?? moment.tz.guess());
  return momentDateObject.toDate();
}

export function timeZoneAwareParse(date: string | Date, timezone = moment.tz.guess()): Date {
  if (date instanceof Date) {
    return date;
  }
  return moment.tz(date, timezone).toDate();
}

export function formatTimestamp(date: string) {
  return moment(date).format('MM/DD/YYYY h:mm a');
}
