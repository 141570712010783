import {DeprecatedButton, SvgIcon, SvgIconName} from '@shipwell/shipwell-ui';
import classNames from 'classnames';

export interface PaginationButtonProps {
  ariaLabel?: string;
  isDisabled?: boolean;
  iconName: SvgIconName;
  onClick: () => void;
}

export function PaginationButton(props: PaginationButtonProps) {
  return (
    <DeprecatedButton aria-label={props.ariaLabel} variant="icon" onClick={props.onClick} disabled={props.isDisabled}>
      <div
        className={classNames('flex items-center justify-center rounded-full p-1', {
          'text-sw-disabled': props.isDisabled,
          'hover:bg-sw-active-light hover:text-sw-active': !props.isDisabled
        })}
      >
        <SvgIcon name={props.iconName} />
      </div>
    </DeprecatedButton>
  );
}
