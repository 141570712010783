import {debounce} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

export interface UseOptimisticDebounceProps<TValue> {
  delay?: number;
  value?: TValue;
  onSubmit?: (value: TValue) => void;
  onBeforeDebounce?: () => void;
  onAfterDebounce?: () => void;
}

export function useOptimisticDebounce<TValue>(props: UseOptimisticDebounceProps<TValue>) {
  const isDebouncing = useRef(false);

  const trueOnChangeRef = useRef(props.onSubmit);
  trueOnChangeRef.current = props.onSubmit;

  const onBeforeDebounceRef = useRef(props.onBeforeDebounce);
  onBeforeDebounceRef.current = props.onBeforeDebounce;

  const onAfterDebounceRef = useRef(props.onAfterDebounce);
  onAfterDebounceRef.current = props.onAfterDebounce;

  const delay = props.delay || 300;

  const [optimisticValue, setOptimisticValue] = useState(props.value);

  const debouncedFn = useMemo(() => {
    return debounce((value: TValue) => {
      isDebouncing.current = false;
      trueOnChangeRef.current?.(value);
      onAfterDebounceRef.current?.();
    }, delay);
  }, [delay]);

  const submit = useCallback(
    (value: TValue) => {
      isDebouncing.current = true;
      onBeforeDebounceRef.current?.();
      setOptimisticValue(value);
      debouncedFn(value);
    },
    [debouncedFn]
  );

  useEffect(() => {
    setOptimisticValue(props.value);
  }, [props.value]);

  return {
    isDebouncing: isDebouncing.current,
    optimisticValue,
    submit
  };
}
