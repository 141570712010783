import {PropsWithChildren} from 'react';
import isNil from 'lodash/isNil';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {Shipment, ShipmentMode} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import withFlags from 'App/utils/withFlags';
import InstantRatesCard from 'App/containers/Marketplace/components/InstantRates/legacy';
import {DigitalFreightMarketplaceRatesCardV2} from 'App/containers/Book/FormSections/InstantRatesCardV2';
import DigitalFreightMarketplaceRatesCard from 'App/containers/Book/FormSections/InstantRatesCard';

interface InstantRatesCardStateProps {
  shipments?: {
    selectedShipment?: {
      mode?: ShipmentMode;
    };
    selectedRFQ?: {
      shipment_modes?: ShipmentMode[];
    };
  };
  shipmentdetails?: {
    one?: Shipment;
  };
}

export interface InstantRatesCardWrapperProps {
  connDigitalFreightMarketplace?: boolean;
  shipmentModes?: ShipmentModeEnum[];
  shipment: Shipment;
}

const InstantRatesCardWrapper = (props: PropsWithChildren<InstantRatesCardWrapperProps>) => {
  if (props.shipmentModes?.includes(ShipmentModeEnum.LTL) || props.shipmentModes?.includes(ShipmentModeEnum.FTL)) {
    return <DigitalFreightMarketplaceRatesCardV2 shipment={props.shipment} />;
  }
  // this will never be reached and will be removed in the next PR
  if (props.connDigitalFreightMarketplace && props?.shipmentModes?.includes(ShipmentModeEnum.FTL)) {
    return <DigitalFreightMarketplaceRatesCard />;
  }
  return <InstantRatesCard />;
};

/**
 * Maps shipmentModes determined between the shipment and the Request for Quote. If the shipment has no mode available
 * then the Request for Quote shipment mode(s) are used as the determining factor. If no mode(s) can be determined an empty
 * array is returned.
 */
const mapsStateToProps = (
  state: InstantRatesCardStateProps,
  ownProps: Partial<InstantRatesCardWrapperProps>
): {shipmentModes?: ShipmentModeEnum[]; shipment?: Shipment} => {
  const shipment = ownProps.shipment || state.shipmentdetails?.one;
  // a shipment can only have ONE mode when created so we can validate a singular mode
  const shipmentMode: ShipmentModeEnum | undefined | null = state.shipments?.selectedShipment?.mode
    ?.code as ShipmentModeEnum;
  if (!isNil(shipmentMode)) {
    return {
      shipmentModes: [shipmentMode],
      shipment
    };
  }
  // fall through to trying to look at the selected request for quote object.
  // RFQs can have more than one mode so we need to check for an array
  const rfqModes = state.shipments?.selectedRFQ?.shipment_modes;
  if (!isNil(rfqModes) && rfqModes.length) {
    return {
      shipmentModes: rfqModes.filter((mode) => !isNil(mode.code)).map((mode) => mode.code) as ShipmentModeEnum[],
      shipment
    };
  }
  // just output to the console for easier troubleshooting if there are issues.
  console.error('Could not determine transporation mode from Shipment or RFQ.');
  return {
    shipmentModes: [],
    shipment
  };
};

export default compose<InstantRatesCardWrapperProps, InstantRatesCardWrapperProps>(
  withFlags(
    'connDigitalFreightMarketplace',
    'intLtl3RdPartySuppressRates',
    'intHideSwlDuplicateRates',
    'intP44MultiAccountCode'
  ),
  connect(mapsStateToProps)
)(InstantRatesCardWrapper);
