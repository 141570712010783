import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {Formik, Form, Field} from 'formik';
import {object, string, boolean} from 'yup';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {Button, SvgIcon, FormikCheckbox} from '@shipwell/shipwell-ui';
import {shipmentsShipmentIdPut} from 'App/actions/_shipmentDetails';
import {fetchShipmentStatuses} from 'App/actions/_shipments';
import {shipmentStatusMap, StatusPill} from 'App/utils/shipmentStatus';
import {getPermittedStatusForShipmentMode} from './helpers';
import {useShipment} from 'App/data-hooks/shipments';
import ShipwellLoader from 'App/common/shipwellLoader';

const StatusSelectBase = ({
  dispatch,
  shipmentStatusList,
  excludeStopAutomatedStatuses,
  field,
  shipmentModeCode,
  form: {setFieldValue, setFieldTouched}
}) => {
  useEffect(() => {
    dispatch(
      fetchShipmentStatuses({
        excludeStopAutomatedStatuses,
        filterDraftStatus: true
      })
    );
  }, [dispatch, excludeStopAutomatedStatuses]);

  const handleStatusClick = (status) => (e) => {
    e.stopPropagation();
    setFieldTouched(field.name, true);
    setFieldValue(field.name, status);
  };

  const permittedStatuses = getPermittedStatusForShipmentMode(shipmentStatusList, shipmentModeCode);

  const sortedShipmentStatuses = sortBy(
    (permittedStatuses || []).map((status) => ({
      ...get(shipmentStatusMap, status.id, {}),
      ...status
    })),
    (status) => Object.keys(shipmentStatusMap).indexOf(status.id)
  );

  return (
    <>
      <input type="hidden" {...field} />
      {sortedShipmentStatuses.map((status) => (
        <div
          key={status.id}
          className={classnames('shipment__statusSelector-edit-select-container', {
            'shipment__statusSelector-edit-select-container-selected':
              get(field, 'value', '').toLowerCase() === status.id.toLowerCase()
          })}
          onClick={handleStatusClick(status.id)}
        >
          <StatusPill {...status} />
        </div>
      ))}
    </>
  );
};

const StatusSelect = connect((state) => ({
  shipmentStatusList: state.shipments.shipmentStatuses,
  excludeStopAutomatedStatuses: get(state, 'userCompany.company.feature_flags.stop_status_enabled', false)
}))(StatusSelectBase);

StatusSelect.propTypes = {
  dispatch: PropTypes.func,
  shipmentStatusList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func
  }).isRequired
};

StatusSelect.defaultProps = {
  dispatch: () => {},
  shipmentStatusList: []
};

const statusValidationSchema = object().shape({
  metadata: object().shape({
    open: boolean().nullable()
  }),
  delayed: boolean().nullable(),
  state: string().nullable().required('A shipment status is required.')
});

const StatusEdit = ({dispatch, shipment, onCancel, onSubmit, onError, dashboardShipmentId}) => {
  const {shipment: dashboardShipment} = useShipment(dashboardShipmentId);
  if (dashboardShipmentId && !dashboardShipment) {
    return <ShipwellLoader loading />;
  }
  const theShipment = dashboardShipmentId ? dashboardShipment : shipment;
  const handleSubmit = async (values) => {
    const payload = {...theShipment, ...values, metadata: {...theShipment.metadata, ...values.metadata}};
    try {
      const response = await dispatch(shipmentsShipmentIdPut(theShipment.id, payload));
      if (response.status >= 400) {
        throw response;
      }
      onSubmit();
    } catch (e) {
      console.error('Error updating shipment status', e);
      // let the parent component know about errors so it can properly display them
      onError(e);
    }
  };
  const dashboardClassNames = 'p-2 w-60';

  return (
    <div className={`shipment__statusSelector-edit ${dashboardClassNames}`} onClick={(e) => e.stopPropagation()}>
      <Formik onSubmit={handleSubmit} validationSchema={statusValidationSchema} initialValues={theShipment}>
        {({isSubmitting, dirty}) => (
          <Form>
            <Field component={StatusSelect} name="state" shipmentModeCode={theShipment.mode?.code || ''} />
            <Field component={FormikCheckbox} name="delayed" label="Delayed" onClick={(e) => e.stopPropagation()} />
            <Field component={FormikCheckbox} name="metadata.open" label="Open" onClick={(e) => e.stopPropagation()} />
            <div className="shipment__statusSelector-edit-buttons">
              <Button size="sm" variant="tertiary" disabled={isSubmitting} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                size="sm"
                variant="primary"
                type="submit"
                disabled={isSubmitting || !dirty}
                onClick={(e) => e.stopPropagation()}
              >
                {isSubmitting ? (
                  <>
                    <SvgIcon name="Refresh" className="rotate" /> Saving...
                  </>
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

StatusEdit.propTypes = {
  dispatch: PropTypes.func,
  shipment: PropTypes.shape({
    metadata: PropTypes.shape({
      open: PropTypes.bool
    }),
    delayed: PropTypes.bool,
    state: PropTypes.string
  }).isRequired,
  shipmentModeCode: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  dashboardShipmentId: PropTypes.string
};

StatusEdit.defaultProps = {
  dispatch: () => {},
  onCancel: () => {},
  onSubmit: () => {},
  onError: () => {},
  dashboardShipmentId: undefined
};

export default connect((state, ownProps) => ({
  shipment: state.shipmentdetails.one
}))(StatusEdit);
