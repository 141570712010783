import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation} from '@tanstack/react-query';
import {ComponentType} from 'react';
import {wrapDisplayName} from 'recompose';
import {CommonBillTo, CapacityProviderOptions, ShipmentMode} from '@shipwell/genesis-sdk';
import {transformParcelPayload} from '../../utils/transformParcelPayload';
import {createRateRequest} from 'App/api/genesis/typed';
import {useCapacityProviders} from 'App/data-hooks/integrations/useCapacityProviders';

export const useCreateParcelRateRequest = () => {
  const {
    capacityProviders: parcelCapacityProviders,
    capacityProvidersQuery: {refetch: refetchCapacityProviders}
  } = useCapacityProviders({modes: [ShipmentMode.Parcel]});

  const {mutateAsync: createParcelRateRequest, data} = useMutation({
    mutationFn: async ({
      shipment,
      parcelCapacityProviderOptions,
      billTo
    }: {
      shipment: Shipment;
      parcelCapacityProviderOptions?: CapacityProviderOptions[];
      billTo?: CommonBillTo;
    }) => {
      if (!parcelCapacityProviderOptions?.length || !parcelCapacityProviders?.length) {
        // attempt to get active parcel accounts again
        await refetchCapacityProviders();
        if (!parcelCapacityProviders?.length) {
          return;
        }
      }

      return createRateRequest(
        transformParcelPayload({
          parcelCapacityProviders: parcelCapacityProviderOptions || parcelCapacityProviders,
          shipment,
          billTo
        })
      );
    }
  });

  return {
    parcelRequestId: data?.id || null,
    handleCreateParcelRequest: createParcelRateRequest
  };
};

// HOC for use with class components
export const withCreateParcelRateRequest = <PropsT,>(Component: ComponentType<PropsT>) => {
  const ComponentWithParcelRateRequest = (props: PropsT) => {
    const {handleCreateParcelRequest} = useCreateParcelRateRequest();

    return <Component {...props} handleCreateParcelRequest={handleCreateParcelRequest} />;
  };

  ComponentWithParcelRateRequest.displayName = wrapDisplayName(Component, 'withCreateParcelRateRequest');
  return ComponentWithParcelRateRequest;
};
