import {useCallback, useEffect, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {ColumnOrderState, ColumnSort, SortingState, VisibilityState} from '@tanstack/react-table';
import {UserDashboardConfiguration} from '@shipwell/backend-core-sdk';
import isEqualWith from 'lodash/isEqualWith';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import {browserHistory} from 'react-router';
import {
  cleanEmptyFilters,
  convertFiltersType,
  convertReceivedColumnsToVisibilityState,
  convertSortingStateToSortString,
  convertSortStringToSortingState,
  convertTableStateToConfig,
  convertTypeCorrectFilters,
  SORT_STAND_IN,
  VIEW_URL_SEARCH_PARAM
} from 'App/components/TypedTable/tableUtils';
import {USER_DASHBOARDS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {
  createUserDashboardConfig,
  deleteUserDashboardConfig,
  getUserDashboardConfigs,
  updateUserDashboardConfig
} from 'App/api/tableConfig/typed';
import {Columns, Filters} from 'App/containers/Dashboard/utils/DashboardUtilTypes';

type SavedTable<T> = {
  tableType: string;
  initialFilterState: T;
  filterState: T;
  onFilterChange: (filterName: keyof T, value: string | string[]) => void;
  initialColumnOrder: ColumnOrderState;
  columnOrder: ColumnOrderState;
  onColumnOrderChange: (colOrder: ColumnOrderState) => void;
  initialColumnVisibility?: VisibilityState;
  columnVisibility: VisibilityState;
  onColumnVisibilityChange: (colVis: VisibilityState) => void;
  initialSortState?: ColumnSort;
  sortString?: string;
  onSort: (newSort: SortingState, dashboardViewFilters?: Record<string, string | string[]>) => void;
  shouldUseRouter?: boolean;
  transformFilters?: (filters: Filters) => Filters;
  transformColumns?: (columns: Columns) => Columns;
  onDashboardChange: (dashboardFilters?: T) => void;
  allowOverrideLoadDashboard?: boolean;
  setStatusToast?: ({title, message}: {title: string; message: string}) => void;
};

export function useSavedTable<T extends Record<string, string | string[]>>({
  tableType,
  initialFilterState,
  filterState,
  initialColumnOrder,
  columnOrder,
  onColumnOrderChange,
  initialColumnVisibility,
  columnVisibility,
  onColumnVisibilityChange,
  initialSortState,
  sortString,
  onSort,
  shouldUseRouter = false,
  transformFilters,
  transformColumns,
  onDashboardChange,
  allowOverrideLoadDashboard = false,
  setStatusToast
}: SavedTable<T>) {
  const searchParamURL = new URLSearchParams(window.location.search);
  const [selectedDashboardId, setSelectedDashboardId] = useState(searchParamURL.get(VIEW_URL_SEARCH_PARAM) || '');
  const [createDashboardSuccess, setCreateDashboardSuccess] = useState(false);
  const dashboardsQuery = useQuery(
    [USER_DASHBOARDS_QUERY_KEY, tableType],
    async () => {
      const response = await getUserDashboardConfigs({page: 1, pageSize: 100, tableType});
      return response.data;
    },
    {
      onSuccess: (data) => {
        const searchParamURL = new URLSearchParams(window.location.search);
        const nonViewFilters: string[] = [];
        for (const [key] of searchParamURL.entries()) {
          if (key !== VIEW_URL_SEARCH_PARAM) {
            nonViewFilters.push(key);
          }
        }
        const shouldLoadDashboard = allowOverrideLoadDashboard ? !nonViewFilters.length : true;
        if (shouldLoadDashboard) {
          const dashboardIdSearchParam = searchParamURL.get(VIEW_URL_SEARCH_PARAM);
          // if there is a dashboard id in the url, set that as the selected dashboard
          if (dashboardIdSearchParam) {
            const dashboard = data.results?.find((d) => d.id === dashboardIdSearchParam);
            if (transformFilters) {
              const dashboardWithTransformedFilters = {
                ...dashboard,
                filters: transformFilters(dashboard?.config?.filters)
              };
              return selectDashboard(dashboardWithTransformedFilters);
            }
            // if no dashboard is found via search params (maybe user deleted part of the url)...
            // ...attempt to select default below
            if (dashboard) {
              return selectDashboard(dashboard);
            }
          }
          // if a dashboard is found via URL params, set table to defaultDashboard
          const defaultDashboard = data.results?.find((dashboard) => dashboard.is_default);
          if (defaultDashboard) {
            return selectDashboard(defaultDashboard);
          }
        }
      }
    }
  );

  const queryClient = useQueryClient();
  const createDashboardMutation = useMutation(createUserDashboardConfig);
  const updateDashboardMutation = useMutation(updateUserDashboardConfig);
  const deleteDashboardMutation = useMutation(deleteUserDashboardConfig);

  const onCreateDashboard = (isDefault: boolean, name: string) => {
    createDashboardMutation.mutate(
      {
        is_default: isDefault,
        name,
        config: convertTableStateToConfig({columnVisibility, columnOrder, filterState, sortString}),
        table_type: tableType
      },
      {
        onSuccess: (data) => {
          setCreateDashboardSuccess(true);
          if (setStatusToast)
            setStatusToast({title: 'Success!', message: `Your dashboard view, ${name}, has been saved`});
          addDashboardIdToSearchParams(data.data.id || '');
          setSelectedDashboardId(data.data.id || '');
        },
        onSettled: () => {
          void queryClient.invalidateQueries([USER_DASHBOARDS_QUERY_KEY]);
        }
      }
    );
  };

  const handleDashboardMutation = (dashboardId: string, config: Partial<UserDashboardConfiguration>) => {
    updateDashboardMutation.mutate(
      {
        userDashboardConfigurationId: dashboardId,
        userDashboardConfiguration: config
      },
      {
        onSuccess: () => {
          setCreateDashboardSuccess(true);
          if (setStatusToast)
            setStatusToast({title: 'Success!', message: `Your dashboard view, ${config.name}, has been updated`});
        },
        onSettled: () => {
          void queryClient.invalidateQueries([USER_DASHBOARDS_QUERY_KEY]);
        }
      }
    );
  };

  // Overload function used here because this function can is used both in the "Saved" sidebar tab...
  // ...to update any of the saved configs.
  // OR it can be used to update the current config on either the "Filters" or "Columns" tab.
  function onUpdateDashboard(dashboardId: string, config: Partial<UserDashboardConfiguration>): void;
  function onUpdateDashboard(dashboardId?: null, config?: null): void;

  function onUpdateDashboard(dashboardId?: string | null, config?: Partial<UserDashboardConfiguration> | null): void {
    // if no dashboardId or config is supplied, update via selectedDashboardId
    if (!dashboardId || !config) {
      const dashboard = dashboardsQuery.data?.results?.find((dashboard) => dashboard.id === selectedDashboardId);
      return handleDashboardMutation(selectedDashboardId, {
        name: dashboard?.name,
        config: convertTableStateToConfig({columnVisibility, columnOrder, filterState, sortString}),
        is_default: dashboard?.is_default || false,
        table_type: tableType
      });
    }
    handleDashboardMutation(dashboardId, config);
  }
  const onDeleteDashboard = (dashboardId: string) => {
    const dashboard = dashboardsQuery.data?.results?.find((dashboard) => dashboard.id === dashboardId);
    deleteDashboardMutation.mutate(dashboardId, {
      onSettled: () => {
        setSelectedDashboardId('');
        if (setStatusToast)
          setStatusToast({title: 'Success!', message: `Your dashboard view, ${dashboard?.name}, has been deleted`});
        void queryClient.invalidateQueries([USER_DASHBOARDS_QUERY_KEY]);
      }
    });
  };

  const selectDashboard = (dashboard: UserDashboardConfiguration) => {
    let dashboardFilters: {[key: string]: string[]};
    let dashboardColumns: string[] = [];
    if (transformFilters) {
      dashboardFilters = transformFilters(dashboard.config?.filters) || {};
    } else dashboardFilters = dashboard.config?.filters || {};
    // set filters based on config
    onDashboardChange(dashboardFilters as T);
    if (transformColumns) {
      dashboardColumns = transformColumns(dashboard.config?.columns) || [];
    } else dashboardColumns = dashboard.config?.columns || [];
    // cols not in dashboard config
    const missingCols = initialColumnOrder.filter((col) => dashboardColumns.every((dashCol) => dashCol !== col));
    // combine dashboard config columns with missing to make sure all columns are accounted for
    const newColOrder = [...dashboardColumns, ...missingCols];
    // column visibility state is only interested in hidden columns
    const missingColVis = missingCols.reduce<Record<string, boolean>>((acc, colKey) => {
      return {
        ...acc,
        [colKey]: false
      };
    }, {});
    const includedColVis = dashboardColumns.reduce<Record<string, boolean>>((acc, colKey) => {
      return {
        ...acc,
        [colKey]: true
      };
    }, {});
    // set column visibility
    onColumnVisibilityChange({...missingColVis, ...includedColVis});
    // set column order
    onColumnOrderChange(newColOrder);
    // set sort
    // SORT_STAND_IN should not be used to set the column sort
    const isSortStandIn = dashboard.config?.ordering?.[0] === SORT_STAND_IN;
    const sortString = dashboard.config?.ordering?.[0] || '';
    const dashboardViewFilters = {view: dashboard.id || '', ...dashboardFilters};
    //we pass along the dashboard view filters to the onSortChange function so that we can update the url
    //params, otherwise the filters and view param get cleared out when we set the sort param.
    onSort(!isSortStandIn ? convertSortStringToSortingState(sortString) : [], dashboardViewFilters);
    if (dashboard.id) {
      setSelectedDashboardId(dashboard.id);
      addDashboardIdToSearchParams(dashboard.id);
    }
  };

  // if hook has shouldUseRouter prop true, add dashboardId to searchparams
  const addDashboardIdToSearchParams = (dashboardId: string) => {
    if (!shouldUseRouter) return;
    const searchParams = new URLSearchParams(window.location.search);
    // legacy dashboard uses view as url search param
    // if there is a current search param, remove it then add current dashboardId
    searchParams.delete(VIEW_URL_SEARCH_PARAM);
    searchParams.append(VIEW_URL_SEARCH_PARAM, dashboardId);
    browserHistory.push({
      pathname: window.location.pathname,
      search: `?${searchParams.toString()}`
    });
  };

  const onSelectDashboard = (dashboardId?: string) => {
    // clear the selected dashboard if the user clicks on active dashboard item
    if (!dashboardId || dashboardId === selectedDashboardId) {
      setSelectedDashboardId('');
      onDashboardChange();
      onColumnVisibilityChange(initialColumnVisibility || {});
      onColumnOrderChange(initialColumnOrder);
    } else {
      const dashboard = dashboardsQuery.data?.results?.find((d) => d.id === dashboardId);
      if (!dashboard) return;
      selectDashboard(dashboard);
    }
  };

  useEffect(() => {
    if (createDashboardSuccess) {
      setTimeout(() => {
        setCreateDashboardSuccess(false);
      }, 2000);
    }
  }, [createDashboardSuccess]);

  const isMatchingColumnOrder = useCallback(
    (configColumns: string[], columnOrder: string[]) => {
      // remove hidden columns from columnOrder
      const cleanedColumnOrder = columnOrder.reduce<string[]>((acc, col) => {
        const value = columnVisibility?.[col];
        if (typeof value === 'boolean' && !value) {
          return acc;
        }
        return [...acc, col];
      }, []);
      // because the BE has no concept of hidden columns, if a dashboard is selected we need to compare the column state without hidden columns
      return isEqualWith(configColumns, selectedDashboardId ? cleanedColumnOrder : columnOrder);
    },
    [columnVisibility, selectedDashboardId]
  );
  const isMatchingSort = useCallback(
    (configSort: string) => {
      return configSort === (sortString || SORT_STAND_IN);
    },
    [sortString]
  );
  const isMatchingFilters = useCallback(
    (configFilters: Record<string, string[]>) => {
      const typeCorrectFilters = convertTypeCorrectFilters(configFilters, filterState);
      const cleanedFilterState = cleanEmptyFilters(filterState);
      return isEqualWith(typeCorrectFilters, cleanedFilterState);
    },
    [filterState]
  );
  const isMatchingColumnVisibility = useCallback(
    (configVisibility?: VisibilityState) => {
      const getHiddenColKeys = (columns: VisibilityState) =>
        Object.keys(columns).reduce((acc, key) => {
          if (columns[key]) {
            return acc;
          }
          return [...acc, key];
        }, [] as string[]);

      if (!configVisibility) {
        // if no configVisibility, we can assume all columns are visible by default
        // so if the current columnVisibility state has hidden keys it has been updated from default
        return !getHiddenColKeys(columnVisibility).length;
      }
      const configHiddenKeys = getHiddenColKeys(configVisibility);
      const currentHiddenKeys = getHiddenColKeys(columnVisibility);
      const isMatchingHiddenKeys = isEmpty(xor(configHiddenKeys, currentHiddenKeys));
      return isMatchingHiddenKeys;
    },
    [columnVisibility]
  );

  const isDefaultTable =
    isMatchingColumnOrder(initialColumnOrder, columnOrder) &&
    isMatchingColumnVisibility(initialColumnVisibility) &&
    isMatchingFilters(convertFiltersType(cleanEmptyFilters(initialFilterState))) &&
    isMatchingSort(initialSortState ? convertSortingStateToSortString([initialSortState]) : SORT_STAND_IN);
  const isNewDashboard = !isDefaultTable && !selectedDashboardId;

  const isMatchingDashboard = (dashboard: UserDashboardConfiguration) => {
    const currentConfig = dashboard.config;
    const colOrderMatches = isMatchingColumnOrder(currentConfig?.columns || [], columnOrder);
    const colVizMatches = isMatchingColumnVisibility(
      convertReceivedColumnsToVisibilityState(currentConfig?.columns || [], initialColumnOrder)
    );
    const filtersMatch = isMatchingFilters(currentConfig?.filters || {});
    const sortMatches = isMatchingSort(currentConfig?.ordering?.[0] || '');
    return colOrderMatches && colVizMatches && filtersMatch && sortMatches;
  };

  // if a dashboard is selected, this func checks if any changes have been made to its config
  const isUpdatedDashboard = () => {
    const currentDashboard = dashboardsQuery.data?.results?.find((dashboard) => dashboard.id === selectedDashboardId);
    if (!currentDashboard) {
      return false;
    }
    return !isMatchingDashboard(currentDashboard);
  };

  return {
    dashboardsQuery,
    onCreateDashboard,
    onUpdateDashboard,
    onDeleteDashboard,
    onSelectDashboard,
    selectedDashboardId,
    isNewDashboard: isNewDashboard || isUpdatedDashboard(),
    createDashboardSuccess,
    setSelectedDashboardId
  };
}
