import {Component} from 'react';
import _ from 'lodash';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {bind} from 'App/utils/camelize';
import 'react-table-6/react-table.css';
import './_header.scss';
import {Button as ShipwellButton} from '@shipwell/shipwell-ui';
import RecentShipments from 'App/containers/quotes/create/components/recentShipments';
import {TryItNowTag} from 'App/containers/Dashboard/components/TryItNowTag';
import {FlexBox} from 'App/components/Box';
import withFlags from 'App/utils/withFlags';

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    shipperRelationships: state.brokers.shipperRelationshipsForHeader,
    selectedShipment: state.shipments.selectedShipment,
    shipments: state.shipments.all,
    hazmatCodes: state.shipments.hazmatCodes,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    isLoading: state.shipments.isLoading
  }),
  {...actions, ...brokerActions, ...shipmentDetailsActions}
)
class Header extends Component {
  constructor(props) {
    super(props);

    bind(this, ['cloneShipment']);

    this.state = {
      showCloneModal: false,
      newShipmentBuilderPreference: JSON.parse(localStorage.getItem('new-shipment-builder-preference')) || false
    };
  }

  togglePreference = () => {
    localStorage.setItem('new-shipment-builder-preference', JSON.stringify(true));
    this.setState({newShipmentBuilderPreference: true});
    this.props.router.push(`/shipment/${this.getShipmentMode()}/create`);
  };

  getShipmentMode() {
    const url = window.location.pathname;
    const prefix = 'new-shipment-';
    if (!url.includes(prefix)) {
      return null;
    }
    const parts = url.split(prefix);
    return parts[1] || null;
  }

  cloneShipment(shipment) {
    //re-fetch the full shipment details before proceeding
    this.props.getShipmentDetails(shipment.id).then((response) => {
      let cloneError = false;
      if (response.status === 200) {
        const shipmentToClone = JSON.parse(JSON.stringify(response.details));

        // don't clone order refs
        shipmentToClone.line_items = shipmentToClone.line_items.map((li) => ({...li, purchase_order: null}));
        shipmentToClone.delayed = false;

        this.props.cloneShipment(shipmentToClone);
      } else {
        cloneError = true;
      }
      this.setState({cloneError: cloneError, showCloneModal: false});
    });
  }

  showNewShipmentBanner() {
    return (
      ['air', 'ftl', 'ltl', 'vltl', 'intermodal', 'drayage', 'rail'].includes(this.getShipmentMode()) &&
      this.props.newShipmentCreationExperience
    );
  }

  render() {
    const {label, hideLabel} = this.props;

    return (
      <div className="quotingHeader">
        <div className="quotingHeader__renderInline">
          <span className="quotingHeader__reference">
            {!hideLabel && <h1 className="quotingHeader__reference-title">{label}</h1>}
          </span>
          <div className="flex justify-end items-center">
            {this.props.showCloneLink && (
              <span className="quotingHeader__cloneButton">
                <Button
                  bsStyle="link"
                  className="btn-tertiary"
                  onClick={() => {
                    this.setState({showCloneModal: true});
                  }}
                >
                  <i className="icon icon-Files pad-right" />
                  Clone from Previous Shipment
                </Button>
              </span>
            )}
            {this.props.hasCustomer && (
              <span className="shipment-quoting-header__customerName">
                <i className="icon icon-Rolodex" />{' '}
                {this.props.selectedShipment &&
                  this.props.selectedShipment.customer &&
                  this.props.selectedShipment.customer.name}
              </span>
            )}
            {this.showNewShipmentBanner() && (
              <TryItNowTag defaultIsOpen={!this.state.newShipmentBuilderPreference}>
                <FlexBox items="center" gap="s">
                  <span className="font-bold">New Shipment Builder Available!</span>
                  <a
                    className="text-xs underline"
                    href="https://docs.google.com/document/d/1vBsmu2G8uLVXmxkfTtqLEpuVpRfioadikBDGPVOSWtg/edit?tab=t.0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                  <ShipwellButton size="sm" isCompact onClick={this.togglePreference}>
                    Try it Today
                  </ShipwellButton>
                </FlexBox>
              </TryItNowTag>
            )}
          </div>
        </div>
        <InfoModalWrapper
          show={this.state.showCloneModal}
          extraClass={'quotingHeader__cloneModal'}
          bsSize="large"
          onHide={() => {
            this.setState({showCloneModal: false});
          }}
          title="Clone Shipment"
          error={this.state.showError ? this.state.errors : null}
        >
          {' '}
          <RecentShipments onSelectShipment={this.cloneShipment.bind(this)} />
        </InfoModalWrapper>
      </div>
    );
  }
}
export default withRouter(
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(withFlags('newShipmentCreationExperience')(Header))
);
