import {Title, Rule, DeprecatedButton} from '@shipwell/shipwell-ui';
import classnames from 'classnames';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {useMutation, useQuery} from '@tanstack/react-query';
import moment from 'moment';
import {
  statusWithoutExecutionInstance,
  getActionsWithExecutionInstance,
  getActionsWithoutExecutionInstance,
  loadingAction
} from './utils';
import {WorkflowAction, WorkflowStatus} from './';
import {cancelWorkflowExecution, getWorkflow, getWorkflowExecutionShipmentInstance} from 'App/api/workflows';
import {getWorkflowFormValues} from 'App/containers/workflows/utils';
import ShipwellLoader from 'App/common/shipwellLoader';
import {POLICY_TYPE_ROUTING_GUIDE} from 'App/containers/workflows/workflowConstants';
import {
  WORKFLOW_DETAILS_QUERY_KEY,
  WORKFLOW_EXECUTION_QUERY_KEY,
  POLICIES_QUERY_KEY,
  WORKFLOW_FORM_VALUES_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import {getPolicies} from 'App/api/policies';

const WorkflowSummaryCard = ({summary, isMobileView, action}) => {
  const {id: workflowId, version_number: versionNumber} = summary.workflow;
  const id = summary.id;

  const executionsQuery = useQuery([WORKFLOW_EXECUTION_QUERY_KEY, id], () => getWorkflowExecutionShipmentInstance(id), {
    // set to false because many of these queries will fail by default...
    // ...because this endpoint cannot see executions made previous to the service's deployment
    retry: false
  });

  // workflowDetailsQuery should only be called if executionsQuery fails
  const workflowDetailsQuery = useQuery(
    [WORKFLOW_DETAILS_QUERY_KEY, workflowId, versionNumber],
    () => getWorkflow(workflowId, versionNumber),
    {enabled: executionsQuery.isError}
  );
  // workflowActionsQuery should only be called if workflowDetailsQuery succeeds
  const workflowActionsQuery = useQuery(
    [WORKFLOW_FORM_VALUES_QUERY_KEY, workflowId, versionNumber],
    () =>
      getWorkflowFormValues({
        workflow: workflowDetailsQuery.data.data,
        policyType: POLICY_TYPE_ROUTING_GUIDE
      }),
    {enabled: workflowDetailsQuery.isSuccess}
  );

  // If workflow is new, it will show in the executionsQuery.
  // Otherwise, we use the workflowActionsQuery as a fallback.
  const workflow = executionsQuery.isSuccess ? executionsQuery.data?.data?.workflow : workflowActionsQuery.data;
  // this is used as unique identifier for the workflow's actionQuery found in the WorkflowAction component
  const versionPlusId = `${workflow?.version_number || versionNumber}-${id}`;

  const policyQuery = useQuery([POLICIES_QUERY_KEY, workflowId], () =>
    getPolicies({
      workflowId: [workflowId]
    })
  );
  const [policy] = policyQuery.data?.body?.results?.filter((result) => result.name === workflow?.name) || [];

  // workflowStatus uses summary as a fallback in the event executionsQuery fails
  const workflowStatus = executionsQuery.isSuccess
    ? executionsQuery.data?.data?.status
    : statusWithoutExecutionInstance(summary);

  const isSuccess = executionsQuery.isSuccess || workflowActionsQuery.isSuccess;
  const isLoading = executionsQuery.isInitialLoading || workflowActionsQuery.isInitialLoading;

  const cancelRoutingGuideMutation = useMutation(() => cancelWorkflowExecution(id), {
    onSuccess: () => executionsQuery.refetch()
  });

  if (workflowActionsQuery.isError && executionsQuery.isError) {
    return null;
  }

  const statusTheme = () => {
    switch (workflowStatus) {
      case 'FAILED':
        return {
          color: 'sw-error',
          icon: 'ErrorOutlined'
        };
      case 'RUNNING':
        return {
          color: 'sw-primary',
          icon: 'Running'
        };
      case 'SUCCESS':
        return {
          color: 'sw-success',
          icon: 'CheckCircleOutlined'
        };
      case 'CANCELLED':
        return {
          color: 'sw-icon',
          icon: 'Cancel'
        };
      default:
        return {
          color: 'sw-primary',
          icon: 'Running'
        };
    }
  };
  const {icon, color} = statusTheme();

  // returns workflow actions with error and status info added
  const getActions = () => {
    // it is possible that the workflow instance was ran before the execution code...
    // ...was added to the backend. If that is the case, the executionsQuery will fail.
    // In that case we have to derive action status in a much less accurate way.
    if (executionsQuery.isError || !executionsQuery.data?.data?.events) {
      return getActionsWithoutExecutionInstance(workflowActionsQuery.data?.actions, summary);
    }
    const executionQueryEvents = executionsQuery.data?.data?.events;
    return getActionsWithExecutionInstance(executionQueryEvents, workflow?.actions || [], workflowStatus);
  };

  const showCancelButton = workflowStatus === 'RUNNING';

  return (
    <div className="relative flex rounded-md border border-sw-border bg-sw-background-component drop-shadow">
      <div className={`bg- w-2 rounded-l-md${color}`} aria-hidden />
      <div className="relative flex w-full items-start justify-between gap-4 p-4">
        <div
          className={classnames('flex', 'gap-4', 'w-full', 'flex-col', {
            'lg:flex-row': !isMobileView
          })}
        >
          <div className={classnames('flex', 'justify-between', {'lg:pr-16': !isMobileView})}>
            <div>
              {policyQuery.isSuccess ? (
                <Link to={`/automation/routing-guides/${policy?.id}`}>
                  <Title className="text-sw-primary">{workflow?.name}</Title>
                </Link>
              ) : (
                <Title>{workflow?.name}</Title>
              )}
              <div className="flex flex-col gap-1 pt-4 text-xs">
                <span>
                  {/* currently the endpoint is only showing shipment creation */}
                  <strong>Trigger:</strong> Shipment Creation
                </span>
                <span>
                  <strong>Start Date:</strong> {moment(summary?.start_time).format('MM-DD-YYYY')}
                </span>
                <span>
                  <strong>End Date:</strong> {summary?.end_time ? moment(summary?.end_time).format('MM-DD-YYYY') : '--'}
                </span>
                <span className="capitalize">
                  <strong>Status:</strong> {policy?.status?.toLowerCase()}
                </span>
              </div>
            </div>
            {isSuccess ? (
              <div className={classnames({block: isMobileView, 'lg:hidden': !isMobileView})}>
                {action ?? <WorkflowStatus icon={icon} color={color} status={workflowStatus} />}
              </div>
            ) : null}
          </div>
          <div className={classnames('-mb-2', {'lg:hidden': !isMobileView})}>
            <Rule />
          </div>
          <div className={classnames('w-px', 'bg-sw-border', {hidden: isMobileView})} aria-hidden />
          <div className="flex flex-col gap-1">
            {isLoading
              ? Array.from({length: 3}, (_, i) => i).map((v) => (
                  <WorkflowAction key={v} action={loadingAction} stepNumber="--" />
                ))
              : null}
            {!isLoading &&
              isSuccess &&
              getActions()?.map((action, i) => (
                <WorkflowAction key={action.STEP_ID} action={action} id={versionPlusId} stepNumber={i + 1} />
              ))}
            {isMobileView && showCancelButton ? (
              <DeprecatedButton
                className="mt-4 w-full whitespace-nowrap"
                variant="secondary"
                onClick={cancelRoutingGuideMutation.mutate}
              >
                Cancel Workflow
              </DeprecatedButton>
            ) : null}
          </div>
        </div>
        {isSuccess ? (
          <div
            className={classnames('tw-hidden', {
              'lg:flex': !isMobileView,
              'lg:flex-col': !isMobileView,
              'justify-between': !isMobileView,
              'h-full': !isMobileView,
              hidden: isMobileView
            })}
          >
            {action ?? (
              <>
                <WorkflowStatus icon={icon} color={color} status={workflowStatus} />
                {showCancelButton ? (
                  <DeprecatedButton
                    className="whitespace-nowrap"
                    variant="secondary"
                    onClick={cancelRoutingGuideMutation.mutate}
                  >
                    Cancel Workflow
                  </DeprecatedButton>
                ) : null}
              </>
            )}
          </div>
        ) : null}
      </div>
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="absolute inset-0 bg-sw-background opacity-50" />
          <ShipwellLoader loading />
        </div>
      ) : null}
    </div>
  );
};

WorkflowSummaryCard.propTypes = {
  summary: PropTypes.shape({
    workflow: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      version_number: PropTypes.number
    }),
    id: PropTypes.string,
    workflow_id: PropTypes.string,
    version_number: PropTypes.number,
    skipped_step_explanations: PropTypes.array,
    execution_steps: PropTypes.arrayOf(
      PropTypes.shape({
        step_id: PropTypes.string,
        started_at: PropTypes.string,
        ended_at: PropTypes.string
      })
    ),
    started_at: PropTypes.string,
    end_time: PropTypes.string,
    start_time: PropTypes.string,
    failed: PropTypes.bool,
    current_running_step_id: PropTypes.string
  }).isRequired,
  isMobileView: PropTypes.bool.isRequired,
  action: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default WorkflowSummaryCard;
