import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';
import {AppointmentStatusIcon} from './AppointmentStatusIcon';
import {FormattedReference} from '../../utils';
import {translateReferenceType} from 'App/containers/appointments/utils';

interface AppointmentStatusInfoProps {
  status: AppointmentStatusEnum;
  companyName: string | undefined;
  references: FormattedReference[];
}

export const AppointmentStatusInfo = ({status, references, companyName}: AppointmentStatusInfoProps) => {
  return (
    <div className="flex items-center gap-2">
      <AppointmentStatusIcon status={status} />
      <div className="flex flex-col gap-1">
        {references.map((reference, index) => (
          <span key={index} className="font-bold text-[12px] truncate max-w-[300px]">
            {`${translateReferenceType(reference.qualifier)}: ${reference.value}`}
          </span>
        ))}
        {companyName && <span className="font-normal text-[12px]">{companyName}</span>}
      </div>
    </div>
  );
};
