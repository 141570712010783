import {useState, useEffect, useCallback, useMemo} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {connect} from 'react-redux';
import flatten from 'lodash/flatten';
import {Link, RouteComponentProps} from 'react-router';
import {SvgIcon, DeprecatedButton, Dropdown, Modal, Drawer, Toast} from '@shipwell/shipwell-ui';
import {User, Task, Company} from '@shipwell/backend-core-singlerequestparam-sdk';
import Navigation from './navigation';
import MobileNavigation from './mobileNavigation';
import {isCurrentLocation} from './helpers/isCurrentLocation';
import Tasks from 'App/components/Tasks/Tasks';
import {leftArrow} from 'App/common/Assets';
import Inbox from 'App/features/inbox';
import GlobalSearchField from 'App/formComponents/fields/globalSearch';
import {getInbox} from 'App/api/inbox/typed';
import useToggleIntercom from 'App/utils/hooks/useToggleIntercom';
import {addTabVisibilityEvent} from 'App/utils/globals';
import {getTabVisibilityEventTypes} from 'App/utils/globalsTyped';
import {TenantPickerMenuItem} from 'App/components/TenantPicker';
import {State} from 'App/reducers/types';
import {INBOX_MESSAGES} from 'App/data-hooks/queryKeys';
import useInfiniteScroll from 'App/utils/hooks/useInfiniteScroll';
import './styles.scss';
import {getUserAvatarUrl} from 'App/utils/userAvatar';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import AirShipmentForm from 'App/containers/Shipment/components/AirShipmentForm';
import {basePath} from 'App/api/config';

export const shipwellReleaseNotesLink = 'https://intercom.help/shipwell/en/collections/3317683-release-notes';

type BrokerLogo = {image_type: string; company_name: string; logo: string};
interface MainHeaderProps extends RouteComponentProps<unknown, unknown> {
  userProfile: Partial<User>;
  brokerLogos: BrokerLogo[];
  systemVersionOutOfDate: boolean;
  isShipwell: boolean | undefined;
  company: Partial<Company>;
}
/**
 * Authenticated App Header
 * @param {*} props
 */
const MainHeader = (props: MainHeaderProps) => {
  const {userProfile, brokerLogos, systemVersionOutOfDate, isShipwell, router} = props;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [hasUnreadInbox, setHasUnreadInbox] = useState(false);
  const [logo, setLogo] = useState('');
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showTasksModal, setShowTasksModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>();
  const [showAirModal, setShowAirModal] = useState(false);
  const {toggleIntercom} = useToggleIntercom();

  const isDev = !basePath?.startsWith('https://api.shipwell.com/');
  const queryClient = useQueryClient();

  const {handleScroll, pages: inboxPages} = useInfiniteScroll([INBOX_MESSAGES], getInbox, {}, 5);
  const inboxData = useMemo(() => flatten(inboxPages.map((page) => page.results?.map((a) => a))), [inboxPages]);

  const handleTabVisibityChange = useCallback(() => {
    const {hidden} = getTabVisibilityEventTypes(document);
    const isHidden = hidden ? document?.hidden : null;

    if (!isHidden) {
      void queryClient.invalidateQueries([INBOX_MESSAGES]);
    }
  }, [queryClient]);

  /** Set inbox notification icon when unread messages */
  useEffect(() => {
    setHasUnreadInbox(inboxData?.some((item) => !item?.read) || false);
  }, [inboxData]);

  /** Set Logo */
  useEffect(() => {
    const companyLogo = brokerLogos.find((logo) => logo.image_type === 'INLINE_COLOR');
    if (companyLogo?.company_name === 'Shipwell') {
      setLogo('/images/svgs/shipwell-logomark.svg');
    } else {
      setLogo(companyLogo?.logo || '');
    }
  }, [brokerLogos]);

  /** Setup Inbox polling */
  useEffect(() => {
    addTabVisibilityEvent(handleTabVisibityChange);
    handleTabVisibityChange();
  }, [handleTabVisibityChange]);

  return (
    <header className="sw-header-wrapper">
      <div className="sw-header">
        <div>
          <Link
            to="/dashboard"
            className="logo"
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              isCurrentLocation('/dashboard') && e.preventDefault();
            }}
          >
            <img src={logo} alt="Logo" className="logo-color" height="37" />
            {isDev ? <strong>DEMO</strong> : null}
          </Link>
          <Navigation newAirShipmentHandler={() => setShowAirModal(true)} />
          <div className="mobile-nav-actions">
            <Drawer show={showMobileNav} onClose={() => setShowMobileNav(false)}>
              <MobileNavigation
                router={router}
                onClose={() => setShowMobileNav(false)}
                onShowTerms={() => {
                  setShowTerms(true);
                  setShowMobileNav(false);
                }}
                onShowTasks={() => {
                  setShowTasksModal(true);
                  setShowMobileNav(false);
                }}
                onShowPrivacyPolicy={() => {
                  setShowPrivacyPolicy(true);
                  setShowMobileNav(false);
                }}
                inbox={inboxData}
                handleScroll={handleScroll}
                newAirShipmentHandler={() => setShowAirModal(true)}
              />
            </Drawer>
            <GlobalSearchField router={router} />
            <i className="material-icons mobile-nav-btn" onClick={() => setShowMobileNav(true)}>
              menu
            </i>
          </div>
        </div>
        <div className="sw-auth-nav">
          <GlobalSearchField router={router} />
          <Dropdown
            alignEnd
            variant="icon"
            indicator={false}
            icon={
              <div>
                <SvgIcon name="AlertFilled" />
                {hasUnreadInbox && <i className="alert-dot" />}
              </div>
            }
          >
            {() => [<Inbox router={router} inbox={inboxData} key="inbox" handleScroll={handleScroll} />]}
          </Dropdown>
          {isShipwell && (
            <DeprecatedButton variant="icon" icon={<SvgIcon name="Task" />} onClick={() => setShowTasksModal(true)} />
          )}
          <Dropdown
            alignEnd
            variant="icon"
            indicator={false}
            icon={
              <a role="button" className="sw-avatar">
                {userProfile && <img id="userAvatar" alt="userAvatar" src={getUserAvatarUrl(userProfile)} />}
              </a>
            }
          >
            {({onClick}: {onClick: () => void}) => (
              <div className="profile-menu" key="profile-menu">
                <p>
                  <strong>
                    {userProfile.first_name} {userProfile.last_name}
                  </strong>
                </p>
                <p>
                  <strong>{userProfile.email}</strong>
                </p>
                {!userProfile.is_quoting_limited_user && (
                  <p>
                    <Link to="/user/profile" onClick={onClick}>
                      Profile and Preferences
                    </Link>
                  </p>
                )}
                <hr />
                <TenantPickerMenuItem />
                <p>
                  <a
                    href={shipwellReleaseNotesLink}
                    target="_blank"
                    title="View the Platform Release Notes"
                    rel="noopener noreferrer"
                    onClick={onClick}
                  >
                    <span>Release Notes</span>
                  </a>
                </p>
                <p>
                  <a
                    role="button"
                    onClick={() => {
                      setShowPrivacyPolicy(true);
                      onClick();
                    }}
                  >
                    Privacy Policy
                  </a>
                </p>
                <p>
                  <a
                    role="button"
                    onClick={() => {
                      setShowTerms(true);
                      onClick();
                    }}
                  >
                    Terms &amp; Conditions
                  </a>
                </p>
                <hr />
                <p>
                  <Link to="/signout" onClick={onClick}>
                    Sign Out
                  </Link>
                </p>
              </div>
            )}
          </Dropdown>
          <div className="sw-knowledgeCenter">
            <SvgIcon name="HelpFilled" color="$sw-primary" className="cursor-pointer" onClick={toggleIntercom} />
          </div>
        </div>
      </div>
      <Modal
        className="tasks-modal"
        bodyVariant="disableOverflowScroll"
        show={showTasksModal || !!selectedTask}
        title={selectedTask ? selectedTask.description : 'Tasks'}
        footerComponent={null}
        prepend={<DeprecatedButton variant="icon" icon={leftArrow()} onClick={() => setSelectedTask(null)} />}
        onClose={() => {
          setSelectedTask(null);
          setShowTasksModal(false);
        }}
      >
        <Tasks
          shouldShowOwner
          shipmentId={null}
          selectedTask={selectedTask}
          showTask={(task: Task) => setSelectedTask(task)}
          clearTask={() => setSelectedTask(null)}
          closeParent={() => {
            setSelectedTask(null);
            setShowTasksModal(false);
          }}
        />
      </Modal>
      <Modal
        show={showPrivacyPolicy}
        title="Privacy Policy"
        footerComponent={null}
        onClose={() => setShowPrivacyPolicy(false)}
      >
        <iframe
          title="privacy policy"
          style={{width: '100%', height: '100%', border: 'none', minHeight: '600px'}}
          src="https://s3-us-west-2.amazonaws.com/shipwell-static/privacy_policy.html"
        />
      </Modal>
      <Modal show={showTerms} title="Terms and Conditions" footerComponent={null} onClose={() => setShowTerms(false)}>
        <iframe
          title="terms and conditions"
          style={{width: '100%', height: '100%', border: 'none', minHeight: '600px'}}
          src="https://s3-us-west-2.amazonaws.com/shipwell-static/full_terms_and_conditions.html"
        />
      </Modal>
      <Modal show={showAirModal} title="New Air Shipment" footerComponent={null} onClose={() => setShowAirModal(false)}>
        <AirShipmentForm onCancel={() => setShowAirModal(false)} />
      </Modal>
      <Toast
        show={systemVersionOutOfDate}
        variant="neutral"
        anchor="top-right"
        title="A New Version of Shipwell is Available!"
        delay={null}
        callToAction={<Link to="/dashboard">Refresh</Link>}
      >
        Refresh your session to get the latest.
      </Toast>
    </header>
  );
};

export default connect((state: State) => ({
  userProfile: state.userProfile.user,
  company: state.company.company,
  isShipwell: state.company.company.is_shipwell,
  brokerLogos: state.brokers?.['brokerLogos'],
  systemVersionOutOfDate: state.auth.systemVersionOutOfDate,
  hasCustomerViewPermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('customer_relationships.view'),
  hasShipmentUpdatePermission:
    state.userProfile.user.permissions &&
    (state.userProfile.user.permissions.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
      state.userProfile.user.permissions.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)),
  hasCompanyUserViewPermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('users.view_company_users'),
  hasCompanyDetailsViewPermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('company.view_company_details'),
  hasCarrierViewPermission:
    state.userProfile.user.permissions &&
    state.userProfile.user.permissions.includes('carrier_relationships.view_carrier_relationships'),
  hasQuotesUpdatePermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('quotes.update_quotes'),
  hasDispatchViewPermission:
    state.userProfile.user.permissions &&
    state.userProfile.user.permissions.includes('carrier.view_driver_relationships'),
  hasAnalyticsViewPermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('analytics.view'),
  hasOrdersViewPermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('orders.view_orders'),
  hasContractsViewPermission:
    state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.view_contracts'),
  isPurchaseOrdersEnabled:
    state.company.company.feature_flags && state.company.company.feature_flags.purchase_orders_enabled,
  isAnalyticsEnabled: state.company.company.feature_flags && state.company.company.feature_flags.analytics_enabled,
  isLoadBoardEnabled: state.company.company.feature_flags && state.company.company.feature_flags.show_load_board,
  isAutomationEnabled:
    state.company.company.feature_flags && state.company.company.feature_flags.freightwaves_driver_texts_enabled,
  isContractsEnabled: state.company.company.feature_flags && state.company.company.feature_flags.contracts_enabled
}))(MainHeader);
