import {AppointmentStatusEnum, DateTimeWithTimezone} from '@shipwell/tempus-sdk';
import {formatDateRange} from 'App/containers/appointments/utils';

interface AppointmentDateInfoProps {
  start: DateTimeWithTimezone;
  end: DateTimeWithTimezone;
  status: AppointmentStatusEnum;
}

export const AppointmentDateInfo = ({start, end, status}: AppointmentDateInfoProps) => {
  const dateInfo = formatDateRange(start, end);
  const isUnscheduled = status === AppointmentStatusEnum.Unscheduled;

  return (
    <div className="flex flex-col gap-1">
      <span className="font-bold text-[12px]">{dateInfo.date}</span>
      <span className="font-normal text-[12px]">{isUnscheduled ? 'Unscheduled' : dateInfo.time}</span>
    </div>
  );
};
