import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AttachedDevice} from '@shipwell/locus-sdk';
import {Button, Select} from '@shipwell/shipwell-ui';
import {useEffect, useMemo, useState} from 'react';
import {useGetAttachedDevices} from './useGetAttachedDevices';
import {SensorDataTableModal} from '../../SensorDataTable/SensorDataTableModal';
import Loader from 'App/common/shipwellLoader';

export const Tracking = ({shipment}: {shipment: Shipment}) => {
  const [device, setDevice] = useState<string>();
  const [showModal, setShowModal] = useState(false);

  const {attachedDevicesData, attachedDevicesStatus, getAttachedDevicesQuery} = useGetAttachedDevices(shipment.id);
  const devicesList = attachedDevicesData?.map((device: AttachedDevice) => {
    const deviceName = device.device_name || device.device_id;
    return {
      value: device.device_id,
      label: `${device.provider.toUpperCase()} - ${deviceName}`
    };
  });

  const deviceData = useMemo(() => {
    if (!device) {
      return null;
    }

    return attachedDevicesData?.find((deviceData) => deviceData.device_id === device);
  }, [attachedDevicesData, device]);

  useEffect(() => {
    getAttachedDevicesQuery.refetch();
  }, [getAttachedDevicesQuery, getAttachedDevicesQuery.refetch, shipment.id]);

  if (attachedDevicesStatus === 'loading') {
    return <Loader loading />;
  }

  return (
    <div className="flex flex-col p-4 gap-8">
      <Select
        simpleValue
        name="devices"
        label="Devices"
        onChange={(value: string) => setDevice(value)}
        value={device}
        options={devicesList}
      />
      <Button variant="tertiary" iconName="ViewTable" disabled={!device} onClick={() => setShowModal(true)}>
        View all Data
      </Button>

      {deviceData && (
        <SensorDataTableModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          deviceName={deviceData?.device_name || ''}
          deviceId={deviceData?.device_id || ''}
          provider={deviceData?.provider || ''}
          resourceId={shipment.id}
        />
      )}
    </div>
  );
};
