import {
  DashboardColumnSort,
  DashboardColumnSortDirection,
  DashboardColumnsSchema
} from 'App/components/dashboards/commons/dashboardColumnSchema';
import {HeaderRowCell} from './headerRowCell/headerRowCell';
import {useOptimisticDebounce} from '../../useOptimisticDeboucer/useOptimisticDebouncer';
import {remoteLoading} from '../../useRemoteLoading/remoteLoading';

export interface TableHeaderProps {
  fullWidth?: boolean;
  columnsSchema: DashboardColumnsSchema;
  sortBy?: DashboardColumnSort;
  onSortBy: (sort?: DashboardColumnSort) => void;
}

export function TableHeader(props: TableHeaderProps) {
  const sortDebounce = useOptimisticDebounce<DashboardColumnSort | undefined>({
    value: props.sortBy,
    onSubmit: props.onSortBy,
    onBeforeDebounce: () => {
      remoteLoading(true);
    },
    onAfterDebounce: () => {
      remoteLoading(false);
    }
  });

  const currentDirection = sortDebounce.optimisticValue?.direction;
  const hasDirection = !!currentDirection;

  function handleSortDirectionChange(columnKey: string, newDirection: DashboardColumnSortDirection) {
    if (newDirection === DashboardColumnSortDirection.NONE) {
      sortDebounce.submit(undefined);
    } else {
      sortDebounce.submit({
        key: columnKey,
        direction: newDirection
      });
    }
  }

  return (
    <thead>
      <tr>
        {props.columnsSchema?.map((column, idx) => {
          let direction: DashboardColumnSortDirection | undefined = undefined;
          const isSortable = !!column.sort;

          if (isSortable) {
            const sameKey = column.sort?.key === sortDebounce.optimisticValue?.key;
            direction = sameKey && hasDirection ? currentDirection : undefined;
          }

          return (
            <HeaderRowCell
              key={idx}
              fullWidth={props.fullWidth}
              column={column}
              sortDirection={direction}
              onSortDirectionChange={(direction) => handleSortDirectionChange(column.sort?.key || '', direction)}
            />
          );
        })}
      </tr>
    </thead>
  );
}
