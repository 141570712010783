import {
  Configuration,
  RateTablesApi,
  RateTablesApiQuotingRateTablesFuelSurchargeTablesGetRequest,
  FuelSurchargeTable,
  AccessorialChargeTable,
  PaginatedFuelSurchargeTable,
  RateTablesApiQuotingRateTablesAccessorialChargeTablesGetRequest,
  RateTablesApiQuotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdGetRequest,
  RateTablesApiQuotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdCalculateAccessorialRatesPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createRateTablesApi() {
  return new RateTablesApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

export type CorrectedFuelSurchargeTable = FuelSurchargeTable & {id: string};
type CorrectedPaginatedFuelSurchargeTable = Omit<PaginatedFuelSurchargeTable, 'results'> & {
  results?: CorrectedFuelSurchargeTable[];
};

export const getFuelSurchargeTables = async ({
  page = 1,
  pageSize = 50,
  ordering,
  q
}: RateTablesApiQuotingRateTablesFuelSurchargeTablesGetRequest) => {
  const axiosResponse = await createRateTablesApi().quotingRateTablesFuelSurchargeTablesGet({
    page,
    pageSize,
    ordering,
    q
  });
  return axiosResponse.data as CorrectedPaginatedFuelSurchargeTable;
};

export const deleteFuelSurchargeTable = (fuelSurchargeTableId: string) => {
  return createRateTablesApi().quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdDelete({fuelSurchargeTableId});
};

export const getFuelSurchargeTable = async (fuelSurchargeTableId: string) => {
  const axiosResponse = await createRateTablesApi().quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdGet({
    fuelSurchargeTableId
  });
  return axiosResponse.data as CorrectedFuelSurchargeTable;
};

export type CorrectedAccessorialChargeTable = AccessorialChargeTable & {id: string};

type CorrectedPaginatedAccessorialChargeTable = Omit<PaginatedFuelSurchargeTable, 'results'> & {
  results: CorrectedAccessorialChargeTable[];
};

export const getAccessorialChargeTables = async (
  requestParameters: RateTablesApiQuotingRateTablesAccessorialChargeTablesGetRequest
) => {
  const axiosResponse = await createRateTablesApi().quotingRateTablesAccessorialChargeTablesGet(requestParameters);
  return axiosResponse.data as CorrectedPaginatedAccessorialChargeTable;
};

export const getAccessorialChargeTable = async (
  requestParameters: RateTablesApiQuotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdGetRequest
) => {
  const axiosResponse = await createRateTablesApi().quotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdGet(
    requestParameters
  );
  return axiosResponse.data;
};

export const calculateAccessorialRateTable = async (
  requestParameters: RateTablesApiQuotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdCalculateAccessorialRatesPostRequest
) => {
  const axiosResponse =
    await createRateTablesApi().quotingRateTablesAccessorialChargeTablesAccessorialChargeTableIdCalculateAccessorialRatesPost(
      requestParameters
    );
  return axiosResponse.data;
};
