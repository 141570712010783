import {useQuery} from '@tanstack/react-query';
import {getBreadcrumbs} from 'App/api/shipment/typed';

const queryQuey = 'getBreadcrumbsByShipmentIdForLogsTable';

export const useViewShipmentMovementLog = (shipmentId: string) => {
  const breadcrumbsQuery = useQuery({
    queryKey: [queryQuey],
    queryFn: async () => {
      const breadcrumbs = await getBreadcrumbs({
        shipmentId
      });

      return breadcrumbs?.data?.shipment_locations || [];
    }
  });

  return breadcrumbsQuery;
};
