import {AttachedDevice, TrackingApiGetAttachedDevicesRequest} from '@shipwell/locus-sdk';
import {useQuery} from '@tanstack/react-query';
import {getAttachedDevices} from 'App/api/locus/tracking';

export const useGetAttachedDevices = (shipmentId: string) => {
  const params: TrackingApiGetAttachedDevicesRequest = {
    resourceId: shipmentId,
    resourceType: 'SHIPMENT'
  };

  const GET_ATTACHED_DEVICES_QUERY = 'getAttachedDevices';
  const getAttachedDevicesQuery = useQuery(
    [GET_ATTACHED_DEVICES_QUERY, params],
    async () => {
      const response = await getAttachedDevices(params);
      return (response.data as unknown as AttachedDevice[]) || [];
    },
    {
      enabled: Boolean(shipmentId)
    }
  );

  const {data: attachedDevicesData, isFetching: isFetchingAttachedDevicesData} = getAttachedDevicesQuery;
  const filteredAttachedDevices =
    attachedDevicesData?.filter((device) => device.connection_status === 'CONNECTED') || [];

  return {
    attachedDevicesData: filteredAttachedDevices,
    isFetchingAttachedDevicesData,
    getAttachedDevicesQuery
  };
};
