import {SvgIcon} from '@shipwell/shipwell-ui';
import {PAGE_SIZES, PageSizesValue} from 'App/components/dashboards/commons/constants';

export interface PageSizeSelectProps {
  value?: PageSizesValue;
  onChange: (value: PageSizesValue) => void;
}

export function PageSizeSelect(props: PageSizeSelectProps) {
  return (
    <label className="m-0 font-normal" htmlFor="pageSizeSelect">
      <div className="flex items-center gap-2">
        <span>Rows Per Page:</span>
        {!props.value && '--'}
        {props.value && (
          <div className="relative flex h-8 items-center">
            <select
              aria-label="page sizes"
              value={props.value || ''}
              onChange={(e) => props.onChange(parseInt(e.target.value) as PageSizesValue)}
              className="appearance-none bg-sw-background p-2 pr-6 text-sw-primary"
            >
              {PAGE_SIZES.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <SvgIcon
              className="pointer-events-none absolute inset-y-0 right-0 h-full text-sw-primary"
              name="CarrotDown"
            />
          </div>
        )}
      </div>
    </label>
  );
}
