import first from 'lodash/first';
import {Field} from 'formik';
import {FormikSelect, Title, SvgIcon} from '@shipwell/shipwell-ui';
import {ContainerLegItem, DrayageLegOverallStatus, OrderStatus} from '@shipwell/corrogo-sdk';
import {isContainerLegItem} from 'App/containers/shipments/utils/typed';
import {useShipmentLegs, useTransportationOrders} from 'App/data-hooks';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';

export function useContainerOptions(shipmentId: string) {
  const shipmentLegsQuery = useShipmentLegs(shipmentId, {
    staleTime: HOT_DATA_STALE_TIME
  });

  const {getTransportationOrdersOfStatus} = useTransportationOrders(shipmentId);

  const acceptedTransportationOrders = getTransportationOrdersOfStatus(OrderStatus.Accepted);
  const acceptedTransportationOrderLegIds = acceptedTransportationOrders?.reduce<string[]>(
    (legList, currentTransportationOrder) => [...legList, ...(currentTransportationOrder.leg_ids || [])],
    []
  );

  const containerOptions = shipmentLegsQuery.data
    ?.filter((leg) => leg.overall_status !== DrayageLegOverallStatus.Cancelled)
    .map((shipmentLeg, index) => {
      const container = first(shipmentLeg.leg_items);
      if (!isContainerLegItem(container)) {
        return;
      }
      const legItemContainer = container as ContainerLegItem;
      const id = legItemContainer.identification_number;
      const seal = first(legItemContainer.seals || [])?.value;

      return {
        label: `Container #${id ? id : index + 1}${seal ? ` - Seal #${seal}` : ''}`,
        value: shipmentLeg.id
      };
    })
    .filter((legOption) => !!legOption);

  const tenderableContainerOptions = containerOptions?.filter(
    (containerOption) => containerOption && !acceptedTransportationOrderLegIds?.includes(containerOption.value)
  );

  return {containerOptions, tenderableContainerOptions, queryInfo: shipmentLegsQuery};
}

const ContainerSelect = ({shipmentId}: {shipmentId: string}) => {
  const {tenderableContainerOptions, queryInfo} = useContainerOptions(shipmentId);

  if (queryInfo.isInitialLoading) {
    return (
      <div className="m-2">
        <SvgIcon name="LoadingDots" />
      </div>
    );
  }
  return (
    <>
      <Title variant="sectionTitle">Containers</Title>
      <Field
        name="containers"
        label="Containers"
        component={FormikSelect}
        required
        isMulti
        options={tenderableContainerOptions}
      />
    </>
  );
};

export default ContainerSelect;
