import moment from 'moment';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import {SignalTriggerTriggerTypeEnum, NoneTriggerTriggerTypeEnum} from '@shipwell/opus-sdk';
import store from 'App/routes/store';
import getTimeOptions from 'App/utils/getTimeOptions';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {getContract} from 'App/api/contracts';
import {
  WORKFLOW_ACTION_OPTIONS,
  WORKFLOW_OPTIONS_BY_POLICY_TYPE,
  POLICY_TYPE_ROUTING_GUIDE,
  POLICY_TYPE_ORDER_CONSOLIDATION,
  SIGNAL_TYPE_SHIPMENT_CREATED,
  SIGNAL_TYPE_PURCHASE_ORDER_CREATED,
  JUNCTION_TYPES,
  WORKFLOW_ACTION_TENDER,
  WORKFLOW_ACTION_POST_TO_LOADBOARD,
  WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS,
  WORKFLOW_ACTION_SEND_EMAIL,
  WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER,
  WORKFLOW_ACTION_QUOTE_AND_TENDER_LTL
} from 'App/containers/workflows/workflowConstants';
import {findAddressValueFromOption} from 'App/utils/globalsTyped';

export function getStopState(stop) {
  return get(stop, 'location.address.state_province');
}

export function getStopCity(stop) {
  return get(stop, 'location.address.city');
}

export function formatAddresses(addresses) {
  return (addresses || []).map((address) => {
    if (address.type === 'country') {
      return findAddressValueFromOption(address.value, ['country']);
    }
    if (address.type === 'state_province') {
      return findAddressValueFromOption(address.value, ['country', 'state_province']);
    }
    return get(address, 'value.address') || get(address, 'value') || address;
  });
}

export async function getCarrierByCompanyId(companyId, opts) {
  try {
    const vendor = await getCarrierRelationshipsPromise({vendorId: companyId, ...opts});
    if (vendor?.body?.results?.length > 0) {
      return vendor.body.results[0];
    }
    return null;
  } catch (error) {
    console.error(error);
  }
}

export function parameterizeValues(values) {
  return map(values, (value, name) => ({value, name}));
}

export function createActionFromParams(params) {
  return params.reduce((acc, curr) => {
    const newObj = {};
    newObj[curr.name] = curr.value;
    return {...acc, ...newObj};
  }, {});
}

export function getTimerTrigger({action, stepNumber, nextActionType, nextStepNumber}) {
  return [
    {
      trigger: {
        trigger_type: WORKFLOW_ACTION_OPTIONS[action?.type]?.triggerType,
        ...(WORKFLOW_ACTION_OPTIONS[action?.type]?.triggerExpirationField && {
          start_after_seconds: action[`${WORKFLOW_ACTION_OPTIONS[action?.type]?.triggerExpirationField}`]
        })
      },
      next_step_id:
        action?.type === WORKFLOW_ACTION_TENDER
          ? `JUNCTION_POST_TENDER_${stepNumber}`
          : nextActionType === WORKFLOW_ACTION_TENDER
          ? `JUNCTION_PRE_TENDER_${nextStepNumber}`
          : nextStepNumber
          ? `STEP_${nextStepNumber}`
          : 'STEP_END'
    }
  ];
}

export async function getWorkflowFormValues({workflow, policyType, skippedStepExplanations = []}) {
  const values = {
    workflow_id: workflow.id,
    name: workflow.name,
    actions: workflow.actions.map((action) => {
      const params = createActionFromParams(action.params);
      return {
        ...params,
        type: action.action_id,
        STEP_ID: action.step_id,
        attached_triggers: action.attached_triggers
      };
    }),
    ...(policyType === POLICY_TYPE_ROUTING_GUIDE && {
      shipment_created:
        workflow.automated_execution_enabled &&
        workflow.start_events[0].trigger.trigger_type === SignalTriggerTriggerTypeEnum.Signal &&
        workflow.start_events[0].trigger.signal_type === SIGNAL_TYPE_SHIPMENT_CREATED
    }),
    ...(policyType === POLICY_TYPE_ORDER_CONSOLIDATION && {
      order_created:
        workflow.automated_execution_enabled &&
        workflow.start_events[0].trigger.trigger_type === SignalTriggerTriggerTypeEnum.Signal &&
        workflow.start_events[0].trigger.signal_type === SIGNAL_TYPE_PURCHASE_ORDER_CREATED
    })
  };

  const actionErrors = keyBy(workflow.data_integrity_errors, (error) =>
    parseInt(error?.source?.pointer?.split('/')?.[2])
  );

  for (let i = 0; i < values.actions.length; i++) {
    values.actions[i].type = WORKFLOW_ACTION_OPTIONS[values.actions[i].type];
    // Set up timer trigger options
    if (values.actions[i].attached_triggers.find((e) => e.trigger.trigger_type === 'TIMER')) {
      if (values.actions[i].type.value === WORKFLOW_ACTION_TENDER) {
        if (getTimeOptions().find((e) => e.value === values.actions[i].expires_after_seconds)) {
          values.actions[i].expires_after_seconds = getTimeOptions().find(
            (e) => e.value === values.actions[i].expires_after_seconds
          );
        } else {
          values.actions[i].expires_after_seconds = {
            label: values.actions[i].expires_after_seconds + ' seconds',
            value: values.actions[i].expires_after_seconds
          };
        }
      } else if (
        [WORKFLOW_ACTION_POST_TO_LOADBOARD, WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS].includes(
          values.actions[i].type.value
        )
      ) {
        values.actions[i].step_timer = getTimeOptions().find(
          (e) =>
            e.value ===
            values.actions[i].attached_triggers.find((e) => e.trigger.trigger_type === 'TIMER').trigger
              .start_after_seconds
        );
      }
    }

    // TENDER ACTION
    if (values.actions[i].type.value === WORKFLOW_ACTION_TENDER) {
      values.actions[i].mode =
        store.getState().shipments.shipmentModes.find((e) => e.code === values.actions[i].mode) ?? null;
      values.actions[i].equipment_type =
        store
          .getState()
          .shipments.equipmentTypes.find((e) => e.machine_readable === values.actions[i].equipment_type) ?? null;
      const vendor = await getCarrierByCompanyId(values.actions[i].tender_to_company, {
        carrierStatus: ['ACTIVE', 'INACTIVE', 'DO_NOT_USE']
      });
      if (vendor) {
        values.actions[i].tender_to_company = vendor;
        values.actions[i].involved_tender_to_company_users =
          values.actions[i].involved_tender_to_company_users &&
          values.actions[i].involved_tender_to_company_users.map((user) => {
            let match = vendor.point_of_contacts.find((e) => e.user === user);
            if (!match) {
              match = {first_name: 'Unknown User', email: 'Unknown Email'};
            }
            const label = `${vendor.shipwell_vendor.name} - ${match.first_name}${
              match.last_name ? ` ${match.last_name}` : ''
            } (${match.email})`;
            return {
              id: match.user,
              label: label,
              carrier: vendor.shipwell_vendor.id,
              carrierName: vendor.shipwell_vendor.name
            };
          });
      }
      if (values.actions[i]?.contract_id) {
        try {
          const contractResponse = await getContract(values.actions[i].contract_id);
          values.actions[i].contract_id = {
            name: contractResponse?.body?.name ?? null,
            value: contractResponse?.body?.id ?? null
          };
        } catch (error) {
          console.error(error);
        }
      }

      // Check for skipped step explanation
      values.actions[i]['skippedStepExplanation'] =
        skippedStepExplanations.find((skippedStep) => skippedStep.skipped_step_id === values.actions[i].STEP_ID)
          ?.detail_message ?? null;
    }

    // CREATE SPOT NEGOTIATIONS ACTION
    if (values.actions[i].type.value === WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS) {
      const pocArray = [];
      for (let j = 0; j < values.actions[i].carriers.length; j++) {
        const carrierDetails = await getCarrierByCompanyId(values.actions[i].carriers[j].company_id, {
          carrierStatus: ['ACTIVE', 'INACTIVE', 'DO_NOT_USE']
        });
        if (carrierDetails) {
          values.actions[i].carriers[j].involved_carrier_users?.map((user) => {
            const matchingPOC = carrierDetails.point_of_contacts.find((e) => user.includes(e.user));
            if (matchingPOC) {
              const label = `${carrierDetails.shipwell_vendor.name} - ${matchingPOC.first_name} ${
                matchingPOC.last_name || ''
              } (${matchingPOC.email})`;
              pocArray.push({
                id: matchingPOC.user,
                label: label,
                carrier: carrierDetails.shipwell_vendor.id,
                carrierName: carrierDetails.shipwell_vendor.name
              });
            }
          });
        }
      }
      values.actions[i].carriers = pocArray;
    }

    // SEND EMAIL ACTION
    if (values.actions[i].type.value === WORKFLOW_ACTION_SEND_EMAIL) {
      values.actions[i].recipients = values.actions[i].recipients.map((email) => {
        return {label: email, value: email};
      });
    }

    // CREATE SHIPMENT FROM PURCHASE ORDER ACTION
    if (values.actions[i].type.value === WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER) {
      values.actions[i].type = {value: WORKFLOW_ACTION_CREATE_SHIPMENT_FROM_PURCHASE_ORDER, label: 'Create Shipment'};
      values.actions[i].service_level =
        store.getState().shipments.serviceLevels.find((e) => e?.code === values.actions[i].service_level)?.code ?? null;
      values.actions[i].mode =
        store.getState().shipments.shipmentModes.find((e) => e?.code === values.actions?.[i]?.mode) ?? null;
      values.actions[i].equipment_type =
        store.getState().shipments.equipmentTypes.find((e) => e.machine_readable === values.actions[i]?.equipment_type)
          ?.machine_readable ?? null;
      delete values.actions[i].mode;
    }

    values.actions[i].error = actionErrors?.[i] ?? null;
    delete values.actions[i].attached_triggers;
  }
  return values;
}

export function buildWorkflow({values, policyType, workflowConditional, preferredCurrency = 'USD'}) {
  const workflow = {
    name: values.name,
    is_active: values.status === 'ACTIVE',
    start_events: [
      {
        step_id: 'STEP_START',
        trigger: {trigger_type: NoneTriggerTriggerTypeEnum.None},
        next_step: {step_id: 'STEP_1'}
      }
    ],
    end_events: [{step_id: 'STEP_END', should_terminate: true}],
    automated_execution_starts_at:
      values.start_date && moment(values.start_date).isValid() ? moment(values.start_date).toDate() : null,
    automated_execution_ends_at:
      values.end_date && moment(values.end_date).isValid() ? moment(values.end_date).toDate() : null,
    actions: [],
    junctions: []
  };

  const {workflowConditionalField, automatedExecutionSignalType} = get(WORKFLOW_OPTIONS_BY_POLICY_TYPE, policyType);

  workflow.automated_execution_enabled = Boolean(get(values, workflowConditionalField));

  //always add start_events[0] if policyType is routing guide - CI-72
  if (workflowConditional && (get(values, workflowConditionalField) || policyType === POLICY_TYPE_ROUTING_GUIDE)) {
    workflow.start_events[0].trigger = {
      trigger_type: SignalTriggerTriggerTypeEnum.Signal,
      signal_type: automatedExecutionSignalType,
      condition: workflowConditional
    };
  }

  let actionCount = 0;
  let tenderCount = 0;

  values.actions?.forEach((action) => {
    actionCount++;
    if (action?.type?.value === WORKFLOW_ACTION_TENDER) {
      tenderCount++;
    }
  });

  if (values?.actions?.length > 0) {
    let needsJunction = false;
    let stepNumber = 1;
    values.actions?.forEach((action, index) => {
      //initial prep/cleaning of data
      action.type = action.type.value;

      const nextActionType = values.actions?.[index + 1]?.type?.value ?? null;

      if (action?.type === WORKFLOW_ACTION_TENDER) {
        action.expires_after_seconds = action.expires_after_seconds.value;
        action.mode = action.mode.code;
        action.equipment_type = action.equipment_type.machine_readable;
        action.tender_to_company = action.involved_tender_to_company_users[0].carrier;
        action.involved_tender_to_company_users = action.involved_tender_to_company_users.map((e) => e.id);
        if (!action.contract_id?.value) {
          action.rate_currency = preferredCurrency;
          action.rate = parseFloat(action.rate);
        }
        action.contract_id = action.contract_id?.value ?? null;
        delete action.contract;
        delete action.skippedStepExplanation;

        // We need a junction before any tender action
        if (stepNumber === 1) {
          workflow.start_events[0] = {
            ...workflow.start_events[0],
            next_step: {step_id: 'JUNCTION_PRE_TENDER_1'},
            trigger: {
              ...workflow.start_events[0].trigger,
              outputs: [
                {
                  name: 'shipment',
                  data_type: 'Shipment'
                },
                {
                  name: 'shipment_id',
                  data_type: 'String'
                }
              ]
            }
          };
        }
      }
      if (action?.type === WORKFLOW_ACTION_SEND_EMAIL) {
        action.recipients = action.recipients.map((e) => e.value);
      }
      if (action?.type === WORKFLOW_ACTION_POST_TO_LOADBOARD) {
        action.step_timer = action.step_timer ? action.step_timer.value : null;
        if (action.step_timer === null) {
          delete action.step_timer;
        }
        action.buy_it_now_amount = action.buy_it_now_amount ? parseFloat(action.buy_it_now_amount) : null;
        if (!action.buy_it_now_amount) {
          delete action.buy_it_now_amount;
        }
        action.buy_it_now_amount_currency = preferredCurrency;
      }
      if (action?.type === WORKFLOW_ACTION_CREATE_SPOT_NEGOTIATIONS) {
        delete action.carriers_by_tag;
        action.buy_it_now_amount = action.buy_it_now_amount ? parseFloat(action.buy_it_now_amount) : null;
        if (!action.buy_it_now_amount) {
          delete action.buy_it_now_amount;
        }
        action.buy_it_now_amount_currency = preferredCurrency;
        action.step_timer = action.step_timer ? action.step_timer.value : null;
        if (action.step_timer === null) {
          delete action.step_timer;
        }

        action.carriers = action.carriers.reduce((allCarriers, carrierPOC) => {
          if (allCarriers.find((e) => e.company_id === carrierPOC.carrier)) {
            //carrier is in the list already
            allCarriers[allCarriers.findIndex((e) => e.company_id === carrierPOC.carrier)].involved_carrier_users.push(
              carrierPOC.id
            );
          } else {
            allCarriers.push({company_id: carrierPOC.carrier, involved_carrier_users: [carrierPOC.id]});
          }
          return allCarriers;
        }, []);
      }
      delete action.error;
      const nextStepNumber = values.actions[index + 1] ? stepNumber + 1 : null;
      if (action?.type === WORKFLOW_ACTION_TENDER) {
        //we will always need a junction for tender steps, if there are subsequent steps
        needsJunction = true;
      }

      delete action.STEP_ID;
      const trigger = getTimerTrigger({action, stepNumber, nextActionType, nextStepNumber});
      delete action.step_timer;

      const workflowAction = {
        action_id: action.type,
        step_id: `STEP_${stepNumber}`,
        params: parameterizeValues(action),
        attached_triggers: trigger,
        inputs: get(WORKFLOW_ACTION_OPTIONS, `${action.type}.inputs`, [])
      };

      if (action?.type === WORKFLOW_ACTION_QUOTE_AND_TENDER_LTL || needsJunction) {
        workflowAction.outputs = get(WORKFLOW_ACTION_OPTIONS, `${action.type}.outputs`, []);
      }

      if (needsJunction) {
        workflow.junctions.push(
          {
            junction_type: JUNCTION_TYPES.CONDITIONAL_SPLIT,
            step_id: `JUNCTION_PRE_TENDER_${stepNumber}`,
            next_steps: getPreTenderNextSteps(stepNumber, nextActionType),
            default_step: nextActionType
              ? nextActionType === WORKFLOW_ACTION_TENDER
                ? `JUNCTION_PRE_TENDER_${stepNumber + 1}`
                : `STEP_${stepNumber + 1}`
              : 'JUNCTION_DEFAULT',
            inputs: [
              {
                name: 'shipment_id',
                data_type: 'String',
                is_required: true
              }
            ]
          },
          {
            junction_type: JUNCTION_TYPES.CONDITIONAL_SPLIT,
            step_id: `JUNCTION_POST_TENDER_${stepNumber}`,
            next_steps: getPostTenderNextSteps(stepNumber, nextActionType, stepNumber < actionCount),
            default_step: 'STEP_END',
            inputs: [
              {
                name: 'tender',
                data_type: 'Tender',
                is_required: true
              },
              {
                name: 'shipment_id',
                data_type: 'String',
                is_required: true
              }
            ]
          }
        );
        needsJunction = false;
      }
      workflow.actions.push(workflowAction);
      stepNumber++;
    });
  }

  // Removes the type param from each action
  workflow?.actions?.forEach((action) => {
    const newActionParams = action.params.filter((param) => param.name !== 'type');
    action.params = newActionParams;
  });

  if (tenderCount > 0) {
    workflow.junctions.push({
      junction_type: JUNCTION_TYPES.CONDITIONAL_SPLIT,
      step_id: 'JUNCTION_DEFAULT',
      next_steps: [{step_id: 'STEP_END', junction_skipped_reason_message: 'Step Skipped'}],
      default_step: 'STEP_END'
    });
  }

  return workflow;
}

function getPreTenderNextSteps(stepNumber, nextActionType) {
  return [
    {
      step_id: `STEP_${stepNumber}`,
      condition: {
        'NEXT_STEP.carrier_has_capacity(shipment_id)': {
          $eq: true
        }
      },
      junction_skipped_reason_message: 'Carrier Capacity Exceeded'
    },
    {
      step_id: nextActionType
        ? nextActionType === WORKFLOW_ACTION_TENDER
          ? `JUNCTION_PRE_TENDER_${stepNumber + 1}`
          : `STEP_${stepNumber + 1}`
        : 'JUNCTION_DEFAULT',
      junction_skipped_reason_message: 'Step Skipped'
    }
  ];
}

function getPostTenderNextSteps(stepNumber, nextActionType, hasNextStep) {
  return [
    {
      step_id: getPostTenderDefaultStep(stepNumber, nextActionType, hasNextStep),
      condition: {$not: {'tender.status': {$eq: 'accepted'}}},
      junction_skipped_reason_message: 'Tender is Accepted'
    },
    {
      step_id: 'STEP_END',
      junction_skipped_reason_message: 'Step Skipped'
    }
  ];
}

function getPostTenderDefaultStep(stepNumber, nextActionType, hasNextStep) {
  let res = 'JUNCTION_DEFAULT';
  if (nextActionType === WORKFLOW_ACTION_TENDER) {
    res = `JUNCTION_PRE_TENDER_${stepNumber + 1}`;
  }
  // If there is another step, go to that step
  else if (hasNextStep) {
    res = `STEP_${stepNumber + 1}`;
  } else {
    res = 'JUNCTION_DEFAULT';
  }

  return res;
}
