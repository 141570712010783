import * as React from 'react';

export interface ReRenderCounterProps {
  name: string;
}

export function ReRenderCounter(props: ReRenderCounterProps) {
  const counterRef = React.useRef(0);
  counterRef.current = counterRef.current + 1;

  return (
    <div className={'inline-block border-2 bg-sw-tooltip text-sw-text-reverse'}>
      <div className="flex gap-1 items-center p-1">
        <div>{counterRef.current}</div>
        <div className="text-xxs">{props.name}</div>
      </div>
    </div>
  );
}
