import {useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Address} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getDistanceBetweenStops} from 'App/api/addressBook/typed';
import {DISTANCE_KEY} from 'App/data-hooks/queryKeys';
import {convertMilesToKilometers} from 'App/utils/internationalConstants';
import pick from 'lodash/pick';

type UseTotalDistanceParams = {
  stops: ({address?: Partial<Address> | null} | null | undefined)[];
  enabled?: boolean;
};

const isValidAddressArray = (
  stops: (
    | {
        address?: Partial<Address> | null;
      }
    | null
    | undefined
  )[]
): stops is {address: Address}[] => stops.every((stop) => !!stop?.address?.country);

export const useTotalDistance = ({stops = [], enabled = true}: UseTotalDistanceParams) => {
  const memoizedStops = useMemo(
    () =>
      stops.map((stop) => ({
        address: pick(stop?.address, [
          'address_1',
          'city',
          'country',
          'latitude',
          'longitude',
          'postal_code',
          'state_province'
        ])
      })),
    [stops]
  );

  const calculateTotalDistance = async () => {
    if (isValidAddressArray(memoizedStops)) {
      return await Promise.all(
        memoizedStops.slice(0, -1).map((stop, index) =>
          getDistanceBetweenStops({
            origin: stop.address,
            destination: memoizedStops[index + 1]?.address
          })
        )
      );
    }
  };

  const {data, isFetching, isLoading} = useQuery([DISTANCE_KEY, memoizedStops], calculateTotalDistance, {
    refetchOnWindowFocus: false,
    enabled: memoizedStops.every((stop) => !!stop?.address?.country) && enabled,
    placeholderData: []
  });

  const totalDistance = (!isLoading && data?.reduce((sum, {distance_miles}) => sum + distance_miles, 0)) || 0;
  const totalDistanceKM = convertMilesToKilometers(totalDistance, 3, false) as number;

  return {totalDistance, totalDistanceKM, isFetchingDistance: isFetching, isLoadingDistance: isLoading};
};
