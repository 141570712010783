import {
  Configuration,
  PaginatedShipperRelationship,
  ShipperRelationshipApi,
  ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsGetRequest,
  ShipperCarrierRelationshipsApi,
  ShipperCarrierRelationshipsApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersGetRequest,
  CarrierSpecificShipperRelationship,
  ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdGetRequest,
  ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdPutRequest,
  ShipperCarrierRelationshipsApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersPutRequest,
  ShipperRelationship,
  ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const shipperRelationshipApi = new ShipperRelationshipApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const shipperCarrierRelationshipApi = new ShipperCarrierRelationshipsApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export async function getBrokerShipperRelationship(
  request: ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsGetRequest
): Promise<PaginatedShipperRelationship> {
  const {data} = await shipperRelationshipApi.brokeragesBrokerageIdShipperRelationshipsGet({
    // setup here for backwards compatibility. If these values exist on the request object they will be overwritten with the props spread
    page: 1,
    pageSize: 20,
    ...request
  });

  return data;
}

export async function brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdGet(
  params: ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdGetRequest
) {
  return await shipperRelationshipApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdGet(params);
}

export async function getBrokerShipperRelationshipCarriers(
  request: ShipperCarrierRelationshipsApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersGetRequest
): Promise<CarrierSpecificShipperRelationship[]> {
  const {data} =
    await shipperCarrierRelationshipApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersGet(
      request
    );

  return data;
}

export type CorrectedUpdateCustomerParams = Omit<
  ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdPutRequest,
  'shipperRelationship'
> & {
  shipperRelationship: Omit<ShipperRelationship, 'company'>;
};
export const updateCustomer = async (requestParameters: CorrectedUpdateCustomerParams) => {
  const response = await shipperRelationshipApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdPut(
    requestParameters as ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdPutRequest
  );
  return response.data;
};

export const updateCustomerCarriers = async (
  requestParameters: ShipperCarrierRelationshipsApiBrokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersPutRequest
) => {
  const response =
    await shipperCarrierRelationshipApi.brokeragesBrokerageIdShipperRelationshipsShipperRelationshipIdCarriersPut(
      requestParameters
    );
  return response.data;
};

export const createCustomer = async (
  requestParameters: ShipperRelationshipApiBrokeragesBrokerageIdShipperRelationshipsPostRequest
) => {
  const response = await shipperRelationshipApi.brokeragesBrokerageIdShipperRelationshipsPost(requestParameters);
  return response.data;
};
