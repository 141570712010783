import {PointOfContactNotificationPreferences} from '@shipwell/backend-core-singlerequestparam-sdk';

export const defaultPreferencesFields: PointOfContactNotificationPreferences & {select_all?: boolean} = {
  cancellation: false,
  delayed: false,
  delivered: false,
  eta_changed: false,
  picked_up: false,
  receive_bol_on_shipment_booked: false,
  shipment_booked: false,
  trailer_assigned: false,
  // additional field used by frontend
  select_all: false
};
