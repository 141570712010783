import {ReactNode} from 'react';
import {Provider} from '@rollbar/react';
import {useUserMe} from 'App/data-hooks';
import {
  getRollbarEnvConfig,
  isRollbarEnabledForEnvironment,
  isRollbarEnabledForCurrentHostname,
  getRollbarConfig
} from './helpers';

export interface RollbarProviderProps {
  children: ReactNode;
}

export function RollbarProvider(props: RollbarProviderProps) {
  const user = useUserMe();
  const rollbarEnvConfig = getRollbarEnvConfig();

  if (!rollbarEnvConfig) {
    return props.children;
  }

  if (!isRollbarEnabledForEnvironment(rollbarEnvConfig)) {
    return props.children;
  }

  if (!isRollbarEnabledForCurrentHostname()) {
    return props.children;
  }

  const rollbarConfig = getRollbarConfig(rollbarEnvConfig, user.data?.user);

  return <Provider config={rollbarConfig}>{props.children}</Provider>;
}
