import {User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {RollbarEnvConfig} from './rollBarEnvConfig';
import {Configuration} from 'rollbar';

export function getRollbarEnvConfig() {
  const rollbarEnv = process.env.ROLLBAR_ENV;
  const rollbarAccessToken = process.env.ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN;

  if (!(rollbarEnv && rollbarAccessToken)) {
    return null;
  }

  const config: RollbarEnvConfig = {
    accessToken: rollbarAccessToken,
    environment: rollbarEnv
  };

  return config;
}

export function isRollbarEnabledForEnvironment(rollbarEnvConfig: RollbarEnvConfig) {
  // https://rollbar.com/shipwell/frontend-web-app/settings/environments/
  const validEnvironments = ['dev', 'production'];
  const isValidEnvironment = validEnvironments.some((env) => env === rollbarEnvConfig.environment);
  return isValidEnvironment;
}

export function isRollbarEnabledForCurrentHostname() {
  const hostname = window.location.hostname;
  const isFeatureBranch = hostname.includes('frontend-web.shipwell-dev');
  return isFeatureBranch ? false : true;
}

export function getRollbarConfig(rollbarEnvConfig: RollbarEnvConfig, user: User | undefined) {
  const userName = `${user?.first_name || ''} ${user?.last_name || ''}`;

  const config: Configuration = {
    accessToken: rollbarEnvConfig.accessToken,
    environment: rollbarEnvConfig.environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: process.env.VERSION
        }
      },
      person: {
        id: user?.id || '',
        username: userName,
        email: user?.email || ''
      }
    }
  };

  return config;
}
