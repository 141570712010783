import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  DrayageBooking,
  CarrierAssignmentStatus,
  DrayageLegOverallStatus,
  UpdateDrayageBookingSummary,
  DrayageBookingLegItemsInner
} from '@shipwell/corrogo-sdk';
import {updateLegItem, updateDrayageLegStopsDetail, updateDrayageLegSummary} from 'App/api/corrogo';
import {useOptimisticUpdate} from 'App/utils/queryHelpers';
import {DRAYAGE_QUERY_KEY} from 'App/data-hooks/queryKeys';

type UpdateLegItemVariables = {
  legId: string;
  legItemId: string;
  legItemData: DrayageBookingLegItemsInner;
};

const defaultLeg: DrayageBooking = {
  created_at: '',
  updated_at: '',
  id: '',
  stops: [],
  shipment_ids: [],
  reference_id: '',
  carrier_assignment_status: CarrierAssignmentStatus.NotAssigned,
  overall_status: DrayageLegOverallStatus.Draft
};

export default function useCreateOrUpdateLeg(legId: string, mutationOptions = {}) {
  const drayageLegQueryKey = [DRAYAGE_QUERY_KEY, legId];

  const updateDrayageLegStopsDetailMutation = useMutation<
    Awaited<ReturnType<typeof updateDrayageLegStopsDetail>>,
    AxiosError,
    {legId: string; sequenceId: number; data: unknown}
  >(({legId, sequenceId, data}) => updateDrayageLegStopsDetail(legId, sequenceId, data), mutationOptions);

  const createUpdateLegItemOptimisticUpdateHandlers = useOptimisticUpdate<
    DrayageBooking,
    Error,
    UpdateLegItemVariables
  >();
  const updateLegItemMutation = useMutation<
    Awaited<ReturnType<typeof updateLegItem>>,
    AxiosError,
    UpdateLegItemVariables
  >(({legId, legItemId, legItemData}) => updateLegItem(legId, legItemId, legItemData), {
    ...createUpdateLegItemOptimisticUpdateHandlers(drayageLegQueryKey, {
      errorHandler: (error) => {
        console.error(`Error updating leg item`, error.message);
      },
      mergeData: (leg?, variables?) => {
        if (!leg || !variables) {
          return;
        }
        const {legItemData} = variables;

        return {
          ...defaultLeg,
          ...leg,
          leg_items: [legItemData]
        };
      }
    }),
    ...mutationOptions
  });

  const createUpdateLegSummaryOptimisticUpdateHandlers = useOptimisticUpdate<
    DrayageBooking,
    unknown,
    {legId: string; data: UpdateDrayageBookingSummary}
  >();

  const updateLegSummaryMutation = useMutation<
    Awaited<ReturnType<typeof updateDrayageLegSummary>>,
    unknown,
    {legId: string; data: UpdateDrayageBookingSummary}
  >(({legId, data}) => updateDrayageLegSummary(legId, data), {
    ...createUpdateLegSummaryOptimisticUpdateHandlers([DRAYAGE_QUERY_KEY, legId]),
    ...mutationOptions
  });

  return {
    isLoading: updateLegItemMutation.isLoading || updateDrayageLegStopsDetailMutation.isLoading,
    updateLegItemMutation,
    updateDrayageLegStopsDetailMutation,
    updateLegSummaryMutation
  };
}
