import {TrackingApiGetDeviceHistoryRequest} from '@shipwell/locus-sdk';
import {useQuery} from '@tanstack/react-query';
import {getDeviceHistory} from 'App/api/locus/tracking';
import {TiveCredentials} from '@shipwell/locus-sdk';
import {trackingProviderCredentialsList} from 'App/api/locus/trackingCredentials';

export const useGetDeviceHistory = (params: Omit<TrackingApiGetDeviceHistoryRequest, 'accountId' | 'resourceType'>) => {
  const GET_DEVICE_HSITORY_QUERY = 'getDeviceHistory';
  const deviceHistoryQuery = useQuery(
    [GET_DEVICE_HSITORY_QUERY, params],
    async () => {
      const credentialsResponse = await trackingProviderCredentialsList({
        provider: 'tive'
      });

      const credentials = credentialsResponse.data.find((item) => item.provider === 'tive') as TiveCredentials;
      const defaultAccount = credentials?.accounts?.at(0);

      const response = await getDeviceHistory({
        ...params,
        accountId: `${defaultAccount?.id}` || '',
        resourceType: 'SHIPMENT',
        limit: '999999999'
      });
      return response;
    },
    {
      enabled: Boolean(params.deviceId)
    }
  );

  const {data: deviceHistoryData, status: deviceHistoryStatus} = deviceHistoryQuery;

  return {
    deviceHistoryData,
    deviceHistoryStatus,
    deviceHistoryQuery
  };
};
