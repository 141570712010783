import {connect} from 'react-redux';
import {SidebarContent} from '@shipwell/shipwell-ui';
import {ErrorBoundary} from 'App/common/ErrorBoundary/ErrorBoundary';
import Notes from 'App/components/shipmentNotes';

const NotesController = ({shipment, user}) => {
  return (
    <ErrorBoundary fallback={() => 'There was an error displaying the notes.'}>
      <Notes
        className="shipment__collaboration-messages"
        shipment={shipment}
        user={user}
        shouldPoll
        fixedWidthContainer
      />
    </ErrorBoundary>
  );
};

const ConnectedNotesController = connect(
  (state) => ({
    shipment: state.shipmentdetails.one,
    user: state.userProfile.user
  }),
  {}
)(NotesController);

const SidebarNotes = (props) => (
  <SidebarContent className="shipment__action-container" title="Notes" {...props}>
    <ConnectedNotesController {...props} />
  </SidebarContent>
);

SidebarNotes.tabsRole = 'TabPanel';

export default SidebarNotes;
