import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import * as actions from '../../actions/shipments';
import * as marketplaceActions from '../../actions/marketplace';
import './header-style.scss';
import {transformShipmentToForm, renderLineItemString} from 'App/utils/globals';

export class ShipmentQuotingHeader extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);

    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleExecutionRedirect = this.handleExecutionRedirect.bind(this);

    this.state = {tab: 'real'};
  }

  componentDidMount() {
    if (window.location.href.endsWith('quotes')) {
      this.setState({tab: 'real'});
    } else if (window.location.href.endsWith('my-marketplace')) {
      this.setState({tab: 'my-marketplace'});
    } else if (window.location.href.endsWith('manual-rate')) {
      this.setState({tab: 'manual'});
    }
    if (this.props.defaultQuotesTab && this.props.defaultQuotesTab === 'my-marketplace') {
      this.setState({tab: 'my-marketplace'});
      this.props.setDefaultQuotesTab(null);
    }
    if (!this.props.hazmatCodes || !this.props.hazmatCodes?.length) {
      this.props.fetchHazmatCodes();
    }
  }

  handleRedirect() {
    let shipment = JSON.parse(JSON.stringify(this.props.selectedShipment));

    let rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    let mode = [];
    let equipment_types = [];
    if (rfqs.length > 0) {
      //for LTL shipments, the parent rfq should be null on the parent rfq obj
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);
      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];
      if (lastRFQ.autoquote === true) {
        mode = [{id: 2, name: 'Less Than Truckload'}];
      } else {
        mode = [{id: 1, name: 'Full Truckload'}];
      }
      if (lastRFQ.shipment_modes) {
        mode = lastRFQ.shipment_modes;
      }
      if (lastRFQ.equipment_types) {
        equipment_types = lastRFQ.equipment_types;
      }
    }
    shipment = transformShipmentToForm(shipment, this.props.hazmatCodes, mode, equipment_types);
    this.props.selectFormShipment(shipment);

    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/edit');
  }

  handleExecutionRedirect() {
    this.context.router.push('/shipments/' + this.props.selectedShipment.id);
  }

  render() {
    const {selectedShipment, selectedRFQ, quote} = this.props;
    let allAcc = [];
    if (selectedShipment && selectedShipment.accessorials.length > 0) {
      allAcc = allAcc.concat(selectedShipment.accessorials);
    }
    if (selectedShipment && selectedShipment.stops && selectedShipment.stops.length > 0) {
      for (let i = 0; i < selectedShipment.stops.length; i++) {
        if (selectedShipment.stops[i].accessorials.length > 0) {
          allAcc = allAcc.concat(selectedShipment.stops[i].accessorials);
        }
      }
    }
    let customerName = '';
    if (selectedShipment && selectedShipment.customer) {
      customerName = selectedShipment.customer.name;
    }

    return (
      <div>
        {selectedShipment && (
          <div>
            <div className="shipment-quoting-header">
              {selectedRFQ && (
                <div className="shipment-quoting-header__item">
                  <i className="flaticon-vendors pad-right" />
                  <div>
                    <h5>Equipment</h5>
                    {selectedRFQ.shipment_modes &&
                      selectedRFQ.shipment_modes.length > 0 &&
                      selectedRFQ.shipment_modes.map((item, i) => {
                        return <p key={i}>{item.description}</p>;
                      })}
                    {selectedRFQ.equipment_types &&
                      selectedRFQ.equipment_types.length > 0 &&
                      selectedRFQ.equipment_types.map((item, i) => {
                        return <p key={i}>{item.name}</p>;
                      })}
                  </div>
                </div>
              )}
              <div className="shipment-quoting-header__item">
                <i className="flaticon-calendar-5 pad-right" />
                <div>
                  <h5>Pickup</h5>
                  <p>{moment(selectedShipment.stops[0].planned_date, 'YYYY-MM-DD').format('ddd MMM D')}</p>
                  <p>
                    {quote && quote.transit_days && !isNaN(quote.transit_days)
                      ? quote.transit_days + (quote.transit_days < 2 ? ' day transit' : ' days transit')
                      : ''}
                  </p>
                </div>
              </div>
              <div className="shipment-quoting-header__item">
                <i className="flaticon-placeholder-3 pad-right" />
                <div>
                  <h5>Origin</h5>
                  <p>
                    {this.props.showZipOnly === true
                      ? selectedShipment.stops[0].location.address.city +
                        ', ' +
                        selectedShipment.stops[0].location.address.state_province +
                        ' ' +
                        selectedShipment.stops[0].location.address.postal_code +
                        ', ' +
                        selectedShipment.stops[0].location.address.country
                      : selectedShipment.stops[0].location.address.formatted_address}
                  </p>
                </div>
              </div>
              {selectedShipment.stops.length > 2 && (
                <div className="shipment-quoting-header__item">
                  <i className="flaticon-placeholders pad-right" />
                  <div>
                    <h5>Stops</h5>
                    {this.props.showZipOnly === true
                      ? selectedShipment.stops.map((stop, index) => {
                          if (index !== 0 && index !== selectedShipment.stops.length - 1) {
                            return (
                              <p key={index}>
                                {index +
                                  '. ' +
                                  stop.location.address.city +
                                  ', ' +
                                  stop.location.address.state_province +
                                  ' ' +
                                  stop.location.address.postal_code +
                                  ', ' +
                                  stop.location.address.country}
                              </p>
                            );
                          }
                        })
                      : selectedShipment.stops.map((stop, index) => {
                          if (index !== 0 && index !== selectedShipment.stops.length - 1) {
                            return <p key={index}>{index + '. ' + stop.location.address.formatted_address}</p>;
                          }
                        })}
                  </div>
                </div>
              )}
              <div className="shipment-quoting-header__item">
                <i className="flaticon-placeholder-3 pad-right" />
                <div>
                  <h5>Destination</h5>
                  <p>
                    {this.props.showZipOnly === true
                      ? selectedShipment.stops[selectedShipment.stops.length - 1].location.address.city +
                        ', ' +
                        selectedShipment.stops[selectedShipment.stops.length - 1].location.address.state_province +
                        ' ' +
                        selectedShipment.stops[selectedShipment.stops.length - 1].location.address.postal_code +
                        ', ' +
                        selectedShipment.stops[selectedShipment.stops.length - 1].location.address.country
                      : selectedShipment.stops[selectedShipment.stops.length - 1].location.address.formatted_address}
                  </p>
                </div>
              </div>
              {selectedShipment.line_items.length > 0 && (
                <div className="shipment-quoting-header__item-wide">
                  <i className="flaticon-products pad-right" />
                  <div>
                    <h5>Items</h5>
                    {selectedShipment.line_items.map((item, i) => {
                      return <p key={i}>{renderLineItemString(item, i)}</p>;
                    })}
                  </div>
                </div>
              )}
              <div className="shipment-quoting-header__item">
                <i className="flaticon-notepad pad-right" />
                <div>
                  <h5>Services</h5>
                  {allAcc.length > 0 ? (
                    allAcc.map((item, i) => {
                      return <p key={i}>{item.description}</p>;
                    })
                  ) : (
                    <p>None</p>
                  )}
                </div>
              </div>

              {!this.props.hideEdit && (
                <div className="shipment-quoting-header__item shipment-quoting-header__item-links">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip placement="bottom" id="tooltip">
                        <div>Edit shipment details and optionally submit for re-rating</div>
                      </Tooltip>
                    }
                  >
                    <span>
                      <i
                        className="flaticon-edit-1 showAsLink shipment-quoting-header__item-normalSize"
                        title="Edit shipment"
                        onClick={this.handleRedirect}
                      />
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip placement="bottom" id="tooltip">
                        <div>Go to shipment execution</div>
                      </Tooltip>
                    }
                  >
                    <span>
                      <i
                        className="flaticon-folder-1 showAsLink shipment-quoting-header__item-normalSize"
                        title="Go to shipment execution"
                        onClick={this.handleExecutionRedirect}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              )}
            </div>
            {!this.props.hideQuoteText && (
              <div className="shipment-quoting-header-quotebar">
                <div className="shipment-quoting-header-quotebar__group">
                  <h1>{'Quote #' + this.props.selectedShipment.reference_id + '  '}</h1>
                  {this.props.selectedShipment.customer_reference_number && (
                    <h5>Customer Ref #{this.props.selectedShipment.customer_reference_number}</h5>
                  )}
                  {/*<div className="shipment-quoting-header-quotebar__communication">
                                      <Button
                                        onClick={() => {
                                          this.context.router.push(
                                            '/shipments/' +
                                              this.props.selectedShipment.id +
                                              '/communication',
                                          );
                                        }}
                                        bsStyle="link"
                                        className="btn-tertiary"
                                        title="Go to communication">
                                        <i className="icon icon-Message pad-right" />
                                        Communication
                                      </Button>
                                    </div>*/}
                </div>
                {customerName !== '' && (
                  <span className="shipment-quoting-header__customerName">
                    <i className="flaticon-id-card-4" /> {customerName}
                  </span>
                )}
              </div>
            )}
            {this.props.showTabs && (
              <div className="quote-flow__results-toggleBar">
                <span
                  className={window.location.href.includes('quotes') ? 'h2 activeTab' : 'h2'}
                  onClick={() => {
                    this.setState({tab: 'real'});
                    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/quotes');
                  }}
                >
                  Instant Rates
                </span>
                <span
                  className={!window.location.href.includes('quotes') ? 'h2 activeTab' : 'h2'}
                  onClick={() => {
                    this.setState({tab: 'my-marketplace'});
                    this.context.router.push(
                      '/shipments/' + this.props.selectedShipment.id + '/my-marketplace/request'
                    );
                  }}
                >
                  My Marketplace
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedShipment: state.shipments.selectedShipment,
    selectedRFQ: state.shipments.selectedRFQ,
    company: state.auth.company,
    hazmatCodes: state.shipments.hazmatCodes,
    defaultQuotesTab: state.marketplace.defaultQuotesTab
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...marketplaceActions,
      ...actions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentQuotingHeader);
