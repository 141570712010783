import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQueryClient} from '@tanstack/react-query';
import {useTenderContract, useUpdateCarrierAssignment} from 'App/data-hooks';
import {getFullShipmentDetails} from 'App/api/shipment/typed';
import {FULL_SHIPMENT_DETAILS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const useCarrierConfigContract = ({
  shipment,
  setError
}: {
  shipment: Shipment;
  setError?: (title: string, message: string) => void;
}) => {
  const {id: shipmentId} = shipment;
  const queryClient = useQueryClient();

  //most of everything this needs exists in this tender hook
  const {selectedApplicableContract, handleContractChange, contractCarrierRelationship} = useTenderContract({shipment});
  const chargeLineItems = selectedApplicableContract?.charges;

  const {mutateAsync: updateCarrierFinancials} = useUpdateCarrierAssignment(shipmentId, {
    onError: ({error, error_description}) => {
      console.error(error);
      setError?.(error, error_description);
    }
  });

  const handleUpdateFinancialsFromContract = async () => {
    if (chargeLineItems?.length) {
      try {
        //need to update shipment
        const {
          data: {relationship_to_vendor}
        } = await queryClient.fetchQuery({
          queryKey: [FULL_SHIPMENT_DETAILS_QUERY_KEY, shipmentId],
          queryFn: () => getFullShipmentDetails(shipmentId)
        });
        if (relationship_to_vendor) {
          const {id: carrierAssignmentId, vendor} = relationship_to_vendor;
          if (carrierAssignmentId)
            await updateCarrierFinancials({
              carrierAssignmentId,
              carrierAssignment: {
                vendor,
                vendor_charge_line_items: [],
                customer_charge_line_items: [],
                contract_id: selectedApplicableContract?.contract?.id
              }
            });
        }
      } catch (error) {
        console.error(error);
        setError?.('Error updating financials', 'Unable to update financials from contract.');
      }
    }
  };

  return {
    selectedApplicableContract,
    handleContractChange,
    contractCarrierRelationship,
    handleUpdateFinancialsFromContract
  };
};

export default useCarrierConfigContract;
