import axios, {AxiosResponse} from 'axios';
import {
  PaginatedWorkflowExecutions1,
  PaginatedWorkflowTriggeringEvents,
  PaginatedWorkflows,
  Workflow,
  WorkflowExecutionInstance
} from '@shipwell/opus-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_CORROGO_API_BASE_PATH || '';

// Currently, the Opus SDK (v0.1.2) does not accept an auth header...
// ...so any requests made directly through the SDK will return a 401
// This file is using axios with typed responses in an attempt to...
// ...not slow production and leverage what is available from the sdk
// The fixes to this are either:
// 1. Opus gets rewritten
// 2. Proper Auth is added to the current opus/sdk

const getConfig = async (params?: unknown) => {
  const accessToken = (await getAccessToken('workflows-api')) as string;
  return {
    headers: {
      authorization: accessToken
    },
    ...(params ? {params} : {})
  };
};

export const getWorkflows = async (): Promise<AxiosResponse<PaginatedWorkflows>> =>
  axios.get(`${basePath}/workflows/`, await getConfig());

export const getWorkflow = async ({id, version}: {id: string; version?: number}): Promise<AxiosResponse<Workflow>> =>
  axios.get(`${basePath}/workflows/${id}${version ? `?version=${version}` : ''}`, await getConfig());

export const getWorkflowExecutions = async ({
  limit = 100,
  page = 1,
  shipmentId
}: {
  limit?: number;
  page?: number;
  shipmentId?: string;
}): Promise<AxiosResponse<PaginatedWorkflowExecutions1>> =>
  axios.get(
    `${basePath}/workflow-execution-instances/`,
    await getConfig({
      limit,
      page,
      shipment_id: shipmentId,
      include: 'workflow'
    })
  );

export const getWorkflowTriggeringEvents = async ({
  limit = 100,
  page = 1,
  triggeringResourceIds
}: {
  limit?: number;
  page?: number;
  triggeringResourceIds: string[];
}): Promise<AxiosResponse<PaginatedWorkflowTriggeringEvents>> =>
  axios.get(
    `${basePath}/workflow-triggering-events/`,
    await getConfig({
      limit,
      page,
      triggering_resource_ids: triggeringResourceIds.join(',')
    })
  );

export const getWorkflowExecutionShipmentInstance = async (
  workflowExecutionId: string
): Promise<AxiosResponse<WorkflowExecutionInstance>> =>
  axios.get(`${basePath}/workflow-execution-instances/${workflowExecutionId}?include=workflow`, await getConfig());
