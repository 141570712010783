import {DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE} from '../../commons/constants';
import {DashboardColumnSort, DashboardColumnSortDirection} from '../../commons/dashboardColumnSchema';
import {DashboardBaseSearchParams} from '../../commons/dashboardSearchParams';
import {getCompatiblePageSize} from '../../commons/pageHelpers';

export function getBaseParamsFromUrlParams() {
  const currentLocation = window.location;
  const searchParams = currentLocation.search;
  const urlParams = new URLSearchParams(searchParams);

  const params: DashboardBaseSearchParams = {
    searchTerm: getSearchTermFromUrlParams(urlParams),
    orderBy: getOrderByFromUrlParams(urlParams),
    page: getPageFromUrlParams(urlParams),
    pageSize: getPageSizeFromUrlParams(urlParams)
  };

  return params;
}

function getSearchTermFromUrlParams(urlParams: URLSearchParams) {
  return urlParams.get('searchTerm') || '';
}

function getOrderByFromUrlParams(urlParams: URLSearchParams) {
  const orderBy = urlParams.get('orderBy');
  return getSortUrlFromString(orderBy);
}

export function getSortUrlFromString(str?: string | null) {
  let sort: DashboardColumnSort;
  if (!str) return;
  if (str.at(0) === '-') {
    sort = {
      direction: DashboardColumnSortDirection.DESC,
      key: str.substring(1)
    };
  } else {
    sort = {
      direction: DashboardColumnSortDirection.ASC,
      key: str
    };
  }

  return sort;
}

function getPageFromUrlParams(urlParams: URLSearchParams) {
  const page = urlParams.get('page');
  return page ? Number(page) : DEFAULT_FIRST_PAGE;
}

function getPageSizeFromUrlParams(urlParams: URLSearchParams) {
  const pageSize = urlParams.get('pageSize');
  return pageSize ? getCompatiblePageSize(Number(pageSize)) : DEFAULT_PAGE_SIZE;
}
