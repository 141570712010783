import {DeviceHistoryEntry} from '@shipwell/locus-sdk';

const columns = [
  {
    Header: 'Update Date/Time',
    accessor: ({update_time}: DeviceHistoryEntry) => (update_time ? new Date(update_time).toLocaleString() : '--'),
    minWidth: 175
  },
  {
    Header: 'Location',
    accessor: ({address}: DeviceHistoryEntry) => address?.formatted_address || '--',
    minWidth: 300
  },
  {
    Header: 'Lat/Long',
    accessor: ({address}: DeviceHistoryEntry) =>
      address?.geolocation.latitude && address?.geolocation.longitude
        ? `${address?.geolocation.latitude.toFixed(6)}; ${address?.geolocation.longitude.toFixed(6)}`
        : '--',
    minWidth: 175
  },
  {
    Header: 'Est Battery Min',
    accessor: ({estimated_battery_life}: DeviceHistoryEntry) => estimated_battery_life || `--`
  },
  {
    Header: 'Battery',
    accessor: ({battery_percent}: DeviceHistoryEntry) => battery_percent || '--'
  },
  {
    Header: 'Temperature',
    accessor: ({temperature}: DeviceHistoryEntry) => {
      if (!temperature) {
        return '--';
      }

      return temperature?.unit.toLowerCase().includes('f') ? `${temperature.value}°F` : `${temperature.value}°C`;
    }
  },
  {
    Header: 'Humidity',
    accessor: ({humidity}: DeviceHistoryEntry) => humidity || '--'
  },
  {
    Header: 'Motion',
    accessor: ({motion}: DeviceHistoryEntry) => {
      if (!motion) {
        return '--';
      }

      return `${motion.x}, ${motion.y}, ${motion.y}`;
    }
  },
  {
    Header: 'Light',
    accessor: ({light}: DeviceHistoryEntry) => {
      if (!light) {
        return '--';
      }

      return `${light.value} ${light.unit}`;
    }
  },
  {
    Header: 'Acceleration',
    accessor: ({motion}: DeviceHistoryEntry) => {
      if (!motion) {
        return '--';
      }

      return `${motion.g} G`;
    }
  },
  {
    Header: 'Pressure',
    accessor: ({pressure}: DeviceHistoryEntry) => {
      if (!pressure) {
        return '--';
      }

      return `${pressure.value} ${pressure.unit}`;
    }
  }
];

export default columns;
