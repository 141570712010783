import {ChargeBreakdownsProps} from 'src/@types/quotingTypes';
import {formatCurrency} from 'App/utils/internationalConstants';
import {formatCurrencyValue} from 'App/utils/globals';
import {Rate} from 'src/@types/quotingTypes';

export const ChargeBreakDowns = ({rate}: ChargeBreakdownsProps) => {
  const getPercentage = (rate: Rate) => {
    if (rate.customer_markup && rate.charge_total) {
      return ((rate.customer_markup * 100) / (rate.charge_total.amount - rate.customer_markup)).toFixed(2);
    }
  };

  return (
    <div className="relative w-[14rem]">
      {rate?.charge_breakdown
        ?.sort((a, b) => (b.charge_detail?.unit_rate?.amount || 0) - (a.charge_detail?.unit_rate?.amount || 0))
        .map((charge, index) => (
          <div className="grid grid-cols-2 gap-4" key={index}>
            <div className="whitespace-nowrap justify-self-end">
              <span className="font-bold">{charge.description}:</span>
            </div>
            <span className="!text-right">
              {formatCurrency(charge.charge_detail?.unit_rate?.amount, charge.charge_detail?.unit_rate?.currency)}
            </span>
          </div>
        ))}
      {rate?.customer_markup && rate.customer_markup !== 0 ? (
        <div className="grid grid-cols-2 gap-4 whitespace-nowrap">
          <div className="justify-self-end">
            <span className="font-bold">Markup ({formatCurrencyValue(getPercentage(rate))}):</span>
          </div>
          <span className="!text-right">{formatCurrency(rate.customer_markup, rate.charge_total?.currency)}</span>
        </div>
      ) : (
        ''
      )}
      <div className="grid grid-cols-2 gap-4 whitespace-nowrap">
        <div className="justify-self-end whitespace-nowrap">
          <span className="font-bold">Total</span>
        </div>
        <span className="!text-right">{formatCurrency(rate?.charge_total?.amount, rate?.charge_total?.currency)}</span>
      </div>
    </div>
  );
};
