import {ELDDeviceLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {DashboardFooter} from 'App/components/dashboards/simpleDashboard/dashboardFooter/dashboardFooter';
import {DashboardTable} from 'App/components/dashboards/simpleDashboard/dashboardTable/dashboardTable';
import {useViewShipmentMovementLogModal} from './useViewShipmentMovementLogTable';
import {Button} from '@shipwell/shipwell-ui';
import {getColumnsSchema} from './columnSchema';

export const ViewShipmentMovementLogTable = ({breadcrumbs}: {breadcrumbs: ELDDeviceLocation[]}) => {
  const {handleExportToCsv, onPageChange, onPerPageChange, page, perPage, totalItems, paginatedBreadcrumbs} =
    useViewShipmentMovementLogModal(breadcrumbs);

  return (
    <>
      <div className="overflow-x-auto h-[calc(100vh-175px)]">
        <DashboardTable
          data={paginatedBreadcrumbs}
          columnsSchema={getColumnsSchema(breadcrumbs)}
          fullWidth={true}
          onSortBy={() => {}}
        />
      </div>
      <DashboardFooter
        page={page}
        perPage={perPage}
        totalItems={totalItems}
        leftElements={
          <Button variant="tertiary" iconName="Download" onClick={handleExportToCsv}>
            Export Table Data
          </Button>
        }
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
      />
    </>
  );
};
