import {Button} from '@shipwell/shipwell-ui';
import {SimpleDashboard} from 'App/components/dashboards/simpleDashboard/simpleDashboard';
import {searchFunction} from './searchFunction';
import {columnSchema} from './columnSchema';
import {CreateSupplierStepsModal} from '../components/CreateSupplierStepsModal';
import {useVisibility} from 'App/components/useVisibilty/useVisibilty';

const SuppliersListV2 = () => {
  const modal = useVisibility();
  return (
    <>
      <SimpleDashboard
        title="Suppliers"
        showSearchTerm
        fullWidth
        columnsSchema={columnSchema}
        headerRight={
          <Button variant="tertiary" iconName="AddCircleOutlined" onClick={modal.show}>
            Supplier
          </Button>
        }
        searchFn={(params) => searchFunction(params)}
      />
      <CreateSupplierStepsModal isOpen={modal.isVisible} onClose={modal.hide} />
    </>
  );
};

export default SuppliersListV2;
