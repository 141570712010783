import PropTypes from 'prop-types';
import {Component} from 'react';
import './_shipwell-loader.scss';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import {loadingMessages, isWhiteLabel} from 'App/utils/globals';
const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
    color: '#319bf5'
  }
});

let messages = [];
if (isWhiteLabel()) {
  messages = loadingMessages.filter((el) => !el.toLowerCase().includes('shipwell'));
} else {
  messages = loadingMessages;
}

const length = messages.length;
const messageIdx = Math.floor(Math.random() * length);
const message = messages[messageIdx];

class ShipwellLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {message: message};
  }
  componentDidMount() {
    const messageIdx = Math.floor(Math.random() * length);
    const message = messages[messageIdx];
    this.setState({message: message});
  }
  render() {
    const {loading, classes, additionalClassNames = []} = this.props;
    let logo;
    if (this.props.brokerLogos) {
      for (let i = 0; i < this.props.brokerLogos.length; i++) {
        if (this.props.brokerLogos[i].image_type === 'LOGO_COLOR') {
          if (this.props.brokerLogos[i].company_name === 'Shipwell') {
            logo = '/images/shipwell_logo.png';
          } else {
            // eslint-disable-next-line
            logo = this.props.brokerLogos[i].logo;
          }
        }
      }
    }

    return loading ? (
      <div data-testid="shipwellLoader" className={classnames('shipwellLoader__container', ...additionalClassNames)}>
        <CircularProgress className={classes.progress} />
      </div>
    ) : (
      <div />
    );
  }
}

ShipwellLoader.propTypes = {
  additionalClassNames: PropTypes.array,
  brokerLogos: PropTypes.arrayOf(
    PropTypes.shape({
      image_type: PropTypes.string,
      company_name: PropTypes.string
    })
  ),
  classes: PropTypes.object,
  loading: PropTypes.bool
};

export default withStyles(styles)(ShipwellLoader);
