import {DashboardSearchParams} from 'App/components/dashboards/commons/dashboardSearchParams';
import {DashboardSearchResult} from 'App/components/dashboards/commons/dashboardSearchResult';
import {DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE} from 'App/components/dashboards/commons/constants';
import {DashboardColumnSortDirection} from 'App/components/dashboards/commons/dashboardColumnSchema';
import {Supplier} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getSuppliers} from 'App/api/suppliers';

export const searchFunction = async (params: DashboardSearchParams) => {
  const response = await getSuppliers({
    page: params.base.page || DEFAULT_FIRST_PAGE,
    pageSize: params.base.pageSize || DEFAULT_PAGE_SIZE,
    ordering: params.base.orderBy
      ? `${params.base.orderBy.direction === DashboardColumnSortDirection.DESC ? '-' : ''}${params.base.orderBy.key}`
      : undefined,
    q: params.base.searchTerm
  });

  const result: DashboardSearchResult<Supplier> = {
    items: response.data || [],
    pagination: {
      page: params.base.page || DEFAULT_FIRST_PAGE,
      pageSize: params.base.pageSize || DEFAULT_PAGE_SIZE,
      totalItems: response.count || 0
    }
  };

  return result;
};
