import Creatable from 'react-select/creatable';
import FormGroup from '../../formGroup';
import './styles.scss';
import {useMemo} from 'react';

/**
 * Createable Field
 */
const CreateableField = (props) => {
  const {input, req, placeholder, options, simpleValue, labelKey, valueKey, clearable, disabled, ...rest} = props;
  const value = input.value || props.value;
  const name = input.name || props.name;
  const onChange = input.onChange || props.onChange;
  const onNewOptionClick = input.onNewOptionClick || props.onNewOptionClick;

  // react-select v5 requires an item from the array to
  // correctly display the selected value, unlike v1,
  // which only needed a string that matched the valueKey.
  const currentValue = useMemo(() => {
    // "simpleValue" is a concept used in the previous version
    // that indicates the value will be only the key. Therefore,
    // we need to dig into the options array to find the corresponding item.
    if (simpleValue) {
      return options?.find((option) => option[valueKey] === value) || null;
    }

    // If it's not a "simpleValue", we can use the value itself,
    // which will likely match one of the option items.
    return value;
  }, [value, simpleValue, options]);

  function handleChange(newValue) {
    if (onChange) {
      if (simpleValue && newValue) onChange(newValue[valueKey]);
      else onChange(newValue);
    }
  }

  function handleChangeCreateOption(inputValue) {
    if (onNewOptionClick) {
      // Old version sends new options as an object
      // using labelKey and valueKey as props, with
      // newValue as value...
      const createdValue = onNewOptionClick({
        [labelKey]: inputValue,
        [valueKey]: inputValue
      });

      if (createdValue) {
        handleChange(createdValue);
      }
    }
  }

  return (
    <FormGroup {...props} className="select-group">
      {() => (
        <Creatable
          {...rest}
          createOptionPosition="first"
          name={name}
          classNamePrefix={'sw-react-select'}
          value={currentValue}
          placeholder={placeholder}
          options={options}
          isClearable={clearable}
          isDisabled={disabled}
          getOptionLabel={(option) => {
            if (option.__isNew__) return option.label;
            return option[labelKey];
          }}
          getOptionValue={(option) => {
            if (option.__isNew__) return option.value;
            return option[valueKey];
          }}
          onChange={handleChange}
          onCreateOption={handleChangeCreateOption}
        />
      )}
    </FormGroup>
  );
};

CreateableField.defaultProps = {
  name: '',
  value: '',
  input: {},
  labelKey: 'name',
  valueKey: 'id',
  clearable: false
};

export default CreateableField;
