import {FullStory, init, isInitialized} from '@fullstory/browser';
import {Company, User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useUserMe} from 'App/data-hooks';
import {useCallback, useEffect} from 'react';

const hostname = window.location.hostname;
const canRunForThisHostname = !hostname.includes('frontend-web.shipwell-dev') && !hostname.includes('localhost');

export function FullStoryAgent() {
  const user = useUserMe();
  const userProfile = user.data?.user;
  const company = user.company;

  const setupFullStory = useCallback(async (currentUser: User, currentCompany: Company) => {
    let displayName = currentUser.email;

    if (currentUser.first_name) {
      displayName = `${currentUser.first_name} ${currentUser.last_name || ''}`;
    }

    FullStory('setIdentity', {
      consent: true,
      uid: currentUser.id ?? '',
      properties: {
        displayName: displayName,
        email: currentUser.email ?? '',
        company_id: currentCompany.id,
        createdAt: currentUser.created_at
      }
    });

    await FullStory('trackEventAsync', {
      name: 'User Identified - FullStory',
      properties: {}
    });
  }, []);

  useEffect(() => {
    if (canRunForThisHostname && userProfile?.id && company?.id && !isInitialized()) {
      init({orgId: '4NK5G'}, () => {
        void setupFullStory(userProfile, company);
      });
    }
  }, [userProfile, company, setupFullStory]);

  /**
   * This could be just a hook, but wanted to keep it close to
   * DataDogProvider, that I think, should be re-worked in the
   * near future. That's why it's just returning null.
   */
  return null;
}
