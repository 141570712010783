import {useState, useMemo, useCallback, useEffect} from 'react';
import {connect, DispatchProp} from 'react-redux';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import cloneDeep from 'lodash/cloneDeep';
import {Loader} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {RFQ, Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {withRouter, WithRouterProps} from 'react-router';
import {parseZone} from 'moment/moment';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CircularProgress from '@material-ui/core/CircularProgress';
import {State} from 'App/reducers/types';
import {
  ActivateRateMutation,
  useActiveRateMutation,
  useConnectionsQuery,
  useCreateRateRequestMutation,
  useRatesPolling
} from 'App/containers/InstantRates/hooks';
import Table from 'App/components/Table';
import {createInstantRatesTableColumns} from 'App/containers/InstantRates/components/InstantRatesTable/columns';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {ChargeBreakdownsProps, Rate} from 'src/@types/quotingTypes';
import {
  CREATE_RFQ,
  SELECT_RFQ,
  SELECT_QUOTE,
  SELECT_SHIPMENT_FOR_FORM,
  CLEAR_SELECTED_QUOTE,
  SELECT_SHIPMENT
} from 'App/actions/types';
import InstantRatesUpdateModal from 'App/containers/InstantRates/components/InstantRatesUpdateModal/InstantModalRateUpdate';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';
import {useUserMe} from 'App/data-hooks';
import {SHIPMENT_CREATE_SPOT_NEGOTIATIONS} from 'App/components/permissions/PermissionsFallback/constants';
import {formatCurrency} from 'App/utils/internationalConstants';
import {transformShipmentToForm} from 'App/utils/globals';
import useHazmatCodes from 'App/data-hooks/mdm/useHazmatCodes';

interface InjectedInstantRatesTablePropTypes extends WithStatusToastProps, WithRouterProps<unknown>, DispatchProp {}

export interface InstantRatesTablePropTypes {
  shipment: Shipment;
  expandedColumnSet?: boolean;
  /**
   * Callback for handling when a rate is selected. If
   * a rate is set to `null` then no rate is currently
   * selected.
   */
  onRateSelect?: (rate: Rate | null) => void;
}

interface RateRequestIdState {
  rateRequestId?: string;
  rateId?: string | null;
  legacyRfqId?: string | null;
}

const InstantRatesTable = (props: InstantRatesTablePropTypes & InjectedInstantRatesTablePropTypes) => {
  const [requestIds, setRequestIds] = useState<RateRequestIdState | undefined>(); // everything has to be in one state because of fun race conditions and asyncronous calls.
  const [loadingMessage, setLoadingMessage] = useState<string>('Searching for best Rates...');
  const [showInstantRatesUpdateModal, setInstantRatesUpdateModal] = useState(false);
  const [rateSelected, setRateSelected] = useState<Rate>();
  const [currentShipment, setCurrentShipment] = useState<Shipment>(props.shipment);
  const connections = useConnectionsQuery();
  const updateShipmentMutation = useUpdateShipment();
  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  const {data: userData} = useUserMe();
  const {krknTenderingUserPermissions} = useFlags();
  const userPermissions = userData?.user?.permissions || [];
  const canTakeActionOnRates = krknTenderingUserPermissions
    ? userPermissions.includes(SHIPMENT_CREATE_SPOT_NEGOTIATIONS)
    : true;
  const [rateData, setRateData] = useState<Rate[]>();

  const {hazmatOptions} = useHazmatCodes();

  const setActiveRateMutation = useActiveRateMutation({
    onSuccess: (data: ActivateRateMutation) => {
      if (!isUndefined(data.legacyQuote)) {
        const shipmentCopy = cloneDeep(props.shipment);
        // legacy hold over until we move away from react-redux.
        // this needs to be set for the confirmation page.
        props.dispatch({type: SELECT_QUOTE, payload: data.legacyQuote});
        props.dispatch({
          type: SELECT_SHIPMENT_FOR_FORM,
          payload: transformShipmentToForm(
            shipmentCopy,
            hazmatOptions,
            shipmentCopy.mode,
            [shipmentCopy.equipment_type].filter(Boolean),
            true
          )
        });
      }
      if (isUndefined(props.shipment) || isUndefined(props.shipment?.id)) {
        return;
      }
      props.router.push(
        '/shipments/' + props.shipment.id + '/confirm?mode=instant-rates&rateRequestId=' + data.rate.rateRequestId
      );
    }
  });

  const ChargeBreakDowns = ({chargeBreakdowns, chargeTotal}: ChargeBreakdownsProps) => (
    <div className="w-full">
      {chargeBreakdowns
        ?.sort((a, b) => (b.charge_detail?.unit_rate?.amount || 0) - (a.charge_detail?.unit_rate?.amount || 0))
        .map((charge, index) => (
          <div className="grid grid-cols-2 gap-4 whitespace-nowrap text-right" key={index}>
            <span className="font-bold">{charge.description}</span>
            <span>
              {formatCurrency(charge?.charge_detail?.unit_rate?.amount, charge?.charge_detail?.unit_rate?.currency)}
            </span>
          </div>
        ))}
      <div className="grid grid-cols-2 gap-4 whitespace-nowrap text-right">
        <span className="font-bold">Total Charges</span>
        <span>{formatCurrency(chargeTotal?.amount, chargeTotal?.currency)}</span>
      </div>
    </div>
  );

  const ratesPollingQuery = useRatesPolling(requestIds?.rateRequestId, requestIds?.legacyRfqId, connections.data, {
    onSuccess: (data: Rate[]) => {
      if (data?.length > 0) {
        setRateData(
          data.map((row) => ({
            ...row,
            expandedContent: <ChargeBreakDowns chargeTotal={row.charge_total} chargeBreakdowns={row.charge_breakdown} />
          }))
        );
        setLoadingMessage('Finished getting rates from carriers');
      }
    }
  });

  const createFtlRatesMutation = useCreateRateRequestMutation({
    onMutate: () => {
      setLoadingMessage('Creating rate request with carriers...');
    },
    onSuccess: (data: {rfq?: RFQ; rateRequestId: string}) => {
      setLoadingMessage('Finished creating rate request...');
      setRequestIds({
        rateRequestId: data.rateRequestId,
        legacyRfqId: data.rfq?.id
      });
      if (!isUndefined(data.rfq)) {
        // legacy hold over until we can remove redux and backend-core logic for rating/quoting
        // these need to be called for the confirmation page
        props.dispatch({type: CREATE_RFQ, payload: data.rfq});
        props.dispatch({type: SELECT_RFQ, payload: data.rfq});
      }
    }
  });
  const isLoading = Boolean(
    createFtlRatesMutation.isLoading || ratesPollingQuery.isInitialLoading || connections.isInitialLoading
  );

  useEffect(() => {
    if (
      isUndefined(props.shipment) ||
      createFtlRatesMutation.isLoading ||
      createFtlRatesMutation.isSuccess ||
      createFtlRatesMutation.isError
    ) {
      return;
    }
    createFtlRatesMutation.mutate(props.shipment);
  }, [props, createFtlRatesMutation]);

  const handleSelect = useCallback(
    (rate: Rate) => {
      if (isUndefined(rate) || isNull(rate)) {
        // legacy hold over until we remove redux and backend-core from rating
        // this is set so we can't redirect to the confirmtion page.
        props.dispatch({type: CLEAR_SELECTED_QUOTE});
      }
      const rateId = rate?.id;
      const legacyRfqId = rate?.legacyRfqId || requestIds?.legacyRfqId;
      const rateRequestId = rate?.rateRequestId || requestIds?.rateRequestId;
      // need to set this information regardless of other calls
      setRequestIds({
        rateId,
        rateRequestId,
        legacyRfqId
      });
      if (isUndefined(rateRequestId) || isUndefined(rateId)) {
        return;
      }
      setActiveRateMutation.mutate(rate);
    },
    [props, setRequestIds, requestIds, setActiveRateMutation]
  );

  const handleUpdate = useCallback(
    (rate: Rate) => {
      setInstantRatesUpdateModal(true);
      setRateSelected(rate);
    },
    [setInstantRatesUpdateModal, setRateSelected]
  );

  const handleCancel = useCallback(() => {
    setInstantRatesUpdateModal(false);
  }, [setInstantRatesUpdateModal]);

  const {dispatch} = props;

  const handleSubmitUpdateStops = useCallback(
    (shipment: Shipment, rate: Rate | undefined) => {
      setLoading(true);
      updateShipmentMutation.mutate(
        {shipmentId: shipment.id, shipment: shipment},
        {
          onSuccess: ({data: updatedShipment}) => {
            if (rate) {
              handleSelect(rate);
            } else {
              createFtlRatesMutation.mutate(shipment);
            }
            dispatch({type: SELECT_SHIPMENT, payload: updatedShipment});
            setCurrentShipment(updatedShipment);
            setInstantRatesUpdateModal(false);
            setAccept(false);
          },
          onError: (error) => {
            setLoading(false);
            setAccept(false);
            console.error(error);
          }
        }
      );
    },
    [
      setLoading,
      updateShipmentMutation,
      createFtlRatesMutation,
      handleSelect,
      setInstantRatesUpdateModal,
      setCurrentShipment,
      dispatch
    ]
  );

  const acceptRecommendation = useCallback(
    (rate: Rate) => {
      setLoadingMessage('Applying the recommendations..');
      setAccept(true);
      const window = rate.recommendations?.filter((value) => value.code === 'STOP_WINDOW_ADJUSTED')[0];
      if (isNil(window)) {
        throw new Error('No recommendation found to carry out the update process');
      }
      const dateTimeFormat = 'HH:mm';
      const dateFormat = 'YYYY-MM-DD';
      currentShipment?.stops?.map((stop) => {
        const pickup = window?.recommendation?.window.pickup;
        if (stop.is_pickup && !isNil(pickup)) {
          stop.planned_time_window_start = parseZone(pickup?.earliestWindowStart).format(dateTimeFormat);
          stop.planned_time_window_end = parseZone(pickup?.latestWindowEnd).format(dateTimeFormat);
          stop.planned_date = parseZone(pickup.earliestWindowStart).format(dateFormat);
        }
        const delivery = window?.recommendation?.window.delivery;
        if (stop.is_dropoff && !isNil(delivery)) {
          stop.planned_time_window_start = parseZone(delivery.earliestWindowStart).format(dateTimeFormat);
          stop.planned_time_window_end = parseZone(delivery?.latestWindowEnd).format(dateTimeFormat);
          stop.planned_date = parseZone(delivery?.earliestWindowStart).format(dateFormat);
        }
      });
      handleSubmitUpdateStops(currentShipment, rate);
    },
    [currentShipment, handleSubmitUpdateStops]
  );

  const columns = useMemo(() => {
    const shouldHideRates =
      !!props.shipment?.metadata?.bill_to_override?.direction &&
      props.shipment?.mode?.code === 'LTL' &&
      ['COLLECT', '3RD_PARTY'].includes(props.shipment?.metadata?.bill_to_override?.direction);

    const isQuoting = props.shipment.state === 'quoting';

    return createInstantRatesTableColumns(
      canTakeActionOnRates,
      isQuoting,
      handleSelect,
      handleUpdate,
      acceptRecommendation,
      requestIds?.rateId,
      props.expandedColumnSet,
      shouldHideRates
    );
  }, [props, handleSelect, handleUpdate, requestIds, acceptRecommendation, canTakeActionOnRates]);

  const activeConnections = connections?.data?.length;

  if (activeConnections === 0) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <span>No rates.</span>
      </div>
    );
  }

  if (accept) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <Loader show={accept}>{loadingMessage}</Loader>
      </div>
    );
  }

  if (activeConnections !== 0 && isLoading) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <Loader show={isLoading}>{loadingMessage}</Loader>
      </div>
    );
  }

  const loadingRateInformation = () => {
    return !!(ratesPollingQuery.data && ratesPollingQuery.data.length === 0);
  };

  return (
    <>
      {!loadingRateInformation() ? (
        <InstantRatesUpdateModal
          onUpdate={handleSubmitUpdateStops}
          show={showInstantRatesUpdateModal}
          close={handleCancel}
          loading={loading}
          shipment={currentShipment}
          rate={rateSelected}
        />
      ) : null}
      <Table
        columns={columns}
        data={rateData}
        dataIsLoading={loadingRateInformation()}
        initialSortKey="rate.amount"
        manualSort={false}
      />
      {!loadingRateInformation() && ratesPollingQuery.data?.length !== connections.data?.length ? (
        <div className="flex w-full justify-center py-4">
          <CircularProgress className="w-8 text-sw-primary" />
        </div>
      ) : null}
    </>
  );
};

interface InternalInstantRatesTableState {
  shipments: {
    selectedShipment?: Shipment;
  };
}

/**
 * Hold over function for mapping redux state to the internal properties. The only state that is used
 * is the `shipments.selectedShipment`. This should be deprecated after moving most tools to react-query.
 */
const mapStateToProps = (state: State, ownProps: InstantRatesTablePropTypes): InstantRatesTablePropTypes => {
  const {shipments} = state as InternalInstantRatesTableState;
  const shipment = ownProps.shipment || shipments?.selectedShipment;
  return {
    onRateSelect: ownProps.onRateSelect,
    expandedColumnSet: ownProps.expandedColumnSet,
    shipment
  };
};

export default compose<InstantRatesTablePropTypes & InjectedInstantRatesTablePropTypes, InstantRatesTablePropTypes>(
  connect(mapStateToProps),
  WithStatusToasts,
  withRouter
)(InstantRatesTable);
