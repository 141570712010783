import {ProviderCode, Rate as GenesisRate, Charge, RateStatus} from '@shipwell/genesis-sdk';
import {ExpandedCreateRateRecommendation, Rate} from 'src/@types/quotingTypes';
import isNil from 'lodash/isNil';
import {Quote, QuoteChargeLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';

const defaultUnknownText = 'Unknown';

const getScac = (providerCode: ProviderCode) => {
  const defaultDisplayScac = {
    scac: defaultUnknownText,
    displayText: defaultUnknownText
  };
  const carrierIdentificationMap: Record<string, string> = {
    UBER: 'Uber Freight',
    AMAZON: 'Amazon Freight',
    CHROBINSON: 'CH Robinson'
  };

  if (isNil(providerCode)) {
    return defaultDisplayScac;
  }

  const carrierIdentificationCode = providerCode;
  return {
    scac: carrierIdentificationCode,
    display_name: carrierIdentificationMap[carrierIdentificationCode]
  };
};

export const transformRate = (rate: GenesisRate, rateRequestId: string, legacyRfqId?: string | null): Rate => {
  const hasError = rate.status === RateStatus.Errored || !rate.charges?.total_charges.amount;

  return {
    ...rate,
    rateRequestId,
    legacyRfqId,
    rate: {
      // it is important to set amount to undefined if 0 for sorting
      amount: hasError ? undefined : rate.charges?.total_charges?.amount,
      currency: rate.charges?.total_charges?.currency ?? 'USD'
    },
    can_dispatch: true,
    charge_breakdown: rate.charges?.charge_breakdown,
    charge_total: {
      currency: rate.charges?.total_charges.currency,
      amount: rate.charges?.total_charges.amount || 0
    },
    carrier: getScac(rate.provider_code),
    transit_days: rate.transit_time?.transit_days,
    delivery_date: rate.transit_time?.delivery?.earliest || rate.transit_time?.delivery?.latest,
    earliest_pickup_date: rate.transit_time?.pickup?.earliest || rate.transit_time?.pickup?.latest,
    expires_at: rate.expiry,
    recommendations: rate.recommendations as ExpandedCreateRateRecommendation[] | undefined,
    errors: rate.messages?.flatMap((value) => value.detail).filter(Boolean) as string[] | undefined,
    service_level: rate.service_code,
    isGenesisRate: true
  };
};

const transformChargeBreakDownLegacy = (charge: QuoteChargeLineItem): Charge => ({
  charge_code: charge.charge_code as string,
  description: charge.unit_name || undefined,
  charge_detail: {
    unit_rate: {
      currency: charge.unit_amount_currency,
      amount: charge.unit_amount
    },
    unit_quantity: charge.unit_quantity
  }
});

export const transformRatesLegacy = (quote: Quote, legacyRfqId: string): Rate => {
  const hasError = quote.is_failure || !quote.total;

  return {
    ...quote,
    legacyQuote: quote,
    id: quote.id as string,
    legacyRfqId,
    charge_breakdown: quote?.charge_line_items?.map((charge) => transformChargeBreakDownLegacy(charge)),
    charge_total: {
      currency: quote.currency,
      amount: quote.total || 0
    },
    rate: {
      // it is important to set amount to undefined if the rate is 0 for sorting
      amount: hasError ? undefined : quote.total,
      currency: quote.currency
    },
    carrier: quote?.carrier
      ? {
          ...quote?.carrier,
          scac: quote.carrier.scac_number || defaultUnknownText,
          display_name: quote.carrier.display_name || defaultUnknownText
        }
      : {scac: defaultUnknownText, display_name: defaultUnknownText},
    isGenesisRate: false
  };
};

export const getHasError = (rate: Rate) =>
  rate.legacyQuote?.is_failure || rate.status === RateStatus.Errored || !rate.rate.amount;
