import {ShipmentMode} from '@shipwell/backend-core-sdk';

export type ReferenceFieldName =
  | 'customer_reference_number'
  | 'bol_number'
  | 'pro_number'
  | 'pickup_number'
  | 'purchase_order_number'
  | 'tracking_number'
  | 'drayage_seal_number'
  | 'drayage_booking_number'
  | 'drayage_container_number'
  | 'drayage_chassis_number'
  | 'drayage_house_bol_number'
  | 'relationship_to_customer.customer.name'
  | 'customer_reference_number'
  | 'drayage_carrier_scac_code'
  | 'rail_car_number'
  | 'rail_car_status'
  | 'MASTER_AIR_WAYBILL'
  | 'HOUSE_AIR_WAYBILL';

export type Context = Partial<Record<ReferenceFieldName, string | null>>;
export type ReferenceItem = {
  label: string;
  fieldName: ReferenceFieldName;
  readonly?: boolean;
  tooltip?: string;
};

export type Shipment = Record<ReferenceFieldName, string> & {
  mode: ShipmentMode;
  carrier_reference_code: string;
  relationship_to_vendor?: {
    vendor: boolean;
  };
};

const po: ReferenceItem = {label: 'PO #', fieldName: 'purchase_order_number'};
const pickup: ReferenceItem = {label: 'Pickup #', fieldName: 'pickup_number'};
const bol: ReferenceItem = {label: 'BOL #', fieldName: 'bol_number'};
const pro: ReferenceItem = {label: 'PRO #', fieldName: 'pro_number'};
const seal: ReferenceItem = {label: 'Seal #', fieldName: 'drayage_seal_number'};
const track: ReferenceItem = {label: 'Tracking #', fieldName: 'tracking_number'};
const booking: ReferenceItem = {label: 'Booking #', fieldName: 'drayage_booking_number'};
const houseBOL: ReferenceItem = {label: 'House BOL #', fieldName: 'drayage_house_bol_number'};
const container: ReferenceItem = {label: 'Container #', fieldName: 'drayage_container_number'};
const chassis: ReferenceItem = {label: 'Chassis #', fieldName: 'drayage_chassis_number'};
const oceanCarrierSCAC: ReferenceItem = {label: 'Ocean Carrier SCAC', fieldName: 'drayage_carrier_scac_code'};
const railcarNumber: ReferenceItem = {label: 'Railcar #', fieldName: 'rail_car_number'};
const railcarStatus: ReferenceItem = {label: 'Railcar Status', fieldName: 'rail_car_status', readonly: true};
const masterAirwayBillNumber: ReferenceItem = {
  label: 'Master Airway Bill Number',
  fieldName: 'MASTER_AIR_WAYBILL',
  tooltip: 'This field is required to enable tracking and must have the format: XXX-######## (e.g. DEL-12345678)'
};
const houseAirwayBillNumber: ReferenceItem = {label: 'House Airway Bill Number', fieldName: 'HOUSE_AIR_WAYBILL'};

// list of fields that the are stored inside the references array, they should be
// handled differently when reading/writing
export const referencesArrayFields = [masterAirwayBillNumber, houseAirwayBillNumber];

export const parcelReferences: ReferenceItem[] = [po, track];
export const drayageReferences: ReferenceItem[] = [
  po,
  pickup,
  bol,
  pro,
  container,
  oceanCarrierSCAC,
  seal,
  chassis,
  booking,
  houseBOL
];
export const ltlReferences: ReferenceItem[] = [po, pickup, bol, pro];
export const vltlReferences: ReferenceItem[] = [po, pickup, bol, pro];
export const ftlReferences: ReferenceItem[] = [po, pickup, bol, pro, seal];
export const intermodalReferences: ReferenceItem[] = [
  po,
  pickup,
  bol,
  pro,
  container,
  oceanCarrierSCAC,
  seal,
  chassis,
  booking
];
export const railReferences: ReferenceItem[] = [
  po,
  pickup,
  bol,
  pro,
  container,
  seal,
  chassis,
  booking,
  houseBOL,
  railcarNumber,
  railcarStatus
];

export const airReferences: ReferenceItem[] = [masterAirwayBillNumber, houseAirwayBillNumber, po, pickup, bol, pro];
