/* eslint-disable max-len */
import {Component} from 'react';
import {connect} from 'react-redux';
import {Field, change, formValueSelector} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import isArray from 'lodash/isArray';
import ModeField from '../../fields/mode';
import EquipmentTypeField from '../../fields/equipmentType';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {POUNDS} from 'App/utils/internationalConstants';
import {equipmentTypesByModeFilter} from 'App/utils/filterEquipmentTypes';

/**
 * Shipment Mode and Equipment Type Fields
 */
class ShipmentModeEquipmentFields extends Component {
  static defaultProps = {
    slimForm: false,
    mode: {},
    equipmentTypes: []
  };

  /**
   * Handler for select box selection
   */
  handleChange(e, values) {
    const {form, equipmentType, slimForm, dispatch, unitPreferences} = this.props;
    const {hasLTL, hasVLTL, hasFTL} = checkShipmentModes(values);
    const isLTLorVLTL = hasLTL || hasVLTL;
    const isMultiSelect = isArray(values);

    // slimForm here seems to indicate a multi select,
    // which is used in the quoting flow rather than the standard new shipment flow and is backed by an array
    if (slimForm && isMultiSelect && !values.length) {
      dispatch(change(form, 'equipment_type', []));
    } else if (!isMultiSelect) {
      dispatch(change(form, 'equipment_type', null));
    }

    if (slimForm && isLTLorVLTL) {
      const includesDryVan = equipmentType.find((type) => type.id === 1);

      if (!includesDryVan) {
        dispatch(
          change(form, 'equipment_type', [...equipmentType, {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}])
        );
      }
    }
    if (!hasFTL) {
      //resets weight override
      dispatch(
        change(form, 'total_weight_override', {
          value: null,
          unit: unitPreferences.weightUnit || POUNDS
        })
      );
      //indicates required field
      dispatch(change(form, 'manual_total_weight', false));
    }
  }

  render() {
    const {slimForm, equipmentTypes, mode, form} = this.props;
    const {hasParcel} = checkShipmentModes(mode);

    return (
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6}>
          <Field
            simpleValue={!slimForm}
            multi={slimForm}
            required
            label="Mode"
            name="mode"
            labelKey="description"
            placeholder="Select mode"
            props={{form: form}}
            component={ModeField}
            onChange={this.handleChange.bind(this)}
          />
        </Grid>
        {!hasParcel && (
          <Grid item xs={12} md={6}>
            <Field
              simpleValue={!slimForm}
              multi={slimForm}
              required
              label="Equipment"
              name="equipment_type"
              placeholder="Select equipment type"
              options={equipmentTypes.filter(equipmentTypesByModeFilter(mode))}
              component={EquipmentTypeField}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default connect((state, props) => ({
  shipmentModes: state.shipments.shipmentModes,
  equipmentTypes: state.shipments.equipmentTypes,
  mode: formValueSelector(props.form)(state, 'mode'),
  equipmentType: formValueSelector(props.form)(state, 'equipment_type'),
  unitPreferences: state.userCompany.unitPreferences
}))(ShipmentModeEquipmentFields);
