import {Component} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import SelectField from 'App/formComponents/fields/select';
import {fetchAccessorialCodes} from 'App/actions/shipments';
import {filterAccessorials} from 'App/utils/globals';

/**
 * Shipment Accessorials Field
 * Renders a multiselect field for choosing accessorial
 */
class ShipmentAccessorialsField extends Component {
  static defaultProps = {
    name: 'accessorials',
    placeholder: 'Select accessorials',
    multi: true,
    labelKey: 'description',
    component: SelectField
  };

  componentDidMount() {
    if (this.props.user.company) {
      this.requestAccessorialOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props;

    /** Need to wait for Auth token to be set */
    if (user !== prevProps.user) {
      this.requestAccessorialOptions();
    }
  }

  requestAccessorialOptions() {
    this.props.dispatch(fetchAccessorialCodes());
  }

  getFilteredAccessorialOptions = () => {
    const {accessorials, hasDrayage, hasParcel} = this.props;
    if (hasDrayage) {
      return accessorials.drayageShipment;
    }
    if (hasParcel) {
      return accessorials.parcelShipment;
    }
    return accessorials.shipment;
  };

  render() {
    return <Field {...this.props} options={this.getFilteredAccessorialOptions()} />;
  }
}

const accessorialPropType = PropTypes.shape({id: PropTypes.number});
ShipmentAccessorialsField.propTypes = {
  user: PropTypes.shape({company: PropTypes.object}),
  accessorials: PropTypes.shape({
    drayageShipment: accessorialPropType,
    parcelShipment: accessorialPropType,
    shipment: accessorialPropType
  }),
  hasDrayage: PropTypes.bool,
  hasParcel: PropTypes.bool
};

export default connect((state) => ({
  user: state.auth.user,
  accessorials: filterAccessorials(state.shipments.accessorialCodes)
}))(ShipmentAccessorialsField);
