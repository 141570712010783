import {useState} from 'react';
import PropTypes from 'prop-types';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import TenderRequestForm from 'App/containers/tendering/create';
import {checkShipmentModes} from 'App/utils/globalsTyped';

const TENDER_TO_CARRIER_TITLE = 'Tender To Carrier';

const TenderToCarrierAction = ({shipment}) => {
  const [showTenderToCarrierModal, setShowTenderToCarrierModal] = useState(false);

  const handleTenderToCarrierClick = () => setShowTenderToCarrierModal(true);

  const {hasFTL, hasLTL, hasDrayage, hasParcel, hasIntermodal, hasRail} = checkShipmentModes(shipment.mode);
  if (hasFTL || hasLTL || hasDrayage || hasParcel || hasIntermodal || hasRail) {
    return (
      <>
        <DeprecatedButton disabled={hasParcel} variant="text" onClick={handleTenderToCarrierClick}>
          {TENDER_TO_CARRIER_TITLE}
        </DeprecatedButton>
        <TenderRequestForm
          show={showTenderToCarrierModal}
          onClose={() => setShowTenderToCarrierModal(false)}
          //this attribute is not needed on shipment details, return true
          submitSucceeded
          shipmentDetailData={shipment}
          selectedCarrier={{
            id: shipment?.relationship_to_vendor?.vendor?.id,
            poc: shipment?.relationship_to_vendor?.vendor_point_of_contact
          }}
        />
      </>
    );
  }
  return null;
};

TenderToCarrierAction.propTypes = {
  shipment: PropTypes.object
};

TenderToCarrierAction.defaultProps = {
  shipment: {}
};

export default TenderToCarrierAction;
