import {object, string} from 'yup';
import {emptyStringToNull} from 'App/utils/yupHelpers';

export type CustomField = {
  id: string;
  label: string;
  default_value: string;
  required: boolean;
  company: unknown;
  allow_carrier_edit: boolean;
  allow_carrier_view: boolean;
  field_type?: string;
  allowed_values?: {
    created_at: string;
    id: string;
    label: string;
    updated_at: string;
    value: string;
  }[];
};

export const createValidationSchema = (customFields: CustomField[], companyId?: string | null) =>
  object().shape({
    bol_number: string().nullable().transform(emptyStringToNull).typeError('BOL # must be a string'),
    pro_number: string().nullable().transform(emptyStringToNull).typeError('PRO # must be a string'),
    pickup_number: string().nullable().transform(emptyStringToNull).typeError('Pickup # must be a string'),
    purchase_order_number: string().nullable().transform(emptyStringToNull).typeError('PO # must be a string'),
    tracking_number: string().nullable().transform(emptyStringToNull).typeError('Tracking # must be a string'),
    drayage_booking_number: string().nullable().transform(emptyStringToNull).typeError('Booking # must be a string'),
    drayage_container_number: string()
      .nullable()
      .transform(emptyStringToNull)
      .typeError('Container # must be a string'),
    drayage_seal_number: string().nullable().transform(emptyStringToNull).typeError('Seal # must be a string'),
    drayage_chassis_number: string().nullable().transform(emptyStringToNull).typeError('Chassis # must be a string'),
    customer_reference_number: string()
      .nullable()
      .transform(emptyStringToNull)
      .typeError('Customer Reference # must be a string'),
    MASTER_AIR_WAYBILL: string()
      .nullable()
      .transform(emptyStringToNull)
      .matches(/^[A-Z0-9]{3}-[0-9]{8}$/, 'Invalid Master Airway Bill #')
      .typeError('Master Airway Bill # must be a string'),
    custom_data: object().shape({
      shipwell_custom_data: object().shape({
        shipment: object().shape(
          customFields.reduce(
            (shape, cf) => ({
              ...shape,
              [cf.id]:
                cf.required && cf.company === companyId
                  ? string().nullable().required(`${cf.label} is required.`)
                  : string().nullable()
            }),
            {}
          )
        )
      })
    })
  });
