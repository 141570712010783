/* global $, google */
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change} from 'redux-form';
import {Button, OverlayTrigger, Popover, FormControl, ControlLabel, FormGroup} from 'react-bootstrap';
import {withRouter} from 'react-router';
import {compose} from 'recompose';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {bind} from 'App/utils/camelize';
import {fetchTags, clearTag, postTag, editTag, getTag, deleteTag} from 'App/actions/shipments';
import {tagColors, filterShipmentTags} from 'App/utils/globals';
import {tagIcon} from 'App/common/Assets';
import getNil from 'App/utils/getNil';
import {userHasDefaultShipmentTags} from 'App/utils/shipmentTags';
import './_shipmentTags.scss';

export class ShipmentTags extends Component {
  constructor(props) {
    super(props);

    bind(this, ['renderTagsDropdown', 'handleBack', 'onFormSubmit', 'handleClick', 'removeTag']);

    this.state = {
      showTagsDropdown: false,
      showCreate: false,
      showDelete: false,
      selectedName: '',
      searchTerm: '',
      selectedColor: {},
      selectedTags: [],
      colorblindMode: false,
      tags: [],
      isEdit: false
    };
  }

  shouldSetUserDefaultTags = () => {
    const {form, isEditForm, companyTags, defaultShipmentTags} = this.props;
    return (
      ['newShipmentForm', 'newQuoteForm'].includes(form) &&
      !isEditForm &&
      userHasDefaultShipmentTags({companyTags, defaultShipmentTags})
    );
  };

  setDefaultShipmentTags = () => {
    const {companyTags, defaultShipmentTags, dispatch, form} = this.props;
    const userDefaultShipmentTags = companyTags.filter(({id}) => defaultShipmentTags.includes(id));

    this.setState({selectedTags: uniqBy([...this.state.selectedTags, ...userDefaultShipmentTags], 'id')}, () =>
      dispatch(change(form, 'metadata.tags', this.state.selectedTags))
    );
  };

  async componentDidMount() {
    const {company, companyTags, fetchTags, formValues} = this.props;
    if (!isEmpty(company)) {
      try {
        const response = await fetchTags();
        if (response.status === 200) {
          this.setState({tags: this.filterShipmentTags(companyTags, this.state.selectedTags)});
        }
      } catch (e) {
        console.error(e);
      }

      // tag values can be id strings or tag objects...if it's an id,
      // map it to it's corresponding tag object before setting it in state
      if (!isEmpty(companyTags) && getNil(formValues, 'metadata.tags', []).length > 0) {
        this.setState({
          selectedTags: formValues.metadata.tags.map((tag) =>
            tag?.id ? tag : companyTags.find((tagObj) => tagObj?.id === tag)
          )
        });
      }
      if (this.shouldSetUserDefaultTags()) {
        this.setDefaultShipmentTags();
      }
    }

    if (localStorage.getItem('colorblindMode')) {
      this.setState({colorblindMode: true});
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {company, companyTags, formValues, fetchTags} = this.props;
    if (company?.id !== prevProps.company?.id && !isEmpty(company)) {
      try {
        const response = await fetchTags();
        if (response.status === 200) {
          this.setState({tags: this.filterShipmentTags(companyTags, this.state.selectedTags)});
        }
      } catch (e) {
        console.error(e);
      }
    }

    // update selected tags if company tags update and user did not manually select tags
    if (
      !isEmpty(company) &&
      isEmpty(this.state.selectedTags) &&
      isEmpty(prevProps.companyTags) &&
      !isEmpty(companyTags) &&
      getNil(formValues, 'metadata.tags', []).length > 0
    ) {
      this.setState({
        selectedTags: formValues.metadata.tags.map((tag) =>
          tag?.id ? tag : companyTags.find((tagObj) => tagObj?.id === tag)
        )
      });
    }

    // if tag values in props have changed and they do not equal tag values in state, update state with the new tag values in props
    // tag values can be id strings or tag objects...if it's an id, map it to it's corresponding tag object before setting it in state
    if (
      !isEqual(getNil(prevProps, 'formValues.metadata.tags', []), getNil(this.props, 'formValues.metadata.tags', [])) &&
      !isEqual(
        getNil(this.props, 'formValues.metadata.tags', []),
        this.state.selectedTags && getNil(formValues, 'metadata.tags', []).length > 0
      )
    ) {
      const tagsToUpdate = getNil(this.props, 'formValues.metadata.tags', []).map((tag) =>
        tag?.id ? tag : companyTags?.find((ct) => ct?.id === tag)
      );
      this.setState({
        selectedTags: tagsToUpdate || []
      });
    }
    if (companyTags && this.state.selectedTags !== prevState.selectedTags) {
      this.setState({
        tags: this.filterShipmentTags(this.state.tags, this.state.selectedTags, this.state.searchTerm)
      });
    }
  }

  handleClick(e) {
    const popover = document.getElementsByClassName('popover-content')[0];
    if (popover && popover.contains(e.target)) {
      return;
    }
    this.setState(
      {
        showTagsDropdown: false,
        showCreate: false,
        showDelete: false,
        selectedColor: {},
        selectedName: '',
        colorError: '',
        nameError: '',
        isEdit: false
      },
      function () {
        if (this.props.companyTags && getNil(this.props, 'formValues.metadata.tags', []).length > 0) {
          this.setState({
            tags: this.filterShipmentTags(this.props.companyTags, this.state.selectedTags)
          });
        }
      }
    );
  }

  filterShipmentTags(tags, selectedTags, searchTerm) {
    return filterShipmentTags(
      tags,
      selectedTags.map((tag) => tag?.id ?? tag),
      searchTerm
    );
  }

  handleBack() {
    this.setState({
      showCreate: false,
      showDelete: false,
      selectedColor: {},
      selectedName: '',
      nameError: '',
      colorError: '',
      isEdit: false
    });
    this.props.clearTag();
  }

  onFormSubmit(attrs) {
    if (!this.state.isEdit) {
      return this.props.postTag(attrs).then((response) => {
        if (response.status === 200) {
          this.props.fetchTags().then((response) => {
            if (response.status === 200) {
              this.setState({
                tags: this.filterShipmentTags(this.props.companyTags, this.state.selectedTags)
              });
            }
            this.setState({
              selectedColor: {},
              selectedName: '',
              showCreate: false,
              showDelete: false,
              colorError: '',
              nameError: '',
              showTagsDropdown: true
            });
          });
        } else {
          if (response.field_errors && Array.isArray(response.field_errors) && response.field_errors.length > 0) {
            for (let i = 0; i < response.field_errors.length; i++) {
              if (response.field_errors[i].field_name) {
                if (
                  response.field_errors[i].field_name === 'name' &&
                  response.field_errors[i].field_errors.length > 0
                ) {
                  if (response.field_errors[i].field_errors.length > 0) {
                    this.setState({
                      nameError: response.field_errors[i].field_errors[0]
                    });
                  }
                }
                if (
                  response.field_errors[i].field_name === 'color' &&
                  response.field_errors[i].field_errors.length > 0
                ) {
                  if (response.field_errors[i].field_errors.length > 0) {
                    this.setState({
                      colorError: response.field_errors[i].field_errors[0]
                    });
                  }
                }
              }
            }
          } else if (
            response.error_description &&
            Array.isArray(response.error_description) &&
            response.error_description.length > 0
          ) {
            if (response.error_description[0].includes('name')) {
              this.setState({nameError: response.error_description[0]});
            }
          } else {
            if (response.error_description.includes('name')) {
              this.setState({nameError: response.error_description});
            }
          }
        }
      });
    }
    return this.props.editTag(this.props.tag.id, attrs).then((response) => {
      if (response.status === 200) {
        this.props.fetchTags().then((response) => {
          this.props.fetchTags().then((response) => {
            this.setState({
              showCreate: false,
              selectedColor: {},
              selectedName: '',
              isEdit: false,
              tags: filterShipmentTags(this.props.companyTags, this.state.selectedTags)
            });
          });
        });
      } else {
        if (response.field_errors && Array.isArray(response.field_errors) && response.field_errors.length > 0) {
          for (let i = 0; i < response.field_errors.length; i++) {
            if (response.field_errors[i].field_name) {
              if (response.field_errors[i].field_name === 'name' && response.field_errors[i].field_errors.length > 0) {
                this.setState({
                  nameError: response.field_errors[i].field_errors[0]
                });
              }
              if (response.field_errors[i].field_name === 'color' && response.field_errors[i].field_errors.length > 0) {
                this.setState({
                  colorError: response.field_errors[i].field_errors[0]
                });
              }
            }
          }
        } else if (
          response.error_description &&
          Array.isArray(response.error_description) &&
          response.error_description.length > 0
        ) {
          if (response.error_description[0].includes('name')) {
            this.setState({nameError: response.error_description[0]});
          }
        }
      }
    });
  }

  removeTag() {
    const selectedTags = JSON.parse(JSON.stringify(this.state.selectedTags));
    let idx = -1;
    for (let i = 0; i < selectedTags.length; i++) {
      if (this.props.tag.id === selectedTags[i].id) {
        idx = i;
      }
    }
    if (idx !== -1) {
      selectedTags.splice(idx, 1);
    }
    this.setState(
      {selectedTags: selectedTags},
      function () {
        this.props.dispatch(change(this.props.form, 'metadata', {tags: selectedTags}));
        this.props.clearTag();
        this.props.fetchTags().then((response) => {
          if (response.status === 200) {
            this.setState({showDelete: false});
          }
        });
      }.bind(this)
    );
  }

  renderTagsDropdown() {
    return !this.state.showCreate && !this.state.showDelete ? (
      <div className="tags-dropdown" ref={(node) => (this.node = node)}>
        <FormControl
          placeholder={'Search for a tag'}
          onChange={(e) => {
            this.setState({searchTerm: e.target.value});
            if (e.target.value.length < 1) {
              if (getNil(this.props, 'formValues.metadata.tags', []).length > 0) {
                this.setState({
                  tags: this.filterShipmentTags(this.props.companyTags, this.state.selectedTags)
                });
              } else {
                const tags = this.props.companyTags;
                this.setState({
                  tags: tags
                });
              }
            } else {
              const tags = this.filterShipmentTags(this.props.companyTags, this.state.selectedTags, e.target.value);
              this.setState({
                tags: tags
              });
            }
          }}
        />
        <div className="tag-list">
          {this.state.tags.map((el) => {
            return (
              <div
                key={el.id}
                className={'tag-container tag-option' + (el.color ? ' ' + el.color : '')}
                onClick={() => {
                  const selectedTags = JSON.parse(JSON.stringify(this.state.selectedTags));
                  let index = -1;
                  for (let i = 0; i < selectedTags.length; i++) {
                    if (selectedTags[i].id === el.id || selectedTags[i] === el.id) {
                      index = i;
                    }
                  }
                  if (index === -1) {
                    selectedTags.push(el);
                  } else {
                    selectedTags.splice(index, 1);
                  }
                  this.setState(
                    {selectedTags: selectedTags},
                    function () {
                      this.props.dispatch(
                        change(this.props.form, 'metadata', {
                          tags: selectedTags
                        })
                      );
                    }.bind(this)
                  );
                }}
              >
                <div
                  className={
                    'tag-container tag-option blind-container' +
                    (el.color ? ' ' + el.color : '') +
                    (this.state.colorblindMode ? ' blind' : '')
                  }
                  style={{width: this.state.colorblindMode ? '' : '5px'}}
                >
                  &nbsp;
                </div>
                <div className="tag">
                  <strong>{el.name}</strong>
                </div>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="top"
                  overlay={
                    <Popover id="shipment-tags-actions">
                      <div>
                        <Button
                          bsStyle="link"
                          onClick={() => {
                            this.props.getTag(el.id).then((response) => {
                              if (response.status === 200) {
                                let color;
                                for (let i = 0; i < tagColors.length; i++) {
                                  if (this.props.tag && this.props.tag.color === tagColors[i].name) {
                                    color = tagColors[i];
                                  }
                                }
                                this.setState({
                                  showCreate: true,
                                  selectedColor: this.props.tag ? color : '',
                                  selectedName: this.props.tag ? this.props.tag.name : '',
                                  isEdit: true
                                });
                              }
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                      <div>
                        <Button
                          bsStyle="link"
                          onClick={() => {
                            this.props.getTag(el.id).then((response) => {
                              if (response.status === 200) {
                                this.setState({
                                  showDelete: true
                                });
                              }
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </Popover>
                  }
                >
                  <div
                    className="context-menu"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </OverlayTrigger>
              </div>
            );
          })}
        </div>
        <div className="new-tag">
          <a
            onClick={() => {
              this.setState({
                showCreate: true,
                selectedColor: {id: '#58595b', name: 'bright_grey'}
              });
            }}
          >
            Create a tag
          </a>
        </div>
        {/* show edit default tags if shipment saved and not consolidating orders */}
        {this.props.params?.shipment_id && !this.props.router.location.query.ordersConsolidated ? (
          <div className="edit-tag">
            <a
              onClick={() =>
                this.props.router.push(
                  `/user/user-defaults?${queryString.stringify({
                    shipmentRedirect: this.props.params.shipment_id,
                    confirmShipment: getNil(this.props, 'router.location.query.mode', false) ? true : null,
                    confirmationType: getNil(this.props, 'router.location.query.mode', false)
                      ? this.props.router.location.query.mode
                      : null
                  })}`
                )
              }
            >
              Edit Default Tags
            </a>
          </div>
        ) : (
          ''
        )}
        <div className="new-tag">
          <a
            onClick={() => {
              if (localStorage.getItem('colorblindMode')) {
                localStorage.removeItem('colorblindMode');
                this.setState({colorblindMode: false});
              } else {
                localStorage.setItem('colorblindMode', 'true');
                this.setState({colorblindMode: true});
              }
            }}
          >
            {this.state.colorblindMode ? 'Disable colorblind mode' : 'Enable colorblind mode'}
          </a>
        </div>
      </div>
    ) : this.state.showCreate && !this.state.showDelete ? (
      <div className="tags-dropdown">
        <FormGroup validationState={this.state.colorError ? 'error' : null}>
          <span className="dropdown-label">
            <ControlLabel>
              Color
              <sup>*</sup>
            </ControlLabel>
          </span>
          <div className="tag-color">
            {tagColors &&
              tagColors.map((el) => {
                return (
                  <div
                    key={el.name}
                    className={
                      'tag-color-square' + (el.name ? ' ' + el.name : '') + (this.state.colorblindMode ? ' blind' : '')
                    }
                    onClick={() => {
                      this.setState({selectedColor: el, colorError: ''});
                    }}
                  >
                    &nbsp;
                  </div>
                );
              })}
          </div>
          {this.state.colorError && (
            <p className="error-text">
              <i className="icon icon-Delayed" />
              {this.state.colorError}
            </p>
          )}
        </FormGroup>
        <FormGroup validationState={this.state.nameError ? 'error' : null}>
          <ControlLabel>
            Name
            <sup>*</sup>
          </ControlLabel>
          <FormControl
            placeholder="Enter a name"
            value={this.state.selectedName}
            onChange={(e) => {
              this.setState({selectedName: e.target.value, nameError: ''});
            }}
          />
          {this.state.nameError && (
            <p className="error-text">
              <i className="icon icon-Delayed" />
              {this.state.nameError}
            </p>
          )}
        </FormGroup>
        {(this.state.selectedColor || this.state.selectedName) && <ControlLabel>Preview</ControlLabel>}
        {(this.state.selectedColor || this.state.selectedName) && (
          <div className={'tag-preview' + (this.state.selectedColor.name ? ' ' + this.state.selectedColor.name : '')}>
            <div
              className={
                'tag-preview blind-container' +
                (this.state.selectedColor.name ? ' ' + this.state.selectedColor.name : '') +
                (this.state.colorblindMode ? ' blind' : '')
              }
              style={{width: this.state.colorblindMode ? '' : '5px'}}
            >
              &nbsp;
            </div>
            <div>
              <strong
                style={{
                  color: this.state.selectedColor ? '' : 'black'
                }}
              >
                {this.state.selectedName ? this.state.selectedName : 'Example Text'}
              </strong>
            </div>
          </div>
        )}
        <div className="footer-btn">
          <Button
            bsStyle="default"
            onClick={() => {
              this.handleBack();
            }}
          >
            Back
          </Button>
          <Button
            disabled={!this.state.selectedColor || isEmpty(this.state.selectedColor) || !this.state.selectedName}
            onClick={() => {
              this.onFormSubmit({
                color: this.state.selectedColor.name,
                name: this.state.selectedName
              });
            }}
            bsStyle="primary"
          >
            Save
          </Button>
        </div>
      </div>
    ) : (
      <div className="tags-dropdown">
        <div>
          Are you sure you want to delete {this.props.tag.name}? Deleting this tag will remove it from all shipments.
        </div>
        <div className="footer-btn">
          <Button
            onClick={() => {
              this.handleBack();
            }}
          >
            Back
          </Button>
          <DeprecatedButton
            variant="icon"
            bsStyle="primary"
            onClick={function () {
              this.props.deleteTag(this.props.tag.id).then((response) => {
                if (response.status === 200) {
                  this.removeTag();
                  this.props.fetchTags().then((response) => {
                    if (response.status === 200) {
                      this.setState({
                        tags: this.filterShipmentTags(this.props.companyTags, this.state.selectedTags)
                      });
                    }
                  });
                }
              });
            }.bind(this)}
          >
            <SvgIcon name="TrashOutlined" className="pad-right" />
            Delete
          </DeprecatedButton>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClick} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <div className="shipment-tags">
          <div className="tags-container">
            <div className="tags-container-icon">{tagIcon()}</div>
            <div
              className="tags"
              onClick={() => {
                if (!this.state.showTagsDropdown) {
                  this.setState({
                    searchTerm: '',
                    tags: this.filterShipmentTags(this.props.companyTags, this.state.selectedTags)
                  });
                }
                this.setState({showTagsDropdown: !this.state.showTagsDropdown});
              }}
            >
              {this.state.selectedTags && this.state.selectedTags.length > 0
                ? this.state.selectedTags.map((el) => {
                    let tag;
                    if (!el?.id) {
                      if (this.props.companyTags) {
                        for (let i = 0; i < this.props.companyTags.length; i++) {
                          if (el === this.props.companyTags[i].id) {
                            tag = this.props.companyTags[i];
                          }
                        }
                      }
                    }
                    return (
                      <div
                        key={tag ? tag?.id : el?.id ? el?.id : el}
                        className={'tag tag-option' + (tag ? ' ' + tag?.color : el?.color ? ' ' + el.color : '')}
                      >
                        <div
                          className={
                            'tag  blind-container' +
                            (tag ? ' ' + tag?.color : el?.color ? ' ' + el.color : '') +
                            (this.state.colorblindMode ? ' blind' : '')
                          }
                          style={{width: this.state.colorblindMode ? '' : '5px'}}
                        >
                          &nbsp;
                        </div>
                        <div>{tag ? tag?.name : el?.name}</div>
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const selectedTags = JSON.parse(JSON.stringify(this.state.selectedTags));
                            let idx = -1;
                            for (let i = 0; i < selectedTags.length; i++) {
                              if ((tag && tag.id === selectedTags[i].id) || el.id === selectedTags[i].id) {
                                idx = i;
                              }
                            }

                            const removedTag = selectedTags[idx];
                            if (idx !== -1) {
                              selectedTags.splice(idx, 1);
                            }

                            this.setState(
                              (prevState) => ({
                                tags: [...prevState.tags, removedTag],
                                selectedTags: selectedTags
                              }),
                              function () {
                                this.props.dispatch(
                                  change(this.props.form, 'metadata', {
                                    tags: selectedTags
                                  })
                                );
                              }.bind(this)
                            );
                          }}
                        >
                          <i className="icon icon-x-small pad-left" />
                        </div>
                      </div>
                    );
                  })
                : 'Select tags'}
            </div>
            {this.state.showTagsDropdown && this.renderTagsDropdown()}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

ShipmentTags.propTypes = {
  companyTags: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string})),
  form: PropTypes.string,
  isEditForm: PropTypes.bool,
  defaultShipmentTags: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func,
  company: PropTypes.object,
  fetchTags: PropTypes.func,
  clearTag: PropTypes.func,
  postTag: PropTypes.func,
  editTag: PropTypes.func,
  getTag: PropTypes.func,
  deleteTag: PropTypes.func,
  formValues: PropTypes.shape({
    metadata: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.shape({id: PropTypes.string}), PropTypes.string]))
    })
  }),
  router: PropTypes.shape({location: PropTypes.shape({query: PropTypes.object}), push: PropTypes.func}),
  params: PropTypes.shape({shipment_id: PropTypes.string}),
  tag: PropTypes.shape({id: PropTypes.string, color: PropTypes.string, name: PropTypes.string})
};

const mapStateToProps = (state) => {
  return {
    company: state.auth.company,
    companyTags: getNil(state, 'shipments.tags', []),
    tag: state.shipments.tag,
    defaultShipmentTags: getNil(state, 'auth.user.default_shipment_tags', [])
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({fetchTags, clearTag, postTag, editTag, getTag, deleteTag}, dispatch);
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(ShipmentTags);
