import {Field} from 'redux-form';
import {Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {preferredCurrencyOptions} from './utils';
import SelectField from 'App/formComponents/fields/select';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';

export const CurrencyOfRecordField = () => {
  const {stmShipmentCurrencyOfRecord} = useFlags();
  const {data: internationalPreferences} = useGetInternationalPreferences();
  if (!stmShipmentCurrencyOfRecord) return null;
  return (
    <div className="grid grid-cols-[1fr_min-content] gap-1">
      <Field
        label="Currency of Record"
        component={SelectField}
        options={preferredCurrencyOptions}
        backspaceRemovesValue={false}
        name="preferred_currency"
        id="preferred_currency"
        simpleValue
        required
        disabled={!internationalPreferences?.allow_currency_change}
      />
      <div className="ml-2 mt-6">
        <Tooltip
          tooltipContent={
            <div className="max-w-xs">
              <div className="font-bold">Currency of Record</div>
              <div>
                This will be the currency used for this shipment and all parties will view the shipment in this
                currency. It cannot be changed after shipment creation.
              </div>
            </div>
          }
          placement="left"
          trigger="hover"
          wrapperClassname="tooltipWrapper"
          tooltipClassname="tooltipContent"
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </div>
    </div>
  );
};
