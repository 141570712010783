// this file should go away after we add a carrier metadata endpoint to genesis
import {ProviderCode, ShipmentMode} from '@shipwell/genesis-sdk';

const chrAllowedModes = [ShipmentMode.Ftl, ShipmentMode.Ltl];
const parcelAllowedModes = [ShipmentMode.Parcel];

export const allowedModesByProvider: Record<string, ShipmentMode[]> = {
  [ProviderCode.Chrobinson]: chrAllowedModes,
  [ProviderCode.Purolator]: parcelAllowedModes,
  [ProviderCode.Canadapost]: parcelAllowedModes,
  [ProviderCode.Canpar]: parcelAllowedModes
};

export const getAllowedModesByProvider = (providerCode?: ProviderCode) =>
  providerCode && allowedModesByProvider[providerCode as keyof typeof allowedModesByProvider];
