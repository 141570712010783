import {
  DistanceResponse,
  Configuration,
  LocationsApi,
  Address,
  AddressBookEntry,
  LocationsApiAddressBookGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createAddressBookApi() {
  return new LocationsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

export function getAddressBookAddresses(options?: LocationsApiAddressBookGetRequest) {
  const {page, pageSize, ordering, q} = options || {};
  return createAddressBookApi().addressBookGet({page, pageSize, ordering, q});
}

export async function getAddressBookAddressById(addressBookId: string) {
  return createAddressBookApi().addressBookAddressBookIdGet({addressBookId});
}

export function updateAddressBookAddressById(addressBookParams: {
  addressBookId: string;
  addressBookEntry: AddressBookEntry;
}) {
  const {addressBookId, addressBookEntry} = addressBookParams;
  return createAddressBookApi().addressBookAddressBookIdPut({addressBookId, addressBookEntry});
}

export function createAddressBookAddress(addressBookEntry: AddressBookEntry) {
  return createAddressBookApi().addressBookPost({addressBookEntry});
}

export function deleteAddressBookAddressById(addressBookId: string) {
  return createAddressBookApi().addressBookAddressBookIdDelete({addressBookId});
}

interface Distance extends DistanceResponse {
  distance_miles: number;
}
export async function getDistanceBetweenStops(
  distanceRequest: {origin: Address; destination: Address},
  companyId?: string
) {
  const config = companyId ? {headers: {'X-Company-ID': companyId}} : undefined;
  const axiosResponse = await createAddressBookApi().locationsDistancePost({distanceRequest}, config);
  return axiosResponse.data as Distance;
}

export async function createAddressBook(addressBookEntry: AddressBookEntry) {
  return createAddressBookApi().addressBookPost({addressBookEntry});
}

export function getLocationTypes() {
  return createAddressBookApi().locationTypesGet();
}
