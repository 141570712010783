import {GenesisRatingSchemasRatingRateRequest} from '@shipwell/genesis-sdk';
import {listRateRequests} from 'App/api/genesis/typed';

/**
 * Retrieve the most recent rate execute
 * @param rates
 */
const getMostRecentRateGenesis = (rates: GenesisRatingSchemasRatingRateRequest[]) => {
  if (!rates.length) return null;

  return rates.sort((a, b) => {
    const aDate = new Date(a.created_at);
    const bDate = new Date(b.created_at);

    return bDate.getTime() - aDate.getTime();
  })[0];
};

/**
 * check exist the most recent rate
 * @param shipmentId
 */
export const getRateRequests = async (shipmentId: string) => {
  try {
    const response = await listRateRequests({shipmentId});
    return getMostRecentRateGenesis(response.data);
  } catch (e) {
    console.error(e);
  }
};
