import {TiveCredentials} from '@shipwell/locus-sdk';
import {listDevices} from 'App/api/locus/tracking';
import {trackingProviderCredentialsList} from 'App/api/locus/trackingCredentials';

export async function getTiveCredentials() {
  const response = await trackingProviderCredentialsList({
    provider: 'tive'
  });

  // This cast is necessary because of the way the
  // CredentialsOutput object is inferred. Without this approach,
  // accessing `credentials?.accounts` would not recognize
  // `accounts` as a property of the filtered object.
  const credentials = response.data.find((item) => item.provider === 'tive') as TiveCredentials | undefined;
  const defaultAccount = credentials?.accounts?.at(0);

  return {
    credentials,
    defaultAccount
  };
}

export async function isTiveConnected() {
  const response = await getTiveCredentials();
  return !!response.credentials;
}

export async function tryGetDefaultTiveAccount() {
  const credentials = await getTiveCredentials();

  if (!credentials) throw new Error('Could not get Tive devices, because Tive is not connected.');

  if (!credentials.defaultAccount) throw new Error('Could not find a default Tive account.');

  return credentials.defaultAccount;
}

export async function getTiveDevices() {
  const defaultAccount = await tryGetDefaultTiveAccount();

  const response = await listDevices({provider: 'tive'}, defaultAccount.id);
  if (response?.total_count && response.total_count > response.data.length) {
    const totalRequests = Math.ceil(response.total_count / response.data.length);

    const requests = await Promise.all(
      Array.from({length: totalRequests - 1}, (_, index) =>
        listDevices({provider: 'tive'}, defaultAccount.id, index + 2)
      )
    );
    const allData = [...response.data];

    requests.forEach((request) => {
      allData.push(...request.data);
    });

    return allData;
  }

  return response.data;
}
