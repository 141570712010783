import moment from 'moment';

// start and duration have to be valid date time strings
// returns time remaining in seconds
export const getSecondsRemaining = (start?: string | null, duration?: string): number => {
  const endTime = moment(start).add(duration, 'seconds');
  const current = moment();
  const timeRemainingAsSecs = moment.duration(endTime.diff(current)).asSeconds();
  return timeRemainingAsSecs;
};
