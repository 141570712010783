import findIndex from 'lodash/findIndex';
import {
  ProviderCode,
  CreateCapacityProviderAccountRequestPayloadSchema,
  CapacityProviderAccountSchema,
  ResponseAddProviderAccountProvidersProviderCodePost,
  ShipwellApiErrorResponse,
  CapacityProviderSchema,
  ResponseUpdateProviderAccountProvidersProviderCodeAccountIdPut,
  CreateCapacityProviderSchemaAccountsInner,
  ShipmentMode
} from '@shipwell/genesis-sdk';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  createProviderAccount,
  getProviderCapacityProvider,
  deleteProviderAccount,
  deleteCapacityProvider,
  createCapacityProviderWithAccount,
  updateCapacityProviderAccount
} from 'App/api/genesis/typed';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {CAPACITY_PROVIDER_ACCOUNTS, DELETE_CAPACITY_PROVIDER_ACCOUNT} from 'App/data-hooks/queryKeys';
import {getAllowedModesByProvider} from 'App/data-hooks/integrations/utils/utils';

export const useCapacityProviderAccounts = ({
  providerCode,
  setError,
  setSuccess
}: {providerCode?: ProviderCode} & Partial<WithStatusToastProps>) => {
  const queryClient = useQueryClient();

  const getCapacityProviderAccountPayload = (values: Partial<Omit<CapacityProviderAccountSchema, 'modes'>>) => {
    const {notes} = values;

    return {
      ...values,
      provider_code: providerCode,
      notes: notes || null
    };
  };

  const handleUpdateQueryData = (
    data:
      | ResponseAddProviderAccountProvidersProviderCodePost
      | ResponseUpdateProviderAccountProvidersProviderCodeAccountIdPut
  ) =>
    // set queryData to avoid the card moving away from the user's focus
    queryClient.setQueryData([CAPACITY_PROVIDER_ACCOUNTS, providerCode], (currentData?: CapacityProviderSchema) => {
      const accountsCopy = [...((currentData?.accounts as Omit<CapacityProviderAccountSchema, 'modes'>[]) || [])];
      const accountIndex = findIndex(accountsCopy, (account) => account.id === data.id);

      const isUpdate = accountIndex !== -1;

      if (isUpdate) {
        accountsCopy.splice(accountIndex, 1, data);
        return {
          ...(currentData || {}),
          accounts: accountsCopy
        } as CapacityProviderSchema;
      }

      return {
        ...(currentData || {}),
        accounts: [data, ...accountsCopy]
      } as CapacityProviderSchema;
    });

  const handleError = ({response}: AxiosError<ShipwellApiErrorResponse>) => {
    console.error(response?.data);
    const {errors} = response?.data || {};
    setError?.('Unable to Create Account', errors?.[0].detail || 'Unknown error.');
  };

  // Use this if no capacity provider exists and we need to create one AND add an account
  const {mutateAsync: createProviderWithAccount} = useMutation<
    CapacityProviderSchema,
    AxiosError<ShipwellApiErrorResponse>,
    CreateCapacityProviderSchemaAccountsInner
  >((values) => createCapacityProviderWithAccount(values), {
    onSuccess: () => queryClient.invalidateQueries([CAPACITY_PROVIDER_ACCOUNTS, providerCode]),
    onError: handleError
  });

  // Use this if capacity provider exists and we need to add an account
  const {mutateAsync: addAccountToProvider} = useMutation<
    ResponseAddProviderAccountProvidersProviderCodePost,
    AxiosError<ShipwellApiErrorResponse>,
    CreateCapacityProviderAccountRequestPayloadSchema
  >(
    (createCapacityProviderAccountRequestPayloadSchema) => {
      if (!providerCode) {
        throw new Error('providerCode is required.');
      }
      return createProviderAccount({providerCode, createCapacityProviderAccountRequestPayloadSchema});
    },
    {
      onSuccess: (data) => {
        handleUpdateQueryData(data);
        setSuccess?.('Success!', 'Account successfully connected.');
      },
      onError: handleError
    }
  );

  // Master create account mutation that chooses the correct request (dependent on having an existing provider)
  const {mutateAsync: createCapacityProviderAccount} = useMutation<
    ResponseAddProviderAccountProvidersProviderCodePost | CapacityProviderSchema,
    AxiosError<ShipwellApiErrorResponse>,
    {values: Partial<Omit<CapacityProviderAccountSchema, 'modes'>>}
  >(({values}: {values: Partial<Omit<CapacityProviderAccountSchema, 'modes'>>}) => {
    const payload = getCapacityProviderAccountPayload(values);

    if (hasNoCapacityProvider) {
      return createProviderWithAccount(payload as CreateCapacityProviderSchemaAccountsInner);
    }

    if (!providerCode) throw new Error('providerCode is required.');

    const createCapacityProviderAccountRequestPayloadSchema = {
      [providerCode.toLowerCase()]: payload
    };
    return addAccountToProvider(createCapacityProviderAccountRequestPayloadSchema);
  });

  // Get capacity provider, returns 404 if one has not been created
  const {
    data: capacityProviderAccounts,
    isFetching: isFetchingProviderAccounts,
    isError: hasNoCapacityProvider
  } = useQuery(
    [CAPACITY_PROVIDER_ACCOUNTS, providerCode],
    () => {
      if (!providerCode) {
        throw new Error('providerCode is required.');
      }

      return getProviderCapacityProvider(providerCode);
    },
    {
      retry: false,
      enabled: !!providerCode
    }
  );

  // Update a single capacity provider's account
  const {mutateAsync: updateProviderAccount} = useMutation<
    ResponseUpdateProviderAccountProvidersProviderCodeAccountIdPut,
    AxiosError<ShipwellApiErrorResponse>,
    {accountId: string; values: Partial<Omit<CapacityProviderAccountSchema, 'modes'>>}
  >(
    ({accountId, values}: {accountId: string; values: Partial<Omit<CapacityProviderAccountSchema, 'modes'>>}) => {
      if (!providerCode) {
        throw new Error('providerCode is required.');
      }

      const createCapacityProviderAccountRequestPayloadSchema = {
        [providerCode.toLowerCase()]: getCapacityProviderAccountPayload(values)
      };

      return updateCapacityProviderAccount({
        providerCode,
        accountId,
        createCapacityProviderAccountRequestPayloadSchema
      });
    },
    {
      onSuccess: (data) => {
        handleUpdateQueryData(data);
        setSuccess?.('Success!', 'Account successfully updated.');
      },
      onError: handleError
    }
  );

  // Delete a provider account by ID
  const {mutate: deleteCapacityProviderAccount, isLoading: isDeleting} = useMutation(
    (id: string) => {
      if (!providerCode) {
        throw new Error('providerCode is required.');
      }

      return deleteProviderAccount({providerCode, id});
    },
    {
      mutationKey: [DELETE_CAPACITY_PROVIDER_ACCOUNT],
      onSuccess: () => queryClient.invalidateQueries([CAPACITY_PROVIDER_ACCOUNTS])
    }
  );

  // DELETE A CAPACITY PROVIDER AND ALL ACCOUNTS
  // DO NOT USE UNLESS YOU REALLY MEAN IT
  const {mutate: deleteCapacityProviderAndAccounts} = useMutation(
    () => {
      if (!providerCode) {
        throw new Error('providerCode is required.');
      }

      return deleteCapacityProvider(providerCode);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData([CAPACITY_PROVIDER_ACCOUNTS, providerCode], {});
        void queryClient.invalidateQueries([CAPACITY_PROVIDER_ACCOUNTS]);
      }
    }
  );

  const allowedModes =
    (capacityProviderAccounts?.modes as ShipmentMode[] | undefined) || getAllowedModesByProvider(providerCode) || [];

  return {
    createCapacityProviderAccount,
    deleteCapacityProviderAccount,
    connections: capacityProviderAccounts?.accounts as CapacityProviderAccountSchema[] | undefined,
    allowedModes,
    isFetchingProviderAccounts,
    deleteCapacityProviderAndAccounts,
    isDeleting,
    updateProviderAccount,
    hasNoCapacityProvider
  };
};
