/* eslint-disable react/prop-types */
/* global google */
import {Component} from 'react';
import isEmpty from 'lodash/isEmpty';
import {FormikTypeahead, SvgIcon, FormikTextInput} from '@shipwell/shipwell-ui';
import AddressVerification from 'App/components/addressVerification';
import {loadGoogleMapsAPI} from 'App/utils/globals';
import {getThreeDigitPostalCodes} from 'App/utils/addressSearchUtils';
import {getAddressBookAddresses} from 'App/api/addressBook/typed';
import {getSupplierAddresses} from 'App/api/suppliers';

const CUSTOM_MENU_OPTION_TEXT = {
  MANUAL: 'Manually Enter an Address',
  ADDRESS_BOOK: 'Create New Address Book Entry'
};

const maxResults = 5;
const minResultsPerType = 1;
const maxResultsPerType = 3;

/**
 * Address Search Field
 * @description Search from address book, google maps or both
 * if You change this file you should also update __mocks__/mockAddress.js
 */
class AddressSearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showManualAddressModal: false
    };
    this.searchAddresses = this.searchAddresses.bind(this);
    this.handleCustomMenuOption = this.handleCustomMenuOption.bind(this);
  }
  static defaultProps = {
    searchMaps: true,
    searchAddressBook: true,
    searchThreeDigitPostalCode: false,
    placeholder: 'Address',
    icon: 'icon-Pointer',
    customMenuOptionType: 'MANUAL',
    additionalValues: {}
  };

  componentDidMount() {
    /** Need bound GMaps object */
    if (window.google) {
      this.initGoogleMaps();
    } else {
      loadGoogleMapsAPI(() => this.initGoogleMaps());
    }
  }

  initGoogleMaps() {
    this.autocomplete = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  /**
   * Get formatted address is selected address is from Googles predicted places
   * @param  {Object} place.value Selected place
   */
  async handlePlaceSelection(place) {
    if (place && place.value && place.value.place_id) {
      const placeDetails = await new Promise((resolve, reject) => {
        this.placeService.getDetails({placeId: place.value.place_id}, (response, status) => {
          if (status === 'OK' || status === 'ZERO_RESULTS') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      return {
        label: placeDetails.formatted_address,
        value: placeDetails
      };
    }
    return place;
  }

  /**
   * Request place predictions from Google Mapa
   * @param  {String} value Place search input
   * @return {Promise}
   */
  getPlacePredictions(value, types = ['geocode', 'establishment']) {
    return new Promise((resolve, reject) => {
      this.autocomplete.getPlacePredictions({input: value, types: types}, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(status);
        }
      });
    });
  }

  /**
   * Request AddressBook entried
   * @param  {String} value    Place search input
   * @param  {Number} pageSize Number of results
   * @return {Promise}
   */
  getAddressBookPromise(value, pageSize) {
    const {supplierId} = this.props;
    return supplierId
      ? getSupplierAddresses({q: value, pageSize: pageSize, supplierId})
      : getAddressBookAddresses({q: value, pageSize: pageSize});
  }

  formatGoogleResults(results = []) {
    return results.map((result) => ({
      value: result,
      label: result.description
    }));
  }

  formatAddressBookResults(results = []) {
    return results.map((result) => ({
      value: {...result.address, ...result},
      label: result.address.formatted_address,
      name: result.company_name || result.location_name,
      icon: true
    }));
  }

  formatThreeDigitPostalCodeResults(results = []) {
    return results.map((result) => ({
      value: {
        city: result.city,
        state_province: result.state,
        postal_code: result.id,
        country: 'US',
        formatted_address: `${result.id}: ${result.city}, ${result.state}, US`
      },
      label: [result.city, result.state].join(', '),
      name: result.id,
      zipIcon: true
    }));
  }

  async getAddressResults(value, ignoreAddressBook = false) {
    const {searchMaps, searchAddressBook, searchThreeDigitPostalCode} = this.props;
    if (value) {
      try {
        const [googleResults, addressBookResults] = await Promise.allSettled([
          searchMaps && this.getPlacePredictions(value),
          searchAddressBook && !ignoreAddressBook && this.getAddressBookPromise(value, maxResults)
        ]);
        const threeDigitPostalCodeResults = searchThreeDigitPostalCode && getThreeDigitPostalCodes(value);
        return {
          googleResults: googleResults?.value ? this.formatGoogleResults(googleResults.value) : [],
          addressBookResults: addressBookResults?.value?.data?.results
            ? this.formatAddressBookResults(addressBookResults.value.data.results)
            : addressBookResults?.value?.data?.data
            ? this.formatAddressBookResults(addressBookResults?.value?.data?.data)
            : [],
          threeDigitPostalCodeResults: threeDigitPostalCodeResults
            ? this.formatThreeDigitPostalCodeResults(threeDigitPostalCodeResults)
            : []
        };
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Search address from both Google and address book
   * @param  {String} value Address search value
   * @return {Promise}
   */
  async searchAddresses(value) {
    if (value) {
      try {
        this.setState({isLoading: true});
        const options = [];
        const results = await this.getAddressResults(value);
        const threeDigitPostalCodeOptions = results.threeDigitPostalCodeResults?.slice(0, maxResultsPerType) || [];
        options.push(...threeDigitPostalCodeOptions);
        const addressBookOptions =
          results.addressBookResults?.slice(0, maxResults - options.length || minResultsPerType) || [];
        options.push(...addressBookOptions);
        const googleOptions = results.googleResults?.slice(0, maxResults - options.length || minResultsPerType) || [];
        options.push(...googleOptions);
        this.setState({isLoading: false});
        return options;
      } catch (error) {
        console.error(error);
      }
    }
  }

  handleCustomMenuOption() {
    switch (this.props.customMenuOptionType) {
      case 'MANUAL':
        this.toggleShowManualAddressModal(true);
        return;
      case 'ADDRESS_BOOK':
        window.open('/addressbook/create', '_blank');
        return;
      default:
        return;
    }
  }

  toggleShowManualAddressModal(show) {
    this.setState({showManualAddressModal: show});
  }

  render() {
    const {
      field,
      form,
      label,
      prepend,
      required,
      disabled,
      allowZipOnly,
      onFieldBlur,
      autoFocus,
      // onAddressSelect is meant to be a side effect of selecting an address. This component always calls formik's setFieldValue on the primary field
      onAddressSelect,
      ignoreFieldWarnings,
      customValidateAddress,
      useFormikValidation,
      isFormikValid,
      customMenuOptionType,
      portal,
      // additionalValues is used to augment the values used by setFieldValue
      additionalValues = {}
    } = this.props;

    return (
      <AddressVerification
        customValidateAddress={customValidateAddress}
        allowZipOnly={allowZipOnly}
        addr={field.value || ''}
        onChange={(address, entry = {}) => {
          if (!isEmpty(address) && entry.value) {
            form.setFieldValue(field.name, {...address, company: entry.value, ...additionalValues});
          } else if (!isEmpty(address)) {
            form.setFieldValue(field.name, {...address, ...additionalValues});
          } else {
            return form.setFieldValue(field.name, {...additionalValues});
          }
        }}
        ignoreFieldWarnings={ignoreFieldWarnings}
        showManualAddressModal={this.state.showManualAddressModal}
        toggleShowManualAddressModal={this.toggleShowManualAddressModal.bind(this)}
      >
        {({value, onChange, isValid, ref, ...props}) => {
          const handleChange = async (selection) => {
            this.selected = null;
            if (selection && (selection.icon || selection.zipIcon)) {
              this.selected = selection.value;
              form.setFieldValue(field.name, {
                ...this.selected,
                ...(selection.icon && {company: selection.value}),
                ...additionalValues
              });
            } else {
              const value = await this.handlePlaceSelection(selection);
              if (value) {
                try {
                  onChange(value, selection);
                  this.selected = value;
                } catch (error) {
                  console.error(error, 'ERROR handling place change in `<AddressSearchField />`.');
                }
              }
            }
            // if there is a custom select handler, pass the selected/validated address
            if (onAddressSelect) {
              onAddressSelect(this.selected);
            }
          };
          return (
            <>
              <FormikTypeahead
                portal={portal}
                field={field}
                form={form}
                label={label}
                value={value}
                required={required}
                disabled={disabled}
                prepend={prepend}
                autoFocus={autoFocus}
                customMenuOptionText={CUSTOM_MENU_OPTION_TEXT[customMenuOptionType]}
                onCustomMenuOption={this.handleCustomMenuOption}
                onSearch={(value) => {
                  if (!value) {
                    form.setFieldValue(field.name, {...additionalValues});
                  }
                  return this.searchAddresses(value);
                }}
                showSuccess={useFormikValidation ? isFormikValid : isValid}
                menuItemComponent={({option, onClick}) => {
                  return (
                    <div className="flex" onClick={onClick}>
                      {option.icon || option.zipIcon ? (
                        <span className="pr-3">
                          {
                            <SvgIcon
                              name={option?.value?.facility_id ? 'Business' : option?.icon ? 'IdCard' : 'Zipcode'}
                              color="$sw-icon"
                            />
                          }
                        </span>
                      ) : null}
                      <span className="option-label">
                        {option.name && <b>{option.zipIcon ? 'Zip' : 'Address'}:</b>} {option.name}
                        {option.name && <br />}
                        {option.label}
                      </span>
                    </div>
                  );
                }}
                onChange={handleChange}
                onBlur={async (event) => {
                  if (customValidateAddress) {
                    event.persist();
                    if (!form.touched[field.name]) {
                      form.setTouched({...form.touched, [field.name]: true});
                    }
                    if (onFieldBlur) {
                      onFieldBlur();
                    }
                    if (!this.addressBookSelection && event.target) {
                      const value = this.handlePlaceSelection(event.target.value);
                      if (value) {
                        try {
                          onChange({value: {formatted_address: value}}, event.target.value);
                        } catch (error) {
                          console.error(error);
                        }
                      }
                    }
                  } else if ((useFormikValidation ? !isFormikValid : !isValid) || event.target.value !== value) {
                    if (!event.target.value) {
                      form.setFieldValue(field.name, {...additionalValues});
                    } else {
                      const results = await this.getAddressResults(event.target.value, true);
                      const selection =
                        results.threeDigitPostalCodeResults.length === 1
                          ? results.threeDigitPostalCodeResults[0]
                          : results.googleResults[0];
                      handleChange(selection);
                    }
                  }
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
              {this.props.showTopLevelLatLong && (
                <FormikTextInput
                  portal={portal}
                  field={field}
                  form={form}
                  label={'Latitude/Longitude'}
                  value={`${field.value?.latitude || ''};${field.value?.longitude || ''}`}
                  required={required}
                  disabled={disabled}
                  prepend={prepend}
                  autoFocus={autoFocus}
                  customMenuOptionText={CUSTOM_MENU_OPTION_TEXT[customMenuOptionType]}
                  onCustomMenuOption={this.handleCustomMenuOption}
                  onChange={(e) => {
                    return;
                  }}
                  onBlur={(e) => {
                    const {value} = e.target;
                    if (!value) {
                      return;
                    }
                    const [lat, long] = value.split(';').map((x) => Number(x));
                    if (!lat || !long) {
                      return;
                    }

                    const addressToSave = {
                      formatted_address: `${e.target.value}, US`,
                      geocoded_address: {
                        country: 'US',
                        formatted_address: `${e.target.value}, US`,
                        address_1: null,
                        address_2: null,
                        city: null,
                        state_province: null,
                        postal_code: null,
                        latitude: lat,
                        longitude: long
                      }
                    };
                    form.setFieldValue(field.name, {...addressToSave.geocoded_address, ...additionalValues});
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            </>
          );
        }}
      </AddressVerification>
    );
  }
}

export default AddressSearchField;
