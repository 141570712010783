import {Component} from 'react';
import {connect} from 'react-redux';
import {submit, change, isSubmitting, getFormError, reset, initialize} from 'redux-form';
import {Checkbox, Row, Col} from 'react-bootstrap';
import get from 'lodash/get';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {DeprecatedButton, Modal, Dropdown} from '@shipwell/shipwell-ui';
import ShipmentDetailsHeader from '../../components/shipmentDetailsHeader';
import ShipmentStops from '../../components/shipmentStops';
import ShipmentItems from '../../components/shipmentItems';
import ShipmentTagsCard from 'App/components/shipmentTagsCard';
import {fetchBrokerShipperRelationships} from 'App/actions/brokers';
import {addressBookGet} from 'App/actions/addressBook';
import {fetchChargeCategories} from 'App/actions/shipments';
import TenderRequestForm from 'App/containers/tendering/create';
import ExecuteRoutingGuide from 'App/containers/routingGuides/execute';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {PricingIntelContextProvider} from 'App/data-hooks/pricing/usePricingIntel';
import {normalizeShipmentForQuote, shouldSkipRating} from 'App/containers/quotes/create/utils/createQuote';
import withFlags from 'App/utils/withFlags';
import {
  DEFAULT_DROPOFF_RADIUS,
  DEFAULT_FORECAST_RANGE,
  DEFAULT_PICKUP_RADIUS,
  HISTORICAL_PRICING,
  PREDICTIVE_MARKET,
  PREDICTIVE_INTERNAL,
  PRICING_INTEL_RANGE_12_MONTHS,
  PRICING_INTEL_SOURCE_NEW_QUOTE
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import './styles.scss';
import {GetInternationalPreferencesQuery} from 'App/data-hooks';

const PushToRoutingGuide = ({onClick}) => <li onClick={onClick}>Push to Routing Guide</li>;

PushToRoutingGuide.propTypes = {
  onClick: PropTypes.func
};

const PushToRoutingGuideWithPoliciesEnabled = compose(
  connect((state) => ({
    policiesEnabled: get(state, 'auth.company.feature_flags.policies_enabled')
  })),
  withConditionalFallback(({policiesEnabled}) => !policiesEnabled)
)(PushToRoutingGuide);

/**
 * Quick Quote - Shipment Stops and LineItem creation
 * @param  {Object} state Application state
 */
class ShipmentDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingManual: false,
      isSubmittingInstant: false,
      isPushToDash: false,
      pushToLoadboard: this.props.featureFlags && this.props.featureFlags.load_board_enabled,
      isTender: false,
      showRoutingGuide: false
    };
  }

  componentWillMount() {
    this.searchCustomerAccounts();
    this.getAddressBook();
    this.getChargeCategories();
  }

  componentWillUnmount() {
    this.props.dispatch(reset('newQuoteForm'));
  }

  componentDidMount() {
    if (this.props.router && this.props.router.location.pathname === '/new-quote-parcel') {
      this.props.dispatch(change('newQuoteForm', 'mode', [{id: 6, mode: 'PARCEL', description: 'Parcel'}]));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.company?.id && prevProps.company?.id !== this.props.company?.id) {
      this.searchCustomerAccounts();
      this.getAddressBook();
      this.getChargeCategories();
    }
    if (!this.props.isSubmitting && prevProps.isSubmitting !== this.props.isSubmitting) {
      this.setState({
        isSubmittingManual: false,
        isSubmittingInstant: false,
        isPushToDash: false
      });
    }

    /**
     * Hack: Need to define if the quote is for manual rates for success callback redirects
     * @todo Find a better way to pass reference to Redux-Form callback
     */
    if (this.props.formValues && this.props.formValues.hasOwnProperty('isManual')) {
      this.props.dispatch(submit('newQuoteForm'));
      this.props.dispatch(change('newQuoteForm', 'isManual', ''));
    }

    if (this.props.formValues && this.props.formValues.hasOwnProperty('pushToDash')) {
      this.props.dispatch(submit('newQuoteForm'));
      this.props.dispatch(change('newQuoteForm', 'pushToDash', ''));
    }

    if (get(this.props, 'formValues.pushToRoutingGuide')) {
      this.props.dispatch(submit('newQuoteForm'));
      this.props.dispatch(change('newQuoteForm', 'pushToRoutingGuide', false));
    }
  }

  getChargeCategories() {
    return this.props.dispatch(fetchChargeCategories());
  }

  getAddressBook() {
    return this.props.dispatch(addressBookGet());
  }

  searchCustomerAccounts() {
    const {company} = this.props;

    if (company && company.brokerage) {
      return this.props.dispatch(fetchBrokerShipperRelationships(company.brokerage.id));
    }
  }

  /**
   * Submit quote form
   * @param  {Object} e Click event
   */
  handleSubmit = (e, hasDrayage, hasParcel, isTender) => {
    e.preventDefault();
    this.props.dispatch(change('newQuoteForm', 'isManual', false));
    const {pushToLoadboard} = this.state;
    if (pushToLoadboard && !hasDrayage && !hasParcel) {
      this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', true));
    } else {
      this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', false));
    }
    this.setState({isSubmittingInstant: true});
    if (isTender) {
      this.props.dispatch(change('newQuoteForm', 'isTender', true));
      this.setState({isTender: true});
    } else {
      this.props.dispatch(change('newQuoteForm', 'isTender', false));
    }
  };
  /**
   * Submit manual quote form
   * @param  {Object} e Click event
   */
  handleSubmitManual(e, hasDrayage, hasParcel) {
    e.preventDefault();

    this.props.dispatch(change('newQuoteForm', 'isManual', true));
    const {pushToLoadboard} = this.state;
    if (pushToLoadboard && !hasDrayage && !hasParcel) {
      this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', true));
    } else {
      this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', false));
    }
    this.setState({isSubmittingManual: true});
  }

  handlePushToDashboard(e) {
    e.preventDefault();

    this.props.dispatch(change('newQuoteForm', 'pushToDash', true));
    const {pushToLoadboard} = this.state;
    if (pushToLoadboard) {
      this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', true));
    } else {
      this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', false));
    }
    this.setState({isPushToDash: true});
  }

  handlePushToRoutingGuide(e) {
    e.preventDefault();
    this.props.dispatch(change('newQuoteForm', 'pushToRoutingGuide', true));
    this.props.dispatch(change('newQuoteForm', 'pushToLoadboard', this.state.pushToLoadboard));
    this.setState({showRoutingGuide: true});
  }

  handleGenerateLabelsAndSchedulePickUp = (e) => {
    e.preventDefault();
    this.props.dispatch(submit('newQuoteForm'));
  };

  render() {
    const {isSubmittingManual, isSubmittingInstant, isTender} = this.state;
    const {
      formValues,
      brokers,
      isSubmitting,
      formError,
      company,
      featureFlags = {},
      router,
      submitSucceeded,
      selectedShipment,
      stopCustomFields,
      isEditForm,
      isLoadingParcelRates
    } = this.props;
    const {mode} = formValues;
    const hasFTL = (Array.isArray(mode) && mode.find((mode) => mode.id === 1)) || mode === '1';
    const hasLTL = Array.isArray(mode) && mode.find((mode) => mode.id === 2 || mode.id === 4);
    const hasDrayage = Array.isArray(mode) && mode.find((mode) => mode.id === 5);
    const hasParcel = Array.isArray(mode) && mode.find((mode) => mode.id === 6);
    const hasRail = Array.isArray(mode) && mode.find((mode) => mode.id === 8);
    const canEnterMaxBuy = featureFlags.uses_3pl_quoting_flow;
    const canTender = hasLTL || hasFTL || hasDrayage || hasRail;
    const sendToCarriersEnabled = featureFlags.policies_enabled || canTender;
    const shouldRenderLabelOptions = shouldSkipRating(formValues);

    return (
      !brokers.loadingCustomerRelationships && (
        <>
          <Row>
            <Col xs={12} sm={12}>
              <ShipmentTagsCard
                className="newshipment__card full-width"
                formValues={formValues}
                form="newQuoteForm"
                dispatch={this.props.dispatch}
                isEditForm={isEditForm}
              />
            </Col>
          </Row>
          <ShipmentDetailsHeader
            formValues={formValues}
            showCustomerField={brokers.shipperRelationships && brokers.shipperRelationships.total_count > 1}
            equipmentType={formValues.equipment_type}
          />
          <div className="quote-stops-lineitems-container">
            <PricingIntelContextProvider
              pageSource={PRICING_INTEL_SOURCE_NEW_QUOTE}
              equipmentType={formValues.equipment_type || []}
              isShipwell={company?.is_shipwell}
              pickupRadius={DEFAULT_PICKUP_RADIUS}
              dropOffRadius={DEFAULT_DROPOFF_RADIUS}
              forecastRange={DEFAULT_FORECAST_RANGE}
              stops={formValues?.stops}
              pricingIntelDataOptions={[PREDICTIVE_MARKET, PREDICTIVE_INTERNAL, HISTORICAL_PRICING]}
              selectedFormRange={PRICING_INTEL_RANGE_12_MONTHS}
            >
              <ShipmentStops formValues={formValues} customFields={stopCustomFields} />
              <GetInternationalPreferencesQuery>
                {({data: internationalPreferences}) => (
                  <ShipmentItems
                    formValues={formValues}
                    internationalPreferences={internationalPreferences}
                    isLoadingParcelRates={isLoadingParcelRates}
                  />
                )}
              </GetInternationalPreferencesQuery>
            </PricingIntelContextProvider>
          </div>
          <div className="quick-quote-footer">
            {formError && (
              <p className="error-text-form-level">
                <i className="icon icon-Delayed pad-right" />
                {formError}
              </p>
            )}
            {featureFlags.load_board_enabled && !hasParcel && !hasDrayage && !(mode.length === 1 && hasLTL) && (
              <Checkbox
                className={this.state.pushToLoadboard ? 'checked' : ''}
                checked={this.state.pushToLoadboard ? true : false}
                onChange={() => this.setState({pushToLoadboard: !this.state.pushToLoadboard})}
              >
                &nbsp;Push to Load Board
              </Checkbox>
            )}
            {canEnterMaxBuy && hasFTL ? (
              <DeprecatedButton
                variant="secondary"
                className="btn-lg btn-secondary"
                onClick={this.handlePushToDashboard.bind(this)}
              >
                {isSubmitting && isSubmittingManual && <i className="icon icon-Restart rotate" />} Push to Dashboard
              </DeprecatedButton>
            ) : !featureFlags.load_board_enabled && !hasParcel ? (
              <button
                type="button"
                className="btn-lg btn-secondary"
                onClick={(e) => this.handleSubmitManual(e, hasDrayage, hasParcel, false)}
              >
                {isSubmitting && isSubmittingManual && <i className="icon icon-Restart rotate" />} Enter manual rate
              </button>
            ) : (
              ''
            )}
            {sendToCarriersEnabled && !shouldRenderLabelOptions ? (
              <Dropdown variant="secondary" title="Send To Carriers" disabled={isSubmitting}>
                {({onClick}) => (
                  <>
                    <PushToRoutingGuideWithPoliciesEnabled
                      onClick={(e) => {
                        onClick();
                        this.handlePushToRoutingGuide(e);
                      }}
                    />
                    {canTender ? (
                      <li
                        onClick={(e) => {
                          onClick();
                          this.handleSubmit(e, hasDrayage, hasParcel, true);
                        }}
                      >
                        Tender to Carrier(s)
                      </li>
                    ) : null}
                  </>
                )}
              </Dropdown>
            ) : null}
            {(!shouldRenderLabelOptions || !this.props.intParcel3RdPartyBillingWithoutQuote) && (
              <DeprecatedButton
                variant="primary"
                className="btn-lg btn-primary"
                disabled={isSubmitting}
                onClick={(e) => this.handleSubmit(e, hasDrayage, hasParcel, false)}
              >
                <div className="space-x-1">
                  {isSubmitting && isSubmittingInstant && <i className="icon icon-Restart rotate" />}
                  <span>Get quotes</span>
                </div>
              </DeprecatedButton>
            )}
            {shouldRenderLabelOptions && this.props.intParcel3RdPartyBillingWithoutQuote ? (
              <>
                <DeprecatedButton
                  className="pull-right mr-2"
                  onClick={() => {
                    this.props.router.push('/dashboard');
                  }}
                  variant="secondary"
                >
                  Cancel
                </DeprecatedButton>
                <DeprecatedButton
                  disabled={false}
                  className="pull-right mr-2"
                  onClick={this.handleGenerateLabelsAndSchedulePickUp}
                  variant="primary"
                >
                  Generate Labels
                </DeprecatedButton>
                <DeprecatedButton
                  disabled={false}
                  className="pull-right"
                  onClick={this.handleGenerateLabelsAndSchedulePickUp}
                  variant="primary"
                >
                  Generate Labels & Schedule Pickup
                </DeprecatedButton>
              </>
            ) : null}
            <TenderRequestForm
              show={isTender}
              onClose={() => this.setState({isTender: false})}
              router={router}
              submitSucceeded={submitSucceeded}
              shipmentDetailData={selectedShipment}
            />
            <Modal
              show={this.state.showRoutingGuide && submitSucceeded}
              title="Push to Routing Guide"
              footerComponent={null}
              onClose={() => this.setState({showRoutingGuide: false})}
            >
              <ExecuteRoutingGuide
                selectedShipment={selectedShipment}
                onCancel={() => {
                  this.setState({showRoutingGuide: false});
                  this.props.dispatch(initialize('newQuoteForm', normalizeShipmentForQuote(selectedShipment, false)));
                }}
                onSubmitSuccess={() =>
                  router.push(`/shipments/${selectedShipment.id}${this.state.pushToLoadboard ? '?loadboard=true' : ''}`)
                }
              />
            </Modal>
          </div>
        </>
      )
    );
  }
}

ShipmentDetails.propTypes = {
  brokers: PropTypes.object,
  company: PropTypes.shape({brokerage: PropTypes.shape({id: PropTypes.string}), is_shipwell: PropTypes.bool}),
  featureFlags: PropTypes.shape({
    load_board_enabled: PropTypes.bool,
    uses_3pl_quoting_flow: PropTypes.bool,
    policies_enabled: PropTypes.bool
  }),
  formValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  formError: PropTypes.string,
  selectedShipment: PropTypes.shape({id: PropTypes.string}),
  dispatch: PropTypes.func,
  router: PropTypes.shape({push: PropTypes.func}),
  location: PropTypes.shape({pathname: PropTypes.string}),
  isEditForm: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  stopCustomFields: PropTypes.array,
  isLoadingParcelRates: PropTypes.bool
};

export default compose(
  withFlags('intParcel3RdPartyBillingWithoutQuote'),
  connect((state) => ({
    brokers: state.brokers,
    company: state.auth.company,
    formValues: state.form.newQuoteForm && state.form.newQuoteForm.values,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    isSubmitting: isSubmitting('newQuoteForm')(state),
    formError: getFormError('newQuoteForm')(state),
    selectedShipment: state.shipments.selectedShipment
  }))
)(ShipmentDetails);
