import {
  ADD_BILLINGSOURCE,
  FETCH_BILLINGSOURCE,
  ALERT_ERROR,
  FETCH_ALL_SUBSCRIPTION_PLANS,
  FETCH_CURRENT_SUBSCRIPTION,
  DELETE_BILLINGSOURCE
} from './types';
import {getShipwellApi} from 'App/api/config';

async function postNONPlaidBillingSourcePromise(body, authorization) {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    shipwellApi.billingSourcesBankPost(body, authorization, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

export function postNONPlaidBillingSourceBank(sourceToken) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return postNONPlaidBillingSourcePromise({source_token: sourceToken}, authorization)
      .then((response) => {
        dispatch({type: ADD_BILLINGSOURCE, payload: response});
      })
      .catch((response) => {
        console.error(response);
      });
  };
}

async function postCreditCardPromise(body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingSourcesCardPost(body, authorization, function (err, data, response) {
      if (err) {
        if (response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error adding this card.');
        }
      } else {
        resolve(data);
      }
    });
  });
}

export function postCreditCard(sourceToken) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  return (dispatch) => {
    return postCreditCardPromise({source_token: sourceToken}, authorization)
      .then((response) => {
        dispatch({type: ADD_BILLINGSOURCE, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function postPlaidBillingSourcePromise(body, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingSourcesBankPlaidPost(body, authorization, function (err, data, response) {
      if (err) {
        if (response.body && response.body.errors) {
          reject(response.body.errors[0]);
        } else {
          reject('There was an unexpected error adding this card.');
        }
      } else {
        resolve(data);
      }
    });
  });
}

export function postPlaidBillingSourceBank(bankData) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;

  const accountID = bankData.metadata.account.id;
  const public_token = bankData.public_token;
  return (dispatch) => {
    return postPlaidBillingSourcePromise({plaid_public_token: public_token, plaid_account_id: accountID}, authorization)
      .then((response) => {
        dispatch({type: ADD_BILLINGSOURCE, payload: response});
        return {status: 200};
      })
      .catch((response) => {
        return {status: 400, details: response};
      });
  };
}

async function getBillingSourcePromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingSourcesGet(authorization, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

export function getBillingSource() {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  return (dispatch) => {
    return getBillingSourcePromise(authorization)
      .then((response) => {
        dispatch({type: FETCH_BILLINGSOURCE, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting billing information.'
        });
      });
  };
}

async function getShipperSubscriptionPlansPromise() {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingShipperSubscriptionPlansGet(function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

export function getShipperSubscriptionPlans() {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  return (dispatch) => {
    return getShipperSubscriptionPlansPromise()
      .then((response) => {
        dispatch({type: FETCH_ALL_SUBSCRIPTION_PLANS, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error getting subscription information.'
        });
      });
  };
}
async function getCurrentSubscriptionPromise(authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingSubscriptionGet(authorization, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

export function getCurrentSubscription() {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  return (dispatch) => {
    return getCurrentSubscriptionPromise(authorization)
      .then((response) => {
        dispatch({type: FETCH_CURRENT_SUBSCRIPTION, payload: response});
      })
      .catch((response) => {
        dispatch({type: FETCH_CURRENT_SUBSCRIPTION, payload: null});
      });
  };
}

async function addSubscriptionPromise(plan, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingSubscriptionPost(plan, authorization, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

export function addSubscription(planId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  return (dispatch) => {
    return addSubscriptionPromise({shipper_subscription_plan: planId}, authorization)
      .then((response) => {
        dispatch({type: FETCH_CURRENT_SUBSCRIPTION, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error adding this plan.'
        });
      });
  };
}

async function deleteBillingSourcePromise(bankId, authorization) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.billingSourcesBankBankIdDelete(bankId, authorization, function (err, response) {
      if (err) {
        reject(err);
      } else {
        resolve(response);
      }
    });
  });
}

export function deleteBillingSource(bankId) {
  const token = localStorage.getItem('dsiajksdjk');
  const authorization = 'Token ' + token;
  return (dispatch) => {
    return deleteBillingSourcePromise(bankId, authorization)
      .then((response) => {
        dispatch({type: DELETE_BILLINGSOURCE, payload: response});
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error deleting this account.'
        });
      });
  };
}
