import {ReactNode} from 'react';
import {DashboardContext} from './context';

export interface DashboardProviderProps {
  children: ReactNode;
  refresh: () => void;
}

export function DashboardProvider(props: DashboardProviderProps) {
  return <DashboardContext.Provider value={{refresh: props.refresh}}>{props.children}</DashboardContext.Provider>;
}
