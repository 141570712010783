import {
  ShipwellError,
  Shipment,
  ShipmentActionsApiShipmentsShipmentIdAutoBookPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {autoBookShipment} from 'App/api/shipment/typed';

export type AutoBookMutationOptions = UseMutationOptions<
  Shipment,
  ShipwellError,
  ShipmentActionsApiShipmentsShipmentIdAutoBookPostRequest
>;

export const useAutoBookShipmentMutation = (mutationOptions?: AutoBookMutationOptions) => {
  const autoBookMutation = useMutation({mutationFn: autoBookShipment, ...mutationOptions});

  return {
    autoBookShipment: autoBookMutation.mutate,
    autoBookMutation
  };
};
