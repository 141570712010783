import {Component} from 'react';
import {connect} from 'react-redux';
import {FieldArray, reduxForm} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import VendorDocumentForm from './VendorDocumentForm';
import NewVendorDocumentForm from './NewVendorDocumentForm';
import renderMultiplePOC from 'App/formComponents/renderMultiplePOC';
import * as actions from 'App/actions/vendors';
import {scrollToFirstErrorField} from 'App/utils/globals';

import Scroll from 'react-scroll';
var Element = Scroll.Element;

@connect((state) => ({}), {...actions})
export class VendorsDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderForm() {
    const {edit, handleSubmit, newRelationshipId, submittedForm, selectedRelationship} = this.props;
    const relationshipId = selectedRelationship && selectedRelationship.id ? selectedRelationship.id : null;

    return (
      <div className="vendors__shipwell-vendor">
        {edit && <VendorDocumentForm onSubmit={handleSubmit} relationshipId={relationshipId} />}

        {!edit && (
          <NewVendorDocumentForm
            onSubmit={handleSubmit}
            newRelationshipId={newRelationshipId}
            submittedForm={submittedForm}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Paper>
          <div className="vendors__header">
            <h3>
              <b>Documents</b>
            </h3>
          </div>
          <Element name={`position-vendor.poc`} />
          {this.renderForm()}
        </Paper>
      </div>
    );
  }
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmitFail: scrollToFirstErrorField
})(VendorsDocuments);
