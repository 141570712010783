import withConditionalFallback from 'App/components/withConditionalFallback';
import withFlags from 'App/utils/withFlags';
import {Link} from 'react-router';
import {compose} from 'recompose';

const ServiceProvidersLink = () => (
  <Link to="/service-providers" activeClassName="active">
    Service Providers
  </Link>
);

export const ConditionalServiceProvidersLink = compose(
  withFlags('modeServiceProviders'),
  withConditionalFallback(({modeServiceProviders}: {modeServiceProviders: boolean}) => !modeServiceProviders, null)
)(ServiceProvidersLink);
