import {connect} from 'react-redux';
import {SidebarContent} from '@shipwell/shipwell-ui';
import {ErrorBoundary} from 'App/common/ErrorBoundary/ErrorBoundary';
import Messages from 'App/components/Messages';
import './styles.scss';

const MessagesController = ({shipment, user}) => {
  return (
    <ErrorBoundary fallback={() => 'There was an error displaying the messages.'}>
      <Messages
        className="shipment__collaboration-messages"
        shipmentResource={shipment}
        resourceType={'SHIPMENT'}
        shouldPoll
        user={user}
        fixedWidthContainer
      />
    </ErrorBoundary>
  );
};

const ConnectedMessagesController = connect((state) => ({
  shipment: state.shipmentdetails.one,
  user: state.userProfile.user
}))(MessagesController);

const SidebarMessages = (props) => (
  <SidebarContent className="shipment__action-container" title="Messages" {...props}>
    <ConnectedMessagesController {...props} />
  </SidebarContent>
);

SidebarMessages.tabsRole = 'TabPanel';

export default SidebarMessages;
