/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from 'lodash';

type Cleanable = {[key: string]: any} | any[];

function removeEmptyPropertiesDeep<T extends Cleanable>(value: T, options?: CleanDeepOptions): T | undefined {
  if (_.isArray(value)) {
    return _.compact(value.map((item) => removeEmptyPropertiesDeep(item, options))) as T;
  }

  if (_.isObject(value)) {
    return _.transform(
      value,
      (result: {[key: string]: any}, val: any, key: string) => {
        const cleaned = removeEmptyPropertiesDeep(val);
        if (!isEmptyValue(cleaned, options)) {
          result[key] = cleaned;
        }
      },
      {}
    ) as T;
  }
  return isEmptyValue(value) ? undefined : value;
}

function isEmptyValue(value: any, options?: CleanDeepOptions): boolean {
  return (
    _.isNil(value) ||
    value === '' ||
    (!!options?.cleanEmptyArrays && _.isArray(value) && value.length === 0) ||
    (!!options?.cleanEmptyObjects && _.isObject(value) && _.isEmpty(value))
  );
}

export interface CleanDeepOptions {
  cleanEmptyArrays: boolean;
  cleanEmptyObjects: boolean;
}

export function cleanDeep<T extends Cleanable>(obj: T, options?: CleanDeepOptions): Partial<T> {
  return removeEmptyPropertiesDeep(obj, options) as Partial<T>;
}
