import _ from 'lodash';
import googleLibPhonenumber from 'google-libphonenumber';

export const phoneUtil = googleLibPhonenumber.PhoneNumberUtil.getInstance();
export const PNF = googleLibPhonenumber.PhoneNumberFormat;

import {removeCommasAndDollarSign} from 'App/utils/globals';

//validatePhoneNumber
export function validatePhoneNumber(number) {
  let parsedNumber;
  if (!number || number.length < 6 || number.length > 17 || !number.startsWith('+')) {
    return false;
  }
  if (number && number.length > 5) {
    parsedNumber = phoneUtil.parse(number);
  }
  if (number && parsedNumber && number.length > 5 && !phoneUtil.isValidNumber(parsedNumber)) {
    return false;
  }

  return true;
}

// validateEmail: accepts a string or array of emails
export function validateEmail(email) {
  const regexEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailList = [];
  if (!email) {
    return false;
  }
  if (typeof email === 'string') {
    return regexEmailValidation.test(email.trim());
  }
  if (Array.isArray(email)) {
    email.forEach((item) => {
      emailList.push(regexEmailValidation.test(item.trim()));
    });
    return emailList.includes(false) ? false : true;
  }
  if (email?.value) {
    return regexEmailValidation.test(email.value.trim());
  }
}

export function validateDollarValue(value) {
  if (typeof value === 'string') {
    if (value === '0' || value === '0.00' || value === '0.0') {
      return true;
    }
    if (
      !parseFloat(removeCommasAndDollarSign(value)) ||
      /^[0-9,.-]*$/.test(removeCommasAndDollarSign(value)) === false ||
      (value.match(/[.]/g) && value.match(/[.]/g).length > 1)
    ) {
      return false;
    }
    return true;
  }
  return true;
}

export function validateInteger(value) {
  if (typeof value === 'string') {
    if (!parseInt(removeCommasAndDollarSign(value)) || /^[0-9,]*$/.test(removeCommasAndDollarSign(value)) === false) {
      return false;
    }
    return true;
  }
  if (value % 1 !== 0) {
    return false;
  }
  return true;
}

// checkIsHoliday
export function validateIsHoliday(date) {
  const _holidays = {
    M: {
      // Month, Day
      '01/01': "New Year's Day",
      '07/04': 'Independence Day',
      '11/11': "Veteran's Day",
      '12/24': 'Christmas Eve',
      '12/25': 'Christmas Day',
      '12/31': "New Year's Eve"
    },
    W: {
      // Month, Week of Month, Day of Week
      '1/3/1': 'Martin Luther King Jr. Day',
      '2/3/1': "Washington's Birthday",
      '5/5/1': 'Memorial Day',
      '9/1/1': 'Labor Day',
      '10/2/1': 'Columbus Day',
      '11/4/4': 'Thanksgiving Day'
    }
  };
  const diff = 1 + (0 | ((new Date(date).getDate() - 1) / 7));
  const memorial = new Date(date).getDay() === 1 && new Date(date).getDate() + 7 > 30 ? '5' : null;

  return (
    _holidays['M'][moment(date).format('MM/DD')] ||
    _holidays['W'][moment(date).format('M/' + (memorial || diff) + '/d')]
  );
}

export function validateIsWeekday(date) {
  if (moment(date).weekday() === 0 || moment(date).weekday() === 6) {
    return false;
  }
  return true;
}
