import {SidebarContent} from '@shipwell/shipwell-ui';
import {Tracking} from './TrackingTabContent';

// this component is not typed in shipwell-ui, TODO: Define types there later.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SidebarTracking = ({shipment, ...props}: any) => {
  return (
    <>
      <SidebarContent className="shipment__action-container" title="Tracking" shipment={shipment} {...props}>
        <Tracking shipment={shipment} />
      </SidebarContent>
    </>
  );
};

SidebarTracking.tabsRole = 'TabPanel';

export default SidebarTracking;
