import {Modal, Button} from 'react-bootstrap';
import './modal-styles.scss';

const InfoModalWrapper = (props) => {
  const {
    show,
    children,
    title,
    onHide,
    error,
    primaryAction,
    secondaryAction,
    bsSize,
    extraClass,
    success,
    successText,
    loadingAction,
    keyboard
  } = props;
  return (
    <Modal
      className={extraClass ? extraClass + ' info-modal-wrapper' : 'info-modal-wrapper'}
      show={show}
      onHide={onHide}
      bsSize={bsSize ? bsSize : undefined}
      keyboard={keyboard ? false : true}
    >
      <Modal.Header closeButton closeLabel="close modal">
        <Modal.Title componentClass="h3">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        {error && Array.isArray(error) === true && (
          <div>
            <p className="error-text-form-level">
              <i className="icon icon-Delayed pad-right" /> Errors:
            </p>
            {error.map(function (item, i) {
              return (
                <p key={i} className="error-text-form-level text-center">
                  {item}
                </p>
              );
            })}
          </div>
        )}
        {error && Array.isArray(error) === false && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed pad-right" /> Error: {error}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="btn-wrapper">
        {success && <b className="text-success pad-right">{successText}</b>}
        {secondaryAction && (
          <Button
            bsStyle="default"
            onClick={(e) => secondaryAction.action(e)}
            disabled={secondaryAction.disabled || loadingAction === true}
          >
            {loadingAction === true && <i className="icon icon-Restart rotate" />}
            &nbsp;
            {secondaryAction.label}
          </Button>
        )}
        {primaryAction && (
          <Button
            bsStyle="primary"
            onClick={(e) => primaryAction.action(e)}
            disabled={primaryAction.disabled || loadingAction === true}
          >
            {loadingAction === true && <i className="icon icon-Restart rotate" />}
            &nbsp;
            {primaryAction.label}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModalWrapper;
