import {ProviderCode} from '@shipwell/genesis-sdk';

// ideally this value should return from backend
const purolatorSignatureTypeOptions = [
  {id: 'SERVICE_DEFAULT', name: 'Service Default'},
  {id: 'NO_SIGNATURE', name: 'No Signature Required'},
  {id: 'INDIRECT_SIGNATURE', name: 'Indirect Signature Required'},
  {id: 'DIRECT_SIGNATURE', name: 'Direct Signature Required'},
  {id: 'ADULT_SIGNATURE', name: 'Adult Signature Required'}
];

const canparSignatureTypeOptions = [
  {id: 'SERVICE_DEFAULT', name: 'Service Default'},
  {id: 'NO_SIGNATURE', name: 'No Signature Required'},
  {id: 'DIRECT_SIGNATURE', name: 'Direct Signature Required'},
  {id: 'ADULT_SIGNATURE', name: 'Adult Signature Required'}
];

const canadaPostSignatureTypeOptions = [
  {id: 'NO_SIGNATURE', name: 'No Signature Required'},
  {id: 'DIRECT_SIGNATURE', name: 'Direct Signature Required'},
  {id: 'ADULT_SIGNATURE', name: 'Adult Signature Required'}
];

export const getProviderSignatureTypeOptions = (providerCode?: ProviderCode) => {
  if (!providerCode) return [];

  const signatureTypeOptions: Record<string, {id: string; name: string}[]> = {
    [ProviderCode.Purolator]: purolatorSignatureTypeOptions,
    [ProviderCode.Canpar]: canparSignatureTypeOptions,
    [ProviderCode.Canadapost]: canadaPostSignatureTypeOptions
  };

  return signatureTypeOptions[providerCode];
};
