import {Component} from 'react';
import Async from 'react-select/async';
import FormGroup from '../../formGroup';
import {getProductsPromise} from 'App/actions/products';

/**
 * Product Search Field -
 * Search field for user products
 */
class ProductSearchField extends Component {
  static defaultProps = {
    value: '',
    name: 'products',
    input: {},
    icon: 'icon-Search',
    placeholder: 'Load from products',
    autoload: true
  };

  constructor() {
    super();
  }

  /**
   * Search products by query value
   * @param  {String} value Search input value
   */
  async searchProductsByQuery(value) {
    try {
      const response = await getProductsPromise({q: value});

      if (response && response.results) {
        const options = response.results.map((result) => ({
          label: result.product_ref,
          value: result
        }));

        return options;
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  }

  render() {
    const {input, req, icon, arrowRenderer, placeholder, autoload, ...rest} = this.props;
    const value = input.value || rest.value;
    const name = input.name || rest.name;
    const onChange = input.onChange || rest.onChange;

    return (
      <FormGroup {...this.props}>
        {() => (
          <>
            <Async
              {...rest}
              classNamePrefix={'sw-react-select'}
              value={value}
              menuPortalTarget={document.body}
              name={name}
              autoload={autoload}
              placeholder={placeholder}
              arrowRenderer={arrowRenderer}
              defaultOptions
              loadOptions={this.searchProductsByQuery.bind(this)}
              filterOptions={(option) => option}
              onChange={(value) => onChange && onChange(value)}
            />
          </>
        )}
      </FormGroup>
    );
  }
}

export default ProductSearchField;
